import React, { useContext } from 'react';

import Form from '../../../../../form';
import Fields from '../../../../../fields';

import { WizardContext } from '../../../state';
import Categories from './components/categories';

import classes from '../properties.module.scss';

const WizardForm = () => {
  const { template, setTemplate } = useContext(WizardContext);

  return (
    <Form initialValues={template} onSubmit={values => setTemplate({ ...template, name: values.name, description: values.description })}>
      <div className={classes.group}>
        <Fields.Text label="Title" name="title" submitOnChange={true} />
        <Fields.Text label="Description" name="description" submitOnChange={true} showError={false} />
      </div>
      <div className={classes.group}>
        <Categories />
      </div>
      <div className={classes.group}>
        <Fields.Text label="Identifier" name="id" disabled={true} showError={false} />
      </div>
    </Form>
  );
};

export default WizardForm;
