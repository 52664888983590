import React from 'react';

import DocumentsBox from './documents-box';
import classes from '../../details.module.scss';

const Documents = ({ api }) => {
  const supplier = api.response;
  const userId = supplier?.managerUserId;

  return (
    <div className={classes.tab}>
      {supplier?.supplierType === 'independent-contractor' && (
        <DocumentsBox initialLoading={api.loading} title="Documents" owner={{ type: 'supplier', id: supplier?.id }} />
      )}
      <DocumentsBox
        initialLoading={api.loading}
        title={supplier?.supplierType === 'independent-contractor' ? 'Supplier manager documents' : 'Documents'}
        owner={{ type: 'user', id: userId }}
      />
    </div>
  );
};

export default Documents;
