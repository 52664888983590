import React from 'react';

import Fields from '../../../../../../../fields';

import useDocuments from '../../../../../state/use-documents';
import FormGroupHeader from './form-group-header';

import classes from '../properties.module.scss';

function getComparedToValueField({ macro, wizard, disabled }) {
  if (!macro) {
    return null;
  }

  if (macro.config.operator === 'expression') {
    return <Fields.Textarea name="config.comparedToValue" placeholder="Compared to value" submitOnChange={true} showError={false} disabled={disabled} />;
  }

  const isWizardField = macro?.name?.includes('WizardInstance');
  if (!isWizardField) {
    return (
      <Fields.Text name="config.comparedToValue" label="Value" placeholder="Compared to value" submitOnChange={true} showError={false} disabled={disabled} />
    );
  }

  const [, page, group, field] = macro.name.split('?.').map(item => item.replace(/[[\]';]/g, ''));
  let fieldObj;
  wizard.pages?.forEach(pageObj => {
    if (pageObj.id === page) {
      pageObj.groups?.forEach(groupObj => {
        if (groupObj.id === group) {
          fieldObj = groupObj.fields.find(fieldObj => fieldObj.id === field);
        }
      });
    }
  });

  if (fieldObj?.type === 'OT_SELECT' || fieldObj?.type === 'OT_MULTI_SELECT') {
    return (
      <Fields.Select
        name="config.comparedToValue"
        label="Value"
        placeholder="Compared to value"
        options={fieldObj.options}
        submitOnChange={true}
        showError={false}
        disabled={disabled}
      />
    );
  }

  return (
    <Fields.Text name="config.comparedToValue" label="Value" placeholder="Compared to value" submitOnChange={true} showError={false} disabled={disabled} />
  );
}

const CheckboxConfigForm = ({ disabled }) => {
  const { selection, wizard } = useDocuments();
  const comparedToValueField = getComparedToValueField({
    macro: selection?.macro,
    wizard,
    disabled,
  });
  return (
    <div className={classes.group}>
      <FormGroupHeader title="Checkbox settings">
        <div>
          <strong>Operator sign</strong>, logical / boolean comparison operator between placed macro and defined value.
        </div>
        <div>
          <strong>Compared to value</strong>, value to which selected macro is being compared to.
        </div>
      </FormGroupHeader>
      <div className={classes.line}>
        <Fields.Select
          name="config.operator"
          label="Sign"
          placeholder="Operator"
          options={[
            { value: 'equals', label: '=', description: 'Equals' },
            { value: 'expression', label: 'Custom expression', description: 'Custom expression' },
          ]}
          showError={false}
          submitOnChange={true}
          disabled={disabled}
        />
      </div>
      <div className={classes.line}>{comparedToValueField}</div>
    </div>
  );
};

export default CheckboxConfigForm;
