import { Button } from '@blueprintjs/core';
import React from 'react';
import * as Yup from 'yup';

import Dialog from '../../../../../../components/dialog';
import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';
import ClientPoliciesDescriptions from '../../../../../../components/organizational/client/client-policies-description';

import useApi from '../../../../../../hooks/use-api';
import { createClient } from '../../../../../../apis/client/organizations';

const NewClient = ({ isOpen, parentId, onClose, reloadList }) => {
  const createClientApi = useApi(createClient, { skip: true });
  const loading = createClientApi.loading;

  const submit = async values => {
    await createClientApi.request(values);
    reloadList();
    onClose();
  };

  return (
    <Dialog title="New Client" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ name: '', parentId, isInvoiced: false }}
        validationSchema={Yup.object().shape({ name: Yup.string().required('Client name is required.') })}
        onSubmit={submit}
      >
        {form => (
          <React.Fragment>
            <Fields.Text name="name" label="Name" />
            <Fields.Select
              label="Will separate invoices need to be created for this client ?"
              name="isInvoiced"
              options={[
                { label: 'Separate invoices will be created for this client.', value: true },
                { label: 'Separate invoices will not be created for this client.', value: false },
              ]}
              // temporarily disabled.
              disabled={true}
            />
            {ClientPoliciesDescriptions.isInvoiced(form?.values)}
            <Button type="submit" outlined fill text="Submit" disabled={loading} loading={loading} />
          </React.Fragment>
        )}
      </Form>
    </Dialog>
  );
};

export default NewClient;
