import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';

import { StorageManager } from '../../../../authentication/storage';
import useApi from '../../../../hooks/use-api';

import * as platformApis from '../../../../apis/platform/time';
import * as clientApis from '../../../../apis/client/time';
import * as supplierApis from '../../../../apis/supplier/time';

export const TimeRecordCommentsContext = React.createContext({});

const apis = organizationType => {
  switch (organizationType) {
    case 'platform':
      return platformApis;

    case 'client':
      return clientApis;

    case 'supplier':
      return supplierApis;
    default:
      return {};
  }
};

const TimeRecordCommentsProvider = ({ children, recordId }) => {
  const [comments, setComments] = useState([]);
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();

  const loadCommentsApi = useApi(apis(organizationType).getRecordComments, { skip: true });
  const loadComments = async () => {
    const result = await loadCommentsApi.request(recordId);
    const sorted = result?.sort?.((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf());
    const processed = [];
    let sender = null;
    let timeDiff = 0;
    sorted?.forEach?.(element => {
      const diff = moment(element.date).fromNow();
      if (element.sender !== sender || diff !== timeDiff) {
        sender = element.sender;
        timeDiff = diff;
        processed.push(element);
      } else {
        processed.push({ ...element, sender: undefined });
      }
    });

    setComments(processed);
  };

  const createCommentApi = useApi(apis(organizationType).createRecordComment, { skip: true });
  const createComment = async values => {
    await createCommentApi.request(values);
    await loadComments(values?.recordId);
  };

  const updateCommentApi = useApi(apis(organizationType).updateRecordComment, { skip: true });
  const updateComment = async values => {
    await updateCommentApi.request(values);
    await loadComments(values?.recordId);
  };

  const removeCommentApi = useApi(apis(organizationType).removeRecordComment, { skip: true });
  const removeComment = async values => {
    await removeCommentApi.request(values);
    await loadComments(values?.recordId);
  };

  useEffect(() => {
    void loadComments();
  }, [recordId]);

  return (
    <TimeRecordCommentsContext.Provider
      value={{
        loading: loadCommentsApi.loading || createCommentApi.loading || updateCommentApi.loading || removeCommentApi.loading,
        comments,
        loadComments,
        createComment,
        updateComment,
        removeComment,
      }}
    >
      {children}
    </TimeRecordCommentsContext.Provider>
  );
};

export default TimeRecordCommentsProvider;
