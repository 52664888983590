import React, { useEffect } from 'react';
import moment from 'moment';
import { Button, Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';

import WithConfirmation from '../../../../../../hooks/with-confirmation';
import useApi from '../../../../../../hooks/use-api';
import {
  getEngagementImportRequest,
  cancelEngagementImportRequest,
  validateEngagementImportRequest,
  executeEngagementImportRequest,
} from '../../../../../../apis/platform/engagements';

import { capitalize } from '../../../../../../utilities/common';

import classes from './details.module.scss';

const ImportRequestDetails = ({ data, onClose, reloadList }) => {
  const api = useApi(getEngagementImportRequest, { params: { id: data?.id }, skip: !data?.id });
  const validateApi = useApi(validateEngagementImportRequest, { skip: true });
  const executeApi = useApi(executeEngagementImportRequest, { skip: true });
  const cancelApi = useApi(cancelEngagementImportRequest, { skip: true });

  useEffect(() => {
    const interval = setInterval(() => {
      void api?.request({ id: data?.id });
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onValidate = async () => {
    await validateApi?.request({ id: data?.id });
  };
  const onExecute = async () => {
    await executeApi?.request({ id: data?.id });
  };
  const onCancel = async () => {
    await cancelApi?.request({ id: data?.id });
    reloadList();
    onClose();
  };

  const renderTitle = () => {
    if (!!api?.loading) {
      return 'Loading...';
    }

    return `${api?.response?.clientName} - ${moment(api?.response?.createdAt).format('MM/DD/YYYY')}`;
  };

  const renderStatusDescription = status => {
    switch (status) {
      case 'idle':
        return ' - Awaiting further action.';

      case 'validating':
        return ' - Provided data is being validated.';

      case 'executing':
        return ' - Engagement import is being executed for provided data.';

      default:
        return null;
    }
  };

  const renderReport = () => {
    var lines = api?.response?.report?.split('\r\n');
    return (
      <React.Fragment>
        {lines?.map?.((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div className={classes.details}>
      <div className={classes.header}>
        <div className={classNames(classes.title, !!api?.loading && Classes.SKELETON)}>{renderTitle()}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.stats}>
            <div className={classes.total}>
              <div className={classes.value}>{api?.response?.total}</div>
              <div className={classes.label}>Total</div>
            </div>
            <div className={classes.validated}>
              <div className={classes.value}>{api?.response?.totalValidated}</div>
              <div className={classes.label}>Validated</div>
            </div>
            <div className={classes.imported}>
              <div className={classes.value}>{api?.response?.totalImported}</div>
              <div className={classes.label}>Imported</div>
            </div>
          </div>
          <div className={classes.actions}>
            <div className={classes.message}>
              Status: <strong>{capitalize(api?.response?.status)}</strong>
              {renderStatusDescription(api?.response?.status)}
            </div>
          </div>
          <div className={classes.actions}>
            <div className={classes.message}>In order to execute engagement import, run the validation first and then the import (if the report is clear).</div>
            <div className={classes.buttons}>
              <Button
                outlined
                text="Validate"
                disabled={api?.response?.status !== 'idle' || (api?.response?.total === api?.response?.totalImported && api?.response?.total !== 0)}
                onClick={onValidate}
              />
              <Button
                outlined
                text="Import"
                disabled={
                  api?.response?.status !== 'idle' ||
                  api?.response?.total !== api?.response?.totalValidated ||
                  api?.response?.total === 0 ||
                  api?.response?.total === api?.response?.totalImported
                }
                onClick={onExecute}
              />
            </div>
          </div>
          <div className={classes.actions}>
            <div className={classes.message}>In case import file is not valid or simply should be abandoned, cancel the import request.</div>
            <div className={classes.buttons}>
              <WithConfirmation messages={{ question: `Are you sure you want to cancel engagement import request ?`, confirmButton: 'Ok' }}>
                <Button outlined text="Cancel" onClick={onCancel} />
              </WithConfirmation>
            </div>
          </div>
          {!!api?.response?.report && <div className={classes.report}>{renderReport()}</div>}
        </div>
      </SimpleBar>
    </div>
  );
};

export default ImportRequestDetails;
