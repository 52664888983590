import React from 'react';

import { DocumentsProvider } from './state/documents-provider';

import Toolbar from './components/toolbar';
import Editor from './components/editor';
import Preview from './components/preview';

import classes from './document-configurator.module.scss';

const DocumentConfigurator = ({ id, recordId }) => {
  return (
    <DocumentsProvider id={id} recordId={recordId}>
      <div className={classes.configurator}>
        <Toolbar />
        <Editor />
        <Preview />
      </div>
    </DocumentsProvider>
  );
};

export default DocumentConfigurator;
