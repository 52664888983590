import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';

import Spinner from '../components/spinner';
import { getUserProfile } from '../state/identity';
import { getThemes } from '../state/application';

import { authenticate, initialize, LOGOUT_ROUTE } from './service';

const Authenticate = ({ getUserProfile, getThemes }) => {
  const navigate = useNavigate();

  useEffect(() => {
    async function authenticateUser() {
      /**
       * Attempt to initialize user identity by refreshing tokens.
       * If successful, retrieve users profile and set identity as authenticated.
       * If not, redirect to login page and handle rest of the flow via <Callback /> component.
       */
      try {
        const initialized = await initialize(navigate);
        if (!initialized) {
          await authenticate();
        } else {
          await getThemes();
          await getUserProfile();
        }
      } catch (e) {
        navigate(LOGOUT_ROUTE);
      }
    }
    void authenticateUser();
  }, [getThemes, getUserProfile, navigate]);

  return <Spinner />;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserProfile,
      getThemes,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Authenticate);
