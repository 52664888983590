import { getIn } from 'formik';
import moment from 'moment';

export function getValue(values, fieldId) {
  if (!fieldId || !values) {
    return undefined;
  }
  return getIn(values, fieldId);
}

const isValidVariableName = value => {
  if (!value) {
    return false;
  }
  return value.match(/^[a-zA-Z_][a-zA-Z0-9_]*$/);
};

export function replaceMacros(text, values) {
  if (!values) {
    return text;
  }

  if (!Object.keys(values).length) {
    return text;
  }

  const varDeclaration = Object.keys(values)
    .filter(keyName => isValidVariableName(keyName))
    .map(keyName => {
      return `const ${keyName} = values.${keyName} || {};`;
    })
    .join('\n');

  const fctStr1 = `(function fn(values, moment) {
    ${varDeclaration} 
    return \`${text}\`;
  })`;

  // eslint-disable-next-line no-eval
  const fn = eval(fctStr1);
  const result = fn(values, moment);
  return result;
}
