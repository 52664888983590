import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Avatar from '../../../components/avatar';
import Page from '../../../components/page';
import Tabs from '../../../components/tabs';

import useApi from '../../../hooks/use-api';
import { getClient } from '../../../apis/client/organizations';

import General from './containers/general';
import Policies from './containers/policies';
import Staff from './containers/staff';
import Departments from './containers/departments';
import Fees from './containers/fees';
import Integrations from './containers/integrations';

import classes from './company-info.module.scss';
import Appearance from './containers/appearance';

const CompanyInfo = ({ clientId }) => {
  const api = useApi(getClient, { params: { clientId } });

  const avatar = (
    <Link
      to={{
        pathname: `/company-info`,
        search: `?t=appearance`,
      }}
    >
      <Avatar
        loading={api?.loading}
        name={api?.response?.name}
        size={{ box: 220, text: 30 }}
        styles={{ marginBottom: '15px' }}
        url={api?.response?.logo}
        upload
      />
    </Link>
  );

  const commonProps = { api, clientId };

  return (
    <Page
      title={!!api?.loading ? <span className={Classes.SKELETON}>Client</span> : api?.response?.name}
      scroll={false}
      path={[
        { name: 'Clients', to: '/clients' },
        {
          name: !!api?.loading ? <span className={Classes.SKELETON}>Client</span> : api?.response?.name,
          to: `/clients/${api?.response?.id}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Staff" transparent component={Staff} props={commonProps} />
          <Tabs.Item title="Departments" transparent component={Departments} props={commonProps} />
          <Tabs.Item title="Policies" component={Policies} props={commonProps} />
          <Tabs.Item title="Fees" component={Fees} props={commonProps} />
          {/* <Tabs.Item title="Notifications" /> */}
          {!!api.response?.isApiEnabled && <Tabs.Item title="Integrations" noScroll component={Integrations} props={commonProps} />}
          <Tabs.Item title="Appearance" component={Appearance} props={commonProps} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  clientId: state.identity.membership.organization.id,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
