import moment from 'moment';
import { EMAIL_DATE_FORMATS, GLOBAL_ITERATOR_NAME } from '../enumerations/constants';

const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

export const getEmailTemplateSpecificMacros = (descriptors, template) => {
  const { contextTypeName, refName } = template;
  const templateDescriptor = descriptors.find(d => d.contextTypeName === contextTypeName);

  const availableMacros = getMacros(getObjectDescriptors(templateDescriptor.children), [], refName);
  const iterators = getMacros(getArrayDescriptors(templateDescriptor.children), [], refName);

  return { availableMacros, iterators };
};

const getObjectDescriptors = elements => {
  let result = [];

  elements?.forEach(element => {
    if (element?.type !== 'iterator') {
      const el = { ...element };
      if (!!element?.children?.length) {
        el.children = getObjectDescriptors(element.children);
      }

      result = [...result, el];
    }
  });

  return result;
};

const getArrayDescriptors = elements => {
  let result = [];

  elements?.forEach(element => {
    const el = { ...element };
    if (element?.type === 'iterator') {
      result = [...result, el];
    } else {
      if (!!element?.children?.length) {
        el.children = getArrayDescriptors(element.children);
        result = [...result, ...el?.children];
      }
    }
  });

  return result;
};

const getMacros = (elements, path = [], refName = 'GL') => {
  const macros = [];
  path = !!path?.length ? path : [refName];

  elements?.forEach(element => {
    const common = {
      name: [...path, element?.name].join('.'),
      label: element?.name?.replace?.(regExp, '$1$4 $2$3$5'),
      type: element?.type,
    };

    switch (element?.type) {
      case 'object':
        const properties = getMacros(element?.children, [...path, element?.name]);
        macros.push({ ...common, items: properties });
        break;

      case 'iterator':
        const items = getMacros(element?.children, [GLOBAL_ITERATOR_NAME]);
        macros.push({ ...common, items });
        break;

      case 'value':
        switch (element?.baseType) {
          case 'datetime':
            const formats = EMAIL_DATE_FORMATS.map(format => ({
              name: `!!${common?.name} ? moment(${common?.name}).format('${format}') : ''`,
              label: moment(element?.example).format(format),
              example: moment(element?.example).format(format),
            }));
            macros.push({ ...common, items: formats });
            break;

          default:
            macros.push({ ...common, example: element?.example });
            break;
        }
        break;

      default:
        break;
    }
  });

  return macros;
};
