import { TimePicker as BlueprintTimePicker } from '@blueprintjs/datetime';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { Field } from '../../form';
import styles from './date.module.scss';

const getDate = (...args) => new Date(0, 0, 0, ...args);

const TimeField = ({ classes, disabled, name, label, description, format = '24', min, max, validate, submitOnChange = false, fill }) => {
  const formatHourOrMinutes = number => (number >= 10 ? number : `0${number}`);

  const validateTime = value => {
    if ((!min && !max) || !value) {
      return validate?.(value);
    }
    const [hours] = value.split(':');
    if (Number(hours) < Number(min)) {
      return `Time cannot be lower than ${formatHourOrMinutes(min)}:00`;
    } else if (Number(hours) > Number(max)) {
      return `Time cannot be greater than ${formatHourOrMinutes(max)}:00`;
    }
    return validate?.(value);
  };

  return (
    <Field
      classes={{ ...classes, wrapper: classNames(styles.time, classes?.content, fill && styles.fill) }}
      label={label}
      description={description}
      name={name}
      validate={validateTime}
    >
      {({ field, form }) => {
        const onChange = time => {
          const hours = time.getHours();
          const minutes = time.getMinutes();
          form.setFieldValue(name, `${formatHourOrMinutes(hours)}:${formatHourOrMinutes(minutes)}`);
          if (submitOnChange) {
            setTimeout(form.submitForm, 0);
          }
        };

        return <TimePicker onChange={onChange} text={field.value} disabled={disabled} useAmPm={format === '12'} />;
      }}
    </Field>
  );
};

const TimePicker = ({ text, useAmPm, disabled, onChange }) => {
  const value = useMemo(() => {
    if (!text) return null;

    const [hours, minutes] = text.split(':');
    return getDate(hours, minutes);
  }, [text]);

  return <BlueprintTimePicker onChange={onChange} value={value} defaultValue={undefined} disabled={disabled} useAmPm={useAmPm} />;
};

export default TimeField;
