import MacroTypes from '../enumerations/macro-types';

function getMacroRender(macro, includeExample) {
  const { type, name, config } = macro;
  const example = includeExample ? macro.example || '' : '';

  if (!name && type !== MacroTypes.CUSTOM_JS) {
    return '';
  }

  switch (type) {
    case MacroTypes.DEFAULT:
      return `\${${name} || '${example}'}`;

    case MacroTypes.CELL: {
      return `\${
        function formatCell() {
            const indexes = '${config.indices}'.split(',');
            return indexes.map((i) => (${name} || '${example}').charAt(Number(i) - 1)).join('');
          }()
        }`;
    }

    case MacroTypes.SUBSTRING: {
      const value = `(${name} || '${example}')`;
      return `\${
        function() {
            const [start, end] = '${config.indices}'.split(',');
            return ${value}.substring(Number(start) - 1, Number(end) - 1);
          }()
        }`;
    }

    case MacroTypes.DATE: {
      return `\${!!${name} ? moment(${name}).format('${config.dateFormat}') : '${example}'}`;
    }

    case MacroTypes.CHECKBOX: {
      if (!config?.operator) {
        return '';
      }
      if (config?.operator === 'equals') {
        const value = `(${name}?.toString() || '${example}')`;
        return `\${
          function() {
               if (${value} === '${config.comparedToValue}') {
               return '&#10004;'
              }
              return '';
            }()
        }`;
      }

      return `\${
          function() {
              if (${config.comparedToValue}) {
                return '&#10004;'
              }
              return '';
            }()
        }`;
    }

    case MacroTypes.SIGNATURE: {
      const background = `transparent url(\${${name} || '${example}'}) no-repeat`;
      return `<div style="width: 100%; height: 100%; background: ${background}; background-size: contain; background-position: left center;"></div>`;
    }

    case MacroTypes.CUSTOM_JS: {
      return `\${${config.customJS} || ''}`;
    }

    // type is undefined or not supported
    default:
      return '';
  }
}

export default getMacroRender;
