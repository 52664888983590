import React, { useState } from 'react';
import { Button, Menu, MenuItem, MenuDivider, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import _ from 'lodash';

import Audit from '../../../../components/audit';
import Table from '../../../../components/table';
import WithConfirmation from '../../../../hooks/with-confirmation';
import { mapCompensationTypeToPaymentLabel } from '../../../../utilities/engagement';

import { removePayroll, removePayrollItem } from '../../../../apis/platform/payroll';

import { distinct } from '../../../../utilities/common';
import { isDoubleTime, isHourly, isOvertime, isRegular, PayableItemType, payrollItemTypeToLabel } from '../../../../domain/payable-item-type';

import classes from './details.module.scss';

const PayrollSupplier = ({ supplier, currency, payrolls, setDialog, onClose, reloadList }) => {
  const [showBreakdown, setShowbreakdown] = useState(false);

  const handleRemovePayroll = async () => {
    await removePayroll(supplier?.payrollId);
    reloadList();
    if (payrolls?.length <= 1) {
      onClose();
    }
  };

  const disableBreakdown = distinct(supplier?.items?.map?.(i => i?.engagementId))?.length <= 1;
  const list = showBreakdown || disableBreakdown ? _.sortBy(supplier?.items, ['engagementId', 'rate', 'type']) : groupHours(supplier);
  return (
    <div className={classes.item}>
      <div className={classes.bar}>
        <div className={classes.info}>
          <div className={classes.name}>{supplier?.supplierUser}</div>
          <div className={classes.address}>{supplier?.supplierAddress}</div>
        </div>
        <div className={classes.actions}>
          <Popover
            content={
              <Menu>
                <MenuItem text={`${showBreakdown ? 'Hide' : 'Show'} breakdown`} disabled={disableBreakdown} onClick={() => setShowbreakdown(!showBreakdown)} />
                <MenuDivider />
                <MenuItem
                  text="Add"
                  onClick={() =>
                    setDialog({
                      open: true,
                      initialValues: {
                        id: undefined,
                        payrollId: supplier?.payrollId,
                        userId: supplier?.userId,
                        supplierId: supplier?.supplierId,
                        engagementId: undefined,
                        type: undefined,
                        description: undefined,
                        amount: undefined,
                        quantity: undefined,
                      },
                    })
                  }
                />
                <MenuDivider />
                <WithConfirmation messages={{ confirmButton: 'Remove' }}>
                  <MenuItem shouldDismissPopover={false} text="Remove" onClick={handleRemovePayroll} />
                </WithConfirmation>
              </Menu>
            }
          >
            <Button small outlined icon={<Icons.MoreVertical size={16} strokeWidth={1.5} />} />
          </Popover>
        </div>
      </div>
      <div className={classes.table}>
        <Table.Container data={list}>
          <Table.Columns.TwoLines name="Description" width="2fr" lines={[d => d?.description || '-', d => payrollItemTypeToLabel(d?.type)]} />
          <Table.Columns.TwoLines showOnMobile lines={[d => d?.clientName, d => d?.engagementTitle]} />
          <Table.Columns.Default name="Hours" width="100px" alt={d => d?.quantity} render={renderHours} />
          <Table.Columns.Default name="Rate" render={d => renderRate(d, currency)} />
          <Table.Columns.Default
            name="Amount"
            width="100px"
            showOnMobile
            alt={d => d?.amount}
            render={d => `${currency?.symbol}${Number.parseFloat(`${d?.amount || 0}`).toFixed(2)}`}
          />
          <Table.Columns.Default
            showOnMobile
            width="36px"
            render={d => <Audit id={d?.id} type="payableItem" icon small outlined disabled={!disableBreakdown && !showBreakdown} />}
          />
          ;
          <Table.Columns.Actions
            showOnMobile
            disabled={() => !disableBreakdown && !showBreakdown}
            actions={[
              {
                name: 'Edit',
                onClick: d => {
                  setDialog({
                    open: true,
                    initialValues: {
                      id: d?.id,
                      payrollId: supplier?.payrollId,
                      userId: supplier?.userId,
                      supplierId: supplier?.supplierId,
                      engagementId: d?.engagementId,
                      type: d?.type,
                      description: d?.description,
                      amount: d?.amount,
                      quantity: d?.quantity,
                    },
                  });
                },
              },
              { divider: true },
              {
                name: 'Remove',
                onClick: async d => {
                  await removePayrollItem(d?.id);
                  reloadList();
                },
                confirmation: { messages: { confirmButton: 'Remove' } },
              },
            ]}
          />
        </Table.Container>
      </div>
      <div className={classes.subTotal}>
        <div className={classes.label}>Total</div>
        <div className={classes.value} title={supplier?.total}>{`${currency?.symbol}${Number.parseFloat(`${supplier?.total || 0}`).toFixed(2)}`}</div>
      </div>
    </div>
  );
};

const groupHours = supplier => {
  if (!supplier?.items?.length) {
    return [];
  }

  const toTyped = (items, type, rateType) => {
    return items?.length > 0
      ? [
          {
            type,
            rateType,
            description: items?.[0]?.description,
            quantity: items?.reduce?.((a, b) => a + b?.quantity, 0),
            amount: items?.reduce?.((a, b) => a + b?.amount, 0),
            rate: Number(items?.reduce?.((a, b) => a + b?.amount, 0) / items?.reduce?.((a, b) => a + b?.quantity, 0)).toFixed(2),
            currency: items?.[0]?.currency,
            engagements: [
              items?.map(({ engagementId, engagementTitle, clientId, clientName, rateType, rate }) => ({
                engagementId,
                engagementTitle,
                clientId,
                clientName,
                rateType,
                rate,
              })),
            ],
          },
        ]
      : [];
  };

  const regularItems = supplier?.items?.filter(i => isRegular(i?.type));
  const regular = toTyped(regularItems, PayableItemType.Regular, 'hourly');

  const overTimeItems = supplier?.items?.filter(i => isOvertime(i?.type));
  const overTime = toTyped(overTimeItems, PayableItemType.Overtime, 'hourly');

  const doubleTimeItems = supplier?.items?.filter(i => isDoubleTime(i?.type));
  const doubleTime = toTyped(doubleTimeItems, PayableItemType.DoubleTime, 'hourly');

  const rest = supplier?.items?.filter(i => !isHourly(i?.type));

  return [...regular, ...overTime, ...doubleTime, ...rest];
};

const renderRate = (d, currency) => isHourly && `${currency?.symbol}${d?.rate || 0}${mapCompensationTypeToPaymentLabel(d?.rateType, true)}`;

const renderHours = d => isHourly(d?.type) && `${Number(d?.quantity).toFixed(2)}`;

export default PayrollSupplier;
