import React, { useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';
import * as Icons from 'react-feather';

import Audit from '../../../../../../../components/audit';
import Box from '../../../../../../../components/box';

import { genderStatusToLabel } from '../../../../../../../domain/gender';
import { maritalStatusToLabel } from '../../../../../../../domain/marital-status';
import { ClassificationType, classificationTypeToLabel } from '../../../../../../../domain/classification-type';

import { getDateOfBirth, getFullName } from '../../../../../../../utilities/user';
import { extractCountryFromAddress } from '../../../../../../../utilities/location';

import WithConfirmation from '../../../../../../../hooks/with-confirmation';

import useApi from '../../../../../../../hooks/use-api';
import { listBankAccounts, removeBankAccount } from '../../../../../../../apis/banking';
import { getUser } from '../../../../../../../apis/platform/users';

import AddressForm from './forms/address-form';
import BankingForm from './forms/banking-form';
import CompanyInfoForm from './forms/company-info-form';
import EmailAddressForm from './forms/email-address-form';
import EmergencyContactForm from './forms/emergency-contact-form';
import PersonalInfoForm from './forms/personal-info-form';

import classes from '../../details.module.scss';

async function resolveGetBankAccountsApi(supplier) {
  const isContractor = supplier?.supplierType === ClassificationType.IndependentContractor;
  const userId = isContractor ? undefined : supplier?.managerUserId;
  const organizationId = isContractor ? supplier.id : undefined;
  return listBankAccounts({ userId, organizationId });
}

const General = ({ api = {} }) => {
  const supplier = api.response;
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const getUserApi = useApi(() => getUser({ userId: supplier?.managerUserId }), { skip: !supplier?.managerUserId });
  const listBankAccountsApi = useApi(() => resolveGetBankAccountsApi(supplier), { skip: !supplier?.managerUserId });
  const loading = api.loading;

  const openDialog = (type, entity) => setDialog({ open: true, type, entity });
  const closeDialog = () => setDialog({ open: false, type: undefined, entity: undefined });

  const handleRemoveBankAccount = async id => {
    await removeBankAccount(id);
    void listBankAccountsApi.request(supplier);
  };

  const user = getUserApi?.response?.user;
  const isContractor = supplier?.supplierType === ClassificationType.IndependentContractor;

  if (supplier && getUserApi.loading === false && !user) {
    return (
      <Box title="Company Information" outlined monochrome loading={loading}>
        <Box.TabularItem>
          <Box.ItemLabel fill>Invited user has not accepted the invitation yet. Please wait for the user to accept the invitation.</Box.ItemLabel>
        </Box.TabularItem>
      </Box>
    );
  }

  return (
    <div className={classes.tab}>
      {isContractor && (
        <Box
          title="Company Information"
          actions={
            <ButtonGroup>
              <Button
                small
                outlined
                icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
                title={'Update company information'}
                onClick={() => openDialog('company-info')}
              />
              <Audit id={supplier.id} type="supplier" icon small outlined />
            </ButtonGroup>
          }
          outlined
          monochrome
          loading={loading}
        >
          <Box.Item label="Business name" notProvidedText="-">
            {supplier?.name}
          </Box.Item>
          <Box.Item label="Manager" notProvidedText="-">
            {supplier.managerName}
          </Box.Item>
          <Box.Item label="Classification" notProvidedText="-">
            {classificationTypeToLabel(supplier?.supplierType)}
          </Box.Item>
          <Box.Item label="Incorporation type">{supplier?.incorporationType}</Box.Item>
          <Box.Item label="Tax identifier">{supplier?.taxIdentifier}</Box.Item>
          <Box.Item label="Address">
            {supplier?.address?.prettyName || '-'}
            <ButtonGroup>
              <Button
                small
                outlined
                icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
                title="Update address"
                onClick={() => openDialog('address', supplier)}
              />
              <Audit id={supplier?.address?.id} type="address" icon small outlined />
            </ButtonGroup>
          </Box.Item>
          <Box.Item label="Phone">{supplier?.phone}</Box.Item>
          <Box.Item label="Website">{supplier?.website}</Box.Item>
        </Box>
      )}
      <Box
        title={isContractor ? 'Manager Information' : 'Supplier Information'}
        loading={loading || getUserApi.loading}
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title={isContractor ? 'Update manager personal information' : 'Update supplier personal information'}
              onClick={() => openDialog('personal-info')}
            />
            <Audit id={user?.personalDetails?.id} type="personalDetails" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
      >
        <Box.Item label="Name" notProvidedText="-">
          {getFullName(user)}
          <NavLink role="button" to={`/users/${user?.id}`}>
            <Button small outlined icon={<Icons.User size={16} strokeWidth={1.5} />} title={'User profile'}></Button>
          </NavLink>
        </Box.Item>
        {!isContractor && <Box.Item label="Classification">{classificationTypeToLabel(supplier?.classification)}</Box.Item>}
        <Box.Item label="Email address">{user?.email}</Box.Item>
        <Box.Item label="Date of birth">{getDateOfBirth(user)}</Box.Item>
        <Box.Item label="Gender">{genderStatusToLabel(user?.personalDetails?.gender)}</Box.Item>
        <Box.Item label="Marital status">{maritalStatusToLabel(user?.personalDetails?.maritalStatus)}</Box.Item>
        <Box.Item label="Address">
          {user?.personalDetails?.address?.prettyName || 'Not provided'}
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update address" onClick={() => openDialog('address', user)} />
            <Audit id={user?.personalDetails?.addressId} type="address" icon small outlined />
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Phone">{user?.personalDetails?.phone}</Box.Item>
        <Box.Item label="National id number (ssn, nic, ...)">{user?.personalDetails?.nationalIdentificationNumber}</Box.Item>
        <Box.Item label="Ethnicity">{user?.personalDetails?.ethnicity}</Box.Item>
      </Box>
      <Box
        title="Emergency Contact"
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update Emergency contact"
              onClick={() => openDialog('emergency-contact')}
            />
            <Audit id={user?.emergencyContact?.id} type="emergencyContact" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
        loading={loading || getUserApi.loading}
      >
        <Box.Item label="First name">{user?.emergencyContact?.firstName}</Box.Item>
        <Box.Item label="Last name">{user?.emergencyContact?.lastName}</Box.Item>
        <Box.Item label="Relationship">{user?.emergencyContact?.relationship}</Box.Item>
        <Box.Item label="Phone">{user?.emergencyContact?.phone}</Box.Item>
        <Box.Item label="Email address">{user?.emergencyContact?.email}</Box.Item>
      </Box>
      <Box
        title="Banking"
        loading={loading || listBankAccountsApi.loading}
        outlined
        monochrome
        actions={
          <ButtonGroup>
            <Button small outlined icon={<Icons.Plus size={16} strokeWidth={1.5} />} title={'Add new bank account'} onClick={() => openDialog('billing')} />
          </ButtonGroup>
        }
      >
        {!listBankAccountsApi?.response?.length ? (
          <Box.ItemContainer>
            <Box.ItemLabel fill>No bank accounts set up.</Box.ItemLabel>
          </Box.ItemContainer>
        ) : (
          listBankAccountsApi?.response?.map(bank => (
            <Box.ItemContainer key={bank.id}>
              <Box.ItemValue>{bank.accountName}</Box.ItemValue>
              <ButtonGroup>
                {bank.isPrimary && <Button small outlined icon={<Icons.Award size={16} strokeWidth={1.5} />} title="This is your primary bank account" />}
                <Button
                  small
                  outlined
                  icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
                  title="Update bank account"
                  onClick={() => openDialog('billing', bank)}
                />
                <WithConfirmation disabled={bank?.isPrimary} messages={{ question: `Remove bank account: (${bank.accountName}) ?`, confirmButton: 'Remove' }}>
                  <Button
                    small
                    outlined
                    disabled={bank?.isPrimary}
                    onClick={() => handleRemoveBankAccount(bank.id)}
                    icon={<Icons.X size={16} strokeWidth={1.5} />}
                    title="Remove bank account"
                  />
                </WithConfirmation>
              </ButtonGroup>
            </Box.ItemContainer>
          ))
        )}
      </Box>

      <CompanyInfoForm
        isOpen={!!dialog?.open && dialog?.type === 'company-info'}
        supplier={supplier}
        onClose={closeDialog}
        onSave={() => {
          void api.request(supplier?.id);
          closeDialog();
        }}
      />
      <PersonalInfoForm
        isOpen={!!dialog?.open && dialog?.type === 'personal-info'}
        user={user}
        onClose={closeDialog}
        onSave={() => {
          void getUserApi.request();
          closeDialog();
        }}
      />
      <AddressForm
        isOpen={!!dialog?.open && dialog?.type === 'address'}
        user={user}
        owner={dialog?.entity}
        onClose={closeDialog}
        onSave={isOwnerUser => {
          if (isOwnerUser) {
            void getUserApi.request();
          } else {
            void api.request(supplier?.id);
          }
          closeDialog();
        }}
      />
      <EmailAddressForm isOpen={!!dialog?.open && dialog?.type === 'email-address'} user={user} onClose={closeDialog} />
      <EmergencyContactForm
        isOpen={!!dialog?.open && dialog?.type === 'emergency-contact'}
        user={user}
        onClose={closeDialog}
        onSave={() => {
          void getUserApi.request();
          closeDialog();
        }}
      />
      <BankingForm
        isOpen={!!dialog?.open && dialog?.type === 'billing'}
        owner={{
          organizationId: isContractor ? supplier.id : undefined,
          userId: isContractor ? undefined : supplier?.managerUserId,
        }}
        countryId={extractCountryFromAddress(isContractor ? supplier?.address : user?.personalDetails?.address)}
        bank={dialog?.entity}
        onClose={closeDialog}
        onSave={() => {
          void listBankAccountsApi.request(supplier);
          closeDialog();
        }}
      />
    </div>
  );
};

export default General;
