import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Fields from '../../../../../../fields';
import Form from '../../../../../../form';

import { SystemContext } from '../../../../system-provider';
import { createRecord, updateRecord } from '../../../../../../../apis/configuration';

import useApi from '../../../../../../../hooks/use-api';
import { createOrUpdateRecurringJob, getJobDestination } from '../../../../../../../apis/scheduler';

const ScheduledRecurringTask = ({ record, onClose, setLoading, api, data, ...rest }) => {
  const { selectedScope } = useContext(SystemContext);

  const jobId = buildJobIdentifier(record, selectedScope);
  const getJobDestinationApi = useApi(getJobDestination, { params: { type: getTaskDestinationType(record) }, skip: !record });

  const loading = rest.loading || getJobDestinationApi.loading;

  const submit = async values => {
    const payload = {
      ...values,
      data: {
        ...values.data,
        cronExpression: values.data?.cronExpression,
        timezone: values.data?.timezone,
      },
    };

    try {
      setLoading(true);
      await createOrUpdateRecurringJob({
        name: jobId,
        destination: getJobDestinationApi?.response,
        cron: payload.data.cronExpression,
        timeZoneId: payload.data.timezone,
        data: getTaskData(record, selectedScope),
      });

      if (payload?.id) {
        await updateRecord(values?.id, payload);
      } else {
        await createRecord(payload);
      }

      await api?.request();
      setLoading(false);
      onClose();
    } catch {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        ...record,
        data: {
          cronExpression: data?.cronExpression,
          timezone: data?.timezone || 'UTC',
        },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.mixed().required('Scope is required'),
        data: Yup.object().shape({
          cronExpression: Yup.string().required('Expression is required'),
          timezone: Yup.string().required('Timezone is required'),
        }),
      })}
      onSubmit={submit}
      enableReinitialize
    >
      <React.Fragment>
        <Fields.CronConfig name="data.cronExpression" label="Expression" />
        <Fields.Timezone name="data.timezone" label="Timezone" />
        <Button outlined fill type="submit" text="Submit" loading={loading} />
      </React.Fragment>
    </Form>
  );
};

const buildJobIdentifier = (record, selectedScope) => {
  const type = record?.configurationType?.replace?.('Tasks|', '');
  const scope = selectedScope?.id;

  return `${type}-${scope}`;
};

const getTaskData = (record, selectedScope) => {
  switch (record?.configurationType?.replace('Tasks|', '')) {
    case 'AdministrativeFetchPayrollReportsCadence':
      return {
        name: record?.configurationType,
      };

    case 'AdministrativeTerminateEngagementsCadence':
      return {
        name: record?.configurationType,
      };

    case 'AdministrativeUpdateEngagementsCadence':
      return {
        name: record?.configurationType,
      };

    case 'SubmitWeeklyTrackingRecords':
      return {
        name: record?.configurationType,
        body: { periodType: 'weekly' },
      };

    case 'SubmitMonthlyTrackingRecords':
      return {
        name: 'Tasks|SubmitWeeklyTrackingRecords', //record?.configurationType,
        body: { periodType: 'monthly' },
      };

    case 'ApproveWeeklyTrackingRecords':
      return {
        name: record?.configurationType,
        body: { periodType: 'weekly' },
      };

    case 'ApproveMonthlyTrackingRecords':
      return {
        name: 'Tasks|ApproveWeeklyTrackingRecords', //record?.configurationType,
        body: { periodType: 'monthly' },
      };

    case 'ClientWeeklyTimeApprovalReminder':
      return {
        name: record?.configurationType,
        body: { clientId: selectedScope?.organizationId, periodType: 'weekly' },
      };

    case 'ClientMonthlyTimeApprovalReminder':
      return {
        name: 'Tasks|ClientWeeklyTimeApprovalReminder', //record?.configurationType,
        body: { clientId: selectedScope?.organizationId, periodType: 'monthly' },
      };

    case 'SupplierWeeklyTimeReminder':
      return {
        name: record?.configurationType,
        body: { periodType: 'weekly' },
      };

    case 'SupplierMonthlyTimeReminder':
      return {
        name: 'Tasks|SupplierWeeklyTimeReminder', //record?.configurationType,
        body: { periodType: 'monthly' },
      };

    default:
      return { name: record?.configurationType };
  }
};

const getTaskDestinationType = record => {
  switch (record?.configurationType?.replace('Tasks|', '')) {
    case 'AdministrativeFetchPayrollReportsCadence':
      return 'integrations-bulk-payroll-queue';

    case 'AdministrativeTerminateEngagementsCadence':
    case 'AdministrativeUpdateEngagementsCadence':
    case 'SubmitWeeklyTrackingRecords':
    case 'SubmitMonthlyTrackingRecords':
    case 'ApproveWeeklyTrackingRecords':
    case 'ApproveMonthlyTrackingRecords':
    case 'ClientWeeklyTimeApprovalReminder':
    case 'ClientMonthlyTimeApprovalReminder':
    case 'SupplierWeeklyTimeReminder':
    case 'SupplierMonthlyTimeReminder':
      return 'functions';

    default:
      return undefined;
  }
};

export default ScheduledRecurringTask;
