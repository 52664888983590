import moment from 'moment';

import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';

export const getTimeRecords = async ({ start, end }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time${toQueryString({ start, end })}`);
  return response?.data;
};

export const getTimeRecordGroups = async ({ filters, page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    page,
    pageSize,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/groups${toQueryString(parameters)}`);
  return response?.data;
};

export const exportTimeRecords = async filters => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/export${toQueryString(parameters)}`);
  return response?.data;
};

export const createShift = async shift => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/shift`, shift);
  return response?.data;
};

export const copyShifts = async (source, destination) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/copy/${source}/${destination}`);
  return response?.data;
};

export const updateShift = async shift => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/time/shift/${shift?.id}`, shift);
  return response?.data;
};

export const createDeliverable = async deliverable => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/deliverable`, deliverable);
  return response?.data;
};

export const updateDeliverable = async deliverable => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/time/deliverable/${deliverable?.id}`, deliverable);
  return response?.data;
};

export const createExpense = async ({ date, amount, notes, receipt, engagementId, userId }, onUploadProgress = () => null) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('date', date);
  data.append('amount', amount);
  data.append('notes', notes);
  data.append('receipt', receipt);
  data.append('engagementId', engagementId);
  data.append('userId', userId);
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/expense`, data, { onUploadProgress });
  return response?.data;
};

export const updateExpense = async ({ id, date, amount, notes, receipt, engagementId, userId }, onUploadProgress = () => null) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('date', date);
  data.append('amount', amount);
  data.append('notes', notes);
  data.append('receipt', receipt);
  data.append('engagementId', engagementId);
  data.append('userId', userId);
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/time/expense/${id}`, data, { onUploadProgress });
  return response?.data;
};

export const removeTrackingRecord = async recordId => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${organizationId}/time/${recordId}`);
  return response?.data;
};

export const getRecordComments = async recordId => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/${recordId}/comments`);
  return response?.data;
};

export const createRecordComment = async ({ recordId, message }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/${recordId}/comment`, { recordId, message });
  return response?.data;
};

export const updateRecordComment = async ({ commentId, recordId, message }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/time/${recordId}/comment/${commentId}`, { recordId, message });
  return response?.data;
};

export const removeRecordComment = async ({ commentId, recordId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${organizationId}/time/${recordId}/comment/${commentId}`);
  return response?.data;
};
