import React from 'react';

import useDocuments from '../../state/use-documents';
import { overrideTemplate } from '../../../../../apis/templates';
import Dialog from '../../../../dialog';
import FileDropzone from '../../../../fields/file/file-dropzone';

const ImportDialog = ({ open, toggle }) => {
  const { template } = useDocuments();
  const handleUpload = async file => {
    const formData = new FormData();
    formData.append('file', file);
    // todo change name of the function and the api path
    await overrideTemplate(template.id, formData);
    // todo find a way to refresh the stream and template using loadTemplate / reloadTemplate
    // await loadTemplate() // reloadTemplate();
    toggle();
  };

  return (
    <Dialog title="Import Template" isOpen={open} onClose={toggle}>
      <FileDropzone extensions=".json" onDrop={handleUpload} />
    </Dialog>
  );
};

export default ImportDialog;
