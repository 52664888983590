import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../dialog';
import Form, { Field } from '../../../../../form';

import useApi from '../../../../../../hooks/use-api';
import { createBankAccount, getBankAccount, updateBankAccount } from '../../../../../../apis/banking';

import BankAccountField from '../../../../../fields/bank-account/bank-account';

import classes from './renderers.module.scss';

const BankAccount = ({ name, label, countryId, owner, isDesigner }) => {
  return (
    <Field label={label} name={name}>
      {({ field, form }) => {
        return (
          <BankAccountDialog
            value={field.value}
            countryId={countryId}
            owner={owner}
            isDesigner={isDesigner}
            onChange={value => form.setFieldValue(name, value)}
            onError={error => form.setFieldError(name, error)}
          />
        );
      }}
    </Field>
  );
};

const BankAccountDialog = ({ value, countryId, owner, isDesigner, onChange, onError }) => {
  const [isOpen, setIsOpen] = useState(false);
  const createOrUpdateApi = useApi(values => (values.id ? updateBankAccount(values.id, values) : createBankAccount(values)), { skip: true });
  const getBankApi = useApi(getBankAccount, { skip: !value, params: value });

  const submit = async values => {
    const res = await createOrUpdateApi.request(values);
    if (res.error) {
      onError('Error while creating bank account');
      return;
    }
    await getBankApi.request(res.id);
    setIsOpen(false);
    onChange(res.id);
  };

  const btnText = getBankApi.response?.accountName ?? 'Setup bank account';
  const loading = getBankApi.loading || createOrUpdateApi.loading;
  return (
    <div>
      <Button
        fill
        className={classes.bankAccountButton}
        alignText="left"
        icon="bank-account"
        text={btnText}
        loading={getBankApi.loading}
        onClick={() => setIsOpen(true)}
      />
      <Dialog title="Bank account settings" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Form
          initialValues={{
            countryId,
            ...getBankApi.response,
            ...owner,
          }}
          enableReinitialize
          onSubmit={submit}
        >
          <BankAccountField countryId={countryId} readonly={isDesigner} />
          <Button type="submit" fill outlined text="Submit" loading={loading} />
        </Form>
      </Dialog>
    </div>
  );
};

export default BankAccount;
