import React, { useState } from 'react';
import moment from 'moment';
import { Button, Popover } from '@blueprintjs/core';
import { DatePicker3 as BlueprintDatePicker } from '@blueprintjs/datetime2';
import cn from 'classnames';
import * as Icons from 'react-feather';

import { calculateInitMonth, parseDate } from './utilities';

import styles from './date.module.scss';

const DatePicker = ({
  className,
  value,
  disabled,
  placeholder,
  format = 'MM/DD/YYYY',
  minDate = moment().subtract(100, 'years').toDate(),
  maxDate = moment().add(100, 'years').toDate(),
  modifiers,
  canClearSelection = false,
  rightIcon,
  handleChange,
  buttonProps,
  dayPickerProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onChange = (date, isDayClicked) => {
    if (isDayClicked) {
      setIsOpen(false);
    }
    const formattedDate = date ? moment(date).format(format) : undefined;
    handleChange?.(formattedDate);
  };

  const parsed = parseDate(value, format);
  const text = !!parsed ? moment(parsed).format(buttonProps?.format || 'LL') : placeholder || 'Select date';
  const initMonth = calculateInitMonth(value, minDate, maxDate, format);
  const _rightIcon = rightIcon || 'caret-down';
  return (
    <Popover
      fill
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      captureDismiss={false}
      popoverClassName={styles.popover}
      content={
        <BlueprintDatePicker
          onChange={onChange}
          defaultValue={parsed}
          initialMonth={initMonth}
          minDate={minDate}
          maxDate={maxDate}
          modifiers={modifiers}
          canClearSelection={canClearSelection}
          dayPickerProps={dayPickerProps}
        />
      }
    >
      <Button
        fill
        className={cn(styles.dateButton, className)}
        text={text}
        icon={<Icons.Calendar size={16} strokeWidth={1.5} />}
        rightIcon={_rightIcon}
        alignText="left"
        {...buttonProps}
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      />
    </Popover>
  );
};

export default DatePicker;
