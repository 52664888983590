import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import NavigationMenu from '../menu';
import NavigationLink from '../link';

import classes from './container.module.scss';

const NavigationContainer = ({ children, user, membership }) => {
  const routes = [];
  const menu = [];

  let _index;
  React.Children.forEach(children, (child, index) => {
    if (child.type?.displayName === 'NavigationItem') {
      const { path, component, title, icon, index, condition } = child.props;

      const satisfied = condition ? condition?.({ membership, user }) : true;
      if (satisfied) {
        routes.push(<Route index={index} path={path} Component={component} key={path} />);

        if (index) {
          _index = child;
        }
        if (title) {
          const formattedPath = path.replace(/\*/g, '');
          menu.push(<NavigationLink link={{ to: formattedPath, title, icon }} key={path} />);
        }
      }
    } else {
      menu.push(child);
    }
  });
  if (_index) {
    routes.push(<Route path="*" Component={() => <Navigate to={_index.props.path} replace />} key={`${_index.props.path}-index`} />);
  }

  return (
    <div className={classes.container}>
      <NavigationMenu user={user}>{menu}</NavigationMenu>
      <div className={classes.content}>
        <Routes>{routes}</Routes>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
  membership: state.identity.membership,
});

export default connect(mapStateToProps)(NavigationContainer);
