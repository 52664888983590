import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../components/fields';
import Form from '../../../../components/form';
import useApi from '../../../../hooks/use-api';

import { generatePayroll } from '../../../../apis/platform/payroll';

const GeneratePayrollForm = ({ onComplete }) => {
  const generatePayrollApi = useApi(generatePayroll, { skip: true });

  const submit = async values => {
    await generatePayrollApi.request(values);
    onComplete();
  };

  return (
    <Form
      initialValues={{
        periodType: '',
        period: '',
        regenerate: false,
      }}
      validationSchema={Yup.object().shape({
        periodType: Yup.string().required('Period type is required'),
        period: Yup.mixed().required('Period is required'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {form => {
        const loading = generatePayrollApi.loading;
        return (
          <React.Fragment>
            <Fields.Select
              label="Period Type"
              name="periodType"
              options={[
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
            />
            <Fields.Select key={form.values.periodType} label="Period" name="period" options={getPeriods(form.values.periodType)} />
            <Fields.Checkbox label="Regenerate existing payrolls ?" name="regenerate" />
            <Button type="submit" outlined fill text="Submit" disabled={loading} loading={loading} />
          </React.Fragment>
        );
      }}
    </Form>
  );
};

const getPeriods = periodType => {
  switch (periodType) {
    case 'weekly':
      const weeks = [];
      for (let i = 0; i < 52; i++) {
        var refWeek = moment().add(i * -7, 'days');
        weeks.push({
          label: `${refWeek.clone().startOf('isoWeek').format('MM/DD')} - ${refWeek.clone().endOf('isoWeek').format('MM/DD/YYYY')}`,
          value: refWeek.clone().startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss'),
        });
      }
      return weeks;

    case 'monthly':
      const months = [];
      for (let i = 0; i < 12; i++) {
        var refMonth = moment().add(i * -1, 'months');
        months.push({
          label: `${refMonth.clone().startOf('month').format('MMMM YYYY')}`,
          value: refMonth.clone().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        });
      }
      return months;

    default:
      return null;
  }
};

export default GeneratePayrollForm;
