import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Form from '../../../../../../../../components/form';
import Fields from '../../../../../../../../components/fields';

import { mapCompensationTypeToPaymentLabel } from '../../../../../../../../utilities/engagement';

import { CompensationFrequencyOptions } from '../../../../../../../../domain/compensation-frequency';
import { CompensationTypeOptions } from '../../../../../../../../domain/compensation-type';
import { ClassificationType } from '../../../../../../../../domain/classification-type';

import useApi from '../../../../../../../../hooks/use-api';
import { getWizardLookupData } from '../../../../../../../../apis/wizards';
import { updateEngagementCompensation, updateEngagementCompensationScheduled } from '../../../../../../../../apis/platform/engagements';

function resolveEngagementCompensationApi({ engagementId, compensation, date }) {
  return date
    ? updateEngagementCompensationScheduled({
        engagementId,
        compensation,
        date,
      })
    : updateEngagementCompensation({ engagementId, compensation });
}

const PaymentDetailsForm = ({ isOpen = false, onClose, onSave, engagement }) => {
  const lookupApi = useApi(getWizardLookupData, { skip: true });
  const updateCompensationApi = useApi(resolveEngagementCompensationApi, { skip: true });

  const submit = async values => {
    const { ...compensation } = values;
    await updateCompensationApi.request({ engagementId: engagement.id, compensation });
    onSave();
  };

  const loading = updateCompensationApi.loading;

  return (
    <Dialog title="Payment details" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          amount: engagement?.compensation?.amount,
          currencyId: engagement?.compensation?.currencyId,
          allocation: engagement?.compensation?.allocation,
          type: engagement?.compensation?.type,
          frequency: engagement?.compensation?.frequency,
          isExempt: engagement?.compensation?.isExempt,
          tracksWork: engagement?.compensation?.tracksWork,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          type: Yup.string().required('Payment type is required'),
          currencyId: Yup.string().required('Currency is required'),
          frequency: Yup.string().required('Frequency is required'),
          allocation: Yup.number().moreThan(0, 'Time allocation is required'),
          amount: Yup.number().moreThan(0, 'Amount is required'),
        })}
        onSubmit={submit}
      >
        {({ values }) => (
          <React.Fragment>
            <Fields.Select label="Payment type" options={CompensationTypeOptions} name="type" />
            <Fields.Text label="Time allocation" extra={{ rightElement: <Button text="h/week" minimal small disabled /> }} type="number" name="allocation" />
            <Fields.Select
              label="Currency"
              name="currencyId"
              remoteOptions={{
                loading: lookupApi.loading,
                request: () => lookupApi.request('client-available-currencies', { clientId: engagement?.clientId }),
                mapper: result => result,
                filter: 'local',
              }}
            />
            <Fields.Text
              label="Amount"
              extra={{
                leftElement: <Button text={engagement?.compensation?.currency?.symbol} minimal small disabled />,
                rightElement: <Button text={mapCompensationTypeToPaymentLabel(values.type, true)} minimal small disabled />,
              }}
              placeholder="Amount"
              type="number"
              name="amount"
            />
            <Fields.Select label="Frequency" options={CompensationFrequencyOptions} name="frequency" />
            {engagement?.classification?.result === ClassificationType.Employee && (
              <Fields.Select
                label="Is exempt ?"
                options={[
                  { value: true, label: 'Supplier is exempt employee.' },
                  { value: false, label: 'Supplier is non-exempt employee.' },
                ]}
                name="isExempt"
              />
            )}
            <Fields.Select
              label="Tracks work ?"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              name="tracksWork"
            />
            <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
          </React.Fragment>
        )}
      </Form>
    </Dialog>
  );
};

export default PaymentDetailsForm;
