import React from 'react';
import { Button, ButtonGroup, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../box';
import WithConfirmation from '../../../../hooks/with-confirmation';

import useApi from '../../../../hooks/use-api';
import { createIntegration, getIntegrations, enableIntegration, disableIntegration } from '../../../../apis/platform/organizations';

import classes from './integrations.module.scss';

const Integrations = ({ clientId }) => {
  const getApi = useApi(readable => getIntegrations({ clientId, readable }));
  const setupApi = useApi(() => createIntegration({ clientId }), { skip: true });

  const readable = !getApi.response?.secret?.startsWith('*');

  const setupIntegration = async () => {
    await setupApi.request({ clientId });
    void getApi.request(false);
  };

  const toggleSecret = async () => {
    void getApi.request(!readable);
  };

  const toggleStatus = async () => {
    if (getApi.response?.enabled) {
      await disableIntegration({ clientId });
    } else {
      await enableIntegration({ clientId });
    }
    void getApi.request(false);
  };

  return (
    <Box
      title="Integrations"
      actions={
        <ButtonGroup>
          <Popover
            content={
              <Menu>
                <MenuItem text={!!getApi.response?.id ? 'Re-generate credentials' : 'Generate credentials'} onClick={setupIntegration} />
                <MenuItem text={!!readable ? 'Hide secret' : 'Show secret'} onClick={toggleSecret} disabled={!getApi.response?.id} />
                <MenuDivider />
                <WithConfirmation icon={true}>
                  <MenuItem
                    text={!!getApi.response?.enabled ? 'Disable integration' : 'Enable integration'}
                    onClick={toggleStatus}
                    disabled={!getApi.response?.id}
                    shouldDismissPopover={false}
                  />
                </WithConfirmation>
              </Menu>
            }
          >
            <Button small outlined icon={<Icons.MoreHorizontal size={16} strokeWidth={1.5} />} title="Setup integration" loading={setupApi.loading} />
          </Popover>
        </ButtonGroup>
      }
      outlined
      monochrome
      loading={getApi.loading}
    >
      <Box.Item>
        <Box.ItemLabel>Client identifier</Box.ItemLabel>
        <Box.ItemValue className={classes.code}>{clientId}</Box.ItemValue>
      </Box.Item>
      {!!getApi.response?.secret && (
        <Box.Item>
          <Box.ItemLabel>Client secret</Box.ItemLabel>
          <Box.ItemValue className={classes.code}>{getApi.response?.secret}</Box.ItemValue>
        </Box.Item>
      )}
      <Box.Item>
        <Box.ItemLabel>Status</Box.ItemLabel>
        <Box.ItemValue>{getApi.response?.enabled ? 'Enabled' : 'Disabled'}</Box.ItemValue>
      </Box.Item>
    </Box>
  );
};

export default Integrations;
