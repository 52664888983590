import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import styles from './file.module.scss';
import { validateFile } from './validations';
import CropZone from './crop-zone';

const getMessage = error => (
  <div className={styles.message}>
    {!!error && <div className={styles.error}>{error}</div>}
    <div className={styles.regular}>Drag and drop file here, or click to select file.</div>
  </div>
);

const FileDropzone = ({ extensions, mimeTypes, maxSize, crop, onDrop }) => {
  const [file, setFile] = useState(null);
  // 0   - initial stage
  // 100 - uploading & loading stage
  // 200 - successfully uploaded
  // 400 - pre-upload validation failed
  // 500 - server error / upload failed
  const [stage, setStage] = useState({ success: undefined, loader: false, disabled: false, message: getMessage() });
  // const setInitialStage = () => setStage({ success: undefined, loader: false, disabled: false, message: getMessage() });
  const setLoadingStage = () => setStage({ success: undefined, loader: true, disabled: true, message: null });
  const setSuccessStage = (message = null) => setStage({ success: true, loader: true, disabled: true, message });
  const setPreUploadFailedStage = error => setStage({ success: false, loader: true, disabled: false, message: getMessage(error) });
  const setFailedStage = error => setStage({ success: false, loader: true, disabled: false, message: getMessage(error || 'Unable to upload selected file.') });

  const handleOnDrop = useCallback(
    async files => {
      if (files?.length) {
        if (crop) {
          setFile(files[0]);
          return;
        }

        const file = files[0];
        try {
          setLoadingStage();
          await onDrop(file);
          setSuccessStage(file.name);
        } catch (ex) {
          const error = ex?.response?.data?.message || ex?.message || ex;
          setFailedStage(error);
        }
      }
    },
    [onDrop],
  );

  const handleUpload = async croppedFile => {
    try {
      setLoadingStage();
      await onDrop(croppedFile);
      setSuccessStage(file.name);
    } catch (ex) {
      const error = ex?.response?.data?.message || ex?.message || ex;
      setFailedStage(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onFileDialogOpen: () => {},
    maxFiles: 1,
    onDropRejected: fileErrors => {
      setPreUploadFailedStage(fileErrors?.map?.(fe => fe?.errors?.map?.(error => error.message)?.join?.(' '))?.join?.(' '));
    },
    onDrop: handleOnDrop,
    disabled: stage?.disabled,
    validator: validateFile(extensions, mimeTypes, maxSize),
  });

  if (file) {
    return <CropZone stage={stage} file={file} config={crop} onUpload={handleUpload} />;
  }

  const rootProps = file ? null : getRootProps();
  const inputProps = file ? null : getInputProps();

  return (
    <div {...rootProps} className={classNames(styles.zone, !!stage?.disabled && styles.disabled)}>
      <input {...inputProps} />
      {!!stage?.loader && (
        <div className={styles.progress}>
          <div className={classNames(styles.loader, stage.success !== undefined && styles.complete)}>
            <div
              className={classNames(!stage.success ? styles.cross : styles.check, styles.draw)}
              style={{ display: stage.success !== undefined ? 'block' : 'none' }}
            ></div>
          </div>
        </div>
      )}
      {stage?.message}
    </div>
  );
};

export default FileDropzone;
