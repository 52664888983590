import React, { useState } from 'react';
import moment from 'moment';
import { Button, Drawer, Menu, MenuItem, MenuDivider, Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Form from '../../../../components/form';
import Fields from '../../../../components/fields';
import Dialog from '../../../../components/dialog';

import { abbreviateClassification } from '../../../../domain/classification-type';

import AddPayrollItem from '../forms/add-payroll-item';
import PayrollSupplier from './payroll-supplier';

import classes from './details.module.scss';

const PayrollDetails = ({ onClose, open, payrollGroup, reloadList, onExport, onRemovePayroll, onPay }) => {
  const [dialog, setDialog] = useState({ open: false, initialValues: undefined });
  const [query, setQuery] = useState('');
  const { currency, period, periodType, payrolls, classification } = payrollGroup || {};
  const filterPayrolls = payroll => payroll?.supplierUser?.toLowerCase()?.includes(query?.toLowerCase());

  const closeDrawer = () => {
    setQuery('');
    onClose();
  };

  const renderTitle = () =>
    periodType === 'weekly'
      ? `Week ${moment(period).format('Do MMMM')} - ${moment(period).add(6, 'days').format('Do MMMM YYYY')} (${abbreviateClassification(classification)})`
      : `${moment(period).format('MMMM YYYY')} (${abbreviateClassification()})`;

  return (
    <Drawer isOpen={!!open} onClose={closeDrawer} className={classes.details} position="right" canEscapeKeyClose canOutsideClickClose>
      <div className={classes.header}>
        <div className={classes.title}>{renderTitle()}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={closeDrawer} />
      </div>
      <div className={classes.controls}>
        <Form initialValues={{ query }} enableReinitialize onSubmit={({ query }) => setQuery(query)}>
          <Fields.Text
            name="query"
            placeholder="Search..."
            submitOnChange
            outline={false}
            minimal={true}
            showError={false}
            extra={{ rightElement: !!query && <Button icon="small-cross" minimal small onClick={() => setQuery('')} /> }}
          />
        </Form>
        <Popover
          content={
            <Menu>
              <MenuItem text="Add" onClick={() => setDialog({ open: true, initialValues: {} })} />
              <MenuDivider />
              <MenuItem text="Pay" onClick={() => onPay(payrollGroup?.payrolls?.map?.(p => p?.payrollId))} />
              <MenuItem text="Export" label=".csv" onClick={onExport} />
            </Menu>
          }
        >
          <Button outlined icon={<Icons.MoreVertical size={16} />} />
        </Popover>
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          {payrolls?.filter?.(filterPayrolls)?.map?.(supplier => (
            <PayrollSupplier key={supplier?.supplierId} {...{ supplier, currency, onRemovePayroll, setDialog, onClose, payrolls, reloadList }} />
          ))}
        </div>
      </SimpleBar>
      <div className={classes.footer}>
        <div className={classes.label}>Total</div>
        <div className={classes.value} title={payrollGroup?.total}>{`${currency?.symbol}${Number.parseFloat(`${payrollGroup?.total || 0}`).toFixed(2)}`}</div>
      </div>
      <Dialog
        isOpen={!!dialog?.open}
        onClose={() => setDialog({ open: false, initialValues: undefined })}
        title={dialog?.initialValues?.id ? 'Edit payroll item' : 'Add payroll item'}
      >
        <AddPayrollItem
          initialValues={dialog?.initialValues}
          payrollGroup={payrollGroup}
          onComplete={() => {
            reloadList();
            setDialog({ open: false, initialValues: undefined });
          }}
        />
      </Dialog>
    </Drawer>
  );
};

export default PayrollDetails;
