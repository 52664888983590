import React from 'react';
import moment from 'moment';

import Avatar from '../../../../../components/avatar';
import Box from '../../../../../components/box';
import Table from '../../../../../components/table';

import useTable from '../../../../../hooks/use-table';
import { getExpiringEngagements } from '../../../../../apis/client/engagements';

import classes from '../../dashboard.module.scss';

const ExpiringBox = ({ membership, setDrawer }) => {
  const { items, loading, pageCount, page, goToPage, reload } = useTable(getExpiringEngagements, { pageSize: 5 });

  return (
    <Box
      title={'Expiring contracts'}
      className={classes.expiringBox}
      contentClassName={classes.onboarding}
      footerClassName={classes.footer}
      footer={<Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />}
    >
      <Table.Container data={items} loading={loading} handleScroll={false} showHeadings={false} dark emptyMessage="No expiring contracts :)">
        <Table.Columns.Default
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d?.supplierName || d?.supplierManagerName} url={d?.supplierIcon} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName || d?.supplierManagerName, d => d?.supplierManagerEmail]} />
        <Table.Columns.Default render={d => d?.engagementTitle} />
        <Table.Columns.TwoLines
          showOnMobile
          lines={[d => moment(d?.endDate).format('MMMM Do, YYYY'), d => `Started on ${moment(d?.startDate).format('MMMM Do, YYYY')}`]}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View engagement', href: d => `/engagements/${d.engagementId}` },
            { divider: true },
            {
              name: 'Extend',
              onClick: d =>
                setDrawer({
                  open: true,
                  data: { id: d?.engagementId, clientId: membership.organization.id },
                  type: 'request-updates',
                  onComplete: () => reload(),
                }),
            },
            { divider: true },
            {
              name: 'Terminate',
              onClick: d => setDrawer({ open: true, data: { id: d?.engagementId }, type: 'termination', onComplete: () => reload() }),
            },
          ]}
        />
      </Table.Container>
    </Box>
  );
};

export default ExpiringBox;
