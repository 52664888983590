import Clients from '../configuration/client';

export const getTrees = async () => {
  const response = await Clients.Api.get('ui/template/describe');
  return response?.data;
};

export const getEmailDescriptors = async () => {
  const response = await Clients.Api.get('/ui/template/describe/email');
  return response?.data;
};

export const getTemplate = async id => {
  const response = await Clients.Api.get(`/ui/template/${id}`);
  return response.data;
};

export const getTemplateStream = async id => {
  const response = await Clients.Api.get(`/ui/template/${id}/stream`);
  return response.data;
};

export const overrideTemplate = async (id, data) => {
  const response = await Clients.Api.post(`/ui/template/override/${id}`, data);
  return response.data;
};

export const replaceTemplateStream = async (id, data) => {
  const response = await Clients.Api.post(`/ui/template/replace/${id}`, data);
  return response.data;
};

export const createTemplate = async data => {
  const response = await Clients.Api.post(`ui/template`, data);
  return response?.data;
};

export const updateTemplate = async data => {
  const response = await Clients.Api.put(`ui/template`, data);
  return response?.data;
};

export const validateTemplate = async template => {
  const response = await Clients.Api.post('/ui/template/validate', template);
  return response.data;
};

export const previewTemplate = async template => {
  const response = await Clients.Api.post('/ui/template/preview', template);
  return response.data;
};

export const publishTemplate = async id => {
  const response = await Clients.Api.post(`/ui/template/publish/${id}`);
  return response.data;
};

export const uploadInitialPdfTemplate = async ({ file }, onUploadProgress = () => null) => {
  const data = new FormData();
  data.append('file', file);
  const response = await Clients.Api.post(`ui/template/upload`, data, { onUploadProgress });
  return response?.data;
};

export const uploadInitialBlankTemplate = async () => {
  const response = await Clients.Api.post(`ui/template/upload/blank`);
  return response?.data;
};

export const getAdHocEmailTemplate = async () => {
  const response = await Clients.Api.post(`ui/template/preview/email/Messages|AdHoc`);
  return response?.data;
};
