import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { compareChanges, formSearchParams, getDefaultItems, getFilters, getPage } from './helper';

const useTable = (fetchData, config = { pageSize: 10, controlled: false }, initialFilters = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ loading, page, pageSize, pageCount, total, items, filters, orderBy }, setState] = useState({
    loading: true,
    page: -1,
    pageCount: 1,
    pageSize: config.pageSize,
    total: config.pageSize,
    items: getDefaultItems({ pageSize: config.pageSize }),
    filters: config.controlled ? getFilters({ initialFilters, searchParams }) : { ...initialFilters },
    initialFilters,
    orderBy: {},
  });

  const getData = async (page, filters, orderBy) => {
    setState(prevState => ({ ...prevState, loading: true, page, filters, orderBy }));
    const response = await fetchData({ filters, page, pageSize, sortBy: orderBy });
    if (response.data.length === 0 && response.page > 0) {
      const lastPage = Math.max(0, Math.ceil(response.total / pageSize) - 1);
      return goToPage(lastPage);
    }
    setState(prevState => ({
      ...prevState,
      loading: false,
      items: response.data,
      pageCount: Math.ceil(response.total / pageSize),
      total: response.total,
    }));
  };

  const goToPage = _page => {
    if (config.controlled) {
      const newSearchParams = formSearchParams({ page: _page, filters, initialFilters, orderBy });
      setSearchParams(newSearchParams);
      return;
    }
    void getData(_page, filters, orderBy);
  };

  const resetFilters = () => {
    if (config.controlled) {
      const newSearchParams = formSearchParams({ page: 0, filters: initialFilters, initialFilters, orderBy });
      if (searchParams.toString() === newSearchParams.toString()) {
        void getData(0, initialFilters, orderBy);
      } else {
        setSearchParams(newSearchParams);
      }
      return;
    }
    void getData(0, initialFilters, orderBy);
  };

  const setFilters = _filters => {
    if (config.controlled) {
      const newSearchParams = formSearchParams({ page: 0, filters: _filters, initialFilters, orderBy });
      setSearchParams(newSearchParams);
      return;
    }
    void getData(0, _filters, orderBy);
  };

  const setOrderBy = _orderBy => {
    if (config.controlled) {
      const newSearchParams = formSearchParams({ page: 0, filters, initialFilters, orderBy });
      setSearchParams(newSearchParams);
      return;
    }
    void getData(0, filters, _orderBy);
  };

  const reload = () => {
    void getData(page, filters, orderBy);
  };

  useEffect(() => {
    if (config.controlled) {
      const _page = getPage({ searchParams });
      const _filters = getFilters({ initialFilters, searchParams });
      const _orderBy = {};

      // this is required to avoid unnecessary API calls when we change are changing search params, from the outside, that are not applied to the table
      // example for this is adding "id" or "tab (t)" to the search params, which is not part of the filters
      const isPageChanged = page !== _page;
      const isFiltersChanged = compareChanges(filters, _filters);
      const isOrderByChanged = compareChanges(orderBy, _orderBy);
      if (isPageChanged || isFiltersChanged || isOrderByChanged) {
        void getData(_page, _filters, _orderBy);
      }
    } else {
      void getData(0, filters, orderBy);
    }
  }, [config.controlled, searchParams]);

  const exportCSV = () => {};

  return {
    loading,
    page,
    pageSize,
    pageCount,
    total,
    items,
    filters,
    initialFilters, // this is useful to indicate if the filters have been changed in the Filters component
    orderBy,
    goToPage,
    setFilters,
    resetFilters,
    setOrderBy,
    exportCSV,
    reload,
  };
};

export default useTable;
