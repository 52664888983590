import { LOOKUPS_SET_COUNTRIES, LOOKUPS_SET_CURRENCIES } from '../types';

const initialState = {
  countries: undefined,
  currencies: undefined,
};

const LookupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOOKUPS_SET_COUNTRIES:
      return { ...state, countries: action.data };

    case LOOKUPS_SET_CURRENCIES:
      return { ...state, currencies: action.data };

    default:
      return state;
  }
};

export default LookupsReducer;
