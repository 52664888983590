import React from 'react';
import * as Icons from 'react-feather';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from '../../components/navigation';

import Profile from '../common/profile';
import Privacy from '../common/privacy';
import Dashboard from './dashboard';
import Company from './company';
import Time from './time';
import Engagements from './engagements';
import Pay from './pay';
// import Support from './support';
import UserDetails from './users';

const Supplier = ({ membership }) => {
  return (
    <Navigation.Container key={membership?.membershipId}>
      <Navigation.Item path="/dashboard" title="Dashboard" icon={Icons.Grid} component={Dashboard} index />
      <Navigation.Item
        path="/company"
        title="Company"
        icon={Icons.FileText}
        component={Company}
        condition={({ membership }) => membership?.organization?.supplierType === 'independent-contractor'}
      />
      <Navigation.Item path="/time" title="Time" icon={Icons.Clock} component={Time} />
      <Navigation.Item path="/engagements/*" title="Engagements" icon={Icons.Briefcase} component={Engagements} />
      <Navigation.Item path="/pay" title="Pay" icon={Icons.CreditCard} component={Pay} />
      {/* <Navigation.Item path="/support" title="Support" icon={Icons.HelpCircle} component={Support} /> */}
      <Navigation.Item path="/users/:id" component={UserDetails} />
      <Navigation.Item path="/profile" component={Profile} />
      <Navigation.Item path="/privacy" component={Privacy} />
    </Navigation.Container>
  );
};

const mapStateToProps = state => ({
  membership: state.identity.membership,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
