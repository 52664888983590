import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import classes from './columns.module.scss';

const DefaultColumn = ({ name, render, alt, row, loading }) => {
  const content = !!loading ? name || 'loading' : render?.(row);
  const altProps = !!alt ? { title: alt?.(row) } : {};
  return (
    <div className={classNames(classes.column, !!loading && Classes.SKELETON)} {...altProps}>
      {typeof content === 'string' ? <div>{content}</div> : content}
    </div>
  );
};

DefaultColumn.definition = 'column';
DefaultColumn.width = '1fr';

export default DefaultColumn;
