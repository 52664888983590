import React from 'react';
import * as Icons from 'react-feather';

import Navigation from '../../components/navigation';

import Profile from '../common/profile';
import Dashboard from './dashboard';

const Vendor = () => {
  return (
    <Navigation.Container>
      <Navigation.Item path="/dashboard" title="Dashboard" icon={Icons.Grid} component={Dashboard} index />
      <Navigation.Item path="/profile" component={Profile} />
    </Navigation.Container>
  );
};

export default Vendor;
