import React from 'react';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';

import { textAlignmentOptions } from '../../../../enumerations/field-options';

import classes from '../properties.module.scss';

const validateIterator = value => {
  if (!value) {
    return `Required field`;
  }
  return undefined;
};

const TableConfigForm = ({ contentItem, iterators, onSubmit }) => {
  const iteratorOptions = iterators.map(iterator => ({
    label: iterator.label,
    value: iterator.name,
  }));

  return (
    <Form initialValues={contentItem} onSubmit={onSubmit} enableReinitialize={true}>
      <div className={classes.group}>
        <Fields.Text label="Title" name="title" submitOnChange={true} showError={false} />
        <Fields.Select label="Show list of" name="iterator" options={iteratorOptions} submitOnChange={true} validate={validateIterator} showError={false} />
      </div>
      <div className={classes.group}>
        <Fields.Select label="Text alignment" name="styleConfig.textAlign" options={textAlignmentOptions} submitOnChange={true} showError={false} />
      </div>
      <div className={classes.group}>
        <div className={classes.line}>
          <Fields.Text name="styleConfig.paddingTop" maskConfig={{ mask: '0[0]px', lazy: false }} label="Top" submitOnChange={true} showError={false} />
          <Fields.Text name="styleConfig.paddingRight" maskConfig={{ mask: '0[0]px', lazy: false }} label="Right" submitOnChange={true} showError={false} />
          <Fields.Text name="styleConfig.paddingBottom" maskConfig={{ mask: '0[0]px', lazy: false }} label="Bottom" submitOnChange={true} showError={false} />
          <Fields.Text name="styleConfig.paddingLeft" maskConfig={{ mask: '0[0]px', lazy: false }} label="Left" submitOnChange={true} showError={false} />
        </div>
      </div>
    </Form>
  );
};

export default TableConfigForm;
