import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ResizeSensor } from '@blueprintjs/core';
import debounce from 'lodash/debounce';
import cn from 'classnames';

import Spinner from '../../spinner';

import { renderDoc } from './signature-helper';

import styles from './signature-field.module.scss';

const DocumentContent = ({ target, readonly, contextData, loading, previewApi, generateApi }) => {
  const [ratio, setRatio] = useState(0);
  const ref = useRef(null);
  const delayedApiCall = useCallback(
    debounce((target, context) => {
      return generateApi.request(target, context);
    }, 500),
    [],
  );

  const onResize = useCallback(
    debounce(e => {
      const roundedRatio = Number((e?.[0]?.contentRect?.width / 816).toFixed(2));
      setRatio(roundedRatio);
    }, 500),
    [],
  );

  useEffect(() => {
    if (!target) {
      // this is used in the wizard template editor to preview the template
      return;
    }

    if (readonly) {
      // this is used in the wizard template editor to preview the template
      void previewApi.request(target);
      return;
    }

    // since the contextData.signatureId is used to regenerate the document when the document is signed
    // and it is not needed to regenerate the document if there is no signatureId
    // we need to check if there is a signatureId before calling the generateApi
    // even if the document is signed we don't receive the signatureId immediately so we need to wait for formik values
    // to be updated with the signatureId and then call the generateApi
    // this is why we use the delayedApiCall
    delayedApiCall(target, contextData);
  }, [target, contextData.signatures]);

  useEffect(() => {
    const response = generateApi.response || previewApi.response;
    if (ref?.current && target && ratio && response) {
      renderDoc(response, ref, ratio);
    }
  }, [ref, readonly, contextData, target, ratio, generateApi.response, previewApi.response]);

  return (
    <ResizeSensor onResize={onResize}>
      <div className={styles.document}>
        <div className={cn(styles.loading, !loading && styles.hidden)}>
          <Spinner blend global={false} />
        </div>
        <div className={styles.htmlContent} ref={ref} style={{ height: 400 }} />
      </div>
    </ResizeSensor>
  );
};

export default DocumentContent;
