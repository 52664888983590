import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';
import * as Icons from 'react-feather';

import Fields from '../../../../fields';
import Form from '../../../../form';

import { getColor } from '../../../../../utilities/common';
import { canBeEdited } from '../../../../../domain/tracking-record-status-type';
import { EngagementStatusType } from '../../../../../domain/engagement-status-type';

import useApi from '../../../../../hooks/use-api';
import { createExpense, updateExpense } from '../../../../../apis/supplier/time';

import { DateFormat } from '../../../../../models/enumerations/date-format';
import { QuickTimekeepingContext } from '../../state';

import classes from '../../quick.module.scss';

const ExpenseForm = ({ user, week, data = {}, onComplete = () => null }) => {
  const { loadRecords, changeDate, engagements: allEngagements } = useContext(QuickTimekeepingContext);
  const engagements = allEngagements?.filter?.(e => e.status === EngagementStatusType.Active);

  const createExpenseApi = useApi(createExpense, { skip: true });
  const updateExpenseApi = useApi(updateExpense, { skip: true });

  const onChangeDate = newDate => {
    const d = moment(newDate);
    if (!d.isSame(week, 'isoWeek')) {
      changeDate(d);
    }
  };

  const submit = async values => {
    if (data?.id) {
      await updateExpenseApi.request(values);
    } else {
      await createExpenseApi.request(values);
    }

    await loadRecords();
    onComplete();
  };

  const editable = canBeEdited(data?.status);

  return (
    <Form
      initialValues={{
        userId: user.id,
        date: moment().isSame(week, 'isoWeek') ? moment().startOf('day').format(DateFormat.DEFAULT) : moment(week).startOf('day').format(DateFormat.DEFAULT),
        notes: '',
        engagementId: engagements?.length === 1 ? engagements[0].id : '',
        ...data,
      }}
      validationSchema={Yup.object().shape({
        engagementId: Yup.string().required('Engagement is required'),
        amount: Yup.number().moreThan(0, 'Amount must be greater than 0').required('Amount is required'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {form => {
        const currency = engagements?.find?.(e => e.id === form.values.engagementId)?.compensation?.currency?.symbol;
        const loading = createExpenseApi.loading || updateExpenseApi.loading;
        return (
          <React.Fragment>
            <div className={classes.engagementSelection}>
              <Fields.Select
                label="Engagement"
                disabled={engagements?.length === 1 || !editable}
                name="engagementId"
                options={engagements?.map?.(e => ({
                  label: (
                    <div className={classes.engagementLabel}>
                      <div className={classes.engagementIcon}>
                        <Icons.Square size={12} strokeWidth={1.5} fill={getColor(e?.clientName)} color={getColor(e?.clientName)} />
                      </div>
                      <div>{e?.title}</div>
                    </div>
                  ),
                  value: e.id,
                  description: e?.title,
                }))}
                searchable
              />
            </div>
            <div className={classes.dateSelection}>
              <Fields.Date
                label="Date"
                name="date"
                disabled={!editable}
                format={DateFormat.DEFAULT}
                maxDate={moment().endOf('isoWeek').toDate()}
                onChange={d => onChangeDate(d)}
              />
            </div>
            <Fields.Text
              extra={currency && { leftElement: <Button text={currency} minimal small disabled /> }}
              label="Amount"
              type="number"
              name="amount"
              disabled={!editable}
            />
            <Fields.File
              name="receipt"
              title={form?.values?.receiptId ? 'Replace receipt' : 'Select receipt'}
              label="Receipt"
              placeholder={form?.values?.receiptId ? 'Replace receipt' : 'Select receipt'}
              extensions={['txt', 'jpeg', 'jpg', 'png', 'pdf']}
              api={file => Promise.resolve(file)}
            />
            <Fields.Textarea label="Notes" name="notes" disabled={!editable} />

            <div className={classes.shiftButtons}>
              <Button type="submit" fill className={classes.submit} text="Submit" disabled={loading || !editable} loading={loading} />
            </div>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseForm);
