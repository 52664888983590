import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';
import * as Icons from 'react-feather';

import Fields from '../../../../fields';
import Form from '../../../../form';

import { getColor } from '../../../../../utilities/common';
import { canBeEdited } from '../../../../../domain/tracking-record-status-type';
import { EngagementStatusType } from '../../../../../domain/engagement-status-type';

import useApi from '../../../../../hooks/use-api';
import { createDeliverable, updateDeliverable } from '../../../../../apis/supplier/time';

import { DateFormat } from '../../../../../models/enumerations/date-format';
import { QuickTimekeepingContext } from '../../state';

import classes from '../../quick.module.scss';

const DeliverableForm = ({ user, week, data = {}, onComplete = () => null }) => {
  const { loadRecords, changeDate, engagements: allEngagements } = useContext(QuickTimekeepingContext);
  const engagements = allEngagements?.filter?.(e => e.status === EngagementStatusType.Active);

  const createDeliverableApi = useApi(createDeliverable, { skip: true });
  const updateDeliverableApi = useApi(updateDeliverable, { skip: true });

  const onChangeDate = newDate => {
    const d = moment(newDate);
    if (!d.isSame(week, 'isoWeek')) {
      changeDate(d);
    }
  };

  const submit = async values => {
    if (data?.id) {
      await updateDeliverableApi.request(values);
    } else {
      await createDeliverableApi.request(values);
    }

    await loadRecords();
    onComplete();
  };

  const editable = canBeEdited(data?.status);

  return (
    <Form
      initialValues={{
        userId: user.id,
        date: moment().isSame(week, 'isoWeek') ? moment().startOf('day').format(DateFormat.DEFAULT) : moment(week).startOf('day').format(DateFormat.DEFAULT),
        notes: '',
        engagementId: engagements?.length === 1 ? engagements[0].id : '',
        ...data,
      }}
      validationSchema={Yup.object().shape({
        engagementId: Yup.string().required('Engagement is required'),
        amount: Yup.number().moreThan(0, 'Amount must be greater than 0').required('Amount is required'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {form => {
        const currency = engagements?.find?.(e => e.id === form.values.engagementId)?.compensation?.currency?.symbol;
        const loading = createDeliverableApi.loading || updateDeliverableApi.loading;
        return (
          <React.Fragment>
            <Fields.Select
              label="Engagement"
              disabled={engagements?.length === 1 || !editable}
              name="engagementId"
              options={engagements?.map?.(e => ({
                label: (
                  <div className={classes.engagementLabel}>
                    <div className={classes.engagementIcon}>
                      <Icons.Square size={12} strokeWidth={1.5} fill={getColor(e?.clientName)} color={getColor(e?.clientName)} />
                    </div>
                    <div>{e?.title}</div>
                  </div>
                ),
                value: e.id,
                description: e?.title,
              }))}
              searchable
            />
            <Fields.Date
              label="Date"
              name="date"
              disabled={!editable}
              format={DateFormat.DEFAULT}
              maxDate={moment().endOf('isoWeek').toDate()}
              onChange={d => onChangeDate(d)}
            />
            <Fields.Text
              label="Amount"
              extra={currency && { leftElement: <Button text={currency} minimal small disabled /> }}
              type="number"
              name="amount"
              disabled={!editable}
            />

            <Fields.Textarea label="Notes" name="notes" disabled={!editable} />

            <div className={classes.shiftButtons}>
              <Button type="submit" fill className={classes.submit} text="Submit" disabled={loading || !editable} loading={loading} />
            </div>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableForm);
