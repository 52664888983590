import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '../../../components/box';
import Page from '../../../components/page';
import { getTimeBasedGreeting } from '../../../utilities/common';

import classes from './dashboard.module.scss';

const Dashboard = ({ user }) => {
  return (
    <Page title={`${getTimeBasedGreeting()}, ${user?.personalDetails?.firstName}.`}>
      <div className={classes.dashboard}>
        <Box title={'Vendor stuff'}>
          <Box.ItemContainer>
            We are the API compliance layer powering The Future of Work. Managing your contingent workforce has never been easier or safer.
          </Box.ItemContainer>
        </Box>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
