import { uuid } from '../../../../utilities/common';

import { ObjectTypes } from './types';

export default class Field {
  constructor(field = {}) {
    const type = field.type || ObjectTypes.Text;
    const {
      id = uuid(),
      label = 'New Field',
      description = '',
      showDescriptionAsTooltip = false,
      name = '',
      originalType,
      lookup = undefined,
      showLabel = true,
      width = 100,
      mobileWidth = 100,
      visibility = {},
      mandatory = false,
      validation = {
        required: type === ObjectTypes.Signature, // set Signature as required by default
      },
      required = false,
      initiallyRequired = undefined,
      defaultValue = undefined,
      category = undefined,
      path = undefined,
      backgroundColor,
      borderColor,
      disabled = false,
      hidden = false,
      ...rest
    } = field;

    this.id = id;
    this.label = label;
    this.name = name;
    this.description = description;
    this.showDescriptionAsTooltip = showDescriptionAsTooltip;
    this.type = type;
    this.originalType = originalType || type;
    this.lookup = lookup;
    this.showLabel = showLabel;
    this.width = width;
    this.mobileWidth = mobileWidth;
    this.mandatory = mandatory;
    this.validation = validation;
    this.required = required;
    this.initiallyRequired = initiallyRequired;
    this.defaultValue = defaultValue;
    this.path = path;
    this.category = category;
    this.backgroundColor = backgroundColor;
    this.borderColor = borderColor;
    this.disabled = disabled;
    this.hidden = hidden;

    if (visibility?.field) {
      // if the field is set then set rules to support backward compatibility
      this.visibility = {
        combinator: 'AND',
        rules: [
          {
            field: visibility.field,
            operator: visibility.operator,
            value: visibility.value,
          },
        ],
      };
    } else {
      this.visibility = visibility;
    }

    if (!!rest) {
      let instance = this;
      // Map type specific properties
      Object.entries(rest).forEach(([key, value]) => {
        switch (key) {
          default:
            instance[key] = value;
            break;
        }
      });
    }

    // Adjust type specific properties
    switch (type) {
      case ObjectTypes.Text:
        if (this.defaultValue === undefined) {
          this.defaultValue = '';
        }
        break;

      case ObjectTypes.Number:
        if (this.defaultValue === undefined) {
          this.defaultValue = null;
        }
        break;

      case ObjectTypes.Boolean:
        if (this.defaultValue === undefined) {
          this.defaultValue = false;
        }
        break;

      case ObjectTypes.Select:
        if (this.defaultValue === undefined) {
          this.defaultValue = null;
        }
        if (this.display === undefined) {
          this.display = 'default';
        }
        break;

      case ObjectTypes.MultiSelect:
        if (this.defaultValue === undefined) {
          this.defaultValue = [];
        }
        if (this.display === undefined) {
          this.display = 'default';
        }
        break;

      case ObjectTypes.Paragraph:
        if (this.text === undefined) {
          this.text = '';
          this.backgroundColor = '#ffffff';
          this.borderColor = undefined;
        }
        break;

      case ObjectTypes.Link:
        if (this.text === undefined) {
          this.text = '';
        }
        if (this.link === undefined) {
          this.link = '';
        }
        break;

      case ObjectTypes.Date:
      case ObjectTypes.DateRange:
        break;

      case ObjectTypes.Phone:
        this.validation.mask = this.validation.mask ?? '+1 (000) 000-0000';
        break;

      default:
        break;
    }
  }
}
