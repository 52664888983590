import React, { useContext, useState } from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as Yup from 'yup';

import WithConfirmation from '../../../../../../../hooks/with-confirmation';
import Form, { Field } from '../../../../../../form';
import Fields from '../../../../../../fields';

import { WizardContext } from '../../../../state';

import classes from './custom-fields.module.scss';

const Categories = () => {
  const [open, setOpen] = useState(false);
  const { template, addCategory, removeCategory } = useContext(WizardContext);

  return (
    <React.Fragment>
      <div>
        <div className={classes.groupTitle}>Question categories</div>
        <div className={classes.groupDescription}>Configure available categories which can be used to tag responses, which will be grouped when reviewing.</div>
      </div>
      <Field name="categories" outline={false} minimal={true} showError={false}>
        {() => (
          <div className={classes.categories}>
            <div className={classes.list}>
              {template?.categories?.map?.((c, i) => (
                <div className={classes.category} key={i}>
                  <div className={classes.indicator} style={{ backgroundColor: c.color }} />
                  <div className={classes.title}>{c.name}</div>
                  <WithConfirmation
                    messages={{
                      question: `Removing (${c.name}) will untag all fields tagged with this category.`,
                      confirmButton: 'Remove',
                    }}
                  >
                    <Button minimal icon="cross" onClick={() => removeCategory(c)} />
                  </WithConfirmation>
                </div>
              ))}
            </div>
            <Popover
              isOpen={open}
              usePortal={true}
              popoverClassName={classes.popover}
              content={
                <div className={classes.content}>
                  <div className={classes.inner}>
                    <Form
                      initialValues={{ name: '', color: '#fc0000' }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required('Category name is required'),
                        color: Yup.string().required('Category color is required'),
                      })}
                      onSubmit={({ name, color }) => {
                        addCategory({ name, color });
                        setOpen(false);
                      }}
                    >
                      <React.Fragment>
                        <div className={classes.title}>Question categories</div>
                        <div className={classes.description}>
                          Configure available categories which can be used to tag responses, which will be grouped when reviewing.
                        </div>
                        <div className={classes.row}>
                          <Fields.Text label="Name" name="name" />
                          <Fields.Color label="Color" name="color" />
                        </div>
                        <div className={classes.row}>
                          <Button type="submit" outlined text="Submit" />
                          <Button outlined text="Cancel" onClick={() => setOpen(false)} />
                        </div>
                      </React.Fragment>
                    </Form>
                  </div>
                </div>
              }
            >
              <Button fill alignText="left" icon="plus" outlined text="Add Category" onClick={() => setOpen(true)} />
            </Popover>
          </div>
        )}
      </Field>
    </React.Fragment>
  );
};

export default Categories;
