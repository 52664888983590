import Container from './container';
import Item from './item';
import ActionItem from './action-item';

const Tabs = {
  Container,
  Item,
  ActionItem,
};

export default Tabs;
