import Clients from '../../configuration/client';
import { toQueryString } from '../../utilities/common';
import { StorageManager } from '../../authentication/storage';
import moment from 'moment';

export const getEngagements = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement${toQueryString(query)}`);
  return response?.data;
};

export const exportEngagements = async ({ filters = {} }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/export${toQueryString(query)}`);
  return response?.data;
};

export const getEngagement = async id => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/${id}`);
  return response?.data;
};

export const getOnboardingEngagements = async ({ page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/onboarding${toQueryString(query)}`);
  return response?.data;
};

export const resendEngagementInvite = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/resend-invite`);
  return response?.data;
};

export const getEvaluationEngagements = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/evaluations${toQueryString(query)}`);
  return response?.data;
};

export const setEngagementStatus = async ({ engagementId, status }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/status/${status}`);
  return response?.data;
};

export const createNewEngagement = async data => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/new`, data);
  return response?.data;
};

export const cancelEngagement = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/cancel`);
  return response?.data;
};

export const updateEngagement = async ({ engagementId, engagement }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/engagement/${engagementId}`, engagement);
  return response?.data;
};

export const updateEngagementPurchaseOrder = async ({ engagementId, purchaseOrder }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/purchase-order`, purchaseOrder);
  return response?.data;
};

export const updateEngagementCompensation = async ({ engagementId, compensation }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/compensation`, compensation);
  return response?.data;
};

export const updateEngagementCompensationScheduled = async ({ engagementId, compensation, date }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/compensation/schedule/${date}`, compensation);
  return response?.data;
};

export const updateEngagementClassification = async ({ engagementId, classification }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/classification`, classification);
  return response?.data;
};

export const updateEngagementHiringManager = async ({ engagementId, managerId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/hiring-manager/${managerId}`);
  return response?.data;
};

export const updateEngagementDepartment = async ({ engagementId, departmentId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/department/${departmentId}`);
  return response?.data;
};

export const removeEngagementDepartment = async ({ engagementId, departmentId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/engagement/${engagementId}/department/${departmentId}`);
  return response?.data;
};

export const getWorkLocationOptions = async ({ engagementId, type }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/${engagementId}/work-location-options/${type}`);
  return response.data;
};

export const updateEngagementMetadata = async ({ engagementId, metadata }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/metadata`, metadata);
  return response?.data;
};

export const getEngagementUpdates = async ({ page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/updates${toQueryString(query)}`);
  return response?.data;
};

export const getEngagementUpdate = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/${engagementId}/updates`);
  return response?.data;
};

export const updateEngagementUpdateRequest = async ({ engagementId, ...rest }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/engagement/${engagementId}/updates`, rest);
  return response?.data;
};

export const cancelEngagementUpdateRequest = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/updates/cancel`);
  return response?.data;
};

export const approveEngagementUpdateRequest = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/updates/approve`);
  return response?.data;
};

export const getEngagementTerminations = async ({ page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/terminations${toQueryString(query)}`);
  return response?.data;
};

export const getEngagementTermination = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/${engagementId}/termination`);
  return response?.data;
};

export const updateEngagementTermination = async ({ engagementId, endDate }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/engagement/${engagementId}/termination/${moment(endDate).format('YYYY-MM-DD')}`);
  return response?.data;
};

export const cancelEngagementTermination = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/termination/cancel`);
  return response?.data;
};

export const approveEngagementTermination = async ({ engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/${engagementId}/termination/approve`);
  return response?.data;
};

export const getExpiringEngagements = async ({ page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/expiring${toQueryString(query)}`);
  return response?.data;
};

export const getEngagementImportRequests = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/import${toQueryString(query)}`);
  return response?.data;
};

export const createEngagementImportRequest = async ({ file, clientId }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('file', file);
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/import/${clientId}`, data, { onUploadProgress });
  return response?.data;
};

export const getEngagementImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/engagement/import/${id}`);
  return response?.data;
};

export const validateEngagementImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/import/${id}/validate`);
  return response?.data;
};

export const executeEngagementImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/import/${id}/execute`);
  return response?.data;
};

export const cancelEngagementImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/engagement/import/${id}/cancel`);
  return response?.data;
};
