import React, { useContext } from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';

import WithConfirmation from '../../../../../hooks/with-confirmation';

import { WizardContext } from '../../state';
import { WizardSelectionContext } from '../../state/selection-provider';

import classes from './field.module.scss';

const FieldMenu = ({ page, group, field }) => {
  const { template, cloneField, moveField, removeField } = useContext(WizardContext);
  const { selectField } = useContext(WizardSelectionContext);

  return (
    <Popover
      interactionKind="click"
      content={
        <Menu>
          <MenuItem text="Properties" icon="settings" onClick={() => selectField(page, group, field)} />
          <MenuItem text="Duplicate" icon="duplicate" onClick={() => cloneField(page, group, field)} />
          <MenuItem text="Move" icon="cut" shouldDismissPopover={false}>
            {template?.pages?.map?.(p => (
              <MenuItem key={p?.id} text={p?.title} shouldDismissPopover={false}>
                {p?.groups?.map?.(g => (
                  <MenuItem key={g?.id} text={g?.title} disabled={page.id === p.id && group.id === g.id} onClick={() => moveField(page, group, field, p, g)} />
                ))}
              </MenuItem>
            ))}
          </MenuItem>
          <MenuDivider />
          <WithConfirmation
            disabled={!!field.mandatory}
            messages={{
              question: `Are you sure you want to remove ${field?.label} field ? All visibility conditions that are based on this field will also be removed.`,
            }}
            icon={true}
          >
            <MenuItem text="Remove" icon="cross" disabled={!!field?.mandatory} onClick={() => removeField(page, group, field)} shouldDismissPopover={false} />
          </WithConfirmation>
        </Menu>
      }
    >
      <Button className={classes.actions} minimal icon="more" />
    </Popover>
  );
};

export default FieldMenu;
