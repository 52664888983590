import React from 'react';
import moment from 'moment';

import Avatar from '../../../../../components/avatar';
import Box from '../../../../../components/box';
import Table from '../../../../../components/table';
import FlowStatus from '../../../../../components/flow-status';

import classes from '../../dashboard.module.scss';

const OffboardingBox = ({ table, setDrawer }) => {
  const { items, loading, pageCount, page, goToPage } = table;

  return (
    <Box
      title={'Offboarding suppliers'}
      className={classes.offboardingBox}
      contentClassName={classes.onboarding}
      footerClassName={classes.footer}
      footer={<Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />}
    >
      <Table.Container data={items} loading={loading} handleScroll={false} showHeadings={false} dark emptyMessage="No offboarding suppliers :)">
        <Table.Columns.Default
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d?.supplierName || d?.supplierManagerName} url={d?.supplierIcon} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName || d?.supplierManagerName, d => d?.supplierManagerEmail]} />
        <Table.Columns.TwoLines showOnMobile lines={[d => <small>{moment(d?.endDate).format('MMM Do, YYYY')}</small>, d => d?.engagementTitle]} />
        <Table.Columns.Default
          width="55px"
          render={d => (
            <FlowStatus flowType="engagement-termination" reference={d} referenceId={d?.engagementId} loading={loading} align="right" stepCount={3} />
          )}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'Review', onClick: d => setDrawer({ open: true, engagementId: d?.engagementId }) },
            { divider: true },
            { name: 'View engagement', href: d => `/engagements/${d.engagementId}` },
            { name: 'View supplier', href: d => `/suppliers/${d.supplierId}` },
            { divider: true },
            {
              name: 'Impersonate',
              disabled: d => !d?.supplierManagerEmail,
              href: d => `/authentication/impersonate/${Buffer.from(d?.supplierManagerEmail).toString('base64')}`,
              target: '_blank',
            },
          ]}
        />
      </Table.Container>
    </Box>
  );
};

export default OffboardingBox;
