import moment from 'moment';
import React from 'react';
import { ModifiersUtils } from 'react-day-picker';

import { Field } from '../../form';
import { DateFormat } from '../../../models/enumerations/date-format';
import DatePicker from './date-picker';
import { isDisabled } from './utilities';

const DateField = ({
  classes,
  disabled,
  name,
  label,
  description,
  placeholder,
  format = DateFormat.DEFAULT,
  minDate,
  maxDate,
  modifiers,
  validate,
  onChange = undefined,
  submitOnChange = false,
  canClearSelection,
  buttonProps,
  outline,
  minimal,
  showError,
  cadence,
}) => {
  const validateDate = value => {
    if ((!minDate && !maxDate && !cadence) || !value) {
      return validate?.(value);
    }
    const date = moment(value, format).startOf('day');
    const min = minDate && moment(minDate).startOf('day');
    const max = maxDate && moment(maxDate).startOf('day');
    const isMinDateSatisfied = !minDate || min?.isSameOrBefore(date);
    const isMaxDateSatisfied = !maxDate || max?.isSameOrAfter(date);
    const dateValidationFormat = 'MMMM DD, YYYY';
    // the validations are working fine but if you need to change anything here consider using ModifiersUtils.dayMatchesModifier to refactor the code
    if (!isMinDateSatisfied && !isMaxDateSatisfied) {
      return `${label} field must be later than ${min?.format(dateValidationFormat)} and earlier than ${max?.format(dateValidationFormat)}`;
    } else if (!isMinDateSatisfied) {
      return `${label} field must be later than ${min?.format(dateValidationFormat)}`;
    } else if (!isMaxDateSatisfied) {
      return `${label} field must be at earlier than ${max?.format(dateValidationFormat)}`;
    }

    if (cadence) {
      const disabled = isDisabled(date, cadence, min, max);
      if (disabled) {
        return `${label} field must be valid according to cadence`;
      }
    }
    return validate?.(value);
  };

  const dayPickerProps = {
    modifiers: {
      disabled: day => {
        const min = minDate && moment(minDate).startOf('day');
        const max = maxDate && moment(maxDate).startOf('day');
        const disabled = isDisabled(day, cadence, min, max);
        if (disabled) {
          return true;
        }
        return ModifiersUtils.dayMatchesModifier(day, {
          before: minDate,
          after: maxDate,
        });
      },
    },
  };

  return (
    <Field
      classes={classes}
      label={label}
      description={description}
      name={name}
      validate={validateDate}
      outline={outline}
      minimal={minimal}
      showError={showError}
    >
      {({ field, form }) => {
        const onDateChange = formattedDate => {
          form.setFieldValue(name, formattedDate);
          if (submitOnChange) {
            form.submitForm();
          }
          if (!!onChange && typeof onChange === 'function') {
            onChange(formattedDate);
          }
        };

        return (
          <DatePicker
            value={field.value}
            placeholder={placeholder}
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            modifiers={modifiers}
            canClearSelection={canClearSelection}
            disabled={disabled}
            handleChange={onDateChange}
            buttonProps={buttonProps}
            dayPickerProps={dayPickerProps}
          />
        );
      }}
    </Field>
  );
};

export default DateField;
