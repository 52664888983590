import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Drawer } from '@blueprintjs/core';

import Configurators from '../../../../../components/configurators';

import classes from './updates.module.scss';

const EngagementUpdates = ({ engagement, data, open, onClose }) => {
  return (
    <Drawer className={classes.onboarding} isOpen={open} onClose={onClose} position="right" canEscapeKeyClose={false} canOutsideClickClose={false}>
      <Configurators.FlowLoader
        flowType={'engagement-updates'}
        data={{
          clientId: engagement?.clientId,
          engagementId: engagement?.id,
          organizationId: engagement?.supplierId,
          userId: engagement?.supplierManagerUserId,
          updatesId: data?.updatesId,
        }}
        referenceId={engagement?.id}
        onCancel={onClose}
        onComplete={onClose}
      />
    </Drawer>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EngagementUpdates);
