import React from 'react';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';
import { fontSizeOptions, fontWeightOptions, textAlignmentOptions, textDecorationOptions } from '../../../../enumerations/field-options';

import classes from '../properties.module.scss';

const TextConfigForm = ({ availableMacros, contentItem, onSubmit }) => {
  return (
    <Form initialValues={contentItem} onSubmit={onSubmit} enableReinitialize={true}>
      <div className={classes.group}>
        <Fields.MacroTextarea
          label="Text"
          name="text"
          description="Right click to insert macros"
          options={availableMacros}
          submitOnChange={true}
          showError={false}
          extra={{ growVertically: true }}
        />
      </div>
      <div className={classes.group}>
        <Fields.Select label="Font size" name="styleConfig.fontSize" options={fontSizeOptions} submitOnChange={true} showError={false} />
        <Fields.Select label="Font weight" name="styleConfig.fontWeight" options={fontWeightOptions} submitOnChange={true} showError={false} />
        <Fields.Select label="Text alignment" name="styleConfig.textAlign" options={textAlignmentOptions} submitOnChange={true} showError={false} />
        <Fields.Select label="Text decoration" name="styleConfig.textDecoration" options={textDecorationOptions} submitOnChange={true} showError={false} />
        <Fields.Color label="Color" name="styleConfig.color" submitOnChange={true} showError={false} />
      </div>
      <div className={classes.group}>
        <div className={classes.line}>
          <Fields.Text
            name="styleConfig.paddingTop"
            maskConfig={{
              mask: '0[0]px',
              lazy: false,
            }}
            label="Top"
            submitOnChange={true}
            showError={false}
          />
          <Fields.Text
            name="styleConfig.paddingRight"
            maskConfig={{
              mask: '0[0]px',
              lazy: false,
            }}
            label="Right"
            submitOnChange={true}
            showError={false}
          />
          <Fields.Text
            name="styleConfig.paddingBottom"
            maskConfig={{
              mask: '0[0]px',
              lazy: false,
            }}
            label="Bottom"
            submitOnChange={true}
            showError={false}
          />
          <Fields.Text
            name="styleConfig.paddingLeft"
            maskConfig={{
              mask: '0[0]px',
              lazy: false,
            }}
            label="Left"
            submitOnChange={true}
            showError={false}
          />
        </div>
      </div>
      <div className={classes.group}>
        <Fields.Text label="Class name" name="class" submitOnChange={true} showError={false} />
        <Fields.Textarea label="Override style" name="customStyle" submitOnChange={true} showError={false} />
      </div>
    </Form>
  );
};

export default TextConfigForm;
