import React from 'react';
import cn from 'classnames';
import { SmallTick } from '@blueprintjs/icons';

import MacroTypes from '../../../../enumerations/macro-types';

import classes from './macro-item.module.scss';

const MacroPlaceholder = ({ macro, onClick }) => {
  const displayName = macro.displayName || 'New macro';

  switch (macro.type) {
    case MacroTypes.DEFAULT:
    case MacroTypes.SUBSTRING:
    case MacroTypes.DATE:
    case MacroTypes.SIGNATURE:
    case MacroTypes.INLINE_IMG:
    case MacroTypes.STANDALONE_IMG:
    case MacroTypes.CUSTOM_JS:
      return (
        <div className={classes.placeholder} style={{ whiteSpace: macro.config.style.whiteSpace }} onClick={onClick}>
          {displayName}
        </div>
      );

    case MacroTypes.CHECKBOX:
      return (
        <div className={classes.placeholder} style={{ whiteSpace: macro.config.style.whiteSpace }} onClick={onClick}>
          <SmallTick />
        </div>
      );

    case MacroTypes.CELL: {
      const indices = macro.config?.indices;
      const cells = (indices || '').split(',');
      const numOfCells = cells.length;
      const items = [];
      for (let i = 0; i < numOfCells; i++) {
        items.push(<li key={i}>{indices ? cells[i] : ' '}</li>);
      }

      return (
        <ul className={cn(classes.placeholder, classes.cell)} style={{ whiteSpace: macro.config.style.whiteSpace }} onClick={onClick}>
          {indices ? items : displayName}
        </ul>
      );
    }

    default:
      return null;
  }
};

export default MacroPlaceholder;
