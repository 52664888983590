import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { useParams } from 'react-router';

import Avatar from '../../../components/avatar';
import Form from '../../../components/form';
import Fields from '../../../components/fields';
import Page from '../../../components/page';
import Tabs from '../../../components/tabs';

import useApi from '../../../hooks/use-api';
import { getUser } from '../../../apis/client/users';

import { getFullName } from '../../../utilities/user';

import General from './containers/general';

import classes from './details.module.scss';

const UserDetails = () => {
  const { id: userId } = useParams();
  const api = useApi(getUser, { params: { userId } });

  const avatar = (
    <Form initialValues={{ avatar: null }} onSubmit={() => null}>
      <Fields.File
        label="Upload avatar"
        mimeTypes={'image/*'}
        name="avatar"
        maxSize={5}
        // api={avatar => uploadAvatar({ avatar })}
        // onUploaded={async () => await getUserProfile()}
        outline={false}
        minimal={true}
        showError={false}
      >
        <Avatar
          loading={api?.loading}
          name={getFullName(api?.response?.user)}
          url={api?.response?.user?.avatar}
          size={{ box: 220, text: 30 }}
          styles={{ marginBottom: '15px' }}
          upload
        />
      </Fields.File>
    </Form>
  );

  const commonProps = { api, userId };

  return (
    <Page
      title={!!api?.loading ? <span className={Classes.SKELETON}>Name</span> : getFullName(api?.response?.user)}
      scroll={false}
      path={[
        { name: 'Clients', to: '/clients' },
        { name: 'Users' },
        {
          name: !!api?.loading ? <span className={Classes.SKELETON}>Name</span> : getFullName(api?.response?.user),
          to: `/user/${userId}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

export default UserDetails;
