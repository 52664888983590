import { getUIConfiguration } from '../../apis/configuration';
import { APPLICATION_SET_OFFLINE, APPLICATION_SET_ACTIVITY_OPEN, APPLICATION_TOGGLE_ACTIVITY_OPEN } from '../types';
import { setThemes, changeThemes } from './actions';
import { setPrimaryColor } from '../../utilities/common';
import { StorageManager } from '../../authentication/storage';

export const getThemes = () => async dispatch => {
  try {
    const ui = await getUIConfiguration();
    const manager = new StorageManager();
    const activeThemeId = manager.getActiveTheme();
    const theme = ui?.themes?.find(t => t.id === activeThemeId) || ui?.themes?.[0];
    if (theme) {
      manager.setActiveTheme(theme?.id);
      setPrimaryColor(theme?.primaryColor);
      dispatch(
        setThemes({
          themes: ui?.themes,
          theme,
        }),
      );
    }
  } catch (error) {}
};

export const changeTheme = theme => async (dispatch, getState) => {
  const {
    application: { theme: currentTheme },
  } = getState();

  if (currentTheme.id === theme.id) {
    return;
  }
  const manager = new StorageManager();
  manager.setActiveTheme(theme?.id);
  setPrimaryColor(theme?.primaryColor);
  dispatch(changeThemes(theme));
};

export const setOfflineStatus = offline => dispatch => dispatch({ type: APPLICATION_SET_OFFLINE, data: offline });
export const setActivityVisibility = open => dispatch => dispatch({ type: APPLICATION_SET_ACTIVITY_OPEN, data: open });
export const toggleActivityVisibility = () => dispatch => dispatch({ type: APPLICATION_TOGGLE_ACTIVITY_OPEN });
