import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';
import Engagement from './engagement';

import classes from './engagements.module.scss';

const EngagementTab = ({ engagements, loading, type }) => {
  return (
    <div className={classes.tab}>
      {loading && (
        <React.Fragment>
          <div className={classNames(Classes.SKELETON, classes.loading)} />
          <div className={classNames(Classes.SKELETON, classes.loading)} />
          <div className={classNames(Classes.SKELETON, classes.loading)} />
          <div className={classNames(Classes.SKELETON, classes.loading)} />
          <div className={classNames(Classes.SKELETON, classes.loading)} />
        </React.Fragment>
      )}
      {!loading && engagements?.map?.(e => <Engagement key={e.id} engagement={{ ...e, description: e.description + e.description + e.description }} />)}
      {!loading && !engagements?.length && <div className={classes.empty}>{`No ${type} engagements.`}</div>}
    </div>
  );
};

export default EngagementTab;
