import * as apis from '../../apis/templates';
import { safeInvoke } from '../../utilities/functions';
import { setTrees } from './actions';

export const loadTrees = (onSuccess, onError) => async dispatch => {
  try {
    const trees = await apis.getTrees();
    dispatch(setTrees(trees));
    safeInvoke(onSuccess, trees);
  } catch (error) {
    safeInvoke(onError, error);
  }
};
