import React from 'react';
import EmailConfiguratorContext from './email-configurator-context';

const useEmailConfigurator = () => {
  const context = React.useContext(EmailConfiguratorContext);

  if (context === undefined) {
    throw new Error('useEmailConfigurator must be used within a EmailConfiguratorProvider');
  }

  return context;
};

export default useEmailConfigurator;
