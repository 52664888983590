import React, { useState } from 'react';
import { startCase } from 'lodash';

import Dialog from '../../../../dialog';
import useApi from '../../../../../hooks/use-api';

import { getRecordValue } from '../../../../../apis/configuration';

import { getForm } from './type-specific-forms';

const ViewRecordDialog = ({ isOpen, onClose, record = {}, api }) => {
  const [loading, setLoading] = useState(false);
  const getRecordValueApi = useApi(() => getRecordValue(record?.id));

  const close = () => (!!loading ? null : onClose());
  const [group, name] = record?.configurationType?.split?.('|');

  const form = getForm(record?.configurationType, {
    onClose,
    record,
    loading: loading || getRecordValueApi?.loading,
    setLoading,
    api,
    data: getRecordValueApi?.response,
  });

  return (
    <Dialog title={`Modify record, ${startCase(group)}: ${startCase(name)}.`} isOpen={isOpen} onClose={close}>
      {form}
    </Dialog>
  );
};

export default ViewRecordDialog;
