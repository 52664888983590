export const Roles = {
  Platform: {
    Administrator: 'platform-administrator',
    Manager: 'platform-manager',
    RegionalManager: 'platform-regional-manager',
    EvaluationManager: 'platform-evaluation-manager',
  },
  Vendor: {
    Manager: 'vendor-manager',
    Subordinate: 'vendor-subordinate',
  },
  Client: {
    Manager: 'client-manager',
    HiringManager: 'client-hiring-manager',
    ProjectManager: 'client-project-manager',
  },
  Supplier: {
    Manager: 'supplier-manager',
    Subordinate: 'supplier-subordinate',
    Witness: 'supplier-witness',
  },
};

export const SignatoriesRoles = [
  {
    id: Roles.Supplier.Manager,
    key: 'supplierManager',
    name: 'Supplier Manager',
    order: 10,
  },
  {
    id: Roles.Client.HiringManager,
    key: 'clientHiringManager',
    name: 'Hiring Manager',
    order: 20,
  },
  {
    id: Roles.Client.Manager,
    key: 'clientManager',
    name: 'Client Manager',
    order: 30,
  },
  {
    id: Roles.Platform.Administrator,
    key: 'platformAdministrator',
    name: 'Platform Administrator',
    order: 40,
  },
];

export const SignatoriesRolesMapper = SignatoriesRoles.reduce((acc, role) => {
  acc[role.id] = role;
  return acc;
}, {});
