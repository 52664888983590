import { Checkbox as BluePrintCheckbox } from '@blueprintjs/core';
import cn from 'classnames';
import React from 'react';

import { Field } from '../../form';
import { isItemSelected, itemsEqual, labelRenderer, loadingArray, valueRenderer } from './helper';

import styles from './checkbox.module.scss';

const CheckboxGroupField = ({ classes, label, description, options, inline, disabled, name, validate, loading, submitOnChange = false }) => {
  return (
    <Field
      classes={{
        ...classes,
        wrapper: cn(styles.wrapper, inline && styles.wrapperInline, classes?.wrapper),
        fieldset: cn(inline ? styles.horizontal : styles.vertical, classes?.fieldset),
      }}
      label={label}
      description={description}
      name={name}
      validate={validate}
    >
      {({ field, form }) => {
        const selectedItems = field.value || [];

        const handleSelectItem = item => {
          form.setFieldValue(name, [...selectedItems, valueRenderer(item)]);
        };

        const handleDeselectItem = item => {
          form.setFieldValue(
            name,
            selectedItems.filter(itemB => !itemsEqual(item, itemB)),
          );
        };

        const onSelectItem = item => {
          if (!isItemSelected(selectedItems, item)) {
            handleSelectItem(item);
          } else {
            handleDeselectItem(item);
          }
          if (submitOnChange) {
            form.submitForm();
          }
        };

        return (loading ? loadingArray : options)?.map(item => (
          <BluePrintCheckbox
            className={classes?.checkbox}
            checked={field.value?.includes(valueRenderer(item))}
            inline={inline}
            disabled={disabled}
            label={labelRenderer(item)}
            onChange={() => onSelectItem(item)}
            key={valueRenderer(item)}
          />
        ));
      }}
    </Field>
  );
};

export default CheckboxGroupField;
