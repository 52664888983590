import React, { useRef } from 'react';
import { Menu, MenuItem, ContextMenu } from '@blueprintjs/core';
import debounce from 'lodash/debounce';

import { Field } from '../../form';
import { Textarea } from '../textarea';

import styles from './macro-textarea-field.module.scss';

const RecursiveMenu = ({ item, onClick }) => {
  if (item.items?.length) {
    return (
      <MenuItem text={item.label}>
        {item.items.map(o => (
          <RecursiveMenu item={o} key={o.name} onClick={onClick} />
        ))}
      </MenuItem>
    );
  }

  return <MenuItem text={item.label} onClick={() => onClick(item)} />;
};

const MacroTextareaField = ({
  classes,
  name,
  type = 'text',
  label,
  placeholder,
  description,
  disabled,
  validate,
  submitOnChange = false,
  extra,
  options,
  literals = true,
  outline,
  minimal,
  showError,
}) => {
  const inputRef = React.useRef();
  const submitFormIfNeeded = useRef(
    debounce(form => {
      if (!!submitOnChange) {
        form.submitForm();
      }
    }, 150),
  );

  return (
    <Field classes={classes} name={name} label={label} description={description} validate={validate} outline={outline} minimal={minimal} showError={showError}>
      {({ field, form }) => {
        const addMacro = macro => {
          const insert = literals ? `$\{${macro.name}}` : macro.name;
          const fieldValue = field?.value || '';
          const selectionStart = inputRef.current.selectionStart;
          const idx = fieldValue.slice(0, selectionStart).length;
          const value = fieldValue.substr(0, idx) + insert + fieldValue.substr(idx);
          form.setFieldValue(name, value);
          submitFormIfNeeded.current(form);
          setTimeout(() => {
            inputRef.current.selectionStart = idx + insert.length;
          }, 100);
        };

        const content = (
          <Menu>
            {options.map(o => (
              <RecursiveMenu item={o} onClick={addMacro} key={`parent-${o.name}`} />
            ))}
          </Menu>
        );

        return (
          <ContextMenu content={content} className={styles.textareaField}>
            <Textarea
              inputRef={inputRef}
              disabled={disabled}
              placeholder={placeholder}
              type={type}
              {...extra}
              value={field.value}
              onChange={value => {
                form.setFieldValue(name, value);
                submitFormIfNeeded.current(form);
              }}
              onKeyDown={e => e.stopPropagation()}
            />
          </ContextMenu>
        );
      }}
    </Field>
  );
};
export default MacroTextareaField;
