import React from 'react';
import ProgressMessage from './progress-message';
import { getRandomIntBetween } from '../../utilities/common';

class ProgressToast {
  constructor({ toaster }) {
    this.toaster = toaster;
  }

  start(toast) {
    this.progress = toast;
    this.value = 0;
    this.key = this.toaster.show(this.renderProgress(0));
    this.interval = setInterval(() => {
      if (this.toaster == null || this.value >= 100) {
        clearInterval(this.interval);
      } else {
        this.value += this.getProgressValue();
        this.toaster.show(this.renderProgress(this.value), this.key);
      }
    }, 1000);
  }

  complete(toast) {
    clearInterval(this.interval);

    this.interval = setInterval(() => {
      if (this.toaster == null || this.value >= 100) {
        clearInterval(this.interval);
        this.toaster.show(
          {
            message: <ProgressMessage title={toast?.title} description={toast?.description} value={100} complete />,
            timeout: toast?.timeout || 2000,
          },
          this.key,
        );
      } else {
        this.value += 5;
        this.toaster.show(this.renderProgress(this.value), this.key);
      }
    }, 10);
  }

  error(error) {
    clearInterval(this.interval);
    this.value = 0;
    this.toaster.show(
      {
        message: error?.description || 'Something went wrong. Please try again.',
        intent: 'danger',
      },
      this.key,
    );
  }

  close() {
    clearInterval(this.interval);
    this.interval = null;
    this.value = 0;
    this.toaster.dismiss(this.key);
  }

  renderProgress(value) {
    return {
      message: <ProgressMessage title={this.progress.title} description={this.progress.description} value={value} />,
      timeout: value < 100 ? 0 : this.progress.timeout || 1000, // zero timeout for infinite progress
    };
  }

  getProgressValue() {
    if (this.value < 20) {
      return getRandomIntBetween(1, 10);
    } else if (this.value < 50) {
      return getRandomIntBetween(1, 6);
    } else if (this.value < 80) {
      return getRandomIntBetween(1, 4);
    } else if (this.value < 90) {
      return getRandomIntBetween(1, 2);
    } else if (this.value < 95) {
      return 0.1;
    } else if (this.value < 97) {
      return 0.0001;
    }
  }
}

export default ProgressToast;
