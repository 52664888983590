import Clients from '../configuration/client';
import { StorageManager } from '../authentication/storage';
import { toQueryString } from '../utilities/common';

export const getMembers = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/members/${toQueryString(query)}`);
  return response?.data;
};

export const inviteMember = async ({ roleId, organizationId, firstName, lastName, email }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const payload = { roleId, organizationId, firstName, lastName, email };
  const response = await Clients.Api.post(`ui/${organizationType}s/members/invite`, payload);
  return response?.data;
};

export const resendMemberInvite = async ({ inviteId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/members/invite/${inviteId}`);
  return response?.data;
};

export const cancelMemberInvite = async ({ inviteId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/members/invite/${inviteId}`);
  return response?.data;
};

export const updateMember = async ({ roleId, membershipId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const payload = { roleId, membershipId };
  const response = await Clients.Api.put(`ui/${organizationType}s/members`, payload);
  return response?.data;
};

export const removeMember = async ({ membershipId, replacement }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/members/${membershipId}/${toQueryString({ replacement })}`);
  return response?.data;
};
