import React from 'react';
import { Button, Popover } from '@blueprintjs/core';
import cn from 'classnames';
import * as Yup from 'yup';

import Form from '../../form';
import TextField from '../text';
import ResourceFiles from '../../resource-files';

import styles from './rich-text.module.scss';

const urlExpression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

const AddLinkValidationSchema = Yup.object().shape({
  text: Yup.string().required('Text is required'),
  link: Yup.string().matches(urlExpression, 'Enter a valid url').required('Url is required'),
});

const LinkModal = props => {
  const { config, expanded, currentState, onExpandEvent, doCollapse, onChange } = props;

  const initialValues = {
    text: currentState.selectionText || currentState.link?.title,
    link: currentState.link?.target,
  };

  const removeLinkFromText = () => onChange();

  return (
    <div>
      <Popover
        isOpen={expanded}
        content={
          <div className={styles.linkOverridePopover} onClick={event => event.stopPropagation()}>
            <Form
              validationSchema={AddLinkValidationSchema}
              initialValues={initialValues}
              enableReinitialize={false} // This is important to prevent the form from resetting the values. For some reason, currentState.selectionText changes when the file is uploaded
              onSubmit={values => {
                onChange('link', values.text, values.link, config.defaultTargetOption);
              }}
            >
              {form => {
                return (
                  <React.Fragment>
                    <TextField name="text" label="Text" />
                    <TextField
                      name="link"
                      label="Url"
                      extra={{
                        rightElement: (
                          <ResourceFiles
                            upload
                            browse
                            button={<Button type="button" text="Resource" minimal small />}
                            current={currentState.link?.target}
                            extensions=".pdf"
                            shouldDismissPopover={true}
                            onSubmit={resource => {
                              form.setValues({ ...form.values, link: resource });
                            }}
                          />
                        ),
                      }}
                    />
                    <div className={styles.popoverFooter}>
                      <Button fill outlined type="submit" text="Submit" disabled={!form.isValid} />
                      <Button fill outlined type="button" text="Cancel" onClick={doCollapse} />
                    </div>
                  </React.Fragment>
                );
              }}
            </Form>
          </div>
        }
      >
        <div className="rdw-link-wrapper" aria-label="rdw-link-control">
          <div className="rdw-option-wrapper" title="Link" onClick={onExpandEvent}>
            <img src={config.link.icon} alt="Link" />
          </div>
          <div
            className={cn('rdw-option-wrapper', !initialValues.link && 'rdw-option-disabled')}
            title="Unlink"
            onClick={initialValues.link && removeLinkFromText}
          >
            <img src={config.unlink.icon} alt="Unlink" />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default LinkModal;
