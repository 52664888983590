import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../components/dialog';
import Form from '../../../../../../components/form';
import Fields from '../../../../../../components/fields';

import useApi from '../../../../../../hooks/use-api';
import { createClient } from '../../../../../../apis/platform/organizations';

import classes from '../../../clients.module.scss';
import ClientPoliciesDescriptions from '../../../../../../components/organizational/client/client-policies-description';

const NewClient = ({ isOpen, onClose, reloadList }) => {
  const createClientApi = useApi(createClient, { skip: true });
  const loading = createClientApi.loading;

  const submit = async values => {
    await createClientApi.request(values);
    reloadList();
    onClose();
  };

  return (
    <Dialog title="New Client" isOpen={isOpen} onClose={onClose}>
      <div className={classes.newClient}>
        <Form
          initialValues={{ name: '', isInvoiced: true }}
          validationSchema={Yup.object().shape({ name: Yup.string().required('Client name is required') })}
          onSubmit={submit}
        >
          {form => (
            <React.Fragment>
              <Fields.Text name="name" label="Name" />
              <Fields.Select
                label="Is this client a partner ?"
                name="isPartner"
                options={[
                  { label: 'The client is a partner.', value: true },
                  { label: 'The client is not a partner.', value: false },
                ]}
              />
              {ClientPoliciesDescriptions.isPartner(form?.values)}
              <Fields.Select
                label="Will separate invoices need to be created for this client ?"
                name="isInvoiced"
                options={[
                  { label: 'Separate invoices will be created for this client.', value: true },
                  { label: 'Separate invoices will not be created for this client.', value: false },
                ]}
                disabled
              />
              {ClientPoliciesDescriptions.isInvoiced(form?.values)}
              <Button type="submit" outlined fill text="Submit" disabled={loading} loading={loading} />
            </React.Fragment>
          )}
        </Form>
      </div>
    </Dialog>
  );
};

export default NewClient;
