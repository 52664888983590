import React from 'react';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getColor } from '../../../utilities/common';

const data = [
  {
    month: 'Mar',
    ic: 1000,
    ee: 1400,
    total: 2400,
  },
  {
    month: 'Apr',
    ic: 1000,
    ee: 1210,
    total: 2210,
  },
  {
    month: 'May',
    ic: 2000,
    ee: 6800,
    total: 8800,
  },
  {
    month: 'Jun',
    ic: 2780,
    ee: 3908,
    total: 6688,
  },
  {
    month: 'Jul',
    ic: 1890,
    ee: 4800,
    total: 6690,
  },
  {
    month: 'Aug',
    ic: 2390,
    ee: 4800,
    total: 7190,
  },
  {
    month: 'Sep',
    ic: 3490,
    ee: 4300,
    total: 7790,
  },
];

const WorkerCharts = () => {
  return (
    <ResponsiveContainer>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 30,
          right: 30,
          left: 30,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <Tooltip />
        <Bar dataKey="ee" name="Employees" stackId="a" fill={getColor('Employees')} />
        <Bar dataKey="ic" name="Contractors" stackId="a" fill={getColor('Contractors')} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WorkerCharts;
