import React from 'react';

import Spinner from '../../../../spinner';

import classes from '../records.module.scss';

const LoadingRecord = () => {
  return (
    <div className={classes.recordLoading}>
      <Spinner blend global={false} />
    </div>
  );
};

export default LoadingRecord;
