import React, { useEffect } from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import { NavLink } from 'react-router-dom';

import Box from '../box';
import Spinner from '../spinner';

import { getClientDisplayName } from '../../utilities/client';
import {
  displayAllocation,
  displayCompensationFrequency,
  displayCompensationType,
  displayEndDate,
  displayRate,
  displayStartDate,
} from '../../utilities/engagement';
import { getLocationTypeName } from '../../utilities/location';
import { classificationPreferenceTypeToLabel } from '../../domain/classification-preference-type';

import { ClassificationType, classificationTypeToLabel } from '../../domain/classification-type';
import { engagementStatusToLabel } from '../../domain/engagement-status-type';

import useApi from '../../hooks/use-api';
import { getSupplierEngagements } from '../../apis/platform/suppliers';

import TimelineEngagementFinalActionsBox from './timeline-final-actions-box';

import classes from './timeline.module.scss';

const TimelineEngagement = ({ loading, engagement, engagementFlow, reload }) => {
  const otherEngagementsApi = useApi(getSupplierEngagements, { skip: true });

  useEffect(() => {
    if (!!engagement?.supplierId) {
      otherEngagementsApi?.request({ supplierId: engagement?.supplierId });
    }
  }, [engagement?.supplierId]);

  if (loading || otherEngagementsApi?.loading) {
    return (
      <div className={classes.engagement}>
        <div className={classes.loading}>
          <Spinner global={false} blend />
        </div>
      </div>
    );
  }

  const client = getClientDisplayName(engagement?.clientName, engagement?.parentClientName);
  const rate = displayRate(engagement?.compensation?.amount, engagement?.compensation?.currency?.symbol, engagement?.compensation?.type);
  const allocation = displayAllocation(engagement?.compensation?.allocation, engagement?.compensation?.type);
  const start = displayStartDate(engagement?.startDate);
  const end = displayEndDate(engagement?.endDate);

  return (
    <div className={classes.engagement}>
      <Box title="Engagement" monochrome small expandible={{ expanded: true }}>
        <Box.Item label="Title">
          {engagement?.title}
          <Popover
            content={
              <div className={classes.popover}>
                <p>
                  <strong>Engagement description</strong>
                </p>
                {engagement?.description}
              </div>
            }
          >
            <Button small outlined icon={<Icons.Info size={16} strokeWidth={1.5} />} title="Show engagement description" />
          </Popover>
        </Box.Item>
        <Box.Item label="Duration">{`${start} - ${end}`}</Box.Item>
        <Box.Item label="Status">{engagementStatusToLabel(engagement?.status)}</Box.Item>
      </Box>
      <TimelineEngagementFinalActionsBox {...{ engagement, engagementFlow, reload }} />
      <Box title="Supplier" monochrome small expandible={{ expanded: false }}>
        <Box.Item label="Business name">
          {engagement?.supplierName}
          <NavLink role="button" to={`/suppliers/${engagement?.supplierId}`}>
            <Button small outlined icon={<Icons.ArrowUpRight size={16} strokeWidth={1.5} />} title="View supplier profile" />
          </NavLink>
        </Box.Item>
        <Box.Item label="Individual name">{engagement?.supplierManagerName}</Box.Item>
        <Box.Item label="Email">
          {!!engagement?.supplierManagerEmail && <a href={`mailto:${engagement?.supplierManagerEmail}`}>{engagement?.supplierManagerEmail}</a>}
        </Box.Item>
      </Box>
      <Box title="Location" monochrome small expandible={{ expanded: false }}>
        <Box.Item label="Country">{engagement?.country?.name}</Box.Item>
        <Box.Item label="Location type">{getLocationTypeName(engagement?.locationType)}</Box.Item>
        <Box.Item label="Location">{engagement?.location?.prettyName || 'Not provided'}</Box.Item>
      </Box>
      <Box title="Client" monochrome small expandible={{ expanded: false }}>
        <Box.Item label="Name">
          {client}
          <NavLink role="button" to={`/clients/${engagement?.clientId}`}>
            <Button small outlined icon={<Icons.ArrowUpRight size={16} strokeWidth={1.5} />} title="View client" />
          </NavLink>
        </Box.Item>
        <Box.Item label="Department">{engagement?.department || '-'}</Box.Item>
        <Box.Item label="Hiring manager">{engagement?.managerName}</Box.Item>
        <Box.Item label="Email">{!!engagement?.managerEmail && <a href={`mailto:${engagement?.managerEmail}`}>{engagement?.managerEmail}</a>}</Box.Item>
      </Box>
      <Box title="Compensation" monochrome small expandible={{ expanded: false }}>
        <Box.Item label="Currency">{engagement?.compensation?.currency?.name}</Box.Item>
        <Box.Item label="Frequency">{displayCompensationFrequency(engagement?.compensation?.frequency)}</Box.Item>
        <Box.Item label="Type">{displayCompensationType(engagement?.compensation?.type)}</Box.Item>
        <Box.Item label="Rate">{rate}</Box.Item>
        <Box.Item label="Allocation">{allocation || '-'}</Box.Item>
        {engagement?.classification?.result === ClassificationType.Employee && (
          <Box.Item label="Is exempt ?">{!!engagement?.compensation?.isExempt ? 'Yes' : 'No'}</Box.Item>
        )}
        <Box.Item label="Tracks work">{!!engagement?.compensation?.tracksWork ? 'Yes' : 'No'}</Box.Item>
      </Box>
      <Box title="Classification" monochrome small expandible={{ expanded: false }}>
        <Box.Item label="Client preference">{classificationPreferenceTypeToLabel(engagement?.classification?.clientPreference)}</Box.Item>
        <Box.Item label="Supplier preference">{classificationTypeToLabel(engagement?.classification?.supplierPreference)}</Box.Item>
        <Box.Item label="Result">
          {engagement?.classification?.result === 'unknown' ? 'tbd' : classificationTypeToLabel(engagement?.classification?.result)}
        </Box.Item>
      </Box>
      <Box title="Other engagements" monochrome small expandible>
        {otherEngagementsApi?.response?.filter?.(e => e?.id !== engagement?.id)?.length ? (
          otherEngagementsApi?.response
            ?.filter?.(e => e?.id !== engagement?.id)
            ?.map?.(e => (
              <Box.Item key={e?.id}>
                <Box.ItemValue>{e?.title}</Box.ItemValue>
                <Box.ItemLabel>{engagementStatusToLabel(e?.status)}</Box.ItemLabel>
                <NavLink role="button" to={`/engagements/${e?.id}`}>
                  <Button small outlined icon={<Icons.ArrowUpRight size={16} strokeWidth={1.5} />} title="View engagement" />
                </NavLink>
              </Box.Item>
            ))
        ) : (
          <Box.Item>
            <Box.ItemLabel fill>No records found.</Box.ItemLabel>
          </Box.Item>
        )}
      </Box>
    </div>
  );
};

export default TimelineEngagement;
