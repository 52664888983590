import React from 'react';
import cn from 'classnames';

import { getContentItemStyle } from '../../../helpers/get-content-item-style';

import classes from './preview-content-item.module.scss';

const fakeArray = new Array(5).fill(0);

const PreviewTableContentItem = ({ contentItem, hovered }) => {
  const style = { ...contentItem.styleConfig, ...getContentItemStyle(contentItem.customStyle) };
  return (
    <div className={cn(classes.previewTableContentItem, contentItem.class, hovered === contentItem.id && classes.hovered)} style={style}>
      <table style={{ width: contentItem?.styleConfig?.width || 'auto' }}>
        <thead>
          <tr>
            {contentItem.content.map(item => (
              <th key={item.id} style={item.styleConfig} width={item.styleConfig?.width}>
                {item.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fakeArray.map((_, index) => (
            <tr key={index}>
              {contentItem.content.map(item => (
                <td key={item.id} style={item.styleConfig} width={item.styleConfig?.width}>
                  {item.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PreviewTableContentItem;
