import Clients from '../configuration/client';

export const getCountries = async () => {
  const response = await Clients.Api.get('ui/lookup/countries');
  return response?.data;
};

export const getCurrencies = async () => {
  const response = await Clients.Api.get('ui/lookup/currencies');
  return response?.data;
};

export const getProviderTypes = async () => {
  const response = await Clients.Api.get('ui/lookup/providers');
  return response?.data;
};
