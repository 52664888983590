import _ from 'lodash';
import { OrganizationType } from '../domain/organization-type';

export const uuid = (n = 36) => {
  return '00000000-0000-4000-8000-000000000000'.replace(/0/g, () => (0 | (Math.random() * 16)).toString(16)).slice(0, n);
};

export const getRandomIntBetween = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const isEmpty = str => str === undefined || str === null || str === '';
export const nth = n => `${n}${['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'}`;

export const base64URLEncode = value => value.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
export const toSnakeCase = str =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

export const toUrlEncoded = obj =>
  Object.keys(obj)
    .map(k => encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]))
    .join('&');

export const toQueryString = (data, includeQuestionMark = true) => {
  const query = Object.keys(data)
    .filter(key => data[key] !== undefined && data[key] !== null && data[key] !== '')
    .map(key => key + '=' + data[key])
    .join('&');

  if (query && includeQuestionMark) return `?${query}`;

  return '';
};

export const randomInteger = (bound = 10) => Math.round(Math.random() * bound);

export const distinct = items => {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  return (items || []).filter(onlyUnique);
};

export const capitalize = value => {
  if (!value) return;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const sanitize = value => {
  if (value === null || value === undefined || value === '' || value.trim() === '') return value;

  // if string starts with number prefix it with 'x'
  // remove all special characters
  // replace all spaces with underscores

  return (value.match(/^\d/) ? `X ${value}` : value)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .join('_');
};

export const urlSanitize = value => {
  if (value === null || value === undefined || value === '' || value.trim() === '') return value;

  // if string starts with number prefix it with 'x'
  // remove all special characters
  // replace all spaces with underscores

  return (value.match(/^\d/) ? `X ${value}` : value)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .join('-');
};

export const getTimeBasedGreeting = () => {
  const currentHour = new Date().getHours();

  if (currentHour >= 3 && currentHour < 12) {
    return 'Good morning';
  }

  if (currentHour >= 12 && currentHour <= 17) {
    return 'Good afternoon';
  }

  return 'Good evening';
};

export const hexToRgb = hex => {
  if (!hex) return '0, 0, 0';
  const color = sanitize(hex);

  var parts = color.match(/.{1,2}/g);
  var rgb = `${parseInt(parts[0], 16)}, ${parseInt(parts[1], 16)}, ${parseInt(parts[2], 16)}`;
  return rgb;
};

export const RANDOM_COLORS = ['#6b5885ff', '#802bf0ff', '#2e4057ff', '#692636ff', '#11ad86ff', '#da4167ff', '#683f9eff', '#ff7094ff'];

export const getColor = value => {
  var hash = 0;
  let chr;
  for (let i = 0; i < value?.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return RANDOM_COLORS[Math.abs(hash) % 8];
};

export const getColorVariant = (colorCode, amount) => {
  var usePound = false;

  if (colorCode[0] === '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  var num = parseInt(colorCode, 16);

  var r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  var b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  var g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const getInitials = name => {
  if (!name || !name.trim()) return '?';

  const parts = name.split(' ');
  const initials = parts.map(p => (p.length ? p[0].toUpperCase() : '')).join('');

  if (!!initials && initials.length) {
    return initials.length >= 2 ? initials.substr(0, 2) : initials[0];
  }
  return '?';
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

export const getAbbreviation = text => {
  if (typeof text != 'string' || !text) {
    return '';
  }

  const caseSplit = _.startCase(text);

  const acronym = caseSplit
    .match(/[\p{Alpha}\p{Nd}]+/gu)
    .reduce((previous, next) => previous + (+next === 0 || parseInt(next) ? parseInt(next) : next[0] || ''), '')
    .toUpperCase();

  return acronym.length > 3 ? acronym.substring(0, 3) : acronym;
};

export const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const hash = value => {
  if (!value) return -1;
  var data = JSON.stringify(value);
  var hash = 0;
  if (data.length === 0) return hash;

  for (let i = 0; i < data.length; i++) {
    let char = data.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
};

export const setCssVariable = (variable, value) => {
  document.documentElement.style.setProperty(variable, value);
};

export const setPrimaryColor = color => {
  setCssVariable('--primary', color);
  setCssVariable('--primary-rgb', hexToRgb(color));
};

export const setRandomPrimaryColor = () => {
  const color = getColor(`${Math.random()}`);
  setCssVariable('--primary', color);
  setCssVariable('--primary-rgb', hexToRgb(color));
};

export const getScrollableParent = node => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollableParent(node.parentNode);
  }
};

export const resolveOrganizationType = organizationId => {
  var letter = organizationId?.split('-')?.[0];
  switch (letter) {
    case 'P':
      return OrganizationType.Platform;

    case 'C':
      return OrganizationType.Client;

    case 'V':
      return OrganizationType.Vendor;

    case 'S':
      return OrganizationType.Supplier;

    default:
      return undefined;
  }
};
