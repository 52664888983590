import {
  APPLICATION_SET_OFFLINE,
  APPLICATION_SET_ACTIVITY_OPEN,
  APPLICATION_TOGGLE_ACTIVITY_OPEN,
  APPLICATION_SET_THEMES,
  APPLICATION_CHANGE_THEME,
} from '../types';

const initialState = {
  offline: false,
  activityOpen: false,
  themes: undefined,
  theme: undefined,
};

const ApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_SET_OFFLINE:
      return { ...state, offline: action.data };

    case APPLICATION_SET_ACTIVITY_OPEN:
      return { ...state, activityOpen: action.data };

    case APPLICATION_TOGGLE_ACTIVITY_OPEN:
      return { ...state, activityOpen: !state.activityOpen };

    case APPLICATION_SET_THEMES:
      return { ...state, themes: action.data?.themes || state.themes, theme: action.data?.theme || state.theme };

    case APPLICATION_CHANGE_THEME:
      return { ...state, theme: action.data };

    default:
      return state;
  }
};

export default ApplicationReducer;
