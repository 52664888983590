import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../components/box';
import Form from '../../../../components/form';
import Fields from '../../../../components/fields';

import WithConfirmation from '../../../../hooks/with-confirmation';
import useApi from '../../../../hooks/use-api';
import { getDocuments, uploadDocument, downloadDocument, removeDocument } from '../../../../apis/documents';

import classes from '../profile.module.scss';

const Documents = ({ user }) => {
  const getDocumentsApi = useApi(() => getDocuments({ type: 'user', id: user?.id }));

  return (
    <div className={classes.tab}>
      <Box
        title="Documents"
        outlined
        monochrome
        actions={
          <ButtonGroup>
            <Form initialValues={{ file: null }} onSubmit={() => null}>
              <div className={classes.neg15}>
                <Fields.File
                  fill
                  outline={false}
                  name="file"
                  label="Upload file"
                  api={file => uploadDocument({ file, name: file?.name, type: 'user', id: user?.id })}
                  onUploaded={getDocumentsApi.request}
                >
                  <Button small outlined icon={<Icons.Upload size={16} strokeWidth={1.5} />} title="Upload" />
                </Fields.File>
              </div>
            </Form>
          </ButtonGroup>
        }
        loading={getDocumentsApi.loading}
      >
        {getDocumentsApi.loading
          ? [1, 2, 3, 4, 5].map((_, i) => (
              <Box.TabularItem key={i}>
                <Box.ItemValue className={Classes.SKELETON}>name</Box.ItemValue>
                <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
                <ButtonGroup>
                  <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
                  <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
                </ButtonGroup>
              </Box.TabularItem>
            ))
          : getDocumentsApi.response?.map((document, i) => (
              <Box.TabularItem key={document.name}>
                <Box.ItemValue>{document.name}</Box.ItemValue>
                <Box.ItemLabel>{document.contentType}</Box.ItemLabel>
                <ButtonGroup>
                  <Button small outlined icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(document)} />
                  <WithConfirmation icon={true}>
                    <Button
                      small
                      outlined
                      icon={<Icons.X size={16} strokeWidth={1.5} />}
                      onClick={async () => {
                        await removeDocument(document);
                        await getDocumentsApi?.request({ type: 'user', id: user?.id });
                      }}
                    />
                  </WithConfirmation>
                </ButtonGroup>
              </Box.TabularItem>
            ))}
        {!getDocumentsApi.loading && !getDocumentsApi.response?.length && (
          <Box.TabularItem>
            <Box.ItemLabel fill>No records found.</Box.ItemLabel>
          </Box.TabularItem>
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
