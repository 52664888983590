import React from 'react';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';

import classes from '../properties.module.scss';

const ImageConfigForm = ({ availableMacros, contentItem, onSubmit }) => {
  return (
    <Form initialValues={contentItem} onSubmit={onSubmit} enableReinitialize={true}>
      <React.Fragment>
        <div className={classes.group}>
          <Fields.MacroTextarea
            label="Text"
            name="text"
            description="Right click to insert macros"
            options={availableMacros}
            submitOnChange={true}
            extra={{ growVertically: true }}
            showError={false}
          />
          <Fields.MacroTextarea
            label="Insert source link"
            name="value"
            description="Right click to insert macros"
            options={availableMacros}
            submitOnChange={true}
            extra={{ growVertically: true }}
            showError={false}
          />
        </div>
        <div className={classes.group}>
          <div className={classes.line}>
            <Fields.Text
              name="styleConfig.width"
              maskConfig={{
                mask: '0[00]px',
                lazy: false,
              }}
              label="Width"
              placeholder="px"
              submitOnChange={true}
              showError={false}
            />
            <Fields.Text
              name="styleConfig.height"
              maskConfig={{
                mask: '0[00]px',
                lazy: false,
              }}
              label="Height"
              placeholder="px"
              submitOnChange={true}
              showError={false}
            />
          </div>
          <div className={classes.line}>
            <Fields.Text
              name="styleConfig.paddingTop"
              maskConfig={{
                mask: '0[0]px',
                lazy: false,
              }}
              label="Top"
              submitOnChange={true}
              showError={false}
            />
            <Fields.Text
              name="styleConfig.paddingRight"
              maskConfig={{
                mask: '0[0]px',
                lazy: false,
              }}
              label="Right"
              submitOnChange={true}
              showError={false}
            />
            <Fields.Text
              name="styleConfig.paddingBottom"
              maskConfig={{
                mask: '0[0]px',
                lazy: false,
              }}
              label="Bottom"
              submitOnChange={true}
              showError={false}
            />
            <Fields.Text
              name="styleConfig.paddingLeft"
              maskConfig={{
                mask: '0[0]px',
                lazy: false,
              }}
              label="Left"
              submitOnChange={true}
              showError={false}
            />
          </div>
        </div>
        <div className={classes.group}>
          <Fields.Text label="Class name" name="class" submitOnChange={true} showError={false} />
          <Fields.Textarea label="Override style" name="customStyle" submitOnChange={true} showError={false} />
        </div>
      </React.Fragment>
    </Form>
  );
};

export default ImageConfigForm;
