import React from 'react';
import cn from 'classnames';

import { getContentItemStyle } from '../../../helpers/get-content-item-style';

import classes from './preview-content-item.module.scss';

const PreviewButtonContentItem = ({ contentItem, hovered }) => {
  const style = { ...contentItem.styleConfig, ...getContentItemStyle(contentItem.customStyle) };
  return (
    <a
      className={cn(classes.button, contentItem.class, hovered === contentItem.id && classes.hovered)}
      href={contentItem.value}
      target="_blank"
      rel="noreferrer"
      style={style}
    >
      {contentItem.text || 'Unset'}
    </a>
  );
};

export default PreviewButtonContentItem;
