import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../../../components/dialog';
import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';

import useApi from '../../../../../../../../hooks/use-api';
import { addWorkLocation } from '../../../../../../../../apis/client/organizations';

const WorkLocationForm = ({ isOpen = false, onClose, api, clientId }) => {
  const addWorkLocationApi = useApi(addWorkLocation, { skip: true });

  const submit = async ({ address, description }, { setFieldError }) => {
    if (!address?.place_id) {
      setFieldError('address', 'Unable to resolve requested location.');
      return;
    }

    await addWorkLocationApi?.request({ clientId, description, placeId: address.place_id });
    await api.request();
    onClose();
  };

  const loading = api?.loading || addWorkLocationApi?.loading;

  return (
    <Dialog title="Work Location" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ description: '', address: null }} onSubmit={submit}>
        <Fields.Text name="description" label="Description" />
        <Fields.Address fill label="Address" name="address" precision="address" />
        <Button type="submit" fill outlined text="Submit" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkLocationForm);
