import React from 'react';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import { NavLink } from 'react-router-dom';

import Box from '../../../../../components/box';

import useApi from '../../../../../hooks/use-api';
import { getTimeReviewRecordStats } from '../../../../../apis/client/time';

import classes from '../../dashboard.module.scss';
import classNames from 'classnames';

const ReviewBox = () => {
  const api = useApi(getTimeReviewRecordStats);

  return (
    <div>
      <Box
        title="Time review"
        className={classes.reviewBox}
        contentClassName={classes.review}
        actions={
          <ButtonGroup>
            <NavLink role="button" to="/time/review">
              <Button outlined small loading={api?.loading} icon={<Icons.ArrowUpRight size={16} strokeWidth={1.5} />} />
            </NavLink>
          </ButtonGroup>
        }
      >
        <div className={classes.container}>
          <div className={classNames(classes.complete, api?.loading && Classes.SKELETON)}>
            <div className={classes.value}>{api?.loading ? 0 : api?.response?.complete}</div>
            <div className={classes.label}>Complete</div>
          </div>
          <div className={classNames(classes.inProgress, api?.loading && Classes.SKELETON)}>
            <div className={classes.value}>{api?.loading ? 0 : api?.response?.inReview}</div>
            <div className={classes.label}>In review</div>
          </div>
          <div className={classNames(classes.pending, api?.loading && Classes.SKELETON)}>
            <div className={classes.value}>{api?.loading ? 0 : api?.response?.pending}</div>
            <div className={classes.label}>Pending</div>
          </div>
          <div className={classNames(classes.empty, api?.loading && Classes.SKELETON)}>
            <div className={classes.value}>{api?.loading ? 0 : api?.response?.empty}</div>
            <div className={classes.label}>Empty</div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ReviewBox;
