import { Button } from '@blueprintjs/core';
import React from 'react';
import * as Yup from 'yup';

import { setEmergencyContact } from '../../../../../../apis/profile';
import Dialog from '../../../../../../components/dialog';
import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';
import useApi from '../../../../../../hooks/use-api';
import classes from '../../../company.module.scss';

const EmergencyContactForm = ({ isOpen = false, user, onClose, onSave }) => {
  const setEmergencyContactApi = useApi(setEmergencyContact, { skip: true });
  const submit = async values => {
    const emergencyContact = await setEmergencyContactApi.request(values);
    onSave(emergencyContact);
  };

  const loading = setEmergencyContactApi.loading;

  return (
    <Dialog title="Emergency contact" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ userId: user?.id, ...(user?.emergencyContact || {}) }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First name is required.'),
          lastName: Yup.string().required('Last name is required.'),
          relationship: Yup.string().required('Relationship is required.'),
          email: Yup.string().email('Not valid email address.').required('Email is required.'),
          phone: Yup.string().required('Phone number is required.'),
        })}
        onSubmit={submit}
      >
        <div className={classes.hRow}>
          <Fields.Text fill label="First Name" name="firstName" />
          <Fields.Text fill label="Last Name" name="lastName" />
        </div>
        <Fields.Text label="Relationship" name="relationship" />
        <Fields.Text label="Email" name="email" />
        <Fields.Text label="Phone" name="phone" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default EmergencyContactForm;
