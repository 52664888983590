import React from 'react';
import * as Icons from 'react-feather';

import Navigation from '../../components/navigation';

import Profile from '../common/profile';
import Privacy from '../common/privacy';
import Dashboard from './dashboard';
import CompanyInfo from './company-info';
import Clients from './clients';
import Engagements from './engagements';
import Time from './time';
import Invoices from './invoices';
// import Support from './support';
import UserDetails from './users';
import Evaluations from './evaluations';

const Client = () => {
  return (
    <Navigation.Container>
      <Navigation.Item path="/dashboard" title="Dashboard" icon={Icons.Grid} component={Dashboard} index />
      <Navigation.Item path="/evaluations/*" title="Evaluations" icon={Icons.BookOpen} component={Evaluations} />
      <Navigation.Item
        path="/company-info"
        title="Company"
        icon={Icons.Archive}
        component={CompanyInfo}
        condition={({ membership }) => membership?.roleId === 'client-manager'}
      />
      <Navigation.Item
        path="/clients/*"
        title="Clients"
        icon={Icons.Briefcase}
        component={Clients}
        condition={({ membership }) => membership?.organization?.policies?.isPartner}
      />
      <Navigation.Item path="/engagements/*" title="Engagements" icon={Icons.Briefcase} component={Engagements} />
      <Navigation.Item path="/time/*" title="Time" icon={Icons.Clock} component={Time} />
      <Navigation.Item
        path="/invoices"
        title="Invoices"
        icon={Icons.CreditCard}
        component={Invoices}
        condition={({ membership }) => membership?.roleId === 'client-manager'}
      />
      {/* <Navigation.Item path="/support" title="Support" icon={Icons.HelpCircle} component={Support} /> */}
      <Navigation.Item path="/users/:id" component={UserDetails} />
      <Navigation.Item path="/profile" component={Profile} />
      <Navigation.Item path="/privacy" component={Privacy} />
    </Navigation.Container>
  );
};

export default Client;
