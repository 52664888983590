import React, { useState } from 'react';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import Form from '../form';
import Fields from '../fields';

import { uploadResourceDocument } from '../../apis/documents';

import ResourceDialog from './dialog';

import classes from './resource-files.module.scss';

const ResourceFiles = ({ upload, browse, button, onSubmit, current, extensions = undefined, styles = '', minimal, shouldDismissPopover = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classNames(classes.resources, styles)}>
      <Popover
        content={
          <Menu>
            {!!upload && (
              <Form initialValues={{ file: null }} onSubmit={() => null}>
                <Fields.File
                  classes={classes.upload}
                  label="Upload resource"
                  placeholder="Upload"
                  resultTextParser={() => 'Upload'}
                  name="file"
                  maxSize={10}
                  api={file => uploadResourceDocument({ file })}
                  onUploaded={document => onSubmit(document?.url)}
                  minimal={true}
                  outline={false}
                  showError={false}
                >
                  <MenuItem text="Upload" shouldDismissPopover={false} />
                </Fields.File>
              </Form>
            )}
            {!!browse && <MenuItem text="Browse" onClick={() => setOpen(true)} shouldDismissPopover={shouldDismissPopover} />}
          </Menu>
        }
      >
        {button || (
          <Button fill text="Resources" minimal={minimal} alignText="left" icon={<Icons.Folder size={16} strokeWidth={1.5} />} rightIcon="chevron-down" />
        )}
      </Popover>
      <ResourceDialog {...{ onSubmit, open, onClose: () => setOpen(false), current, extensions }} />
    </div>
  );
};

export default ResourceFiles;
