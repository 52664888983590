import React from 'react';
import Spinner from '../../../spinner';

import classes from '../system-configurator.module.scss';

const loader = ({ loading, height }) =>
  !!loading && (
    <div className={classes.splash} style={{ height: `${height - 80}px` }}>
      <Spinner blend global={false} />
    </div>
  );

const empty = ({ loading, response, message, height }) =>
  !loading &&
  !!response &&
  response.length === 0 && (
    <div className={classes.splash} style={{ height: `${height - 80}px` }}>
      <div className={classes.message}>{message}</div>
    </div>
  );

export const SystemConfiguratorElements = {
  Loader: loader,
  EmptyList: empty,
};
