import React from 'react';

import { uploadClientIcon, uploadClientLogo } from '../../../../../../../apis/platform/organizations';
import { createRecord, getAppliedConfiguration, getScopes, updateRecord } from '../../../../../../../apis/configuration';
import useApi from '../../../../../../../hooks/use-api';
import UploadLogo from '../../../../../../../components/organizational/client/appearance/upload-logo';
import UploadIcon from '../../../../../../../components/organizational/client/appearance/upload-icon';
import Colors from '../../../../../../../components/organizational/client/appearance/colors';
import AppearanceInstructions from '../../../../../../../components/organizational/client/appearance/instructions';

import classes from '../../details.module.scss';

const Appearance = ({ api }) => {
  const client = api.response;
  const getScopesApi = useApi(() => getScopes({ organizationId: client?.id, includeInherited: true }), { skip: !client?.id });
  const globalScope = getScopesApi.response?.find(scope => !scope.parentId);

  const getThemesRecordsApi = useApi(() => getAppliedConfiguration({ organizationId: client?.id, type: 'UserInterface|Theme' }), {
    skip: !client?.id,
  });

  const themeRecord = getThemesRecordsApi.response
    ?.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt))
    ?.find(record => record.organization?.id === client?.id);
  const theme = themeRecord?.data;

  const handleSave = async values => {
    const payload = {
      id: themeRecord?.recordId,
      scopeId: themeRecord?.scopeId || globalScope?.id,
      description: themeRecord?.description,
      configurationType: 'UserInterface|Theme',
      data: {
        icon: themeRecord?.data?.icon,
        logo: themeRecord?.data?.logo,
        ...values,
      },
    };
    if (payload?.id) {
      await updateRecord(payload?.id, payload);
    } else {
      await createRecord(payload);
    }
    void getThemesRecordsApi.request();
  };

  return (
    <div className={classes.appearance}>
      <UploadLogo
        name={client?.name}
        url={theme?.logo}
        api={logo => uploadClientLogo({ clientId: client.id, logo })}
        onUploaded={() => {
          void getThemesRecordsApi.request();
          void api.request({ clientId: client.id });
        }}
        className={classes.logo}
      />
      <UploadIcon
        name={client?.name}
        url={theme?.icon}
        api={icon => uploadClientIcon({ clientId: client.id, icon })}
        onUploaded={() => {
          void getThemesRecordsApi.request();
          void api.request({ clientId: client.id });
        }}
        className={classes.icon}
      />
      <Colors name={client?.name} theme={theme} handleSave={handleSave} className={classes.colors} />
      <AppearanceInstructions className={classes.instructions} />
    </div>
  );
};

export default Appearance;
