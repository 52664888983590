import React from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../form';
import Fields from '../../../../fields';
import { ClassificationType } from '../../../../../domain/classification-type';

import useApi from '../../../../../hooks/use-api';
import { createRecord, updateRecord } from '../../../../../apis/configuration';

import { ClientConfigurationType } from '../enumerations';

async function createOrUpdate(payload) {
  return payload?.id ? updateRecord(payload?.id, payload) : createRecord(payload);
}
const WorkerClassificationForm = ({ record, scopeId, onSave }) => {
  const createOrUpdateApi = useApi(createOrUpdate, { skip: true });
  const submit = async values => {
    await createOrUpdateApi.request(values);
    onSave();
  };

  const loading = createOrUpdateApi.loading;
  return (
    <Form
      initialValues={{
        id: record?.recordId,
        scopeId: record?.scopeId || scopeId,
        description: record?.description,
        configurationType: ClientConfigurationType.SupplierClassificationPreference,
        data: { classification: record?.data?.classification || null },
      }}
      onSubmit={submit}
      enableReinitialize
    >
      {form => {
        return (
          <div>
            <Fields.Select
              name="data.classification"
              label="Your company requires that all suppliers be..."
              options={[
                { label: 'Employed on your behalf.', value: ClassificationType.Employee },
                {
                  label: 'Engaged as Independent Contractors.',
                  value: ClassificationType.IndependentContractor,
                },
                {
                  label: 'Engaged regardless of the classification.',
                  value: null,
                },
              ]}
            />
            <Button outlined fill text="Submit" type="submit" disabled={form.invalid} loading={loading} />
          </div>
        );
      }}
    </Form>
  );
};

export default WorkerClassificationForm;
