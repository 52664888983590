import * as apis from '../../apis/lookups';

import { safeInvoke } from '../../utilities/functions';
import { setCountries, setCurrencies } from './actions';

export const loadCountries = (onSuccess, onError) => async dispatch => {
  try {
    const result = await apis.getCountries();
    dispatch(setCountries(result));
    safeInvoke(onSuccess);
  } catch (error) {
    safeInvoke(onError, error);
  }
};

export const loadCurrencies = (onSuccess, onError) => async dispatch => {
  try {
    var result = await apis.getCurrencies();
    dispatch(setCurrencies(result));
    safeInvoke(onSuccess);
  } catch (error) {
    safeInvoke(onError, error);
  }
};
