import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, useParams } from 'react-router';

import Spinner from '../components/spinner';
import { getUserProfile } from '../state/identity';
import { getThemes } from '../state/application';

import { impersonate, LOGOUT_ROUTE } from './service';

const Impersonate = ({ getUserProfile, getThemes }) => {
  const { email } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function impersonateUser() {
      try {
        await impersonate(email);
        await getUserProfile();
        await getThemes();
        navigate('/');
      } catch (e) {
        navigate(LOGOUT_ROUTE);
      }
    }

    void impersonateUser();
  }, [email, getThemes, getUserProfile, navigate]);

  return <Spinner />;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserProfile,
      getThemes,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Impersonate);
