import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const MembersMenu = ({ onInvite, openEmailDialog }) => {
  return (
    <Menu>
      <MenuItem text="Invite" label={<Icon icon="plus" />} onClick={onInvite} />
      <MenuDivider />
      <MenuItem text="Export" label=".csv" disabled />
      <MenuDivider />
      <MenuItem text="Send email" label={<Icon icon="send-message" />} onClick={() => openEmailDialog()} />
    </Menu>
  );
};

export default MembersMenu;
