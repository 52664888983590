import { arrayMoveImmutable } from 'array-move';
import templateFactory from '../helpers/template-factory';

const recursiveRemove = (array, path) => {
  const [idx] = path.splice(0, 1);
  if (path.length === 0) {
    array.splice(idx, 1);
    return array;
  }
  return recursiveRemove(array[idx].content, path);
};

const recursiveInsert = (array, path, contentItem) => {
  const [idx] = path.splice(0, 1);
  if (path.length === 0) {
    array[idx].content.push(contentItem);
    return array;
  }
  return recursiveInsert(array[idx].content, path, contentItem);
};

const recursiveUpdate = (array, path, contentItem) => {
  const [idx] = path.splice(0, 1);
  if (path.length === 0) {
    array[idx] = contentItem;
    return array;
  }
  return recursiveUpdate(array[idx].content, path, contentItem);
};

function emailConfiguratorReducer(state, action) {
  switch (action.type) {
    case 'EMAIL_CONFIGURATOR_LOAD': {
      return { ...state, isLoading: false, ...action.data };
    }

    case 'EMAIL_CONFIGURATOR_REPLACE': {
      return { ...state, ...action.data };
    }

    case 'EMAIL_CONFIGURATOR_ADD_CONTENT': {
      const { path, contentItem } = action.data;
      let content = [...state.content];
      if (path === undefined) {
        content.push(contentItem);
      } else {
        recursiveInsert(content, [...path], contentItem);
      }
      return { ...state, content };
    }

    case 'EMAIL_CONFIGURATOR_REMOVE_CONTENT': {
      const { path } = action.data;
      let content = [...state.content];
      recursiveRemove(content, [...path]);
      return { ...state, content, selection: undefined };
    }

    case 'EMAIL_CONFIGURATOR_UPDATE_CONTENT': {
      const { path, contentItem } = action.data;
      let content = [...state.content];
      recursiveUpdate(content, [...path], contentItem);
      return { ...state, content };
    }

    case 'EMAIL_CONFIGURATOR_UPDATE_CONFIG': {
      const { name, description, subject, logo, footer, style, class: className } = action.data;
      const template = {
        ...state.template,
        name,
        description,
        metadata: templateFactory.createMetadata({ subject, logo, footer, style, class: className, content: state.content }),
      };

      return { ...state, template };
    }

    case 'EMAIL_CONFIGURATOR_SET_SELECTION': {
      const { config, contentItem, path, type } = action.data;
      const selection = type === 'config' ? { config, contentItem: { title: config?.name, elementType: 'Email' } } : { config, path, contentItem };
      return { ...state, selection };
    }

    case 'EMAIL_CONFIGURATOR_CLEAR_SELECTION': {
      return { ...state, selection: undefined };
    }

    case 'EMAIL_CONFIGURATOR_SET_HOVERED': {
      return { ...state, hovered: action.data };
    }

    case 'EMAIL_CONFIGURATOR_REORDER_CONTENT': {
      const { updates, path } = action.data;
      const { oldIndex, newIndex } = updates || {};
      if (!path) {
        const newOrder = arrayMoveImmutable(state.content, oldIndex, newIndex);
        return { ...state, content: newOrder };
      }

      let content = [...state.content];
      let childRef = content;
      for (let i = 0; i < path.length; i++) {
        childRef = childRef[path[i]];
      }
      childRef.content = arrayMoveImmutable(childRef.content, oldIndex, newIndex);
      return { ...state, content };
    }

    case 'EMAIL_CONFIGURATOR_SET_PUBLISHED': {
      return { ...state, template: { ...state.template, status: action.status } };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default emailConfiguratorReducer;
