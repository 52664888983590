import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../dialog';

import styles from './rich-text.module.scss';

const RichTextFieldModal = ({ children, title, buttonProps, onSubmit }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Button
        className={styles.richTextTriggerButton}
        alignText="left"
        fill
        outlined
        text={buttonProps?.text}
        icon={buttonProps?.icon}
        onClick={() => setIsOpen(true)}
      />
      <Dialog isOpen={isOpen} large title={title} onClose={() => setIsOpen(false)}>
        {children}
        <Button
          fill
          outlined
          text="Submit"
          onClick={() => {
            setIsOpen(false);
            onSubmit?.();
          }}
        />
      </Dialog>
    </>
  );
};

export default RichTextFieldModal;
