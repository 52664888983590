import React, { useEffect, useRef } from 'react';
import { InputGroup } from '@blueprintjs/core';
import cn from 'classnames';
import IMask from 'imask';
import debounce from 'lodash/debounce';

import { Field } from '../../form';

import styles from './text-field.module.scss';

const Input = ({ classes, disabled, value, type, placeholder, onChange, maskConfig, preventPaste, extra = {} }) => {
  const inputRef = useRef();
  const maskRef = useRef();

  useEffect(() => {
    if (inputRef.current && maskConfig?.mask) {
      maskRef.current = IMask(inputRef.current, maskConfig);

      if (value) {
        maskRef.current.value = value;
        maskRef.current?.alignCursor();
      }

      maskRef.current
        .on('accept', e => {
          if (e?.inputType === 'deleteContentBackward') {
            onChange(undefined);
          }
        })
        .on('complete', () => {
          onChange(maskRef.current.value || '');
        });
    }

    return () => {
      maskRef.current?.destroy();
    };
  }, [inputRef.current, maskConfig?.mask, value]);

  const handleChange = e => {
    if (maskRef.current) {
      return;
    }

    let value = undefined;
    if (!maskConfig && type === 'number') {
      try {
        value = Number(e.target.value);
        if (Number.isNaN(value) || value === null || value === undefined) {
          value = e.target.value || '';
        }
      } catch (e) {
        value = e.target.value;
      }
    } else if (!maskConfig) {
      value = e.target.value;
    }

    onChange(value);
  };

  const displayValue = (maskConfig ? maskRef.current?._value : value) || '';

  const extraProps = {
    ...extra,
    onPaste: preventPaste
      ? e => {
          e.preventDefault();
          e.stopPropagation();
        }
      : undefined,
  };

  return (
    <InputGroup
      disabled={disabled}
      inputRef={inputRef}
      className={cn(styles.textField, classes?.input)}
      placeholder={placeholder}
      type={type}
      {...extraProps}
      value={displayValue}
      onChange={handleChange}
      onKeyDown={e => e.stopPropagation()}
    />
  );
};

const TextField = ({
  classes,
  name,
  type = 'text',
  label,
  placeholder,
  description,
  disabled,
  maskConfig,
  validate,
  submitOnChange = false,
  minimal,
  showError,
  outline,
  extra,
  hidden,
  preventPaste,
}) => {
  const submitFormIfNeeded = useRef(
    debounce(form => {
      if (!!submitOnChange) {
        form.submitForm();
      }
    }, 150),
  );

  return (
    <Field
      classes={{ ...classes, wrapper: cn(hidden && styles.hidden, classes?.wrapper) }}
      name={name}
      label={label}
      description={description}
      validate={validate}
      minimal={minimal}
      showError={showError}
      outline={outline}
    >
      {({ field, form }) => (
        <Input
          disabled={disabled}
          classes={classes}
          name={name}
          placeholder={placeholder}
          value={field.value}
          type={type}
          maskConfig={maskConfig}
          extra={extra}
          preventPaste={preventPaste}
          onChange={value => {
            form.setFieldValue(name, value);
            submitFormIfNeeded.current(form);
          }}
        />
      )}
    </Field>
  );
};
export default TextField;
