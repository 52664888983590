import React, { useState } from 'react';
import moment from 'moment';
import { Button, Popover } from '@blueprintjs/core';
import { DateRangePicker3 } from '@blueprintjs/datetime2';
import cn from 'classnames';
import * as Icons from 'react-feather';

import { Field } from '../../form';
import { DateFormat } from '../../../models/enumerations/date-format';
import { calculateInitMonth, parseDate } from './utilities';

import styles from './date.module.scss';

const DateRangeField = ({
  classes,
  disabled,
  name,
  label,
  description,
  placeholder,
  format = DateFormat.DEFAULT,
  minDate,
  maxDate,
  validate,
  submitOnChange = false,
  showShortcuts = true,
  outline,
  minimal,
  showError,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const validateRange = values => {
    if ((!minDate && !maxDate) || !values || values.length === 0) {
      return validate?.(values);
    }
    const startDate = moment(values[0]).startOf('day');
    const endDate = moment(values[1]).startOf('day');
    const min = minDate && moment(minDate).startOf('day');
    const max = maxDate && moment(maxDate).startOf('day');
    const startDateValid = !minDate || min?.isSameOrBefore(startDate);
    const endDateValid = !maxDate || max?.isSameOrAfter(endDate);
    const dateValidationFormat = 'MMMM DD, YYYY';
    if (!startDateValid && !endDateValid) {
      return `${label} field must be later than ${min?.format(dateValidationFormat)} and earlier than ${max?.format(dateValidationFormat)}`;
    } else if (!startDateValid) {
      return `${label} field must be later than ${min?.format(dateValidationFormat)}`;
    } else if (!endDateValid) {
      return `${label} field must be at earlier than ${max?.format(dateValidationFormat)}`;
    }
    return validate?.(values);
  };

  return (
    <Field
      classes={cn(classes)}
      label={label}
      description={description}
      name={name}
      validate={validateRange}
      outline={outline}
      minimal={minimal}
      showError={showError}
    >
      {({ field, form }) => {
        const onChange = range => {
          const [start, end] = (range || []).map(p => parseDate(p, format));
          if (!start || !end) {
            return;
          }

          form.setFieldValue(name, [moment(start).format(format), moment(end).format(format)]);
          setIsOpen(false);

          if (submitOnChange) {
            form.submitForm();
          }
        };

        const parsed = (field.value || []).map(p => parseDate(p, format));
        const text = parsed?.length === 2 && parsed?.every(p => !!p) ? parsed.map(p => moment(p).format('LL')).join(' - ') : placeholder || 'Select date range';
        const initMonth = calculateInitMonth(field.value?.[0], minDate);
        return (
          <Popover
            fill
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            captureDismiss={false}
            popoverClassName={styles.popover}
            content={
              <DateRangePicker3
                onChange={onChange}
                defaultValue={parsed}
                allowSingleDayRange={true}
                contiguousCalendarMonths={false}
                initialMonth={initMonth}
                minDate={minDate}
                maxDate={maxDate}
                shortcuts={showShortcuts}
              />
            }
          >
            <Button
              className={styles.dateButton}
              text={text}
              icon={<Icons.Calendar size={16} strokeWidth={1.5} />}
              rightIcon="caret-down"
              alignText="left"
              onClick={() => setIsOpen(true)}
              disabled={disabled}
            />
          </Popover>
        );
      }}
    </Field>
  );
};

export default DateRangeField;
