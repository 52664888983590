import Clients from '../configuration/client';

export const getUserChecks = async userId => {
  const response = await Clients.Api.get(`ui/backgroundCheck/user/${userId}`);
  return response?.data;
};

export const getEngagementChecks = async engagementId => {
  const response = await Clients.Api.get(`ui/backgroundCheck/engagement/${engagementId}`);
  return response?.data;
};
