import React from 'react';
import { Button, ButtonGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../box';

import useApi from '../../../../hooks/use-api';
import { regenerateIntegration, getIntegrations } from '../../../../apis/client/organizations';

import classes from './integrations.module.scss';

const Integrations = ({ clientId }) => {
  const getApi = useApi(readable => getIntegrations({ clientId, readable }));
  const regenerateApi = useApi(() => regenerateIntegration({ clientId }), { skip: true });

  const readable = !getApi.response?.secret?.startsWith('*');

  const regenerate = async () => {
    await regenerateApi.request({ clientId });
    void getApi.request(false);
  };

  const toggleSecret = async () => {
    void getApi.request(!readable);
  };

  return (
    <Box
      title="Integrations"
      actions={
        <ButtonGroup>
          <Popover
            content={
              <Menu>
                <MenuItem text="Re-generate credentials" onClick={regenerate} />
                <MenuItem text={!!readable ? 'Hide secret' : 'Show secret'} onClick={toggleSecret} disabled={!getApi.response?.id} />
              </Menu>
            }
          >
            <Button small outlined icon={<Icons.MoreHorizontal size={16} strokeWidth={1.5} />} title="Setup integration" loading={regenerateApi.loading} />
          </Popover>
        </ButtonGroup>
      }
      outlined
      monochrome
      loading={getApi.loading}
    >
      <Box.Item>
        <Box.ItemLabel>Client identifier</Box.ItemLabel>
        <Box.ItemValue className={classes.code}>{clientId}</Box.ItemValue>
      </Box.Item>
      {!!getApi.response?.secret && (
        <Box.Item>
          <Box.ItemLabel>Client secret</Box.ItemLabel>
          <Box.ItemValue className={classes.code}>{getApi.response?.secret}</Box.ItemValue>
        </Box.Item>
      )}
    </Box>
  );
};

export default Integrations;
