import cronstrue from 'cronstrue';
import { EXPRESSION_UNITS } from './constants';
import { capitalize } from '../../../utilities/common';

export function parseExpressionToText(expression) {
  try {
    const text = cronstrue.toString(expression, { use24HourTimeFormat: true, throwExceptionOnParseError: true });
    return {
      text,
      success: true,
    };
  } catch (e) {
    return { error: e, text: '' };
  }
}

export function parseExpression(expression) {
  try {
    const units = expression?.split(' ') || [];
    const minute = EXPRESSION_UNITS.MINUTE.parseUnit(units[0]);
    const hour = EXPRESSION_UNITS.HOUR.parseUnit(units[1]);
    const day = EXPRESSION_UNITS.DAY.parseUnit(units[2]);
    const month = EXPRESSION_UNITS.MONTH.parseUnit(units[3]);
    const weekday = EXPRESSION_UNITS.WEEKDAY.parseUnit(units[4]);
    return {
      minute,
      hour,
      day,
      month,
      weekday,
    };
  } catch (e) {
    return false;
  }
}

export function canBeConfigured(expression) {
  return parseExpression(expression) !== false;
}

export function convertToExpression(values) {
  const minute = EXPRESSION_UNITS.MINUTE.toUnit(values.minute);
  const hour = EXPRESSION_UNITS.HOUR.toUnit(values.hour);
  const day = EXPRESSION_UNITS.DAY.toUnit(values.day);
  const month = EXPRESSION_UNITS.MONTH.toUnit(values.month);
  const weekday = EXPRESSION_UNITS.WEEKDAY.toUnit(values.weekday);
  return `${minute} ${hour} ${day} ${month} ${weekday} `;
}

export function itemClick(selected, value) {
  if (!selected?.length) {
    return [value];
  }

  let array = [...selected];
  const idx = selected.indexOf(value);
  if (idx !== -1) {
    array.splice(idx, 1);
  } else {
    array.push(value);
  }
  return array;
}

export function displayTime(hour, minute) {
  if (!hour || !minute) {
    return `At ${hour || '__'}:${minute || '__'}`;
  }

  if (!hour?.length && !minute?.length) {
    return 'Every minute';
  }
  return parseExpressionToText(`${EXPRESSION_UNITS.MINUTE.toUnit(minute)} ${EXPRESSION_UNITS.HOUR.toUnit(hour)} * * *`)?.text;
}

export function displayDay(day, weekday) {
  if (!day?.length && !weekday?.length) {
    return 'Every day';
  }
  const result = parseExpressionToText(`0 0 ${EXPRESSION_UNITS.DAY.toUnit(day)} * ${EXPRESSION_UNITS.WEEKDAY.toUnit(weekday)}`);
  const idx = result.text?.indexOf(',');
  const text = result.text?.substr(idx + 2);
  return capitalize(text);
}

export function displayMonth(month) {
  if (!month?.length) {
    return 'Every month';
  }
  const result = parseExpressionToText(`0 0 * ${EXPRESSION_UNITS.MONTH.toUnit(month)} *`);
  const idx = result.text?.indexOf(',');
  const text = result.text?.substr(idx + 2);
  return capitalize(text);
}
