import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const PayrollMenu = ({ onGeneratePayroll, onRunBulk }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="Generate Payroll(s)" onClick={onGeneratePayroll} />
        <MenuItem text="Sync Payslips" onClick={onRunBulk} />
        <MenuDivider />
        <MenuItem text="Export" label=".csv" disabled />
        <MenuItem text="Import" label=".csv" disabled />
      </Menu>
    </React.Fragment>
  );
};

export default PayrollMenu;
