import React from 'react';
import { ButtonGroup, Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { useEmailConfigurator } from '../../../../context';
import { emailFieldsDefinitionTypes } from '../../../../enumerations/field-definitions';
import { Handle } from '../../sortable';
import FieldMenu from './field-menu';
import ValidationMark from '../../../../../../validation-mark';
import ValidationSchemas from '../../../../helpers/validation-schemas';

import classes from './field.module.scss';

const Field = ({ field, path }) => {
  const [{ selection }, dispatch] = useEmailConfigurator();
  const icon = emailFieldsDefinitionTypes[field.elementType]?.icon;

  const onMouseEnter = () => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_SET_HOVERED', data: field.id });
  };

  const onMouseLeave = () => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_SET_HOVERED', data: undefined });
  };

  return (
    <div
      className={classNames(classes.field, selection?.contentItem?.id === field.id && classes.selected)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Handle />
      <div className={classes.title}>{field.text || field.value || 'Unset'}</div>
      <ButtonGroup>
        <Icon className={classes.icon} size={14} icon={icon} />
        <ValidationMark entity={field} validationSchema={ValidationSchemas[field.elementType]} />
        <FieldMenu field={field} path={path} dispatch={dispatch} />
      </ButtonGroup>
    </div>
  );
};

export default Field;
