import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button, ButtonGroup, Classes, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import useApi from '../../../../hooks/use-api';
import Box from '../../../../components/box';

import UpdatePasswordForm from './forms/update-password-form';

import { getUserLogons } from '../../../../apis/profile';

import classes from '../profile.module.scss';

const Security = ({ user }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const { loading, response } = useApi(getUserLogons);

  const openDialog = type => setDialog({ open: true, type });
  const closeDialog = () => setDialog({ open: false, type: undefined });

  return (
    <div className={classes.tab}>
      <Box title="Authentication" outlined monochrome>
        <Box.Item label="Password">
          ••••••••••
          <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update password" onClick={() => openDialog('update-password')} />
        </Box.Item>
        <Box.Item label="Multi-Factor authentication">
          Disabled
          <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update password" onClick={() => openDialog('mfa')} />
        </Box.Item>
      </Box>
      <Box title="Logon History" outlined monochrome>
        {loading ? (
          [1, 2, 3, 4, 5].map((_, i) => (
            <Box.TabularItem key={i}>
              <Box.ItemValue className={Classes.SKELETON}>date</Box.ItemValue>
              <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
              <ButtonGroup>
                <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
              </ButtonGroup>
            </Box.TabularItem>
          ))
        ) : !response?.length ? (
          <Box.TabularItem key={document.name}>
            <Box.ItemLabel fill>No activity recorded.</Box.ItemLabel>
          </Box.TabularItem>
        ) : (
          response.map(({ id, ipAddress, loginType, logonDateTime, platform, platformVersion, userDevice }, i) => (
            <Box.TabularItem key={id}>
              <Box.ItemLabel>{moment(logonDateTime).format('LLL')}</Box.ItemLabel>
              <Box.ItemValue>{!!loginType ? loginType : 'Password'} authenticated.</Box.ItemValue>
              <Popover
                content={
                  <div className={classes.logonPopover}>
                    <div className={classes.row}>
                      <div className={classes.label}>IP Address</div>
                      <div className={classNames(classes.value, classes.monospace)}>{ipAddress}</div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.label}>Platform</div>
                      <div className={classNames(classes.value, classes.monospace)}>
                        {platform?.split('"')?.join('')} {platformVersion}
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.label}>Device</div>
                      <div className={classNames(classes.value, classes.monospace)}>{userDevice}</div>
                    </div>
                  </div>
                }
              >
                <Button small outlined text="Details" />
              </Popover>
            </Box.TabularItem>
          ))
        )}
      </Box>
      <UpdatePasswordForm isOpen={!!dialog?.open && dialog?.type === 'update-password'} onClose={closeDialog} />
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Security);
