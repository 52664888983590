import React, { useRef, useState } from 'react';
import { Button, InputGroup, Spinner as BluePrintSpinner } from '@blueprintjs/core';
import debounce from 'lodash/debounce';
import cn from 'classnames';

import useApi from '../../../hooks/use-api';
import { Field } from '../../form';

import Map from './map';
import LocationResults from './results';
import { resolveLocation } from './api';

import styles from './address.module.scss';

const AddressField = ({ classes, name, validate, label, placeholder = 'Search...', description, disabled = false, precision, submitOnChange = false }) => {
  const [active, setActive] = useState(false);
  const { loading, response, request } = useApi(query => resolveLocation(query, precision), { skip: true });
  const handleQueryChange = useRef(debounce(request, 500));

  return (
    <Field classes={classes} name={name} label={label} description={description} validate={validate} disabled={disabled}>
      {({ field, form }) => {
        const setResult = result => {
          form.setFieldValue(name, result);
          setActive(false);
          if (!!submitOnChange) {
            form.submitForm();
          }
        };

        return (
          <div className={cn(styles.address, !!active && styles.active)}>
            <SearchBar
              active={active}
              className={classes?.input}
              disabled={disabled}
              loading={loading}
              placeholder={placeholder}
              value={field?.value}
              setActive={setActive}
              onQueryChange={handleQueryChange.current}
            />
            <LocationResults results={response} setResult={setResult} />
            {!!active && <Map address={response?.[0]} loading={loading} />}
          </div>
        );
      }}
    </Field>
  );
};

const SearchBar = ({ active, className, disabled, placeholder, loading, value, setActive, onQueryChange }) => {
  const [query, setQuery] = useState(value?.formatted_address || '');

  React.useEffect(() => {
    setQuery(value?.formatted_address || '');
  }, [value]);

  const onChange = e => {
    setQuery(e.target.value);
    onQueryChange(e.target.value);
  };

  const undo = () => {
    setActive(false);
    setQuery(value?.formatted_address || '');
  };

  return (
    <InputGroup
      onFocus={() => setActive(true)}
      className={cn(styles.searchField, className)}
      placeholder={placeholder}
      value={query}
      onChange={onChange}
      rightElement={loading ? <BluePrintSpinner size="16" /> : !!active ? <Button minimal icon="undo" onClick={undo} /> : null}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default AddressField;
