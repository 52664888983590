import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { useParams } from 'react-router';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Tabs from '../../../../../components/tabs';

import useApi from '../../../../../hooks/use-api';
import { getUser } from '../../../../../apis/platform/users';

import { getFullName } from '../../../../../utilities/user';

import General from './containers/general';
import Security from './containers/security';

import classes from './details.module.scss';

const Details = () => {
  const { id: userId } = useParams();
  const api = useApi(() => getUser({ userId }));

  const avatar = (
    <Avatar
      loading={api?.loading}
      name={getFullName(api?.response?.user)}
      url={api?.response?.user?.avatar}
      size={{ box: 220, text: 30 }}
      styles={{ marginBottom: '15px' }}
    />
  );

  const commonProps = { userId, api };

  return (
    <Page
      title={!!api?.loading ? <span className={Classes.SKELETON}>Name</span> : getFullName(api?.response?.user)}
      scroll={false}
      path={[
        { name: 'Users', to: '/users' },
        {
          name: !!api?.loading ? <span className={Classes.SKELETON}>Name</span> : getFullName(api?.response?.user),
          to: `/users/${userId}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Security" component={Security} props={commonProps} />
          {/* <Tabs.Item title="General" component={General} />
          <Tabs.Item title="Documents" component={Documents} />
          <Tabs.Item title="Signature Settings" component={SignatureSettings} />
          <Tabs.Item title="Banking" component={Banking} />
          */}
        </Tabs.Container>
      </div>
    </Page>
  );
};
export default Details;
