import React from 'react';
import SimpleBar from 'simplebar-react';

import useDocuments from '../../../../../state/use-documents';
import Fields from '../../../../../../../fields';

import FormGroupHeader from './form-group-header';

import classes from '../properties.module.scss';

const CustomJSConfigForm = ({ disabled }) => {
  const { macros } = useDocuments();
  return (
    <div className={classes.group}>
      <FormGroupHeader title="Custom JS code">
        <div>
          <strong>Examples</strong>, you can copy-paste and modify by your will.
        </div>
        <SimpleBar style={{ maxHeight: '35vh' }}>
          <table className="bp5-html-table bp5-html-table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Expression</td>
                {/* eslint-disable-next-line no-template-curly-in-string */}
                <td>{"moment(__FIELD_NAME__ || '__EXAMPLE__').format()"}</td>
              </tr>
              <tr>
                <td>Function</td>
                <td>
                  {'function() {\n' +
                    "    if ((GL.Engagement.Title || 'Software Engineer') === 'Software Engineer') {\n" +
                    "      return 'This is Software Engineer job document'\n" +
                    '    }\n' +
                    "    return 'This is not Software Engineer job document'\n" +
                    '  }()\n'}
                </td>
              </tr>
            </tbody>
          </table>
        </SimpleBar>
      </FormGroupHeader>
      <div className={classes.line}>
        <Fields.MacroTextarea
          label=""
          name="config.customJS"
          description="Right click to insert macros"
          options={macros}
          submitOnChange={true}
          extra={{ growVertically: true }}
          literals={false}
          showError={false}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CustomJSConfigForm;
