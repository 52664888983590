import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../../../components/box';
import useApi from '../../../../../../hooks/use-api';
import { getConfiguration } from '../../../../../../apis/configuration';
import MetadataForm from './forms/metadata-form';

import classes from '../details.module.scss';

const Metadata = ({ engagement, dialog, openDialog, closeDialog, onSave }) => {
  const getConfigurationApi = useApi(() => getConfiguration(engagement?.clientId, 'ExternalMetadata|EngagementMetadata'), { skip: !engagement?.clientId });
  const metadata = React.useMemo(() => (engagement?.metadata ? JSON.parse(engagement.metadata) : null), [engagement?.metadata]);
  const fields = React.useMemo(() => {
    if (getConfigurationApi.response?.[0]?.schema) {
      return JSON.parse(getConfigurationApi.response?.[0]?.schema);
    }
    return [];
  }, [getConfigurationApi.response]);

  return (
    <div className={classes.tab}>
      <Box
        title="Metadata"
        outlined
        actions={
          <Button
            small
            outlined
            icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
            title="Update metadata details"
            onClick={() => openDialog('metadata')}
            disabled={!fields.length}
          />
        }
        loading={getConfigurationApi.loading}
        monochrome
        accordion
      >
        {fields.map(field => (
          <Box.Item label={field.name} key={field.name}>
            {metadata?.[field.name]}
          </Box.Item>
        ))}
        {!getConfigurationApi.loading && !fields?.length && (
          <Box.TabularItem>
            <Box.ItemLabel fill>No records found.</Box.ItemLabel>
          </Box.TabularItem>
        )}
      </Box>
      <MetadataForm
        isOpen={!!dialog?.open && dialog?.type === 'metadata'}
        fields={fields}
        engagement={engagement}
        metadata={metadata}
        onClose={closeDialog}
        onSave={onSave}
      />
    </div>
  );
};

export default Metadata;
