import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import AuditContainer from './audit-container';
import { isPlatformAdministrator } from '../../utilities/roles';

const Audit = ({ isAdministrator, type, id, element, icon, minimal, outlined, small, disabled }) => {
  const [isRendered, setIsRendered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    if (!isRendered) setIsRendered(true);

    setTimeout(() => setIsOpen(!isOpen), 1);

    if (isRendered) setTimeout(() => setIsRendered(false), 150);
  };

  // display revisions only for platform administrators.
  if (!isAdministrator) return null;

  if (!type || !id) return null;

  const getElement = () => {
    if (!!element) {
      return React.cloneElement(element, { onClick: toggleDrawer });
    }

    const commonProps = { onClick: toggleDrawer, small, minimal, outlined, disabled, title: 'View changes' };
    if (!!icon) {
      return <Button icon={<Icons.Archive size={16} strokeWidth={1.5} />} {...commonProps} />;
    }

    return <Button {...commonProps}>Revisions</Button>;
  };

  if (!isOpen) {
    return getElement();
  }

  return (
    <React.Fragment>
      {getElement()}
      <AuditContainer {...{ type, id, isOpen, isRendered, toggleDrawer }} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAdministrator: isPlatformAdministrator(state.identity.memberships),
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
