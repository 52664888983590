import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { Roles } from '../../../../../models/enumerations/roles';
import { roleIdToRoleName } from '../../../../../utilities/roles';

const MemberFilters = ({ values, filterCounter, setFieldValue, submitForm, resetFilters }) => {
  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;

  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCounter}
        onClick={resetFilters}
        text="Clear Filters"
        label={!!filterCounter ? <Icon icon="cross" /> : null}
      />
      <MenuDivider />
      <MenuItem text={`Role (${values?.roleId ? roleIdToRoleName(values.roleId) : 'any'})`} shouldDismissPopover={false}>
        {Object.values(Roles.Supplier).map(role => (
          <MenuItem
            key={role}
            shouldDismissPopover={false}
            onClick={() => {
              setFieldValue('roleId', role === values?.roleId ? null : role);
              submitForm();
            }}
            label={tick(values?.roleId === role)}
            text={roleIdToRoleName(role)}
          />
        ))}
      </MenuItem>
      <MenuItem
        shouldDismissPopover={false}
        onClick={() => {
          setFieldValue('includeInvites', !values?.includeInvites);
          submitForm();
        }}
        label={tick(values?.includeInvites)}
        text="Show invites ?"
      />
    </Menu>
  );
};

export default MemberFilters;
