import React, { useEffect, useState } from 'react';

import Field from '../domain/field';
import Group from '../domain/group';
import Page from '../domain/page';
import { ObjectTypes } from '../domain/types';
import Wizard from '../domain/wizard';

import { uuid } from '../../../../utilities/common';
import { getDescriptors, getTemplate, getTemplateStream, getWizardMetadataFields, uploadTemplate, getEligibleSignatories } from '../../../../apis/wizards';
import WizardExpandProvider from './expand-provider';
import WizardInstanceProvider from './instance-provider';
import WizardOrderProvider from './order-provider';
import WizardSelectionProvider from './selection-provider';
import { initializeTemplateFromContext } from './template-initialization';

export const WizardContext = React.createContext({});

const WizardProvider = ({ templateId, flowType, referenceId, data, isDesigner, children, onCancel, onComplete, ...rest }) => {
  const [template, _setTemplate] = useState();
  const [instanceId, setInstanceId] = useState(rest?.instanceId);
  const [templateInfo, setTemplateInfo] = useState();
  const [descriptor, setDescriptor] = useState();
  const [eligibleSignatories, setEligibleSignatories] = useState();
  const [loading, setLoading] = useState(false);

  const fetchTemplateInfo = async () => {
    const templateData = await getTemplate(templateId);
    setTemplateInfo(templateData);
  };

  // Wizard updates
  const setTemplate = template => {
    const updates = new Wizard(template);
    _setTemplate(updates);
  };

  const addCategory = category => {
    if (!category || !category.name) return;
    const exists = template?.categories?.find(c => c.name.toLowerCase() === category.name.toLowerCase());
    if (!!exists) return;
    const updates = { ...template, categories: [...template?.categories, category] };
    setTemplate(new Wizard(updates));
  };
  const removeCategory = category => {
    const updates = {
      ...template,
      pages: template.pages.map(page => ({
        ...page,
        groups: page.groups.map(group => ({
          ...group,
          fields: group.fields.map(field =>
            field?.category?.toLowerCase() === category?.name?.toLowerCase() ? new Field({ ...field, category: undefined }) : field,
          ),
        })),
      })),
      categories: template?.categories.filter(c => c.name.toLowerCase() !== category.name.toLowerCase()),
    };
    setTemplate(new Wizard(updates));
  };

  // Page CRUD
  const addPage = () => {
    const updates = { ...template, pages: [...(template?.pages || []), new Page()] };
    setTemplate(updates);
  };
  const clonePage = page => {
    const updates = {
      ...template,
      pages: [
        ...(template?.pages || []),
        new Page({
          ...page,
          id: uuid(),
          title: `Copy of ${page.title}`,
          groups: page?.groups?.map(
            g =>
              new Group({
                ...g,
                id: uuid(),
                fields: g?.fields?.map(f => new Field({ ...f, id: uuid(), mandatory: false })),
              }),
          ),
        }),
      ],
    };
    setTemplate(updates);
  };
  const updatePage = page => {
    const updates = { ...template, pages: template?.pages?.map(p => (p?.id !== page?.id ? p : new Page(page))) };
    setTemplate(updates);
  };
  const removePage = page => {
    const visibilityUpdates = getUpdatesWithPurgedVisibilityConditions(page);
    const updates = { ...visibilityUpdates, pages: visibilityUpdates.pages.filter(p => p.id !== page.id) };
    setTemplate(updates);
  };

  // Group CRUD
  const addGroup = page => {
    if (!page) return;
    const updates = { ...template, pages: (template?.pages || []).map(p => (p.id !== page.id ? p : { ...p, groups: [...p.groups, new Group()] })) };
    setTemplate(updates);
  };
  const cloneGroup = (page, group) => {
    if (!page) return;
    const updates = {
      ...template,
      pages: template?.pages?.map(p => {
        if (p.id !== page.id) return p;

        return {
          ...p,
          groups: [
            ...(p.groups || []),
            new Group({
              ...group,
              id: uuid(),
              title: `Copy of ${group.title}`,
              fields: group?.fields?.map(f => new Field({ ...f, id: uuid(), mandatory: false })),
            }),
          ],
        };
      }),
    };
    setTemplate(updates);
  };
  const updateGroup = (page, group) => {
    if (!page) return;
    const updates = {
      ...template,
      pages: template?.pages?.map(p => (p.id !== page.id ? p : { ...p, groups: p.groups.map(g => (g.id !== group.id ? g : new Group(group))) })),
    };
    setTemplate(updates);
  };
  const moveGroup = (page, group, targetPage) => {
    if (!page || !group) return;
    const visibilityUpdates = getUpdatesWithPurgedVisibilityConditions(page, group);
    const updates = {
      ...visibilityUpdates,
      pages: (visibilityUpdates?.pages || []).map(p =>
        p.id !== page.id && p.id !== targetPage.id
          ? p
          : p.id === page.id
            ? { ...p, groups: p.groups.filter(g => g.id !== group.id) }
            : { ...p, groups: [...(p.groups || []), new Group({ ...group, id: uuid(), title: group?.title })] },
      ),
    };
    setTemplate(updates);
  };
  const removeGroup = (page, group) => {
    if (!page || !group) return;
    const visibilityUpdates = getUpdatesWithPurgedVisibilityConditions(page, group);
    const updates = {
      ...visibilityUpdates,
      pages: (visibilityUpdates?.pages || []).map(p => (p.id !== page.id ? p : { ...p, groups: p.groups.filter(g => g.id !== group.id) })),
    };
    setTemplate(updates);
  };

  // Field CRUD
  const addField = (page, group, field = new Field()) => {
    if (!page || !group) return;
    const updates = {
      ...template,
      pages: (template?.pages || []).map(p =>
        p.id !== page.id ? p : { ...p, groups: (p?.groups || []).map(g => (g.id !== group.id ? g : { ...g, fields: [...(g.fields || []), field] })) },
      ),
    };
    setTemplate(updates);
  };
  const cloneField = (page, group, field) => {
    if (!page || !group || !field) return;
    const updates = {
      ...template,
      pages: template?.pages.map(p => {
        return p.id !== page.id
          ? p
          : {
              ...p,
              groups: p?.groups?.map(g =>
                g.id !== group.id
                  ? g
                  : { ...g, fields: [...(g?.fields || []), new Field({ ...field, id: uuid(), label: `Copy of ${field.label}`, mandatory: false })] },
              ),
            };
      }),
    };
    setTemplate(updates);
  };
  const updateField = (page, group, field) => {
    if (!page || !group || !field) return;
    const updates = {
      ...template,
      pages: template?.pages.map(p => {
        return p.id !== page.id
          ? p
          : {
              ...p,
              groups: p?.groups?.map(g => (g.id !== group.id ? g : { ...g, fields: g?.fields?.map(f => (f.id !== field.id ? f : new Field(field))) })),
            };
      }),
    };
    setTemplate(updates);
  };
  const removeField = (page, group, field) => {
    if (!page || !group || !field) return;
    const visibilityUpdates = getUpdatesWithPurgedVisibilityConditions(page, group, field);
    const updates = {
      ...visibilityUpdates,
      pages: visibilityUpdates.pages.map(p =>
        p.id !== page.id ? p : { ...p, groups: p.groups.map(g => (g.id !== group.id ? g : { ...g, fields: g.fields.filter(f => f.id !== field.id) })) },
      ),
    };
    setTemplate(updates);
  };
  const moveField = (page, group, field, targetPage, targetGroup) => {
    if (!page || !group || !field) return;
    const visibilityUpdates = getUpdatesWithPurgedVisibilityConditions(page, group, field);
    const updates = {
      ...visibilityUpdates,
      pages: visibilityUpdates.pages.map(p =>
        p.id !== page.id && p.id !== targetPage.id
          ? p
          : p.id === targetPage.id
            ? {
                ...p,
                groups: p.groups?.map(g =>
                  g.id !== group.id && g.id !== targetGroup.id
                    ? g
                    : g.id === targetGroup.id
                      ? { ...g, fields: [...g.fields, field] }
                      : { ...g, fields: g.fields?.filter?.(f => f.id !== field.id) },
                ),
              }
            : {
                ...p,
                groups: p.groups?.map(g =>
                  g.id !== group.id && g.id !== targetGroup.id
                    ? g
                    : g.id === targetGroup.id
                      ? { ...g, fields: [...g.fields, field] }
                      : { ...g, fields: g.fields?.filter?.(f => f.id !== field.id) },
                ),
              },
      ),
    };
    setTemplate(updates);
  };

  const resolvePagePath = (pathString = '') => {
    if (!pathString) return undefined;

    const [page, group, field] = pathString.split('.');
    if (!page || !group || !field) return undefined;

    return template?.pages
      ?.find(p => p.id === page)
      ?.groups?.find(g => g.id === group)
      ?.fields?.find(f => f.id === field);
  };

  const getUpdatesWithPurgedVisibilityConditions = (page, group, field) => {
    const allConditions = getObjectsWithVisibilityConditions();
    if (!allConditions?.length) {
      return template;
    }

    let removingFields = [];
    if (!!page && !!group && !!field) {
      removingFields = [`${page.id}.${group.id}.${field.id}`];
    } else if (!!page && !!group && !field) {
      removingFields = [].concat.apply(
        [],
        group?.fields?.map(f => `${page.id}.${group.id}.${f.id}`),
      );
    } else if (!!page && !group && !field) {
      removingFields = [].concat.apply(
        [],
        (page?.groups || []).map(g => (g?.fields || []).map(f => `${page.id}.${g.id}.${f.id}`)),
      );
    }
    if (!removingFields.length) {
      return template;
    }

    const conditionsToRemove = [].concat.apply(
      [],
      removingFields.map(f => allConditions.filter(c => c.field === f)),
    );
    if (!conditionsToRemove.length) {
      return template;
    }

    let updates = { ...template };
    conditionsToRemove.forEach(({ target }) => {
      const parts = target?.split('.');
      switch (parts?.length) {
        case 1:
          updates = { ...template, pages: template.pages?.map(p => (p.id === parts[0] ? new Page({ ...p, visibility: undefined }) : p)) };
          break;

        case 2:
          updates = {
            ...template,
            pages: template.pages?.map(p =>
              p.id === parts[0] ? new Page({ ...p, groups: p?.groups?.map(g => (g.id === parts[1] ? new Group({ ...g, visibility: undefined }) : g)) }) : p,
            ),
          };
          break;

        case 3:
          updates = {
            ...template,
            pages: template.pages?.map(p =>
              p.id === parts[0]
                ? new Page({
                    ...p,
                    groups: p?.groups?.map(g =>
                      g.id === parts[1]
                        ? new Group({ ...g, fields: g?.fields?.map(f => (f.id === parts[2] ? new Field({ ...f, visibility: undefined }) : f)) })
                        : g,
                    ),
                  })
                : p,
            ),
          };
          break;

        default:
          break;
      }
    });

    return updates;
  };

  const getObjectsWithVisibilityConditions = () => {
    const objects = [];

    template?.pages?.forEach(page => {
      // add pages with visibility conditions.
      if (!!page?.visibility?.field) {
        objects.push({ target: page?.id, field: page?.visibility?.field });
      }

      page?.groups?.forEach(group => {
        // add groups with visibility conditions.
        if (!!group?.visibility?.field) {
          objects.push({ target: `${page?.id}.${group?.id}`, field: group?.visibility?.field });
        }

        group?.fields?.forEach(field => {
          // add fields with visibility conditions.
          if (!!field?.visibility?.field) {
            objects.push({ target: `${page?.id}.${group?.id}.${field?.id}`, field: field?.visibility?.field });
          }
        });
      });
    });

    return objects;
  };

  const loadTemplate = async () => {
    setLoading(true);
    const templateData = await getTemplate(templateId);
    setTemplateInfo(templateData);
    const descriptors = await getDescriptors();
    const descriptor = descriptors?.find?.(d => d.contextTypeName === templateData?.contextTypeName);
    if (!descriptor) {
      setLoading(false);
      return;
    }

    setDescriptor(descriptor);

    let template = await getTemplateStream(templateId);
    if (!template) {
      template = initializeTemplateFromContext(descriptor);
      await uploadTemplate(templateData?.id, template);
      template = await getTemplateStream(templateId);
    }
    setTemplate(template);

    // Load metadata fields.
    if (!isDesigner) {
      const hasSignatureFields = template?.pages?.some?.(p => p?.groups?.some?.(g => g?.fields?.some?.(f => f?.type === ObjectTypes.Signature)));
      if (hasSignatureFields) {
        const eligibleSignatories = await getEligibleSignatories({ flowType, referenceId });
        setEligibleSignatories(eligibleSignatories);
      }

      const metadataGroup = template?.pages
        ?.map(p => p?.groups)
        ?.flat?.(1)
        ?.find?.(g => !!g?.metadata);

      if (!!metadataGroup) {
        const metadataFields = await getWizardMetadataFields({ ...data, providedBy: metadataGroup?.metadata?.providedBy });
        if (metadataFields?.length) {
          const metadataPage = template.pages?.find?.(p => p?.groups?.map(g => g.id)?.includes(metadataGroup?.id));
          const fields = metadataFields?.map(
            item => new Field({ label: item?.name, type: item?.type === 'text' ? ObjectTypes.Text : ObjectTypes.Number, validation: { required: true } }),
          );
          if (!!metadataPage) {
            updateGroup(metadataPage, { ...metadataGroup, fields });
            setTemplate({
              ...template,
              pages: template?.pages?.map?.(p =>
                p.id !== metadataPage?.id ? p : { ...p, groups: p?.groups?.map?.(g => (g.id !== metadataGroup?.id ? g : { ...g, fields })) },
              ),
            });
          }
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    void loadTemplate();
  }, []);

  return (
    <WizardContext.Provider
      value={{
        loading,
        setLoading,
        template,
        templateInfo,
        descriptor,
        eligibleSignatories,
        setTemplate,
        isDesigner,
        isPublished: templateInfo?.status === 1,
        data,
        instanceId,
        addCategory,
        removeCategory,
        addPage,
        clonePage,
        updatePage,
        removePage,
        addGroup,
        cloneGroup,
        updateGroup,
        moveGroup,
        removeGroup,
        addField,
        cloneField,
        updateField,
        removeField,
        moveField,
        resolvePagePath,
        fetchTemplateInfo,
        onCancel,
        onComplete,
      }}
    >
      <WizardOrderProvider template={template} setTemplate={setTemplate}>
        <WizardSelectionProvider template={template}>
          <WizardExpandProvider>
            <WizardInstanceProvider {...{ templateId, template, data, instanceId, setInstanceId, isDesigner, onComplete }}>{children}</WizardInstanceProvider>
          </WizardExpandProvider>
        </WizardSelectionProvider>
      </WizardOrderProvider>
    </WizardContext.Provider>
  );
};

export default WizardProvider;
