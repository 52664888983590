import React, { useState } from 'react';
import moment from 'moment';
import { Button, Drawer, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Form from '../../../../components/form';
import Fields from '../../../../components/fields';
import Dialog from '../../../../components/dialog';

import WithConfirmation from '../../../../hooks/with-confirmation';
import useApi from '../../../../hooks/use-api';
import { sendInvoice } from '../../../../apis/platform/invoice';

import AddInvoiceItem from '../forms/add-invoice-item';
import InvoiceSupplier from './invoice-supplier';

import classes from './details.module.scss';

const InvoiceDetails = ({ onClose, open, invoice, reloadList, onExport }) => {
  const [dialog, setDialog] = useState({ open: false, initialValues: undefined });
  const [query, setQuery] = useState('');
  const { currency, period, periodType, client, invoiceSuppliers } = invoice || {};
  const filterInvoices = invoice => invoice?.supplierName?.toLowerCase()?.includes(query?.toLowerCase());

  const sendInvoiceApi = useApi(sendInvoice, { skip: true });

  const closeDrawer = () => {
    setQuery('');
    onClose();
  };

  const renderTitle = () =>
    periodType === 'weekly'
      ? `${client?.name} - ${currency?.name} - Week ${moment(period).format('Do MMMM')} - ${moment(period).add(6, 'days').format('Do MMMM YYYY')}`
      : `${client?.name} - ${currency?.name} - ${moment(period).format('MMMM YYYY')}`;

  return (
    <Drawer isOpen={!!open} onClose={closeDrawer} className={classes.details} position="right" canEscapeKeyClose canOutsideClickClose>
      <div className={classes.header}>
        <div className={classes.title}>{renderTitle()}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={closeDrawer} />
      </div>
      <div className={classes.controls}>
        <Form initialValues={{ query }} enableReinitialize onSubmit={({ query }) => setQuery(query)}>
          <Fields.Text
            name="query"
            placeholder="Search..."
            submitOnChange
            outline={false}
            minimal={true}
            showError={false}
            extra={{ rightElement: !!query && <Button icon="small-cross" minimal small onClick={() => setQuery('')} /> }}
          />
        </Form>
        <Popover
          content={
            <Menu>
              <MenuItem
                text="Add"
                onClick={() =>
                  setDialog({
                    open: true,
                    initialValues: {
                      invoiceId: invoice?.invoiceId,
                    },
                  })
                }
              />
              <MenuDivider />
              <WithConfirmation captureDismiss={false} messages={{ confirmButton: 'Send', title: 'Send invoice to invoicing provider ?' }} icon={true}>
                <MenuItem text="Send" onClick={() => sendInvoiceApi?.request({ invoiceId: invoice?.invoiceId })} shouldDismissPopover={false} />
              </WithConfirmation>
              <MenuItem text="Export" label=".csv" onClick={onExport} />
            </Menu>
          }
        >
          <Button outlined icon={<Icons.MoreVertical size={16} />} />
        </Popover>
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          {invoiceSuppliers?.filter?.(filterInvoices)?.map?.((supplier, index) => (
            <InvoiceSupplier key={index} {...{ invoice, supplier, currency, setDialog, reloadList }} />
          ))}
        </div>
      </SimpleBar>
      <div className={classes.footer}>
        <div className={classes.label}>Total</div>
        <div className={classes.value} title={invoice?.total}>{`${currency?.symbol}${Number.parseFloat(`${invoice?.total || 0}`).toFixed(2)}`}</div>
      </div>
      <Dialog
        isOpen={!!dialog?.open}
        onClose={() => setDialog({ open: false, initialValues: undefined })}
        title={dialog?.initialValues?.id ? 'Edit invoice item' : 'Add invoice item'}
      >
        <AddInvoiceItem
          initialValues={dialog?.initialValues}
          client={client}
          currency={currency}
          onComplete={() => {
            reloadList();
            setDialog({ open: false, initialValues: undefined });
          }}
        />
      </Dialog>
    </Drawer>
  );
};

export default InvoiceDetails;
