import React from 'react';
import { useParams } from 'react-router';

import Configurators from '../../../../../components/configurators';
import Page from '../../../../../components/page';

import classes from './documents.module.scss';

const Documents = () => {
  const { id, recordId } = useParams();
  return (
    <Page
      title="Documents"
      path={[
        { name: 'Settings', to: '/settings' },
        { name: 'Documents', to: `/settings?group=documents` },
      ]}
      scroll={false}
      allowMobile={false}
    >
      <div className={classes.documents}>
        <Configurators.DocumentConfigurator id={id} recordId={recordId} />
      </div>
    </Page>
  );
};

export default Documents;
