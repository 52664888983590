import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Form from '../../../../../../components/form';
import Fields from '../../../../../../components/fields';

import useApi from '../../../../../../hooks/use-api';
import { getClients } from '../../../../../../apis/platform/organizations';
import { createEngagementImportRequest } from '../../../../../../apis/platform/engagements';

import { MimeTypes } from '../../../../../../utilities/mime-types';
import { downloadSampleEngagementImportCsv } from '../../../../../../utilities/files';

import classes from '../../../engagements.module.scss';

const CreateImportRequestForm = ({ onClose, reloadList }) => {
  const clientsApi = useApi(getClients, { skip: true });

  const onUploaded = () => {
    reloadList();
    onClose();
  };

  return (
    <Form initialValues={{ file: null, clientId: '' }} onSubmit={() => null}>
      {form => {
        return (
          <div className={classes.importForm}>
            <Fields.Select
              name="clientId"
              label="Client"
              remoteOptions={{
                loading: clientsApi.loading,
                request: clientsApi.request,
                mapper: result => result.map(c => ({ value: c?.id, label: c?.name })),
                filter: 'local',
              }}
              showError={false}
            />
            {renderInfo()}
            <Fields.File
              fill
              outline={false}
              name="file"
              mimeTypes={[MimeTypes.CSV]}
              label="Upload file"
              api={file => createEngagementImportRequest({ file, clientId: form?.values?.clientId })}
              resultValueParser={r => r?.id}
              onUploaded={onUploaded}
              disabled={!form?.values?.clientId}
              showError={false}
            >
              <Button fill outlined icon={<Icons.Upload size={16} strokeWidth={1.5} />} text="Choose file" disabled={!form?.values?.clientId} />
            </Fields.File>
          </div>
        );
      }}
    </Form>
  );
};

const renderInfo = () => {
  const info = <Icons.AlertTriangle className={classes.info} size={16} strokeWidth={1.5} />;
  const download = <Icons.Paperclip className={classes.info} size={16} strokeWidth={1.5} />;

  return (
    <div className={classes.importGuides}>
      <div>Please review list and import example file available below:</div>
      <ul>
        <li>
          {info}All columns present in sample import file are required and need to remain in the file, however if the value is optional you can use space or
          empty text.
        </li>
        <li>{info}Data delimiter used in import process is "," comma, make sure that any column which contains comma is under double quotes ("...").</li>
        <li>
          {info}Hiring managers are referenced by using their email address. Also make sure that hiring manager is actually a member of client (or parent
          client) organization.
        </li>
        <li>{info}Departments are referenced by using department name. Also make sure that the client has referenced department record.</li>
        <li>{info}On-site work locations are referenced by using address full name, visible on client profile page.</li>
        <li>
          {download}
          <span className={classes.sampleFile} onClick={downloadSampleEngagementImportCsv}>
            Sample file
          </span>
        </li>
      </ul>
    </div>
  );
};

export default CreateImportRequestForm;
