import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Box from '../../../../../../../components/box';

import { CompensationFrequency } from '../../../../../../../domain/compensation-frequency';

import ShiftGraphDay from './day';

import classes from './shift-graph.module.scss';

const ShiftGraph = ({ supplier, shifts }) => {
  const start = moment(supplier?.period?.includes('T') ? supplier?.period?.split?.('T')?.[0] : supplier?.period);

  const old = {};
  const current = enumerateDays(start, supplier?.frequency);

  shifts
    ?.sort((a, b) => moment(a?.date).diff(b?.date))
    ?.forEach?.(shift => {
      const date = shift?.date?.includes('T') ? moment(shift?.date?.split?.('T')?.[0]) : moment(shift?.date);
      const key = moment(date).format('YYYY-MM-DD');

      const target = moment(date)?.valueOf() >= start?.valueOf() ? current : old;
      target[key] = !!target?.[key] ? [...target[key], shift] : [shift];
    });

  return (
    <div className={classes.graph}>
      <div className={classes.calendar}>
        <Box outlined monochrome>
          <div className={classNames(classes.period, classes.current)}>
            {Object.keys(current).map?.(day => (
              <ShiftGraphDay key={day} engagements={supplier?.engagements} day={day} shifts={current?.[day]} />
            ))}
          </div>
        </Box>
        {!!Object.keys(old).length && (
          <Box title="Late time" outlined monochrome expandible={{ expanded: true }}>
            <div className={classNames(classes.period, classes.old)}>
              {Object.keys(old).map?.(day => (
                <ShiftGraphDay key={day} engagements={supplier?.engagements} day={day} shifts={old?.[day]} />
              ))}
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};

const enumerateDays = (start, type) => {
  const indices = type === CompensationFrequency.Weekly ? [...Array(7).keys()] : [...Array(start.daysInMonth()).keys()];
  const map = {};
  indices.forEach(index => {
    map[start.clone().add(index, 'days').format('YYYY-MM-DD')] = [];
  });

  return map;
};

export default ShiftGraph;
