export const EDITING_MODES = {
  NONE: 0,
  MANUAL_FORM: 1,
  CONFIG_FORM: 2,
};

export const EXPRESSION_UNITS = {
  MINUTE: {
    name: 'minute',
    min: 0,
    max: 59,
    parseUnit: function (value) {
      if (!value) {
        return undefined;
      }

      if (value === '*') {
        return [];
      }

      const values = value.split(',');
      const isValid = values.every(i => {
        const num = Number(i);
        return Number.isInteger(num) && num >= this.min && num <= this.max;
      });
      if (!isValid) {
        throw new Error('Expression cannot be configured using form');
      }
      return values;
    },
    toUnit: function (value) {
      if (!value) {
        return `0`;
      }

      if (!value.length) {
        return '*';
      }
      return value.join(',');
    },
  },
  HOUR: {
    name: 'hour',
    min: 0,
    max: 23,
    parseUnit: function (value) {
      if (!value) {
        return undefined;
      }

      if (value === '*') {
        return [];
      }

      const values = value.split(',');
      const isValid = values.every(i => {
        const num = Number(i);
        return Number.isInteger(num) && num >= this.min && num <= this.max;
      });
      if (!isValid) {
        throw new Error('Expression cannot be configured using form');
      }
      return values;
    },
    toUnit: function (value) {
      if (!value) {
        return `0`;
      }

      if (!value.length) {
        return '*';
      }
      return value.join(',');
    },
  },
  DAY: {
    name: 'day',
    min: 1,
    max: 31,
    parseUnit: function (value) {
      if (!value || value === '*') {
        return [];
      }

      const values = value.split(',');
      const isValid = values.every(i => {
        const num = Number(i);
        return Number.isInteger(num) && num >= this.min && num <= this.max;
      });
      if (!isValid) {
        throw new Error('Expression cannot be configured using form');
      }
      return values;
    },
    toUnit: function (value) {
      if (!value.length) {
        return '*';
      }
      return value.join(',');
    },
  },
  MONTH: {
    name: 'month',
    min: 1,
    max: 12,
    alt: [undefined, 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    names: [undefined, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    parseUnit: function (value) {
      if (!value || value === '*') {
        return [];
      }

      const values = value.split(',');
      let isValid = values.every(name => this.alt.includes(name));

      if (isValid) {
        return values;
      }

      isValid = values.every(i => {
        const num = Number(i);
        return Number.isInteger(num) && num >= this.min && num <= this.max;
      });

      if (!isValid) {
        throw new Error('Expression cannot be configured using form');
      }

      return values.map(i => this.alt[i]);
    },
    toUnit: function (value) {
      if (!value.length) {
        return '*';
      }
      return value.join(',');
    },
  },
  WEEKDAY: {
    name: 'weekday',
    min: 0,
    max: 6,
    alt: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    names: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    parseUnit: function (value) {
      if (!value || value === '*') {
        return [];
      }

      const values = value.split(',');
      let isValid = values.every(name => this.alt.includes(name));
      if (isValid) {
        return values;
      }

      isValid = values.every(i => {
        const num = Number(i);
        return Number.isInteger(num) && num >= this.min && num <= this.max;
      });

      if (!isValid) {
        throw new Error('Expression cannot be configured using form');
      }

      return values.map(i => this.alt[i]);
    },
    toUnit: function (value) {
      if (!value.length) {
        return '*';
      }
      return value.join(',');
    },
  },
};
