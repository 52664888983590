import React from 'react';
import { Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import classes from './filters.module.scss';

const Menu = ({ form, resetFilters, element }) => {
  if (!element) return null;

  const content = React.cloneElement(element, { ...form, resetFilters });

  return (
    <div className={classes.actions}>
      <div className={classNames(classes.button)}>
        <Popover placement="bottom" content={content}>
          <Icons.MoreVertical className={classes.actionTarget} size={20} />
        </Popover>
      </div>
    </div>
  );
};

export default Menu;
