import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Drawer } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Audit from '../../../../../components/audit';
import Table from '../../../../../components/table';
import Dialog from '../../../../../components/dialog';
import Comments from '../../../../../components/time/comments';

import { canBeEdited, trackingRecordStatusToLabel, TrackingRecordStatusTypes } from '../../../../../domain/tracking-record-status-type';

import useApi from '../../../../../hooks/use-api';
import { getTimeRecords, removeTrackingRecord, reviewRecords } from '../../../../../apis/platform/time';
import { downloadDocument } from '../../../../../apis/documents';

import ShiftForm from './forms/shift';
import DeliverableForm from './forms/deliverable';
import ExpenseForm from './forms/expense';

import classes from './details.module.scss';

const TimeDetails = ({ reloadRecords, onClose, details, range }) => {
  const engagementRecordsApi = useApi(() => getTimeRecords({ filters: { engagementId: details?.id, range } }), { skip: true });
  const [engagement, setEngagement] = useState();
  const [shifts, setShifts] = useState([]);
  const [deliverables, setDeliverables] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [dialog, setDialog] = useState({ open: false, type: undefined, data: undefined });

  const loadEngagementRecords = async () => {
    const _engagements = await engagementRecordsApi.request();
    const engagement = _engagements?.data?.find?.(e => e?.engagementId === details?.id && e?.period === details?.period);
    if (engagement) {
      setEngagement(engagement);
      setShifts(engagement?.records?.filter?.(r => r.type === 0)?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf()));
      setDeliverables(engagement?.records?.filter?.(r => r.type === 1)?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf()));
      setExpenses(engagement?.records?.filter?.(r => r.type === 2)?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf()));
    }
  };

  useEffect(() => {
    if (details?.open) {
      setEngagement(details?.engagement);
      void loadEngagementRecords();
    }

    return () => {
      setEngagement(undefined);
      setShifts([]);
      setDeliverables([]);
      setExpenses([]);
    };
  }, [details?.id, details?.open]);

  const updateStatus = async updates => {
    await reviewRecords(updates);
    await loadEngagementRecords();
    void reloadRecords();
  };

  const removeRecordApi = useApi(removeTrackingRecord, { skip: true });
  const removeRecord = async id => {
    await removeRecordApi.request(id);
    await loadEngagementRecords();
    void reloadRecords();
  };

  const onAddOrEditComplete = async () => {
    await loadEngagementRecords();
    void reloadRecords();
    setDialog({ open: false, type: undefined, data: undefined });
  };

  if (!details || !details?.open) {
    return null;
  }

  const dateColumn = <Table.Columns.TwoLines showOnMobile name="Date" lines={[d => moment(d?.date).format('MMMM Do'), d => moment(d?.date).format('YYYY')]} />;
  const amountColumn = <Table.Columns.Bold showOnMobile width="90px" name="Amount" render={d => `${engagement?.currencySymbol}${d?.amount?.toFixed(2)}`} />;
  const statusColumn = <Table.Columns.Default showOnMobile width="90px" name="Status" render={d => trackingRecordStatusToLabel(d?.status)} />;
  const commentsColumn = <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />;
  const auditColumn = <Table.Columns.Default showOnMobile width="36px" render={d => <Audit id={d?.id} type="trackingRecord" icon small outlined />} />;
  const notesColumn = <Table.Columns.Default width="2fr" name="Notes" render={d => d?.notes} />;
  const receiptColumn = (
    <Table.Columns.Default
      showOnMobile
      width="15px"
      render={d => (
        <Icons.Paperclip
          style={{ opacity: d?.receiptId ? 1 : 0.25, cursor: d?.receiptId ? 'pointer' : 'not-allowed' }}
          size={15}
          onClick={() => {
            if (d?.receiptId) {
              void downloadDocument({ id: d?.receiptId, name: `Receipt-${d?.receiptId}` });
            }
          }}
        />
      )}
    />
  );
  const actionsColumn = (
    <Table.Columns.Actions
      showOnMobile
      actions={[
        {
          name: 'Edit',
          onClick: d => setDialog({ open: true, type: d?.type === 0 ? 'shifts' : d?.type === 1 ? 'deliverables' : 'expenses', data: d }),
          disabled: d => !canBeEdited(d?.status),
        },
        { name: 'Remove', onClick: d => removeRecord(d?.id), confirmation: true, disabled: d => !canBeEdited(d?.status) },
        { divider: true },
        {
          name: 'Set Status',
          nested: true,
          items: [
            {
              name: 'Pending',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Pending, records: [d?.id] }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => d?.status === TrackingRecordStatusTypes.Pending,
            },
            {
              name: 'Submitted',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Submitted, records: [d?.id] }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => d?.status === TrackingRecordStatusTypes.Submitted,
            },
            {
              name: 'Approved',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: [d?.id] }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => d?.status === TrackingRecordStatusTypes.Approved,
            },
            {
              name: 'Disputed',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: [d?.id] }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => d?.status === TrackingRecordStatusTypes.Disputed,
            },
            {
              name: 'Processed',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Processed, records: [d?.id] }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => d?.status === TrackingRecordStatusTypes.Processed,
            },
          ],
        },
      ]}
    />
  );

  const round = value => (value?.toString()?.length > 5 ? value.toFixed(2) : value);
  const getTotalHours = shifts => {
    const total = shifts?.reduce?.((a, b) => a + moment.duration(b?.duration).asHours(), 0);
    return round(total);
  };

  return (
    <Drawer isOpen={!!details?.open} onClose={onClose} className={classes.details} position="right" canEscapeKeyClose canOutsideClickClose>
      <div className={classes.header}>
        <div className={classes.title}>{`${engagement?.firstName} ${engagement?.lastName}`}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.engagement}>{engagement?.title}</div>
            <div className={classes.client}>{!!engagement?.parentClient ? `${engagement?.client} (via ${engagement?.parentClient})` : engagement?.client}</div>
          </div>

          <div className={classes.table}>
            <div className={classes.heading}>
              <div className={classes.title}>Shifts</div>
              <Button
                className={classes.addButton}
                outlined
                text="Add"
                onClick={() => {
                  setDialog({ open: true, type: 'shifts', data: {} });
                }}
              />
            </div>
            <div className={classes.data}>
              <Table.Container data={shifts} loading={engagementRecordsApi.loading} handleScroll={false}>
                {dateColumn}
                {notesColumn}
                {statusColumn}
                <Table.Columns.Default name="Start" width="60px" render={d => moment(d?.date).format('h:mm A')} />
                <Table.Columns.Default
                  name="End"
                  width="60px"
                  render={d => moment(d?.date).add(moment.duration(d?.duration).asMilliseconds(), 'milliseconds').format('h:mm A')}
                />
                <Table.Columns.Bold width="60px" render={d => `${round(moment.duration(d?.duration).asHours())}h`} />
                {commentsColumn}
                {auditColumn}
                {actionsColumn}
              </Table.Container>
              <div className={classes.total}>
                <div className={classes.label}>Total Hours</div>
                <div className={classes.value}>{`${getTotalHours(shifts)}h`}</div>
              </div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.heading}>
              <div className={classes.title}>Deliverables</div>
              <Button className={classes.addButton} outlined text="Add" onClick={() => setDialog({ open: true, type: 'deliverables' })} />
            </div>
            <div className={classes.data}>
              <Table.Container data={deliverables} loading={engagementRecordsApi.loading} handleScroll={false}>
                {dateColumn}
                {notesColumn}
                {statusColumn}
                {amountColumn}
                {commentsColumn}
                {auditColumn}
                {actionsColumn}
              </Table.Container>
              <div className={classes.total}>
                <div className={classes.label}>Total Amount</div>
                <div className={classes.value}>{`${engagement?.currencySymbol}${deliverables?.reduce?.((a, b) => a + b?.amount, 0).toFixed(2)}`}</div>
              </div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.heading}>
              <div className={classes.title}>Expenses</div>
              <Button className={classes.addButton} outlined text="Add" onClick={() => setDialog({ open: true, type: 'expenses' })} />
            </div>
            <div className={classes.data}>
              <Table.Container data={expenses} loading={engagementRecordsApi.loading} handleScroll={false}>
                {dateColumn}
                {receiptColumn}
                {notesColumn}
                {statusColumn}
                {amountColumn}
                {commentsColumn}
                {auditColumn}
                {actionsColumn}
              </Table.Container>
              <div className={classes.total}>
                <div className={classes.label}>Total Amount</div>
                <div className={classes.value}>{`${engagement?.currencySymbol}${expenses?.reduce?.((a, b) => a + b?.amount, 0).toFixed(2)}`}</div>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
      <Dialog
        isOpen={dialog?.open}
        title={dialog?.data?.id ? 'Update record' : 'Create new record'}
        onClose={() => setDialog({ open: false, type: undefined, data: undefined })}
      >
        {dialog?.type === 'shifts' && <ShiftForm engagement={engagement} data={dialog?.data} onComplete={onAddOrEditComplete} />}
        {dialog?.type === 'deliverables' && <DeliverableForm engagement={engagement} data={dialog?.data} onComplete={onAddOrEditComplete} />}
        {dialog?.type === 'expenses' && <ExpenseForm engagement={engagement} data={dialog?.data} onComplete={onAddOrEditComplete} />}
      </Dialog>
    </Drawer>
  );
};

export default TimeDetails;
