import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as requests from '../../../apis/configuration';

export const SystemContext = React.createContext({});

const SystemProvider = ({ organizationId, currentOrganization, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedScope, setSelectedScope] = useState();
  const [includeInheritedRecords, setIncludeInheritedRecords] = useState(false);
  const [types, setTypes] = useState();
  const [scopes, setScopes] = useState();
  const [scopesLoading, setScopesLoading] = useState(false);

  const loadScopes = async () => {
    setScopesLoading(true);
    const scopesResult = await requests.getScopes({ organizationId });
    setScopes(scopesResult);
    setScopesLoading(false);
  };

  const addScope = async (organizationId, parentScopeId, locationQuery) => {
    const response = await requests.createScope(organizationId, parentScopeId, locationQuery);
    searchParams.set('scope', response.id);
    searchParams.delete('groups');
    setSearchParams(searchParams);
    void loadScopes();
  };

  const removeScope = async scopeId => {
    await requests.removeScope(scopeId);
    if (searchParams.get('scope') === scopeId) {
      searchParams.delete('scope');
      searchParams.delete('groups');
      setSearchParams(searchParams);
    }
    void loadScopes();
  };

  useEffect(() => {
    async function loadTypes() {
      // Load and set configuration record types
      const params =
        organizationId === currentOrganization ? { organizationId } : { organizationId: currentOrganization, targetOrganizationId: organizationId };
      const typesResult = await requests.getConfigurationRecordTypes(params);
      setTypes(typesResult);

      // Load and set scopes
      await loadScopes();
    }

    void loadTypes();
  }, []);

  useEffect(() => {
    if (!scopes) {
      return;
    }

    const scopeId = searchParams.get('scope');
    if (scopeId) {
      setSelectedScope(scopes?.find(d => d.id === scopeId));
    } else {
      setSelectedScope(scopes?.find(d => !d.parentId));
    }
  }, [searchParams, scopes]);

  const selectScope = scope => {
    searchParams.set('scope', scope.id);
    searchParams.delete('group');
    setSearchParams(searchParams);
    setSelectedScope(scope);
  };

  return (
    <SystemContext.Provider
      value={{
        organizationId,
        types,
        scopes,
        scopesLoading,
        addScope,
        removeScope,
        selectedScope,
        setSelectedScope: selectScope,
        includeInheritedRecords,
        setIncludeInheritedRecords,
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};

export default SystemProvider;
