import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

import { itemClick } from '../utilities';

import classes from '../cron-expression.module.scss';

const ListMenu = ({ value, min, max, alt, names, onChange }) => {
  const [selected, setSelected] = React.useState(value);
  const allSelected = value.length === 0;
  const items = [];

  for (let i = min; i <= max; i++) {
    const num = i;
    items.push(
      <MenuItem
        active={!allSelected && selected.includes(alt[num])}
        text={names[num]}
        onClick={e => {
          e.stopPropagation();
          const s = itemClick(selected, alt[num]);
          setSelected(s);
          onChange(s);
        }}
        key={num}
      />,
    );
  }
  return <Menu className={classes.popoverWeekdays}>{items}</Menu>;
};

export default ListMenu;
