import React from 'react';
import { useNavigate } from 'react-router';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const EngagementsMenu = ({ openEmailDialog, onExport }) => {
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuItem text="Send email" label={<Icon icon="send-message" />} onClick={() => openEmailDialog()} />
      <MenuDivider />
      <MenuItem text="Export" label=".csv" onClick={onExport} />
      <MenuItem text="Import" label={<Icon icon="plus" />} onClick={() => navigate(`/engagements/import`)} />
    </Menu>
  );
};

export default EngagementsMenu;
