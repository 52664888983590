import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

const TimeMenu = ({ onExport }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="Export" label=".csv" onClick={onExport} />
        <MenuItem text="Import" label=".csv" disabled />
      </Menu>
    </React.Fragment>
  );
};

export default TimeMenu;
