import React from 'react';
import { Button, Drawer } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { setIn } from 'formik';

import Form from '../form';
import Fields from '../fields';
import { awaitSignatures, getInstance, getTemplate, progressInstance, submitFlowStep } from '../../apis/wizards';
import useApi from '../../hooks/use-api';

import classes from './sign-document.module.scss';

const SignDocument = ({ eligibleSignatories, signatures, dialog, membership, user, onClose, onSuccess, contextProps = {} }) => {
  const step = dialog.data?.step;
  const target = dialog.data?.target;
  const instanceId = step?.instanceId;
  const getDocumentApi = useApi(() => getTemplate(target), { skip: !target });

  // _signatures = [{ roleId, organizationId, signatureId }]
  const onSign = async _signatures => {
    const instance = await getInstance(instanceId);
    let values = JSON.parse(instance.data);

    const aux = step.aux?.map(s => {
      if (s?.[0]?.target === target) {
        const path = s[0].path;
        values = setIn(values, path, _signatures);
        // _signatures is an array of signatories and comes from the signature field component. The structure is [{ target, roleId, organizationId, signatureId }]
        // when updating the aux we need to keep the path and update the signatories
        return _signatures.map(signatory => ({ ...signatory, path }));
      }
      return s;
    });

    values.Signatures = values.Signatures?.map(s => {
      if (s.target === target && s.roleId === membership.roleId) {
        const roleSignature = _signatures.find(signature => signature.roleId === membership.roleId && signature.organizationId === membership.organization.id);
        return { ...s, organizationId: roleSignature?.organizationId, signatureId: roleSignature?.signatureId };
      }
      return s;
    });

    const everyoneSigned = aux?.every(s => !s || s?.every(signatory => !!signatory.signatureId));
    await progressInstance({ instanceId, progress: instance.progress, data: values });
    await awaitSignatures({ instanceId, aux });
    if (everyoneSigned) {
      await submitFlowStep({ instanceId });
    }
    onSuccess();
  };

  return (
    <Drawer position="right" isOpen={dialog.open} className={classes.drawer} onClose={onClose}>
      <div className={classes.browser}>
        <Form
          initialValues={{
            signatures,
          }}
        >
          <div className={classes.header}>
            <div className={classes.title}>Sign document</div>
            <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
          </div>

          <div id="wizard_container" className={classes.wizardContainer}>
            <SimpleBar className={classes.page} style={{ overflowX: 'hidden' }}>
              <div className={classes.scrollableContent}>
                <div className={classes.intro}>
                  <div className={classes.htmlContent}>
                    <h2>{getDocumentApi.response?.name}</h2>
                  </div>
                </div>
                <div className={classes.body}>
                  <div className={classes.group}>
                    <div className={classNames(classes.inner, classes.block)}>
                      <div className={classes.content}>
                        <div className={classes.field}>
                          <Fields.Signature
                            name="signatures"
                            target={target}
                            eligibleSignatories={eligibleSignatories}
                            contextData={{
                              signatures,
                              target,
                              organizationId: membership.organization.id,
                              userId: user.id,
                              instanceId,
                              ...contextProps,
                            }}
                            outline={false}
                            minimal={true}
                            onSign={onSign}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
          <div className={classes.footer}>
            <Button type="button" outlined className={classNames(classes.button, classes.back)} text="Close" onClick={onClose} />
            <div className={classes.space} />
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default SignDocument;
