import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { urlSanitize } from '../../utilities/common';

import classes from './tabs.module.scss';

const Item = ({ item, to, isActive, isMobile }) => {
  const { title } = item?.props;
  const sanitized = urlSanitize(title);
  return (
    <React.Fragment>
      <NavLink to={{ pathname: to, search: `?t=${sanitized}` }} className={classNames(classes.link, isActive && classes.active)}>
        {title}
      </NavLink>
      {!!isMobile && isActive ? <div className={classes.panel}>{Item.render(item)}</div> : null}
    </React.Fragment>
  );
};

Item.displayName = 'Tabs.Item';

Item.render = item => {
  const { element, component, props } = item?.props || {};
  if (!!component) {
    const Component = component;
    return <Component {...(props || {})} />;
  }

  if (!!element) {
    return element;
  }

  return null;
};

export default Item;
