import React, { useEffect } from 'react';
import { Classes } from '@blueprintjs/core';
import cn from 'classnames';
import { useFormikContext } from 'formik';

import useApi from '../../../hooks/use-api';

import { getBankingFields } from '../../../apis/banking';
import { getCountries } from '../../../apis/lookups';
import { SelectField } from '../select';
import TextField from '../text';

import styles from './bank-account-field.module.scss';

const booleanOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

const BankAccountField = ({ disabled, countryId, readonly }) => {
  const { values, setFieldValue } = useFormikContext();
  const countriesApi = useApi(getCountries, { skip: true });
  const getFieldsApi = useApi(country => getBankingFields(country || countryId), { skip: true });

  useEffect(() => {
    const load = async () => {
      if (values.countryId) {
        const response = await getFieldsApi.request(values.countryId);

        // set default values when creating new bank account.
        if (!values?.id) {
          response?.forEach?.(f => {
            if (!!f.defaultValue) {
              setFieldValue(f.name, f.defaultValue);
            }
          });
        }
      }
    };
    load();
  }, [values.countryId]);

  return (
    <div>
      {!countryId && (
        <SelectField
          name="countryId"
          label="Country"
          remoteOptions={{
            loading: countriesApi.loading,
            request: countriesApi.request,
            mapper: result => result?.map(d => ({ value: d.id, label: d.name })),
            filter: 'local',
          }}
          disabled={disabled}
        />
      )}
      {getFieldsApi.loading
        ? new Array(4).fill({}).map((_, idx) => {
            return (
              <div className={styles.loading} key={idx}>
                <div className={cn(styles.label, Classes.SKELETON)}>{Math.random().toString()}</div>
                <div className={cn(styles.field, Classes.SKELETON)}></div>
              </div>
            );
          })
        : getFieldsApi.response?.map(field => {
            if (field.type === 'bool') {
              return <SelectField options={booleanOptions} name={field.name} label={field.label} key={field.name} disabled={disabled} />;
            }

            return (
              <React.Fragment key={field.name}>
                <TextField
                  name={field.name}
                  label={field.label}
                  extra={{ readOnly: readonly }}
                  validate={value => {
                    if (!!field.required && !value) {
                      return `${field.label} is required`;
                    }

                    if (!!field.maxLength && value?.length > field.maxLength) {
                      return `${field.label} has to be less then ${field.maxLength} characters.`;
                    }

                    if (!!field.minLength && value?.length < field.maxLength) {
                      return `${field.label} has to be longer then ${field.minLength} characters.`;
                    }

                    if (!!field.validatorRegex) {
                      const doesMatch = new RegExp(field.validatorRegex, 'g').test(value);
                      if (!doesMatch) {
                        return `${field.label} format is not valid.`;
                      }
                    }

                    return undefined;
                  }}
                  disabled={disabled}
                />
                {field.requiresRetype && (
                  <TextField
                    label={`${field.label} (please re-type)`}
                    name={`${field.name}Confirmation`}
                    validate={value => {
                      if (values[field.name] !== value) {
                        return `${field.label} does not match re-typed value.`;
                      }
                    }}
                    preventPaste
                  />
                )}
              </React.Fragment>
            );
          })}
    </div>
  );
};

export default BankAccountField;
