import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'formik';
import { ResizeSensor } from '@blueprintjs/core';
import classNames from 'classnames';

import { WizardContext } from '../../../state';
import { WizardInstanceContext } from '../../../state/instance-provider';
import FieldRenderer from './field';

import classes from './renderers.module.scss';

const GroupRenderer = ({ page, group, formik }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(document.body.clientWidth < 800);
  }, []);

  const { isDesigner } = useContext(WizardContext);
  const { getVisibleFields } = useContext(WizardInstanceContext);

  const width = isMobile ? group.mobileWidth : group.width;
  const styles = { minWidth: `${width || 100}%`, maxWidth: `${width || 100}%` };

  const visibleFields = getVisibleFields(group, formik?.values);

  return (
    <ResizeSensor observeParents={true} onResize={() => setIsMobile(document.body.clientWidth < 800)}>
      <div className={classes.group} style={styles}>
        <div className={classNames(classes.inner, group?.block && classes.block)}>
          {!!group.showTitle && <div className={classes.title}>{group.title}</div>}
          {!!group.description && (
            <div className={classes.description} style={!!group.showTitle ? { margin: '-10px 0 0' } : {}}>
              {group.description}
            </div>
          )}
          {!!isDesigner && !!group?.metadata && <div className={classes.metadataPlaceholder}>Metadata fields placeholder.</div>}
          {!!visibleFields?.length && (
            <div className={classes.content}>
              {visibleFields?.map(field => (
                <FieldRenderer key={field?.id} page={page} group={group} field={field} isMobile={isMobile} values={formik.values} formik={formik} />
              ))}
            </div>
          )}
        </div>
      </div>
    </ResizeSensor>
  );
};

export default connect(GroupRenderer);
