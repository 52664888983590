import React from 'react';
import cn from 'classnames';

import { getContentItemStyle } from '../../../helpers/get-content-item-style';
import classes from './preview-content-item.module.scss';

const PreviewLinkContentItem = ({ children, contentItem, hovered }) => {
  const style = { ...contentItem.styleConfig, ...getContentItemStyle(contentItem.customStyle) };
  return (
    <a href={contentItem.value} target="_blank" rel="noreferrer" className={cn(hovered === contentItem.id && classes.hovered, contentItem.class)} style={style}>
      {contentItem.text || 'Unset'}
      {children}
    </a>
  );
};

export default PreviewLinkContentItem;
