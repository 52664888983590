import React, { useContext } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { FieldArray } from 'formik';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';
import * as Yup from 'yup';

import { createRecord, updateRecord } from '../../../../../../../apis/configuration';
import Fields from '../../../../../../fields';
import Form from '../../../../../../form';
import { SystemContext } from '../../../../system-provider';

import classes from './engagement-metadata.module.scss';
import { OrganizationType } from '../../../../../../../domain/organization-type';

const EngagementMetadata = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    setLoading(true);
    const payload = { ...values, data: { ...values.data, schema: JSON.stringify(values.data?.schema) } };
    if (values?.id) {
      await updateRecord(values?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <Form
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        ...record,
        data: { schema: !!data?.schema ? JSON.parse(data?.schema) : [] },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.mixed().required('Scope is required'),
        data: Yup.object().shape({
          schema: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Name is required'),
              type: Yup.string().required('Type is required'),
              providedBy: Yup.mixed().required('Provided by is required'),
              example: Yup.string().required('Example is required'),
            }),
          ),
        }),
      })}
      onSubmit={submit}
      enableReinitialize
    >
      {form => {
        const disabled = loading;
        return (
          <div className={classes.fields}>
            {!!form?.values?.data?.schema?.length && (
              <div className={classes.header}>
                <div>Name</div>
                <div>Type</div>
                <div>Provided by</div>
                <div>Example</div>
              </div>
            )}
            <FieldArray name="data.schema">
              {({ remove, push }) => (
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.container}>
                    {form.values.data?.schema?.map?.((_, index) => (
                      <div className={classes.row} key={index}>
                        <div className={classes.field}>
                          <Fields.Text type="text" name={`data.schema.${index}.name`} placeholder="Name" outline={false} minimal={true} />
                        </div>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.schema.${index}.type`}
                            placeholder="Type"
                            options={[
                              { label: 'Text', value: 'text' },
                              { label: 'Number', value: 'number' },
                            ]}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.schema.${index}.providedBy`}
                            options={[
                              { label: 'Client', value: OrganizationType.Client },
                              { label: 'Supplier', value: OrganizationType.Supplier },
                            ]}
                            placeholder="Provided by"
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Text name={`data.schema.${index}.example`} placeholder="Example" outline={false} minimal={true} />
                        </div>
                        <div className={classes.removeButton}>
                          <Button icon="small-minus" outlined type="button" onClick={() => remove(index)} />
                        </div>
                      </div>
                    ))}
                    <ButtonGroup>
                      <Button
                        outlined
                        fill
                        icon={<Icons.Plus size={16} strokeWidth={1.5} />}
                        text={'Add New'}
                        disabled={!form.isValid}
                        onClick={() => push({ name: '', type: '', example: '' })}
                      />
                    </ButtonGroup>
                  </div>
                </SimpleBar>
              )}
            </FieldArray>
            <Button outlined fill text="Submit" disabled={disabled} loading={loading} onClick={() => (disabled ? null : form.submitForm())} />
          </div>
        );
      }}
    </Form>
  );
};

export default EngagementMetadata;
