import React from 'react';

import Page from '../../../../components/page';

import ClientTime from '../../../../components/organizational/client/time';

const TimeList = () => {
  return (
    <Page title="Time" path={[{ name: 'Time', to: '/time' }]} scroll={false}>
      <ClientTime additionalActions={[{ divider: true }, { name: 'View engagement', href: d => `/engagements/${d.engagementId}` }]} />
    </Page>
  );
};

export default TimeList;
