import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../components/dialog';
import Form from '../../../../../../components/form';
import Fields from '../../../../../../components/fields';
import useApi from '../../../../../../hooks/use-api';
import { createDepartment, updateDepartment } from '../../../../../../apis/client/departments';

function createOrUpdateDepartment(clientId, department) {
  return department.id ? updateDepartment({ clientId, department }) : createDepartment({ clientId, department });
}

const NewDepartment = ({ open, department, clientId, onClose, onSave }) => {
  const createOrUpdateApi = useApi((clientId, department) => createOrUpdateDepartment(clientId, department), { skip: true });
  const submit = async values => {
    await createOrUpdateApi.request(clientId, values);
    onSave();
  };

  if (!open) {
    return null;
  }

  const title = department?.id ? `Edit Department: ${department.name}` : 'New Department';
  return (
    <Dialog title={title} isOpen={open} onClose={onClose}>
      <Form
        initialValues={{
          id: department?.id,
          name: department?.name || '',
          description: department?.description || '',
          // hasOwnInvoice: department?.hasOwnInvoice,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Department name is required'),
        })}
        onSubmit={submit}
      >
        <Fields.Text name="name" label="Name" />
        <Fields.Textarea name="description" label="Description" />
        {/* <Fields.Checkbox name="hasOwnInvoice" label="Standalone invoice ?" /> */}
        <Button type="submit" outlined fill text="Submit" disabled={createOrUpdateApi.loading} loading={createOrUpdateApi.loading} />
      </Form>
    </Dialog>
  );
};

export default NewDepartment;
