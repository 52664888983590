import React, { useState } from 'react';

import useTable from '../../../../../../../hooks/use-table';
import Table from '../../../../../../../components/table';
import Dialog from '../../../../../../../components/dialog';
import Spinner from '../../../../../../../components/spinner';
import ExternalIdentities from '../../../../../../../components/external-identities';

import { getExternalIdentities, removeExternalIdentity } from '../../../../../../../apis/external-identities';

import ProvidersMenu from './menu';

import classes from '../../details.module.scss';

const ProvidersForm = ({ userId, supplierId }) => {
  const { items, loading, pageCount, page, filters, initialFilters, reload, goToPage, setFilters, resetFilters } = useTable(
    getExternalIdentities,
    { pageSize: 10, controlled: true },
    { userId, supplierId, query: '' },
  );
  const [dialog, setDialog] = useState({ open: false });
  const closeDialog = () => setDialog({ open: false, identity: undefined, register: false, title: undefined });

  const onRemoveIdentity = async identity => {
    await removeExternalIdentity({ id: identity?.id });
    reload();
  };

  return (
    <React.Fragment>
      <Table.Container data={items} loading={loading} dark>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={
            <ProvidersMenu
              onRegister={() => setDialog({ open: true, identity: undefined, register: true, title: 'Register external identity' })}
              onLink={() => setDialog({ open: true, identity: undefined, register: false, title: 'Add external identity' })}
            />
          }
        />
        <Table.Columns.TwoLines showOnMobile name="Provider" lines={[d => d?.providerName, d => d?.providerType]} />
        <Table.Columns.Default showOnMobile name="Identifier" render={d => d?.externalOrganizationId || d?.externalUserId} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'Modify', onClick: d => setDialog({ open: true, identity: d, title: 'Update external identity' }) },
            { name: 'Remove', onClick: d => onRemoveIdentity(d), confirmation: { messages: { confirmButton: 'Remove' } } },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <Dialog isOpen={!!dialog?.open} onClose={closeDialog} title={dialog?.title}>
        <ExternalIdentities.Form
          userId={userId}
          supplierId={supplierId}
          identity={dialog?.identity}
          register={dialog?.register}
          onComplete={() => {
            reload();
            closeDialog();
          }}
        />
      </Dialog>
    </React.Fragment>
  );
};

const Providers = ({ api }) => {
  return (
    <div className={classes.tab}>
      {api?.loading ? (
        <div className={classes.centerWithin}>
          <Spinner global={false} blend />
        </div>
      ) : (
        <ProvidersForm {...{ userId: api?.response?.managerUserId, supplierId: api?.response?.id }} />
      )}
    </div>
  );
};

export default Providers;
