import React, { useState } from 'react';
import moment from 'moment/moment';
import { Tooltip } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Table from '../../../../../../../components/table';
import Avatar from '../../../../../../../components/avatar';
import useTable from '../../../../../../../hooks/use-table';

import { getClientSub, removeClient } from '../../../../../../../apis/platform/organizations';

import SubClientsMenu from './menu';
import SubClientFilters from './filters';
import NewSubClient from './new';

import classes from '../../details.module.scss';

const SubClients = ({ clientId }) => {
  const [dialog, setDialog] = useState({ open: false });
  const { items, loading, filters, initialFilters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    getClientSub,
    { pageSize: 10, controlled: true },
    { clientId, includeRemoved: null, countries: null },
  );

  const handleClientRemove = async subClient => {
    await removeClient(subClient?.id);
    reload();
  };

  return (
    <div className={classes.tab}>
      <Table.Container data={items} loading={loading} dark>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<SubClientsMenu setDialog={setDialog} />}
          content={<SubClientFilters />}
        />
        <Table.Columns.Default
          name="Name"
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d.name} url={d.logo} />}
        />
        <Table.Columns.TwoLines width="2fr" showOnMobile lines={[d => d?.name, d => d.address?.prettyName]} />
        <Table.Columns.TwoLines width="2fr" name="Contact" lines={[d => d?.managerName || 'Not provided', d => d?.managerEmailAddress]} />
        <Table.Columns.Default
          width="65px"
          render={d => (
            <div className={classes.tags}>
              {!!d?.isInvoiced && (
                <Tooltip content={'Client is charged for invoices.'}>
                  <Icons.Book size={15} />
                </Tooltip>
              )}
            </div>
          )}
        />
        <Table.Columns.Default
          name="Created at"
          render={d => <small>{moment(d.createdAt).isValid() ? moment(d.createdAt).format('LL') : moment(d.updatedAt).format('LL')}</small>}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', href: d => `/clients/${d.id}` },
            { name: 'Remove', onClick: d => handleClientRemove(d), confirmation: true },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <NewSubClient isOpen={dialog.open} parentId={clientId} onClose={() => setDialog({ open: false })} reloadList={reload} />
    </div>
  );
};

export default SubClients;
