import React from 'react';
import moment from 'moment';
import * as Icons from 'react-feather';

import { capitalize } from '../../../../../utilities/common';

import classes from '../../quick.module.scss';

const Break = ({ record }) => {
  const duration = moment.duration(record.duration);
  return (
    <div className={classes.breakContainer}>
      <div className={classes.breakText}>
        {capitalize(`${duration.humanize()} break`)}
        <Icons.Coffee size={16} strokeWidth={1.5} />
      </div>
    </div>
  );
};

export default Break;
