import Authenticate from './authenticate';
import Impersonate from './impersonate';
import Callback from './callback';
import Logout from './logout';

const Authentication = {
  Initial: Authenticate,
  Impersonate,
  Callback,
  Logout,
};

export default Authentication;
