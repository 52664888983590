import React, { useState } from 'react';
import { Button, Classes, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';

import useAutosave from '../../../../../hooks/use-autosave';
import useApi from '../../../../../hooks/use-api';
import { updateTemplate, validateTemplate, publishTemplate } from '../../../../../apis/templates';
import { getRecord, getRecordValue, updateRecord } from '../../../../../apis/configuration';
import { downloadStringAsFile } from '../../../../../utilities/files';

import useDocuments from '../../state/use-documents';
import { getContextTypeLabel } from '../../helpers/get-context-type-label';

import ReplaceDialog from './replace-dialog';
import ImportDialog from './import-dialog';
import RenameDialog from './rename-dialog';

import classes from './toolbar.module.scss';

async function updateDocumentConfigurationRecord({ status, recordId }) {
  const record = await getRecord(recordId);
  const recordValue = await getRecordValue(recordId);
  let description = record?.description;
  if (status === 0) {
    const alreadyLabeled = description.includes('(Draft)');
    description = alreadyLabeled ? description : `(Draft) ${description}`;
  } else if (status === 1) {
    description = description.replace('(Draft)', '').trim();
  }
  const newRecord = await updateRecord(recordId, {
    scopeId: record.scopeId,
    description,
    data: {
      documentTemplateType: recordValue?.documentTemplateType,
      documentName: recordValue?.documentName,
      templateId: recordValue.templateId,
    },
    configurationType: record.configurationType,
  });
  return newRecord;
}

const Toolbar = () => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const { loading, template, stream, wizard, publish, togglePreview } = useDocuments();
  const saveApi = useApi(
    async () => {
      await validateTemplate(template);
      return updateTemplate(template);
    },
    { skip: true },
  );
  const publishApi = useApi(() => publishTemplate(template.id), { skip: true });
  const updateConfigurationRecordApi = useApi(updateDocumentConfigurationRecord, { skip: true });

  const handleSave = async () => {
    const response = await saveApi.request();
    if (template.status === 0) {
      const newRecord = await updateConfigurationRecordApi.request({ status: response.status, recordId });
      navigate(`/settings/documents/${template.id}/${newRecord?.data?.id}`, { replace: true });
    }
  };

  useAutosave({ payload: template, onSave: handleSave });

  const exportTemplate = () => {
    const content = Buffer.from(stream.html).toString('base64');
    const json = { template, content };
    downloadStringAsFile(JSON.stringify(json), template.name, 'json');
  };

  const handlePublish = async () => {
    await publishApi.request();
    publish();
    const newRecord = await updateConfigurationRecordApi.request({ status: 1, recordId });
    navigate(`/settings/documents/${template.id}/${newRecord?.data?.id}`, { replace: true });
  };

  const closeDialog = () => setDialog({ open: false, type: undefined });

  function getContextTypeName() {
    const [documentType] = template?.description?.split(', ') || [];
    return documentType || getContextTypeLabel(template?.contextTypeName);
  }
  function getWizardReferenceName() {
    const [, wizardName, organizationName, scope] = template?.description?.split(', ') || [];
    const name = wizardName || wizard?.title;
    return organizationName ? `${name} ${organizationName} (${scope})` : name;
  }

  const menuActionLoading = publishApi.loading || saveApi.loading || updateConfigurationRecordApi.loading;
  return (
    <div className={classes.toolbar}>
      <div className={classes.document}>
        <div className={cn(classes.name, loading && Classes.SKELETON)}>
          {template?.name || 'Loading...'}
          {template?.status !== 1 && <span className={classes.draft}> (draft)</span>}
        </div>
        <div className={classes.info}>
          <div className={cn(classes.type, loading && Classes.SKELETON)}>{getContextTypeName()}</div>
          {template?.objectReferences && (
            <div className={cn(classes.reference, loading && Classes.SKELETON)}>
              <Link to={`/settings/wizards/${template.objectReferences}`}>{getWizardReferenceName()}</Link>
            </div>
          )}
        </div>
      </div>
      <div className={classes.actions}>
        <Popover
          placement="bottom"
          content={
            <Menu>
              <MenuItem text="Preview" icon="search" onClick={togglePreview} />
              <MenuDivider />
              <MenuItem text="Save" icon="floppy-disk" onClick={handleSave} disabled={saveApi.loading || updateConfigurationRecordApi.loading} />
              <MenuItem text="Publish" icon="cloud-upload" onClick={handlePublish} disabled={saveApi.loading || updateConfigurationRecordApi.loading} />
              <MenuDivider />
              <MenuItem text="Import" icon="import" disabled onClick={() => setDialog({ open: true, type: 'import' })} />
              <MenuItem text="Export" icon="code-block" onClick={exportTemplate} />
              <MenuDivider />
              <MenuItem text="Rename" icon="edit" onClick={() => setDialog({ open: true, type: 'rename' })} />
              <MenuItem
                text="Replace document"
                icon="rotate-document"
                onClick={() => setDialog({ open: true, type: 'replace' })}
                disabled={stream.fromScratch}
              />
            </Menu>
          }
        >
          <Button minimal icon="more" loading={menuActionLoading} />
        </Popover>
      </div>

      <ReplaceDialog open={dialog.open && dialog.type === 'replace'} toggle={closeDialog} />
      <ImportDialog open={dialog.open && dialog.type === 'import'} toggle={closeDialog} />
      <RenameDialog open={dialog.open && dialog.type === 'rename'} toggle={closeDialog} />
    </div>
  );
};

export default Toolbar;
