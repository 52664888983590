import React from 'react';
import cn from 'classnames';

import Fields from '../../../../../fields';

import { replaceMacros } from './renderers-helper';

import classes from './renderers.module.scss';

/*
  `isErrorMessage`  is a custom property that is used to display the paragraph as an error message and display hidden field that will always be invalid (returns an error)
  and that will prevent the form from being submitted.
*/
const ParagraphRenderer = ({ isDesigner, values, props, formik }) => {
  const { id, text, borderColor, backgroundColor, isErrorMessage } = props;
  const textToDisplay = React.useMemo(() => {
    return isDesigner ? text : replaceMacros(text, values);
  }, [text, values]);
  const style = {
    backgroundColor: backgroundColor,
    border: borderColor ? `1px solid ${props.borderColor}` : undefined,
    borderRadius: borderColor ? '5px' : undefined,
    padding: borderColor ? '15px' : undefined,
  };

  return (
    <div
      style={style}
      className={cn(
        classes.formParagraph,
        !!props.block && classes.block,
        !!props.paddingBottom && classes.paddingBottom,

        // only display error message if form is submitted
        !!isErrorMessage && !formik?.submitCount && classes.formNotSubmitted,
      )}
    >
      {isErrorMessage && <Fields.Text name={`error-${id}`} hidden validate={() => 'Error'} />}
      <div dangerouslySetInnerHTML={{ __html: textToDisplay }} />
    </div>
  );
};

export default ParagraphRenderer;
