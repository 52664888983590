import React, { useContext } from 'react';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import _ from 'lodash';
import * as Icons from 'react-feather';

import { SystemContext } from '../system-provider';
import FlowMenu from './flowMenu';

const RecordTypesMenu = ({ onAdd, disabled, existingRecords = [] }) => {
  const { selectedScope, types } = useContext(SystemContext);

  const isTypeDisabled = type => {
    if (disabled) return false;
    if (!selectedScope) return false;

    const isGlobal = selectedScope?.path === null;
    const isCountry = !!selectedScope?.path && !selectedScope?.path?.includes('|');
    const scopeType = isGlobal ? 'Global' : isCountry ? 'Country' : 'Lower';
    if (type?.eligibleScopes?.length && !type?.eligibleScopes?.includes(scopeType)) {
      return true;
    }

    if (!!type?.single && !!existingRecords?.find(r => r.configurationType === type?.name)) {
      return true;
    }

    return false;
  };

  const renderGroup = (group, index) => {
    return (
      <MenuItem key={index} text={_.startCase(group?.name)} popoverProps={{ interactionKind: 'click' }}>
        {group?.recordTypes?.length > 0 &&
          group?.recordTypes?.map((recordType, i) => (
            <MenuItem
              key={i}
              disabled={isTypeDisabled(recordType)}
              text={_.startCase(recordType?.name?.split('|')?.[1])}
              onClick={() => onAdd({ configurationType: recordType?.name })}
            />
          ))}
      </MenuItem>
    );
  };

  const sortedTypes = _.sortBy(types || [], ['name']);
  return (
    <Popover
      disabled={disabled}
      placement="bottom"
      content={
        <Menu>
          {sortedTypes?.map((group, i) => {
            if (group?.name === 'Wizards') {
              return null;
            } else if (group?.name === 'Flows') {
              return <FlowMenu sortedTypes={sortedTypes} onAdd={onAdd} isTypeDisabled={isTypeDisabled} key={i} />;
            }
            return renderGroup(group, i);
          })}
        </Menu>
      }
    >
      <Button disabled={disabled} outlined small icon={<Icons.Plus size={16} strokeWidth={1.5} />} />
    </Popover>
  );
};

export default RecordTypesMenu;
