import React, { useContext } from 'react';

import Fields from '../../../../../../../../fields';
import { WizardContext } from '../../../../../../state';
import getWizardSpecificMacros from '../../../../../../common/get-wizard-specific-macros';

const options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const ParagraphProperties = ({ form }) => {
  const { descriptor } = useContext(WizardContext);
  const macros = React.useMemo(() => getWizardSpecificMacros(descriptor.children, []), [descriptor]);

  return (
    <React.Fragment>
      <Fields.RichText
        name="text"
        options={macros}
        outline={false}
        minimal={true}
        modalProps={{
          title: form.values.text ? 'Edit content' : 'Setup content',
          buttonProps: {
            icon: 'paragraph',
            text: 'Setup content',
          },
        }}
        submitOnChange={true}
      />
      <Fields.Color label="Background color" name="backgroundColor" submitOnChange={true} />
      <Fields.Color label="Border color" name="borderColor" submitOnChange={true} />
      <Fields.Select label="Display as block ?" name="block" options={options} submitOnChange={true} />
      <Fields.Select label="Display bottom padding ?" name="paddingBottom" options={options} submitOnChange={true} />
      <Fields.Select label="This is an error message ?" name="isErrorMessage" options={options} submitOnChange={true} showError={false} />
    </React.Fragment>
  );
};

export default ParagraphProperties;
