import React from 'react';
import { Route, Routes } from 'react-router-dom';

import List from './containers/list';

const Evaluations = () => {
  return (
    <Routes>
      <Route path="/" Component={List} />
    </Routes>
  );
};

export default Evaluations;
