import React from 'react';

import { OperatorDefinitions } from '../../../../../../domain/operator-definitions';

import classes from '../../custom-fields.module.scss';

const getAvailableOperators = ({ fields, fieldPath }) => {
  const field = fields[fieldPath];
  if (!fieldPath || !field) {
    return [];
  }
  const operators = OperatorDefinitions[field.type]?.map(operator => ({
    ...operator,
    description: (
      <div className={classes.info}>
        <div className={classes.group}>{operator?.description}</div>
      </div>
    ),
  }));
  return operators ?? [];
};

export default getAvailableOperators;
