import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResizeSensor } from '@blueprintjs/core';

import SystemProvider from './system-provider';
import Scopes from './scopes';
import Records from './records';

import classes from './system-configurator.module.scss';

const System = ({ organizationId, currentOrganization, embedded = false, offset = 0 }) => {
  const [height, setHeight] = useState(0);

  const calculateHeight = () => {
    const client = document.body.clientHeight;
    const height = !embedded ? client - 120 : client - offset;
    setHeight(height);
  };

  useEffect(() => {
    const t = setTimeout(calculateHeight, 100);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <SystemProvider organizationId={organizationId || currentOrganization} currentOrganization={currentOrganization}>
      <ResizeSensor observeParents={true} onResize={calculateHeight}>
        <div id="system-configurator" className={classes.configurator}>
          <Scopes height={height} embedded={embedded} />
          <Records height={height} embedded={embedded} />
        </div>
      </ResizeSensor>
    </SystemProvider>
  );
};

const mapStateToProps = state => ({
  currentOrganization: state.identity.membership?.organization?.id,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(System);
