import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Drawer, MenuItem } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Yup from 'yup';
import cn from 'classnames';

import Form from '../../../form';
import { CreatableField, TextField } from '../../../fields';
import { sendAdHocEmailNotification } from '../../../../apis/platform/organizations';
import useApi from '../../../../hooks/use-api';
import EmailPreview from './email-preview';
import { emailDialogValidationSchema } from './email-dialog-helper';

import classes from './email-dialog.module.scss';

const EmailDialog = ({ isOpen, isMinimized, formValues, handleClose, handleMinimize }) => {
  const sendAdHocEmailNotificationApi = useApi(sendAdHocEmailNotification, { skip: true });

  const [initialValues, setInitialValues] = useState({ subject: '', content: '', ...formValues });

  useEffect(() => {
    setInitialValues(prevState => ({ ...prevState, ...formValues }));
  }, [formValues]);

  const handleSubmit = async values => {
    const payload = {
      subject: values.subject,
      organizationId: values.organizationId,
      recipientType: values.recipientType,
      to: values.to,
      cc: values.cc,
      bcc: values.bcc,
      content: values.content,
    };
    await sendAdHocEmailNotificationApi.request(payload);
    handleClose();
  };

  return (
    <Drawer
      className={classes.drawer}
      portalClassName={cn(classes.emailDialog, isMinimized && classes.emailDialogMinimized)}
      position="right"
      usePortal={true}
      size="100%"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={isOpen || isMinimized}
      onClose={handleClose}
    >
      <Form initialValues={initialValues} validationSchema={emailDialogValidationSchema} enableReinitialize onSubmit={handleSubmit}>
        {form => {
          const inToList = form.values.to.includes(formValues.recipientType);
          const inCcList = form.values.cc.includes(formValues.recipientType);
          const inBccList = form.values.bcc.includes(formValues.recipientType);

          let options = [];
          if (!inToList && !inCcList && !inBccList) {
            options = [{ label: formValues.recipientType, value: formValues.recipientType }];
          }

          return (
            <React.Fragment>
              <div className={classes.header}>
                <div className={classes.title}>Send email</div>
                <ButtonGroup>
                  <Button
                    type="button"
                    minimal
                    icon="minimize"
                    className={classes.iconButton}
                    onClick={() => {
                      setInitialValues({ ...form.values });
                      handleMinimize();
                    }}
                  />
                  <Button type="button" minimal icon="cross" className={classes.iconButton} onClick={handleClose} />
                </ButtonGroup>
              </div>
              <SimpleBar className={classes.wrapper}>
                <div className={classes.content}>
                  <TextField name="subject" label="Subject" />
                  <CreatableField name="to" label="To" options={options} createNewItemRenderer={createNewItemRenderer} />
                  <CreatableField name="cc" label="Cc" options={options} createNewItemRenderer={createNewItemRenderer} />
                  <CreatableField name="bcc" label="Bcc" options={options} createNewItemRenderer={createNewItemRenderer} />
                  <EmailPreview />
                </div>
              </SimpleBar>
              <div className={classes.footer}>
                <Button className={cn(classes.button)} type="button" outlined text="Cancel" onClick={handleClose} />
                <div className={classes.space} />
                <Button className={cn(classes.button, classes.send)} type="submit" intent="success" text="Send" />
              </div>
            </React.Fragment>
          );
        }}
      </Form>
    </Drawer>
  );
};

const createNewItemRenderer = (query, active, handleClick) => {
  const isValidEmail = Yup.string().email().required().isValidSync(query);
  return (
    <MenuItem
      icon="add"
      text={`Create ${query}`}
      roleStructure="listoption"
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
      disabled={!isValidEmail}
    />
  );
};

export default EmailDialog;
