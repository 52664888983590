import axios from 'axios';
import { isEmpty } from 'lodash-es';

export const getLocation = async ({ query, placeId }) => {
  if (!isEmpty(query)) return await resolveLocation(query);
  if (!isEmpty(placeId)) return await resolvePlace(placeId);

  return await getCurrentLocation();
};

export const resolveLocation = async query => {
  if (isEmpty(query)) return await getCurrentLocation();

  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${query}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const { data } = await axios.get(apiUrl);
  return data.results;
};

export const resolvePlace = async placeId => {
  if (isEmpty(placeId)) return await getCurrentLocation();

  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const { data } = await axios.get(apiUrl);
  return data.results;
};

export const getCurrentLocation = () => {
  const defaultPosition = { lat: 59.95, lng: 30.33 };
  if (!navigator.geolocation) return Promise.resolve(defaultPosition);

  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        resolve(defaultPosition);
      },
    );
  });
};
