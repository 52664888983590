import moment from 'moment';

import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';

export const getInvoices = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/invoice${toQueryString(parameters)}`);
  return response?.data;
};

export const getCsvData = async invoiceId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/invoice/export/${invoiceId}`);
  return response?.data;
};

export const generateInvoices = async ({ periodType, period, clientId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/invoice`, { periodType, period, clientId });
  return response?.data;
};

export const sendInvoice = async ({ invoiceId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/invoice/send/${invoiceId}`);
  return response?.data;
};

export const getInvoicedClients = async () => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/invoice/clients`);
  return response?.data;
};

export const removeInvoice = async invoiceId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/invoice/${invoiceId}`);
  return response?.data;
};

export const addInvoiceItem = async payload => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/invoice/item`, payload);
  return response?.data;
};

export const updateInvoiceItem = async ({ id, payload }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/invoice/item/${id}`, payload);
  return response?.data;
};

export const removeInvoiceItem = async id => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/invoice/item/${id}`);
  return response?.data;
};

export const getEngagementFees = async ({ clientId, engagementId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/invoice/fees/${clientId}/${engagementId}`);
  return response?.data;
};
