import Clients from '../configuration/client';
import { toQueryString } from '../utilities/common';

export const getProfile = async () => {
  const response = await Clients.Api.get(`ui/profile`);
  return response?.data;
};

export const setPersonalDetails = async details => {
  const response = await Clients.Api.post('ui/profile/personal', details);
  return response?.data;
};

export const setEmergencyContact = async details => {
  const response = await Clients.Api.post('ui/profile/emergency-contact', details);
  return response?.data;
};

export const setUserAddress = async (userId, placeId) => {
  const response = await Clients.Api.post(`ui/profile/address`, { userId, placeId });
  return response?.data;
};

export const setUserEmail = async ({ userId, email }) => {
  const response = await Clients.Api.put(`ui/profile/email`, { userId, email });
  return response?.data;
};

export const generateResetPasswordLink = async ({ email }) => {
  const response = await Clients.Api.post(`ui/profile/reset-link`, { email });
  return response?.data;
};

export const setUserPassword = async ({ userId, password }) => {
  const response = await Clients.Api.put(`ui/profile/password`, { userId, password });
  return response?.data;
};

export const getUserLogons = async ({ userId } = {}) => {
  const response = await Clients.Api.get(`ui/profile/logons${!!userId ? toQueryString({ userId }) : ''}`);
  return response?.data;
};

export const updateSignatureSettings = async signature => {
  const response = await Clients.Api.post('ui/profile/signature-settings', signature);
  return response?.data;
};

export const uploadAvatar = async ({ avatar }, onUploadProgress = () => null) => {
  const data = new FormData();
  data.append('avatar', avatar);
  const response = await Clients.Api.post(`ui/profile/avatar`, data, { onUploadProgress });
  return response?.data;
};
