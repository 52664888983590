export const MaritalStatusType = {
  Single: 1000,
  Married: 2000,
  Partnership: 3000,
  Divorced: 4000,
  Separated: 5000,
  Widowed: 6000,
};

export const MaritalStatusOptions = [
  { value: MaritalStatusType.Single, label: 'Single' },
  { value: MaritalStatusType.Married, label: 'Married' },
  { value: MaritalStatusType.Partnership, label: 'Partnership' },
  { value: MaritalStatusType.Divorced, label: 'Divorced' },
  { value: MaritalStatusType.Separated, label: 'Separated' },
  { value: MaritalStatusType.Widowed, label: 'Widowed' },
];

const MaritalStatusLabels = MaritalStatusOptions.reduce((mapper, status) => {
  mapper[status.value] = status.label;
  return mapper;
}, {});

export const maritalStatusToLabel = status => MaritalStatusLabels?.[status] || '-';
