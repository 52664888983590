import React from 'react';
import { useParams } from 'react-router';

import Configurators from '../../../../../components/configurators';
import Page from '../../../../../components/page';

import classes from './wizards.module.scss';

const Wizards = props => {
  const { id, recordId } = useParams();
  return (
    <Page
      title="Wizards"
      path={[
        { name: 'Settings', to: '/settings' },
        { name: 'Wizards', to: `/settings?group=wizards` },
      ]}
      scroll={false}
    >
      <div className={classes.wizards}>
        <Configurators.Wizards isDesigner templateId={id} recordId={recordId} />
      </div>
    </Page>
  );
};

export default Wizards;
