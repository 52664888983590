import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../components/dialog';
import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';
import useApi from '../../../../../../hooks/use-api';
import { setSupplierAddress } from '../../../../../../apis/supplier/organizations';
import { setUserAddress } from '../../../../../../apis/profile';

const AddressForm = ({ isOpen = false, user, supplier, onClose, onSave }) => {
  const setSupplierAddressApi = useApi(setSupplierAddress, { skip: true });
  const setUserAddressApi = useApi(setUserAddress, { skip: true });

  const submit = async (values, { setFieldError }) => {
    const { address } = values;
    if (!address?.place_id) {
      setFieldError('address', 'Unable to resolve requested location.');
      return;
    }

    if (!!user) {
      await setUserAddressApi.request(user?.id, address.place_id);
    } else {
      await setSupplierAddressApi.request({ supplierId: supplier?.id, placeId: address.place_id });
    }
    onSave(values);
  };

  const loading = setSupplierAddressApi.loading;
  const address = (user?.personalDetails || supplier)?.address ? JSON.parse((user?.personalDetails || supplier)?.address?.json) : null;
  return (
    <Dialog title="Address" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ address }} onSubmit={submit}>
        <Fields.Address fill name="address" label="Address" precision="address" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default AddressForm;
