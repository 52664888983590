export const ElementTypes = {
  TEXT: 'Text',
  IMAGE: 'Image',
  DIV: 'Div',
  LINK: 'Hyperlink',
  BUTTON: 'Button',
  TABLE: 'Table',
  COLUMN: 'Column',
};

export const emailFieldsDefinitionTypes = {
  [ElementTypes.TEXT]: {
    name: ElementTypes.TEXT,
    label: 'Text',
    description: 'Capture "free-form" textual data',
    icon: 'text-highlight',
  },
  [ElementTypes.BUTTON]: {
    name: ElementTypes.BUTTON,
    label: 'Button',
    description: 'Static button',
    icon: 'widget-button',
  },
  [ElementTypes.LINK]: {
    name: ElementTypes.LINK,
    label: 'Link',
    description: 'Static hyperlink',
    icon: 'link',
  },
  [ElementTypes.IMAGE]: {
    name: ElementTypes.IMAGE,
    label: 'Image',
    description: 'Static image',
    icon: 'media',
  },
  [ElementTypes.COLUMN]: {
    name: ElementTypes.COLUMN,
    label: 'Column',
    description: 'Table column',
    icon: 'th',
  },
};

export const emailBlocksDefinitionTypes = {
  [ElementTypes.DIV]: {
    name: ElementTypes.DIV,
    label: 'Block',
    description: 'Add new block',
    icon: 'add-row-bottom',
  },
  [ElementTypes.TABLE]: {
    name: ElementTypes.TABLE,
    label: 'Table',
    description: 'Static table',
    icon: 'th',
  },
};

export const blockOptions = Object.values(emailBlocksDefinitionTypes);

export const fieldOptions = Object.values(emailFieldsDefinitionTypes);
