import 'cropperjs/dist/cropper.css';
import React, { useCallback, useEffect, useRef } from 'react';
import Cropper from 'cropperjs';
import { Button, Slider } from '@blueprintjs/core';
import debounce from 'lodash/debounce';
import * as Icons from 'react-feather';

import styles from './file.module.scss';

const CropZone = ({ stage, file, config, onUpload }) => {
  const img = useRef();
  const cropper = useRef(null);
  const [zoom, setZoom] = React.useState(0);
  const delayedZoomTo = useCallback(
    debounce(zoom => cropper.current?.zoomTo(zoom), 100),
    [],
  );

  useEffect(() => {
    if (img.current && !cropper.current) {
      const objectURL = URL.createObjectURL(file);
      img.current.src = objectURL;
      cropper.current = new Cropper(img.current, {
        aspectRatio: config?.aspectRatio,
        // viewMode: 1, // restrict the crop box not to exceed the size of the canvas.
        dragMode: 'move',
        modal: true, // Show the black modal above the image and under the crop box.
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        zoomOnTouch: false,
        zoomOnWheel: false,
        ready(event) {
          const image = this.cropper.getImageData();
          const zoom = image.width / image.naturalWidth;
          setZoom(zoom);
        },
        zoom(event) {
          if (event.detail.ratio === event.detail.oldRatio) {
            event.preventDefault(); // Prevent zoom
            return;
          }
          const zoom = Math.max(0, Math.max(0, event.detail.ratio));
          setZoom(zoom); // todo delete this in case we remove zoom in and zoom out buttons
        },
      });
    }
  }, [img.current]);

  useEffect(() => {
    return () => {
      cropper.current?.destroy();
    };
  }, []);

  const handleUpload = () => {
    const canvas = cropper.current?.getCroppedCanvas({
      width: config?.width,
      height: config?.height,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    });
    canvas?.toBlob(blob => {
      if (blob) {
        const newFile = new File([blob], file.name, {
          type: file.type,
        });
        void onUpload(newFile);
      }
    }, file?.type);
  };

  const zoomStep = 0.015;
  return (
    <div className={styles.crop}>
      <div className={styles.cropContainer}>
        <img ref={img} src={file} alt="Crop" className={styles.imageToCrop} />
        <div className={styles.zoomButtons}>
          <Button icon={<Icons.ZoomOut size={16} strokeWidth={1.5} />} onClick={() => delayedZoomTo(zoom - zoomStep)} />
          <Button disabled={zoom >= 1} icon={<Icons.ZoomIn size={16} strokeWidth={1.5} />} onClick={() => delayedZoomTo(zoom + zoomStep)} />
        </div>
      </div>
      <div className={styles.zoomSlider}>
        <Slider
          min={0}
          max={1}
          stepSize={zoomStep}
          onChange={newValue => {
            delayedZoomTo(newValue);
            setZoom(newValue);
          }}
          value={zoom}
        />
      </div>
      <Button fill outlined text="Upload" loading={stage.loader} disabled={stage.loader} onClick={handleUpload} />
    </div>
  );
};

export default CropZone;
