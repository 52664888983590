import React, { useContext } from 'react';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import Fields from '../../../../../../fields';
import Form from '../../../../../../form';

import { SystemContext } from '../../../../system-provider';

import { createRecord, updateRecord } from '../../../../../../../apis/configuration';

import classes from './client-fees.module.scss';

const ClientFees = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    setLoading(true);
    const payload = { ...values, data: { ...values.data, fees: JSON.stringify(values.data.fees) } };
    if (values?.id) {
      await updateRecord(values?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <Form
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        ...record,
        data: { fees: !!data?.fees ? JSON.parse(data?.fees) : [] },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.mixed().required('Scope is required'),
        data: Yup.object().shape({
          fees: Yup.array().of(
            Yup.object().shape({
              feeType: Yup.mixed().required('Fee type is required'),
              classification: Yup.mixed().required('Classification type is required'),
              targetType: Yup.mixed().required('Target type type is required'),
              amount: Yup.number().required('Amount is required').min(0, 'Amount must be greater than or equal to 0'),
            }),
          ),
        }),
      })}
      onSubmit={submit}
      enableReinitialize
    >
      {form => {
        const disabled = loading;
        return (
          <div className={classes.fees}>
            {!!form?.values?.data?.fees?.length && (
              <div className={classes.header}>
                <div>Classification</div>
                <div>Target</div>
                <div>Type</div>
                <div>Amount</div>
              </div>
            )}
            <FieldArray name="data.fees">
              {({ remove, push }) => (
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.container}>
                    {form.values.data?.fees?.map?.((_, index) => (
                      <div className={classes.row} key={index}>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.fees.${index}.classification`}
                            placeholder="Classification"
                            options={[
                              { label: 'Employee', value: 'employee' },
                              { label: 'Independent contractor', value: 'independent-contractor' },
                            ]}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Select
                            fill
                            name={`data.fees.${index}.targetType`}
                            placeholder="Target"
                            options={[
                              { label: 'Hours', value: 'hours' },
                              { label: 'Salaries', value: 'salaries' },
                              { label: 'Deliverables', value: 'deliverables' },
                              { label: 'Expenses', value: 'expenses' },
                            ]}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.fees.${index}.feeType`}
                            placeholder="Type"
                            options={[
                              { label: 'Percentage', value: 'percentage' },
                              { label: 'Flat Amount', value: 'flat-amount' },
                            ]}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Text type="number" name={`data.fees.${index}.amount`} placeholder="Amount" outline={false} minimal={true} />
                        </div>
                        <div className={classes.removeButton}>
                          <Button icon="small-minus" outlined type="button" onClick={() => remove(index)} />
                        </div>
                      </div>
                    ))}
                    <ButtonGroup>
                      <Button
                        outlined
                        fill
                        icon={<Icons.Plus size={16} strokeWidth={1.5} />}
                        text={'Add New'}
                        disabled={!form.isValid}
                        onClick={() => push({ feeType: null, classification: null, targetType: null, amount: 0 })}
                      />
                    </ButtonGroup>
                  </div>
                </SimpleBar>
              )}
            </FieldArray>
            <Button outlined fill text="Submit" disabled={disabled} loading={loading} onClick={() => (disabled ? null : form.submitForm())} />
          </div>
        );
      }}
    </Form>
  );
};

export default ClientFees;
