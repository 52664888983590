export const ClassificationPreferenceType = {
  Any: 'any',
  EmployeeOnly: 'ee-only',
  IndependentContractorOnly: 'ic-only',
  IndependentContractorPreferred: 'ic-preferred',
};

export const ClassificationPreferenceTypeOptions = [
  {
    label: 'Any',
    value: ClassificationPreferenceType.Any,
  },
  {
    label: 'Employee only',
    value: ClassificationPreferenceType.EmployeeOnly,
  },
  {
    label: 'Independent contractor only',
    value: ClassificationPreferenceType.IndependentContractorOnly,
  },
  {
    label: 'Independent contractor preferred',
    value: ClassificationPreferenceType.IndependentContractorPreferred,
  },
];

const ClassificationPreferenceTypeLabels = ClassificationPreferenceTypeOptions.reduce((mapper, type) => {
  mapper[type.value] = type.label;
  return mapper;
}, {});

export const classificationPreferenceTypeToLabel = classification => ClassificationPreferenceTypeLabels[classification] || 'Unknown';
