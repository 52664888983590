import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { useParams } from 'react-router';

import Avatar from '../../../../../components/avatar';
import Fields from '../../../../../components/fields';
import Form from '../../../../../components/form';
import Page from '../../../../../components/page';
import Tabs from '../../../../../components/tabs';

import useApi from '../../../../../hooks/use-api';
import { getSupplier, uploadSupplierIcon } from '../../../../../apis/platform/suppliers';

import Documents from './containers/documents';
import Engagements from './containers/engagements';
import General from './containers/general';
import Pay from './containers/pay';
import Time from './containers/time';
import Providers from './containers/providers';
import Staff from './containers/staff';

import classes from './details.module.scss';

const Details = () => {
  const { id: supplierId } = useParams();
  const api = useApi(getSupplier, { params: supplierId });

  const avatar = (
    <Form initialValues={{ logo: null }} onSubmit={() => null}>
      <Fields.File
        label="Upload company icon"
        mimeTypes={'image/*'}
        name="icon"
        maxSize={5}
        crop={{ aspectRatio: 1, width: 220, height: 220 }}
        api={icon => uploadSupplierIcon({ supplierId, icon })}
        onUploaded={() => api.request(supplierId)}
        outline={false}
      >
        <Avatar loading={api?.loading} name={api?.response?.name} size={{ box: 220, text: 30 }} url={api?.response?.icon} upload />
      </Fields.File>
    </Form>
  );

  const commonProps = { api, supplierId };
  return (
    <Page
      title={!!api.loading ? <span className={Classes.SKELETON}>Supplier</span> : api?.response?.name}
      scroll={false}
      path={[
        { name: 'Suppliers', to: '/suppliers' },
        {
          name: !!api.loading ? <span className={Classes.SKELETON}>Supplier</span> : api?.response?.name,
          to: `/suppliers/${api.response?.id}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Staff" component={Staff} props={commonProps} />
          <Tabs.Item title="Engagements" component={Engagements} props={commonProps} />
          <Tabs.Item title="Time" component={Time} props={commonProps} />
          <Tabs.Item title="Pay" component={Pay} props={commonProps} />
          <Tabs.Item title="Documents" component={Documents} props={commonProps} />
          <Tabs.Item title="Service providers" component={Providers} props={commonProps} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

export default Details;
