import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import classes from './columns.module.scss';

const BoldColumn = ({ name, render, row, loading }) => {
  const content = !!loading ? name || 'loading' : render?.(row);
  return <div className={classNames(classes.boldColumn, !!loading && Classes.SKELETON)}>{typeof content === 'string' ? <div>{content}</div> : content}</div>;
};

BoldColumn.definition = 'column';
BoldColumn.width = '1fr';

export default BoldColumn;
