import React, { useState } from 'react';
import { Button, ButtonGroup, Toaster } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../../../../components/box';

import useApi from '../../../../../../../hooks/use-api';
import { generateResetPasswordLink } from '../../../../../../../apis/profile';

import LogonHistory from './logon-history';
import UpdatePasswordForm from './forms/update-password-form';

import classes from '../../details.module.scss';

const Security = ({ api }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const openDialog = type => setDialog({ open: true, type });
  const closeDialog = () => setDialog({ open: false, type: undefined });

  const generateResetLinkApi = useApi(generateResetPasswordLink, { skip: true });

  const user = api.response?.user;
  const resetLink = generateResetLinkApi?.response?.link;

  const onGenerateLink = () => {
    void generateResetLinkApi.request({ email: user?.email });
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(resetLink);
    Toaster.create({ position: 'top' }).show({ message: 'Copied password reset link!', intent: 'success' });
  };

  return (
    <div className={classes.tab}>
      <Box title="Authentication" outlined monochrome>
        <Box.Item label="Password">
          ••••••••••
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update password" onClick={() => openDialog('update-password')} />
            <Button
              small
              outlined
              disabled={!user?.email}
              loading={generateResetLinkApi?.loading}
              icon={<Icons.Key size={16} strokeWidth={1.5} />}
              title="Generate password reset link"
              onClick={onGenerateLink}
            />
          </ButtonGroup>
        </Box.Item>
        {!!resetLink && (
          <Box.Item label="Password reset link">
            <div className={classes.resetLink}>{resetLink}</div>
            <ButtonGroup>
              <Button small outlined icon={<Icons.Copy size={16} strokeWidth={1.5} />} title="Copy to clipboard" disabled={!resetLink} onClick={onCopyLink} />
            </ButtonGroup>
          </Box.Item>
        )}
        <Box.Item label="Multi-Factor authentication">
          Disabled
          <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update password" onClick={() => openDialog('mfa')} />
        </Box.Item>
      </Box>
      <LogonHistory user={user} />
      <UpdatePasswordForm isOpen={!!dialog?.open && dialog?.type === 'update-password'} user={user} onClose={closeDialog} onSave={closeDialog} />
    </div>
  );
};
export default Security;
