import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Drawer, Menu, MenuItem, Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import { setActivityVisibility } from '../../state/application';

import classes from './notifications.module.scss';

const Notifications = ({ open, setActivityVisibility }) => {
  return (
    <Drawer position="right" isOpen={open} className={classes.notifications} onClose={() => setActivityVisibility(false)}>
      <div className={classes.header}>
        <div className={classes.title}>Notifications</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={() => setActivityVisibility(false)} />
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.notificationsList}>
            {[1, 2, 3, 4, 5].map(i => (
              <div key={i} className={classes.notification}>
                <div className={classes.title}>
                  <div className={classes.date}>02/15/2021 9:47 pm</div>
                  <div>Jimmy Greaves completed onboarding, and is awaiting approval.</div>
                </div>
                <div className={classes.actions}>
                  <Popover
                    content={
                      <Menu>
                        <MenuItem icon="geosearch" text="Review supplier data" />
                        <MenuItem icon="cross" text="Dismiss notification" />
                      </Menu>
                    }
                  >
                    <Button minimal icon="more" className={classes.iconButton} />
                  </Popover>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SimpleBar>
    </Drawer>
  );
};

const mapStateToProps = state => ({
  open: state.application.activityOpen,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActivityVisibility,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
