import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Page from '../../../../components/page';
import Configurators from '../../../../components/configurators';

import classes from './onboarding.module.scss';

const Onboarding = ({ user, membership }) => {
  const { id: engagementId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('clientId');
  const userId = user?.id;
  const organizationId = membership?.organization?.id;

  return (
    <Page
      title="Engagement onboarding"
      scroll={false}
      path={[
        {
          name: 'Onboarding',
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Configurators.FlowLoader
          flowType={'engagement'}
          data={{
            clientId,
            engagementId,
            organizationId,
            userId,
          }}
          referenceId={engagementId}
          onCancel={() => {
            navigate('/');
          }}
          onComplete={() => {
            navigate('/');
          }}
        />
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
  membership: state.identity.membership,
});

export default connect(mapStateToProps)(Onboarding);
