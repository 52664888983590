import React from 'react';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import moment from 'moment';

import WithConfirmation from '../../../../../../hooks/with-confirmation';

import { TrackingRecordStatusTypes } from '../../../../../../domain/tracking-record-status-type';
import { DateTimeFormat } from '../../../../../../models/enumerations/date-format';

import StatusLabel from './status-label';

import classes from './boxes.module.scss';

const HeaderActions = ({ period, status, total, items, updateStatus }) => {
  const isLoggedLate =
    !!period && !!items?.length && items?.every?.(i => moment(i?.date, DateTimeFormat.DEFAULT).isBefore(moment(period, DateTimeFormat.DEFAULT)));

  return (
    <div className={classes.headerActions}>
      {!!isLoggedLate && (
        <Popover content={<div className={classes.lateRecord}>Logged late.</div>}>
          <Button small minimal intent="warning" icon={<Icons.AlertTriangle size={16} strokeWidth={2} />} />
        </Popover>
      )}
      <div className={classes.headerTotal}>{total}</div>
      <StatusLabel status={status} />
      <Popover
        disabled={!items?.length}
        content={
          <Menu>
            <WithConfirmation captureDismiss={false} messages={{ question: `Are you sure you want to approve all records bellow ?` }} icon={true}>
              <MenuItem
                text="Approve"
                onClick={() => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: items?.map(r => r.id) })}
                shouldDismissPopover={false}
              />
            </WithConfirmation>
            <WithConfirmation captureDismiss={false} messages={{ question: `Are you sure you want to dispute all records bellow?` }} icon={true}>
              <MenuItem
                text="Dispute"
                onClick={() => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: items?.map(r => r.id) })}
                shouldDismissPopover={false}
              />
            </WithConfirmation>
          </Menu>
        }
      >
        <Button small outlined icon={<Icons.MoreHorizontal size={16} strokeWidth={1.5} />} disabled={!items?.length} />
      </Popover>
    </div>
  );
};

export default HeaderActions;
