import React from 'react';
import classNames from 'classnames';

import Box from '../../../../../../components/box';
import Table from '../../../../../../components/table';

import { getGroupStatus, groupByDay } from '../../utilities';
import { TrackingRecordStatusTypes, trackingRecordStatusToLabel } from '../../../../../../domain/tracking-record-status-type';

import HeaderActions from './actions';

import classes from './boxes.module.scss';
import Comments from '../../../../../../components/time/comments';
import { convertTimeTo12Hour, getTotalHours } from '../../../../../../utilities/time';
import { extractStartTime, calculateShiftEnd, displayDuration } from '../../../../../../utilities/time/shift';
import EngagementInfo from './engagement-info';

const Shifts = ({ supplier, shifts, updateStatus }) => {
  const weekdays = groupByDay(shifts);
  return (
    <Box
      className={classes.box}
      title="Shifts"
      actions={<HeaderActions items={shifts} status={getGroupStatus(shifts)} total={getTotalHours(shifts)} updateStatus={updateStatus} />}
      headerClassName={classes.boxHeader}
      contentClassName={classes.container}
      outlined
      monochrome
      expandible={{ expanded: false }}
    >
      {!weekdays?.length && (
        <Box.ItemContainer className={classNames(classes.table, classes.tablePad)}>
          <Table.Container data={[]} dark showHeadings={false} emptyMessage="No shifts recorded." />
        </Box.ItemContainer>
      )}
      {weekdays?.map(weekday => (
        <Box
          title={weekday?.name}
          actions={
            <HeaderActions
              period={supplier?.period}
              items={weekday.records}
              status={getGroupStatus(weekday.records)}
              total={getTotalHours(weekday.records)}
              updateStatus={updateStatus}
            />
          }
          small
          outlined
          monochrome
          expandible={{ expanded: false }}
          key={weekday?.name}
        >
          <Box.ItemContainer className={classes.table}>
            <Table.Container data={weekday.records} dark showHeadings={false}>
              <Table.Columns.TwoLines
                name="Engagement"
                lines={[d => <EngagementInfo engagement={d} />, d => (d?.parentClient ? `${d?.client} via ${d.parentClient}` : d?.client)]}
              />
              <Table.Columns.Default name="Start" width="90px" render={d => convertTimeTo12Hour(extractStartTime(d.date))} />
              <Table.Columns.Default name="End" width="90px" render={d => convertTimeTo12Hour(calculateShiftEnd(extractStartTime(d.date), d.duration))} />
              <Table.Columns.Default name="Total" width="90px" render={d => displayDuration(d?.duration)} />
              <Table.Columns.Bold name="Status" width="100px" render={d => trackingRecordStatusToLabel(d?.status)} />
              <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />;
              <Table.Columns.Actions
                showOnMobile
                actions={[
                  {
                    name: 'Approve',
                    onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: [d?.id] }),
                    confirmation: { messages: { confirmButton: 'Update' } },
                  },
                  {
                    name: 'Dispute',
                    onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: [d?.id] }),
                    confirmation: { messages: { confirmButton: 'Update' } },
                  },
                ]}
              />
            </Table.Container>
          </Box.ItemContainer>
        </Box>
      ))}
    </Box>
  );
};

export default Shifts;
