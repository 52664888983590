import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../components/dialog';
import Form from '../../../../../components/form';
import { TextField } from '../../../../../components/fields';

import useApi from '../../../../../hooks/use-api';
import { getProfile } from '../../../../../apis/profile';
import { setProfile } from '../../../../../state/identity/actions';

const UpdatePasswordForm = ({ isOpen = false, onClose }) => {
  const getProfileApi = useApi(getProfile, { skip: true });

  const submit = async () => {
    // TODO: call update password api.
    const profile = await getProfileApi.request();
    setProfile(profile);
    onClose();
  };

  const loading = getProfileApi.loading;

  return (
    <Dialog title="Update Password" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ currentPassword: '', newPassword: '', repeatedPassword: '' }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required('Current password is required'),
          newPassword: Yup.string().required('New password is required'),
          repeatedPassword: Yup.string()
            .required('Please rewrite your new password')
            .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.'),
        })}
        onSubmit={submit}
      >
        <TextField fill type="password" label="Current password" name="currentPassword" />
        <TextField fill type="password" label="New password" name="newPassword" />
        <TextField fill type="password" label="Re-type new password" name="repeatedPassword" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
