import React, { useState } from 'react';

import Avatar from '../../../../../../../components/avatar';
import Table from '../../../../../../../components/table';
import Dialog from '../../../../../../../components/dialog';
import { EmailDialog, useEmailDialog } from '../../../../../../../components/organizational/platform/email';

import { getFullName } from '../../../../../../../utilities/user';
import { roleIdToRoleName } from '../../../../../../../utilities/roles';

import useTable from '../../../../../../../hooks/use-table';
import * as MembersApis from '../../../../../../../apis/members';

import MemberFilters from './filters';
import MembersMenu from './menu';
import InviteMemberForm from './forms/invite-member';
import UpdateMemberForm from './forms/update-member';
import RemoveMemberForm from './forms/remove-member';

import classes from '../../details.module.scss';

const Staff = ({ supplierId }) => {
  const { items, loading, filters, initialFilters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    MembersApis.getMembers,
    { pageSize: 10, controlled: true },
    { organizationId: supplierId, roles: null },
  );
  const emailDialog = useEmailDialog({ recipients: 'members', organizationId: supplierId });

  const [dialog, setDialog] = useState({ open: false, type: undefined, title: undefined, data: undefined });
  const closeDialog = () => setDialog({ open: false, type: undefined, title: undefined, data: undefined });

  const handleResendInvite = async inviteId => {
    await MembersApis.resendMemberInvite({ inviteId });
    reload();
  };
  const handleCancelInvite = async inviteId => {
    await MembersApis.cancelMemberInvite({ inviteId });
    reload();
  };

  return (
    <div className={classes.tab}>
      <Table.Container data={items} loading={loading} dark>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<MembersMenu onInvite={() => setDialog({ open: true, type: 'invite', supplierId })} openEmailDialog={emailDialog.handleOpen} />}
          content={<MemberFilters />}
        />
        <Table.Columns.Default
          name="Name"
          showOnMobile
          width="30px"
          render={d => (
            <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={getFullName(d?.user)} url={d?.user?.avatar} showInviteIndicator={d?.isInvite} />
          )}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => getFullName(d?.user), d => roleIdToRoleName(d?.roleId)]} />
        <Table.Columns.Default name="Email" render={d => d?.user?.email} />
        <Table.Columns.Default name="Phone" render={d => d?.user?.phone || 'Not provided'} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            // User actions
            { name: 'View', href: d => `/users/${d?.user?.id}`, hidden: d => d?.isInvite },
            { name: 'Modify', onClick: d => setDialog({ open: true, type: 'modify', title: 'Modify supplier member', data: d }), hidden: d => d?.isInvite },
            { name: 'Remove', onClick: d => setDialog({ open: true, type: 'remove', title: 'Remove supplier member', data: d }), hidden: d => d?.isInvite },
            { divider: true, hidden: d => d?.isInvite },
            {
              name: 'Impersonate',
              disabled: d => !d?.user?.email,
              href: d => `/authentication/impersonate/${Buffer.from(d?.user?.email).toString('base64')}`,
              target: '_blank',
              hidden: d => d?.isInvite,
            },
            // Invite actions
            { name: 'Re-send', onClick: d => handleResendInvite(d?.id), hidden: d => !d?.isInvite },
            { divider: true, hidden: d => !d?.isInvite },
            { name: 'Cancel', onClick: d => handleCancelInvite(d?.id), confirmation: { messages: { confirmButton: 'Ok' } }, hidden: d => !d?.isInvite },
            ...emailDialog.getTableActions({ emailRenderer: d => d.user?.email }),
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <Dialog isOpen={!!dialog?.open} onClose={closeDialog} title={dialog?.member ? `Edit ${getFullName(dialog.member?.user)}` : 'Invite supplier member'}>
        {dialog?.type === 'invite' && (
          <InviteMemberForm
            organizationId={supplierId}
            onComplete={() => {
              reload();
              closeDialog();
            }}
          />
        )}
        {dialog?.type === 'modify' && (
          <UpdateMemberForm
            membership={dialog?.data}
            onComplete={() => {
              reload();
              closeDialog();
            }}
          />
        )}
        {dialog?.type === 'remove' && (
          <RemoveMemberForm
            organizationId={supplierId}
            membership={dialog?.data}
            onComplete={() => {
              reload();
              closeDialog();
            }}
          />
        )}
      </Dialog>
      <EmailDialog
        isMinimized={emailDialog.isMinimized}
        isOpen={emailDialog.isOpen}
        formValues={emailDialog.values}
        handleClose={emailDialog.handleClose}
        handleMinimize={emailDialog.handleMinimize}
      />
    </div>
  );
};

export default Staff;
