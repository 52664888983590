import { Button, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useState } from 'react';

import Dialog from '../../dialog';
import { Field } from '../../form';
import FileDropzone from './file-dropzone';

import styles from './file.module.scss';

const FileField = props => {
  const {
    classes,
    disabled,
    label,
    title = label,
    placeholder,
    description,
    name,
    validate,
    extensions,
    mimeTypes,
    maxSize = 100,
    submitOnChange = false,
    api,
    crop,
    onUploaded = async () => null,
    resultTextParser = r => r?.name || r?.FileName,
    resultValueParser = r => r,
    children,
    outline,
    showError = true,
    minimal,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  const elements = React.Children.map(children, child => React.cloneElement(child, { onClick: () => setOpen(true) }));

  const onDrop = async (file, form) => {
    const result = await api(file);
    const value = resultValueParser(result);
    form.setFieldValue(name, value);
    await onUploaded(result);
    if (submitOnChange) {
      form.submitForm();
    }
    setTimeout(() => setOpen(false), 300);
  };

  return (
    <Field
      classes={{ ...classes, wrapper: classNames(styles.file, classes) }}
      label={!!elements?.length ? null : label}
      description={!!elements?.length ? null : description}
      name={name}
      validate={validate}
      {...rest}
      outline={outline}
      showError={showError}
      minimal={minimal}
    >
      {({ form, field }) => {
        const text = !!field.value ? resultTextParser(field.value) : placeholder || 'Browse';
        const rightIcon = open ? <Spinner size={14} /> : null;
        const button = !!elements?.length ? (
          <React.Fragment>{elements}</React.Fragment>
        ) : (
          <Button className={styles.button} icon="paperclip" text={text} disabled={disabled} rightIcon={rightIcon} onClick={() => setOpen(true)} />
        );

        return (
          <React.Fragment>
            {button}
            <Dialog isOpen={open} title={title} onClose={() => setOpen(false)}>
              <FileDropzone crop={crop} maxSize={maxSize * 1000 * 1000} extensions={extensions} mimeTypes={mimeTypes} onDrop={file => onDrop(file, form)} />
            </Dialog>
          </React.Fragment>
        );
      }}
    </Field>
  );
};

export default FileField;
