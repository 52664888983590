import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../components/dialog';
import Form from '../../../../../components/form';
import Fields from '../../../../../components/fields';

import useApi from '../../../../../hooks/use-api';
import { getProfile, setEmergencyContact } from '../../../../../apis/profile';
import { setProfile } from '../../../../../state/identity/actions';

import classes from '../../profile.module.scss';

const EmergencyContactForm = ({ isOpen = false, onClose, user, setProfile }) => {
  const getProfileApi = useApi(getProfile, { skip: true });
  const setEmergencyContactApi = useApi(setEmergencyContact, { skip: true });

  const submit = async values => {
    await setEmergencyContactApi.request(values);
    const profile = await getProfileApi.request();
    setProfile(profile);
    onClose();
  };

  const loading = getProfileApi.loading || setEmergencyContactApi.loading;

  return (
    <Dialog title="Emergency contact" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ userId: user?.id, ...(user?.emergencyContact || {}) }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First name is required.'),
          lastName: Yup.string().required('Last name is required.'),
          relationship: Yup.string().required('Relationship is required.'),
          email: Yup.string().email('Not valid email address.').required('Email is required.'),
          phone: Yup.string().required('Phone number is required.'),
        })}
        onSubmit={submit}
      >
        <div className={classes.hRow}>
          <Fields.Text fill label="First Name" name="firstName" />
          <Fields.Text fill label="Last Name" name="lastName" />
        </div>
        <Fields.Text label="Relationship" name="relationship" />
        <Fields.Text label="Email" name="email" />
        <Fields.Text label="Phone" name="phone" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContactForm);
