import React, { useEffect, useRef, useState } from 'react';
import { ResizeSensor } from '@blueprintjs/core';
import GoogleMapReact from 'google-map-react';

import Spinner from '../../spinner';
import { uuid } from '../../../utilities/common';
import useApi from '../../../hooks/use-api';

import { getCurrentLocation } from './api';
import { fitMapBounds, formatViewportBounds, getBoundsRectangle, getMapSize } from './utilities';

import styles from './address.module.scss';

const Map = ({ address, mapId = uuid() }) => {
  const [map, setMap] = useState();
  const [height, setHeight] = useState(0);
  const addressBoundsRect = useRef();
  const location = useApi(getCurrentLocation);
  const center = address?.geometry?.location || location.response;

  const calculateHeight = () => {
    const properties = document.getElementById(mapId);
    setHeight(properties.offsetHeight >= 350 ? properties.offsetHeight : 350);
  };

  const updateMap = () => {
    if (address) {
      addressBoundsRect.current.setBounds(formatViewportBounds(address.geometry.viewport));
      const { center, zoom } = fitMapBounds(address.geometry.viewport, getMapSize(map));
      map.setCenter(center);
      map.setZoom(zoom - 1);
    }
  };

  useEffect(() => {
    calculateHeight();
  });

  useEffect(() => {
    if (map) {
      updateMap();
    }
  }, [map, address]);

  return (
    <ResizeSensor observeParents={true} onResize={calculateHeight}>
      <div id={mapId} className={styles.map} style={{ width: '100%', height: `${height}px` }}>
        {location.loading ? (
          <Spinner global={false} blend />
        ) : (
          <GoogleMapReact
            id="map"
            style={{ width: '100%', height: `${height}px` }}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultCenter={location.response}
            center={center}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals={true}
            options={{ gestureHandling: 'cooperative', disableDefaultUI: true }}
            onGoogleApiLoaded={options => {
              addressBoundsRect.current = getBoundsRectangle(options.map);
              setMap(options.map);
            }}
          />
        )}
      </div>
    </ResizeSensor>
  );
};

export default Map;
