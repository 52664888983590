import React, { useState } from 'react';
import moment from 'moment';
import { ResizeSensor, Popover } from '@blueprintjs/core';
import classNames from 'classnames';

import { getColor } from '../../../../../utilities/common';

import Comments from '../../../comments';
import Avatar from '../../../../avatar';

import { trackingRecordStatusToLabel } from '../../../../../domain/tracking-record-status-type';
import classes from '../../quick.module.scss';

const getDimensions = record => {
  const start = moment.duration(moment(record.date).format('HH:mm')).asMinutes();
  const duration = moment.duration(record.duration).asMinutes();
  const end = start + duration;

  const full = 24 * 60;
  const offset = (start * 100) / full;
  const width = (duration * 100) / full;

  return {
    full,
    start,
    end,
    duration,
    offset,
    width,
  };
};

const Shift = ({ record, engagements }) => {
  const [showBounds, setShowBounds] = useState(0);
  const { offset, width, duration } = getDimensions(record);
  const start = moment(record.date).format('h:mm A');
  const end = moment(record.date).add(record.duration).format('h:mm A');
  const span = `${start} - ${end}`;
  const engagement = engagements?.find(e => e.id === record?.engagementId);

  return (
    <div className={classes.shift} style={{ left: `${offset}%`, width: `${width}%` }}>
      <Popover
        content={
          <div className={classes.popover}>
            <div className={classes.meta}>
              <Avatar classOverrides={classes.avatar} abbreviate name={engagement?.clientName} url={engagement?.clientIcon} size={{ box: 60 }} />
              <div className={classes.text}>
                <div className={classes.title}>{engagement?.title}</div>
                <div className={classes.client}>{engagement?.clientName}</div>
              </div>
            </div>

            <div className={classes.status}>Status: {trackingRecordStatusToLabel(record?.status)}</div>
            <div className={classes.info}>{`${span}, ${(duration / 60).toFixed(2)} hours`}</div>
            {record.notes && <div className={classes.notes}>{record.notes}</div>}
            <Comments recordId={record?.id} />
          </div>
        }
      >
        <ResizeSensor onResize={e => setShowBounds(e?.[0]?.contentRect?.width >= 85)}>
          <div
            className={classes.target}
            style={engagement?.clientName && { backgroundColor: getColor(engagement?.clientName), color: getColor(engagement?.clientName) }}
          >
            {!!record?.comments?.length && <div className={classes.commentsIndicator} />}
          </div>
        </ResizeSensor>
      </Popover>
      {showBounds && (
        <div className={classes.span}>
          <div className={classes.start}>{start}</div>
          <div className={classes.end}>{end}</div>
        </div>
      )}
    </div>
  );
};

const Break = ({ record }) => {
  const { offset, width, duration } = getDimensions(record);
  const span = `${moment(record.date).format('h:mm A')} - ${moment(record.date).add(record.duration).format('h:mm A')}`;
  return (
    <div className={classNames(classes.shift, classes.break)} style={{ left: `${offset}%`, width: `${width}%` }}>
      <Popover
        content={
          <div className={classes.popover}>
            <div className={classes.project}>Break</div>
            <div className={classes.info}>
              {span}, {`${(duration / 60).toFixed(2)} hours`}
            </div>
          </div>
        }
      >
        <div className={classes.target} />
      </Popover>
    </div>
  );
};

const Empty = ({ record }) => {
  const { offset, width } = getDimensions(record);
  return <div className={classNames(classes.shift, classes.empty)} style={{ left: `${offset}%`, width: `${width}%` }} />;
};

const Segments = {
  Shift,
  Break,
  Empty,
};

export default Segments;
