import axios from 'axios';
import { isEmpty } from '../../../utilities/common';

const defaultPosition = { lat: 59.95, lng: 30.33 };

export const resolveLocation = async (query, precision) => {
  if (isEmpty(query)) {
    return;
  }

  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const { data } = await axios.get(apiUrl);
  const results = data.results;
  if (precision !== 'address') {
    return results;
  }

  return results.filter(
    address =>
      address.geometry.location_type === 'ROOFTOP' ||
      address.types.includes('street_address') ||
      address.types.includes('premise') ||
      address.types.includes('subpremise'),
  );
};

export const getCurrentLocation = () => {
  if (!navigator.geolocation) return Promise.resolve(defaultPosition);

  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        resolve(defaultPosition);
      },
    );
  });
};
