import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Dialog from '../../../../dialog';
import Table from '../../../../table';
import { ClassificationType } from '../../../../../domain/classification-type';

import { getAppliedConfiguration, removeRecord } from '../../../../../apis/configuration';
import useApi from '../../../../../hooks/use-api';

import WorkerClassificationForm from './worker-classification-form';
import { ClientConfigurationType } from '../enumerations';

import classes from '../policies.module.scss';
import WithConfirmation from '../../../../../hooks/with-confirmation';

const WorkerClassificationPolicy = ({ client, globalScope }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const getWorkerClassificationApi = useApi(
    () => getAppliedConfiguration({ organizationId: client?.id, type: ClientConfigurationType.SupplierClassificationPreference }),
    { skip: !client?.id },
  );
  const removeRecordApi = useApi(removeRecord, { skip: true });
  const close = () => setIsOpen(false);

  const record = getWorkerClassificationApi.response?.[0];

  const handleRemove = async () => {
    await removeRecordApi.request(record?.recordId);
    getWorkerClassificationApi.setResponse([]);
  };

  return (
    <Box
      title="Supplier classification"
      outlined
      monochrome
      actions={
        <ButtonGroup>
          <WithConfirmation
            captureDismiss={false}
            messages={{
              question: `Are you sure you want to remove worker classification configuration record?`,
            }}
          >
            <Button
              small
              outlined
              loading={removeRecordApi.loading}
              icon={<Icons.Trash size={16} strokeWidth={1.5} />}
              title="Delete worker classification"
              disabled={!record}
              onClick={handleRemove}
            />
          </WithConfirmation>
          <Button
            small
            outlined
            icon={<Icons.Settings size={16} strokeWidth={1.5} />}
            title="Setup supplier classification preferences"
            disabled={!globalScope}
            onClick={() => setIsOpen(true)}
          />
        </ButtonGroup>
      }
      loading={getWorkerClassificationApi.loading}
    >
      <Box.ItemContainer>
        <span>
          Each time your company invites a supplier, you can indicate a preference for that supplier to be evaluated as an employee or independent contractor.
          If you do not want this flexibility, because your program only includes one type of classification, please set your preferences.
        </span>
      </Box.ItemContainer>
      <Box.ItemContainer className={classes.table}>
        <Table.Container
          data={record ? [record] : [{}]}
          loading={getWorkerClassificationApi.loading}
          showHeadings={false}
          dark
          emptyMessage="No independent contractor insurance configured."
        >
          <Table.Columns.Default
            render={d => {
              if (d.data?.classification === ClassificationType.Employee) {
                return (
                  <span>
                    Your company requires that all suppliers <strong>be employed</strong> on your behalf.
                  </span>
                );
              } else if (d.data?.classification === ClassificationType.IndependentContractor) {
                return (
                  <span>
                    Your company requires that all suppliers <strong>be engaged as independent contractors</strong>.
                  </span>
                );
              }
              return (
                <span>
                  Your company requires that all suppliers <strong>be engaged</strong> regardless of the classification.
                </span>
              );
            }}
          />
        </Table.Container>
      </Box.ItemContainer>
      <Dialog isOpen={isOpen} title="Supplier classification preferences" onClose={close}>
        {isOpen && (
          <WorkerClassificationForm
            scopeId={globalScope?.id}
            record={record}
            onSave={() => {
              void getWorkerClassificationApi.request();
              close();
            }}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default WorkerClassificationPolicy;
