import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import classes from './link.module.scss';

const NavigationLink = ({ link, isMobile, onClick }) => {
  if (!link) {
    return isMobile ? null : <div className={classes.space} />;
  }

  const clickProps = isMobile ? { onClick } : {};

  return (
    <NavLink
      to={link.to}
      {...clickProps}
      className={({ isActive, isPending }) => classNames(isMobile ? classes.mobileLink : classes.link, isActive && classes.active)}
    >
      <div className={classes.icon}>
        <link.icon size={17} strokeWidth={2} />
      </div>
      <div className={classes.title}>{link.title}</div>
    </NavLink>
  );
};

export default NavigationLink;
