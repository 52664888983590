import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Toaster } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import Page from '../../../components/page';
import Table from '../../../components/table';
import Avatar from '../../../components/avatar';
import useTable from '../../../hooks/use-table';
import { capitalize } from '../../../utilities/common';
import { downloadStringAsFile } from '../../../utilities/files';
import { abbreviateClassification } from '../../../domain/classification-type';

import { getPayrollGroups, removePayrollGroup, getCsvData, runBulkPayrollProcessing, processPayrolls } from '../../../apis/platform/payroll';
import toaster from '../../../components/toaster';

import PayrollDetails from './details';
import PayrollMenu from './menu';
import PayrollFilters from './filters';
import GeneratePayrollDialog from './generate';

const Payrolls = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialog, setDialog] = useState({ open: false });
  const [generateDialog, setGenerateDialog] = useState({ open: false });

  const defaultFilters = { periodType: 'weekly', currencyId: undefined, classification: undefined };
  const { items, loading, filters, initialFilters, reload, setFilters, resetFilters, pageCount, page, goToPage } = useTable(
    getPayrollGroups,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const onRemovePayrollGroup = async data => {
    await removePayrollGroup({
      period: data?.period,
      periodType: data?.periodType,
      classification: data?.classification,
      currencyId: data?.currency?.id,
    });
    reload();
  };

  const onPay = async payrolls => {
    await processPayrolls({ payrolls });
    Toaster.create({ position: 'top' }).show({ message: 'Payroll data sent to provider!', intent: 'success' });
  };

  const renderPeriod = d =>
    d?.periodType === 'weekly'
      ? `Week ${moment(d?.period).format('Do MMMM')} - ${moment(d?.period).add(6, 'days').format('Do MMMM YYYY')}`
      : `${moment(d?.period).format('MMMM YYYY')}`;

  const onExport = data => {
    void toaster.export({
      progress: {
        title: 'Exporting payroll',
      },
      promise: () =>
        getCsvData({
          period: data?.period,
          periodType: data?.periodType,
          classification: data?.classification,
          currencyId: data?.currency?.id,
        }),
      onSuccess: csv =>
        downloadStringAsFile(csv, `${renderPeriod(data).split(' ').join('_')}_${abbreviateClassification(data?.classification)}_${data?.currency?.id}`, 'csv'),
    });
  };

  const onRunBulk = async () => {
    await runBulkPayrollProcessing();
    Toaster.create({ position: 'top' }).show({ message: 'Started updating payment reports.', intent: 'success' });
  };

  const payrollGroup = searchParams.get('id');
  useEffect(() => {
    if (payrollGroup) {
      const groupStr = Buffer.from(payrollGroup, 'base64').toString('utf8');
      const [period, periodType, classification, currencyId] = JSON.parse(groupStr);
      // validate group data that is required to find payroll group that will be displayed in the opened dialog
      if (period && periodType && classification && currencyId) {
        const payrollGroup = items?.find?.(
          i => i?.period === period && i?.periodType === periodType && i?.classification === classification && i?.currency?.id === currencyId,
        );
        setDialog({ open: !!payrollGroup, group: payrollGroup });
      }
    } else {
      setDialog({ open: false });
    }
  }, [payrollGroup, items]);

  return (
    <Page title="Payrolls" path={[{ name: 'Payrolls', to: '/payrolls' }]}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          content={<PayrollFilters />}
          menu={
            <PayrollMenu
              onGeneratePayroll={() => setGenerateDialog({ open: true })}
              onRunBulk={onRunBulk}
              onComplete={() => setGenerateDialog({ open: false })}
            />
          }
        />
        <Table.Columns.Default
          name="Period"
          showOnMobile
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} name={abbreviateClassification(d?.classification)} />}
        />
        <Table.Columns.Default width="2fr" showOnMobile render={d => renderPeriod(d)} />
        <Table.Columns.Default name="Type" render={d => capitalize(d?.periodType)} />
        <Table.Columns.Default name="Currency" render={d => d?.currency?.name} />
        <Table.Columns.Bold name="Total" render={d => `${d?.currency?.symbol}${Number.parseFloat(`${d?.total || 0}`).toFixed(2)}`} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            {
              name: 'View',
              onClick: d => {
                const groupParts = [d?.period, d?.periodType, d?.classification, d?.currency?.id];
                const id = Buffer.from(JSON.stringify(groupParts)).toString('base64');
                searchParams.set('id', id);
                setSearchParams(searchParams);
              },
            },
            { name: 'Pay', onClick: d => onPay(d?.payrolls?.map?.(p => p?.payrollId)) },
            { divider: true },
            { name: 'Export', onClick: d => onExport(d) },
            { divider: true },
            { name: 'Remove', onClick: d => onRemovePayrollGroup(d), confirmation: { messages: { confirmButton: 'Remove' } } },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <PayrollDetails
        {...dialog}
        payrollGroup={dialog?.group}
        reloadList={() => reload()}
        onExport={() => onExport(dialog?.group)}
        onPay={onPay}
        onClose={() => {
          searchParams.delete('id');
          setSearchParams(searchParams);
        }}
      />
      <GeneratePayrollDialog {...generateDialog} onClose={() => setGenerateDialog({ open: false })} />
    </Page>
  );
};

export default Payrolls;
