import React from 'react';
import classNames from 'classnames';
import { Popover } from '@blueprintjs/core';

import { calculateStepState, getSignaturesByMembership } from './flow-utilities';

import FlowStepDocumentSignatures from './flow-step-document-signatures';

import classes from './flow-status.module.scss';
import SimpleBar from 'simplebar-react';

const FlowStepIndicator = ({ step, eligibleSignatories, membership, user, setDialog }) => {
  const state = calculateStepState(step);
  const documents = getSignaturesByMembership({ step, eligibleSignatories, membership, user });
  const showSignIndicator = documents?.some(s => s?.some(s => s.showSignButton));
  const handleSignClick = target => setDialog({ open: true, data: { step, target } });

  return (
    <Popover
      className={classes.popover}
      content={
        <div className={classes.stepPopover}>
          <div className={classes.title}>{step?.name}</div>
          <div className={classes.description}>{step?.description}</div>
          <div className={classNames(classes.status, state.progress.class)}>{state.progress.message}</div>
          {!!step?.aux?.length && (
            <SimpleBar className={classes.signaturesWrapper}>
              <div className={classes.signDocuments}>
                <div className={classes.info}>
                  Following documents require signature from multiple parties, below is the list of documents and outstanding/required signatures.
                </div>
                <div className={classes.targets}>
                  {documents?.map?.((document, i) => (
                    <FlowStepDocumentSignatures key={i} signatories={document} handleSignClick={handleSignClick} />
                  ))}
                </div>
              </div>
            </SimpleBar>
          )}
          {!!state.review && <div className={classNames(classes.status, state.review.class)}>{state.review.message}</div>}
        </div>
      }
    >
      <div className={classNames(classes.step, state.progress.class, showSignIndicator && classes.awaitingSignature)}>
        {!!state.review && <div className={classNames(classes.review, state.review.class)} />}
      </div>
    </Popover>
  );
};

export default FlowStepIndicator;
