import React from 'react';
import cn from 'classnames';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { Field } from '../../form';

import styles from './phone-field.module.scss';
import 'react-phone-number-input/style.css';

const isPhoneNumberValid = value => (value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required');

const PhoneField = ({
  classes,
  name,
  validate = isPhoneNumberValid,
  international = true,
  initialValueFormat,
  defaultCountry,
  disabled,
  label,
  placeholder,
  description,
}) => (
  <Field classes={classes} name={name} label={label} description={description} validate={validate}>
    {({ field, form }) => {
      const handleChange = value => {
        !form.touched[name] && form.setFieldTouched(name, true);

        if (!value) {
          form.setFieldValue(name, undefined);
          return;
        }

        form.setFieldValue(name, value);
      };

      return (
        <PhoneInput
          className={cn('bp5-input', styles.phoneField)}
          numberInputProps={{
            className: cn('bp5-input', classes?.input),
          }}
          disabled={disabled}
          defaultCountry={defaultCountry}
          initialValueFormat={initialValueFormat}
          international={international}
          placeholder={placeholder}
          value={field.value}
          onChange={handleChange}
        />
      );
    }}
  </Field>
);
export default PhoneField;
