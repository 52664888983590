import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Popover } from '@blueprintjs/core';

import { changeTheme } from '../../../../state/application';

import classes from './theme-switcher.module.scss';
import classNames from 'classnames';

const ThemeSwitcher = ({ themes, theme, changeTheme }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!themes || themes?.length < 2) {
    return null;
  }

  return (
    <div className={classes.themeSwitcher}>
      <Popover
        isOpen={isOpen}
        popoverClassName={classes.popover}
        minimal
        placement="right"
        content={
          <div className={classes.themes}>
            {themes
              ?.filter?.(t => t?.id !== theme?.id)
              ?.map?.(t => (
                <div
                  key={t?.id}
                  className={classes.theme}
                  style={!!t?.primaryColor ? { backgroundColor: t?.primaryColor } : {}}
                  onClick={() => {
                    changeTheme(t);
                    setIsOpen(false);
                  }}
                />
              ))}
          </div>
        }
      >
        <div
          className={classNames(classes.button, !!isOpen && classes.active)}
          style={!!theme?.primaryColor ? { color: theme?.primaryColor } : {}}
          onClick={() => setIsOpen(!isOpen)}
          title="Select theme"
        >
          <Icon icon="style" />
        </div>
      </Popover>
    </div>
  );
};

const mapStateToProps = state => ({
  theme: state.application.theme,
  themes: state.application.themes,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeTheme,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitcher);
