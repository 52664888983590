import React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import Spinner from '../components/spinner';

import { ai } from './service';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js'
 * has a HOC withAITracking that requires this  * to be a
 * Class Component rather than a Functional Component
 */
class TelemetryProvider extends React.Component {
  state = { initialized: false };

  componentDidMount() {
    const { initialized } = this.state;
    const key = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;
    const env = process.env.NODE_ENV;

    if (env === 'production' && !initialized && !!key) {
      ai.initialize(key);
      this.setState({ initialized: true });
    }
  }

  render() {
    const env = process.env.NODE_ENV;
    if (env === 'production' && !this.state.initialized) {
      return <Spinner global text="Initializing telemetry..." />;
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
