import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../../../components/dialog';
import Form from '../../../../../../../../components/form';
import Fields from '../../../../../../../../components/fields';

import useApi from '../../../../../../../../hooks/use-api';
import { setUserAddress } from '../../../../../../../../apis/profile';

const AddressForm = ({ isOpen = false, onClose, user, onSave }) => {
  const setUserAddressApi = useApi(setUserAddress, { skip: true });

  const submit = async ({ address }, { setFieldError }) => {
    if (!address?.place_id) {
      setFieldError('address', 'Unable to resolve requested location.');
      return;
    }

    await setUserAddressApi.request(user.id, address.place_id);
    onSave();
  };

  const loading = setUserAddressApi.loading;
  const address = user?.personalDetails?.address ? JSON.parse(user?.personalDetails?.address?.json) : null;
  return (
    <Dialog title="Address" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ address }} onSubmit={submit}>
        <Fields.Address fill name="address" label="Address" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default AddressForm;
