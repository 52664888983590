import React from 'react';
import cn from 'classnames';

import classes from './preview-content-item.module.scss';
import { getContentItemStyle } from '../../../helpers/get-content-item-style';

const PreviewTextContentItem = ({ contentItem, hovered }) => {
  const style = { ...contentItem.styleConfig, ...getContentItemStyle(contentItem.customStyle) };
  return (
    <div className={cn(classes.previewTextContentItem, contentItem.class, hovered === contentItem.id && classes.hovered)} style={style}>
      {contentItem.text}
    </div>
  );
};

export default PreviewTextContentItem;
