import React from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';

import WithConfirmation from '../../../../../../../hooks/with-confirmation';

import classes from './field.module.scss';

const FieldMenu = ({ field, path, dispatch }) => {
  const removeContent = () => {
    dispatch({
      type: 'EMAIL_CONFIGURATOR_REMOVE_CONTENT',
      data: { path },
    });
  };

  const selectContent = () => {
    dispatch({
      type: 'EMAIL_CONFIGURATOR_SET_SELECTION',
      data: { path, contentItem: field },
    });
  };

  return (
    <Popover
      interactionKind="click"
      content={
        <Menu>
          <MenuItem text="Properties" icon="settings" onClick={selectContent} />
          <MenuDivider />
          <WithConfirmation
            messages={{
              question: `Are you sure you want to remove "${field?.text}" field ? All visibility conditions that are based on this field will also be removed.`,
            }}
            icon={true}
          >
            <MenuItem text="Remove" icon="cross" onClick={removeContent} shouldDismissPopover={false} />
          </WithConfirmation>
        </Menu>
      }
    >
      <Button className={classes.actions} minimal icon="more" />
    </Popover>
  );
};

export default FieldMenu;
