import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';

import classes from './sortable.module.scss';

const Handle = SortableHandle(() => (
  <span>
    <Icon className={classNames(classes.dragHandle)} icon="drag-handle-vertical" />
  </span>
));

export default Handle;
