import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../components/dialog';
import Form from '../../../../../components/form';
import Fields from '../../../../../components/fields';

import useApi from '../../../../../hooks/use-api';
import { getProfile, setUserAddress } from '../../../../../apis/profile';
import { setProfile } from '../../../../../state/identity/actions';

const AddressForm = ({ isOpen = false, onClose, user, setProfile }) => {
  const setUserAddressApi = useApi(setUserAddress, { skip: true });
  const getProfileApi = useApi(getProfile, { skip: true });

  const submit = async ({ address }, { setFieldError }) => {
    if (!address?.place_id) {
      setFieldError('address', 'Unable to resolve requested location.');
      return;
    }

    await setUserAddressApi.request(user.id, address.place_id);
    const profile = await getProfileApi.request();
    setProfile(profile);
    onClose();
  };

  const loading = setUserAddressApi.loading || getProfileApi.loading;
  const address = user?.personalDetails?.address ? JSON.parse(user?.personalDetails?.address?.json) : null;
  return (
    <Dialog title="Address" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ address }} onSubmit={submit}>
        <Fields.Address fill name="address" label="Address" precision="address" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
