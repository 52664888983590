import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useApi from '../../hooks/use-api';
import { getFlowStatus } from '../../apis/wizards';

import { FlowLoading } from './flow-utilities';
import FlowStepIndicator from './flow-step-indicator';
import SignDocument from './sign-document';

import classes from './flow-status.module.scss';

const FlowStatus = ({ membership, user, flowType, referenceId, loading, align = 'left', stepCount = 5, signatureContextProps = {} }) => {
  const [dialog, setDialog] = useState({ open: false });
  const getFlowStatusApi = useApi(getFlowStatus, { skip: true });

  useEffect(() => {
    if (!!flowType && referenceId) {
      getFlowStatusApi.request({ flowType, referenceId });
    }
  }, [flowType, referenceId]);

  if (getFlowStatusApi?.loading && !getFlowStatusApi?.response) {
    return <FlowLoading align={align} stepCount={stepCount} />;
  }

  const flow = getFlowStatusApi?.response;

  if (loading || !flowType || !referenceId) {
    return <FlowLoading align={align} />;
  }

  if (!flow?.steps?.length) {
    return null;
  }

  const signatures = flow?.steps
    ?.find(step => step.contextType === dialog.data?.step?.contextType)
    ?.aux?.find(signatures => signatures[0].target === dialog.data?.target);

  return (
    <div className={classes.flow} style={{ justifyContent: align }}>
      <SignDocument
        signatures={signatures}
        contextProps={signatureContextProps}
        dialog={dialog}
        eligibleSignatories={flow?.eligibleSignatories}
        membership={membership}
        user={user}
        setDialog={setDialog}
        onClose={() => setDialog({ open: false })}
        onSuccess={() => void getFlowStatusApi.request({ flowType, referenceId })}
      />
      {flow?.steps?.map?.((s, i) => (
        <FlowStepIndicator key={i} step={s} eligibleSignatories={flow?.eligibleSignatories} membership={membership} user={user} setDialog={setDialog} />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  membership: state.identity.membership,
  user: state.identity.user,
});

export default connect(mapStateToProps)(FlowStatus);
