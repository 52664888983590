import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../components/dialog';
import Form from '../../../../../components/form';
import BankAccountField from '../../../../../components/fields/bank-account/bank-account';

import useApi from '../../../../../hooks/use-api';
import { createBankAccount, updateBankAccount } from '../../../../../apis/banking';

const BankingForm = ({ isOpen = false, bank, owner, countryId, readOnly, onClose, onSubmit }) => {
  const createOrUpdateApi = useApi(values => (values.id ? updateBankAccount(values.id, values) : createBankAccount(values)), { skip: true });

  const submit = async values => {
    await createOrUpdateApi.request(values);
    onSubmit();
  };

  return (
    <Dialog title="Bank account" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          countryId,
          ...bank,
          ...owner,
        }}
        enableReinitialize
        onSubmit={submit}
      >
        <BankAccountField countryId={countryId} disabled={readOnly} />
        <Button type="submit" fill outlined text="Submit" loading={createOrUpdateApi.loading} />
      </Form>
    </Dialog>
  );
};

export default BankingForm;
