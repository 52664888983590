import React from 'react';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';
import useApi from '../../../../../../hooks/use-api';

import { inviteMember } from '../../../../../../apis/members';

const InviteMemberForm = ({ onComplete }) => {
  const inviteMemberApi = useApi(inviteMember, { skip: true });

  const submit = async values => {
    await inviteMemberApi.request(values);
    onComplete();
  };

  const getRoleOptions = () => [
    { label: 'Administrator', value: 'platform-administrator' },
    { label: 'Manager', value: 'platform-manager' },
    { label: 'Evaluation Manager', value: 'platform-evaluation-manager' },
  ];

  return (
    <Form
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
        organizationId: 'P-greenlight',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        email: Yup.string().email('Not valid email address.').required('Email is required.'),
        roleId: Yup.string().required('Role is required.'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      <React.Fragment>
        <Fields.Text label="First Name" name="firstName" />
        <Fields.Text label="Last Name" name="lastName" />
        <Fields.Text label="Email" name="email" />
        <Fields.Select label="Role" name="roleId" options={getRoleOptions()} />
        <Button type="submit" outlined fill text="Submit" disabled={inviteMemberApi.loading} loading={inviteMemberApi.loading} />
      </React.Fragment>
    </Form>
  );
};

export default InviteMemberForm;
