import { FIELDS_TO_SKIP } from '../../utilities/filter';

export const getDefaultItems = ({ pageSize }) => new Array(pageSize).fill({});

/**
 * this function creates the filters object by combining the initial filters and the search params
 * if the search params has the same key as the initial filters, the value from the search params will be used
 * otherwise the value from the initial filters will be used
 * @param {boolean} controlled - The controlled flag.
 * @param {Object} initialFilters - The initial filters object - the default filters passed to the useTable hook.
 * @param {Object} searchParams - The search params object.
 */
export const getFilters = ({ initialFilters, searchParams }) => {
  const keys = [...searchParams.keys()];

  const filters = keys.reduce((_filters, key) => {
    if (FIELDS_TO_SKIP.includes(key)) {
      return _filters;
    }
    const value = searchParams.getAll(key);
    if (value.length > 1) {
      _filters[key] = value;
    } else {
      _filters[key] = value[0];
    }

    return _filters;
  }, {});

  // assign initial filters to filters if they are not set
  Object.keys(initialFilters).forEach(key => {
    if (!filters[key]) {
      filters[key] = initialFilters[key];
    }
  });

  return filters;
};

/**
 * this function forms the search params object by combining the page, filters, initial filters and order by
 * in a way that value that are is in the initialFilters object should not be included in the search params
 * if they are not set in the filters object, or it is the same as the initial filters
 * @param {number} page - The page number.
 * @param {Object} filters - The filters object - merge of initial filters and search params.
 * @param {Object} initialFilters - The initial filters object - the default filters passed to the useTable hook.
 * @param {Object} orderBy - The order by object.
 */
export const formSearchParams = ({ page, filters, initialFilters, orderBy }) => {
  const currentSearchParams = new URLSearchParams(document.location.search);
  const tab = currentSearchParams.get('t');
  const urlSearchParams = new URLSearchParams();
  if (tab) {
    urlSearchParams.set('t', tab);
  }

  Object.entries(filters).forEach(([key, value]) => {
    const initialValue = initialFilters[key];
    if (value && value !== initialValue) {
      // if value is an array, append each value to the search params (range filter)
      if (Array.isArray(value)) {
        urlSearchParams.delete(key); // remove the key if it exists
        value.forEach(v => urlSearchParams.append(key, v));
      } else {
        urlSearchParams.set(key, value);
      }
    } else {
      urlSearchParams.delete(key);
    }
  });

  if (page) {
    urlSearchParams.set('page', page);
  }

  return urlSearchParams;
};

export const getPage = ({ searchParams }) => {
  const page = searchParams.get('page') || 0;
  const potentialPage = Number(parseInt(page, 10));
  if (Number.isNaN(potentialPage)) {
    return 0;
  }
  return Math.max(potentialPage, 0);
};

export const compareChanges = (prev, next) => {
  const prevKeys = Object.keys(prev);
  const nextKeys = Object.keys(next);
  if (prevKeys.length !== nextKeys.length) {
    return true;
  }

  return prevKeys.some(key => {
    if (Array.isArray(prev[key])) {
      return prev[key]?.length !== next[key]?.length || prev[key]?.some((value, index) => value !== next[key]?.[index]);
    }
    return prev[key] !== next[key];
  });
};
