import React from 'react';
import cn from 'classnames';

import { getContentItemStyle } from '../../../helpers/get-content-item-style';
import classes from './preview-content-item.module.scss';

const IMAGE_PLACEHOLDER = 'https://png.pngtree.com/png-vector/20210604/ourmid/pngtree-gray-network-placeholder-png-image_3416659.jpg';

const PreviewImageContentItem = ({ children, contentItem, hovered }) => {
  const style = { ...contentItem.styleConfig, ...getContentItemStyle(contentItem.customStyle) };
  return (
    <div className={cn(classes.previewImageContentItem, contentItem.class, hovered === contentItem.id && classes.hovered)} style={style}>
      <img src={contentItem.value || IMAGE_PLACEHOLDER} alt={contentItem.text} width="100%" height="100%" />
      {children}
    </div>
  );
};

export default PreviewImageContentItem;
