import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import classes from './block.module.scss';

const BlockLoading = () => (
  <div className={classNames(classes.block, Classes.SKELETON)}>
    <div className={classes.header}>
      <div>block</div>
    </div>
  </div>
);

export default BlockLoading;
