import React, { useRef } from 'react';

import debounce from 'lodash/debounce';

import { Field } from '../../form';

import Textarea from './textarea';

const TextareaField = ({
  classes,
  name,
  type = 'text',
  label,
  placeholder,
  description,
  disabled,
  maskConfig,
  validate,
  submitOnChange = false,
  extra,
  outline,
  minimal,
  showError,
}) => {
  const submitFormIfNeeded = useRef(
    debounce(form => {
      if (!!submitOnChange) {
        form.submitForm();
      }
    }, 150),
  );

  return (
    <Field classes={classes} name={name} label={label} description={description} validate={validate} outline={outline} minimal={minimal} showError={showError}>
      {({ field, form }) => (
        <Textarea
          disabled={disabled}
          classes={classes}
          name={name}
          placeholder={placeholder}
          value={field.value === undefined ? '' : field.value}
          type={type}
          maskConfig={maskConfig}
          extra={extra}
          onChange={value => {
            form.setFieldValue(name, value);
            submitFormIfNeeded.current(form);
          }}
        />
      )}
    </Field>
  );
};
export default TextareaField;
