import React from 'react';
import moment from 'moment';

import { SignatoriesRolesMapper } from '../../models/enumerations/roles';

import classes from './flow-status.module.scss';

export const FlowLoading = ({ align, stepCount }) => (
  <div className={classes.flow} style={{ justifyContent: align }}>
    <div className={classes.loading}>
      {[...Array(stepCount)]?.map((_, i) => (
        <div key={i} className={classes.step} />
      ))}
    </div>
  </div>
);

export const FlowStepState = {
  Pending: 'pending',
  InProgress: 'inProgress',
  Stale: 'stale',
  Complete: 'complete',
};

export const FlowStepReviewState = {
  Pending: 'pending',
  InProgress: 'inProgress',
  Complete: 'complete',
  Rejected: 'rejected',
};

/**
 * Calculate flow step progress status and message
 */
const calculateStepProgressState = step => {
  if (step?.isComplete) {
    return {
      status: FlowStepState.Complete,
      message: step?.updatedAt ? `Completed on ${moment(step?.updatedAt).format('Do MMMM, YYYY, h:mm a')}.` : 'Not yet complete.',
      class: step?.review?.result === false ? classes.rejected : classes.complete,
    };
  }

  if (!!step?.createdAt) {
    // if there is no aux data, then no one is needed to sign
    const everyoneSigned = !step?.aux || step?.aux?.every(signatories => !signatories || signatories?.every(signatory => !!signatory.signatureId));
    if (!everyoneSigned) {
      return {
        status: FlowStepState.InProgress,
        message: `Awaiting signatures...`,
        class: classes.inProgress,
      };
    }

    const inProgressDuration = moment.duration(moment().diff(moment(step?.createdAt)), 'milliseconds').asDays();
    const takesTooLong = inProgressDuration > 1;
    return takesTooLong
      ? {
          status: FlowStepState.Stale,
          message: `Started on ${moment(step?.createdAt).format('Do MMMM, YYYY, h:mm a')}. (${moment(step?.createdAt).fromNow()})`,
          class: classes.stale,
        }
      : {
          status: FlowStepState.InProgress,
          message: `Started on ${moment(step?.createdAt).format('Do MMMM, YYYY, h:mm a')}.`,
          class: classes.inProgress,
        };
  }

  return { status: FlowStepState.Pending, message: 'Not yet started.', class: `` };
};

/**
 * Calculate flow step review status and message
 */
const calculateStepReviewState = step => {
  if (!step?.review?.isRequired) {
    return undefined;
  }

  if (step?.review?.isComplete) {
    return {
      status: FlowStepReviewState.Complete,
      message: !!step?.review?.elaboration ? (
        <React.Fragment>
          {`Review complete on ${moment(step?.completedOn).format('Do MMMM, YYYY, h:mm a')}.`}
          <br />
          <br />
          <i>"{step?.review?.elaboration}"</i>
        </React.Fragment>
      ) : (
        `Review complete on ${moment(step?.completedOn).format('Do MMMM, YYYY, h:mm a')}.`
      ),
      class: step?.review?.result === false ? classes.rejected : classes.complete,
    };
  }

  if (step?.isComplete) {
    return {
      status: FlowStepReviewState.InProgress,
      message: `Review in progress.`,
      class: classes.inProgress,
    };
  }

  return undefined;
};

export const calculateStepState = step => {
  return {
    progress: calculateStepProgressState(step),
    review: calculateStepReviewState(step),
  };
};

export const getSignaturesByMembership = ({ step, eligibleSignatories, membership, user }) => {
  // if step does not have aux data then return undefined as there is no document to sign
  if (!step.aux) {
    return undefined;
  }

  if (!eligibleToSign({ eligibleSignatories, roleId: membership.roleId, user, organization: membership.organization })) {
    // user is not eligible to sign any document in the flow
    return undefined;
  }

  const pendingSignatures = [];
  step.aux.forEach(signatures => {
    const notSigned = signatures?.filter(s => !s.signatureId);
    const roleCanSign = notSigned?.filter(s => eligibleToSign({ eligibleSignatories, roleId: s.roleId, user, organization: membership.organization }));

    if (roleCanSign?.length) {
      const orders = roleCanSign.map(s => SignatoriesRolesMapper[s.roleId].order);
      const lowestOrder = Math.min(...orders);
      // check is user's turn to sign
      const isUserTurn = notSigned.every(s => SignatoriesRolesMapper[s.roleId].order >= lowestOrder);
      if (isUserTurn) {
        pendingSignatures.push({
          target: roleCanSign[0].target,
          roles: roleCanSign.map(s => s.roleId),
        });
      }
    }
  });

  const documents = step.aux
    .filter(d => d?.length > 1)
    .map(signatures => {
      const signatory = signatures?.[0];
      const currentDocument = pendingSignatures?.find(p => p.target === signatory?.target);
      const formatted = signatures.map(s => {
        return {
          ...s,
          showSignButton: currentDocument?.roles.includes(s.roleId),
          roles: currentDocument?.roles,
        };
      });
      formatted.sort((a, b) => SignatoriesRolesMapper[a.roleId].order - SignatoriesRolesMapper[b.roleId].order);
      return formatted;
    });

  return documents;
};

export const eligibleToSign = ({ eligibleSignatories, roleId, user, organization }) => {
  const role = SignatoriesRolesMapper[roleId];
  const rules = eligibleSignatories?.[role.key];
  const checkUser = rules?.users.includes(user.id);
  const checkRole = rules?.roles.includes(roleId);
  const checkOrganization = rules?.organizations.includes(organization.id);

  return checkUser && checkRole && checkOrganization;
};
