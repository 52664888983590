import React from 'react';
import moment from 'moment';

import Avatar from '../../../../../components/avatar';
import Box from '../../../../../components/box';
import Table from '../../../../../components/table';
import FlowStatus from '../../../../../components/flow-status';

import useTable from '../../../../../hooks/use-table';
import { getEngagementUpdates } from '../../../../../apis/client/engagements';

import classes from '../../dashboard.module.scss';

const UpdatesBox = () => {
  const { items, loading, pageCount, page, goToPage } = useTable(getEngagementUpdates, { pageSize: 5 });

  return (
    <Box
      title={'Update requests'}
      className={classes.updatesBox}
      contentClassName={classes.onboarding}
      footerClassName={classes.footer}
      footer={<Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />}
    >
      <Table.Container data={items} loading={loading} handleScroll={false} showHeadings={false} dark emptyMessage="No updates requested :)">
        <Table.Columns.Default
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d?.supplierName || d?.supplierManagerName} url={d?.supplierIcon} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName || d?.supplierManagerName, d => d?.supplierManagerEmail]} />
        <Table.Columns.TwoLines showOnMobile lines={[d => <small>{moment(d?.updateDate).format('MMM Do, YYYY')}</small>, d => d?.engagementTitle]} />
        <Table.Columns.Default
          width="35px"
          render={d => <FlowStatus flowType="engagement-updates" reference={d} referenceId={d?.engagementId} loading={loading} align="right" stepCount={2} />}
        />
        <Table.Columns.Actions showOnMobile actions={[{ name: 'View engagement', href: d => `/engagements/${d.engagementId}` }]} />
      </Table.Container>
    </Box>
  );
};

export default UpdatesBox;
