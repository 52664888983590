import React from 'react';

import Browser from './browser';
import WizardProvider from './state';
import Toolbox from './toolbox';
import classes from './wizards-configurator.module.scss';

const Wizard = ({ flowType, referenceId, templateId, recordId, instanceId, data = {}, isDesigner = false, onCancel = undefined, onComplete = undefined }) => {
  return (
    <WizardProvider {...{ templateId, instanceId, flowType, referenceId, data, isDesigner, onCancel, onComplete }}>
      <div className={classes.wizard}>
        <Toolbox recordId={recordId} />
        <Browser />
      </div>
    </WizardProvider>
  );
};

export default Wizard;
