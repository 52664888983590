import { EngagementLocationType } from '../domain/engagement-location-type';

export const getLocationTypeName = locationType => {
  switch (locationType) {
    case EngagementLocationType.OffSite:
      return 'Remote';

    case EngagementLocationType.OnSite:
      return 'On Site';

    default:
      return 'Not provided';
  }
};

export const extractCountryFromAddress = address => {
  if (!address?.json) {
    return undefined;
  }

  try {
    const obj = JSON.parse(address.json);
    return obj?.address_components?.find(c => c.types.includes('country'))?.short_name;
  } catch (e) {
    return undefined;
  }
};
