import React from 'react';
import { Link } from 'react-router-dom';
import { Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import Avatar from '../../avatar';
import useWindowSize from '../../../hooks/use-window-size';
import { getFullName } from '../../../utilities/user';

import MobileMenu from '../mobile';
import UserMenu from '../user';

import classes from './menu.module.scss';
import { connect } from 'react-redux';

const NavigationMenu = ({ children, user, theme }) => {
  const { isMobile } = useWindowSize();
  const name = getFullName(user);

  if (isMobile) {
    return <MobileMenu>{children}</MobileMenu>;
  }

  return (
    <div className={classes.menu}>
      <div className={classes.menuContent}>
        <div className={classes.logo}>
          <Link
            to="/"
            style={{
              background: theme?.logo ? `url(${theme?.logo}) center center / contain no-repeat` : '',
            }}
          >
            {theme?.logo ? '' : 'GL'}
          </Link>
        </div>
        <div className={classes.items}>
          <SimpleBar className={classes.wrapper}>{children}</SimpleBar>
        </div>
        <Popover
          className={classes.popover}
          popoverClassName={classes.popoverContent}
          interactionKind="click"
          placement="right-end"
          modifiers={{ arrow: { enabled: false } }}
          content={<UserMenu />}
        >
          <div className={classes.user}>
            <Avatar name={name} url={user?.avatar} abbreviate size={{ box: 30, text: 13 }} showOnlineIndicator />
            <div className={classes.info}>
              <div className={classes.name}>{user?.personalDetails?.firstName}</div>
              <div className={classes.name}>{user?.personalDetails?.lastName}</div>
            </div>
            <div className={classes.icon}>
              <Icons.ChevronRight size={15} strokeWidth={2} />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  theme: state.application.theme,
});

export default connect(mapStateToProps)(NavigationMenu);
