import React from 'react';
import moment from 'moment';

import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import Fields from '../../../../components/fields';

import { TrackingRecordStatusOptions, trackingRecordStatusToLabel } from '../../../../domain/tracking-record-status-type';

import classes from './time.module.scss';

const TimeFilters = ({ values, filterCounter, setFieldValue, submitForm, resetFilters, engagements }) => {
  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;

  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCounter}
        onClick={() => resetFilters()}
        text="Clear Filters"
        label={!!filterCounter ? <Icon icon="cross" /> : null}
      />
      <MenuDivider />
      <MenuItem
        className={classes.rangeFilter}
        text={
          <Fields.DateRange
            name="range"
            submitOnChange={true}
            showShortcuts={false}
            outline={false}
            minimal={true}
            showError={false}
            maxDate={
              values?.frequency === 'weekly' ? moment().startOf('isoWeek').add(-1, 'minute').toDate() : moment().startOf('month').add(-1, 'minute').toDate()
            }
          />
        }
        shouldDismissPopover={false}
      ></MenuItem>
      <MenuDivider />
      <MenuItem shouldDismissPopover={false} text={`Engagement (${engagements?.find(e => e.id === values?.engagementId)?.title || 'any'})`}>
        {engagements?.map?.(e => (
          <MenuItem
            key={e?.id}
            shouldDismissPopover={false}
            onClick={() => {
              setFieldValue('engagementId', e.id === values?.engagementId ? null : e.id);
              submitForm();
            }}
            label={tick(e.id === values?.engagementId)}
            text={e?.title}
          />
        ))}
      </MenuItem>
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Status (${values?.status === undefined || values?.status === null ? 'any' : trackingRecordStatusToLabel(values?.status)})`}
      >
        {TrackingRecordStatusOptions.map(option => (
          <MenuItem
            key={option.value}
            shouldDismissPopover={false}
            onClick={() => {
              setFieldValue('status', `${option.value}`);
              submitForm();
            }}
            label={tick(`${values?.status}` === `${option.value}`)}
            text={option.label}
          />
        ))}
      </MenuItem>
    </Menu>
  );
};

export default TimeFilters;
