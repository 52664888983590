import { uuid } from '../../../../utilities/common';

import { ObjectTypes } from './types';
import Group from './group';

export default class Page {
  constructor(page = {}) {
    const { id = uuid(), title, showHeader = true, description = '', groups = [], visibility = {}, buttons } = page;

    this.id = id;
    this.type = ObjectTypes.Page;
    this.title = title;
    if (!this.title) {
      this.title = 'New Page';
    }

    this.showHeader = showHeader;
    this.description = description || `<h2>${this.title}</h2>`;

    this.buttons = {
      close: { show: true, icon: 'cross' },
      back: { show: true, text: 'Back' },
      next: { show: true, text: 'Next' },
      ...buttons,
    };

    if (visibility?.field) {
      // if the field is set then set rules to support backward compatibility
      this.visibility = {
        combinator: 'AND',
        rules: [
          {
            field: visibility.field,
            operator: visibility.operator,
            value: visibility.value,
          },
        ],
      };
    } else {
      this.visibility = visibility;
    }

    this.groups = groups?.map(g => new Group(g));
  }
}
