import React, { useContext } from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import _ from 'lodash';

import Box from '../../../box';

import WithConfirmation from '../../../../hooks/with-confirmation';
import useApi from '../../../../hooks/use-api';
import { removeRecord } from '../../../../apis/configuration';

import { SystemContext } from '../system-provider';

import classes from './records.module.scss';

const Record = ({ record, onView, api }) => {
  const { selectedScope } = useContext(SystemContext);
  const removeRecordApi = useApi(id => removeRecord(id), { skip: true });
  const handleRemove = async id => {
    await removeRecordApi.request(id);
    await api?.request();
  };

  const [group, name] = record?.configurationType.split('|');
  const isInherited = record.scopeId !== selectedScope.id;

  const renderDescription = () => {
    if (!record.description?.length) {
      return null;
    }

    if (!!record.description?.length < 75) {
      return <div className={classes.recordDescription}>{record.description}</div>;
    }

    return (
      <Popover placement="bottom" disabled={!record.description} content={<div className={classes.description}>{record.description}</div>}>
        <Button minimal icon={<Icons.FileText size={16} strokeWidth={1.5} />} />
      </Popover>
    );
  };

  return (
    <Box.ItemContainer className={classes.record}>
      <Box.ItemValue className={classes.ellipsis}>
        <div className={classes.name}>
          {isInherited && <Icons.ChevronsUp size={14} strokeWidth={1} />}
          {_.startCase(name)}
        </div>
      </Box.ItemValue>
      {record.description && <Box.ItemLabel>{renderDescription()}</Box.ItemLabel>}
      <Popover
        content={
          <Menu>
            <MenuItem text="Modify" onClick={() => onView(record)} />
            <MenuDivider />
            <WithConfirmation messages={{ question: `Remove this item (${_.startCase(group)} - ${_.startCase(name)}) ?`, confirmButton: 'Remove' }}>
              <MenuItem shouldDismissPopover={false} text="Delete" onClick={() => handleRemove(record?.id)} />
            </WithConfirmation>
          </Menu>
        }
      >
        <Button outlined small icon={<Icons.MoreHorizontal size={16} strokeWidth={1.25} />} />
      </Popover>
    </Box.ItemContainer>
  );
};

export default Record;
