import React, { useState } from 'react';
import moment from 'moment';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Dialog from '../../../../dialog';

import WithConfirmation from '../../../../../hooks/with-confirmation';
import useApi from '../../../../../hooks/use-api';
import { approveEngagementTermination, cancelEngagementTermination, getEngagementTermination } from '../../../../../apis/platform/engagements';
import { updateReview } from '../../../../../apis/wizards';

import TerminationForm from './termination-form';

import classes from './termination.module.scss';

const TimelineTerminationRequest = ({ engagement, step, loadTimeline, closeStepDetails, review, loadReview }) => {
  const [dialog, setDialog] = useState({ open: false });
  const getTerminationApi = useApi(getEngagementTermination, {
    params: { engagementId: engagement?.id },
    skip: !engagement?.id,
  });
  const cancelTerminationApi = useApi(cancelEngagementTermination, { skip: true });
  const cancelTermination = async () => {
    await cancelTerminationApi?.request({ engagementId: engagement?.id });
    await getTerminationApi?.request({ engagementId: engagement?.id });
    closeStepDetails();
    loadTimeline();
  };

  const approveTerminationApi = useApi(approveEngagementTermination, { skip: true });
  const updateReviewApi = useApi(updateReview, { skip: true });
  const approveTermination = async () => {
    await approveTerminationApi?.request({ engagementId: engagement?.id });

    const data = {
      reviewId: review.id,
      flowId: step.review?.flowReviewId,
      instanceId: step?.instanceId,
      result: true,
      elaboration: 'Termination request approved.',
    };

    await updateReviewApi.request(data);

    closeStepDetails();
    loadReview();
    loadTimeline();
  };

  if (step?.contextType !== 'requestengagementterminationobjectcontext') {
    return null;
  }

  const loading = getTerminationApi.loading;

  return (
    <div className={classes.termination}>
      <Box
        title="Termination request"
        small
        monochrome
        loading={loading}
        actions={
          <ButtonGroup>
            <WithConfirmation
              messages={{
                question: `Are you sure you want to approve termination request ? Doing this will set new engagement end date (${moment(
                  getTerminationApi?.response?.endDate,
                ).format('MM/DD/YYYY')}) and engagement status will be changed to "Complete" on that same date.`,
                confirmButton: 'Approve',
              }}
            >
              <Button small outlined icon={<Icons.Check size={16} strokeWidth={1.5} />} onClick={approveTermination} />
            </WithConfirmation>
            <WithConfirmation messages={{ question: `Are you sure you want to cancel termination request ?`, confirmButton: 'Yes' }}>
              <Button small outlined icon={<Icons.X size={16} strokeWidth={1.5} />} onClick={cancelTermination} />
            </WithConfirmation>
            <Button small outlined icon={<Icons.Edit2 size={16} strokeWidth={1.5} />} onClick={() => setDialog({ open: true })} />
          </ButtonGroup>
        }
      >
        <Box.Item label="End date">{moment(getTerminationApi?.response?.endDate).format('MM/DD/YYYY')}</Box.Item>
        <Box.Item label="Reason">{getTerminationApi?.response?.reason}</Box.Item>
        <Box.Item label="Notes">{getTerminationApi?.response?.notes}</Box.Item>
      </Box>
      <Dialog title="Update termination details" isOpen={dialog?.open} onClose={() => setDialog({ open: false })}>
        <TerminationForm {...{ getTerminationApi, onComplete: () => setDialog({ open: false }) }} />
      </Dialog>
    </div>
  );
};

export default TimelineTerminationRequest;
