import React from 'react';
import { Classes } from '@blueprintjs/core';

import classes from './engagement.module.scss';

const EngagementSpinner = () => {
  return (
    <div className={classes.loader}>
      <div className={Classes.SKELETON}>...</div>
    </div>
  );
};

export default EngagementSpinner;
