import React, { useState } from 'react';
import _ from 'lodash';

import Dialog from '../../../../dialog';

import { getForm } from './type-specific-forms';

const AddRecordDialog = ({ isOpen, onClose, record = {}, api }) => {
  const [loading, setLoading] = useState(false);

  const close = () => (!!loading ? null : onClose());
  const [group, name] = record?.configurationType?.split?.('|');
  const form = getForm(record?.configurationType, { onClose, record, loading, setLoading, api });

  return (
    <Dialog enforceFocus={false} title={`Add configuration record, ${_.startCase(group)}: ${_.startCase(name)}.`} isOpen={isOpen} onClose={close}>
      {form}
    </Dialog>
  );
};

export default AddRecordDialog;
