import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const MembersMenu = ({ onInvite }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="Invite" label={<Icon icon="plus" />} onClick={onInvite} />
        <MenuDivider />
        <MenuItem text="Export" label=".csv" disabled />
      </Menu>
    </React.Fragment>
  );
};

export default MembersMenu;
