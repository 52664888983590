import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { Button, ResizeSensor } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';

import Notifications from '../notifications';
import { toggleActivityVisibility } from '../../state/application';
import Breadcrumbs from './breadcrumbs';

import classes from './page.module.scss';

const Page = ({ children, title, path, notificationsCount, toggleActivityVisibility, scroll = true, allowMobile = true }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(document.body.clientWidth < 800);
  }, []);

  const getContent = () => {
    const mobile =
      isMobile && !allowMobile ? (
        <div className={classNames(classes.content, classes.noMobile)}>
          <div className={classes.splash}>
            <Icons.Smartphone size={150} strokeWidth={0.25} />
            <div className={classes.slash} />
          </div>
          <div>Page not optimized for mobile viewing.</div>
          <NavLink to="/dashboard" replace>
            Go back
          </NavLink>
        </div>
      ) : null;

    if (scroll) {
      return (
        <SimpleBar className={classNames(classes.content, classes.scrollable)}>
          {mobile}
          {children}
        </SimpleBar>
      );
    }

    return (
      <div className={classes.content}>
        {mobile}
        {children}
      </div>
    );
  };

  return (
    <ResizeSensor observeParents={true} onResize={() => setIsMobile(document.body.clientWidth < 800)}>
      <div className={classes.page}>
        <div className={classes.header}>
          <div className={classes.info}>
            {!!title && <div className={classNames(classes.title, !path?.length && classes.large)}>{title}</div>}
            <Breadcrumbs path={path} />
          </div>
          <Button
            disabled={!notificationsCount}
            className={classNames(classes.notifications, !!notificationsCount && classes.withAlerts)}
            minimal
            icon="notifications"
            onClick={toggleActivityVisibility}
          />
          <Notifications />
        </div>
        {getContent()}
      </div>
    </ResizeSensor>
  );
};

const mapStateToProps = state => ({
  notificationsCount: state.notifications?.length,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleActivityVisibility,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Page);
