export const PayableItemType = {
  Regular: 'regular-hours',
  Overtime: 'over-time-hours',
  DoubleTime: 'double-time-hours',
  Salary: 'salary',
  SalaryOvertime: 'salary-over-time',
  SalaryDoubleTime: 'salary-double-time',
  Expense: 'expense',
  Deliverable: 'deliverable',
  Adjustment: 'adjustment',
  Deduction: 'deduction',
};

export const PayableItemTypeOptions = [
  {
    label: 'Regular Hours',
    value: PayableItemType.Regular,
  },
  {
    label: 'Overtime Hours',
    value: PayableItemType.Overtime,
  },
  {
    label: 'Double Time Hours',
    value: PayableItemType.DoubleTime,
  },
  {
    label: 'Salary',
    value: PayableItemType.Salary,
  },
  {
    label: 'Salary Overtime',
    value: PayableItemType.SalaryOvertime,
  },
  {
    label: 'Salary Double Time',
    value: PayableItemType.SalaryDoubleTime,
  },
  {
    label: 'Expense',
    value: PayableItemType.Expense,
  },
  {
    label: 'Deliverable',
    value: PayableItemType.Deliverable,
  },
  {
    label: 'Adjustment',
    value: PayableItemType.Adjustment,
  },
  {
    label: 'Deduction',
    value: PayableItemType.Deduction,
  },
];

export const isRegular = type => [PayableItemType.Regular, PayableItemType.Salary].includes(type);
export const isOvertime = type => [PayableItemType.Overtime, PayableItemType.SalaryOvertime].includes(type);
export const isDoubleTime = type => [PayableItemType.DoubleTime, PayableItemType.SalaryDoubleTime].includes(type);
export const isHourly = type => [PayableItemType.Regular, PayableItemType.Overtime, PayableItemType.DoubleTime].includes(type);

export const payrollItemTypeToLabel = type => {
  switch (type) {
    case PayableItemType.Regular:
    case PayableItemType.Overtime:
    case PayableItemType.DoubleTime:
      return 'Shifts';

    case PayableItemType.Salary:
    case PayableItemType.SalaryOvertime:
    case PayableItemType.SalaryDoubleTime:
      return 'Salary';

    case PayableItemType.Expense:
      return 'Expense';

    case PayableItemType.Deliverable:
      return 'Deliverable';

    case PayableItemType.Adjustment:
      return 'Adjustment';

    case PayableItemType.Deduction:
      return 'Deduction';

    default:
      return 'Other';
  }
};
