import React from 'react';
import { Route, Routes } from 'react-router-dom';

import List from './list';
import Onboarding from './onboarding';

const Engagements = () => {
  return (
    <Routes>
      <Route path="/" Component={List} />
      <Route path="/:id/onboarding" Component={Onboarding} />
    </Routes>
  );
};

export default Engagements;
