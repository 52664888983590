import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';

import { MimeTypes } from '../../../../../../../../utilities/mime-types';
import Fields from '../../../../../../../fields';
import Form, { FormGroup } from '../../../../../../../form';

import useApi from '../../../../../../../../hooks/use-api';
import { createRecord } from '../../../../../../../../apis/configuration';
import { updateTemplate, uploadInitialBlankTemplate, uploadInitialPdfTemplate } from '../../../../../../../../apis/templates';
import { getDocumentWizards } from '../../../../../../../../apis/wizards';

import { AllowedTemplateTypes } from '../../../../../common/allowed-template-types';
import { TemplateTypes } from '../../../../../common/template-types';
import { loadTrees } from '../../../../../../../../state/contexts';
import { SystemContext } from '../../../../../system-provider';

import classes from './system-populated-document.module.scss';

const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

const SystemPopulatedDocument = ({ record, onClose, loading, setLoading, api, data, trees, treesOptions, loadTrees }) => {
  const navigate = useNavigate();
  const { selectedScope } = useContext(SystemContext);
  const getTreesApi = useApi(async () => (trees ? trees : loadTrees()), { skip: record?.id });
  const getDocumentWizardsApi = useApi(() => getDocumentWizards({ scopeId: selectedScope?.id }), { skip: true });

  useEffect(() => {
    if (record?.id && data?.templateId) {
      navigate(`/settings/documents/${data?.templateId}/${record?.id}`);
    }
  }, [data?.templateId]);

  const submit = async values => {
    setLoading(true);
    const { objectReferences, fromScratch, template: _template, ...rest } = values;
    const documentType = trees?.find?.(t => t.contextTypeName === values.data?.documentTemplateType);

    let template;
    if (fromScratch === false) {
      template = await uploadInitialBlankTemplate();
    } else {
      template = values.template;
    }

    let wizardInfo;
    if (documentType?.templateSubType === TemplateTypes.Wizard && objectReferences) {
      const wizard = getDocumentWizardsApi.response?.find(w => w?.data?.templateId === objectReferences);
      const name = wizard?.type?.split('|')?.[1];
      const label = name?.replace(regExp, '$1$4 $2$3$5');
      wizardInfo = `${label}, ${wizard?.organization?.name}, ${wizard.location?.prettyName || 'Global'}`;
    }
    const description = [documentType?.name, wizardInfo].filter(Boolean).join(', ');

    await updateTemplate({
      ...template,
      name: values?.data?.documentName,
      description,
      refName: documentType?.refName,
      contextTypeName: values?.data?.documentTemplateType,
      subType: documentType?.templateSubType,
      objectReferences: documentType?.templateSubType !== TemplateTypes.Wizard ? undefined : objectReferences,
    });

    const payload = { ...rest, description: `(Draft) ${rest?.data?.documentName}`, data: { ...rest.data, templateId: template?.id || rest.data.templateId } };
    const response = await createRecord(payload);
    await api?.request();

    if (payload.data?.templateId) {
      navigate(`/settings/documents/${payload.data?.templateId}/${response.data?.id}`);
    } else {
      setLoading(false);
      onClose();
    }
  };

  const disabled = loading || getTreesApi.loading;

  return (
    <Form
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        fromScratch: undefined,
        data: {
          documentTemplateType: trees?.find?.(t => t.contextTypeName === data?.documentTemplateType)?.contextTypeName,
          documentName: data?.documentName || '',
          templateId: data?.templateId,
        },
        objectReferences: undefined,
        ...record,
      }}
      validationSchema={Yup.object({
        scopeId: Yup.string().required('Scope is required'),
        data: Yup.object({
          documentTemplateType: Yup.string().required('Document type is required'),
          documentName: Yup.string().required('Document name is required'),
          templateId: Yup.string().when(['fromScratch'], ([fromScratch], schema, { value }) => {
            if (fromScratch === false && !value) {
              return schema.required('Source is required');
            }
            return schema;
          }),
        }),
        fromScratch: Yup.boolean().when(['data'], ([data], schema, { value }) => {
          if (!data?.templateId && value !== false) {
            return schema.required('Source is required');
          }
          return schema;
        }),
        objectReferences: Yup.string().when('data.documentTemplateType', ([documentTemplateType], schema, { value }) => {
          const documentType = trees?.find?.(t => t.contextTypeName === documentTemplateType);
          if (documentType?.templateSubType === TemplateTypes.Wizard && !value) {
            return schema.required('Wizard target is required');
          }
          return schema;
        }),
      })}
      enableReinitialize={true}
      onSubmit={submit}
    >
      {form => {
        const documentType = trees?.find?.(t => t.contextTypeName === form.values.data?.documentTemplateType);
        const uploaded = form.values?.data?.templateId || form.values?.template?.id;
        return (
          <React.Fragment>
            <FormGroup label="Template source" error={form.errors['fromScratch']}>
              <div className={classes.chooseDocumentSource}>
                <Button
                  fill
                  intent={form.values.fromScratch === false ? 'primary' : undefined}
                  onClick={() => {
                    form.setFieldValue('fromScratch', false);
                  }}
                >
                  Create from scratch
                </Button>
                <Fields.File
                  name="template"
                  maxSize={5}
                  mimeTypes={MimeTypes.PDF}
                  api={file => uploadInitialPdfTemplate({ file })}
                  onUploaded={result => {
                    form.setFieldValue('fromScratch', true);
                    form.setFieldValue('data.templateId', result.id);
                  }}
                  fill
                  outline={false}
                  minimal
                  showError={false}
                >
                  <Button intent={uploaded ? 'primary' : undefined} fill>
                    Select initial template
                  </Button>
                </Fields.File>
              </div>
            </FormGroup>

            <Fields.Text name="data.documentName" label="Name" />
            <Fields.Select name="data.documentTemplateType" label="Document type" options={treesOptions} />

            {documentType?.templateSubType === TemplateTypes.Wizard && (
              <Fields.Select
                name="objectReferences"
                label="Target wizard"
                textRenderer={option => {
                  const obj = getDocumentWizardsApi?.response?.find(r => r?.data?.templateId === option.value);
                  const name = obj?.type?.split('|')?.[1];
                  return name?.replace(regExp, '$1$4 $2$3$5');
                }}
                remoteOptions={{
                  loading: getDocumentWizardsApi.loading,
                  request: getDocumentWizardsApi.request,
                  mapper: result => {
                    return result?.map(r => {
                      const name = r?.type?.split('|')?.[1];
                      const label = name?.replace(regExp, '$1$4 $2$3$5');
                      return {
                        label: (
                          <div className={classes.info} key={r.id}>
                            <div className={classes.name}>{label}</div>
                            <div className={classes.meta}>
                              <div className={classes.organization}>{r?.organization?.name}</div>
                              <div className={classes.scope}>({r.location?.prettyName || 'Global'})</div>
                            </div>
                          </div>
                        ),
                        value: r?.data?.templateId,
                        description: `${label} ${r?.organization?.name} ${r.location?.prettyName || 'Global'}`,
                      };
                    });
                  },
                  filter: 'local',
                }}
              />
            )}
            <Button type="submit" outlined fill text="Submit" disabled={disabled} loading={loading} />
          </React.Fragment>
        );
      }}
    </Form>
  );
};

const mapStateToProps = state => {
  const trees = state.contexts.trees?.filter(tree => tree.templateTypes === AllowedTemplateTypes.Document || tree.templateTypes === AllowedTemplateTypes.All);
  const treesOptions = (trees || []).map(tree => ({ label: tree?.name, value: tree?.contextTypeName }));
  return {
    trees,
    treesOptions,
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadTrees,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SystemPopulatedDocument);
