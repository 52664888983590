import React from 'react';
import { Classes } from '@blueprintjs/core';

import Box from '../../box';

import useApi from '../../../hooks/use-api';
import { getBankAccount, getBankingFields } from '../../../apis/banking';

import NotesPopover from './notes-popover';

import classes from './timeline-step.module.scss';

const BankBox = ({ field, review, loadReview }) => {
  return (
    <Box monochrome small expandible title={field.label} actions={<NotesPopover fieldId={field.fullPath} review={review} onComplete={loadReview} />}>
      <BankBoxContent field={field} />
    </Box>
  );
};

const BankBoxContent = ({ field }) => {
  const bankAccountApi = useApi(() => getBankAccount(field.value), { skip: !field.value });
  const bankAccountFieldsApi = useApi(countryId => getBankingFields(countryId), { skip: true });

  React.useEffect(() => {
    if (bankAccountApi.response?.countryId) {
      void bankAccountFieldsApi.request(bankAccountApi.response?.countryId);
    }
  }, [bankAccountApi.response?.countryId]);

  if (!field.value) {
    return (
      <Box.ItemContainer key={field.name}>
        <Box.ItemLabel>{field.label}</Box.ItemLabel>
        <Box.ItemValue className={classes.fieldValue}>Not provided</Box.ItemValue>
      </Box.ItemContainer>
    );
  }

  const ready = bankAccountApi.response && bankAccountFieldsApi.response;

  if (!ready) {
    return new Array(4).fill(0).map((_, index) => (
      <Box.ItemContainer key={index}>
        <Box.ItemLabel className={Classes.SKELETON}>Loading...</Box.ItemLabel>
        <Box.ItemValue className={Classes.SKELETON}>Loading...</Box.ItemValue>
      </Box.ItemContainer>
    ));
  }

  const getFieldValue = (field, bankAccount) => {
    switch (field.type) {
      case 'bool':
        return bankAccount[field.name] ? 'Yes' : 'No';

      default:
        return bankAccount[field.name] || 'Not provided';
    }
  };

  return bankAccountFieldsApi.response?.map(field => {
    return (
      <Box.ItemContainer key={field.name}>
        <Box.ItemLabel>{field.label}</Box.ItemLabel>
        <Box.ItemValue className={classes.fieldValue}>{getFieldValue(field, bankAccountApi.response)}</Box.ItemValue>
      </Box.ItemContainer>
    );
  });
};

export default BankBox;
