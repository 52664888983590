import * as Yup from 'yup';
import { ElementTypes } from '../../../enumerations/field-definitions';

const envResourceRegEx = new RegExp(/glpubdocstora(dev|uat|prod)/gm);
const isEnvResource = url => Boolean(url?.match(envResourceRegEx)?.length);

const validateAndCastContent = (template, content) => {
  try {
    const json = JSON.parse(content);
    let temp;
    try {
      temp = templateSchema.validateSync(json, { abortEarly: true });
    } catch (e) {
      return {
        error: `The file content is not valid. Error: ${e.errors.join(', ')}`,
      };
    }

    if (template.contextTypeName !== temp.contextTypeName) {
      return {
        error: `contextTypeName must be a ${template.contextTypeName} but the final value was: ${temp.contextTypeName}`,
      };
    }

    let logo;
    const excludedResources = [];
    if (isEnvResource(temp.metadata.logo)) {
      excludedResources.push('Logo');
      logo = template.metadata.logo;
    } else {
      logo = temp.metadata.logo;
    }

    temp.metadata.render.body?.content?.forEach(row => {
      row.content?.forEach(obj => {
        if (obj.elementType === ElementTypes.IMAGE && isEnvResource(obj.value)) {
          excludedResources.push(obj.text || 'New Image');
          obj.value = '';
        }
      });
    });

    return {
      template: {
        ...template,
        metadata: {
          ...temp.metadata,
          logo,
        },
      },
      excluded: excludedResources,
    };
  } catch (e) {
    return {
      error: 'The file format is invalid',
    };
  }
};

const validObjectTypes = Object.values(ElementTypes);

const templateSchema = Yup.object({
  id: Yup.string().required().uuid(),
  type: Yup.number().required(),
  name: Yup.string().required(),
  refName: Yup.string().required(),
  contextTypeName: Yup.string().required(),
  contentType: Yup.string().required().matches('text/html'),
  metadata: Yup.object({
    subject: Yup.string().required(),
    footer: Yup.string().required(),
    render: Yup.object({
      body: Yup.object({
        elementType: Yup.string().required().matches('Div'),
        content: Yup.array().of(
          Yup.object({
            id: Yup.string().required().uuid(),
            title: Yup.string().required(),
            elementType: Yup.string().required().matches('Div'),
            content: Yup.array().of(
              Yup.object({
                id: Yup.string().required().uuid(),
                elementType: Yup.string()
                  .required()
                  .test('is-valid-field-type', 'Invalid or unsupported field type', value => {
                    return validObjectTypes.includes(value);
                  }),
                styleConfig: Yup.mixed().required(),
              }),
            ),
          }),
        ),
      }),
    }),
  }),
});

export default validateAndCastContent;
