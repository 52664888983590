import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import classes from './sortable.module.scss';

const Container = SortableContainer(({ children }) => {
  if (!children?.length) {
    return <div />;
  }
  return <div className={classes.dragArea}>{children}</div>;
});

export default Container;
