import React from 'react';
import { Icon, Menu, MenuItem } from '@blueprintjs/core';

const TimeImportMenu = ({ onImport }) => {
  return (
    <Menu>
      <MenuItem text="Import records" label={<Icon icon="plus" />} onClick={onImport} />
    </Menu>
  );
};

export default TimeImportMenu;
