import { base64URLEncode, uuid } from '../utilities/common';

export class StorageManager {
  constructor(location) {
    this.storage = location ? location : window.sessionStorage.getItem(StorageManager.Keys.AccessToken) ? window.sessionStorage : window.localStorage;
  }

  static Keys = {
    Codes: 'gl:c',
    Origin: 'gl:o',
    AccessToken: 'gl:a',
    RefreshToken: 'gl:r',
    ActiveMembership: 'gl:m',
    IsImpersonate: 'gl:i',
    Theme: 'gl:t',
  };

  async setCodes() {
    const codeVerifier = base64URLEncode(uuid(64));
    const sha = await window.crypto.subtle.digest('SHA-256', Buffer.from(codeVerifier));
    const codeChallenge = base64URLEncode(Buffer.from(sha).toString('base64'));

    const createdAt = new Date();
    const codes = { codeVerifier, codeChallenge, createdAt };
    this.storage.setItem(StorageManager.Keys.Codes, JSON.stringify(codes));
  }
  getCodes() {
    return this.getJson(StorageManager.Keys.Codes);
  }
  clearCodes() {
    this.storage.removeItem(StorageManager.Keys.Codes);
  }

  setOriginUrl() {
    const location =
      window.location.pathname === '/authentication/logout'
        ? {
            ...window.location,
            href: `${window.location.origin}/dashboard`,
            pathname: '/dashboard',
          }
        : window.location;
    this.storage.setItem(StorageManager.Keys.Origin, JSON.stringify(location));
  }
  getOriginUrl() {
    return this.getJson(StorageManager.Keys.Origin);
  }
  clearOriginUrl() {
    this.storage.removeItem(StorageManager.Keys.Origin);
  }

  setStoredTokens(data) {
    if (!data) throw new Error('No token data provided.');

    const { access_token, refresh_token } = data;
    this.storage.setItem(StorageManager.Keys.AccessToken, access_token);
    this.storage.setItem(StorageManager.Keys.RefreshToken, refresh_token);
  }
  getStoredTokens() {
    const access_token = this.storage.getItem(StorageManager.Keys.AccessToken);
    const refresh_token = this.storage.getItem(StorageManager.Keys.RefreshToken);
    return { access_token, refresh_token };
  }
  clearStoredTokens() {
    this.storage.removeItem(StorageManager.Keys.AccessToken);
    this.storage.removeItem(StorageManager.Keys.RefreshToken);
  }

  setActiveMembership(membership) {
    if (!!membership) {
      this.storage.setItem(StorageManager.Keys.ActiveMembership, JSON.stringify(membership));
    }
  }
  setActiveMembershipIfNonePresent(membership, all) {
    if (!membership || !all?.length) {
      return;
    }

    const current = this.getActiveMembership();
    const isValid = tested => all?.find(m => m?.roleId === tested?.roleId && m?.organization?.id === tested?.organization?.id);

    if (!!current && !!isValid(current)) {
      return;
    }
    if (!isValid(membership)) {
      return;
    }

    this.storage.setItem(StorageManager.Keys.ActiveMembership, JSON.stringify(membership));
  }
  getActiveMembership() {
    return this.getJson(StorageManager.Keys.ActiveMembership);
  }
  getActiveMembershipIdentifiers() {
    const membership = this.getJson(StorageManager.Keys.ActiveMembership);
    if (!membership) return {};

    const identifiers = {
      roleId: membership?.roleId,
      organizationId: membership?.organization?.id,
      membershipId: membership?.membershipId,
      organizationType: membership?.roleId?.split?.('-')?.[0],
    };
    return identifiers;
  }
  clearActiveMembership() {
    this.storage.removeItem(StorageManager.Keys.ActiveMembership);
  }

  setImpersonate() {
    this.storage.setItem(StorageManager.Keys.IsImpersonate, true);
  }
  IsImpersonate() {
    return this.storage.getItem(StorageManager.Keys.IsImpersonate);
  }
  clearImpersonate() {
    this.storage.removeItem(StorageManager.Keys.IsImpersonate);
  }

  getActiveTheme() {
    return this.storage.getItem(StorageManager.Keys.Theme);
  }

  setActiveTheme(theme) {
    this.storage.setItem(StorageManager.Keys.Theme, theme);
  }

  clear() {
    try {
      const membership = this.getActiveMembership();
      this.storage.clear();
      this.setActiveMembership(membership);
    } catch {
      this.storage.clear();
    }
  }

  getJson(key) {
    try {
      const data = this.storage.getItem(key);
      return JSON.parse(data);
    } catch (error) {
      return undefined;
    }
  }
}
