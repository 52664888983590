import React, { useContext } from 'react';
import moment from 'moment';
import { Button, Classes } from '@blueprintjs/core';

import { QuickTimekeepingContext } from '../../state';
import { isSameDay } from '../../../../../utilities/time';
import Segments from './segments';

import { DateFormat } from '../../../../../models/enumerations/date-format';
import { getSegments } from '../../utilities';

import classes from '../../quick.module.scss';
import classNames from 'classnames';
import * as Icons from 'react-feather';

const Day = ({ day, loading, isMobile }) => {
  const { openDialog, timeRecords, engagements } = useContext(QuickTimekeepingContext);
  const dateStr = day?.format(DateFormat.DEFAULT);
  const records = timeRecords?.shifts?.filter?.(s => isSameDay(s.date, day));
  const items = getSegments(dateStr, timeRecords, { includeBreakSegments: true, includeEmptySegments: true });
  const total = records?.reduce?.((a, b) => a + moment.duration(b.duration).asMinutes(), 0) / 60;
  const totalLabel = total.toString().length > 5 ? total.toFixed(2) : total;

  if (loading) {
    return (
      <div className={classes.day}>
        <span className={Classes.SKELETON}>{day.format('L')}</span>
      </div>
    );
  }

  const text = isMobile ? undefined : records?.length ? 'Edit' : 'Add';
  const icon = isMobile ? !!records?.length ? <Icons.Edit2 size={16} strokeWidth={1.5} /> : 'plus' : undefined;

  return (
    <div className={classes.day}>
      <div className={classes.indicator}>
        <div className={classes.name}>{moment(day).format('ddd').toUpperCase()}</div>
        <div className={classes.date}>{moment(day).format('MM/DD')}</div>
      </div>
      <div className={classes.bar}>
        <div className={classes.background}>{items?.map?.((r, i) => renderSegment(r, engagements, i))}</div>
      </div>
      <div className={classNames(classes.sum, !records?.length && classes.zero)}>{`${totalLabel}h`}</div>
      <div className={classes.controls}>
        <Button
          className={classNames(classes.quickButton, !!records?.length ? classes.edit : classes.add)}
          fill
          outlined
          icon={icon}
          text={text}
          onClick={() => openDialog({ type: 'day', date: day })}
        />
      </div>
    </div>
  );
};

const renderSegment = (record, engagements, index) => {
  switch (record?.type) {
    case 'break':
      return <Segments.Break key={index} record={record} />;

    case 'empty':
      return <Segments.Empty key={index} record={record} />;

    default:
      return <Segments.Shift key={index} record={record} engagements={engagements} />;
  }
};

export default Day;
