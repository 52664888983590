import React from 'react';
import { MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';

import { Field } from '../../form';

import styles from './creatable-field.module.scss';

const CreatableField = ({
  classes,
  name,
  label,
  description,
  placeholder = '',
  options,
  disabled = false,
  submitOnChange = false,
  outline,
  minimal,
  showError,
  validate,
  createNewItemRenderer,
}) => {
  return (
    <Field
      classes={classes}
      name={name}
      label={label}
      description={description}
      validate={validate}
      disabled={disabled}
      outline={outline}
      minimal={minimal}
      showError={showError}
    >
      {({ field, form }) => {
        const selectedItems = field.value || [];

        const onChange = value => {
          if (value && !selectedItems.includes(value)) {
            form.setFieldValue(name, [...selectedItems, value]);
          }

          if (!!submitOnChange) {
            void form.submitForm();
          }
        };

        const onRemoveItem = (value, index) => {
          selectedItems.splice(index, 1);
          form.setFieldValue(name, [...selectedItems]);

          if (!!submitOnChange) {
            void form.submitForm();
          }
        };

        const onClear = () => {
          form.setFieldValue(name, []);

          if (!!submitOnChange) {
            void form.submitForm();
          }
        };

        return (
          <MultiSelect
            className={styles.creatable}
            createNewItemFromQuery={query => query}
            createNewItemRenderer={(query, active, handleClick) => {
              if (createNewItemRenderer) {
                return createNewItemRenderer(query, active, handleClick);
              }
              return (
                <MenuItem icon="add" text={`Create ${query}`} roleStructure="listoption" active={active} onClick={handleClick} shouldDismissPopover={false} />
              );
            }}
            resetOnSelect={true}
            items={options}
            selectedItems={selectedItems}
            placeholder={placeholder}
            noResults={<MenuItem text="No items in the list" disabled shouldDismissPopover={false} />}
            itemRenderer={(obj, props) => {
              const { modifiers, handleFocus } = props;
              if (!props.modifiers.matchesPredicate) {
                return null;
              }
              return (
                <MenuItem
                  active={modifiers.active}
                  disabled={modifiers.disabled}
                  onFocus={handleFocus}
                  onClick={() => onChange(obj.value)}
                  text={obj.label}
                  key={obj.value}
                />
              );
            }}
            tagRenderer={obj => obj}
            onItemSelect={onChange}
            onRemove={onRemoveItem}
            onClear={onClear}
          />
        );
      }}
    </Field>
  );
};

export default CreatableField;
