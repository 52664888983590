import React from 'react';
import { Icon } from '@blueprintjs/core';

export const DialogStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
  MINIMIZED: 'minimized',
};
const useEmailDialog = ({ recipients, organizationId }) => {
  const [dialog, setDialog] = React.useState({
    status: DialogStatus.CLOSED,
    values: {
      organizationId,
      recipientType: recipients,
      to: [],
      cc: [],
      bcc: [recipients],
    },
  });

  const handleOpen = () => {
    if (dialog.status === DialogStatus.OPEN) {
      return;
    }

    // when maximized set to open
    if (dialog.status === DialogStatus.MINIMIZED) {
      setDialog(prevState => ({ ...prevState, status: DialogStatus.OPEN }));
      return;
    }

    // set to empty state on every open
    setDialog(prevState => ({
      ...prevState,
      status: DialogStatus.OPEN,
      values: {
        organizationId,
        recipientType: recipients,
        to: [],
        cc: [],
        bcc: [recipients],
      },
    }));
  };

  const handleClose = () => {
    setDialog(prevState => ({ ...prevState, status: DialogStatus.CLOSED }));
  };

  const handleMinimize = () => {
    setDialog(prevState => ({ ...prevState, status: DialogStatus.MINIMIZED }));
  };

  const addToRecipients = (email, type) => {
    const prevRecipients = dialog.values[type];
    const idx = prevRecipients.indexOf(email);

    // if already in list, remove it
    if (idx !== -1) {
      prevRecipients.splice(idx, 1);
      setDialog(prevState => ({
        ...prevState,
        values: { ...prevState.values, [type]: [...prevRecipients] },
      }));
      return;
    }

    // otherwise add it
    setDialog(prevState => ({
      ...prevState,
      values: { ...prevState.values, [type]: [...prevRecipients, email] },
    }));
  };

  const getTableActions = ({ emailRenderer }) => {
    if (dialog.status !== DialogStatus.MINIMIZED) {
      return [];
    }

    return [
      { divider: true },
      {
        name: 'Add to recipients',
        nested: true,
        items: [
          {
            name: 'To',
            label: d => {
              const alreadyInList = dialog.values.to.includes(emailRenderer(d));
              return <Icon icon="tick" style={{ opacity: alreadyInList ? 1 : 0.15 }} />;
            },
            onClick: d => addToRecipients(emailRenderer(d), 'to'),
          },
          {
            name: 'Cc',
            label: d => {
              const alreadyInList = dialog.values.cc.includes(emailRenderer(d));
              return <Icon icon="tick" style={{ opacity: alreadyInList ? 1 : 0.15 }} />;
            },
            onClick: d => addToRecipients(emailRenderer(d), 'cc'),
          },
          {
            name: 'Bcc',
            label: d => {
              const alreadyInList = dialog.values.bcc.includes(emailRenderer(d));
              return <Icon icon="tick" style={{ opacity: alreadyInList ? 1 : 0.15 }} />;
            },
            onClick: d => addToRecipients(emailRenderer(d), 'bcc'),
          },
        ],
      },
    ];
  };

  return {
    status: dialog.status,
    values: dialog.values,
    isOpen: dialog.status === DialogStatus.OPEN,
    isMinimized: dialog.status === DialogStatus.MINIMIZED,
    getTableActions,
    handleOpen,
    handleClose,
    handleMinimize,
    addToRecipients,
  };
};

export default useEmailDialog;
