export const ClassificationType = {
  Employee: 'employee',
  IndependentContractor: 'independent-contractor',
  Unknown: 'unknown',
};

export const ClassificationTypeOptions = [
  {
    label: 'Employee',
    value: ClassificationType.Employee,
  },
  {
    label: 'Independent contractor',
    value: ClassificationType.IndependentContractor,
  },
  {
    label: 'Unknown',
    value: ClassificationType.Unknown,
  },
];

const ClassificationTypeLabels = ClassificationTypeOptions.reduce((mapper, type) => {
  mapper[type.value] = type.label;
  return mapper;
}, {});

export const abbreviateClassification = classification => (classification === 'employee' ? 'EE' : 'IC');
export const classificationTypeToLabel = classification => ClassificationTypeLabels[classification] || 'Unknown';
