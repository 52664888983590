import React from 'react';
import { Slider } from '@blueprintjs/core';
import cn from 'classnames';

import { Field } from '../../form';

import styles from './slider.module.scss';

const SliderField = ({
  classes,
  name,
  validate,
  label,
  description,
  disabled = false,
  min = 0,
  max = 100,
  stepSize = 1,
  labelStepSize = 10,
  labelRenderer,
  submitOnChange = false,
  showError = true,
}) => (
  <Field classes={classes} name={name} label={label} description={description} validate={validate} outline={false} disabled={disabled} showError={showError}>
    {({ field, form }) => {
      return (
        <Slider
          className={cn(styles.sliderField, classes?.slider)}
          min={min}
          max={max}
          stepSize={stepSize}
          labelStepSize={labelStepSize}
          onChange={newValue => form.setFieldValue(name, newValue)}
          onRelease={() => {
            if (!!submitOnChange) {
              form.submitForm();
            }
          }}
          labelRenderer={labelRenderer}
          value={field.value}
        />
      );
    }}
  </Field>
);

export default SliderField;
