import React, { useEffect } from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as Yup from 'yup';

import Fields from '../../../fields';
import Form from '../../../form';

import classes from '../cron-expression.module.scss';

const CronExpressionSimpleForm = ({ label, placeholder, text, value, options, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const submit = values => {
    const [, , , ...rest] = values.expression.split(' ');
    const [hours, minutes] = values.time.split(':');
    const expression = `0 ${minutes} ${hours} ${rest.join(' ')}`;
    onChange(expression);
    setIsOpen(false);
  };

  function getInitialValues() {
    if (!value) {
      return {
        expression: undefined,
        time: undefined,
      };
    }

    const [, _m, _h, ...rest] = value.split(' ');
    return {
      expression: `0 0 12 ${rest.join(' ')}`,
      time: `${_h}:${_m}`,
    };
  }

  const initialValues = getInitialValues();
  const validationSchema = Yup.object().shape({
    expression: Yup.string().required('Day is required'),
    time: Yup.string().required('Time is required'),
  });

  return (
    <Popover
      fill
      isOpen={isOpen}
      content={
        <div className={classes.simplerConfigPopover}>
          <Form initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={submit}>
            {form => <FormFields {...{ form, options, setIsOpen }} />}
          </Form>
        </div>
      }
    >
      <Button
        className={classes.cronSimpleFormPopoverTarget}
        minimal
        title={label || placeholder}
        fill
        text={text}
        alignText="left"
        onClick={() => setIsOpen(true)}
      />
    </Popover>
  );
};

const FormFields = ({ form, options, setIsOpen }) => {
  useEffect(() => {
    if (form.values.expression && !form.values.time) {
      const [, _m, _h] = form.values.expression.split(' ');
      form.setFieldValue('time', `${_h}:${_m}`);
    }
  }, [form.values]);

  return (
    <React.Fragment>
      <Fields.Select label="Expression" name="expression" options={options} />
      <Fields.Time label="Time" name="time" format="12" fill />
      <div className={classes.row}>
        <Button outlined fill type="submit" text="Submit" />
        <Button outlined fill text="Cancel" onClick={() => setIsOpen(false)} />
      </div>
    </React.Fragment>
  );
};

export default CronExpressionSimpleForm;
