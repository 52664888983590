import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import normalizeUrl from 'normalize-url';

import Clients from '../configuration/client';
import { store } from '../configuration/store';
import { toUrlEncoded } from '../utilities/common';
import configuration from './configuration';
import { StorageManager } from './storage';

import { logout, setClaims } from '../state/identity/actions';

function createPath(to) {
  if (typeof to === 'string') return to;
  const { pathname, search, hash } = to;
  let path = pathname;
  if (search && search !== '?') {
    path += search.charAt(0) === '?' ? search : '?' + search;
  }
  if (hash && hash !== '#') {
    path += hash.charAt(0) === '#' ? hash : '#' + hash;
  }
  return path;
}

export const LOGOUT_ROUTE = '/authentication/logout';

/**
 * Checks for existence of tokens in storage location (local if not impersonate / session if impersonate).
 * If tokens are found they are immediately refreshed and identity state initialized.
 * @returns
 */
export const initialize = async () => {
  const manager = new StorageManager();
  let tokens = manager.getStoredTokens();
  if (!tokens?.access_token || !tokens?.refresh_token) return false;

  await refreshTokens();
  tokens = manager.getStoredTokens();
  const claims = jwtDecode(tokens.access_token);
  store.dispatch(setClaims(claims));
  setupAutoRefresh();
  return true;
};

export const impersonate = async emailHash => {
  try {
    const email = Buffer.from(emailHash, 'base64').toString('utf8');
    const response = await Clients.Api.get(`ui/profile/generate-token/${emailHash}`);
    const manager = new StorageManager(window.sessionStorage);
    const data = { access_token: response?.data?.accessToken, refresh_token: `gl:im|${email}` };
    manager.setStoredTokens(data);
    setImpersonateHeader(email);
    return true;
  } catch {
    return false;
  }
};

/**
 * Prepares PKCE request for identity server and redirects to login page.
 */
export const authenticate = async () => {
  const { authority, client_id, scope, redirect_uri } = configuration;

  const manager = new StorageManager();
  manager.clear();
  await manager.setCodes();
  manager.setOriginUrl();

  const query = {
    clientId: client_id,
    scope,
    responseType: 'code',
    redirectUri: redirect_uri,
    codeChallenge: manager.getCodes().codeChallenge,
    codeChallengeMethod: 'S256',
  };

  // Responds with a 302 redirect
  window.location.replace(normalizeUrl(`${authority}/connect/authorize?${toUrlEncoded(query)}`));
};

/**
 * Prepares token request data from received code and stored PKCE code.
 * If token request is successful clears stored PCKE code and stores received tokens.
 * @param {*} code
 */
export const getTokens = async (code, navigate) => {
  const { client_id, redirect_uri } = configuration;
  const manager = new StorageManager();
  const codes = manager.getCodes();
  let payload = { clientId: client_id, redirectUri: redirect_uri, grantType: 'authorization_code', code, codeVerifier: codes?.codeVerifier };

  const tokens = await requestTokens(payload);
  manager.setStoredTokens(tokens);
  manager.clearCodes();
  const location = manager.getOriginUrl();
  manager.clearOriginUrl();
  if (location) {
    const path = createPath(location);
    navigate(path);
  }
  const claims = jwtDecode(tokens.access_token);
  store.dispatch(setClaims(claims));
  setupAutoRefresh();
};

/**
 * Prepares data for refresh tokens call and stores received tokens.
 */
export const refreshTokens = async () => {
  const { client_id, redirect_uri } = configuration;
  const manager = new StorageManager();
  const refresh_token = manager.getStoredTokens()?.refresh_token;
  try {
    if (refresh_token?.startsWith('gl:im|')) {
      await refreshImpersonateToken(refresh_token.split('|')[1]);
      return;
    }
    const payload = { clientId: client_id, redirectUri: redirect_uri, grantType: 'refresh_token', refresh_token };
    const tokens = await requestTokens(payload);
    manager.setStoredTokens(tokens);
  } catch (error) {
    if (error?.message === 'Network Error') {
      return;
    }
    throw error;
  }
};

const refreshImpersonateToken = async email => {
  const adminManager = new StorageManager(window.localStorage);
  const tokens = adminManager.getStoredTokens();

  const headers = {
    Authorization: `Bearer ${tokens.access_token}`,
  };
  const { roleId, organizationId } = adminManager.getActiveMembershipIdentifiers();
  if (roleId && organizationId) {
    const membershipHeader = 'x-gl-member';
    headers[membershipHeader] = Buffer.from(`${organizationId}|${roleId}`).toString('base64');
  }

  const response = await axios.get(`${process.env.REACT_APP_API_SERVER}ui/profile/generate-token/${Buffer.from(email).toString('base64')}`, { headers });

  const manager = new StorageManager(window.sessionStorage);
  const data = { access_token: response?.data?.accessToken, refresh_token: `gl:im|${email}` };
  manager.setStoredTokens(data);
  setImpersonateHeader(email);
};

const setImpersonateHeader = email => {
  window.document.title = `🕵 Impersonating: ${email}`;
};

/**
 * Prepares logout arguments and redirects to identity logout endpoint.
 */
export const endSession = async () => {
  const { authority, client_id, post_logout_redirect_uri } = configuration;
  const manager = new StorageManager();
  manager.clear();
  const url = normalizeUrl(`${authority}/connect/logout?${toUrlEncoded({ client_id, post_logout_redirect_uri })}`, { removeTrailingSlash: false });
  window.location.replace(url);
};

export const getCodeFromLocation = location => {
  const split = location?.search?.toString().split('?');
  if (split.length < 2) return '';

  const pairs = split[1].split('&');
  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    if (key === 'code') {
      return decodeURIComponent(value || '');
    }
  }

  return '';
};

const requestTokens = async payload => {
  const { authority } = configuration;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const response = await axios.post(normalizeUrl(`${authority}/connect/token`), toUrlEncoded(payload), { headers });
  return response?.data;
};

const setupAutoRefresh = () => {
  const { checkSessionInterval } = configuration;
  const runIn = 60 * checkSessionInterval;

  window.setTimeout(async () => {
    try {
      await refreshTokens();
    } catch (e) {
      store.dispatch(logout());
    }
    setupAutoRefresh();
  }, runIn * 1000);
};
