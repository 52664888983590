import React from 'react';
import { ButtonGroup, Button } from '@blueprintjs/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { NavLink } from 'react-router-dom';

import { getAppInsights } from '../../telemetry/service';

import Page from '../page';
import Box from '../box';

import classes from './error-handler.module.scss';

export default class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, info: undefined, showDetails: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(_, info) {
    this.setState({ hasError: true, info });
    const ai = getAppInsights();
    ai?.trackException({ error: info, severityLevel: SeverityLevel.Error });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div className={classes.error}>
        <Page title="Something went wrong">
          <Box monochrome dark>
            <Box.ItemContainer>
              <div className={classes.content}>
                <div className={classes.splash}></div>
                <div className={classes.title}>Sorry, something went wrong there</div>
                <div className={classes.message}>
                  {`We track these errors automatically, but if the problem persists please feel free to `}
                  <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}?subject=${window.location.host} Issue`}>contact us</a>
                  {`. In the meantime, try again.`}
                </div>
                <div>
                  <ButtonGroup>
                    <NavLink role="button" to="/" replace>
                      <Button outlined text="Go back" onClick={() => this.setState(prevState => ({ ...prevState, hasError: false }))} />
                    </NavLink>
                    <Button
                      outlined
                      text={!this.state.showDetails ? 'Show details' : 'Hide details'}
                      onClick={() => this.setState(prevState => ({ ...prevState, showDetails: !this.state.showDetails }))}
                    />
                  </ButtonGroup>
                </div>
                {!!this.state.showDetails && <div className={classes.block}>{JSON.stringify(this.state.info?.componentStack)}</div>}
              </div>
            </Box.ItemContainer>
          </Box>
        </Page>
      </div>
    );
  }
}
