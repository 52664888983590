import React from 'react';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';

import useApi from '../../../../../../hooks/use-api';
import { getMembers, removeMember } from '../../../../../../apis/members';

const RemoveMemberForm = ({ organizationId, membership, onComplete }) => {
  const listApi = useApi(getMembers, { skip: true });
  const removeApi = useApi(removeMember, { skip: true });

  const submit = async values => {
    await removeApi.request(values);
    onComplete();
  };

  return (
    <Form
      initialValues={{
        replacement: '',
        membershipId: membership?.id,
      }}
      validationSchema={Yup.object().shape({
        replacement: Yup.string().required('Replacement member must be selected.'),
        membershipId: Yup.string().required('Membership identifier is required.'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      <Fields.Select
        label="Replacement"
        name="replacement"
        remoteOptions={{
          loading: listApi.loading,
          request: (query = '') => listApi.request({ filters: { organizationId, includeParentMembers: true, query } }),
          mapper: result => {
            return result?.data?.map?.(r => ({
              label: `${r?.user?.personalDetails?.firstName} ${r?.user?.personalDetails?.lastName}`,
              value: r?.id,
              disabled: r?.id === membership?.id,
            }));
          },
          filter: 'remote',
        }}
      />
      <Button type="submit" outlined fill text="Submit" disabled={removeApi.loading} loading={removeApi.loading} />
    </Form>
  );
};

export default RemoveMemberForm;
