import React from 'react';

import useDocuments from '../../state/use-documents';
import Spinner from '../../../../spinner';

import BlankEditor from './blank-editor';
import PdfEditor from './pdf-editor';

import classes from './editor.module.scss';

const Editor = () => {
  const { loading, template, stream } = useDocuments();

  if (!template || loading) {
    return (
      <div className={classes.editor}>
        <div className={classes.content}>
          <div className={classes.loading}>
            <Spinner global={false} blend />
          </div>
        </div>
      </div>
    );
  }

  if (stream.fromScratch) {
    return (
      <div className={classes.editor}>
        <BlankEditor />
      </div>
    );
  }

  return (
    <div className={classes.editor}>
      <PdfEditor />
    </div>
  );
};

export default Editor;
