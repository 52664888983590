import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import React from 'react';
import * as Icons from 'react-feather';

import { downloadDocument, getDocuments, removeDocument, uploadDocument } from '../../../../../apis/documents';
import Box from '../../../../../components/box';
import useApi from '../../../../../hooks/use-api';
import Form from '../../../../../components/form';
import Fields from '../../../../../components/fields';
import { MimeTypes } from '../../../../../utilities/mime-types';
import classes from '../../company.module.scss';
import WithConfirmation from '../../../../../hooks/with-confirmation';

const Documents = ({ api }) => {
  const supplier = api.response;
  const getDocumentsApi = useApi(() => getDocuments({ type: 'supplier', id: supplier?.id }), { skip: true });

  React.useEffect(() => {
    if (supplier?.id) {
      void getDocumentsApi.request({ type: 'supplier', id: supplier?.id });
    }
  }, [supplier?.id]);

  return (
    <div className={classes.tab}>
      <Box
        title="Documents"
        actions={
          <ButtonGroup>
            <Form initialValues={{ file: null }} onSubmit={() => null}>
              <div className={classes.neg15}>
                <Fields.File
                  fill
                  outline={false}
                  name="file"
                  mimeTypes={[MimeTypes.PDF]}
                  label="Upload file"
                  api={file => uploadDocument({ file, name: file?.name, type: 'supplier', userId: supplier?.id })}
                  onUploaded={getDocumentsApi.request}
                >
                  <Button small outlined icon={<Icons.Upload size={16} strokeWidth={1.5} />} title="Upload" />
                </Fields.File>
              </div>
            </Form>
          </ButtonGroup>
        }
        outlined
        monochrome
        loading={getDocumentsApi.loading}
      >
        {getDocumentsApi.loading
          ? [1, 2, 3, 4, 5].map((_, i) => (
              <Box.TabularItem key={i}>
                <Box.ItemValue className={Classes.SKELETON}>name</Box.ItemValue>
                <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
                <ButtonGroup>
                  <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
                  <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
                </ButtonGroup>
              </Box.TabularItem>
            ))
          : getDocumentsApi.response?.map(document => (
              <Box.TabularItem key={document?.id}>
                <Box.ItemValue>{document?.name}</Box.ItemValue>
                <Box.ItemLabel>{document?.contentType}</Box.ItemLabel>
                <ButtonGroup>
                  <Button small outlined icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(document)} />
                  <WithConfirmation>
                    <Button
                      small
                      outlined
                      icon={<Icons.X size={16} strokeWidth={1.5} />}
                      onClick={async () => {
                        await removeDocument(document);
                        void getDocumentsApi.request();
                      }}
                    />
                  </WithConfirmation>
                </ButtonGroup>
              </Box.TabularItem>
            ))}
        {!getDocumentsApi.loading && !getDocumentsApi.response?.length && (
          <Box.TabularItem>
            <Box.ItemLabel fill>No records found.</Box.ItemLabel>
          </Box.TabularItem>
        )}
      </Box>
    </div>
  );
};

export default Documents;
