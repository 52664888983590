import React, { useContext, useEffect } from 'react';
import { connect } from 'formik';

import { WizardContext } from '../state';
import { WizardInstanceContext } from '../state/instance-provider';

import { buildValidationSchema } from '../state/validation-builder';
import { buildInitialValues } from '../state/initial-values-builder';

import PageRenderer from './components/renderers/page';

const WizardForm = ({ formik, page, setFormOptions }) => {
  const wizardContext = useContext(WizardContext);
  const instanceContext = useContext(WizardInstanceContext);
  const { template } = wizardContext;
  const { instance } = instanceContext;

  useEffect(() => {
    if (!instance || !page) {
      return;
    }

    let validationSchema;
    const values = { ...instance, ...formik?.values, progress: instance?.progress };
    const initialValues = buildInitialValues(template, page, values);
    if (!wizardContext.isDesigner) {
      validationSchema = buildValidationSchema(template, page, values);
    }
    setFormOptions({ validationSchema, initialValues });
  }, [page, formik?.values, instance]);

  return <PageRenderer formik={formik} page={page} />;
};

export default connect(WizardForm);
