import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import React from 'react';
import * as Icons from 'react-feather';

import Box from '../../../../../../components/box';

import useApi from '../../../../../../hooks/use-api';

import { downloadDocument, getDocuments, removeDocument } from '../../../../../../apis/documents';
import classes from '../details.module.scss';
import WithConfirmation from '../../../../../../hooks/with-confirmation';

const Documents = ({ api, engagementId }) => {
  const { loading, response, request } = useApi(() => getDocuments({ type: 'engagement', id: engagementId }));

  return (
    <div className={classes.tab}>
      <Box title="Documents" outlined monochrome>
        {loading
          ? [1, 2, 3, 4, 5].map((_, i) => (
              <Box.TabularItem key={i}>
                <Box.ItemValue className={Classes.SKELETON}>name</Box.ItemValue>
                <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
                <ButtonGroup>
                  <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
                  <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
                </ButtonGroup>
              </Box.TabularItem>
            ))
          : response?.map(document => (
              <Box.TabularItem key={document.id}>
                <Box.ItemValue>{document.name}</Box.ItemValue>
                <Box.ItemLabel>{document.contentType}</Box.ItemLabel>
                <ButtonGroup>
                  <Button small outlined icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(document)} />
                  <WithConfirmation icon={true}>
                    <Button
                      small
                      outlined
                      icon={<Icons.X size={16} strokeWidth={1.5} />}
                      onClick={async () => {
                        await removeDocument(document);
                        await request();
                      }}
                    />
                  </WithConfirmation>
                </ButtonGroup>
              </Box.TabularItem>
            ))}
        {!loading && !response?.length && (
          <Box.TabularItem key={document.name}>
            <Box.ItemLabel fill>No records found.</Box.ItemLabel>
          </Box.TabularItem>
        )}
      </Box>
    </div>
  );
};

export default Documents;
