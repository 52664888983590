import React, { useEffect } from 'react';
import moment from 'moment';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../box';

import useApi from '../../../hooks/use-api';
import { getEngagementChecks } from '../../../apis/background-check';
import { downloadDocument } from '../../../apis/documents';

import { capitalize } from '../../../utilities/common';

import classes from './timeline-step.module.scss';

const TimelineBackgroundCheck = ({ template, engagement }) => {
  const getChecksApi = useApi(() => getEngagementChecks(engagement?.id), { skip: true });

  useEffect(() => {
    if (template?.contextTypeName === 'supplierbackgroundcheckobjectcontext') {
      getChecksApi.request();
    }
  }, [template?.contextTypeName]);

  if (template?.contextTypeName !== 'supplierbackgroundcheckobjectcontext') {
    return null;
  }

  if (getChecksApi?.loading) {
    return (
      <React.Fragment>
        <div className={classes.separator}>
          <span>Reports</span>
        </div>
        <div className={classes.list}>
          <Box loading={true}></Box>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.separator}>
        <span>Reports</span>
      </div>
      <div className={classes.list}>
        {getChecksApi?.response?.map?.(check => (
          <Box
            key={check.id}
            monochrome
            small
            expandible={!!check?.reports?.length}
            title={capitalize(check?.package?.split?.('_').join?.(' '))}
            actions={
              <ButtonGroup className={classes.backgroundCheckIndicators}>
                <Button outlined small className={classes.time} text={moment(check?.date).format('MM/DD/YYYY HH:mm')} />
                <Button
                  outlined
                  small
                  className={classes.isComplete}
                  intent={!!check?.isComplete ? 'success' : 'none'}
                  text={!!check?.isComplete ? 'Complete' : 'In Progress'}
                />
                <Button
                  outlined
                  small
                  className={classes.isClear}
                  intent={!!check?.isClear ? 'success' : 'danger'}
                  icon={!!check?.isClear ? <Icons.Check size={16} strokeWidth={1.5} /> : <Icons.X size={16} strokeWidth={1.5} />}
                />
              </ButtonGroup>
            }
          >
            {!!check?.reports?.length ? (
              check?.reports?.map?.(report => (
                <Box.ItemContainer key={report?.id}>
                  <Box.ItemValue>{report?.name}</Box.ItemValue>
                  <Button outlined small icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(report)} />
                </Box.ItemContainer>
              ))
            ) : (
              <Box.ItemContainer>
                <Box.ItemLabel>No reports received.</Box.ItemLabel>
              </Box.ItemContainer>
            )}
          </Box>
        ))}
      </div>
    </React.Fragment>
  );
};

export default TimelineBackgroundCheck;
