import React from 'react';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import classes from './invite.module.scss';

const NextStep = ({ onDone, setProgress }) => {
  return (
    <div className={classes.nextStep}>
      <div className={classes.title}>You have successfully invited a worker!</div>
      <div className={classes.list}>
        <div className={classes.subtitle}>Here are the next steps:</div>
        <div className={classNames(classes.button, classes.new)} onClick={() => setProgress({ status: 'in-progress', engagementId: undefined })}>
          <span>Invite another worker</span>
          <Icons.UserPlus size={20} strokeWidth={1.5} />
        </div>
        <div className={classNames(classes.button, classes.new)} onClick={() => setProgress(prevState => ({ ...prevState, status: 'in-progress' }))}>
          <span>Copy from previous</span>
          <Icons.Users size={20} strokeWidth={1.5} />
        </div>
        <div className={classes.button} onClick={onDone}>
          <span>All done</span>
          <Icons.CornerDownLeft size={20} strokeWidth={1.5} />
        </div>
      </div>
    </div>
  );
};

export default NextStep;
