import React, { useContext } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Button, ButtonGroup } from '@blueprintjs/core';
import classNames from 'classnames';

import { WizardOrderContext } from '../../state/order-provider';
import { WizardExpandContext } from '../../state/expand-provider';
import { WizardSelectionContext } from '../../state/selection-provider';
import { Container, Handle } from '../../common/components/sortable';

import Field from '../field';
import GroupMenu from './menu';

import classes from './group.module.scss';

const Group = ({ page, group }) => {
  const { reorderFields } = useContext(WizardOrderContext);
  const { isGroupExpanded, toggleGroup } = useContext(WizardExpandContext);
  const { getSelection } = useContext(WizardSelectionContext);

  const Item = SortableElement(({ page, group, field }) => <Field page={page} group={group} field={field} />);

  return (
    <div className={classNames(classes.group, getSelection()?.id === group.id && classes.selected)}>
      <div className={classes.header}>
        <Handle />
        <div className={classes.title}>{group.title || 'Unset'}</div>
        <ButtonGroup>
          <Button
            className={classes.actions}
            minimal
            icon={isGroupExpanded(group.id) ? 'chevron-up' : 'chevron-down'}
            onClick={() => toggleGroup(group.id)}
            disabled={!group.fields?.length}
          />
          <GroupMenu page={page} group={group} />
        </ButtonGroup>
      </div>
      {isGroupExpanded(group.id) && (
        <div className={classes.fields}>
          <Container useDragHandle onSortEnd={updates => reorderFields(updates, page.id, group.id)}>
            {group.fields?.map?.((field, i) => (
              <Item key={i} index={i} collection={`${page.id}-${group.id}`} page={page} group={group} field={field} />
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default Group;
