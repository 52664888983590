import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { Toaster } from '@blueprintjs/core';

import Avatar from '../../../../../components/avatar';
import Box from '../../../../../components/box';
import Table from '../../../../../components/table';
import FlowStatus from '../../../../../components/flow-status';

import useTable from '../../../../../hooks/use-table';
import { getOnboardingEngagements } from '../../../../../apis/client/engagements';
import { resendEngagementInvite } from '../../../../../apis/client/engagements';

import InviteButton from '../invite-button';

import classes from '../../dashboard.module.scss';

const OnboardingBox = ({ membership }) => {
  const navigate = useNavigate();
  const { items, loading, pageCount, page, goToPage } = useTable(getOnboardingEngagements, { pageSize: 5 });

  const onResend = async engagementId => {
    try {
      await resendEngagementInvite({ engagementId });
      Toaster.create({ position: 'top' }).show({ message: 'Engagement invite re-sent.', intent: 'success' });
    } catch (e) {}
  };

  return (
    <Box
      title={'Onboarding suppliers'}
      className={classes.onboardingBox}
      contentClassName={classes.onboarding}
      actions={
        <InviteButton
          organization={membership?.organization}
          onClick={d => navigate({ pathname: `/engagements/invite`, search: createSearchParams({ ...d, redirect: '/' }).toString() })}
        />
      }
      footerClassName={classes.footer}
      footer={<Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />}
    >
      <Table.Container
        data={items}
        loading={loading}
        handleScroll={false}
        showHeadings={false}
        dark
        emptyMessage="No onboarding suppliers, click [+] to send an invite."
      >
        <Table.Columns.Default
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d?.supplierName || d?.supplierManagerName} url={d?.supplierIcon} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName || d?.supplierManagerName, d => d?.supplierManagerEmail]} />
        <Table.Columns.TwoLines lines={[d => d?.clientName, d => d?.parentClientName]} />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.engagementTitle, d => `Starts on ${moment(d?.startDate).format('MMM Do, YYYY')}`]} />
        <Table.Columns.Default
          width="150px"
          render={d => (
            <FlowStatus
              flowType="engagement"
              reference={d}
              referenceId={d?.engagementId}
              flow={d?.flow}
              signatureContextProps={{ engagementId: d?.engagementId }}
              loading={loading}
              align="right"
            />
          )}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View engagement', href: d => `/engagements/${d.engagementId}` },
            {
              name: 'Copy & invite',
              href: d => {
                const searchParams = createSearchParams({ clientId: d?.clientId, engagementId: d?.engagementId, redirect: '/' }).toString();
                return `/engagements/invite?${searchParams}`;
              },
            },
            { divider: true },
            {
              name: 'Re-send invite',
              onClick: d => onResend(d.engagementId),
              disabled: d => !!d?.supplierManagerEmail,
            },
          ]}
        />
      </Table.Container>
    </Box>
  );
};

export default OnboardingBox;
