import React from 'react';

import GenericFieldContainer from './generic-field-container';
import SignatureFieldValue from './signature-field-value';
import QuestionFieldContainer from './question-field-container';

const FieldValueContainer = ({ instanceId, field, type, clientId, review, onComplete }) => {
  if (type === 'question') {
    return <QuestionFieldContainer field={field} clientId={clientId} review={review} onComplete={onComplete} />;
  }

  if (type === 'OT_SIGNATURE') {
    return <SignatureFieldValue instanceId={instanceId} field={field} clientId={clientId} review={review} onComplete={onComplete} />;
  }

  return <GenericFieldContainer field={field} clientId={clientId} review={review} onComplete={onComplete} />;
};

export default FieldValueContainer;
