import React from 'react';
import { Icon, Menu, MenuItem } from '@blueprintjs/core';

const EngagementsImportFilters = ({ values, filterCounter, setFieldValue, submitForm, resetFilters }) => {
  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;

  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCounter}
        onClick={() => resetFilters()}
        text="Clear Filters"
        label={!!filterCounter ? <Icon icon="cross" /> : null}
      />
      <MenuItem
        shouldDismissPopover={false}
        onClick={() => {
          setFieldValue('validated', !!values?.validated ? null : true);
          submitForm();
        }}
        label={tick(!!values?.validated)}
        text="Only Validated ?"
      />
      <MenuItem
        shouldDismissPopover={false}
        onClick={() => {
          setFieldValue('complete', !!values?.complete ? null : true);
          submitForm();
        }}
        label={tick(!!values?.complete)}
        text="Only Complete ?"
      />
    </Menu>
  );
};

export default EngagementsImportFilters;
