import React from 'react';
import { MenuItem } from '@blueprintjs/core';

import classes from './field-menu-item.module.scss';

const FieldMenuItem = ({ text, icon, items, isDisabled, onClick }) => {
  return (
    <MenuItem text={text} icon={icon}>
      {items.map(t => {
        const disabled = isDisabled?.(t);
        return (
          <MenuItem
            key={t.name}
            disabled={disabled}
            className={classes.fieldItem}
            onClick={() => onClick(t)}
            text={
              <div className={classes.info}>
                <div className={classes.name}>{t.label}</div>
                <div className={classes.description}>{t.description}</div>
              </div>
            }
            icon={t.icon}
          />
        );
      })}
    </MenuItem>
  );
};

export default FieldMenuItem;
