import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';

/**
 * These api call are meant to be used from src/containers/platform only !!!
 */

// Platform

export const uploadPlatformLogo = async ({ logo }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('logo', logo);
  const response = await Clients.Api.post(`ui/${organizationType}s/platform/logo`, data, { onUploadProgress });
  return response?.data;
};

export const uploadPlatformIcon = async ({ icon }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('icon', icon);
  const response = await Clients.Api.post(`ui/${organizationType}s/platform/icon`, data, { onUploadProgress });
  return response?.data;
};

// Clients

export const filterClients = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = {
    ...filters,
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/client${toQueryString(query)}`);
  return response?.data;
};

export const exportClients = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/client/export${toQueryString(query)}`);
  return response?.data;
};

export const getClients = async () => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/client/all`);
  return response?.data;
};

export const getClient = async ({ clientId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/client/${clientId}`);
  return response?.data;
};

export const getClientSub = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const { clientId, ...rest } = filters;
  const query = {
    ...rest,
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/client/${clientId}/sub${toQueryString(query)}`);
  return response?.data;
};

export const createClient = async client => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/client`, client);
  return response?.data;
};

export const updateClient = async updates => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/client`, updates);
  return response?.data;
};

export const removeClient = async clientId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/client/${clientId}`);
  return response?.data;
};

export const uploadClientLogo = async ({ clientId, logo }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('logo', logo);
  const response = await Clients.Api.post(`ui/${organizationType}s/client/${clientId}/logo`, data, { onUploadProgress });
  return response?.data;
};

export const uploadClientIcon = async ({ clientId, icon }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('icon', icon);
  const response = await Clients.Api.post(`ui/${organizationType}s/client/${clientId}/icon`, data, { onUploadProgress });
  return response?.data;
};

export const setAddress = async ({ clientId, placeId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/client/${clientId}/address`, { placeId });
  return response?.data;
};

export const getWorkLocations = async ({ clientId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/client/${clientId}/work-locations`);
  return response?.data;
};

export const addWorkLocation = async ({ clientId, description, placeId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/client/${clientId}/work-locations`, { clientId, description, placeId });
  return response?.data;
};

export const removeWorkLocation = async ({ clientId, workLocationId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/client/${clientId}/work-locations/${workLocationId}`);
  return response?.data;
};

export const sendAdHocEmailNotification = async payload => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/notification/ad-hoc`, payload);
  return response?.data;
};

export const getIntegrations = async ({ clientId, readable }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/client/${clientId}/integrations`, { params: { readable } });
  return response?.data;
};

export const createIntegration = async ({ clientId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/client/${clientId}/integrations`);
  return response?.data;
};

export const enableIntegration = async ({ clientId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/client/${clientId}/integrations/enable`);
  return response?.data;
};

export const disableIntegration = async ({ clientId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/client/${clientId}/integrations/disable`);
  return response?.data;
};

// Suppliers

export const uploadSupplierLogo = async ({ supplierId, logo }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('logo', logo);
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/${supplierId}/logo`, data, { onUploadProgress });
  return response?.data;
};

export const uploadSupplierIcon = async ({ supplierId, icon }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('icon', icon);
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/${supplierId}/icon`, data, { onUploadProgress });
  return response?.data;
};
