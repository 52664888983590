import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Form from '../../../../../../../../components/form';
import { TextField } from '../../../../../../../../components/fields';

import useApi from '../../../../../../../../hooks/use-api';
import { setUserPassword } from '../../../../../../../../apis/profile';

const UpdatePasswordForm = ({ isOpen = false, user, onClose, onSave }) => {
  const setUserPasswordApi = useApi(setUserPassword, { skip: true });

  const submit = async values => {
    const { userId, newPassword } = values;
    await setUserPasswordApi.request({ userId, password: newPassword });
    onSave();
  };

  const loading = setUserPasswordApi.loading;

  return (
    <Dialog title="Update Password" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ userId: user?.id, newPassword: '', repeatedPassword: '' }}
        validationSchema={Yup.object().shape({
          newPassword: Yup.string().required('New password is required'),
          repeatedPassword: Yup.string()
            .required('Please rewrite your new password')
            .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.'),
        })}
        onSubmit={submit}
      >
        <TextField fill type="password" label="New password" name="newPassword" />
        <TextField fill type="password" label="Re-type new password" name="repeatedPassword" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default UpdatePasswordForm;
