import React, { useState } from 'react';
import { createSearchParams, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import * as Icons from 'react-feather';
import { Button, Popover } from '@blueprintjs/core';

import Avatar from '../../../../../components/avatar';
import FlowStatus from '../../../../../components/flow-status';
import { getClientDisplayName } from '../../../../../utilities/client';
import { displayAllocation, displayEndDate, displayRate, displayStartDate } from '../../../../../utilities/engagement';

import useApi from '../../../../../hooks/use-api';
import { getEngagementTermination, getEngagementUpdate } from '../../../../../apis/supplier/engagements';

import EngagementTermination from '../termination';
import EngagementUpdates from '../updates';

import classes from './engagement.module.scss';

const Engagement = ({ engagement }) => {
  const [drawer, setDrawer] = useState({ open: false, type: undefined, data: undefined });
  const getEngagementTerminationApi = useApi(getEngagementTermination, { params: { engagementId: engagement?.id } });
  const getEngagementUpdatesApi = useApi(getEngagementUpdate, { params: { engagementId: engagement?.id } });

  if (!engagement) return null;

  const client = getClientDisplayName(engagement.clientName, engagement.parentClientName);

  const rate = displayRate(engagement.compensation?.amount, engagement.compensation?.currency?.symbol, engagement.compensation?.type);
  const allocation = displayAllocation(engagement.compensation?.allocation, engagement.compensation?.type);
  const payRate = !!allocation ? `${rate}, ${allocation}` : rate;

  const start = displayStartDate(engagement.startDate);
  const end = displayEndDate(getEngagementTerminationApi?.response?.endDate || engagement.endDate);

  return (
    <div className={classes.engagement}>
      <div className={classes.details}>
        <div className={classes.left}>
          <Avatar
            classOverrides={classes.client}
            abbreviate
            name={engagement.clientName || engagement.parentClientName}
            url={engagement.clientIcon}
            size={{ box: 60 }}
          />
        </div>
        <div className={classes.center}>
          <div className={classes.info}>
            <div className={classNames(classes.group, classes.main)}>
              <div className={classes.title}>{engagement.title}</div>
              <div>{client}</div>
            </div>
            <div className={classNames(classes.group)}>
              <div className={classes.label}>Pay Rate</div>
              <div>{payRate}</div>
              <div className={classes.space} />
              <div className={classes.label}>Manager</div>
              <div>{engagement.managerName || '-'}</div>
            </div>
            <div className={classNames(classes.group)}>
              <div className={classes.label}>Start Date</div>
              <div>{start}</div>
              <div className={classes.space} />
              <div className={classes.label}>End Date</div>
              <div>{end}</div>
            </div>
          </div>
        </div>

        <div className={classes.right}>
          {!!getEngagementUpdatesApi?.response && (
            <Button
              small
              outlined
              className={classes.cta}
              icon={<Icons.Play size={16} strokeWidth={1.5} />}
              title="Complete engagement updates flow"
              onClick={() => setDrawer({ open: true, type: 'updates', data: getEngagementUpdatesApi?.response })}
            />
          )}
          {!!getEngagementTerminationApi?.response && (
            <Button
              small
              outlined
              className={classes.cta}
              icon={<Icons.Play size={16} strokeWidth={1.5} />}
              title="Complete engagement offboarding"
              onClick={() => setDrawer({ open: true, type: 'termination', data: getEngagementTerminationApi?.response })}
            />
          )}
          {engagement?.status === 0 && (
            <NavLink
              to={{
                pathname: `/engagements/${engagement?.id}/onboarding`,
                search: createSearchParams({ clientId: engagement?.clientId }).toString(),
              }}
              role="button"
            >
              <Button small outlined className={classes.cta} icon={<Icons.Play size={16} strokeWidth={1.5} />} title="Complete engagement onboarding" />
            </NavLink>
          )}
          <Popover
            content={
              <div className={classes.description}>
                <p>
                  <strong>Engagement description</strong>
                </p>
                {engagement.description}
              </div>
            }
          >
            <Button small outlined icon={<Icons.Briefcase size={16} strokeWidth={1.5} />} title="Show engagement description" />
          </Popover>
        </div>
      </div>
      {engagement?.status === 0 && (
        <div className={classes.onboarding}>
          <div className={classes.info}>
            <div className={classes.title}>Engagement onboarding flow status</div>
            <FlowStatus flowType="engagement" reference={engagement} referenceId={engagement?.id} />
          </div>
        </div>
      )}
      <EngagementUpdates
        engagement={engagement}
        data={drawer?.data}
        open={!!drawer?.open && drawer?.type === 'updates'}
        onClose={() => setDrawer({ open: false, type: undefined })}
      />
      <EngagementTermination
        engagement={engagement}
        data={drawer?.data}
        open={!!drawer?.open && drawer?.type === 'termination'}
        onClose={() => setDrawer({ open: false, type: undefined })}
      />
    </div>
  );
};

export default Engagement;
