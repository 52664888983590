import moment from 'moment';

import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';

export const getInvoices = async ({ filters = {}, pageSize, page }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
    pageSize,
    page,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/invoice${toQueryString(parameters)}`);
  return response?.data;
};

export const getCsvData = async invoiceId => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/invoice/export/${invoiceId}`);
  return response?.data;
};
