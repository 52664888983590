import Clients from '../configuration/client';

export const getConfigurationRecordTypes = async ({ organizationId, targetOrganizationId = undefined }) => {
  const response = await Clients.Api.get('ui/configuration/types', { params: { organizationId, targetOrganizationId } });
  return response?.data;
};

export const getScopes = async ({ organizationId, includeInherited = false }) => {
  const response = await Clients.Api.get('ui/configuration/scopes', { params: { organizationId, includeInherited } });
  return response?.data;
};

export const createScope = async (organizationId, parentScopeId, placeId) => {
  const response = await Clients.Api.post('ui/configuration/scopes', { organizationId, parentScopeId, placeId });
  return response?.data;
};

export const removeScope = async scopeId => await Clients.Api.delete(`ui/configuration/scopes/${scopeId}`);

export const getRecords = async (scope, includeInherited = false) => {
  if (!scope) return;
  const response = await Clients.Api.get(`ui/configuration/scopes/${scope?.id}/records?includeInherited=${includeInherited}`);
  return response?.data;
};

export const getRecord = async recordId => {
  const response = await Clients.Api.get(`ui/configuration/records/${recordId}`);
  return response?.data;
};

export const createRecord = async record => await Clients.Api.post(`ui/configuration/records`, record);

export const createCountryRecord = async request => await Clients.Api.post(`ui/configuration/records/country`, request);

export const updateRecord = async (recordId, record) => await Clients.Api.put(`ui/configuration/records/${recordId}`, record);

export const removeRecord = async recordId => await Clients.Api.delete(`ui/configuration/records/${recordId}`);

export const getConfiguration = async (organizationId, type, userId = undefined) => {
  const response = await Clients.Api.get(`ui/configuration/${type}`, { params: { organizationId, userId } });
  return response?.data;
};

export const getConfigurationInScope = async (organizationId, type, scopeId) => {
  const response = await Clients.Api.get(`ui/configuration/${type}/${scopeId}`, { params: { organizationId } });
  return response?.data;
};

export const getConfigurationInCountry = async (organizationId, type, countryId) => {
  const response = await Clients.Api.get(`ui/configuration/${type}/country/${countryId}`, { params: { organizationId } });
  return response?.data;
};

export const getRecordValue = async recordId => {
  const response = await Clients.Api.get(`ui/configuration/records/${recordId}/value`);
  return response?.data;
};

export const getContext = async () => {
  const response = await Clients.Api.get(`ui/configuration/context`);
  return response?.data;
};

export const getConfigurationHierarchy = async ({ organizationId, type }) => {
  const response = await Clients.Api.get(`ui/configuration/hierarchy?organizationId=${organizationId}&type=${type}`);
  return response?.data;
};

export const getAppliedConfiguration = async ({ organizationId, type }) => {
  const response = await Clients.Api.get(`ui/configuration/applied?organizationId=${organizationId}&type=${type}`);
  return response?.data;
};

export const getUIConfiguration = async () => {
  const response = await Clients.Api.get(`ui/configuration/ui`);
  return response?.data;
};
