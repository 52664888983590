export const TrackingRecordStatusTypes = {
  Pending: 0,
  Submitted: 1,
  Approved: 2,
  Disputed: 3,
  Processed: 4,
};

export const TrackingRecordStatusOptions = [
  { label: 'Pending', value: TrackingRecordStatusTypes.Pending },
  { label: 'Submitted', value: TrackingRecordStatusTypes.Submitted },
  { label: 'Approved', value: TrackingRecordStatusTypes.Approved },
  { label: 'Disputed', value: TrackingRecordStatusTypes.Disputed },
  { label: 'Processed', value: TrackingRecordStatusTypes.Processed },
];

export const trackingRecordStatusToLabel = status => TrackingRecordStatusOptions.find(o => `${o.value}` === `${status}`)?.label || '-';

export const canBeEdited = status => {
  if (!status) {
    return true;
  }
  return status === TrackingRecordStatusTypes.Pending || status === TrackingRecordStatusTypes.Disputed;
};
