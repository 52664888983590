import React from 'react';

import ClientFees from '../../../../../components/organizational/client/fees';

import classes from '../../company-info.module.scss';

const Fees = ({ clientId }) => {
  return (
    <div className={classes.tab}>
      <ClientFees clientId={clientId} />
    </div>
  );
};

export default Fees;
