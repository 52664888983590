import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import { ObjectTypes } from '../../../../../../domain/types';
import { Operators } from '../../../../../../domain/operator-definitions';
import Fields from '../../../../../../../../fields';
import Form from '../../../../../../../../form';

import FieldSelection from './field-selection';
import getAvailableOperators from './get-available-operators';
import renderValueField from './render-field-value';
import { getRuleValue } from '../helper';

import classes from '../../custom-fields.module.scss';

function getButtonText({ rule, fields }) {
  if (rule?.field) {
    const field = fields[rule.field];
    const label = `${field?.label || field?.title} ${Operators[rule.operator]?.label} ${rule.value}`;
    return label;
  }

  const ruleValue = getRuleValue(rule);
  return ruleValue.simple ? 'Add condition' : 'Edit conditions';
}

const RuleBuilder = ({ rule, disabled, eligibleData, onClick, onUpdate, onRemove }) => {
  const [open, setOpen] = useState(false);
  const { fields, hasFields, pages, initialDataFields, categories } = eligibleData;

  const submit = _values => {
    onUpdate(_values);
    setOpen(false);
  };

  const handleButtonClick = () => {
    if (onClick) {
      // this is a callback function that is passed as a prop when the component is used as simple rule-builder builder
      onClick();
      return;
    }
    setOpen(true);
  };

  const text = getButtonText({ rule, fields });
  return (
    <div className={classes.ruleBuilderConditions}>
      <Popover
        isOpen={open}
        popoverClassName={classes.popover}
        content={
          <div className={classes.content}>
            <div className={classes.inner}>
              <Form
                initialValues={rule}
                validationSchema={Yup.object().shape({
                  field: Yup.string().required('Field is required'),
                  operator: Yup.string().required('Operator is required'),
                  value: Yup.mixed().when(['field', 'operator'], ([_field, _operator], schema, { value }) => {
                    const resolved = fields[_field];
                    const skipValue = [ObjectTypes.Boolean, ObjectTypes.Document].includes(resolved.type);
                    if (skipValue) {
                      return schema;
                    }

                    if (!value) {
                      return schema.required('Value is required');
                    }

                    const isSelect = [ObjectTypes.Select || ObjectTypes.MultiSelect].includes(resolved.type);
                    const isArrayOperator = [Operators.contains.value, Operators.doesNotContain.value].includes(_operator);
                    if (isSelect && isArrayOperator && value?.length < 1) {
                      return schema.required('Value is required');
                    }

                    return schema;
                  }),
                })}
                enableReinitialize
                onSubmit={submit}
              >
                {({ values }) => {
                  const selected = fields[values.field];

                  const availableOperators = getAvailableOperators({ fieldPath: values.field, fields });

                  return (
                    <React.Fragment>
                      <div className={classes.title}>Configure visibility condition</div>
                      <div className={classes.description}>
                        Select target <strong>field</strong>, logical <strong>operator</strong> and target <strong>value</strong> which satisfies the condition.
                      </div>
                      <FieldSelection
                        name="field"
                        selected={selected}
                        categories={categories}
                        pages={pages}
                        initialDataFields={initialDataFields}
                        disabled={!hasFields}
                      />
                      <Fields.Select label="Operator..." name="operator" options={availableOperators} disabled={!values.field} />
                      {renderValueField({ field: fields[values?.field], operator: values?.operator })}
                      <div className={classes.row}>
                        <Button outlined text="Submit" type="submit" />
                        <Button outlined text="Cancel" onClick={() => setOpen(false)} />
                      </div>
                    </React.Fragment>
                  );
                }}
              </Form>
            </div>
          </div>
        }
      >
        <div className={classes.conditionButtons}>
          <Button fill alignText="left" icon="small-plus" outlined text={text} disabled={disabled} onClick={handleButtonClick} />
          {onRemove && <Button icon={<Icons.Trash size={16} strokeWidth={1.5} />} outlined title="Remove Condition" onClick={onRemove} />}
        </div>
      </Popover>
    </div>
  );
};

export default RuleBuilder;
