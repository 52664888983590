import React from 'react';
import { SketchPicker } from 'react-color';
import { Button, Popover } from '@blueprintjs/core';

import { Field } from '../../form';

import styles from './color-field.module.scss';
const ColorField = ({ classes, name, validate, label, description, disabled = false, submitOnChange = false, outline, minimal, showError }) => {
  return (
    <Field
      classes={classes}
      name={name}
      label={label}
      description={description}
      validate={validate}
      disabled={disabled}
      outline={outline}
      minimal={minimal}
      showError={showError}
    >
      {({ field, form }) => {
        const value = field.value || '#75a535';
        const onChange = (color, _) => {
          form.setFieldValue(name, color.hex);

          if (!!submitOnChange) {
            void form.submitForm();
          }
        };

        return (
          <Popover fill content={<SketchPicker disableAlpha={true} presetColors={[]} color={value} onChange={onChange} />}>
            <Button fill className={styles.button} text={<span className={styles.indicator} style={{ backgroundColor: value }} />} />
          </Popover>
        );
      }}
    </Field>
  );
};

export default ColorField;
