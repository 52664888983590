import React from 'react';

import Dialog from '../../../components/dialog';
import GeneratePayrollForm from './forms/generate-payrolls';

const GeneratePayrollDialog = ({ onClose, open }) => {
  return (
    <Dialog isOpen={!!open} onClose={onClose} title="Generate payroll(s)">
      <div>
        <GeneratePayrollForm onComplete={onClose} />
      </div>
    </Dialog>
  );
};

export default GeneratePayrollDialog;
