import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Button, ButtonGroup, Classes, Tooltip } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import cn from 'classnames';

import Table from '../../../../table';
import Dialog from '../../../../dialog';

import useWindowSize from '../../../../../hooks/use-window-size';
import { isNextWeekDisabled, isPrevWeekDisabled, nextWeek, previousWeek } from '../../utilities';

import { canBeEdited, trackingRecordStatusToLabel } from '../../../../../domain/tracking-record-status-type';
import { removeTrackingRecord } from '../../../../../apis/supplier/time';
import { downloadDocument } from '../../../../../apis/documents';

import Comments from '../../../comments';
import { QuickTimekeepingContext } from '../../state';

import Day from '../day';
import DayDialog from '../day/day-dialog';
import DeliverableForm from '../forms/deliverable';
import ExpenseForm from '../forms/expense';

import classes from '../../quick.module.scss';
import classNames from 'classnames';

const Week = () => {
  const { isMobile } = useWindowSize();
  const { loading, weekdays, date, timeRecords, engagements, dialog, openDialog, closeDialog, changeDate, loadRecords } = useContext(QuickTimekeepingContext);
  const [expanded, setExpanded] = useState(undefined);

  const getRecordCurrency = record => engagements?.find?.(e => e.id === record?.engagementId)?.compensation?.currency?.symbol;
  const total = timeRecords?.shifts?.reduce?.((a, b) => a + moment.duration(b.duration).asMinutes(), 0) / 60;
  const totalLabel = total.toString().length > 5 ? total.toFixed(2) : total;

  const btnText = isMobile ? undefined : 'Add';
  const btnIcon = isMobile ? 'plus' : undefined;

  return (
    <React.Fragment>
      <div className={classes.week}>
        <div className={classes.heading}>
          <div className={cn(loading && Classes.SKELETON)}>{`Week of ${weekdays[0].format('MMM Do, YYYY')} - ${weekdays[6].format('MMM Do, YYYY')}`}</div>
          <div>
            <ButtonGroup className={classes.arrows}>
              <Button outlined minimal small icon="chevron-left" disabled={isPrevWeekDisabled(date)} onClick={() => changeDate(previousWeek(date))} />
              <Button outlined minimal small icon="chevron-right" disabled={isNextWeekDisabled(date)} onClick={() => changeDate(nextWeek(date))} />
            </ButtonGroup>
          </div>
        </div>
        <div className={cn(loading && classes.loading, classes.days)}>
          {weekdays.map((day, i) => (
            <Day key={i} day={day} loading={loading} isMobile={isMobile} />
          ))}
        </div>
        <div className={classes.total}>
          <div className={cn(loading && Classes.SKELETON, classes.label)}>Total Hours</div>
          <div className={cn(loading && Classes.SKELETON, classes.sum)}>{`${totalLabel}h`}</div>
        </div>
        {!loading && (
          <div className={classes.deliverables}>
            <div className={classes.header}>
              <Button
                minimal
                className={classes.control}
                icon={expanded === 'deliverables' ? <Icons.ChevronUp size={16} strokeWidth={1.5} /> : <Icons.ChevronDown size={16} strokeWidth={1.5} />}
                text={`Deliverables ${timeRecords?.deliverables?.length ? `(${timeRecords.deliverables.length})` : ''}`}
                onClick={() => setExpanded(expanded === 'deliverables' ? undefined : 'deliverables')}
                disabled={!timeRecords?.deliverables?.length}
              />
              <Button
                className={classNames(classes.quickButton, classes.add)}
                outlined
                icon={btnIcon}
                text={btnText}
                onClick={() => openDialog({ type: 'deliverables' })}
              />
            </div>
            {expanded === 'deliverables' && (
              <div className={classes.body}>
                <Table.Container data={timeRecords?.deliverables}>
                  <Table.Columns.Default showOnMobile width="90px" name="Date" render={d => moment(d?.date).format('L')} />
                  <Table.Columns.Bold showOnMobile width="120px" name="Amount" render={d => `${getRecordCurrency(d)}${d?.amount?.toFixed(2)}`} />
                  <Table.Columns.Default name="Notes" render={d => d?.notes} />
                  <Table.Columns.Default width="90px" name="Status" render={d => trackingRecordStatusToLabel(d?.status)} />
                  <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />
                  <Table.Columns.Actions
                    showOnMobile
                    actions={[
                      { name: 'Edit', onClick: d => openDialog({ type: 'deliverables', data: d }), disabled: d => !canBeEdited(d?.status) },
                      {
                        name: 'Remove',
                        onClick: async d => {
                          await removeTrackingRecord(d?.id);
                          void loadRecords();
                          if (timeRecords?.deliverables?.length <= 1) {
                            setExpanded(undefined);
                          }
                        },
                        disabled: d => !canBeEdited(d?.status),
                        confirmation: true,
                      },
                    ]}
                  />
                </Table.Container>
              </div>
            )}
          </div>
        )}
        {!!loading && (
          <div className={cn(loading && classes.loading, classes.days)}>
            {[moment(), moment()].map((day, i) => (
              <Day key={i} day={day} loading={loading} />
            ))}
          </div>
        )}
        {!loading && (
          <div className={classes.expenses}>
            <div className={classes.header}>
              <div className={classes.toggle}>
                <Button
                  minimal
                  className={classes.control}
                  icon={expanded === 'expenses' ? <Icons.ChevronUp size={16} strokeWidth={1.5} /> : <Icons.ChevronDown size={16} strokeWidth={1.5} />}
                  text={`Expenses ${timeRecords?.expenses?.length ? `(${timeRecords.expenses.length})` : ''}`}
                  onClick={() => setExpanded(expanded === 'expenses' ? undefined : 'expenses')}
                  disabled={!timeRecords?.expenses?.length}
                />
              </div>
              <Button
                className={classNames(classes.quickButton, classes.add)}
                outlined
                icon={btnIcon}
                text={btnText}
                onClick={() => openDialog({ type: 'expenses' })}
              />
            </div>
            {expanded === 'expenses' && (
              <div className={classes.body}>
                <Table.Container data={timeRecords?.expenses}>
                  <Table.Columns.Default showOnMobile width="90px" name="Date" render={d => moment(d?.date).format('L')} />
                  <Table.Columns.Bold showOnMobile width="120px" name="Amount" render={d => `${getRecordCurrency(d)}${d?.amount?.toFixed(2)}`} />
                  <Table.Columns.Default name="Notes" render={d => d?.notes} />
                  <Table.Columns.Default width="90px" name="Status" render={d => trackingRecordStatusToLabel(d?.status)} />
                  <Table.Columns.Default
                    width="32px"
                    render={d => (
                      <Tooltip content="Download receipt" disabled={!d?.receiptId}>
                        <Button
                          disabled={!d?.receiptId}
                          outlined
                          small
                          icon={<Icons.Paperclip size={16} strokeWidth={1.25} />}
                          onClick={() => {
                            if (d?.receiptId) {
                              void downloadDocument({ id: d?.receiptId, name: `Receipt-${d?.receiptId}` });
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  />
                  <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />
                  <Table.Columns.Actions
                    showOnMobile
                    actions={[
                      { name: 'Edit', onClick: d => openDialog({ type: 'expenses', data: d }), disabled: d => !canBeEdited(d?.status) },
                      {
                        name: 'Remove',
                        onClick: async d => {
                          await removeTrackingRecord(d?.id);
                          void loadRecords();
                          if (timeRecords?.expenses?.length <= 1) {
                            setExpanded(undefined);
                          }
                        },
                        disabled: d => !canBeEdited(d?.status),
                        confirmation: true,
                      },
                    ]}
                  />
                </Table.Container>
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog isOpen={dialog?.open && dialog?.type === 'deliverables'} title={dialog?.title} onClose={closeDialog}>
        <DeliverableForm
          week={date}
          data={dialog?.data}
          onComplete={() => {
            closeDialog();
            setExpanded('deliverables');
          }}
        />
      </Dialog>
      <Dialog isOpen={dialog?.open && dialog?.type === 'expenses'} title={dialog?.title} onClose={closeDialog}>
        <ExpenseForm
          week={date}
          data={dialog?.data}
          onComplete={() => {
            closeDialog();
            setExpanded('expenses');
          }}
        />
      </Dialog>
      <DayDialog />
    </React.Fragment>
  );
};

export default Week;
