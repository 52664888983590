import classNames from 'classnames';
import React, { useContext } from 'react';
import { setIn } from 'formik';
import { Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Fields from '../../../../../fields';

import { ObjectTypes } from '../../../domain/types';
import { getMaxDateValidationValue, getMinDateValidationValue } from '../../../domain/validation/date-validation';

import useApi from '../../../../../../hooks/use-api';
import { getWizardLookupData } from '../../../../../../apis/wizards';

import { WizardContext } from '../../../state';
import { WizardInstanceContext } from '../../../state/instance-provider';

import { getValue } from './renderers-helper';
import BankAccountDialog from './bank-account-dialog';
import ParagraphRenderer from './paragraph-renderer';
import SelectRenderer from './select-renderer';

import classes from './renderers.module.scss';

const FieldRenderer = ({ page, group, field, values, isMobile, formik }) => {
  const wizardContext = useContext(WizardContext);
  const instanceContext = useContext(WizardInstanceContext);
  const lookupApi = useApi(getWizardLookupData, { skip: true });

  const lookupProps = {
    remoteOptions: {
      loading: lookupApi.loading,
      request: () => lookupApi.request(field?.lookup?.type, wizardContext?.data),
      mapper: result => {
        if (!field.options) {
          return result;
        }
        const optionsMapper = field.options?.reduce((mapper, option) => {
          mapper[option.value] = option.label;
          return mapper;
        }, {});
        return result.map(r => ({
          label: optionsMapper[r.value] || r.label,
          value: r.value,
        }));
      },
      filter: 'local',
    },
  };
  const width = isMobile ? field.mobileWidth : field.width;
  const styles = { minWidth: `${width || 100}%`, maxWidth: `${width || 100}%` };

  return (
    <div className={classes.field} style={styles}>
      {renderField(page, group, field, lookupProps, wizardContext, instanceContext, values, formik)}
      {renderTooltip(field)}
    </div>
  );
};

const renderField = (page, group, field, lookupProps, wizardContext, instanceContext, values, formik) => {
  const name = `${page.id}.${group.id}.${field.id}`;
  const { type, ...fieldProps } = field;
  const props = { ...fieldProps, name, label: field?.showLabel ? field.label : '', description: !!field?.showDescriptionAsTooltip ? '' : field?.description };

  switch (type) {
    case ObjectTypes.Text:
      return fieldProps?.display === 'textarea' ? (
        <Fields.Textarea {...props} />
      ) : (
        <Fields.Text
          {...props}
          maskConfig={
            field.validation?.mask && {
              mask: field.validation?.mask,
              lazy: false,
            }
          }
        />
      );

    case ObjectTypes.Phone:
      const maskConfig = props.validation?.mask
        ? {
            mask: props.validation?.mask,
            lazy: false,
          }
        : undefined;
      return <Fields.Text {...props} maskConfig={maskConfig} />;

    case ObjectTypes.Number:
      return <Fields.Text type="number" {...props} />;

    case ObjectTypes.Select:
      return <SelectRenderer name={name} field={field} data={wizardContext.data} isDesigner={wizardContext.isDesigner} formik={formik} />;

    case ObjectTypes.MultiSelect:
      const multiSelectProps = !!field?.lookup?.type && !wizardContext?.isDesigner ? { ...props, ...lookupProps } : { ...props, options: props?.options || [] };
      return <Fields.MultiSelect {...multiSelectProps} />;

    case ObjectTypes.Boolean:
      return <Fields.Checkbox {...props} />;

    case ObjectTypes.Date: {
      const { min, max, cadence } = props.validation;
      const minTargetFieldValue = getValue(values, min?.field);
      const minDate = getMinDateValidationValue(min?.amount, min?.unit, minTargetFieldValue);
      const maxTargetFieldValue = getValue(values, max?.field);
      const maxDate = getMaxDateValidationValue(max?.amount, max?.unit, maxTargetFieldValue);

      return <Fields.Date {...props} minDate={minDate} maxDate={maxDate} cadence={cadence} />;
    }

    case ObjectTypes.DateRange: {
      const { min, max } = props.validation;
      const minTargetFieldValue = getValue(values, min?.field);
      const minDate = getMinDateValidationValue(min?.amount, min?.unit, minTargetFieldValue);
      const maxTargetFieldValue = getValue(values, max?.field);
      const maxDate = getMaxDateValidationValue(max?.amount, max?.unit, maxTargetFieldValue);
      return <Fields.DateRange {...props} minDate={minDate} maxDate={maxDate} showShortcuts={false} />;
    }

    case ObjectTypes.Time: {
      let min;
      if (props.validation.min) {
        min = props.validation.min?.split(':')[0];
      }
      let max;
      if (props.validation.max) {
        max = props.validation.max?.split(':')[0];
      }
      return <Fields.Time {...props} min={min} max={max} />;
    }

    case ObjectTypes.Document: {
      return (
        <Fields.Document
          {...props}
          required={props.validation?.required}
          expiration={props.validation?.expiration}
          owner={{ ownerType: 'wizard-instance', ownerId: wizardContext.instanceId }}
          readonly={wizardContext.isDesigner}
        />
      );
    }

    case ObjectTypes.Address:
      return <Fields.Address {...props} precision="address" />;

    case ObjectTypes.Signature:
      const signatures = getValue(values, name);
      return (
        <Fields.Signature
          {...props}
          placeholder={props.label}
          disabled={!props.target}
          readonly={wizardContext.isDesigner}
          contextData={{ ...(wizardContext?.data || {}), target: props.target, signatures }}
          eligibleSignatories={wizardContext?.eligibleSignatories}
          outline={false}
          minimal={true}
          onSign={signatories => void instanceContext.saveInstanceValues(setIn({ ...values }, name, signatories))}
        />
      );

    case ObjectTypes.Paragraph:
      return <ParagraphRenderer isDesigner={wizardContext.isDesigner} values={values} props={props} formik={formik} />;

    case ObjectTypes.Link:
      return (
        <a href={props?.url} target="_blank" className={classNames(classes.formLink, !!props.paddingBottom && classes.paddingBottom)} rel="noreferrer">
          {props?.text}
        </a>
      );

    case ObjectTypes.BankAccount:
      return (
        <BankAccountDialog
          label={props.label}
          name={props.name}
          countryId={props.countryId}
          owner={{
            organizationId: props.ownership === 'company' ? wizardContext.data?.organizationId : undefined,
            userId: props.ownership === 'individual' ? wizardContext.data?.userId : undefined,
          }}
          isDesigner={wizardContext.isDesigner}
        />
      );

    default:
      return props?.type;
  }
};

const renderTooltip = field => {
  if (!field?.description || !field?.showDescriptionAsTooltip) {
    return null;
  }

  return (
    <div className={classes.tooltip}>
      <Popover content={<div className={classes.descriptionPopover}>{field?.description}</div>}>
        <div className={classes.target}>
          <Icons.HelpCircle size={19} strokeWidth={1.5} />
        </div>
      </Popover>
    </div>
  );
};

export default FieldRenderer;
