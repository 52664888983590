import ActionsColumn from './columns/actions';
import DefaultColumn from './columns/default';
import BoldColumn from './columns/bold';
import TwoLinesColumn from './columns/two-lines';
import TableContainer from './container';
import TableFilters from './filters';
import TablePagination from './pagination';

const Table = {
  Container: TableContainer,
  Filters: TableFilters,
  Pagination: TablePagination,
  Columns: {
    Default: DefaultColumn,
    Bold: BoldColumn,
    Actions: ActionsColumn,
    TwoLines: TwoLinesColumn,
  },
};

export default Table;
