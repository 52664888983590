import React from 'react';
import { Button } from '@blueprintjs/core';
import { getIn } from 'formik';
import classNames from 'classnames';
import * as Icons from 'react-feather';

import RuleBuilder from '../rule-builder';

import classes from '../../custom-fields.module.scss';

const Rules = ({ prefix, index, values, errors, eligibleData, setFieldValue }) => {
  const combinator = getIn(values, `${prefix}combinator`);
  const rules = getIn(values, `${prefix}rules`);
  const isGroup = path => !!getIn(values, `${path}combinator`);
  const isRoot = !prefix;

  const addGroup = () => {
    const newRule = { combinator: 'AND', rules: [] };
    const newRules = [...rules, newRule];
    setFieldValue(`${prefix}rules`, newRules);
  };

  const setCombinator = option => {
    setFieldValue(`${prefix}combinator`, option);
  };

  const addRule = () => {
    const newRule = { field: '', value: '', operator: '' };
    const newRules = [...rules, newRule];
    setFieldValue(`${prefix}rules`, newRules);
  };

  const remove = index => {
    const newRules = rules?.filter((_, i) => i !== index);
    setFieldValue(`${prefix}rules`, newRules);
  };

  const removeGroup = () => {
    function removeLastSegment(path) {
      // Remove the trailing period if it exists
      if (path.endsWith('.')) {
        path = path.slice(0, -1);
      }

      // Find the last occurrence of "rules[1]" and remove it
      const lastSegmentIndex = path.lastIndexOf(`rules[${index}]`);
      if (lastSegmentIndex !== -1) {
        return path.substring(0, lastSegmentIndex - 1);
      }

      // Return an empty string if no segments found
      return '';
    }

    const parentName = removeLastSegment(prefix);
    const parent = getIn(values, parentName);
    const newParentRules = parent?.rules.filter((_, i) => i !== index);
    setFieldValue(`${parentName}rules`, newParentRules);
  };

  const update = (index, newRule) => {
    const newRules = rules?.map((r, i) => (i === index ? newRule : r));
    setFieldValue(`${prefix}rules`, newRules);
  };

  const isValid = listOfRules => listOfRules?.every?.(r => (!!r.field && !!r.operator) || (!!r?.combinator && isValid(r?.rules)));
  const invalid = !isValid(rules);
  const disabledAddGroup = invalid;
  const disabledRemoveGroup = isRoot;
  const disabledAddRule = invalid;

  return (
    <div className={classes.rulesGroup}>
      {isGroup(prefix) && (
        <div className={classes.rulesHeader}>
          <div className={classes.rulesCombinator}>
            <Button minimal text="AND" onClick={() => setCombinator('AND')} disabled={combinator === 'AND'} />
            <Button minimal text="OR" onClick={() => setCombinator('OR')} disabled={combinator === 'OR'} />
          </div>
          <div className={classes.rulesActions}>
            <Button
              icon={<Icons.FolderPlus size={16} strokeWidth={1.5} />}
              minimal
              title="Add condition group"
              onClick={addGroup}
              disabled={disabledAddGroup}
            />
            <Button
              icon={<Icons.FolderMinus size={16} strokeWidth={1.5} />}
              minimal
              title="Remove condition group"
              onClick={removeGroup}
              disabled={disabledRemoveGroup}
            />
            <Button icon={<Icons.Plus size={16} strokeWidth={1.5} />} outlined title="Add condition" onClick={addRule} disabled={disabledAddRule} />
          </div>
        </div>
      )}
      {!!rules?.length && (
        <div className={classes.conditions}>
          <div className={classNames(classes.floatingCombinator, combinator === 'AND' ? classes.and : classes.or)}>{combinator}</div>
          {rules?.map((rule, i) => (
            <React.Fragment key={i}>
              {!rule?.combinator && (
                <div className={classes.condition}>
                  <RuleBuilder
                    error={getIn(errors, `${prefix}rules[${i}]`)}
                    eligibleData={eligibleData}
                    rule={rule}
                    onUpdate={c => update(i, c)}
                    onRemove={() => remove(i)}
                  />
                </div>
              )}
              {isGroup(`${prefix}rules[${i}].`) && (
                <Rules eligibleData={eligibleData} index={i} prefix={`${prefix}rules[${i}].`} values={values} errors={errors} setFieldValue={setFieldValue} />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default Rules;
