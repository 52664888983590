import {
  IDENTITY_SET_AUTHENTICATED,
  IDENTITY_SET_CLAIMS,
  IDENTITY_SET_PROFILE,
  IDENTITY_CHANGE_ROLE,
  IDENTITY_UPDATE_SIGNATURE,
  IDENTITY_LOGOUT,
} from '../types';

export const setAuthenticated = () => ({ type: IDENTITY_SET_AUTHENTICATED, data: true });
export const setClaims = claims => ({ type: IDENTITY_SET_CLAIMS, data: claims });
export const setProfile = profile => ({ type: IDENTITY_SET_PROFILE, data: profile });
export const changeRole = role => ({ type: IDENTITY_CHANGE_ROLE, data: role });
export const logout = () => ({ type: IDENTITY_LOGOUT });
export const setSignatureSettings = signature => ({ type: IDENTITY_UPDATE_SIGNATURE, data: signature });
