import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import classes from './timeline.module.scss';

const TimelineItem = ({ engagement, step, loading, onExpand }) => {
  const status = step?.isComplete
    ? step?.updatedAt
      ? `Submitted on ${moment(step?.updatedAt).format('Do MMMM, YYYY, h:mm a')}.`
      : `Not yet submitted.`
    : step?.createdAt
      ? `Started on ${moment(step?.createdAt).format('Do MMMM, YYYY, h:mm a')}.`
      : `Not yet started.`;

  if (step?.type === 'engagement-duration') {
    return (
      <li>
        <div className={classNames(classes.step, classes.engagementDuration)}>
          <div className={classes.duration}>
            <span>~</span>
            <span>{(moment(step?.end).diff(moment(step?.start || engagement?.startDate), 'days', true) / 30).toFixed(2)}</span>
            <span>months</span>
          </div>
        </div>
      </li>
    );
  }

  return (
    <li className={classNames(step?.isComplete && classes.completeStep, step?.isCurrent && classes.pendingStep)}>
      <div className={classes.step}>
        <div className={classes.indicator}>
          {step?.isComplete ? (
            <Icons.CheckCircle size={19} strokeWidth={2.5} />
          ) : step?.isCurrent ? (
            <Icons.ArrowRightCircle size={19} strokeWidth={2.5} />
          ) : (
            <Icons.Circle size={19} strokeWidth={2.5} />
          )}
        </div>
        <div className={classes.heading}>
          <div className={classes.info}>
            <div className={classNames(classes.title, loading && Classes.SKELETON)}>{step?.name}</div>
            <div className={classNames(classes.subtitle, loading && Classes.SKELETON)}>
              {!!step?.instanceId ? moment(step?.updatedAt || step?.createdAt).format('Do MMMM, YYYY, h:mm a') : 'Pending'}
            </div>
          </div>
          <ButtonGroup>
            <Button
              className={classes.actions}
              small
              outlined
              title="(Re)View"
              icon={<Icons.ExternalLink size={16} strokeWidth={1.25} />}
              disabled={!step?.instanceId || loading}
              onClick={() => onExpand(true)}
            />
          </ButtonGroup>
        </div>
        <div className={classNames(classes.body, loading && Classes.SKELETON)}>
          <div className={classes.description}>{step.description}</div>
        </div>
        {!!step?.instanceId && !!step?.review?.isRequired && (
          <div className={classNames(classes.line, step?.review?.isRequired && !step?.review?.isComplete && classes.alert, loading && Classes.SKELETON)}>
            {step?.review?.isComplete ? `Review completed on ${moment(step?.review?.completedOn).format('Do MMMM, YYYY, h:mm a')}.` : `Requires review.`}
          </div>
        )}
        <div className={classNames(classes.line, loading && Classes.SKELETON)}>{status}</div>
        {step?.isComplete && (
          <div className={classNames(classes.line, loading && Classes.SKELETON)}>{`Collection started on ${moment(step?.createdAt).format(
            'Do MMMM, YYYY, h:mm a',
          )}.`}</div>
        )}
      </div>
    </li>
  );
};

export default TimelineItem;
