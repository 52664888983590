import React from 'react';
import { Route, Routes } from 'react-router-dom';

import TimeList from './standard';
import TimeReview from './review';

const Time = () => {
  return (
    <Routes>
      <Route path="/" Component={TimeList} />
      <Route path="/review" Component={TimeReview} />
    </Routes>
  );
};

export default Time;
