import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Yup from 'yup';
import moment from 'moment/moment';

import Dialog from '../../../dialog';
import Form from '../../../form';
import Fields from '../../../fields';

import { EngagementLocationType, EngagementLocationTypeOptions } from '../../../../domain/engagement-location-type';
import { EngagementStatusOptions, EngagementStatusType } from '../../../../domain/engagement-status-type';
import { ClassificationType, ClassificationTypeOptions } from '../../../../domain/classification-type';
import { ClassificationPreferenceType, ClassificationPreferenceTypeOptions } from '../../../../domain/classification-preference-type';
import { CompensationType, CompensationTypeOptions } from '../../../../domain/compensation-type';
import { CompensationFrequency, CompensationFrequencyOptions } from '../../../../domain/compensation-frequency';

import useApi from '../../../../hooks/use-api';
import { getClients } from '../../../../apis/platform/organizations';
import { getMembers } from '../../../../apis/members';
import { getWizardLookupData } from '../../../../apis/wizards';
import { getSupplierAddress } from '../../../../apis/platform/suppliers';
import { createNewEngagement } from '../../../../apis/platform/engagements';

import { getFullName } from '../../../../utilities/user';
import { mapCompensationTypeToPaymentLabel } from '../../../../utilities/engagement';

import classes from './new-engagement.module.scss';

const NewEngagement = ({ open, onClose, supplier }) => {
  const navigate = useNavigate();

  const clientsApi = useApi(getClients, { skip: true });
  const getMembersApi = useApi(getMembers, { skip: true });
  const lookupApi = useApi(getWizardLookupData, { skip: true });
  const getSupplierAddressApi = useApi(getSupplierAddress, { skip: true });
  const createNewEngagementApi = useApi(createNewEngagement, { skip: true });

  const submit = async data => {
    const locationId = data?.general?.locationType === EngagementLocationType.OnSite ? data?.general?.onsite : data?.general?.offsite;
    if (!locationId) return;

    const engagement = await createNewEngagementApi.request({ ...data, general: { ...data?.general, locationId } });

    if (!!engagement?.id) {
      onClose();
      navigate(`/engagements/${engagement?.id}`);
    }
  };

  const clientPreferences = ClassificationPreferenceTypeOptions.filter(c => {
    if (supplier?.classification === ClassificationType.Employee) {
      return c.value === ClassificationPreferenceType.EmployeeOnly;
    } else if (supplier?.classification === ClassificationType.IndependentContractor) {
      return c.value === ClassificationPreferenceType.IndependentContractorOnly;
    }
    return true;
  });
  const supplierPreferences = ClassificationTypeOptions.filter(c => c.value === supplier?.classification);
  const classificationResults = ClassificationTypeOptions.filter(c => c.value === supplier?.classification);

  return (
    <Dialog isOpen={open} onClose={onClose} title="Create new engagement">
      <div className={classes.newEngagement}>
        <Form
          onSubmit={submit}
          initialValues={{
            supplierId: supplier?.id,
            general: { title: '', description: '', startDate: undefined, endDate: undefined, status: EngagementStatusType.Active, locationType: undefined },
            classification: {
              clientPreference: clientPreferences.length === 1 ? clientPreferences[0].value : undefined,
              supplierPreference: supplierPreferences.length === 1 ? supplierPreferences[0].value : undefined,
              result: classificationResults.length === 1 ? classificationResults[0].value : undefined,
            },
            compensation: {
              type: undefined,
              currencyId: undefined,
              frequency: undefined,
              allocation: undefined,
              amount: undefined,
              tracksWork: true,
              isExempt: false,
            },
          }}
          validationSchema={Yup.object().shape({
            clientId: Yup.string().required('Client is required'),
            hiringManagerId: Yup.string().required('Hiring manager is required'),
            general: Yup.object().shape({
              title: Yup.string().required('Title is required'),
              description: Yup.string().required('Description is required'),
              startDate: Yup.string().required('Start date is required'),
              status: Yup.string().required('Status is required'),
              locationType: Yup.string().required('Location type is required'),
              onsite: Yup.string().when('locationType', {
                is: EngagementLocationType.OnSite,
                then: schema => schema.required('Work location is required'),
              }),
              offsite: Yup.string().when('locationType', {
                is: EngagementLocationType.OffSite,
                then: schema => schema.required('Work location is required'),
              }),
            }),
            classification: Yup.object().shape({
              result: Yup.string().required('Classification result is required'),
              clientPreference: Yup.string().required('Client preference result is required'),
              supplierPreference: Yup.string().required('Supplier preference result is required'),
            }),
            compensation: Yup.object().shape({
              type: Yup.string().required('Payment type is required'),
              currencyId: Yup.string().required('Currency is required'),
              frequency: Yup.string().required('Frequency is required'),
              allocation: Yup.number().moreThan(0, 'Time allocation is required'),
              amount: Yup.number().moreThan(0, 'Amount is required'),
            }),
          })}
          enableReinitialize={true}
        >
          {form => {
            return (
              <React.Fragment>
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.hRow}>
                    <Fields.Select
                      name="clientId"
                      label="Client"
                      disabled={!!form?.values?.clientId}
                      remoteOptions={{
                        loading: clientsApi.loading,
                        request: clientsApi.request,
                        mapper: result => result.map(c => ({ value: c?.id, label: c?.name })),
                        filter: 'local',
                      }}
                    />
                    {!!form?.values?.clientId && (
                      <Button className={classes.changeClientButton} outlined icon="edit" onClick={() => form.setFieldValue('clientId', undefined)} />
                    )}
                  </div>

                  {!!form?.values?.clientId && (
                    <React.Fragment>
                      <Fields.Text name="general.title" label="Title" />
                      <Fields.Textarea name="general.description" label="Description" />
                      <Fields.Select
                        name="hiringManagerId"
                        label="Hiring manager"
                        remoteOptions={{
                          loading: getMembersApi.loading,
                          request: (query = '') =>
                            getMembersApi.request({ filters: { organizationId: form?.values?.clientId, includeParentMembers: true, query } }),
                          mapper: result => result?.data?.map(m => ({ label: getFullName(m.user), value: m.id })),
                          filter: 'remote',
                        }}
                      />
                      <Fields.Select name="general.status" label="Status" options={EngagementStatusOptions} />
                      <div className={classes.hRow}>
                        <Fields.Date
                          fill
                          label="Start date"
                          name="general.startDate"
                          maxDate={form.values.general?.endDate ? moment(form.values.general?.endDate).startOf('day').toDate() : undefined}
                        />
                        <Fields.Date
                          fill
                          label="End date"
                          name="general.endDate"
                          minDate={form.values.general?.startDate ? moment(form.values.general?.startDate).startOf('day').toDate() : undefined}
                        />
                      </div>
                      <p>Work Location</p>
                      <Fields.Select
                        name="general.countryId"
                        label="Country"
                        remoteOptions={{
                          loading: lookupApi.loading,
                          request: () => lookupApi.request('client-available-countries', { clientId: form?.values?.clientId }),
                          mapper: result => result,
                          filter: 'local',
                        }}
                      />
                      <div className={classes.hRow}>
                        <Fields.Select
                          name="general.locationType"
                          label="Location type"
                          options={EngagementLocationTypeOptions}
                          disabled={!!form?.values?.general?.locationType}
                        />
                        {!!form?.values?.general?.locationType && (
                          <Button
                            className={classes.changeClientButton}
                            outlined
                            icon="edit"
                            onClick={() => form.setFieldValue('general.locationType', undefined)}
                          />
                        )}
                      </div>
                      {form?.values?.general?.locationType === EngagementLocationType.OnSite && (
                        <Fields.Select
                          name="general.onsite"
                          label="Work location"
                          remoteOptions={{
                            loading: lookupApi.loading,
                            request: () => lookupApi.request('client-work-locations', { clientId: form?.values?.clientId }),
                            mapper: result => result,
                            filter: 'local',
                          }}
                        />
                      )}
                      {form?.values?.general?.locationType === EngagementLocationType.OffSite && (
                        <Fields.Select
                          name="general.offsite"
                          label="Work location"
                          remoteOptions={{
                            loading: getSupplierAddressApi.loading,
                            request: () => getSupplierAddressApi.request({ supplierId: supplier?.id }),
                            mapper: address => [{ label: address.prettyName, value: address.id }],
                            filter: 'local',
                          }}
                        />
                      )}

                      <p>Classification</p>
                      <div className={classes.hRow}>
                        <Fields.Select label="Client preference" options={clientPreferences} name="classification.clientPreference" />
                        <Fields.Select label="Supplier preference" options={supplierPreferences} name="classification.supplierPreference" />
                      </div>
                      <Fields.Select label="Classification Result" options={classificationResults} name="classification.result" />

                      <p>Compensation</p>
                      <div className={classes.hRow}>
                        <Fields.Select label="Payment type" options={CompensationTypeOptions} name="compensation.type" />
                        <Fields.Select
                          label="Frequency"
                          options={CompensationFrequencyOptions.filter(f => {
                            if (form.values.compensation?.type === CompensationType.Monthly) {
                              return f.value !== CompensationFrequency.Weekly;
                            } else if (form.values.compensation?.type === CompensationType.Weekly) {
                              return f.value !== CompensationFrequency.Monthly;
                            }
                            return true;
                          })}
                          name="compensation.frequency"
                        />
                      </div>
                      <div className={classes.hRow}>
                        <Fields.Text
                          label="Amount"
                          extra={{
                            rightElement: <Button text={mapCompensationTypeToPaymentLabel(form?.values?.compensation?.type, true)} minimal small disabled />,
                          }}
                          placeholder="Amount"
                          type="number"
                          name="compensation.amount"
                        />
                        <Fields.Select
                          label="Currency"
                          name="compensation.currencyId"
                          remoteOptions={{
                            loading: lookupApi.loading,
                            request: () => lookupApi.request('client-available-currencies', { clientId: form?.values?.clientId }),
                            mapper: result => result,
                            filter: 'local',
                          }}
                        />
                      </div>
                      <div className={classes.hRow}>
                        <Fields.Text
                          label="Time allocation"
                          extra={{ rightElement: <Button text="h/week" minimal small disabled /> }}
                          type="number"
                          name="compensation.allocation"
                        />
                        <Fields.Select
                          label="Tracks work ?"
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          name="compensation.tracksWork"
                        />
                      </div>
                      {form?.values?.classification?.result === ClassificationType.Employee && (
                        <Fields.Select
                          label="Is exempt ?"
                          options={[
                            { value: true, label: 'Supplier is exempt employee.' },
                            { value: false, label: 'Supplier is non-exempt employee.' },
                          ]}
                          name="compensation.isExempt"
                        />
                      )}
                    </React.Fragment>
                  )}
                </SimpleBar>
                <div className={classes.actions}>
                  <Button outlined fill type="submit" text="Submit" disabled={!form.isValid} />
                </div>
              </React.Fragment>
            );
          }}
        </Form>
      </div>
    </Dialog>
  );
};

export default NewEngagement;
