import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';

export const getSupplier = async supplierId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier/${supplierId}`);
  return response?.data;
};

export const uploadSupplierLogo = async ({ supplierId, logo }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('logo', logo);
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/${supplierId}/logo`, data, { onUploadProgress });
  return response?.data;
};

export const uploadSupplierIcon = async ({ supplierId, icon }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('icon', icon);
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/${supplierId}/icon`, data, { onUploadProgress });
  return response?.data;
};

export const setSupplierAddress = async ({ supplierId, placeId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/supplier/${supplierId}/address`, { placeId });
  return response?.data;
};

export const updateSupplier = async supplier => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/supplier/${supplier.id}`, supplier);
  return response?.data;
};

export const getSupplierNoticeBoard = async () => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier/${organizationId}/notice-board`);
  return response?.data;
};
