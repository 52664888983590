import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, ButtonGroup, Classes, Popover } from '@blueprintjs/core';
import ReactJson from 'react-json-view';
import classNames from 'classnames';

import WithConfirmation from '../../hooks/with-confirmation';

import classes from './audit.module.scss';

const AuditItem = ({ revision, loading, isCurrentVersion, onSelect }) => {
  const [expanded, setExpanded] = useState(false);

  if (loading) {
    return (
      <div className={classes.auditItem}>
        <div className={classes.top}>
          <div className={classes.info}>
            <div className={classNames(Classes.SKELETON)}>.</div>
          </div>
          <div className={classes.actions}>
            <div className={classNames(Classes.SKELETON)}>.</div>
          </div>
        </div>
      </div>
    );
  }

  const renderInfo = () => {
    if (!revision) return null;

    const date = moment.utc(revision?.date);
    const timestamp = date.format('[on] MM/DD/YYYY [at] h:mm a');
    const user = revision.user ? `${revision.user}, ` : '';
    const ipAddress = revision?.ipAddress && revision?.ipAddress !== '::1' ? revision?.ipAddress : null;

    return (
      <React.Fragment>
        <strong>{user}</strong>
        <br />
        <span>{`${timestamp}. `}</span>
        <span>{ipAddress}</span>
      </React.Fragment>
    );
  };

  const changes = !revision.changes ? [{ key: 'Record Created.' }] : !revision.changes.length ? [{ key: 'Record metadata updates.' }] : revision.changes;

  return (
    <div className={classes.auditItem}>
      <div className={classes.top}>
        <div className={classes.info}>
          <div className={classes.line}>{renderInfo()}</div>
        </div>
        <div className={classes.actions}>
          <ButtonGroup>
            <Button minimal icon={expanded ? 'collapse-all' : 'expand-all'} onClick={() => setExpanded(!expanded)} />
            <Popover content={<ReactJson style={{ padding: '15px' }} src={revision.data} enableClipboard={false} />}>
              <Button minimal icon="code" />
            </Popover>
            <WithConfirmation messages={{ question: `Are you sure you want to restore this record to selected point in time ?`, confirmButton: 'Restore' }}>
              <Button minimal icon="undo" disabled={isCurrentVersion} onClick={() => onSelect(revision)} />
            </WithConfirmation>
          </ButtonGroup>
        </div>
      </div>
      {expanded && (
        <div className={classes.changes}>
          {changes?.map?.(({ key, first, second }, index) => (
            <div key={index} className={classes.change}>
              <div className={classes.key}>{_.startCase(key)}</div>
              {first && (
                <div title={first} className={classes.first}>
                  {first}
                </div>
              )}
              {second && (
                <div title={second} className={classes.second}>
                  {second}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AuditItem;
