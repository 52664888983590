import React, { createRef, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import Page from '../../../../../components/page';

import useApi from '../../../../../hooks/use-api';

import Configurators from '../../../../../components/configurators';
import { getInstanceFlow, removeFlow } from '../../../../../apis/wizards';

import NextStep from './next-step';

import classes from './invite.module.scss';

const Invite = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const clientId = searchParams.get('clientId');
  const initializeFromEngagementId = searchParams.get('engagementId');
  const redirectTo = searchParams.get('redirect');

  const [progress, setProgress] = React.useState({ status: 'in-progress', engagementId: initializeFromEngagementId });
  const wizardInstanceId = createRef();
  const removeInstanceApi = useApi(removeFlow, { skip: true });
  const onBeforeUnload = useRef(ev => {
    if (wizardInstanceId.current) {
      void removeInstanceApi.request({ instanceId: wizardInstanceId.current });
      wizardInstanceId.current = null;
    }
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload.current);
      onBeforeUnload.current = null;

      if (wizardInstanceId.current) {
        void removeInstanceApi.request({ instanceId: wizardInstanceId.current });
        wizardInstanceId.current = null;
      }
    };
  }, []);

  const path = [
    {
      name: 'Invite',
    },
  ];

  if (redirectTo.startsWith('/engagements')) {
    path.unshift({ name: 'Engagements', to: redirectTo });
  } else if (redirectTo.startsWith('/evaluations')) {
    path.unshift({ name: 'Evaluations', to: redirectTo });
  }

  return (
    <Page title="Invite Supplier" scroll={false} path={path}>
      <div className={classNames(classes.details)}>
        {progress.status === 'in-progress' && (
          <Configurators.FlowLoader
            flowType={'engagement'}
            referenceId={clientId}
            data={{ clientId, initializeFromEngagementId: progress.engagementId }}
            initialize
            canContinue={false}
            onCreate={response => {
              wizardInstanceId.current = response.wizardInstanceId;
              window.addEventListener('beforeunload', onBeforeUnload.current);
            }}
            onCancel={() => {
              void removeInstanceApi.request({ instanceId: wizardInstanceId.current });
              wizardInstanceId.current = null;
              navigate(redirectTo);
            }}
            onComplete={async () => {
              const response = await getInstanceFlow(wizardInstanceId.current);
              wizardInstanceId.current = null;
              if (!response.error) {
                setProgress({ status: 'completed', engagementId: response.referenceId });
              }
            }}
          />
        )}
        {progress.status === 'completed' && <NextStep setProgress={setProgress} onDone={() => navigate(redirectTo)} />}
      </div>
    </Page>
  );
};

export default Invite;
