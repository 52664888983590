import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import { getColor } from '../../utilities/common';
import { BoxItem, BoxItemContainer, BoxItemLabel, BoxItemValue, BoxTabularItem } from './box-item';

import classes from './box.module.scss';

const Box = ({
  title,
  actions,
  children,
  className,
  headerClassName,
  titleClassName,
  contentClassName,
  footerClassName,
  footer,
  titleStyle = {},
  icon = undefined,
  expandible = undefined,
  monochrome = false,
  dark = false,
  outlined = false,
  small = false,
  style = {},
  loading = false,
  onClick,
}) => {
  const [expanded, setExpanded] = useState(expandible === undefined ? true : !!expandible?.expanded);

  const handleClick = () => {
    if (!!expandible) {
      setExpanded(!expanded);
    }
    onClick?.();
  };

  useEffect(() => {
    if (expandible?.expanded !== undefined && expandible?.expanded !== null && expandible?.expanded !== expanded) {
      setExpanded(expandible?.expanded);
    }
  }, [expandible?.expanded]);

  return (
    <div
      className={classNames(
        classes.box,
        dark && classes.dark,
        outlined && classes.outlined,
        small && classes.small,
        !!expandible && classes.expandible,
        className,
      )}
      style={style}
    >
      {!!title && (
        <div className={classNames(classes.header, headerClassName)}>
          <div
            className={classNames(classes.title, titleClassName)}
            style={monochrome ? { ...titleStyle } : { color: getColor(title), ...titleStyle }}
            onClick={handleClick}
          >
            {!!expandible && (
              <div className={classes.expandControl}>
                {expanded ? <Icons.ChevronUp size={16} strokeWidth={1.5} /> : <Icons.ChevronDown size={16} strokeWidth={1.5} />}
              </div>
            )}
            {icon}
            {title}
          </div>
          {!!actions && !loading && <div>{actions}</div>}
        </div>
      )}
      {!loading && expanded && <div className={classNames(classes.content, contentClassName)}>{children}</div>}
      {!!loading && expanded && (
        <div className={classNames(classes.content)}>
          {[1, 2, 3, 4, 5].map(i => (
            <div className={classes.row} key={i}>
              <div className={classNames(Classes.SKELETON, classes.label)}>loading</div>
              <div className={classNames(Classes.SKELETON, classes.value)}>loading</div>
            </div>
          ))}
        </div>
      )}
      {!!footer && expanded && <div className={classNames(classes.footer, !!footerClassName && footerClassName)}>{footer}</div>}
    </div>
  );
};

Box.Item = BoxItem;
Box.ItemContainer = BoxItemContainer;
Box.ItemLabel = BoxItemLabel;
Box.ItemValue = BoxItemValue;
Box.TabularItem = BoxTabularItem;

export default Box;
