import { ElementTypes } from '../enumerations/field-definitions';
import { DEFAULT_FOOTER_TEXT, DEFAULT_LOGO } from '../enumerations/constants';

const convertCamelCaseToDashCase = camel => {
  return camel.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
};

const recursiveTemplateFormat = (array, content) => {
  if (!content?.length) {
    return array;
  }

  content.forEach(contentItem => {
    const style = Object.keys(contentItem.styleConfig)
      .reduce((styles, name) => {
        const value = contentItem.styleConfig[name];
        if (value) {
          const nameInDashedCase = convertCamelCaseToDashCase(name);
          styles.push(`${nameInDashedCase}:${value}`);
        }
        return styles;
      }, [])
      .join(';');

    const formattedContentItem = {
      ...contentItem,
      style,
      // style: `${style};${contentItem.customStyle || ''}`,
      // customStyle: undefined,
    };
    array.push(formattedContentItem);
    formattedContentItem.content = recursiveTemplateFormat([], contentItem.content);
  });

  return array;
};

const createMetadata = ({ subject = '', logo, footer, class: className = '', style = '', content = [] }) => ({
  subject,
  logo: logo || DEFAULT_LOGO,
  footer: footer || DEFAULT_FOOTER_TEXT,
  render: {
    style,
    body: {
      elementType: ElementTypes.DIV,
      class: className,
      content,
    },
  },
});

const createOrUpdateMetadata = (metadata, content) => {
  if (!metadata) {
    return createMetadata({ content });
  }

  return {
    subject: metadata.subject,
    logo: metadata.logo,
    footer: metadata.footer,
    render: {
      style: metadata.render?.style,
      body: {
        elementType: ElementTypes.DIV,
        class: metadata.render?.body?.class,
        content: content || metadata.render?.body.content,
      },
    },
  };
};

const createConfig = template => {
  return {
    name: template.name,
    description: template.description,
    subject: template.metadata.subject,
    logo: template.metadata.logo,
    footer: template.metadata.footer,
    style: template.metadata.render.style,
    class: template.metadata.render.body.class,
  };
};

const create = (template, content) => {
  const formattedContent = recursiveTemplateFormat([], content);
  return {
    id: template.id,
    name: template.name,
    description: template.description,
    contextTypeName: template.contextTypeName.toLowerCase(),
    refName: template.refName,
    contentType: template.contentType,
    templateData: template.templateData,
    status: template.status,
    type: template.type,
    metadata: createOrUpdateMetadata(template.metadata, formattedContent),
  };
};

const templateFactory = {
  create,
  createMetadata,
  createOrUpdateMetadata,
  createConfig,
};

export default templateFactory;
