import React from 'react';
import { Button } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import * as Yup from 'yup';
import * as Icons from 'react-feather';

import Dialog from '../dialog';
import Fields from '../fields';
import Form from '../form';
import Spinner from '../spinner';

import useApi from '../../hooks/use-api';
import { getResourceDocuments } from '../../apis/documents';

import classes from './resource-files.module.scss';

const filter = (record, inputValue) => !inputValue || record?.toLowerCase?.()?.includes(inputValue?.toLowerCase?.());
const parseName = path => path.substring(path.lastIndexOf('/') + 1);
const getResources = async value => {
  const resources = await getResourceDocuments();
  if (!value) {
    return resources;
  }
  const filtered = resources.filter(record => record !== value);
  filtered.unshift(value); // selected image will be always on top of the list
  return filtered;
};

const ResourceDialog = ({ current, open, onClose, onSubmit, extensions }) => {
  const api = useApi(() => getResources(current));

  const initialValues = React.useMemo(() => {
    const initial = { query: '' };
    if (current && api.response) {
      initial.resource = api.response?.find(r => r === current);
    }
    return initial;
  }, [api.response, current]);

  return (
    <Dialog title="Resources" isOpen={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup.object().shape({
          resource: Yup.string().required('Please select resource.'),
        })}
        onSubmit={values => {
          onSubmit(values.resource);
          onClose();
        }}
      >
        {({ values, setFieldValue }) => {
          const resources = api.response?.filter?.(record => filter(record, values?.query));
          return (
            <React.Fragment>
              <div className={classes.search}>
                <Fields.Text type="text" name="query" placeholder="Search" outline={false} minimal={true} showError={false} />
              </div>
              <div className={classes.dialog}>
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.container}>
                    {api.loading ? (
                      <Spinner blend global={false} />
                    ) : api.response.length === 0 ? (
                      <div className={classes.message}>
                        <div className={classes.regular}>No resources found.</div>
                      </div>
                    ) : (
                      resources.map(record => {
                        const filename = parseName(record);
                        const extension = filename.substring(filename.lastIndexOf('.') + 1);
                        const match = !extensions?.length || extensions.includes(extension);
                        return (
                          <div
                            className={classNames(classes.row, !match && classes.disabled, record === values.resource && classes.active)}
                            onClick={() => match && setFieldValue('resource', record === values.resource ? undefined : record)}
                            key={record}
                          >
                            <div className={classes.controls}>
                              {values?.resource === record ? <Icons.CheckCircle className={classes.active} /> : <Icons.Circle />}
                            </div>
                            <div className={classes.thumbnail}>
                              <div className={match ? classes.image : classes.extension} style={{ backgroundImage: match ? `url(${record})` : undefined }}>
                                {match ? '' : extension}
                              </div>
                            </div>
                            <div className={classes.info}>
                              <div className={classes.name}>{filename}</div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </SimpleBar>
              </div>
              <Button type="submit" outlined fill text="Submit" />
            </React.Fragment>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default ResourceDialog;
