import React from 'react';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';

import classes from './rich-text.module.scss';

const RecursiveMenu = ({ item, onClick }) => {
  if (item.items?.length) {
    return (
      <MenuItem text={item.label}>
        {item.items.map(o => (
          <RecursiveMenu item={o} key={o.name} onClick={onClick} />
        ))}
      </MenuItem>
    );
  }

  return <MenuItem text={item.label} onClick={() => onClick(item)} />;
};

const RichTextContextMenu = ({ children, options, onSelect }) => {
  if (!options) {
    return children;
  }

  const content = (
    <Menu>
      {options?.map(o => (
        <RecursiveMenu item={o} onClick={onSelect} key={o.name} />
      ))}
    </Menu>
  );

  return (
    <ContextMenu className={classes.contextMenu} content={content}>
      {children}
    </ContextMenu>
  );
};

export default RichTextContextMenu;
