import React, { useContext } from 'react';
import { Button, Drawer } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

import { WizardSelectionContext } from '../../state/selection-provider';
import { TypeDefinitions } from '../../domain/types';
import PropertiesForm from './forms';

import classes from './properties.module.scss';

const Properties = () => {
  const { getSelection, clearSelection } = useContext(WizardSelectionContext);
  const selection = getSelection();
  const { type, title, label } = selection || {};
  const typeLabel = !type ? '' : Object.values(TypeDefinitions).find(v => v.name === type)?.label;

  return (
    <div className={classes.properties}>
      <Drawer isOpen={selection} position="left" usePortal={false} size={300} className={classes.drawer} onClose={clearSelection}>
        <div className={classes.header}>
          <div className={classes.main}>
            <div className={classes.title}>{title || label}</div>
            <div className={classes.description}>{typeLabel} properties</div>
          </div>
          <Button minimal icon="cross" onClick={clearSelection} />
        </div>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.content}>
            <PropertiesForm />
          </div>
        </SimpleBar>
      </Drawer>
    </div>
  );
};

export default Properties;
