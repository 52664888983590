import React, { useState } from 'react';
import moment from 'moment';
import { Button, Drawer, Menu, MenuItem, Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Form from '../../../../components/form';
import Fields from '../../../../components/fields';

import InvoiceSupplier from './invoice-supplier';

import classes from './details.module.scss';

const InvoiceDetails = ({ onClose, open, invoice, reloadList, onExport }) => {
  const [query, setQuery] = useState('');
  const { currency, period, periodType, client, invoiceSuppliers } = invoice || {};
  const filterInvoices = invoice => invoice?.supplierName?.toLowerCase()?.includes(query?.toLowerCase());

  const closeDrawer = () => {
    setQuery('');
    onClose();
  };

  const renderTitle = () =>
    periodType === 'weekly'
      ? `${client?.name} - ${currency?.name} - Week ${moment(period).format('Do MMMM')} - ${moment(period).add(6, 'days').format('Do MMMM YYYY')}`
      : `${client?.name} - ${currency?.name} - ${moment(period).format('MMMM YYYY')}`;

  return (
    <Drawer isOpen={!!open} onClose={closeDrawer} className={classes.details} position="right" canEscapeKeyClose canOutsideClickClose>
      <div className={classes.header}>
        <div className={classes.title}>{renderTitle()}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={closeDrawer} />
      </div>
      <div className={classes.controls}>
        <Form initialValues={{ query }} enableReinitialize onSubmit={({ query }) => setQuery(query)}>
          <Fields.Text
            name="query"
            placeholder="Search..."
            submitOnChange
            outline={false}
            minimal={true}
            showError={false}
            extra={{ rightElement: !!query && <Button icon="small-cross" minimal small onClick={() => setQuery('')} /> }}
          />
        </Form>
        <Popover
          content={
            <Menu>
              <MenuItem text="Export" label=".csv" onClick={onExport} />
            </Menu>
          }
        >
          <Button outlined icon={<Icons.MoreVertical size={16} />} />
        </Popover>
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          {invoiceSuppliers?.filter?.(filterInvoices)?.map?.((supplier, index) => (
            <InvoiceSupplier key={index} {...{ invoice, supplier, currency, reloadList }} />
          ))}
        </div>
      </SimpleBar>
      <div className={classes.footer}>
        <div className={classes.label}>Total</div>
        <div className={classes.value} title={invoice?.total}>{`${currency?.symbol}${Number.parseFloat(`${invoice?.total || 0}`).toFixed(2)}`}</div>
      </div>
    </Drawer>
  );
};

export default InvoiceDetails;
