import React from 'react';
import { useParams } from 'react-router';

import { EmailConfiguratorProvider } from './context';
import EmailConfigurator from './email-configurator';

const Email = () => {
  const { id } = useParams();
  return (
    <EmailConfiguratorProvider id={id}>
      <EmailConfigurator />
    </EmailConfiguratorProvider>
  );
};

export default Email;
