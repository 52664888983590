import { useState, useCallback, useEffect } from 'react';
import { Classes, Tree } from '@blueprintjs/core';
import { cloneDeep } from 'lodash';

import { uuid } from '../../../../../../../utilities/common';
import SimpleBar from 'simplebar-react';

function getTreeNodes({ macros, value }) {
  const mapItemsToNodes = items => {
    return items.map(item => {
      const id = item.id || uuid();
      if (item.items?.length) {
        const childNodes = mapItemsToNodes(item.items, id);
        const isExpanded = childNodes.some(node => node.isExpanded || node.active);
        return {
          id,
          label: item.label,
          childNodes,
          isExpanded,
        };
      }

      const active = item.name === value;
      return {
        id,
        disabled: item.disabled,
        active,
        label: item.label,
        nodeData: item,
        className: [active && Classes.TREE_NODE_SELECTED].join(' '),
      };
    });
  };

  return mapItemsToNodes(macros);
}

function forNodeAtPath(nodes, path, callback) {
  callback(Tree.nodeFromPath(path, nodes));
}

const SelectMacro = ({ macros, value, handleClick }) => {
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    setNodes(getTreeNodes({ macros, value }));
  }, [value]);

  const handleNodeCollapse = useCallback(
    (_node, nodePath) => {
      const newNodes = cloneDeep(nodes);
      forNodeAtPath(newNodes, nodePath, node => (node.isExpanded = false));
      setNodes(newNodes);
    },
    [nodes],
  );

  const handleNodeClick = useCallback(
    (node, nodePath, e) => {
      if (!node.nodeData) {
        node.isExpanded ? handleNodeCollapse(node, nodePath) : handleNodeExpand(node, nodePath);
        return;
      }
      handleClick(node.nodeData);
    },
    [nodes, handleClick],
  );

  const handleNodeExpand = useCallback(
    (_node, nodePath) => {
      const newNodes = cloneDeep(nodes);
      forNodeAtPath(newNodes, nodePath, node => (node.isExpanded = true));
      setNodes(newNodes);
    },
    [nodes],
  );

  return (
    <SimpleBar>
      <Tree contents={nodes} onNodeClick={handleNodeClick} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
    </SimpleBar>
  );
};

export default SelectMacro;
