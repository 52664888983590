import React from 'react';
import { Button, Classes, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import classes from './with-confirmation.module.scss';

const WithConfirmation = ({ captureDismiss = true, messages = {}, children, fill = true, icon = true, disabled = false }) => {
  const components = React.Children.toArray(children);
  if (components?.length !== 1) throw new Error('Unsupported number of child components.');

  const confirmedAction = components?.[0]?.props?.onClick;
  const { title = 'Are you sure ?', question, rejectButton = 'Cancel', confirmButton = 'Confirm' } = messages || {};
  const actionProps = { onClick: undefined };
  if (components?.[0]?.type?.displayName === MenuItem.displayName) {
    actionProps.tagName = 'button';
  }
  const action = React.cloneElement(components?.[0], actionProps);

  return (
    <Popover
      disabled={disabled}
      fill={fill}
      minimal
      interactionKind="click"
      captureDismiss={captureDismiss}
      popoverClassName={classes.popover}
      content={
        <div className={classes.withConfirmation}>
          {!!icon && (
            <div className={classes.icon}>
              <Icons.AlertTriangle size={70} strokeWidth={0.5} />
            </div>
          )}
          <div className={classes.title}>{title}</div>
          {question && <div className={classes.question}>{question}</div>}
          <div className={classes.buttons}>
            <Button className={Classes.POPOVER_DISMISS} outlined text={rejectButton} />
            <Button className={Classes.POPOVER_DISMISS} outlined intent="danger" onClick={confirmedAction} text={confirmButton} />
          </div>
        </div>
      }
    >
      {action}
    </Popover>
  );
};

export default WithConfirmation;
