import { fitBounds } from 'google-map-react';

export const getBoundsRectangle = map => {
  return new window.google.maps.Rectangle({
    clickable: false,
    fillOpacity: 0,
    strokeColor: '#FF0000',
    strokeOpacity: 1,
    strokeWeight: 1,
    map,
  });
};

export const formatViewportBounds = viewportBounds => ({
  north: viewportBounds.northeast.lat,
  south: viewportBounds.southwest.lat,
  east: viewportBounds.northeast.lng,
  west: viewportBounds.southwest.lng,
});

export const getMapSize = map => ({ width: map?.getDiv().offsetWidth, height: map?.getDiv().offsetHeight });

export const fitMapBounds = (viewportBounds, size) => fitBounds({ ne: viewportBounds.northeast, sw: viewportBounds.southwest }, size);
