import { Button, ButtonGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { FieldArray } from 'formik';
import React, { useContext } from 'react';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import useApi from '../../../../../../../hooks/use-api';

import { createRecord, updateRecord } from '../../../../../../../apis/configuration';
import { getCurrencies } from '../../../../../../../apis/lookups';
import Form from '../../../../../../form';
import { SystemContext } from '../../../../system-provider';

import classes from './available-currencies.module.scss';

const AvailableCurrencies = ({ record, onClose, loading, setLoading, api, data }) => {
  const currenciesApi = useApi(getCurrencies);
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    const payload = { ...values, data: { currencies: values.data?.currencies?.join(',') } };
    setLoading(true);
    if (payload?.id) {
      await updateRecord(values?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <Form
      enableReinitialize
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        ...record,
        data: { currencies: !!data?.currencies ? data?.currencies.split(',') : [] },
      }}
      onSubmit={submit}
    >
      {form => {
        const disabled = loading || currenciesApi.loading;
        return (
          <div className={classes.currencies}>
            {!!form?.values?.data?.currencies?.length && (
              <div className={classes.header}>
                <div>Country</div>
              </div>
            )}
            <FieldArray name="data.currencies">
              {({ remove, push }) => (
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.container}>
                    {form.values.data.currencies?.map((c, i) => {
                      const ccc = currenciesApi.response?.find(currency => currency.id === c);
                      return (
                        <div className={classes.row} key={c}>
                          <div className={classes.currency}>
                            {ccc?.name} ({ccc?.id})
                          </div>
                          <div className={classes.removeButton}>
                            <Button icon="small-minus" outlined type="button" onClick={() => remove(i)} />
                          </div>
                        </div>
                      );
                    })}
                    <ButtonGroup>
                      <Popover
                        content={
                          <Menu>
                            <SimpleBar style={{ maxHeight: '50vh' }}>
                              {currenciesApi.response?.map(currency => {
                                const selected = form.values.data.currencies?.includes(currency.id);
                                return (
                                  <MenuItem
                                    key={currency.id}
                                    disabled={selected}
                                    onClick={() => {
                                      if (!selected) {
                                        push(currency.id);
                                      }
                                    }}
                                    text={`(${currency.symbol}) ${currency.name}`}
                                  />
                                );
                              })}
                            </SimpleBar>
                          </Menu>
                        }
                      >
                        <Button outlined fill icon={<Icons.Plus size={16} strokeWidth={1.5} />} text={'Add New'} disabled={!form.isValid} />
                      </Popover>
                    </ButtonGroup>
                  </div>
                </SimpleBar>
              )}
            </FieldArray>
            <Button type="submit" outlined fill text="Submit" disabled={disabled} loading={loading} />
          </div>
        );
      }}
    </Form>
  );
};

export default AvailableCurrencies;
