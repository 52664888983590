import React from 'react';
import cn from 'classnames';
import { TextArea as BlueprintTextArea } from '@blueprintjs/core';

import styles from './textarea-field.module.scss';

const Textarea = ({ classes, disabled, value, type, placeholder, onChange, maskConfig, extra = {}, inputRef }) => {
  const handleChange = e => {
    let value = e.target.value;
    onChange(value);
  };

  return (
    <BlueprintTextArea
      disabled={disabled}
      className={cn(styles.textareaField, classes?.input)}
      placeholder={placeholder}
      type={type}
      {...extra}
      autoResize={false}
      value={value ? value : maskConfig ? undefined : ''}
      onChange={handleChange}
      inputRef={inputRef}
      onKeyDown={e => e.stopPropagation()}
    />
  );
};

export default Textarea;
