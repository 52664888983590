import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import classes from './page.module.scss';

const PageLoading = () => (
  <div className={classNames(classes.page, Classes.SKELETON)}>
    <div className={classes.header}>
      <div>page</div>
    </div>
  </div>
);

export default PageLoading;
