import React, { useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Table from '../../../../table';
import Dialog from '../../../../dialog';

import { organizationTypeToLabel } from '../../../../../domain/organization-type';

import useApi from '../../../../../hooks/use-api';
import { getAppliedConfiguration, removeRecord } from '../../../../../apis/configuration';

import EngagementMetadataForm from './engagement-metadata-form';
import { ClientConfigurationType } from '../enumerations';

import classes from '../policies.module.scss';
import WithConfirmation from '../../../../../hooks/with-confirmation';

const mapType = type => {
  switch (type) {
    case 'text':
      return 'text-highlight';

    case 'number':
      return 'calculator';

    default:
      return '';
  }
};

const EngagementMetadataPolicy = ({ client, globalScope }) => {
  const [isOpen, setIsOpen] = useState(false);
  const getEngagementMetadataApi = useApi(() => getAppliedConfiguration({ organizationId: client?.id, type: ClientConfigurationType.EngagementMetadata }), {
    skip: !client?.id,
  });
  const removeRecordApi = useApi(removeRecord, { skip: true });

  const record = getEngagementMetadataApi.response?.find(r => r.organizationId === client?.id) || getEngagementMetadataApi.response?.[0];
  const fields = record ? JSON.parse(record?.data?.schema) : undefined;

  const handleRemove = async () => {
    await removeRecordApi.request(record?.recordId);
    getEngagementMetadataApi.setResponse([]);
  };

  return (
    <Box
      title="Engagement metadata"
      outlined
      monochrome
      actions={
        <ButtonGroup>
          <WithConfirmation
            captureDismiss={false}
            messages={{
              question: `Are you sure you want to remove engagement metadata configuration record?`,
            }}
          >
            <Button
              small
              outlined
              loading={removeRecordApi.loading}
              icon={<Icons.Trash size={16} strokeWidth={1.5} />}
              title="Delete engagement metadata"
              disabled={!record}
              onClick={handleRemove}
            />
          </WithConfirmation>
          <Button
            small
            outlined
            icon={<Icons.Settings size={16} strokeWidth={1.5} />}
            title="Setup engagement metadata"
            disabled={!globalScope}
            onClick={() => setIsOpen(true)}
          />
        </ButtonGroup>
      }
      loading={getEngagementMetadataApi.loading}
    >
      <Box.ItemContainer>
        <div>
          <p>
            Specify custom engagement metadata. This data will be available within engagement details. It can be used for filtering or be exported. Metadata can
            be provided by:
          </p>
          <ul>
            <li>Client staff, during supplier invite process.</li>
            <li>Supplier, during engagement onboarding flow.</li>
          </ul>
          <strong>Note:</strong> Changing metadata schema <strong>will not</strong> automatically propagate to already existing engagements.
        </div>
      </Box.ItemContainer>
      <Box.ItemContainer className={classes.table}>
        <Table.Container data={fields} loading={getEngagementMetadataApi.loading} showHeadings={false} dark emptyMessage="No metadata configured.">
          <Table.Columns.Default
            showOnMobile
            width="2fr"
            name="Field"
            render={d => <Button minimal alignText="left" className={classes.typeButton} text={d?.name} icon={mapType(d?.type)} />}
          />
          <Table.Columns.TwoLines showOnMobile lines={[d => organizationTypeToLabel(d?.providedBy), _ => 'provides the value.']} />
          <Table.Columns.Default name="Example" render={d => d?.example} />
          {record?.organizationId !== client?.id && <Table.Columns.Default render={_ => '(inherited)'} />}
        </Table.Container>
      </Box.ItemContainer>
      <Dialog isOpen={isOpen} title="Engagement metadata" onClose={() => setIsOpen(false)}>
        {isOpen && (
          <EngagementMetadataForm
            scopeId={globalScope?.id}
            record={record?.organizationId !== client?.id ? {} : record}
            onSave={() => {
              void getEngagementMetadataApi.request();
              setIsOpen(false);
            }}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default EngagementMetadataPolicy;
