import _ from 'lodash';

import Field from '../domain/field';
import Group from '../domain/group';
import Page from '../domain/page';
import { ObjectTypes } from '../domain/types';
import Wizard from '../domain/wizard';

export const initializeTemplateFromContext = descriptor => {
  const data = new Wizard({
    title: descriptor?.name,
    description: descriptor?.description,
    refName: descriptor?.refName,
    contextTypeName: descriptor?.contextTypeName,
    categories: [],
    pages: [],
  });

  data.pages = setupContent(descriptor);

  return data;
};

const setupContent = descriptor => {
  if (!descriptor?.children?.length) {
    return [];
  }

  const pages = descriptor.children?.filter?.(c => !c?.isInitialData)?.map?.(setupPages);
  return pages;
};

const setupPages = child => {
  if (child?.type !== 'object') {
    return new Page({
      title: _.startCase(child?.name),
      groups: [
        new Group({
          title: _.startCase(child?.name),
          fields: [setupFields(child)],
        }),
      ],
    });
  }

  if (!!child?.metadata) {
    return new Page({
      title: _.startCase(child?.name),
      groups: [new Group({ title: _.startCase(child?.name), mandatory: true, metadata: child?.metadata })],
    });
  }

  if (child?.children?.length) {
    const groupedFields = child?.children?.filter?.(c => c.type === 'object');
    const nonGroupedFields = child?.children?.filter?.(c => c.type !== 'object');

    let groups = groupedFields?.map?.(setupGroups);
    if (!!nonGroupedFields?.length) {
      groups = [...groups, new Group({ fields: nonGroupedFields?.map?.(setupFields) })];
    }

    return new Page({ title: _.startCase(child?.name), groups });
  }

  return [];
};

const setupGroups = child => {
  const fields = extractFields(child);
  return new Group({ title: _.startCase(child?.name), fields: fields.map(setupFields) });
};

const setupFields = child => {
  const typeProps = getFieldTypeProps(child);
  return new Field({
    label: _.startCase(child?.name),
    ...typeProps,
    validation: { required: !!child?.required },
    mandatory: true, // default backend fields
    initiallyRequired: !!child.required, // used to indicate if backend marked it as required or it was frontend decision.
    path: child?.path,
  });
};

const extractFields = child => {
  const nonGroupedFields = child?.children?.filter?.(c => c.type !== 'object') || [];
  const groupedFields = child?.children?.filter?.(c => c.type === 'object') || [];

  let fields = [];
  groupedFields.forEach(gf => (fields = [...fields, ...extractFields(gf)]));

  return [...nonGroupedFields, ...fields];
};

export const getFieldTypeProps = child => {
  let props = {};
  switch (child?.baseType) {
    case 'number':
      props = { type: ObjectTypes.Number };
      break;

    case 'bool':
      props = { type: ObjectTypes.Boolean };
      break;

    case 'datetime':
      props = { type: ObjectTypes.Date };
      break;

    case 'location':
      props = { type: ObjectTypes.Address };
      break;

    case 'bankAccount':
      props = { type: ObjectTypes.BankAccount, ownership: child?.ownerType };
      break;

    case 'char':
    case 'string':
    case 'object':
    default:
      props = { type: ObjectTypes.Text };
      break;
  }

  if (child?.lookup || child?.options) {
    return { ...props, options: child?.options?.map?.(o => ({ value: o, label: o })), lookup: child?.lookup, type: ObjectTypes.Select };
  }

  return props;
};
