import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Button, Drawer } from '@blueprintjs/core';
import moment from 'moment';

import useTable from '../../hooks/use-table';
import useApi from '../../hooks/use-api';
import Table from '../table';
import Dialog from '../dialog';

import { getRevisions, revert } from '../../apis/audit';

import AuditItem from './audit-item';

import classes from './audit.module.scss';

const AuditContainer = ({ type, id, isRendered, isOpen, toggleDrawer }) => {
  const [selected, setSelected] = useState();
  const { items, loading, pageCount, page, goToPage } = useTable(filters => getRevisions({ type, id, ...filters }), { pageSize: 10 });
  const revertApi = useApi(revert, { skip: true });

  const onRevertConfirm = async () => {
    await revertApi.request(type, selected?.data?.id, selected?.revisionId);
    setSelected();
    toggleDrawer();
    window.location.reload();
  };

  if (!isRendered) return null;

  return (
    <Drawer position="right" isOpen={isOpen} className={classes.audit} onClose={toggleDrawer}>
      <div className={classes.header}>
        <div className={classes.title}>Revisions</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={toggleDrawer} />
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          {loading
            ? [0, 1, 2, 3, 4].map(i => <AuditItem key={i} loading={true} />)
            : items.map((revision, index) => <AuditItem key={index} revision={revision} onSelect={setSelected} isCurrentVersion={index === 0} />)}
        </div>
      </SimpleBar>
      <div className={classes.footer}>
        <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      </div>
      <Dialog title="Revert to older revision" isOpen={!!selected} onClose={() => setSelected()}>
        <div className={classes.revertDialog}>
          <div className={classes.title}>Are you sure you want to apply changes from {moment.utc(selected?.date).format('LLL')} ?</div>
          <div className={classes.revision}>{selected?.revisionId}</div>
        </div>
        <Button fill outlined text="Submit" disabled={revertApi.loading} loading={revertApi.loading} onClick={onRevertConfirm} />
      </Dialog>
    </Drawer>
  );
};

export default AuditContainer;
