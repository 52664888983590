import React, { useState } from 'react';
import Form, { Field } from '../../form';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import TextField from '../text';

import styles from './timezone.module.scss';

const TimezoneField = ({ classes, name, label, description, placeholder, validate }) => {
  const [query, setQuery] = useState('');

  return (
    <Field classes={classes} label={label} description={description} name={name} validate={validate}>
      {({ field, form }) => {
        const filtered = Timezones.filter(t => t.name.toLowerCase().includes(query.toLowerCase) || t.id.toLowerCase().includes(query.toLowerCase()));
        const selected = Timezones.find(t => t.id === field.value);
        const placeholderText = selected?.name || placeholder || 'Select timezone...';
        return (
          <Popover
            fill
            popoverClassName={styles.popover}
            content={
              <Menu>
                <div className={styles.search}>
                  <Form initialValues={{ query }} onSubmit={v => setQuery(v.query)}>
                    <TextField name="query" placeholder="Search..." submitOnChange outline={false} minimal={true} showError={false} />
                  </Form>
                </div>
                <SimpleBar className={styles.wrapper}>
                  {filtered.map(tz => (
                    <MenuItem
                      key={tz.id}
                      text={tz.name}
                      onClick={() => {
                        form.setFieldValue(name, tz.id);
                        setQuery('');
                      }}
                      label={selected?.id === tz.id ? <Icons.Check size={16} strokeWidth={1.5} /> : null}
                    />
                  ))}
                </SimpleBar>
              </Menu>
            }
          >
            <Button className={styles.button} text={placeholderText} fill alignText="left" icon="globe" />
          </Popover>
        );
      }}
    </Field>
  );
};

const Timezones = [
  {
    id: 'Dateline Standard Time',
    name: '(UTC-12:00) International Date Line West',
    offset: '-12:00',
  },
  {
    id: 'UTC-11',
    name: '(UTC-11:00) Coordinated Universal Time-11',
    offset: '-11:00',
  },
  {
    id: 'Aleutian Standard Time',
    name: '(UTC-10:00) Aleutian Islands',
    offset: '-10:00',
  },
  {
    id: 'Hawaiian Standard Time',
    name: '(UTC-10:00) Hawaii',
    offset: '-10:00',
  },
  {
    id: 'Marquesas Standard Time',
    name: '(UTC-09:30) Marquesas Islands',
    offset: '-09:30',
  },
  {
    id: 'Alaskan Standard Time',
    name: '(UTC-09:00) Alaska',
    offset: '-09:00',
  },
  {
    id: 'UTC-09',
    name: '(UTC-09:00) Coordinated Universal Time-09',
    offset: '-09:00',
  },
  {
    id: 'Pacific Standard Time (Mexico)',
    name: '(UTC-08:00) Baja California',
    offset: '-08:00',
  },
  {
    id: 'UTC-08',
    name: '(UTC-08:00) Coordinated Universal Time-08',
    offset: '-08:00',
  },
  {
    id: 'Pacific Standard Time',
    name: '(UTC-08:00) Pacific Time (US & Canada)',
    offset: '-08:00',
  },
  {
    id: 'US Mountain Standard Time',
    name: '(UTC-07:00) Arizona',
    offset: '-07:00',
  },
  {
    id: 'Mountain Standard Time (Mexico)',
    name: '(UTC-07:00) La Paz, Mazatlan',
    offset: '-07:00',
  },
  {
    id: 'Mountain Standard Time',
    name: '(UTC-07:00) Mountain Time (US & Canada)',
    offset: '-07:00',
  },
  {
    id: 'Yukon Standard Time',
    name: '(UTC-07:00) Yukon',
    offset: '-07:00',
  },
  {
    id: 'Central America Standard Time',
    name: '(UTC-06:00) Central America',
    offset: '-06:00',
  },
  {
    id: 'Central Standard Time',
    name: '(UTC-06:00) Central Time (US & Canada)',
    offset: '-06:00',
  },
  {
    id: 'Easter Island Standard Time',
    name: '(UTC-06:00) Easter Island',
    offset: '-06:00',
  },
  {
    id: 'Central Standard Time (Mexico)',
    name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    offset: '-06:00',
  },
  {
    id: 'Canada Central Standard Time',
    name: '(UTC-06:00) Saskatchewan',
    offset: '-06:00',
  },
  {
    id: 'SA Pacific Standard Time',
    name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    offset: '-05:00',
  },
  {
    id: 'Eastern Standard Time (Mexico)',
    name: '(UTC-05:00) Chetumal',
    offset: '-05:00',
  },
  {
    id: 'Eastern Standard Time',
    name: '(UTC-05:00) Eastern Time (US & Canada)',
    offset: '-05:00',
  },
  {
    id: 'Haiti Standard Time',
    name: '(UTC-05:00) Haiti',
    offset: '-05:00',
  },
  {
    id: 'Cuba Standard Time',
    name: '(UTC-05:00) Havana',
    offset: '-05:00',
  },
  {
    id: 'US Eastern Standard Time',
    name: '(UTC-05:00) Indiana (East)',
    offset: '-05:00',
  },
  {
    id: 'Turks And Caicos Standard Time',
    name: '(UTC-05:00) Turks and Caicos',
    offset: '-05:00',
  },
  {
    id: 'Paraguay Standard Time',
    name: '(UTC-04:00) Asuncion',
    offset: '-04:00',
  },
  {
    id: 'Atlantic Standard Time',
    name: '(UTC-04:00) Atlantic Time (Canada)',
    offset: '-04:00',
  },
  {
    id: 'Venezuela Standard Time',
    name: '(UTC-04:00) Caracas',
    offset: '-04:00',
  },
  {
    id: 'Central Brazilian Standard Time',
    name: '(UTC-04:00) Cuiaba',
    offset: '-04:00',
  },
  {
    id: 'SA Western Standard Time',
    name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    offset: '-04:00',
  },
  {
    id: 'Pacific SA Standard Time',
    name: '(UTC-04:00) Santiago',
    offset: '-04:00',
  },
  {
    id: 'Newfoundland Standard Time',
    name: '(UTC-03:30) Newfoundland',
    offset: '-03:30',
  },
  {
    id: 'Tocantins Standard Time',
    name: '(UTC-03:00) Araguaina',
    offset: '-03:00',
  },
  {
    id: 'E. South America Standard Time',
    name: '(UTC-03:00) Brasilia',
    offset: '-03:00',
  },
  {
    id: 'SA Eastern Standard Time',
    name: '(UTC-03:00) Cayenne, Fortaleza',
    offset: '-03:00',
  },
  {
    id: 'Argentina Standard Time',
    name: '(UTC-03:00) City of Buenos Aires',
    offset: '-03:00',
  },
  {
    id: 'Montevideo Standard Time',
    name: '(UTC-03:00) Montevideo',
    offset: '-03:00',
  },
  {
    id: 'Magallanes Standard Time',
    name: '(UTC-03:00) Punta Arenas',
    offset: '-03:00',
  },
  {
    id: 'Saint Pierre Standard Time',
    name: '(UTC-03:00) Saint Pierre and Miquelon',
    offset: '-03:00',
  },
  {
    id: 'Bahia Standard Time',
    name: '(UTC-03:00) Salvador',
    offset: '-03:00',
  },
  {
    id: 'UTC-02',
    name: '(UTC-02:00) Coordinated Universal Time-02',
    offset: '-02:00',
  },
  {
    id: 'Greenland Standard Time',
    name: '(UTC-02:00) Greenland',
    offset: '-02:00',
  },
  {
    id: 'Mid-Atlantic Standard Time',
    name: '(UTC-02:00) Mid-Atlantic - Old',
    offset: '-02:00',
  },
  {
    id: 'Azores Standard Time',
    name: '(UTC-01:00) Azores',
    offset: '-01:00',
  },
  {
    id: 'Cape Verde Standard Time',
    name: '(UTC-01:00) Cabo Verde Is.',
    offset: '-01:00',
  },
  {
    id: 'UTC',
    name: '(UTC) Coordinated Universal Time',
    offset: '00:00',
  },
  {
    id: 'GMT Standard Time',
    name: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    offset: '00:00',
  },
  {
    id: 'Greenwich Standard Time',
    name: '(UTC+00:00) Monrovia, Reykjavik',
    offset: '00:00',
  },
  {
    id: 'Sao Tome Standard Time',
    name: '(UTC+00:00) Sao Tome',
    offset: '00:00',
  },
  {
    id: 'Morocco Standard Time',
    name: '(UTC+01:00) Casablanca',
    offset: '00:00',
  },
  {
    id: 'W. Europe Standard Time',
    name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    offset: '01:00',
  },
  {
    id: 'Central Europe Standard Time',
    name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    offset: '01:00',
  },
  {
    id: 'Romance Standard Time',
    name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    offset: '01:00',
  },
  {
    id: 'Central European Standard Time',
    name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    offset: '01:00',
  },
  {
    id: 'W. Central Africa Standard Time',
    name: '(UTC+01:00) West Central Africa',
    offset: '01:00',
  },
  {
    id: 'GTB Standard Time',
    name: '(UTC+02:00) Athens, Bucharest',
    offset: '02:00',
  },
  {
    id: 'Middle East Standard Time',
    name: '(UTC+02:00) Beirut',
    offset: '02:00',
  },
  {
    id: 'Egypt Standard Time',
    name: '(UTC+02:00) Cairo',
    offset: '02:00',
  },
  {
    id: 'E. Europe Standard Time',
    name: '(UTC+02:00) Chisinau',
    offset: '02:00',
  },
  {
    id: 'West Bank Standard Time',
    name: '(UTC+02:00) Gaza, Hebron',
    offset: '02:00',
  },
  {
    id: 'South Africa Standard Time',
    name: '(UTC+02:00) Harare, Pretoria',
    offset: '02:00',
  },
  {
    id: 'FLE Standard Time',
    name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    offset: '02:00',
  },
  {
    id: 'Israel Standard Time',
    name: '(UTC+02:00) Jerusalem',
    offset: '02:00',
  },
  {
    id: 'South Sudan Standard Time',
    name: '(UTC+02:00) Juba',
    offset: '02:00',
  },
  {
    id: 'Kaliningrad Standard Time',
    name: '(UTC+02:00) Kaliningrad',
    offset: '02:00',
  },
  {
    id: 'Sudan Standard Time',
    name: '(UTC+02:00) Khartoum',
    offset: '02:00',
  },
  {
    id: 'Libya Standard Time',
    name: '(UTC+02:00) Tripoli',
    offset: '02:00',
  },
  {
    id: 'Namibia Standard Time',
    name: '(UTC+02:00) Windhoek',
    offset: '02:00',
  },
  {
    id: 'Jordan Standard Time',
    name: '(UTC+03:00) Amman',
    offset: '03:00',
  },
  {
    id: 'Arabic Standard Time',
    name: '(UTC+03:00) Baghdad',
    offset: '03:00',
  },
  {
    id: 'Syria Standard Time',
    name: '(UTC+03:00) Damascus',
    offset: '03:00',
  },
  {
    id: 'Turkey Standard Time',
    name: '(UTC+03:00) Istanbul',
    offset: '03:00',
  },
  {
    id: 'Arab Standard Time',
    name: '(UTC+03:00) Kuwait, Riyadh',
    offset: '03:00',
  },
  {
    id: 'Belarus Standard Time',
    name: '(UTC+03:00) Minsk',
    offset: '03:00',
  },
  {
    id: 'Russian Standard Time',
    name: '(UTC+03:00) Moscow, St. Petersburg',
    offset: '03:00',
  },
  {
    id: 'E. Africa Standard Time',
    name: '(UTC+03:00) Nairobi',
    offset: '03:00',
  },
  {
    id: 'Volgograd Standard Time',
    name: '(UTC+03:00) Volgograd',
    offset: '03:00',
  },
  {
    id: 'Iran Standard Time',
    name: '(UTC+03:30) Tehran',
    offset: '03:30',
  },
  {
    id: 'Arabian Standard Time',
    name: '(UTC+04:00) Abu Dhabi, Muscat',
    offset: '04:00',
  },
  {
    id: 'Astrakhan Standard Time',
    name: '(UTC+04:00) Astrakhan, Ulyanovsk',
    offset: '04:00',
  },
  {
    id: 'Azerbaijan Standard Time',
    name: '(UTC+04:00) Baku',
    offset: '04:00',
  },
  {
    id: 'Russia Time Zone 3',
    name: '(UTC+04:00) Izhevsk, Samara',
    offset: '04:00',
  },
  {
    id: 'Mauritius Standard Time',
    name: '(UTC+04:00) Port Louis',
    offset: '04:00',
  },
  {
    id: 'Saratov Standard Time',
    name: '(UTC+04:00) Saratov',
    offset: '04:00',
  },
  {
    id: 'Georgian Standard Time',
    name: '(UTC+04:00) Tbilisi',
    offset: '04:00',
  },
  {
    id: 'Caucasus Standard Time',
    name: '(UTC+04:00) Yerevan',
    offset: '04:00',
  },
  {
    id: 'Afghanistan Standard Time',
    name: '(UTC+04:30) Kabul',
    offset: '04:30',
  },
  {
    id: 'West Asia Standard Time',
    name: '(UTC+05:00) Ashgabat, Tashkent',
    offset: '05:00',
  },
  {
    id: 'Ekaterinburg Standard Time',
    name: '(UTC+05:00) Ekaterinburg',
    offset: '05:00',
  },
  {
    id: 'Pakistan Standard Time',
    name: '(UTC+05:00) Islamabad, Karachi',
    offset: '05:00',
  },
  {
    id: 'Qyzylorda Standard Time',
    name: '(UTC+05:00) Qyzylorda',
    offset: '05:00',
  },
  {
    id: 'India Standard Time',
    name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    offset: '05:30',
  },
  {
    id: 'Sri Lanka Standard Time',
    name: '(UTC+05:30) Sri Jayawardenepura',
    offset: '05:30',
  },
  {
    id: 'Nepal Standard Time',
    name: '(UTC+05:45) Kathmandu',
    offset: '05:45',
  },
  {
    id: 'Central Asia Standard Time',
    name: '(UTC+06:00) Astana',
    offset: '06:00',
  },
  {
    id: 'Bangladesh Standard Time',
    name: '(UTC+06:00) Dhaka',
    offset: '06:00',
  },
  {
    id: 'Omsk Standard Time',
    name: '(UTC+06:00) Omsk',
    offset: '06:00',
  },
  {
    id: 'Myanmar Standard Time',
    name: '(UTC+06:30) Yangon (Rangoon)',
    offset: '06:30',
  },
  {
    id: 'SE Asia Standard Time',
    name: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    offset: '07:00',
  },
  {
    id: 'Altai Standard Time',
    name: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    offset: '07:00',
  },
  {
    id: 'W. Mongolia Standard Time',
    name: '(UTC+07:00) Hovd',
    offset: '07:00',
  },
  {
    id: 'North Asia Standard Time',
    name: '(UTC+07:00) Krasnoyarsk',
    offset: '07:00',
  },
  {
    id: 'N. Central Asia Standard Time',
    name: '(UTC+07:00) Novosibirsk',
    offset: '07:00',
  },
  {
    id: 'Tomsk Standard Time',
    name: '(UTC+07:00) Tomsk',
    offset: '07:00',
  },
  {
    id: 'China Standard Time',
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    offset: '08:00',
  },
  {
    id: 'North Asia East Standard Time',
    name: '(UTC+08:00) Irkutsk',
    offset: '08:00',
  },
  {
    id: 'Singapore Standard Time',
    name: '(UTC+08:00) Kuala Lumpur, Singapore',
    offset: '08:00',
  },
  {
    id: 'W. Australia Standard Time',
    name: '(UTC+08:00) Perth',
    offset: '08:00',
  },
  {
    id: 'Taipei Standard Time',
    name: '(UTC+08:00) Taipei',
    offset: '08:00',
  },
  {
    id: 'Ulaanbaatar Standard Time',
    name: '(UTC+08:00) Ulaanbaatar',
    offset: '08:00',
  },
  {
    id: 'Aus Central W. Standard Time',
    name: '(UTC+08:45) Eucla',
    offset: '08:45',
  },
  {
    id: 'Transbaikal Standard Time',
    name: '(UTC+09:00) Chita',
    offset: '09:00',
  },
  {
    id: 'Tokyo Standard Time',
    name: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    offset: '09:00',
  },
  {
    id: 'North Korea Standard Time',
    name: '(UTC+09:00) Pyongyang',
    offset: '09:00',
  },
  {
    id: 'Korea Standard Time',
    name: '(UTC+09:00) Seoul',
    offset: '09:00',
  },
  {
    id: 'Yakutsk Standard Time',
    name: '(UTC+09:00) Yakutsk',
    offset: '09:00',
  },
  {
    id: 'Cen. Australia Standard Time',
    name: '(UTC+09:30) Adelaide',
    offset: '09:30',
  },
  {
    id: 'AUS Central Standard Time',
    name: '(UTC+09:30) Darwin',
    offset: '09:30',
  },
  {
    id: 'E. Australia Standard Time',
    name: '(UTC+10:00) Brisbane',
    offset: '10:00',
  },
  {
    id: 'AUS Eastern Standard Time',
    name: '(UTC+10:00) Canberra, Melbourne, Sydney',
    offset: '10:00',
  },
  {
    id: 'West Pacific Standard Time',
    name: '(UTC+10:00) Guam, Port Moresby',
    offset: '10:00',
  },
  {
    id: 'Tasmania Standard Time',
    name: '(UTC+10:00) Hobart',
    offset: '10:00',
  },
  {
    id: 'Vladivostok Standard Time',
    name: '(UTC+10:00) Vladivostok',
    offset: '10:00',
  },
  {
    id: 'Lord Howe Standard Time',
    name: '(UTC+10:30) Lord Howe Island',
    offset: '10:30',
  },
  {
    id: 'Bougainville Standard Time',
    name: '(UTC+11:00) Bougainville Island',
    offset: '11:00',
  },
  {
    id: 'Russia Time Zone 10',
    name: '(UTC+11:00) Chokurdakh',
    offset: '11:00',
  },
  {
    id: 'Magadan Standard Time',
    name: '(UTC+11:00) Magadan',
    offset: '11:00',
  },
  {
    id: 'Norfolk Standard Time',
    name: '(UTC+11:00) Norfolk Island',
    offset: '11:00',
  },
  {
    id: 'Sakhalin Standard Time',
    name: '(UTC+11:00) Sakhalin',
    offset: '11:00',
  },
  {
    id: 'Central Pacific Standard Time',
    name: '(UTC+11:00) Solomon Is., New Caledonia',
    offset: '11:00',
  },
  {
    id: 'Russia Time Zone 11',
    name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    offset: '12:00',
  },
  {
    id: 'New Zealand Standard Time',
    name: '(UTC+12:00) Auckland, Wellington',
    offset: '12:00',
  },
  {
    id: 'UTC+12',
    name: '(UTC+12:00) Coordinated Universal Time+12',
    offset: '12:00',
  },
  {
    id: 'Fiji Standard Time',
    name: '(UTC+12:00) Fiji',
    offset: '12:00',
  },
  {
    id: 'Kamchatka Standard Time',
    name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    offset: '12:00',
  },
  {
    id: 'Chatham Islands Standard Time',
    name: '(UTC+12:45) Chatham Islands',
    offset: '12:45',
  },
  {
    id: 'UTC+13',
    name: '(UTC+13:00) Coordinated Universal Time+13',
    offset: '13:00',
  },
  {
    id: 'Tonga Standard Time',
    name: "(UTC+13:00) Nuku'alofa",
    offset: '13:00',
  },
  {
    id: 'Samoa Standard Time',
    name: '(UTC+13:00) Samoa',
    offset: '13:00',
  },
  {
    id: 'Line Islands Standard Time',
    name: '(UTC+14:00) Kiritimati Island',
    offset: '14:00',
  },
];

export default TimezoneField;
