import React from 'react';
import { Button, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';

import { Field } from '../../form';
import { EDITING_MODES } from './constants';
import CronExpressionEditForm from './form';
import { canBeConfigured, parseExpressionToText } from './utilities';
import CronExpressionSimpleForm from './form/cron-expression-simple-form';

import styles from './cron-expression.module.scss';

const EditMenu = ({ disabled, toggle }) => {
  return (
    <Popover
      content={
        <Menu>
          <MenuItem text="Edit" onClick={() => toggle(EDITING_MODES.CONFIG_FORM)} />
          <MenuItem text="Change expression" onClick={() => toggle(EDITING_MODES.MANUAL_FORM)} />
        </Menu>
      }
      disabled={disabled}
      placement="bottom-end"
    >
      <Button minimal disabled={disabled} icon="more" />
    </Popover>
  );
};

const CronExpression = ({ disabled, display, label, placeholder, options, value, onChange }) => {
  const [editingMode, setEditingMode] = React.useState(EDITING_MODES.NONE);
  const result = parseExpressionToText(value);

  const toggle = mode => {
    if (!value || (result.success && canBeConfigured(value))) {
      return setEditingMode(mode);
    }
    setEditingMode(EDITING_MODES.MANUAL_FORM);
  };

  if (display === 'simple') {
    return <CronExpressionSimpleForm label={label} text={result?.text || placeholder || 'Select...'} value={value} options={options} onChange={onChange} />;
  }

  return (
    <React.Fragment>
      {editingMode === EDITING_MODES.NONE && (
        <InputGroup
          inputClassName={styles.displayInput}
          fill
          disabled={disabled}
          placeholder={placeholder}
          value={result.text}
          readOnly
          rightElement={<EditMenu disabled={disabled} toggle={toggle} />}
        />
      )}
      <CronExpressionEditForm editingMode={editingMode} value={value} onChange={onChange} toggle={toggle} />
    </React.Fragment>
  );
};

const CronExpressionField = ({ classes, name, validate, disabled, label, description, placeholder, display, options, submitOnChange, minimal, showError }) => (
  <Field classes={{ ...classes, fieldset: styles.fieldset }} name={name} label={label} description={description} validate={validate}>
    {({ field, form }) => {
      const handleChange = value => {
        !form.touched[name] && form.setFieldTouched(name, true);

        if (!value) {
          form.setFieldValue(name, undefined);
          return;
        }

        form.setFieldValue(name, value);

        if (submitOnChange) {
          form.submitForm();
        }
      };

      return (
        <CronExpression
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          display={display}
          options={options}
          value={field.value}
          onChange={handleChange}
          minimal={minimal}
          showError={showError}
        />
      );
    }}
  </Field>
);

export default CronExpressionField;
