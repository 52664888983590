import Clients from '../configuration/client';

export const getJob = async ({ jobId }) => {
  const response = await Clients.Api.get(`ui/scheduler/${jobId}`);
  return response?.data;
};

export const createOrUpdateOneTimeJob = async ({ name, destination, startTime, timeZoneId, cron, data }) => {
  const response = await Clients.Api.post(`ui/scheduler/onetime`, { name, destination, startTime, timeZoneId, cron, data });
  return response?.data;
};

export const createOrUpdateRecurringJob = async ({ name, destination, timeZoneId, cron, data }) => {
  const response = await Clients.Api.post(`ui/scheduler/recurring`, { name, destination, timeZoneId, cron, data });
  return response?.data;
};

export const deleteJob = async ({ jobId }) => {
  const response = await Clients.Api.delete(`ui/scheduler/${jobId}`);
  return response?.data;
};

export const getJobDestination = async ({ type }) => {
  const response = await Clients.Api.get(`ui/scheduler/destinations/${type}`);
  return response?.data;
};
