import React from 'react';
import { Menu, MenuDivider, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import { ClassificationType } from '../../domain/classification-type';
import { EngagementStatusType } from '../../domain/engagement-status-type';

import WithConfirmation from '../../hooks/with-confirmation';
import useApi from '../../hooks/use-api';
import { setEngagementStatus } from '../../apis/platform/engagements';
import { completeFlow } from '../../apis/wizards';

import classes from './timeline.module.scss';

const TimelineEngagementFinalActionsBox = ({ engagement, engagementFlow, reload }) => {
  const flowComplete = engagementFlow?.steps?.every?.(step => !!step?.isComplete && (!step?.review || !step?.review?.isRequired || !!step?.review?.isComplete));

  if (!flowComplete) {
    return null;
  }

  const props = { engagement, engagementFlow, reload };

  const allApproved = engagementFlow?.steps?.every?.(
    step => !!step?.isComplete && (!step?.review || !step?.review?.isRequired || (!!step?.review?.isComplete && !!step?.review?.result)),
  );

  // Box displayed if all steps are approved and is not already active.
  if (!!allApproved && engagement?.status !== EngagementStatusType.Active) {
    return <ApproveBox {...props} />;
  }

  // Box displayed if not all steps are approved and is not already canceled (soft-deleted).
  if (!allApproved && !engagement?.isDeleted) {
    return <RejectBox {...props} />;
  }

  return null;
};

const ApproveBox = ({ engagement, engagementFlow, reload }) => {
  const completeFlowApi = useApi(completeFlow, { skip: true });
  return (
    <div className={classNames(classes.finalActionsBox, classes.approving)}>
      <div className={classes.checklist}>
        <div className={classes.checklistTitle}>Please review the checklist below.</div>
        <div className={classes.checklistItem}>
          {!!engagement?.compensation?.tracksWork ? 'Supplier is requested to track work.' : 'Supplier is NOT requested to track work.'}
        </div>
        {engagement?.classification?.result === ClassificationType.Employee && (
          <div className={classes.checklistItem}>
            {!!engagement?.compensation?.isExempt ? 'Supplier is exempt employee.' : 'Supplier is non-exempt employee.'}
          </div>
        )}
      </div>
      <div className={classes.changeStatusBox}>
        <WithConfirmation messages={{ question: `Mark engagement as active so supplier can start working ?`, confirmButton: 'Ok' }}>
          <div
            className={classes.main}
            onClick={async () => {
              await completeFlowApi.request({ flowId: engagementFlow?.flowRecordId, approved: true });
              reload();
            }}
          >
            {completeFlowApi.loading && <Spinner className={classes.completeSpinner} size={16} strokeWidth={1.5} />}
            Mark engagement as <span>Active</span>
          </div>
        </WithConfirmation>
        <CommonActions {...{ engagement, engagementFlow, reload }} />
      </div>
    </div>
  );
};

const RejectBox = ({ engagement, engagementFlow, reload }) => {
  const completeFlowApi = useApi(completeFlow, { skip: true });

  return (
    <div className={classNames(classes.finalActionsBox, classes.rejecting)}>
      <div className={classes.checklist}>
        <div className={classes.checklistTitle}>Canceling the engagement</div>
        <div className={classes.checklistItem}>Engagement onboarding will be complete.</div>
        <div className={classes.checklistItem}>Engagement will be archived.</div>
      </div>
      <div className={classes.changeStatusBox}>
        <WithConfirmation messages={{ question: `Mark engagement as canceled ?`, confirmButton: 'Ok' }}>
          <div
            className={classes.main}
            onClick={async () => {
              await completeFlowApi.request({ flowId: engagementFlow?.flowRecordId, approved: false });
              reload();
            }}
          >
            Mark engagement as <span>Canceled</span>
          </div>
        </WithConfirmation>
        <CommonActions {...{ engagement, engagementFlow, reload }} />
      </div>
    </div>
  );
};

const CommonActions = ({ engagement, engagementFlow, reload }) => {
  const setEngagementStatusApi = useApi(setEngagementStatus, { skip: true });
  const completeFlowApi = useApi(completeFlow, { skip: true });

  return (
    <Popover
      content={
        <Menu>
          <WithConfirmation messages={{ question: `Set engagement status to pending ?`, confirmButton: 'Ok' }}>
            <MenuItem
              text="Pending"
              onClick={async () => {
                await setEngagementStatusApi.request({ engagementId: engagement?.id, status: EngagementStatusType.Pending });
                reload();
              }}
              shouldDismissPopover={false}
            />
          </WithConfirmation>
          <MenuDivider />
          <WithConfirmation messages={{ question: `Cancel engagement (engagement will be deleted/archived) ?`, confirmButton: 'Ok' }}>
            <MenuItem
              text="Cancel"
              onClick={async () => {
                await completeFlowApi.request({ flowId: engagementFlow?.flowRecordId, approved: false });
                reload();
              }}
              shouldDismissPopover={false}
            />
          </WithConfirmation>
        </Menu>
      }
    >
      <div className={classes.statusButton} title="Change status">
        <Icons.MoreHorizontal size={16} strokeWidth={1.5} />
      </div>
    </Popover>
  );
};

export default TimelineEngagementFinalActionsBox;
