import React from 'react';

import ClientTime from '../../../../../../components/organizational/client/time';

import classes from './time.module.scss';

const Time = ({ engagementId }) => {
  return (
    <div className={classes.time}>
      <ClientTime engagementId={engagementId} handleScroll={false} dark />
    </div>
  );
};

export default Time;
