import { Button } from '@blueprintjs/core';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';

import useApi from '../../../../../../hooks/use-api';

import { createShift, updateShift } from '../../../../../../apis/platform/time';
import { DateFormat } from '../../../../../../models/enumerations/date-format';
import { calculateDuration, calculateShiftEnd, extractDateStr, extractStartTime } from '../../../../../../utilities/time/shift';

import classes from '../details.module.scss';

const ShiftForm = ({ engagement, data = {}, onComplete = () => null }) => {
  const createShiftApi = useApi(createShift, { skip: true });
  const updateShiftApi = useApi(updateShift, { skip: true });

  const submit = async values => {
    const startDate = `${values.date}T${values?.start}`;
    const duration = calculateDuration(values.start, values.end);
    const payload = {
      id: data.id,
      userId: engagement?.userId,
      engagementId: values?.engagementId,
      start: startDate,
      duration,
      notes: values?.notes,
    };
    const response = payload.id ? await updateShiftApi.request(payload) : await createShiftApi.request(payload);
    if (!response.error) {
      onComplete();
    }
  };

  function getInitialValues() {
    const date = data.date ? extractDateStr(data.date) : moment().format(DateFormat.DEFAULT);
    const start = data?.date ? extractStartTime(data.date) : '08:00';
    const end = data?.duration ? calculateShiftEnd(start, data.duration) : '12:00';
    return {
      date,
      start,
      end,
      notes: data?.notes || '',
      engagementId: engagement.engagementId,
    };
  }

  return (
    <Form
      initialValues={getInitialValues()}
      validationSchema={Yup.object().shape({
        engagementId: Yup.string().required('Project is required'),
        start: Yup.mixed()
          .required('Shift start is required')
          .test('test', 'Invalid session start or end.', (v, { parent }) => {
            const s = moment.duration(v).asMinutes();
            const e = moment.duration(parent.end).asMinutes();
            return s < e;
          }),
        end: Yup.mixed().required('Shift end is required'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {form => {
        const loading = createShiftApi.loading || updateShiftApi.loading;
        const error = createShiftApi.error || updateShiftApi.error;
        return (
          <React.Fragment>
            <Fields.Select label="Engagement" disabled name="engagementId" options={[{ label: engagement?.title, value: engagement?.engagementId }]} />
            <div className={classes.shiftSpan}>
              <div className={classes.project}>
                <Fields.Date label="Date" name="date" format={DateFormat.DEFAULT} fill />
              </div>
              <Fields.Time label="Start time" name="start" format="12" fill />
              <Fields.Time label="End time" name="end" format="12" fill />
            </div>
            <Fields.Text label="Notes" name="notes" />
            {error && <div className={classes.shiftOverlapsMessage}>Requested shift overlaps with already logged shifts.</div>}
            <Button intent="success" fill outlined text={data?.id ? 'Update' : 'Save'} disabled={loading} type="submit" />
          </React.Fragment>
        );
      }}
    </Form>
  );
};

export default ShiftForm;
