import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const ProvidersMenu = ({ onRegister, onLink }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="Register" label={<Icon icon="plus" />} onClick={onRegister} />
        <MenuItem text="Link" label={<Icon icon="link" />} onClick={onLink} />
        <MenuDivider />
        <MenuItem text="Export" label=".csv" disabled />
      </Menu>
    </React.Fragment>
  );
};

export default ProvidersMenu;
