import React from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../form';
import useApi from '../../../../../hooks/use-api';
import Fields from '../../../../fields';
import { updateEngagementTermination } from '../../../../../apis/platform/engagements';

const TerminationForm = ({ getTerminationApi, onComplete = null }) => {
  const updateTerminationApi = useApi(updateEngagementTermination, { skip: true });

  const submit = async values => {
    await updateTerminationApi?.request(values);
    await getTerminationApi?.request({ engagementId: values?.engagementId });

    if (!!onComplete && typeof onComplete === 'function') {
      onComplete();
    }
  };

  const loading = getTerminationApi?.loading || updateTerminationApi?.loading;

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        engagementId: getTerminationApi?.response?.engagementId,
        endDate: getTerminationApi?.response?.endDate,
        reason: getTerminationApi?.response?.reason || '',
        notes: getTerminationApi?.response?.notes || '',
      }}
    >
      <Fields.Date label="End date" name="endDate" />
      <Fields.Text label="Reason" name="reason" disabled />
      <Fields.Text label="Notes" name="notes" disabled />
      <Button fill outlined type="submit" text="Submit" disabled={loading} />
    </Form>
  );
};
export default TerminationForm;
