const camelCase = str => str.replace(/-(.)/g, (_, p) => p.toUpperCase());

export function getContentItemStyle(customStyle) {
  const style = {};
  if (!customStyle) {
    return style;
  }

  const attributes = customStyle.split(';');
  attributes.forEach(attr => {
    const [property, value] = attr.split(':');
    if (value) {
      style[camelCase(property.trim())] = value;
    }
  });
  return style;
}
