import React from 'react';
import { BarChart, Bar, Cell, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getColor } from '../../../utilities/common';

const data = [
  {
    client: 'Acme',
    active: 12,
    inactive: 32,
  },
  {
    client: 'IBM',
    active: 62,
    inactive: 12,
  },
  {
    client: 'CocaCola',
    active: 25,
    inactive: 1,
  },
  {
    client: 'Catalant',
    active: 30,
    inactive: 3,
  },
  {
    client: 'Toptal',
    active: 45,
    inactive: 3,
  },
  {
    client: 'Mercedes',
    active: 23,
    inactive: 21,
  },
  {
    client: 'Microsoft',
    active: 34,
    inactive: 43,
  },
  {
    client: 'Boing',
    active: 12,
    inactive: 32,
  },
  {
    client: 'Uber',
    active: 62,
    inactive: 12,
  },
  {
    client: 'Petsmart',
    active: 25,
    inactive: 1,
  },
  {
    client: 'Walmart',
    active: 30,
    inactive: 3,
  },
  {
    client: 'Omega',
    active: 45,
    inactive: 3,
  },
  {
    client: 'BMW',
    active: 23,
    inactive: 21,
  },
  {
    client: 'WeAreRosie',
    active: 34,
    inactive: 43,
  },
];

const ClientCharts = () => {
  return (
    <ResponsiveContainer>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 30,
          right: 30,
          left: 30,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="client" />
        <Tooltip />
        <Bar dataKey="active" name="Active" fill={getColor('Active')}>
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={getColor(entry.client)} />;
          })}
        </Bar>
        <Bar dataKey="inactive" name="Inactive" fill={getColor('Inactive')}>
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={getColor(entry.client)} style={{ opacity: '0.25' }} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ClientCharts;
