import React from 'react';
import { useParams } from 'react-router';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Tabs from '../../../../../components/tabs';
import useApi from '../../../../../hooks/use-api';
import { getClient } from '../../../../../apis/platform/organizations';

import Configuration from './containers/configuration';
import General from './containers/general';
import Policies from './containers/policies';
import Staff from './containers/staff';
import SubClients from './containers/sub-clients';
import Fees from './containers/fees';
import Providers from './containers/providers';
import Time from './containers/time';
import Departments from './containers/departments';
import Integrations from './containers/integrations';
import Appearance from './containers/appearance';

import classes from './details.module.scss';

const Details = () => {
  const { id: clientId } = useParams();
  const api = useApi(getClient, { skip: true, params: { clientId } });

  React.useEffect(() => {
    if (clientId) {
      api.request({ clientId });
    }
  }, [clientId]);

  const avatar = (
    <Link
      to={{
        pathname: `/clients/${clientId}`,
        search: `?t=appearance`,
      }}
    >
      <Avatar
        loading={api?.loading}
        name={api?.response?.name}
        size={{ box: 220, text: 30 }}
        styles={{ marginBottom: '15px' }}
        url={api?.response?.icon}
        upload
      />
    </Link>
  );

  const commonProps = { api, clientId };

  return (
    <Page
      title={!!api?.loading ? <span className={Classes.SKELETON}>Client</span> : api?.response?.name}
      scroll={false}
      path={[
        { name: 'Clients', to: '/clients' },
        {
          name: !!api?.loading ? <span className={Classes.SKELETON}>Client</span> : api?.response?.name,
          to: `/clients/${api?.response?.id}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Staff" transparent component={Staff} props={commonProps} />
          <Tabs.Item title="Departments" transparent component={Departments} props={commonProps} />
          <Tabs.Item title="Policies" component={Policies} props={commonProps} />
          {api.response?.isPartner && <Tabs.Item title="Clients" component={SubClients} props={commonProps} />}
          <Tabs.Item title="Time" component={Time} props={commonProps} />
          <Tabs.Item title="Fees" component={Fees} props={commonProps} />
          {/* <Tabs.Item title="Notifications" /> */}
          <Tabs.Item title="Service providers" component={Providers} props={commonProps} />
          <Tabs.Item title="Integrations" noScroll component={Integrations} props={commonProps} />
          <Tabs.Item title="Appearance" component={Appearance} props={commonProps} />
          <Tabs.Item title="Configuration" noScroll component={Configuration} props={commonProps} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

export default Details;
