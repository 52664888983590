import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import Box from '../../../../../components/box';

import { genderStatusToLabel } from '../../../../../domain/gender';
import { maritalStatusToLabel } from '../../../../../domain/marital-status';

import classes from '../../details.module.scss';

const General = ({ api }) => {
  const user = api.response?.user;

  const dateOfBirth =
    user?.personalDetails?.dateOfBirth && moment(user?.personalDetails?.dateOfBirth).isValid()
      ? moment(user?.personalDetails?.dateOfBirth).format('Do MMMM YYYY')
      : 'Not provided';

  return (
    <div className={classes.tab}>
      <Box title="Personal Information" outlined monochrome loading={api.loading}>
        <Box.Item label="First Name">{user?.personalDetails?.firstName}</Box.Item>
        <Box.Item label="Middle name(s)" notProvidedText="-">
          {user?.personalDetails?.middleName}
        </Box.Item>
        <Box.Item label="Last Name">{user?.personalDetails?.lastName}</Box.Item>
        <Box.Item label="Email address">{user?.email}</Box.Item>
        <Box.Item label="Date of birth">{dateOfBirth}</Box.Item>
        <Box.Item label="Gender">{genderStatusToLabel(user?.personalDetails?.gender)}</Box.Item>
        <Box.Item label="Marital status">{maritalStatusToLabel(user?.personalDetails?.maritalStatus)}</Box.Item>
        <Box.Item label="Address">{user?.personalDetails?.address?.prettyName || 'Not provided'}</Box.Item>
        <Box.Item label="Phone">{user?.personalDetails?.phone}</Box.Item>
        <Box.Item label="National id number (ssn, nic, ...)">{user?.personalDetails?.nationalIdentificationNumber}</Box.Item>
        <Box.Item label="Ethnicity">{user?.personalDetails?.ethnicity}</Box.Item>
      </Box>
      <Box title="Emergency Contact" outlined monochrome loading={api.loading}>
        <Box.Item label="First name">{user?.emergencyContact?.firstName}</Box.Item>
        <Box.Item label="Last name">{user?.emergencyContact?.lastName}</Box.Item>
        <Box.Item label="Relationship">{user?.emergencyContact?.relationship}</Box.Item>
        <Box.Item label="Phone">{user?.emergencyContact?.phone}</Box.Item>
        <Box.Item label="Email address">{user?.emergencyContact?.email}</Box.Item>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(General);
