import React, { useState } from 'react';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Tabs from '../../../../../components/tabs';
import Timeline from '../../../../../components/timeline';

import useApi from '../../../../../hooks/use-api';
import { getEngagement } from '../../../../../apis/platform/engagements';

import Documents from './containers/documents';
import General from './containers/general';
import Time from './containers/time';

import classes from './details.module.scss';
import { useParams } from 'react-router';

const Details = () => {
  const { id: engagementId } = useParams();
  const api = useApi(() => getEngagement(engagementId));
  const [dialog, setDialog] = useState({ open: false });
  const avatar = (
    <div className={classes.avatarContainer}>
      <Avatar
        loading={api?.loading}
        name={api?.response?.supplierName}
        size={{ box: 220, text: 30 }}
        styles={{ marginBottom: '15px' }}
        url={api?.response?.supplierIcon}
      />
    </div>
  );

  const commonProps = { api, engagementId };
  const title = `${api?.response?.supplierName} - ${api?.response?.title}`;

  return (
    <Page
      title={!!api?.loading ? <span className={Classes.SKELETON}>Engagement</span> : title}
      scroll={false}
      path={[
        { name: 'Engagements', to: '/engagements' },
        {
          name: !!api?.loading ? <span className={Classes.SKELETON}>Engagement</span> : title,
          to: `/engagements/${api?.response?.id}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Time" component={Time} props={commonProps} />
          <Tabs.Item title="Documents" component={Documents} props={commonProps} />
          <Tabs.ActionItem title="Timeline" onClick={() => setDialog({ open: true })} />
        </Tabs.Container>
      </div>
      <Timeline open={dialog?.open} engagementId={engagementId} onClose={() => setDialog({ open: false })} />
    </Page>
  );
};

export default Details;
