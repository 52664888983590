import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { TrackingRecordStatusTypes } from '../../../../domain/tracking-record-status-type';

import WithConfirmation from '../../../../hooks/with-confirmation';

const TimeMenu = ({ engagements, updateStatus, onExport }) => {
  const records = engagements?.reduce((a, b) => [...a, ...(b?.records?.map?.(r => r.id) || [])], []);

  return (
    <React.Fragment>
      <Menu>
        <WithConfirmation messages={{ confirmButton: 'Approve', title: 'Approve records for all listed suppliers ?' }} icon={true}>
          <MenuItem text="Approve" onClick={() => updateStatus({ status: TrackingRecordStatusTypes.Approved, records })} shouldDismissPopover={false} />
        </WithConfirmation>
        <WithConfirmation messages={{ confirmButton: 'Dispute', title: 'Dispute records for all listed suppliers ?' }} icon={true}>
          <MenuItem text="Dispute" onClick={() => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records })} shouldDismissPopover={false} />
        </WithConfirmation>
        <MenuDivider />
        <MenuItem text="Export" label=".csv" onClick={onExport} />
        <MenuItem text="Import" label=".csv" disabled />
      </Menu>
    </React.Fragment>
  );
};

export default TimeMenu;
