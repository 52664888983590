import React, { useContext, useState } from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../dialog';
import Fields from '../../../../fields';
import Form from '../../../../form';

import { SystemContext } from '../../system-provider';

const AddScopeDialog = ({ scope, isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { addScope } = useContext(SystemContext);

  const submit = async values => {
    setLoading(true);
    await addScope(scope?.organizationId, scope?.id, values?.location?.place_id);
    setLoading(false);
    onClose();
  };
  const close = () => (!!loading ? null : onClose());

  return (
    <Dialog title={`Add child scope to ${scope?.prettyName || 'Global'}`} isOpen={isOpen} onClose={close}>
      <Form
        initialValues={{ location: {} }}
        validationSchema={Yup.object().shape({
          location: Yup.object().test('required', 'Scope location is required', l => !!l?.place_id),
        })}
        onSubmit={submit}
      >
        <Fields.Address name="location" label="Location" />
        <Button type="submit" outlined fill text="Submit" loading={loading} />
      </Form>
    </Dialog>
  );
};

export default AddScopeDialog;
