import React from 'react';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import { sortBy } from 'lodash';

import Box from '../../../../../../../components/box';
import Form from '../../../../../../../components/form';
import Fields from '../../../../../../../components/fields';

import WithConfirmation from '../../../../../../../hooks/with-confirmation';
import useApi from '../../../../../../../hooks/use-api';
import { getDocuments, uploadDocument, downloadDocument, removeDocument } from '../../../../../../../apis/documents';

import classes from '../../details.module.scss';

const Pay = ({ api }) => {
  const getDocumentsApi = useApi(() => getDocuments({ type: 'payroll', id: api?.response?.managerUserId }), { skip: !api?.response?.managerUserId });

  const payslipIndicator = 'Direct Deposit Vouchers';
  const other = getDocumentsApi?.response?.filter?.(d => !d?.name?.includes?.(payslipIndicator));
  const payslips = sortBy(
    getDocumentsApi?.response
      ?.filter?.(d => d?.name?.includes?.(payslipIndicator))
      ?.map?.(d => ({
        ...d,
        date: d?.name?.replace?.(payslipIndicator, '')?.replace?.('.pdf', ''),
      })),
    'date',
  ).reverse();

  const renderBox = (title, loading, items, expanded = false) => (
    <Box
      title={title}
      outlined
      monochrome
      loading={getDocumentsApi.loading}
      expandible={{ expanded }}
      actions={
        <ButtonGroup>
          <Form initialValues={{ file: null }} onSubmit={() => null}>
            <div className={classes.neg15}>
              <Fields.File
                fill
                outline={false}
                name="file"
                label="Upload file"
                api={file => uploadDocument({ file, name: file?.name, type: 'payroll', id: api?.response?.managerUserId })}
                onUploaded={getDocumentsApi.request}
              >
                <Button small outlined icon={<Icons.Upload size={16} strokeWidth={1.5} />} title="Upload" />
              </Fields.File>
            </div>
          </Form>
        </ButtonGroup>
      }
    >
      {loading
        ? [1, 2, 3, 4, 5].map((_, i) => (
            <Box.TabularItem key={i}>
              <Box.ItemValue className={Classes.SKELETON}>name</Box.ItemValue>
              <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
              <ButtonGroup>
                <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
              </ButtonGroup>
            </Box.TabularItem>
          ))
        : items?.map(document => (
            <Box.TabularItem key={document.name}>
              <Box.ItemValue>{document.name}</Box.ItemValue>
              <Box.ItemLabel>{document.contentType}</Box.ItemLabel>
              <ButtonGroup>
                <Button small outlined icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(document)} />
                <WithConfirmation icon={true}>
                  <Button
                    small
                    outlined
                    icon={<Icons.X size={16} strokeWidth={1.5} />}
                    onClick={async () => {
                      await removeDocument(document);
                      await getDocumentsApi?.request({ type: 'payroll', id: api?.response?.managerUserId });
                    }}
                  />
                </WithConfirmation>
              </ButtonGroup>
            </Box.TabularItem>
          ))}
      {!loading && !items?.length && (
        <Box.TabularItem>
          <Box.ItemLabel fill>No records found.</Box.ItemLabel>
        </Box.TabularItem>
      )}
    </Box>
  );

  return (
    <div className={classes.tab}>
      {renderBox('Pay Reports', getDocumentsApi.loading, other)}
      {renderBox('Pay History', getDocumentsApi.loading, payslips, true)}
    </div>
  );
};

export default Pay;
