import React from 'react';

import TimelineTerminationRequest from './termination-request';
import TimelineUpdateRequest from './update-request';

const TimelineTypeSpecificContent = props => {
  return (
    <React.Fragment>
      <TimelineTerminationRequest {...props} />
      <TimelineUpdateRequest {...props} />
    </React.Fragment>
  );
};

export default TimelineTypeSpecificContent;
