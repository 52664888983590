import { Button } from '@blueprintjs/core';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import Fields from '../../../../../../components/fields';
import Form from '../../../../../../components/form';

import useApi from '../../../../../../hooks/use-api';

import { createDeliverable, updateDeliverable } from '../../../../../../apis/platform/time';
import { DateFormat } from '../../../../../../models/enumerations/date-format';

const DeliverableForm = ({ engagement, data = {}, onComplete = () => null }) => {
  const createDeliverableApi = useApi(createDeliverable, { skip: true });
  const updateDeliverableApi = useApi(updateDeliverable, { skip: true });

  const submit = async values => {
    if (data?.id) {
      await updateDeliverableApi.request(values);
    } else {
      await createDeliverableApi.request(values);
    }

    onComplete();
  };

  return (
    <Form
      initialValues={{
        userId: engagement?.userId,
        engagementId: engagement?.engagementId,
        date: moment().startOf('day').format(DateFormat.DEFAULT),
        notes: '',
        ...data,
      }}
      validationSchema={Yup.object().shape({
        engagementId: Yup.string().required('Project is required'),
        amount: Yup.number().moreThan(0, 'Amount must be greater than 0').required('Amount is required'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {form => {
        const loading = createDeliverableApi.loading || updateDeliverableApi.loading;
        return (
          <React.Fragment>
            <Fields.Select label="Engagement" disabled name="engagementId" options={[{ label: engagement?.title, value: engagement?.engagementId }]} />
            <Fields.Date
              label="Date"
              name="date"
              format={DateFormat.DEFAULT}
              minDate={moment().startOf('isoWeek').toDate()}
              maxDate={moment().endOf('isoWeek').toDate()}
            />
            <Fields.Text
              extra={{ leftElement: <Button text={engagement?.currencySymbol} minimal small disabled /> }}
              label="Amount"
              type="number"
              name="amount"
            />
            <Fields.Text label="Notes" name="notes" />
            <Button type="submit" outlined fill text={data?.id ? 'Update' : 'Save'} disabled={loading} loading={loading} />
          </React.Fragment>
        );
      }}
    </Form>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableForm);
