import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../../../../form';
import Fields from '../../../../../../../fields';

import { createRecord, updateRecord } from '../../../../../../../../apis/configuration';

import { SystemContext } from '../../../../../system-provider';

import classes from './public-notice.module.scss';
import ResourceFiles from '../../../../../../../resource-files';

const PublicNotice = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    const payload = {
      ...(record || {}),
      scopeId: selectedScope?.id,
      description: record?.description || values?.name,
      data: values,
    };

    setLoading(true);
    if (record?.id) {
      await updateRecord(record?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  return (
    <div className={classes.notice}>
      <Form
        onSubmit={submit}
        enableReinitialize
        initialValues={{
          name: data?.name || '',
          url: data?.url || '',
          description: data?.description || '',
          visibleToEmployees: data?.visibleToEmployees || false,
          visibleToIndependentContractors: data?.visibleToIndependentContractors || false,
        }}
      >
        {form => (
          <div className={classes.form}>
            <Fields.Text name="name" label="Name" />
            <Fields.Text name="description" label="Description" />
            <div className={classes.document}>
              <Fields.Text name="url" label="Url" />
              <ResourceFiles minimal={true} upload browse onSubmit={url => form.setFieldValue('url', url)} />
            </div>
            <div className={classes.visibility}>
              <Fields.Select name="visibleToEmployees" options={options} label="Is notice visible to employee suppliers ?" showError={false} />
              <Fields.Select
                name="visibleToIndependentContractors"
                options={options}
                label="Is notice visible to independent contractor suppliers ?"
                showError={false}
              />
            </div>
            <Button type="submit" text="Submit" loading={loading} outlined fill />
          </div>
        )}
      </Form>
    </div>
  );
};

export default PublicNotice;
