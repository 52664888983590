export const EngagementStatusType = {
  Pending: 0,
  Active: 1000,
  Complete: 2000,
};

export const EngagementStatusOptions = [
  { value: 0, label: 'Pending' },
  { value: 1000, label: 'Active' },
  { value: 2000, label: 'Complete' },
];

const EngagementStatusLabels = EngagementStatusOptions.reduce((mapper, status) => {
  mapper[status.value] = status.label;
  return mapper;
}, {});

export const engagementStatusToLabel = status => EngagementStatusLabels[status] || '-';
