import React from 'react';
import { Icon, Menu, MenuItem } from '@blueprintjs/core';

const SuppliersMenu = ({ openEmailDialog }) => {
  return (
    <Menu>
      <MenuItem text="Send email" label={<Icon icon="send-message" />} onClick={() => openEmailDialog()} />
    </Menu>
  );
};

export default SuppliersMenu;
