import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

const InvoiceMenu = ({ onGenerateInvoice }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="Generate Invoice(s)" onClick={onGenerateInvoice} />
      </Menu>
    </React.Fragment>
  );
};

export default InvoiceMenu;
