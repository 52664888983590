import * as Yup from 'yup';

import { ElementTypes } from '../enumerations/field-definitions';

const ValidationSchemas = {
  [ElementTypes.TABLE]: Yup.object().shape({ iterator: Yup.string().label('Iterator').required('You need to configure iterator for the table') }),
  [ElementTypes.COLUMN]: Yup.object().shape({ text: Yup.string().label('Text').required(), value: Yup.string().label('Value').required() }),
  [ElementTypes.TEXT]: Yup.object().shape({ text: Yup.string().label('Text').required() }),
  [ElementTypes.BUTTON]: Yup.object().shape({ text: Yup.string().label('Text').required(), value: Yup.string().label('Link').required() }),
  [ElementTypes.LINK]: Yup.object().shape({ text: Yup.string().label('Text').required(), value: Yup.string().label('Link').required() }),
  [ElementTypes.IMAGE]: Yup.object().shape({ value: Yup.string().label('Source link').required() }),
  template: Yup.object({
    metadata: Yup.object({
      subject: Yup.string().required('Subject cannot be blank'),
    }),
    status: Yup.number().test('is-template-published', 'Template is not published yet', value => value !== 0),
  }),
};

export default ValidationSchemas;
