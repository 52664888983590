import React from 'react';

import { convertToExpression, parseExpression } from '../utilities';
import DayConfigItem from './day-config-item';
import HourConfigItem from './hour-config-item';
import MonthConfigItem from './month-config-item';

const CronExpressionConfigForm = ({ value, onChange }) => {
  const [values, setValues] = React.useState(parseExpression(value));

  const submit = unit => {
    const newValues = { ...values, ...unit };
    setValues(newValues);
    const expression = convertToExpression(newValues);
    onChange(expression);
  };

  return (
    <div>
      <HourConfigItem hour={values.hour} minute={values.minute} onChange={submit} />
      <DayConfigItem day={values.day} weekday={values.weekday} onChange={submit} />
      <MonthConfigItem month={values.month} onChange={submit} />
    </div>
  );
};
export default CronExpressionConfigForm;
