import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Spinner } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';

import { createRecord, updateRecord } from '../../../../../../../apis/configuration';
import {
  uploadPlatformLogo,
  uploadPlatformIcon,
  uploadClientLogo,
  uploadClientIcon,
  uploadSupplierLogo,
  uploadSupplierIcon,
} from '../../../../../../../apis/platform/organizations';
import { getThemes } from '../../../../../../../state/application';

import { OrganizationType } from '../../../../../../../domain/organization-type';
import { resolveOrganizationType } from '../../../../../../../utilities/common';

import { SystemContext } from '../../../../system-provider';

import classes from './theme.module.scss';

const Theme = ({ record, data, onClose, loading, setLoading, api, membership, getThemes }) => {
  const { selectedScope } = useContext(SystemContext);
  const [logo, setLogo] = useState();
  const [icon, setIcon] = useState();

  const submit = async values => {
    setLoading(true);
    const payload = { ...values, data: values.data };

    if (payload?.id) {
      await updateRecord(values?.id, payload);
    } else {
      await createRecord(payload);
    }

    if (!!logo) {
      await uploadImage('logo', logo, selectedScope?.organizationId);
    }

    if (!!icon) {
      await uploadImage('icon', icon, selectedScope?.organizationId);
    }

    await api?.request();

    if (selectedScope?.organizationId === membership?.organization?.id || selectedScope?.id === membership?.organization?.ParentClientId) {
      getThemes();
    }

    setLoading(false);
    onClose();
  };

  return (
    <div className={classes.theme}>
      <div className={classes.images}>
        <div className={classNames(classes.image, classes.logo)}>
          <div className={classes.label}>Logo</div>
          <Form initialValues={{ logo: data?.logo || null }} onSubmit={() => null} enableReinitialize>
            <Fields.File label="Upload logo" mimeTypes={'image/*'} name="logo" maxSize={5} api={setLogo} outline={false} minimal={true} showError={false}>
              <div className={classes.splash} style={{ backgroundImage: `url(${!!logo ? URL.createObjectURL(logo) : data?.logo})` }}>
                {loading ? (
                  <div className={classes.loading}>
                    <Spinner />
                  </div>
                ) : (
                  <div className={classes.upload}>
                    <Icons.Upload size={30} strokeWidth={1.5} />
                  </div>
                )}
              </div>
            </Fields.File>
          </Form>
        </div>
        <div className={classNames(classes.image, classes.icon)}>
          <div className={classes.label}>Icon</div>
          <Form initialValues={{ logo: data?.icon || null }} onSubmit={() => null} enableReinitialize>
            <Fields.File label="Upload icon" mimeTypes={'image/*'} name="logo" maxSize={5} api={setIcon} outline={false} minimal={true} showError={false}>
              <div className={classes.splash} style={{ backgroundImage: `url(${!!icon ? URL.createObjectURL(icon) : data?.icon})` }}>
                {loading ? (
                  <div className={classes.loading}>
                    <Spinner />
                  </div>
                ) : (
                  <div className={classes.upload}>
                    <Icons.Upload size={30} strokeWidth={1.5} />
                  </div>
                )}
              </div>
            </Fields.File>
          </Form>
        </div>
      </div>
      <div className={classes.colors}>
        <Form
          initialValues={{
            ...record,
            scopeId: selectedScope?.id,
            description: record?.description || '',
            data: { logo: data?.logo || '', icon: data?.icon || '', primaryColor: data?.primaryColor || null },
          }}
          onSubmit={submit}
          enableReinitialize
        >
          {form => {
            const disabled = loading;
            return (
              <React.Fragment>
                <Fields.Color name="data.primaryColor" label="Primary Color" />
                <Button outlined fill text="Submit" disabled={disabled} loading={loading} onClick={() => (disabled ? null : form.submitForm())} />
              </React.Fragment>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

const uploadImage = async (type, image, organizationId) => {
  const organizationType = resolveOrganizationType(organizationId);

  let uploadLogo = null;
  let uploadIcon = null;

  switch (organizationType) {
    case OrganizationType.Platform:
      uploadLogo = async image => await uploadPlatformLogo({ logo: image });
      uploadIcon = async image => await uploadPlatformIcon({ icon: image });
      break;

    case OrganizationType.Client:
      uploadLogo = async image => await uploadClientLogo({ clientId: organizationId, logo: image });
      uploadIcon = async image => await uploadClientIcon({ clientId: organizationId, icon: image });
      break;

    case OrganizationType.Supplier:
      uploadLogo = async image => await uploadSupplierLogo({ supplierId: organizationId, logo: image });
      uploadIcon = async image => await uploadSupplierIcon({ supplierId: organizationId, icon: image });
      break;

    default:
      uploadLogo = () => null;
      uploadIcon = () => null;
      break;
  }

  switch (type) {
    case 'logo':
      return await uploadLogo(image);

    case 'icon':
      return await uploadIcon(image);

    default:
      break;
  }
};

const mapStateToProps = state => ({
  membership: state.identity.membership,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getThemes,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
