import axios from 'axios';
import { Buffer } from 'buffer';

import { StorageManager } from '../authentication/storage';
import { setOfflineStatus } from '../state/application';
import { store } from './store';
import { logout } from '../state/identity/actions';

const membershipHeader = 'x-gl-member';

const createAuthenticatedApiClient = () => {
  const options = {
    baseURL: process.env.REACT_APP_API_SERVER,
    headers: {},
  };

  const client = axios.create(options);
  client.interceptors.request.use(configuration => {
    // Set authentication token header.
    const manager = new StorageManager();
    const tokens = manager.getStoredTokens();
    configuration.headers.Authorization = !!tokens?.access_token ? `Bearer ${tokens.access_token}` : undefined;

    // Set membership header.
    const { roleId, organizationId } = manager.getActiveMembershipIdentifiers();
    if (roleId && organizationId) {
      configuration.headers[membershipHeader] = Buffer.from(`${organizationId}|${roleId}`).toString('base64');
    }

    return configuration;
  });

  client.interceptors.response.use(
    response => response,
    error => {
      if (error.isAxiosError) {
        if (!error?.response) {
          store.dispatch(setOfflineStatus(true));
          return;
        }

        if (401 === error?.response?.status) {
          store.dispatch(logout());
          return;
        }
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );

  return {
    get: async (url, config = undefined) => await client.get(url, config),
    post: async (url, data = undefined, config = undefined) => await client.post(url, data, config),
    put: async (url, data = undefined, config) => await client.put(url, data, config),
    patch: async (url, data, config = undefined) => await client.patch(url, data, config),
    delete: async (url, config = undefined) => await client.delete(url, config),
  };
};

const Clients = {
  Api: createAuthenticatedApiClient(),
};

export default Clients;
