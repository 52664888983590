import React from 'react';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';

import { textAlignmentOptions } from '../../../../enumerations/field-options';

import classes from '../properties.module.scss';

const DivConfigForm = ({ contentItem, onSubmit }) => {
  return (
    <Form initialValues={contentItem} onSubmit={onSubmit} enableReinitialize={true}>
      <React.Fragment>
        <div className={classes.group}>
          <Fields.Text label="Title" name="title" submitOnChange={true} showError={false} />
        </div>
        <div className={classes.group}>
          <div className={classes.line}>
            <Fields.Text name="styleConfig.paddingTop" maskConfig={{ mask: '0[0]px', lazy: false }} label="Top" submitOnChange={true} showError={false} />
            <Fields.Text name="styleConfig.paddingRight" maskConfig={{ mask: '0[0]px', lazy: false }} label="Right" submitOnChange={true} showError={false} />
            <Fields.Text name="styleConfig.paddingBottom" maskConfig={{ mask: '0[0]px', lazy: false }} label="Bottom" submitOnChange={true} showError={false} />
            <Fields.Text name="styleConfig.paddingLeft" maskConfig={{ mask: '0[0]px', lazy: false }} label="Left" submitOnChange={true} showError={false} />
          </div>
        </div>
        <div className={classes.group}>
          <Fields.Select label="Text alignment" name="styleConfig.textAlign" options={textAlignmentOptions} submitOnChange={true} showError={false} />
        </div>
        <div className={classes.group}>
          <Fields.Text label="Class name" name="class" submitOnChange={true} showError={false} />
          <Fields.Textarea label="Override style" name="customStyle" submitOnChange={true} showError={false} />
        </div>
      </React.Fragment>
    </Form>
  );
};

export default DivConfigForm;
