import React from 'react';

import { useEmailConfigurator } from './context';

import classes from './email-configurator.module.scss';
import Toolbox from './components/toolbox';

import Browser from './components/browser';

const EmailConfigurator = () => {
  const [{ template, content, hovered }] = useEmailConfigurator();
  return (
    <div className={classes.configurator}>
      <Toolbox />
      <Browser
        content={content}
        footer={template?.metadata.footer}
        logo={template?.metadata.logo}
        customStyle={template?.metadata?.render?.style}
        hovered={hovered}
      />
    </div>
  );
};

export default EmailConfigurator;
