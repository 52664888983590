import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';
import { startCase } from 'lodash';

import Dialog from '../../../../dialog';
import Fields from '../../../../fields';
import Form from '../../../../form';

import { SystemContext } from '../../system-provider';

const CopyRecordDialog = ({ record, isOpen, onClose, onRecordAdded }) => {
  const { scopes, selectedScope } = useContext(SystemContext);
  const [group, name] = record?.configurationType?.split?.('|') || [];

  const scopeOptions = scopes?.filter(scope => scope.id !== selectedScope?.id).map(scope => ({ value: scope?.id, label: scope?.prettyName || 'Global' }));
  const loading = false;
  const disabled = false; //loading || countriesApi.loading;

  return (
    <Dialog title={`Copy record, ${startCase(group)}: ${startCase(name)}.`} isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ scopeId: null, ...record }}
        validationSchema={Yup.object().shape({
          scopeId: Yup.mixed().required('Destination scope is required'),
        })}
        onSubmit={() => null}
      >
        <Fields.Select
          name="scopeId"
          searchable
          label={scopeOptions?.length ? 'Select destination scope...' : 'No eligible scopes'}
          disabled={!scopeOptions?.length}
          options={scopeOptions}
        />
        <Button type="submit" outlined fill text="Copy" disabled={disabled} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default CopyRecordDialog;
