import Documents from './documents';
import DocumentConfigurator from './documents';
import System from './system';
import Wizards from './wizards';
import FlowLoader from './wizards/flowLoader';
import Email from './email';

const Configurators = {
  Documents,
  DocumentConfigurator,
  System,
  Wizards,
  FlowLoader,
  Email,
};

export default Configurators;
