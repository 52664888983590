import React from 'react';
import cn from 'classnames';

import classes from './box.module.scss';

export const BoxItemLabel = ({ children, className, fill }) => <div className={cn(classes.label, fill && classes.fill, className)}>{children}</div>;
export const BoxItemValue = ({ children, className }) => <div className={cn(classes.value, className)}>{children}</div>;

export const BoxItem = ({ children, label, notProvidedText = 'Not provided' }) => {
  return (
    <BoxItemContainer>
      {label && <BoxItemLabel>{label}</BoxItemLabel>}
      <BoxItemValue>{children || notProvidedText}</BoxItemValue>
    </BoxItemContainer>
  );
};

export const BoxTabularItem = ({ children }) => {
  return <div className={cn(classes.row, classes.tabular)}>{children}</div>;
};

export const BoxItemContainer = ({ children, className }) => {
  return <div className={cn(classes.row, className)}>{children}</div>;
};
