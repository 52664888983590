import { Button, ButtonGroup, Collapse } from '@blueprintjs/core';
import React from 'react';

import { EDITING_MODES } from '../constants';
import CronExpressionConfigForm from './cron-expression-config-form';
import CronExpressionManualForm from './cron-expression-manual-form';

import classes from '../cron-expression.module.scss';

const CronExpressionEditForm = ({ editingMode, value, onChange, toggle }) => (
  <Collapse isOpen={editingMode !== EDITING_MODES.NONE}>
    <div className={classes.collapse}>
      {editingMode === EDITING_MODES.MANUAL_FORM && <CronExpressionManualForm value={value} onChange={onChange} />}
      {editingMode === EDITING_MODES.CONFIG_FORM && <CronExpressionConfigForm value={value} onChange={onChange} />}
      <ButtonGroup fill>
        <Button outlined text="Apply" onClick={() => toggle(EDITING_MODES.NONE)} />
        <Button outlined text="Cancel" onClick={() => toggle(EDITING_MODES.NONE)} />
      </ButtonGroup>
    </div>
  </Collapse>
);

export default CronExpressionEditForm;
