import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../../../../form';
import Fields from '../../../../../../../fields';

import { ClassificationType } from '../../../../../../../../domain/classification-type';

import { createRecord, updateRecord } from '../../../../../../../../apis/configuration';

import { SystemContext } from '../../../../../system-provider';

import classes from './supplier-classification-preference.module.scss';

const SupplierClassificationPreference = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    const payload = {
      ...(record || {}),
      scopeId: selectedScope?.id,
      data: values,
    };

    setLoading(true);
    if (record?.id) {
      await updateRecord(record?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <div className={classes.requirements}>
      <Form
        onSubmit={submit}
        enableReinitialize
        initialValues={{
          classification: data?.classification || null,
        }}
      >
        <Fields.Select
          name="classification"
          label="Your company requires that all suppliers be..."
          options={[
            { label: 'Employed on your behalf.', value: ClassificationType.Employee },
            {
              label: 'Engaged as Independent Contractors.',
              value: ClassificationType.IndependentContractor,
            },
            {
              label: 'Engaged regardless of the classification.',
              value: null,
            },
          ]}
        />
        <Button type="submit" text="Submit" loading={loading} outlined fill />
      </Form>
    </div>
  );
};

export default SupplierClassificationPreference;
