import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Yup from 'yup';

import Spinner from '../spinner';
import Form, { FormGroup } from '../form';
import Fields from '../fields';

import useApi from '../../hooks/use-api';
import { getProviderTypes } from '../../apis/lookups';
import { createExternalIdentity, registerNewExternalIdentity, updateExternalIdentity } from '../../apis/external-identities';

import classes from './external-identities.module.scss';

const ExternalIdentitiesForm = ({ userId, supplierId, clientId, identity, register = false, onComplete = () => null }) => {
  const [selectedType, setSelectedType] = useState(identity?.providerType || '');

  const getTypesApi = useApi(getProviderTypes);

  const createExternalIdentityApi = useApi(createExternalIdentity, { skip: true });
  const registerExternalIdentityApi = useApi(registerNewExternalIdentity, { skip: true });
  const updateExternalIdentityApi = useApi(updateExternalIdentity, { skip: true });
  const api = !!register ? registerExternalIdentityApi : !!identity ? updateExternalIdentityApi : createExternalIdentityApi;

  if (!userId && !supplierId && !clientId) {
    return null;
  }

  if (getTypesApi.loading) {
    return (
      <div className={classes.loading}>
        <Spinner global={false} blend />
      </div>
    );
  }

  const userTypes = ['background-check', 'payroll'];
  const supplierTypes = ['payout'];
  const clientTypes = ['invoice'];

  const getTypeOptions = () => {
    let eligibleTypes = [];
    eligibleTypes = !!userId ? [...eligibleTypes, ...userTypes] : eligibleTypes;
    eligibleTypes = !!supplierId ? [...eligibleTypes, ...supplierTypes] : eligibleTypes;
    eligibleTypes = !!clientId ? [...eligibleTypes, ...clientTypes] : eligibleTypes;

    return (getTypesApi?.response || [])?.map(p => ({ value: p?.type, label: p?.label })).filter(o => eligibleTypes.includes(o.value));
  };

  const getProviderOptions = type =>
    !!type ? (getTypesApi?.response || [])?.find(p => p?.type === type)?.providers?.map?.(p => ({ value: p, label: p })) : [];

  const submit = async values => {
    await api.request(values);
    onComplete();
  };

  const validationSchema = getValidationSchema(selectedType, register);
  const initialValues = getInitialValues(userId, supplierId, clientId, selectedType, identity);

  return (
    <div>
      <Popover
        fill
        position="bottom"
        content={
          <Menu>
            {getTypeOptions()?.map(p => (
              <MenuItem
                key={p.value}
                text={p.label}
                onClick={() => setSelectedType(p.value)}
                label={selectedType === p.value ? <Icon icon="small-tick" /> : null}
              />
            ))}
          </Menu>
        }
      >
        <FormGroup classes={{ wrapper: classes.providerTypeFormGroups }} label="Provider type">
          <Button
            fill
            minimal
            alignText="left"
            rightIcon="caret-down"
            text={getTypeOptions()?.find(o => o.value === selectedType)?.label || 'Select provider type...'}
            loading={getTypesApi?.loading}
            disabled={getTypesApi?.loading}
          />
        </FormGroup>
      </Popover>
      <Form initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={submit}>
        {form => (
          <React.Fragment>
            <Fields.Select name="providerName" label="Provider" options={getProviderOptions(selectedType)} disabled={!selectedType} />
            {getExternalIdentifierField(form?.values, register)}
            <Button type="submit" fill outlined text="Submit" loading={api.loading} />
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};

const getExternalIdentifierField = (values, register) => {
  if (!!register) {
    return null;
  }

  switch (values?.providerType) {
    case 'background-check':
    case 'payroll':
      return <Fields.Text name="externalUserId" label={`${values?.providerName || 'External'} identifier`} disabled={!values?.providerName} />;

    case 'invoice':
    case 'payout':
      return <Fields.Text name="externalOrganizationId" label={`${values?.providerName || 'External'} identifier`} disabled={!values?.providerName} />;

    default:
      return null;
  }
};

const getInitialValues = (userId, supplierId, clientId, providerType, identity) => {
  switch (providerType) {
    case 'background-check':
      return {
        ...(identity || {}),
        providerType: identity?.providerType || providerType,
        providerName: identity?.providerName || null,
        userId: identity?.userId || userId,
        organizationId: identity?.organizationId || supplierId || 'P-greenlight',
        externalUserId: identity?.externalUserId || '',
      };

    case 'payroll':
      return {
        ...(identity || {}),
        providerType: identity?.providerType || providerType,
        providerName: identity?.providerName || null,
        userId: identity?.userId || userId,
        organizationId: identity?.organizationId || supplierId || 'P-greenlight',
        externalUserId: identity?.externalUserId || '',
      };

    case 'payout':
      return {
        ...(identity || {}),
        providerType: identity?.providerType || providerType,
        providerName: identity?.providerName || null,
        organizationId: identity?.organizationId || supplierId || 'P-greenlight',
        externalOrganizationId: identity?.externalOrganizationId || '',
      };

    case 'invoice':
      return {
        ...(identity || {}),
        providerType: identity?.providerType || providerType,
        providerName: identity?.providerName || null,
        organizationId: identity?.organizationId || clientId,
        externalOrganizationId: identity?.externalOrganizationId || '',
      };

    default:
      return { providerType };
  }
};

const getValidationSchema = (providerType, register) => {
  if (register) {
    return Yup.object().shape({
      providerName: Yup.string().required('Provider is required'),
    });
  }

  switch (providerType) {
    case 'BackgroundCheck':
      return Yup.object().shape({
        providerName: Yup.string().required('Provider is required'),
        externalOrganizationId: Yup.string().required('External organization identifier is required'),
      });

    case 'Payroll':
      return Yup.object().shape({
        providerName: Yup.string().required('Provider is required'),
        externalOrganizationId: Yup.string().required('External organization identifier is required'),
      });

    case 'Payout':
      return Yup.object().shape({
        providerName: Yup.string().required('Provider is required'),
        externalOrganizationId: Yup.string().required('External organization identifier is required'),
      });
    case 'Invoice':
      return Yup.object().shape({
        providerName: Yup.string().required('Provider is required'),
        externalOrganizationId: Yup.string().required('External organization identifier is required'),
      });

    default:
      return undefined;
  }
};

const ExternalIdentities = {
  Form: ExternalIdentitiesForm,
};
export default ExternalIdentities;
