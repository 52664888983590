import React from 'react';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { Button } from '@blueprintjs/core';
import moment from 'moment/moment';

import Fields from '../../../fields';
import Form from '../../../form';

import WithConfirmation from '../../../../hooks/with-confirmation';
import useApi from '../../../../hooks/use-api';
import { createFlowStepReviewInsight, deleteFlowStepReviewInsight } from '../../../../apis/wizards';

import classes from './notes-popover.module.scss';

const PopoverContent = ({ insights, reviewId, fieldId, categoryId, onComplete }) => {
  const createApi = useApi(createFlowStepReviewInsight, { skip: true });
  const deleteApi = useApi(deleteFlowStepReviewInsight, { skip: true });

  const submit = async (values, actions) => {
    await createApi.request(reviewId, values);
    actions.setFieldValue('notes', '');
    onComplete();
  };

  const handleDelete = async insightId => {
    await deleteApi.request(reviewId, insightId);
    onComplete();
  };

  return (
    <Form initialValues={{ notes: '', field: fieldId, category: categoryId }} enableReinitialize onSubmit={submit}>
      {form => {
        return (
          <div className={classes.insights}>
            {insights && (
              <SimpleBar className={classes.wrapper}>
                <div className={classes.inner}>
                  {insights.map(insight => {
                    return (
                      <div className={classNames(classes.message)} key={insight.id}>
                        <div className={classes.details}>
                          <div className={classes.text}>{insight.notes}</div>
                          <div className={classes.date}>{moment(insight.createdAt).format('Do MMMM YYYY HH:mm')}</div>
                        </div>
                        <div className={classes.actions}>
                          <WithConfirmation
                            fill={false}
                            messages={{ question: `Are you sure you want to remove the note "${insight.notes}"?`, confirmButton: 'Remove' }}
                          >
                            <Button
                              className={classes.actionButton}
                              small
                              minimal
                              icon="cross"
                              loading={deleteApi.loading}
                              onClick={() => handleDelete(insight.id)}
                            />
                          </WithConfirmation>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SimpleBar>
            )}
            <div className={classes.messageForm}>
              <Fields.Text
                label="Notes..."
                name="notes"
                outline={false}
                minimal={true}
                showError={false}
                extra={{
                  rightElement: (
                    <Button type="submit" minimal fill icon="send-message" disabled={createApi.loading || !form?.values?.notes} loading={createApi.loading} />
                  ),
                }}
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default PopoverContent;
