import React, { useState } from 'react';
import { Button, ButtonGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Yup from 'yup';

import Fields from '../../../../../../../fields';
import Form, { Field } from '../../../../../../../form';

import classes from '../custom-fields.module.scss';

const validationDateTypeOptions = [
  { value: 'relative', label: 'Relative' },
  { value: 'absolute', label: 'Absolute' },
];

const DateValidation = ({ name, label, description, relativeOptions, targetFieldsOptions, showError = true }) => {
  const [open, setOpen] = useState(false);

  const submit = (values, form) => {
    const value = values.type === 'relative' ? { amount: values.amount, unit: values.unit, field: '' } : { amount: '', unit: '', field: values.field };
    form.setFieldValue(name, value);
    form.submitForm();
    setOpen(false);
  };

  const clearCondition = form => {
    form.setFieldValue(name, { amount: '', unit: '', field: '' });
    form.submitForm();
  };

  const getInitType = values => {
    if (!values?.amount && !values?.field) {
      return undefined;
    }
    if (values.field) {
      return 'absolute';
    }
    return 'relative';
  };
  const getValueLabel = values => {
    if (!values?.amount && !values?.field) {
      return undefined;
    }
    if (values.field) {
      return `Field: ${targetFieldsOptions.find(i => i.value === values.field)?.label}`;
    }
    return `${values.amount} ${relativeOptions.find(i => i.value === values.unit)?.label}`;
  };

  return (
    <Field name={name} label={label} description={description} showError={showError}>
      {({ form, field }) => {
        const valueLabel = getValueLabel(field.value);
        return (
          <div className={classes.date}>
            <Popover
              isOpen={open}
              popoverClassName={classes.popover}
              content={
                <div className={classes.content}>
                  <div className={classes.inner}>
                    <Form
                      initialValues={{ ...field.value, unit: field.value?.unit || relativeOptions[0].value, type: getInitType(field.value) }}
                      validationSchema={Yup.object().shape({
                        type: Yup.string().label('Type').required(),
                        amount: Yup.number()
                          .label('Amount')
                          .test({
                            name: 'is-amount-valid',
                            test: (value, ctx) => {
                              if (ctx.parent.type === 'absolute') {
                                return true;
                              }
                              if (value === undefined || value === '') {
                                return ctx.createError({ message: `Amount of ${ctx.parent.unit} ago is required field` });
                              }

                              return true;
                            },
                          }),
                        field: Yup.string()
                          .label('Target field')
                          .when('type', {
                            is: 'absolute',
                            then: schema => schema.required(),
                          }),
                      })}
                      enableReinitialize
                      onSubmit={values => submit(values, form)}
                    >
                      {({ values, setFieldValue }) => {
                        return (
                          <React.Fragment>
                            <div className={classes.title}>Configure date validation</div>
                            <div className={classes.description}>
                              Select validation type, absolute (target other date field) or relative (days, months or year ago/until).
                            </div>
                            <Fields.Select key={values.field} label="Type" name="type" options={validationDateTypeOptions} />
                            {values.type === 'relative' && (
                              <Fields.Text
                                label={label}
                                name="amount"
                                type="number"
                                extra={{
                                  rightElement: (
                                    <Popover
                                      content={
                                        <Menu>
                                          {relativeOptions.map(unit => (
                                            <MenuItem
                                              text={unit.label}
                                              onClick={() => {
                                                setFieldValue('unit', unit.value);
                                              }}
                                              key={unit.value}
                                            />
                                          ))}
                                        </Menu>
                                      }
                                      placement="bottom-end"
                                    >
                                      <Button minimal={true} rightIcon="caret-down">
                                        {relativeOptions.find(unit => unit.value === values.unit)?.label}
                                      </Button>
                                    </Popover>
                                  ),
                                }}
                              />
                            )}
                            {values.type === 'absolute' && <Fields.Select label="Target field" name="field" options={targetFieldsOptions} />}
                            <div className={classes.row}>
                              <Button outlined text="Submit" type="submit" />
                              <Button outlined text="Cancel" onClick={() => setOpen(false)} />
                            </div>
                          </React.Fragment>
                        );
                      }}
                    </Form>
                  </div>
                </div>
              }
              placement="bottom-end"
            >
              {valueLabel ? (
                <ButtonGroup fill>
                  <Button className={classes.dateValidationButton} fill alignText="left" outlined text={valueLabel} onClick={() => setOpen(true)} />
                  <Button className={classes.dateValidationButton} outlined icon="cross" onClick={() => clearCondition(form)} />
                </ButtonGroup>
              ) : (
                <Button className={classes.dateValidationButton} alignText="left" fill minimal outlined text="Unset" onClick={() => setOpen(true)} />
              )}
            </Popover>
          </div>
        );
      }}
    </Field>
  );
};

export default DateValidation;
