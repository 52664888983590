import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Page from '../../../components/page';
import Timeline from '../../../components/timeline';

import { getTimeBasedGreeting } from '../../../utilities/common';

import useTable from '../../../hooks/use-table';
import { getOnboardingEngagements, getEngagementTerminations, getExpiringEngagements, getEngagementUpdates } from '../../../apis/platform/engagements';

import OnboardingBox from './components/onboarding-box';
import OffboardingBox from './components/offboarding-box';
import ExpiringBox from './components/expiring-box';
import UpdatesBox from './components/updates-box';

import classes from './dashboard.module.scss';

const Dashboard = ({ user }) => {
  const [drawer, setDrawer] = useState({ open: false, type: undefined, data: undefined });

  const onboardingTable = useTable(getOnboardingEngagements, { pageSize: 5 });
  const offboardingTable = useTable(getEngagementTerminations, { pageSize: 5 });
  const expiringTable = useTable(getExpiringEngagements, { pageSize: 5 });
  const updatesTable = useTable(getEngagementUpdates, { pageSize: 5 });

  const reloadOnboardingList = () => onboardingTable?.reload();

  return (
    <Page title={`${getTimeBasedGreeting()}, ${user?.personalDetails?.firstName}.`}>
      <div className={classes.dashboard}>
        <OnboardingBox {...{ drawer, setDrawer, table: onboardingTable }} />
        <UpdatesBox {...{ drawer, setDrawer, table: updatesTable }} />
        <OffboardingBox {...{ drawer, setDrawer, table: offboardingTable }} />
        <ExpiringBox {...{ drawer, setDrawer, table: expiringTable }} />
      </div>
      <Timeline
        open={drawer?.open}
        engagementId={drawer?.engagementId}
        onClose={() => setDrawer({ open: false, engagementId: undefined })}
        reloadList={reloadOnboardingList}
      />
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
