import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Form from '../../../../../../../../components/form';
import Fields from '../../../../../../../../components/fields';

import { GenderOptions } from '../../../../../../../../domain/gender';
import { MaritalStatusOptions } from '../../../../../../../../domain/marital-status';

import useApi from '../../../../../../../../hooks/use-api';
import { setPersonalDetails } from '../../../../../../../../apis/profile';

import classes from '../../../details.module.scss';
import { dateOfBirthMaxDate } from '../../../../../../../../utilities/date';

const PersonalInfoForm = ({ isOpen = false, onClose, user, onSave }) => {
  const setPersonalDetailsApi = useApi(setPersonalDetails, { skip: true });

  const submit = async values => {
    await setPersonalDetailsApi.request(values);
    onSave();
  };

  const loading = setPersonalDetailsApi.loading;

  return (
    <Dialog title="Personal Information" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          userId: user?.id,
          firstName: user?.personalDetails?.firstName || '',
          lastName: user?.personalDetails?.lastName || '',
          middleName: user?.personalDetails?.middleName || '',
          dateOfBirth: user?.personalDetails?.dateOfBirth || '',
          gender: user?.personalDetails?.gender || '',
          maritalStatus: user?.personalDetails?.maritalStatus || '',
          nationalIdentificationNumber: user?.personalDetails?.nationalIdentificationNumber || '',
          ethnicity: user?.personalDetails?.ethnicity || '',
          phone: user?.personalDetails?.phone || '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          dateOfBirth: Yup.mixed().required('Date of birth is required'),
          nationalIdentificationNumber: Yup.string().required('National Identification Number (SSN, NIC, ...) is required'),
          phone: Yup.string().required('Phone number is required'),
        })}
        onSubmit={submit}
      >
        <div className={classes.hRow}>
          <Fields.Text fill label="First Name" name="firstName" />
          <Fields.Text fill label="Last Name" name="lastName" />
        </div>
        <Fields.Text label="Middle Name(s)" name="middleName" />
        <div className={classes.hRow}>
          <Fields.Date label="Date of birth" name="dateOfBirth" maxDate={dateOfBirthMaxDate()} />
          <Fields.Select label="Gender" name="gender" options={GenderOptions} />
          <Fields.Select label="Marital Status" name="maritalStatus" options={MaritalStatusOptions} />
        </div>
        <Fields.Text label="National Identification Number" description="ie. SSN, NIC,... etc." name="nationalIdentificationNumber" />
        <Fields.Text label="Ethnicity" name="ethnicity" />
        <Fields.Text label="Phone" name="phone" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};
export default PersonalInfoForm;
