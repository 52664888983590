import React, { useState } from 'react';
import moment from 'moment';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Dialog from '../../../../dialog';

import WithConfirmation from '../../../../../hooks/with-confirmation';
import useApi from '../../../../../hooks/use-api';
import { approveEngagementUpdateRequest, cancelEngagementUpdateRequest, getEngagementUpdate } from '../../../../../apis/platform/engagements';
import { updateReview } from '../../../../../apis/wizards';

import UpdatesForm from './updates-form';

import classes from './updates.module.scss';

const TimelineUpdateRequest = ({ engagement, step, loadTimeline, closeStepDetails, review, loadReview }) => {
  const [dialog, setDialog] = useState({ open: false });
  const getUpdatesApi = useApi(getEngagementUpdate, { params: { engagementId: engagement?.id }, skip: !engagement?.id });
  const cancelUpdateRequestApi = useApi(cancelEngagementUpdateRequest, { skip: true });
  const cancelUpdates = async () => {
    await cancelUpdateRequestApi?.request({ engagementId: engagement?.id });
    await getUpdatesApi?.request({ engagementId: engagement?.id });
    closeStepDetails();
    loadTimeline();
  };

  const approveUpdatesRequestApi = useApi(approveEngagementUpdateRequest, { skip: true });
  const updateReviewApi = useApi(updateReview, { skip: true });
  const approveUpdates = async () => {
    await approveUpdatesRequestApi?.request({ engagementId: engagement?.id });

    const data = {
      reviewId: review.id,
      flowId: step.review?.flowReviewId,
      instanceId: step?.instanceId,
      result: true,
      elaboration: 'Updates request approved.',
    };

    await updateReviewApi.request(data);

    closeStepDetails();
    loadReview();
    loadTimeline();
  };

  if (step?.contextType !== 'engagementupdaterequestobjectcontext') {
    return null;
  }

  const loading = getUpdatesApi.loading;

  return (
    <div className={classes.updates}>
      <Box
        title="Updates request"
        small
        monochrome
        loading={loading}
        actions={
          <ButtonGroup>
            <WithConfirmation
              messages={{
                question: `Are you sure you want to approve update request ? Doing this will apply all the engagement changes listed below on (${moment(
                  getUpdatesApi?.response?.endDate,
                ).format('MM/DD/YYYY')}).`,
                confirmButton: 'Approve',
              }}
            >
              <Button small outlined icon={<Icons.Check size={16} strokeWidth={1.5} />} onClick={approveUpdates} />
            </WithConfirmation>
            <WithConfirmation messages={{ question: `Are you sure you want to cancel update request ?`, confirmButton: 'Yes' }}>
              <Button small outlined icon={<Icons.X size={16} strokeWidth={1.5} />} onClick={cancelUpdates} />
            </WithConfirmation>
            <Button small outlined icon={<Icons.Edit2 size={16} strokeWidth={1.5} />} onClick={() => setDialog({ open: true })} />
          </ButtonGroup>
        }
      >
        <Box.Item label="Requested date">{moment(getUpdatesApi?.response?.data?.updateDate).format('MM/DD/YYYY')}</Box.Item>
        <Box.ItemContainer>
          <div className={classes.sectionTitle}>General</div>
        </Box.ItemContainer>
        <Box.Item label="Title">{getUpdatesApi?.response?.data?.title}</Box.Item>
        <Box.Item label="Description">{getUpdatesApi?.response?.data?.description}</Box.Item>
        <Box.Item label="End date">{moment(getUpdatesApi?.response?.endDate).format('MM/DD/YYYY')}</Box.Item>
        <Box.ItemContainer>
          <div className={classes.sectionTitle}>Compensation</div>
        </Box.ItemContainer>
        <Box.Item label="Frequency">{getUpdatesApi?.response?.data?.frequency}</Box.Item>
        <Box.Item label="Type">{getUpdatesApi?.response?.data?.type}</Box.Item>
        <Box.Item label="Allocation">{getUpdatesApi?.response?.data?.allocation}</Box.Item>
        <Box.Item label="Currency">{getUpdatesApi?.response?.data?.currencyId}</Box.Item>
        <Box.Item label="Amount">{getUpdatesApi?.response?.data?.amount}</Box.Item>
        <Box.Item label="Tracks work ?">{!!getUpdatesApi?.response?.data?.tracksWork ? 'Yes' : 'No'}</Box.Item>
      </Box>
      <Dialog title="Modify engagement update request" isOpen={dialog?.open} onClose={() => setDialog({ open: false })}>
        <UpdatesForm {...{ getUpdatesApi, onComplete: () => setDialog({ open: false }) }} />
      </Dialog>
    </div>
  );
};

export default TimelineUpdateRequest;
