import { Button, Menu, MenuDivider, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import ReactJson from 'react-json-view';
import { SortableElement } from 'react-sortable-hoc';
import SimpleBar from 'simplebar-react';
import { useNavigate } from 'react-router';

import useAutosave from '../../../../hooks/use-autosave';
import useApi from '../../../../hooks/use-api';
import { getRecord, getRecordValue, updateRecord } from '../../../../apis/configuration';
import { publishTemplate, uploadTemplate } from '../../../../apis/wizards';
import { uuid } from '../../../../utilities/common';
import { downloadStringAsFile } from '../../../../utilities/files';
import { Container } from '../common/components/sortable';
import { WizardContext } from '../state';
import { WizardInstanceContext } from '../state/instance-provider';
import { WizardOrderContext } from '../state/order-provider';
import { WizardSelectionContext } from '../state/selection-provider';
import ImportDialog from './import-dialog';
import ToolboxLoading from './loading';
import Page from './page';
import Properties from './properties';

import classes from './toolbox.module.scss';

const Item = SortableElement(({ page }) => <Page page={page} />);

async function updateWizardConfigurationRecord({ status, recordId }) {
  const record = await getRecord(recordId);
  const recordValue = await getRecordValue(recordId);
  const description = status === 0 ? '(Draft)' : status === 1 ? '' : record?.description;
  const newRecord = await updateRecord(recordId, {
    scopeId: record.scopeId,
    description,
    data: {
      documentTemplateType: recordValue?.documentTemplateType,
      documentName: recordValue?.documentName,
      templateId: recordValue.templateId,
    },
    configurationType: record.configurationType,
  });
  return newRecord;
}

const Toolbox = ({ recordId }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { loading, template, addPage, isDesigner, fetchTemplateInfo, isPublished, templateInfo } = useContext(WizardContext);
  const saveApi = useApi(() => uploadTemplate(templateInfo.id, template), { skip: true });
  const publishApi = useApi(() => publishTemplate(templateInfo.id), { skip: true });
  const updateConfigurationRecordApi = useApi(updateWizardConfigurationRecord, { skip: true });
  const { reorderPages } = useContext(WizardOrderContext);
  const { selectTemplate } = useContext(WizardSelectionContext);
  const { instance } = useContext(WizardInstanceContext);
  const handleSave = async () => {
    await saveApi.request();
    if (templateInfo.status === 0) {
      const newRecord = await updateConfigurationRecordApi.request({ status: templateInfo.status, recordId });
      navigate(`/settings/wizards/${templateInfo?.id}/${newRecord?.data?.id}`, { replace: true });
    }
  };

  const handlePublish = async () => {
    await publishApi.request();
    void fetchTemplateInfo();
    const newRecord = await updateConfigurationRecordApi.request({ status: 1, recordId });
    navigate(`/settings/wizards/${templateInfo?.id}/${newRecord?.data?.id}`, { replace: true });
  };

  useAutosave({ payload: template, onSave: handleSave });

  if (!isDesigner) {
    return null;
  }
  if (loading) {
    return <ToolboxLoading />;
  }
  if (!template) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.toolbox}>
        <div className={classes.header}>
          <div className={classes.main}>
            <div className={classes.title}>
              <div className={classes.name}>{template.title}</div>
              {templateInfo.status !== 1 && <div className={classes.draft}>(Draft)</div>}
            </div>
            <div className={classes.description}>{template.description}</div>
          </div>
          <Popover
            placement="bottom"
            content={
              <Menu>
                <MenuItem text="Add page" icon="plus" onClick={() => addPage()} />
                <MenuDivider />
                <MenuItem text="Properties" icon="settings" onClick={selectTemplate} />
                <MenuDivider />
                <MenuItem text="Export" icon="code-block">
                  <MenuItem text="Template" icon="application" onClick={() => downloadStringAsFile(JSON.stringify(template), template?.title, 'json')} />
                  <MenuItem
                    text="Values"
                    icon="applications"
                    onClick={() => downloadStringAsFile(JSON.stringify(instance), `${template?.title || 'instance'}-${uuid()}`, 'json')}
                  />
                </MenuItem>
                <MenuItem text="Debug" icon="code">
                  <Popover
                    fill
                    interactionKind="click"
                    position="right"
                    content={
                      <SimpleBar style={{ padding: '15px', maxHeight: '80vh', width: '40vw' }}>
                        <ReactJson src={{ ...template }} />
                      </SimpleBar>
                    }
                  >
                    <MenuItem shouldDismissPopover={false} text="Template" icon="application" />
                  </Popover>
                  <Popover
                    fill
                    interactionKind="click"
                    position="right"
                    content={
                      <SimpleBar style={{ padding: '15px', maxHeight: '80vh', width: '40vw' }}>
                        <ReactJson src={{ ...instance }} />
                      </SimpleBar>
                    }
                  >
                    <MenuItem shouldDismissPopover={false} text="Values" icon="applications" />
                  </Popover>
                </MenuItem>
                <MenuItem text="Import" icon="import" onClick={() => setIsOpen(true)} />
                <MenuDivider />
                <MenuItem text="Save" icon="floppy-disk" onClick={handleSave} />
                <MenuItem text="Publish" icon="cloud-upload" disabled={isPublished} onClick={handlePublish} />
              </Menu>
            }
          >
            <Button minimal icon="more" loading={saveApi.loading || publishApi.loading || updateConfigurationRecordApi.loading} />
          </Popover>
        </div>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.pages}>
            <Container useDragHandle onSortEnd={reorderPages}>
              {template.pages?.map?.((page, i) => (
                <Item key={page.id} index={i} page={page} />
              ))}
            </Container>
          </div>
        </SimpleBar>
        <div className={classes.footer}>
          <Button
            fill
            outlined
            icon={saveApi.loading ? <Spinner size={16} /> : 'floppy-disk'}
            alignText="left"
            text={saveApi.loading ? 'Saving' : 'Save'}
            onClick={handleSave}
            disabled={saveApi.loading || updateConfigurationRecordApi.loading}
          />
          <Button
            fill
            outlined
            icon="cloud-upload"
            alignText="left"
            text="Publish"
            loading={publishApi.loading || saveApi.loading || updateConfigurationRecordApi.loading}
            disabled={isPublished}
            onClick={handlePublish}
          />
        </div>
      </div>
      <ImportDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Properties />
    </React.Fragment>
  );
};

export default Toolbox;
