import Clients from '../configuration/client';
import { toQueryString } from '../utilities/common';

export const getBankingFields = async countryId => {
  const response = await Clients.Api.get(`ui/banking/fields/${countryId}`);
  return response?.data;
};

/**
 * Get the list of bank accounts.
 * UserId or OrganizationId have to be provided:
 *  1. in case of organizational bank accounts provide organizationId.
 *  2. in case of individual user bank accounts provide userId.
 */
export const listBankAccounts = async ({ userId, organizationId }) => {
  const response = await Clients.Api.get(`ui/banking/${toQueryString({ userId, organizationId })}`);
  return response?.data;
};

export const getBankAccount = async id => {
  const response = await Clients.Api.get(`ui/banking/${id}`);
  return response?.data;
};

export const createBankAccount = async data => {
  const response = await Clients.Api.post(`ui/banking/`, data);
  return response?.data;
};

export const updateBankAccount = async (id, data) => {
  const response = await Clients.Api.put(`ui/banking/${id}`, data);
  return response?.data;
};

export const removeBankAccount = async id => {
  const response = await Clients.Api.delete(`ui/banking/${id}`);
  return response?.data;
};
