import moment from 'moment';
import { getSchedule, stringToArray } from 'cron-converter';
import { DateFormat } from '../../../models/enumerations/date-format';

export const parseDate = (value, format = DateFormat.DEFAULT) => {
  if (!value) {
    return null;
  }

  let date = moment(value, format);
  if (date.isValid()) {
    return date.toDate();
  }

  date = new Date(value);
  if (moment(date).isValid()) {
    return date;
  }

  return null;
};

// todo cover this function with unit tests and test more edge cases
export const calculateInitMonth = (value, minDate, maxDate, format = DateFormat.DEFAULT) => {
  const now = moment().toDate();
  const min = minDate || now;
  const max = maxDate || now;

  if (value) {
    const initDate = moment(value, format);
    if (initDate.isValid()) {
      return initDate.toDate();
    }
    return now;
  }

  if (min < now && max > now) {
    return now;
  }

  if (max < now) {
    return max;
  }

  if (min > now) {
    return min;
  }

  // if (!value && !minDate) {
  //   return moment().toDate();
  // }
  //
  // if (!value && minDate) {
  //   return minDate;
  // }
  //
  // const initDate = moment(value, format);
  // if (initDate.isValid()) {
  //   return initDate.toDate();
  // }
  return null;
};

export function isDisabled(day, cadence) {
  if (!cadence) {
    return false;
  }

  const arr = stringToArray(cadence);
  let reference = moment(day).toDate();
  const next = getSchedule(arr, reference).next();
  return moment(next.toString()).format(DateFormat.DEFAULT) !== moment(day).format(DateFormat.DEFAULT);
}
