export const OrganizationType = {
  Platform: 1,
  Client: 1024,
  Vendor: 65536,
  Supplier: 1048576,
};

export const OrganizationTypeOptions = [
  { label: 'Platform', value: OrganizationType.Platform },
  { label: 'Client', value: OrganizationType.Client },
  { label: 'Vendor', value: OrganizationType.Vendor },
  { label: 'Supplier', value: OrganizationType.Supplier },
];

export const organizationTypeToLabel = type => OrganizationTypeOptions.find(c => c.value === type)?.label;
