export const PurchaseOrderType = {
  Available: 'available',
  InProcess: 'in-process',
  NotRequired: 'not-required',
};

export const PurchaseOrderTypeOptions = [
  { value: PurchaseOrderType.Available, label: 'Available' },
  { value: PurchaseOrderType.InProcess, label: 'In process' },
  { value: PurchaseOrderType.NotRequired, label: 'Not required' },
];

const PurchaseOrderTypeLabels = PurchaseOrderTypeOptions.reduce((mapper, status) => {
  mapper[status.value] = status.label;
  return mapper;
}, {});

export const purchaseOrderStatusToLabel = status => PurchaseOrderTypeLabels[status] || '-';
