import moment from 'moment';

import { toQueryString } from '../../utilities/common';

import { StorageManager } from '../../authentication/storage';
import Clients from '../../configuration/client';

export const getTimeRecords = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/time${toQueryString(parameters)}`);
  return response.data;
};

export const exportTimeRecords = async filters => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/time/export${toQueryString(parameters)}`);
  return response?.data;
};

export const getEngagementRecords = async ({ filters: { engagementId } }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/time/engagement/${engagementId}`);
  return response;
};

export const reviewRecords = async ({ status, records }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/review`, { status, records });
  return response?.data;
};

export const removeTrackingRecord = async recordId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/time/${recordId}`);
  return response?.data;
};

export const createShift = async shift => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/shift`, shift);
  return response?.data;
};

export const updateShift = async shift => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/time/shift/${shift?.id}`, shift);
  return response?.data;
};

export const createDeliverable = async deliverable => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/deliverable`, deliverable);
  return response?.data;
};

export const updateDeliverable = async deliverable => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/time/deliverable/${deliverable?.id}`, deliverable);
  return response?.data;
};

export const createExpense = async ({ date, amount, notes, receipt, engagementId, userId }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('date', date);
  data.append('amount', amount);
  data.append('notes', notes);
  data.append('receipt', receipt);
  data.append('engagementId', engagementId);
  data.append('userId', userId);
  const response = await Clients.Api.post(`ui/${organizationType}s/time/expense`, data, { onUploadProgress });
  return response?.data;
};

export const updateExpense = async ({ id, date, amount, notes, receipt, engagementId, userId }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('date', date);
  data.append('amount', amount);
  data.append('notes', notes);
  data.append('receipt', receipt);
  data.append('engagementId', engagementId);
  data.append('userId', userId);
  const response = await Clients.Api.put(`ui/${organizationType}s/time/expense/${id}`, data, { onUploadProgress });
  return response?.data;
};

export const getRecordComments = async recordId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/time/${recordId}/comments`);
  return response?.data;
};

export const createRecordComment = async ({ recordId, message }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/${recordId}/comment`, { recordId, message });
  return response?.data;
};

export const updateRecordComment = async ({ commentId, recordId, message }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/time/${recordId}/comment/${commentId}`, { recordId, message });
  return response?.data;
};

export const removeRecordComment = async ({ commentId, recordId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/time/${recordId}/comment/${commentId}`);
  return response?.data;
};

export const getTimeImportRequests = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/time/import${toQueryString(query)}`);
  return response?.data;
};

export const createTimeImportRequest = async ({ file }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('file', file);
  const response = await Clients.Api.post(`ui/${organizationType}s/time/import`, data, { onUploadProgress });
  return response?.data;
};

export const getTimeImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/time/import/${id}`);
  return response?.data;
};

export const validateTimeImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/import/${id}/validate`);
  return response?.data;
};

export const executeTimeImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/import/${id}/execute`);
  return response?.data;
};

export const cancelTimeImportRequest = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/time/import/${id}/cancel`);
  return response?.data;
};
