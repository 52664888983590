import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Form from '../../../../../../../../components/form';
import Fields from '../../../../../../../../components/fields';
import ClientPoliciesDescriptions from '../../../../../../../../components/organizational/client/client-policies-description';

import useApi from '../../../../../../../../hooks/use-api';
import { createClient } from '../../../../../../../../apis/platform/organizations';

const NewSubClient = ({ isOpen, parentId, onClose, reloadList }) => {
  const createClientApi = useApi(createClient, { skip: true });
  const loading = createClientApi.loading;

  const submit = async values => {
    await createClientApi.request(values);
    onClose();
    reloadList();
  };

  return (
    <Dialog title="New Client" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ name: '', parentId, isInvoiced: false }}
        validationSchema={Yup.object().shape({ name: Yup.string().required('Client name is required') })}
        onSubmit={submit}
      >
        {form => (
          <React.Fragment>
            <Fields.Text name="name" label="Name" />
            <Fields.Select
              label="Will Invoices need to be created for this client ?"
              name="isInvoiced"
              options={[
                { label: 'Invoices will be created for this client.', value: true },
                { label: 'Invoices will not be created for this client.', value: false },
              ]}
            />
            {ClientPoliciesDescriptions.isInvoiced(form?.values)}
            <Button type="submit" outlined fill text="Submit" disabled={loading} loading={loading} />
          </React.Fragment>
        )}
      </Form>
    </Dialog>
  );
};

export default NewSubClient;
