import MacroTypes from '../../../../../enumerations/macro-types';
import DateConfigForm from './date-config-form';
import SubstringConfigForm from './substring-config-form';
import CellConfigForm from './cell-config-form';
import CheckboxConfigForm from './checkbox-config-form';
import CustomJsConfigForm from './custom-js-config-form';

const TypeSpecificFields = ({ type, disabled }) => {
  switch (type) {
    case MacroTypes.DEFAULT:
      return null;

    case MacroTypes.DATE:
      return <DateConfigForm disabled={disabled} />;

    case MacroTypes.SUBSTRING:
      return <SubstringConfigForm disabled={disabled} />;

    case MacroTypes.CELL:
      return <CellConfigForm disabled={disabled} />;

    case MacroTypes.CHECKBOX:
      return <CheckboxConfigForm disabled={disabled} />;

    case MacroTypes.CUSTOM_JS:
      return <CustomJsConfigForm disabled={disabled} />;

    default:
      return null;
  }
};

export default TypeSpecificFields;
