import { MACRO_DATE_TIME_FORMATS } from './constants';
import moment from 'moment/moment';

const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

function getWizardSpecificMacros(data, array, parentInitialData) {
  data?.forEach?.(obj => {
    if (parentInitialData || obj.isInitialData) {
      const name = obj.path;
      const label = obj.name.replace(regExp, '$1$4 $2$3$5');
      let items = [];
      if (obj.baseType === 'datetime') {
        items = MACRO_DATE_TIME_FORMATS.map(format => ({
          name: `!!${name} ? moment(${name}).format('${format}') : ''`,
          label: moment(obj.example).format(format),
          example: moment(obj.example).format(format),
        }));
      } else if (obj.children.length) {
        items = getWizardSpecificMacros(obj.children, [], {
          name,
          parentInitialData: parentInitialData || obj.isInitialData,
        });
        items.sort((x, y) => x.items.length - y.items.length);
      }
      array.push({ name, label, example: obj.example, items });
    }
  });

  return array;
}

export default getWizardSpecificMacros;
