import moment from 'moment/moment';

export const getFullName = user => {
  if (!user?.personalDetails) {
    return 'Not provided';
  }
  const name = [
    `${user?.personalDetails?.firstName || ''}`.trim(),
    `${user?.personalDetails?.middleName || ''}`.trim(),
    `${user?.personalDetails?.lastName || ''}`.trim(),
  ]
    .join(' ')
    .replace('  ', ' ');

  return name;
};

export const getDateOfBirth = user => {
  if (!user?.personalDetails?.dateOfBirth) {
    return 'Not provided';
  }
  const dob = moment(user.personalDetails?.dateOfBirth);
  return dob.isValid() ? dob.format('Do MMMM YYYY') : 'Not provided';
};
