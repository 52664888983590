import React, { useContext } from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';

import Field from '../../domain/field';
import { TypeDefinitions } from '../../domain/types';

import WithConfirmation from '../../../../../hooks/with-confirmation';

import { WizardContext } from '../../state';
import { WizardExpandContext } from '../../state/expand-provider';
import { WizardSelectionContext } from '../../state/selection-provider';
import classes from './group.module.scss';

const GroupMenu = ({ page, group }) => {
  const { template, addField, cloneGroup, moveGroup, removeGroup } = useContext(WizardContext);
  const { isGroupExpanded, toggleGroup } = useContext(WizardExpandContext);
  const { selectGroup } = useContext(WizardSelectionContext);

  const canNotBeRemoved = group?.fields?.some?.(f => !!f?.mandatory);

  return (
    <Popover
      content={
        <Menu>
          <MenuItem text="Properties" icon="settings" onClick={() => selectGroup(page, group)} />
          <MenuItem text="Add field" icon="plus">
            {Object.values(TypeDefinitions)
              .filter(t => t.field)
              .map(t => (
                <MenuItem
                  key={t.name}
                  className={classes.fieldItem}
                  onClick={() => {
                    addField(page, group, new Field({ type: t.name }));
                    if (!isGroupExpanded(group.id)) {
                      toggleGroup(group.id);
                    }
                  }}
                  text={
                    <div className={classes.info}>
                      <div className={classes.name}>{t.label}</div>
                      <div className={classes.description}>{t.description}</div>
                    </div>
                  }
                  icon={t.icon}
                />
              ))}
          </MenuItem>
          <MenuItem text="Duplicate group" icon="duplicate" onClick={() => cloneGroup(page, group)} />
          <MenuItem text="Move" icon="cut" shouldDismissPopover={false}>
            {template?.pages?.map?.(p => (
              <MenuItem key={p?.id} text={p?.title} disabled={page.id === p.id} onClick={() => moveGroup(page, group, p)} />
            ))}
          </MenuItem>
          <MenuDivider />
          <WithConfirmation
            messages={{
              question: `Are you sure you want to remove ${group?.title} group ? All fields in this group will also be removed, as well as any visibility conditions that are based on these fields.`,
            }}
            icon={true}
            disabled={canNotBeRemoved}
          >
            <MenuItem text="Remove" icon="cross" disabled={canNotBeRemoved} onClick={() => removeGroup(page, group)} shouldDismissPopover={false} />
          </WithConfirmation>
        </Menu>
      }
    >
      <Button className={classes.actions} minimal icon="more" />
    </Popover>
  );
};

export default GroupMenu;
