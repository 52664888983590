import React, { useState } from 'react';

import Avatar from '../../../../components/avatar';
import Page from '../../../../components/page';
import Table from '../../../../components/table';

import { capitalize, uuid } from '../../../../utilities/common';
import { displayPeriod, getTotalAmount, getTotalHours } from '../../../../utilities/time';
import { downloadStringAsFile } from '../../../../utilities/files';

import { abbreviateClassification } from '../../../../domain/classification-type';
import { TrackingRecordStatusTypes } from '../../../../domain/tracking-record-status-type';
import { CompensationFrequency } from '../../../../domain/compensation-frequency';

import useTable from '../../../../hooks/use-table';
import { getTimeReviewRecords, reviewRecords, exportTimeReviewRecords } from '../../../../apis/client/time';
import toaster from '../../../../components/toaster';

import TimeMenu from './menu';
import TimeFilters from './filters';
import TimeApprovalDetails from './details';
import StatusLabel from './details/boxes/status-label';
import { getGroupStatus } from './utilities';

const TimeReview = () => {
  const [details, setDetails] = useState({ open: false, supplierId: undefined });
  const defaultFilters = {
    query: '',
    classification: undefined,
    frequency: CompensationFrequency.Weekly,
    currencies: undefined,
    countries: undefined,
    showEmpty: false,
  };
  const { items, loading, filters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    getSupplierRecordsForReview,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const exportRecords = () => {
    void toaster.export({
      progress: {
        title: 'Exporting time review records',
      },
      promise: () => exportTimeReviewRecords(filters),
      onSuccess: csv => downloadStringAsFile(csv, `TimeReviewRecords-${uuid()}`, 'csv'),
    });
  };

  const updateStatus = async updates => {
    await reviewRecords(updates);
    reload();
  };

  return (
    <Page
      title="Time Review"
      path={[
        { name: 'Time', to: '/time' },
        { name: 'Time Review', to: '/time/review' },
      ]}
      scroll={false}
    >
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={filters}
          menu={<TimeMenu updateStatus={updateStatus} onExport={exportRecords} suppliers={items} />}
          content={<TimeFilters />}
        />
        <Table.Columns.Default
          showOnMobile
          name="Name"
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} name={abbreviateClassification(d?.classification)} />}
        />
        <Table.Columns.TwoLines width="2fr" showOnMobile lines={[d => `${d?.firstName} ${d?.lastName}`, d => d.email]} />
        <Table.Columns.TwoLines name="Period" lines={[d => displayPeriod(d), d => capitalize(d?.frequency) || '-']} />
        <Table.Columns.Default name="Status" render={d => <StatusLabel status={getGroupStatus(d.records)} />} />
        <Table.Columns.TwoLines showOnMobile width="100px" lines={[d => getTotalHours(d.records), d => getTotalAmount(d.records, d.currencySymbol)]} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', onClick: d => setDetails({ open: true, supplierId: d?.supplierId }) },
            { divider: true },
            {
              name: 'Approve',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: d.records?.map(r => r.id) }),
              confirmation: { messages: { confirmButton: 'Approve' } },
              disabled: d => d?.records?.every?.(r => r?.status === TrackingRecordStatusTypes.Approved),
            },
            {
              name: 'Dispute',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: d.records?.map(r => r.id) }),
              confirmation: { messages: { confirmButton: 'Dispute' } },
              disabled: d => d?.records?.every?.(r => r?.status === TrackingRecordStatusTypes.Approved),
            },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <TimeApprovalDetails
        isOpen={!!details?.open}
        supplier={items?.find(i => i?.supplierId === details?.supplierId)}
        updateStatus={updateStatus}
        onClose={() => setDetails({ open: false, supplierId: undefined })}
      />
    </Page>
  );
};

const getSupplierRecordsForReview = async filters => {
  const response = await getTimeReviewRecords(filters);
  const data = response?.data?.map(supplier => {
    const records = supplier.engagements?.reduce(
      (arr, e) =>
        arr.concat(
          e.records.map(record => {
            return {
              ...record,
              title: e.title,
              description: e.description,
              startDate: e.startDate,
              endDate: e.endDate,
              client: e.client,
              parentClient: e.parentClient,
              managerName: e.managerName,
              compensationType: e.compensationType,
              compensationAmount: e.compensationAmount,
              compensationAllocation: e.compensationAllocation,
              currencySymbol: e.currencySymbol,
              classification: e.classification,
              metadata: e.engagementMetadata,
            };
          }),
        ),
      [],
    );
    return {
      ...supplier,
      engagements: supplier?.engagements?.map?.(e => ({ ...e, records: undefined })),
      records,
    };
  });
  return { ...response, data };
};

export default TimeReview;
