import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../components/dialog';
import { SelectField, TextField } from '../../../../../../../components/fields';
import Form from '../../../../../../../components/form';
import useApi from '../../../../../../../hooks/use-api';

import { PurchaseOrderType, PurchaseOrderTypeOptions } from '../../../../../../../domain/purchase-order-type';
import { getWizardLookupData } from '../../../../../../../apis/wizards';
import { updateEngagementPurchaseOrder } from '../../../../../../../apis/client/engagements';

const PurchaseOrderForm = ({ isOpen = false, onClose, onSave, engagement }) => {
  const lookupApi = useApi(getWizardLookupData, { skip: true });
  const updatePurchaseOrderApi = useApi(updateEngagementPurchaseOrder, { skip: true });

  const submit = async values => {
    const purchaseOrder = values.status === PurchaseOrderType.Available ? values : { status: values.status };
    await updatePurchaseOrderApi.request({ engagementId: engagement.id, purchaseOrder });
    onSave();
  };

  const loading = updatePurchaseOrderApi.loading;

  return (
    <Dialog title="Purchase Order" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          status: engagement?.purchaseOrderStatus,
          number: engagement?.purchaseOrder?.number,
          amount: engagement?.purchaseOrder?.amount,
          currencyId: engagement?.purchaseOrder?.currencyId,
          reference: engagement?.purchaseOrder?.reference,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          status: Yup.string().required('Purchase order status is required'),
          number: Yup.string()
            .label('Number')
            .when('status', ([type], schema, { value }) => {
              if (type !== PurchaseOrderType.NotRequired && !value) {
                return schema.required();
              }
              return schema;
            }),
          currencyId: Yup.string()
            .label('Currency')
            .when('status', ([type], schema, { value }) => {
              if (type !== PurchaseOrderType.NotRequired && !value) {
                return schema.required();
              }
              return schema;
            }),
          amount: Yup.number()
            .label('Amount')
            .when('status', ([type], schema, { value }) => {
              if (type !== PurchaseOrderType.NotRequired && !value) {
                return schema.required().moreThan(0, 'Amount is required');
              }
              return schema;
            }),
        })}
        onSubmit={submit}
      >
        {({ values }) => (
          <React.Fragment>
            <SelectField label="Status" options={PurchaseOrderTypeOptions} name="status" />
            {values.status === PurchaseOrderType.Available && (
              <React.Fragment>
                <TextField label="Number" placeholder="Number" name="number" />
                <SelectField
                  label="Currency"
                  name="currencyId"
                  remoteOptions={{
                    loading: lookupApi.loading,
                    request: () => lookupApi.request('client-available-currencies', { clientId: engagement?.clientId }),
                    mapper: result => result,
                    filter: 'local',
                  }}
                />
                <TextField
                  label="Amount"
                  extra={{
                    leftElement: <Button text={engagement?.compensation?.currency?.symbol} minimal small disabled />,
                  }}
                  placeholder="Amount"
                  type="number"
                  name="amount"
                />
                <TextField label="Reference" placeholder="Reference" name="reference" />
              </React.Fragment>
            )}

            <Button type="submit" fill outlined text="Save" disabled={loading} loading={loading} />
          </React.Fragment>
        )}
      </Form>
    </Dialog>
  );
};

export default PurchaseOrderForm;
