import React, { useState } from 'react';
import moment from 'moment';

import Avatar from '../../../../components/avatar';
import Table from '../../../../components/table';

import { abbreviateClassification } from '../../../../domain/classification-type';

import { capitalize, uuid } from '../../../../utilities/common';
import { downloadStringAsFile } from '../../../../utilities/files';
import { displayPeriod, getTotalAmount, getTotalHours } from '../../../../utilities/time';

import useApi from '../../../../hooks/use-api';
import { getEngagements } from '../../../../apis/supplier/engagements';

import useTable from '../../../../hooks/use-table';
import { exportTimeRecords, getTimeRecordGroups } from '../../../../apis/supplier/time';

import TimeDetails from './details';
import TimeFilters from './filters';
import TimeMenu from './menu';
import toaster from '../../../toaster';
import { DateFormat } from '../../../../models/enumerations/date-format';

const SupplierTime = ({ supplierId, range = undefined, dark = false, handleScroll = true }) => {
  const getEngagementsApi = useApi(getEngagements);

  if (getEngagementsApi?.loading) {
    return (
      <Table.Container data={[1, 2, 3, 4, 5]} loading={true} handleScroll={handleScroll} dark={dark}>
        <Table.Filters />
        <Table.Columns.Default showOnMobile name="Name" width="30px" render={d => ''} />
        <Table.Columns.TwoLines showOnMobile lines={[_ => '', _ => '']} />
        <Table.Columns.TwoLines width="3fr" name="Engagement" lines={[_ => '', _ => '']} />
        <Table.Columns.TwoLines showOnMobile lines={[_ => '', _ => '']} />
        <Table.Columns.TwoLines width="130px" name="Period" lines={[_ => '', _ => '']} />
        <Table.Columns.Actions showOnMobile actions={[]} />
      </Table.Container>
    );
  }

  return <SupplierTimeInternal {...{ supplierId, engagements: getEngagementsApi?.response, range, dark, handleScroll }} />;
};

const SupplierTimeInternal = ({ supplierId, engagements, range = undefined, dark = false, handleScroll = true }) => {
  const [details, setDetails] = useState({ open: false, id: undefined, period: undefined });
  const defaultFilters = {
    query: '',
    supplierId,
    engagementId: undefined,
    status: 4,
    frequency: engagements?.[0]?.compensation?.frequency || 'weekly',
    range:
      range || engagements?.[0]?.compensation?.frequency || 'weekly'
        ? [moment().add(-2, 'months').startOf('isoWeek').format(DateFormat.DEFAULT), moment().startOf('isoWeek').add(-1, 'minute').format(DateFormat.DEFAULT)]
        : [moment().add(-6, 'months').startOf('month').format(DateFormat.DEFAULT), moment().startOf('month').add(-1, 'minute').format(DateFormat.DEFAULT)],
  };

  const { items, loading, filters, initialFilters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    getTimeRecordGroups,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const onExport = () => {
    void toaster.export({
      progress: {
        title: 'Exporting time records',
      },
      promise: () => exportTimeRecords(filters),
      onSuccess: csv => downloadStringAsFile(csv, `TimeRecords-${uuid()}`, 'csv'),
    });
  };

  return (
    <React.Fragment>
      <Table.Container data={items} loading={loading} handleScroll={handleScroll} dark={dark}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<TimeMenu onExport={onExport} />}
          content={<TimeFilters engagements={engagements} />}
        />
        <Table.Columns.Default
          showOnMobile
          name="Name"
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} name={abbreviateClassification(d?.classification)} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => `${d?.firstName} ${d?.lastName}`, d => d.email]} />
        <Table.Columns.TwoLines
          width="3fr"
          name="Engagement"
          lines={[d => d?.title, d => (d?.parentClient ? `${d?.client} via ${d.parentClient}` : d?.client)]}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => getTotalHours(d.records), d => getTotalAmount(d.records, d.currencySymbol)]} />
        <Table.Columns.TwoLines width="130px" name="Period" lines={[d => displayPeriod(d), d => capitalize(d?.frequency) || '-']} />
        <Table.Columns.Actions showOnMobile actions={[{ name: 'View', onClick: d => setDetails({ open: true, id: d?.engagementId, period: d?.period }) }]} />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <TimeDetails
        details={{ ...details }}
        engagements={items}
        reloadRecords={reload}
        onClose={() => setDetails({ open: false, id: undefined, period: undefined })}
      />
    </React.Fragment>
  );
};

export default SupplierTime;
