import React, { useContext, useState } from 'react';
import { Button } from '@blueprintjs/core';

import { WizardContext } from '../../../../../state';
import { WizardSelectionContext } from '../../../../../state/selection-provider';

import Condition from './rule-builder';
import ConditionBuilderDialog from './rule-builder-dialog';
import { getEligibleFields, getRuleValue } from './helper';

import classes from '../custom-fields.module.scss';

const VisibilityConditions = ({ form }) => {
  const { template, descriptor } = useContext(WizardContext);
  const { getSelection, getSelectionPath } = useContext(WizardSelectionContext);
  const [dialog, setDialog] = useState({ open: false, visibility: { rules: [], combinator: 'AND' } });

  const selection = getSelection();
  const selectionPath = getSelectionPath(false);

  const eligibleData = React.useMemo(
    () =>
      getEligibleFields({
        template,
        selection,
        selectionPath,
        descriptor,
      }),
    [template, selection, selectionPath, descriptor],
  );

  const condition = getRuleValue(form.values.visibility);
  const disabled = form.values.hidden;
  return (
    <React.Fragment>
      <div className={classes.botPad15}>
        <div className={classes.groupTitle}>Visibility</div>
        <div className={classes.groupDescription}>Configure conditions which need to be satisfied for this field to be visible.</div>
      </div>
      <div className={classes.visibilityConditionsButtons}>
        <Condition
          disabled={disabled}
          eligibleData={eligibleData}
          rule={{ ...condition.value }}
          onClick={condition.simple ? undefined : () => setDialog({ open: true, visibility: condition.value })}
          onUpdate={visibility => {
            form.setFieldValue('visibility', {
              combinator: 'AND',
              rules: [visibility],
            });
            void form.submitForm();
          }}
        />
        <Button
          icon="add-to-artifact"
          outlined
          title="Add complex condition"
          onClick={() => setDialog({ open: true, visibility: form.values.visibility })}
          disabled={disabled}
        />
        <ConditionBuilderDialog
          dialog={dialog}
          onSubmit={visibility => {
            form.setFieldValue('visibility', visibility);
            void form.submitForm();
            setDialog({ open: false, visibility: undefined });
          }}
          onClose={() => setDialog({ open: false })}
        />

        <Button
          icon="small-cross"
          outlined
          onClick={() => {
            form.setFieldValue('visibility', {});
            form.submitForm();
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default VisibilityConditions;
