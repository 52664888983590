import React from 'react';
import { InputGroup, FormGroup } from '@blueprintjs/core';

import { parseExpressionToText } from '../utilities';

const CronExpressionManualForm = ({ value: defaultValue, onChange }) => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = e => {
    const newValue = e.target.value.replace(/  +/g, ' ');
    setValue(newValue);
    onChange(newValue);
  };

  const result = value ? parseExpressionToText(value) : null;

  return (
    <div>
      <FormGroup helperText={result?.error}>
        <InputGroup fill placeholder="Expression" value={value} onChange={handleChange} />
      </FormGroup>
    </div>
  );
};
export default CronExpressionManualForm;
