import React, { useState } from 'react';
import { connect } from 'react-redux';

import Avatar from '../../../../../components/avatar';
import Dialog from '../../../../../components/dialog';
import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import { EmailDialog, useEmailDialog } from '../../../../../components/organizational/platform/email';

import { getFullName } from '../../../../../utilities/user';

import useTable from '../../../../../hooks/use-table';

import * as MembersApis from '../../../../../apis/members';

import { roleIdToRoleName } from '../../../../../utilities/roles';

import MembersMenu from './menu';
import UserFilters from './filters';
import InviteMemberForm from './forms/invite-member';
import UpdateMemberForm from './forms/update-member';

const List = ({ organizationId }) => {
  const { items, loading, filters, initialFilters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    MembersApis.getMembers,
    { pageSize: 10, controlled: true },
    { organizationId, roles: null },
  );
  const emailDialog = useEmailDialog({ recipients: 'members', organizationId });

  const [dialog, setDialog] = useState({ open: false, type: undefined, title: undefined, data: undefined });
  const closeDialog = () => setDialog({ open: false, type: undefined, title: undefined, data: undefined });

  const handleRemoveMember = async membershipId => {
    await MembersApis.removeMember({ membershipId });
    reload();
  };
  const handleResendInvite = async inviteId => {
    await MembersApis.resendMemberInvite({ inviteId });
    reload();
  };
  const handleCancelInvite = async inviteId => {
    await MembersApis.cancelMemberInvite({ inviteId });
    reload();
  };

  return (
    <Page title="Users" path={[{ name: 'Users', to: '/users' }]} scroll={false}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          content={<UserFilters />}
          menu={
            <MembersMenu onInvite={() => setDialog({ open: true, type: 'invite', title: 'Invite platform member' })} openEmailDialog={emailDialog.handleOpen} />
          }
        />
        <Table.Columns.Default
          name="Name"
          showOnMobile
          width="30px"
          render={d => (
            <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={getFullName(d?.user)} url={d?.user?.avatar} showInviteIndicator={d?.isInvite} />
          )}
        />
        <Table.Columns.TwoLines showOnMobile width="2fr" lines={[d => getFullName(d?.user), d => d?.user?.email]} />
        <Table.Columns.Default name="Role" render={d => <small>{roleIdToRoleName(d?.roleId) || 'Not provided'}</small>} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            // User actions
            { name: 'View', href: d => `/users/${d?.user?.id}`, hidden: d => d?.isInvite },
            { name: 'Modify', onClick: d => setDialog({ open: true, type: 'modify', title: 'Modify platform member', data: d }), hidden: d => d?.isInvite },
            { name: 'Remove', onClick: d => handleRemoveMember(d?.id), confirmation: true, hidden: d => d?.isInvite },
            { divider: true, hidden: d => d?.isInvite },
            {
              name: 'Impersonate',
              disabled: d => !d?.user?.email,
              href: d => `/authentication/impersonate/${Buffer.from(d?.user?.email).toString('base64')}`,
              target: '_blank',
              hidden: d => d?.isInvite,
            },
            // Invite actions
            { name: 'Re-send', onClick: d => handleResendInvite(d?.id), hidden: d => !d?.isInvite },
            { divider: true, hidden: d => !d?.isInvite },
            { name: 'Cancel', onClick: d => handleCancelInvite(d?.id), confirmation: { messages: { confirmButton: 'Ok' } }, hidden: d => !d?.isInvite },
            ...emailDialog.getTableActions({ emailRenderer: d => d.user?.email }),
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <Dialog isOpen={!!dialog?.open} onClose={closeDialog} title={dialog?.title}>
        {dialog?.type === 'invite' && (
          <InviteMemberForm
            onComplete={() => {
              reload();
              closeDialog();
            }}
          />
        )}
        {dialog?.type === 'modify' && (
          <UpdateMemberForm
            membership={dialog?.data}
            onComplete={() => {
              reload();
              closeDialog();
            }}
          />
        )}
      </Dialog>
      <EmailDialog
        isMinimized={emailDialog.isMinimized}
        isOpen={emailDialog.isOpen}
        formValues={emailDialog.values}
        handleClose={emailDialog.handleClose}
        handleMinimize={emailDialog.handleMinimize}
      />
    </Page>
  );
};

const mapStateToProps = state => ({
  organizationId: state.identity.membership.organization.id,
});

export default connect(mapStateToProps)(List);
