import React, { useEffect, useCallback, useRef } from 'react';
import { Popover } from '@blueprintjs/core';
import cn from 'classnames';
import { useDrag } from 'react-dnd';

import { debounce } from '../../../../../../../utilities/common';
import MacroTypes from '../../../../enumerations/macro-types';

import MacroPlaceholder from './macro-placeholder';
import MacroControls from './macro-controls';
import Resizers from './resizers';

import classes from './macro-item.module.scss';

const ALLOW_VERTICAL_RESIZE_TYPES = [MacroTypes.INLINE_IMG, MacroTypes.SIGNATURE];

const MacroItem = ({ selection, selected, macro, setSelection, removeSelection, duplicate, move }) => {
  const ref = useRef();
  const onKeyDown = useRef();
  const delayedMove = useCallback(
    debounce(({ left, top }) => move({ id: macro.id, left, top }), 500),
    [],
  );

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'macro-item',
      item: macro,
      collect: monitor => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [macro],
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    dragRef(ref);
  }, [ref.current]);

  useEffect(() => {
    if (selected) {
      onKeyDown.current = e => {
        if (e.ctrlKey && e.key === 'd') {
          e.preventDefault();
          duplicate({ id: macro.id });
          return;
        }

        const xArrows = ['ArrowLeft', 'ArrowRight'];
        const yArrows = ['ArrowUp', 'ArrowDown'];
        const isX = xArrows.includes(e.key);
        const isY = yArrows.includes(e.key);
        if (isX || isY) {
          e.preventDefault();

          const increment = e.ctrlKey || e.shiftKey ? 10 : 1;
          let { offsetLeft: left, offsetTop: top } = ref.current;

          if (isX) {
            const direction = e.key === 'ArrowRight' ? 1 : -1;
            left = left + direction * increment;
            ref.current.style.left = `${left}px`;
          } else if (isY) {
            const direction = e.key === 'ArrowDown' ? 1 : -1;
            top = top + direction * increment;
            ref.current.style.top = `${top}px`;
          }

          delayedMove({ left, top });
        }
      };

      document.addEventListener('keydown', onKeyDown.current);
    }
    return () => {
      if (onKeyDown.current) {
        document.removeEventListener('keydown', onKeyDown.current);
        onKeyDown.current = null;
      }
    };
  }, [selected]);

  // check if the macro is being edited or selected
  const isId = selection?.macro?.id === macro.id;
  const editing = isId && selection?.action === 'edit';

  const handleClick = () => {
    if (selection?.macro?.id === macro.id) {
      removeSelection();
    } else {
      setSelection({ macro, action: 'select' });
    }
  };

  if (isDragging) {
    return <div style={{ ...macro.config.style, width: 0 }} />;
  }

  const showPopover = isDragging || editing ? false : undefined;
  const showResizers = selected && macro.type !== MacroTypes.CHECKBOX;
  const classNames = cn(classes.macroItem, {
    [classes.macroItemSelected]: selected && !editing,
    [classes.macroItemEditing]: editing,
  });
  return (
    <div ref={ref} className={classNames} style={macro.config.style}>
      <Popover
        isOpen={showPopover}
        popoverClassName={classes.macroItemPopover}
        position="right"
        interactionKind="hover"
        content={<MacroControls macro={macro} />}
        enforceFocus={false}
        transitionDuration={0}
        style={{ zIndex: 1000 }}
      >
        <MacroPlaceholder macro={macro} onClick={handleClick} />
      </Popover>
      {showResizers && <Resizers macro={macro} horizontal vertical={ALLOW_VERTICAL_RESIZE_TYPES.includes(macro.type)} />}
    </div>
  );
};

export default MacroItem;
