import { useEffect, useRef } from 'react';

const ONE_MINUTE = 1 * 60 * 1000;
const FIVE_MINUTES = 5 * ONE_MINUTE;
const useAutosave = ({ interval = FIVE_MINUTES, payload, onSave }) => {
  const initialPayloadRef = useRef(payload);
  const payloadRef = useRef(payload);
  const intervalRef = useRef();
  const callbackRef = useRef(onSave);

  useEffect(() => {
    callbackRef.current = onSave;
    payloadRef.current = payload;

    if (initialPayloadRef.current === undefined) {
      // because we load payload from async source at the beginning it can be undefined so we need to set it to
      // initial value to prevent autosave on first render
      initialPayloadRef.current = payload;
    }
  }, [onSave, payload]);

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        const hasChanges = JSON.stringify(initialPayloadRef.current) !== JSON.stringify(payloadRef.current);
        if (hasChanges) {
          initialPayloadRef.current = payloadRef.current;
          callbackRef.current();
        }
      }, interval);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        callbackRef.current = null;
        intervalRef.current = null;
      }
    };
  }, []);

  return null;
};

export default useAutosave;
