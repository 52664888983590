import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import * as Icons from 'react-feather';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../fields';
import Form, { FormGroup } from '../../../../form';

import { canBeEdited } from '../../../../../domain/tracking-record-status-type';
import { EngagementStatusType } from '../../../../../domain/engagement-status-type';

import useApi from '../../../../../hooks/use-api';
import { createShift, updateShift } from '../../../../../apis/supplier/time';

import { getColor } from '../../../../../utilities/common';
import { calculateShiftEnd, extractDateStr, extractStartTime, displayDuration, calculateDuration } from '../../../../../utilities/time/shift';

import classes from '../../quick.module.scss';

const ShiftForm = ({ data, engagements: allEngagements, user, onCancel, onSave }) => {
  const engagements = allEngagements?.filter?.(e => e.status === EngagementStatusType.Active);

  const createShiftApi = useApi(createShift, { skip: true });
  const updateShiftApi = useApi(updateShift, { skip: true });

  const submit = async values => {
    const startDate = `${extractDateStr(data.date)}T${values?.start}`;
    const duration = calculateDuration(values.start, values.end);
    const payload = {
      id: data.id,
      userId: user?.id,
      engagementId: values?.engagementId,
      start: startDate,
      duration,
      notes: values?.notes,
    };
    const response = payload.id ? await updateShiftApi.request(payload) : await createShiftApi.request(payload);
    if (!response.error) {
      onSave();
    }
  };

  const editable = canBeEdited(data?.status);

  function getInitialValues() {
    const start = data?.date ? extractStartTime(data.date) : '08:00';
    const end = data?.duration ? calculateShiftEnd(start, data.duration) : '12:00';
    return {
      start,
      end,
      notes: data?.notes || '',
      engagementId: data?.engagementId || (engagements?.length === 1 ? engagements[0].id : ''),
    };
  }

  return (
    <Form
      initialValues={getInitialValues()}
      validationSchema={Yup.object({
        engagementId: Yup.string().required('Engagement is required'),
        start: Yup.mixed()
          .required('Shift start is required')
          .test('test', 'Invalid session start or end.', (v, { parent }) => {
            const s = moment.duration(v).asMinutes();
            const e = moment.duration(parent.end).asMinutes();
            return s < e;
          }),
        end: Yup.string().required('Shift end is required'),
      })}
      onSubmit={submit}
    >
      {form => {
        const loading = createShiftApi.loading || updateShiftApi.loading;
        const error = createShiftApi.error || updateShiftApi.error;
        const duration = calculateDuration(form.values.start, form.values.end);
        return (
          <React.Fragment>
            <Fields.Select
              label="Engagement"
              disabled={engagements?.length === 1 || !editable}
              name="engagementId"
              options={engagements?.map?.(e => ({
                label: (
                  <div className={classes.engagementLabel}>
                    <div className={classes.engagementIcon}>
                      <Icons.Square size={12} strokeWidth={1.5} fill={getColor(e?.clientName)} color={getColor(e?.clientName)} />
                    </div>
                    <div>{e?.title}</div>
                  </div>
                ),
                value: e.id,
                description: e?.title,
              }))}
              searchable
            />
            <div className={classes.shiftSpan}>
              <div className={classes.time}>
                <Fields.Time label="Start time" name="start" format="12" fill disabled={!editable || !form.values.engagementId} />
              </div>
              <div className={classes.time}>
                <Fields.Time label="End time" name="end" format="12" fill disabled={!editable || !form.values.engagementId} />
              </div>
              <div className={classes.duration}>
                <FormGroup label="Duration">
                  <div className={classes.value}>{displayDuration(duration)}</div>
                </FormGroup>
              </div>
            </div>
            <Fields.Textarea label="Notes" name="notes" disabled={!editable || !form.values.engagementId} />

            {error && <div className={classes.shiftOverlapsMessage}>Requested shift overlaps with already logged shifts.</div>}

            <div className={classes.shiftButtons}>
              <Button fill className={classes.cancel} text="Cancel" disabled={loading} type="button" onClick={onCancel} />
              <Button fill className={classes.submit} text="Submit" disabled={loading || !editable || !form.isValid} loading={loading} type="submit" />
            </div>
          </React.Fragment>
        );
      }}
    </Form>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShiftForm);
