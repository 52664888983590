import React from 'react';
import * as Icons from 'react-feather';

import classes from './client-policies-description.module.scss';

const yes = <Icons.Check className={classes.yes} size={16} strokeWidth={1.5} />;
const no = <Icons.X className={classes.no} size={16} strokeWidth={1.5} />;

const isPartner = ({ isPartner, parentId }) => {
  if (isPartner === true) {
    return (
      <div className={classes.policies}>
        <div className={classes.block}>
          <ul>
            <li>{yes}Partner clients can have departments.</li>
            <li>{yes}Partner clients can have sub-clients.</li>
            <li>{yes}Sub-clients can have departments.</li>
            <li>{no}Sub-clients cannot have their own sub-clients.</li>
          </ul>
        </div>
      </div>
    );
  }
  if (isPartner === false) {
    return (
      <div className={classes.policies}>
        <div className={classes.block}>
          <ul>
            <li>{yes}Partner clients can have departments.</li>
            <li>{no}Partner clients cannot have sub-clients.</li>
          </ul>
        </div>
      </div>
    );
  }
  return null;
};

const isInvoiced = ({ isPartner, isInvoiced, parentId }) => {
  // Creating a sub client
  if (!!parentId) {
    if (isInvoiced === true) {
      return (
        <div className={classes.policies}>
          <div className={classes.block}>
            <ul>
              <li>{yes}The client will be invoiced for all supplier costs that are incurred on their behalf.</li>
              <li>{yes}The client will be invoiced for all non-supplier costs that are incurred on their behalf.</li>
            </ul>
          </div>
        </div>
      );
    }
    if (isInvoiced === false) {
      return (
        <div className={classes.policies}>
          <div className={classes.block}>
            <ul>
              <li>
                {no}The client will not be invoiced for all supplier and non-supplier costs that are incurred on their behalf. Instead the costs will be passed
                on to the parent client.
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }

  // Creating a client
  if (isPartner === true) {
    return (
      <div className={classes.policies}>
        <div className={classes.notice}>Invoices must be issued to this client because there is no parent organization that can cover the incurred costs.</div>
        <div className={classes.block}>
          <ul>
            <li>{yes}The client will be invoiced for all supplier costs that are incurred on their behalf.</li>
            <li>
              {yes}
              The client may choose to pass of sub-clients supplier costs that are incurred on sub-clients behalf. In this case separate invoice will be created
              for each such sub-client.
            </li>
            <li>{yes}The client will be invoiced for all non-supplier costs that are incurred on their behalf.</li>
          </ul>
        </div>
      </div>
    );
  }
  if (isPartner === false) {
    return (
      <div className={classes.policies}>
        <div className={classes.notice}>Invoices must be issued to this client because there is no parent organization that can cover the incurred costs.</div>
        <div className={classes.block}>
          <ul>
            <li>{yes}The client will be invoiced for all supplier costs that are incurred on their behalf.</li>
            <li>{yes}The client will be invoiced for all non-supplier costs that are incurred on their behalf.</li>
          </ul>
        </div>
      </div>
    );
  }
  return null;
};

const ClientPoliciesDescriptions = {
  isPartner,
  isInvoiced,
};

export default ClientPoliciesDescriptions;
