import React, { useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Button, Menu, MenuDivider, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import ReactJson from 'react-json-view';
import { useNavigate, useParams } from 'react-router';

import useAutosave from '../../../../../hooks/use-autosave';
import useApi from '../../../../../hooks/use-api';
import { updateTemplate, validateTemplate, publishTemplate } from '../../../../../apis/templates';
import { getRecord, getRecordValue, updateRecord } from '../../../../../apis/configuration';

import { downloadStringAsFile } from '../../../../../utilities/files';
import { blockOptions } from '../../enumerations/field-definitions';
import FieldMenuItem from './field-menu-item/field-menu-item';
import { createContentItem } from '../../helpers/create-content-item';
import templateFactory from '../../helpers/template-factory';
import { Container } from './sortable';
import { useEmailConfigurator } from '../../context';
import Properties from './properties';
import Block from './block';
import ToolboxLoading from './toolbox-loading';
import Preview from './preview';
import ImportDialog from './import-dialog';

import classes from './toolbox.module.scss';

async function updateEmailConfigurationRecord({ status, recordId }) {
  const record = await getRecord(recordId);
  const recordValue = await getRecordValue(recordId);
  const description = status === 0 ? '(Draft)' : status === 1 ? '' : record?.description;
  const newRecord = await updateRecord(recordId, {
    scopeId: record.scopeId,
    description,
    data: {
      documentTemplateType: recordValue?.documentTemplateType,
      documentName: recordValue?.documentName,
      templateId: recordValue.templateId,
    },
    configurationType: record.configurationType,
  });
  return newRecord;
}

const Item = SortableElement(({ contentItem, isLoading, path }) => <Block block={contentItem} path={path} isLoading={isLoading} dispatch />);

const Toolbox = () => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState();
  const [{ template, content, isLoading }, dispatch] = useEmailConfigurator();
  const saveApi = useApi(
    async emailTemplate => {
      await validateTemplate(emailTemplate);
      return updateTemplate(emailTemplate);
    },
    { skip: true },
  );
  const publishApi = useApi(publishTemplate, { skip: true });
  const updateConfigurationRecordApi = useApi(updateEmailConfigurationRecord, { skip: true });

  const handleSave = async () => {
    const emailTemplate = templateFactory.create(template, content);
    const response = await saveApi.request(emailTemplate);
    if (template.status === 0) {
      const newRecord = await updateConfigurationRecordApi.request({ status: response?.status, recordId });
      navigate(`/settings/emails/${template.id}/${newRecord?.data?.id}`, { replace: true });
    }
  };

  useAutosave({ payload: template ? templateFactory.create(template, content) : undefined, onSave: handleSave });

  const handlePublish = async () => {
    await publishApi.request(template?.id);
    dispatch({ type: 'EMAIL_CONFIGURATOR_SET_PUBLISHED', status: 1 });
    const newRecord = await updateConfigurationRecordApi.request({ status: 1, recordId });
    navigate(`/settings/emails/${template.id}/${newRecord?.data?.id}`, { replace: true });
  };

  const handlePreview = () => {
    setDialog('preview');
  };

  const addField = field => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_ADD_CONTENT', data: { path: undefined, contentItem: createContentItem(field) } });
  };

  const selectBlock = () => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_SET_SELECTION', data: { type: 'config', config: templateFactory.createConfig(template) } });
  };

  const reorderItems = updates => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_REORDER_CONTENT', data: { updates, path: undefined } });
  };

  if (isLoading) {
    return <ToolboxLoading />;
  }

  if (!template) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.toolbox}>
        <div className={classes.header}>
          <div className={classes.main}>
            <div className={classes.title}>
              <div className={classes.name}>{template.name}</div>
              {template.status !== 1 && <div className={classes.draft}>(Draft)</div>}
            </div>
            <div className={classes.description}>{template.description}</div>
          </div>
          <Popover
            content={
              <Menu>
                <MenuItem text="Save" icon="floppy-disk" onClick={handleSave} />
                <MenuItem text="Preview" icon="eye-open" onClick={handlePreview} />
                <MenuDivider />
                <FieldMenuItem text="Add" icon="plus" items={blockOptions} onClick={addField} />
                <MenuDivider />
                <MenuItem text="Properties" icon="settings" onClick={selectBlock} />
                <MenuDivider />
                <MenuItem text="Export" icon="code-block">
                  <MenuItem text="Template" icon="application" onClick={() => downloadStringAsFile(JSON.stringify(template), template.name, 'json')} />
                </MenuItem>
                <MenuItem text="Debug" icon="code">
                  <Popover
                    fill
                    interactionKind="click"
                    position="right"
                    content={
                      <SimpleBar style={{ padding: '15px', maxHeight: '80vh', width: '40vw' }}>
                        <ReactJson src={{ ...template }} />
                      </SimpleBar>
                    }
                  >
                    <MenuItem shouldDismissPopover={false} text="Template" icon="application" />
                  </Popover>
                </MenuItem>
                <MenuDivider />
                <MenuItem text="Import" icon="import" onClick={() => setDialog('import')} />
                <MenuDivider />
                <MenuItem text="Publish" icon="cloud-upload" onClick={handlePublish} disabled={template?.status === 1} />
              </Menu>
            }
          >
            <Button minimal icon="more" loading={saveApi.loading || publishApi.loading} />
          </Popover>
        </div>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.pages}>
            <Container useDragHandle onSortEnd={reorderItems}>
              {content.map((contentItem, index) => (
                <Item key={index} index={index} path={[index]} contentItem={contentItem} isLoading={isLoading} dispatch={dispatch} />
              ))}
            </Container>
          </div>
        </SimpleBar>
        <div className={classes.footer}>
          <Button
            fill
            outlined
            icon={saveApi.loading ? <Spinner size={16} /> : 'floppy-disk'}
            alignText="left"
            text="Save"
            onClick={handleSave}
            disabled={saveApi.loading || updateConfigurationRecordApi.loading}
          />
          <Button
            fill
            outlined
            icon="cloud-upload"
            alignText="left"
            text="Publish"
            onClick={handlePublish}
            disabled={template?.status === 1 || saveApi.loading || publishApi.loading || updateConfigurationRecordApi.loading}
            loading={publishApi.loading}
          />
        </div>
      </div>
      <Preview isOpen={dialog === 'preview'} template={template} content={content} onClose={() => setDialog(undefined)} />
      <Properties />
      <ImportDialog isOpen={dialog === 'import'} onClose={() => setDialog(undefined)} />
    </React.Fragment>
  );
};

export default Toolbox;
