import React, { useContext } from 'react';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import { Button, ButtonGroup } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';

import { SystemContext } from '../../../../system-provider';

import { createRecord, updateRecord } from '../../../../../../../apis/configuration';

import classes from './employee-over-time.module.scss';

const EmployeeOverTime = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    setLoading(true);
    const payload = { ...values, data: { ...values.data, rules: JSON.stringify(values.data.rules) } };

    if (values?.id) {
      await updateRecord(values?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <Form
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        ...record,
        data: { rules: !!data?.rules ? JSON.parse(data?.rules) : [] },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.mixed().required('Scope is required'),
        data: Yup.object().shape({
          rules: Yup.array().of(
            Yup.object().shape({
              ruleType: Yup.mixed().required('Rule type is required'),
              threshold: Yup.number().required('Threshold is required').min(0, 'Threshold must be greater than or equal to 0'),
              coefficient: Yup.number().min(1, 'Coefficient must be greater than one').required('Coefficient is required'),
            }),
          ),
        }),
      })}
      onSubmit={submit}
      enableReinitialize
    >
      {form => {
        const disabled = loading;
        return (
          <div className={classes.rules}>
            {!!form?.values?.data?.rules?.length && (
              <div className={classes.header}>
                <div>Type</div>
                <div>Threshold</div>
                <div>Coefficient</div>
              </div>
            )}
            <FieldArray name="data.rules">
              {({ remove, push }) => (
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.container}>
                    {form.values.data.rules?.map?.((_, index) => (
                      <div className={classes.row} key={index}>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.rules.${index}.ruleType`}
                            placeholder="Type"
                            options={[
                              { label: 'Daily', value: 'daily' },
                              { label: 'Weekly', value: 'weekly' },
                              { label: 'Seventh Consecutive Day', value: 'seventh-consecutive-day' },
                            ]}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Text type="number" name={`data.rules.${index}.threshold`} placeholder="Threshold" outline={false} minimal={true} />
                        </div>
                        <div className={classes.field}>
                          <Fields.Text type="number" name={`data.rules.${index}.coefficient`} placeholder="Coefficient" outline={false} minimal={true} />
                        </div>
                        <div className={classes.removeButton}>
                          <Button icon="small-minus" outlined type="button" onClick={() => remove(index)} />
                        </div>
                      </div>
                    ))}
                    <ButtonGroup>
                      <Button
                        fill
                        outlined
                        icon={<Icons.Plus size={16} strokeWidth={1.5} />}
                        text={'Add New'}
                        disabled={!form.isValid}
                        onClick={() => push({ ruleType: null, threshold: 0, coefficient: 0 })}
                      />
                    </ButtonGroup>
                  </div>
                </SimpleBar>
              )}
            </FieldArray>

            <Button outlined fill text="Submit" disabled={disabled} loading={loading} onClick={() => (disabled ? null : form.submitForm())} />
          </div>
        );
      }}
    </Form>
  );
};

export default EmployeeOverTime;
