import React from 'react';
import { Button, Callout, Drawer } from '@blueprintjs/core';

import useApi from '../../../../../../hooks/use-api';
import { previewTemplate } from '../../../../../../apis/templates';
import Spinner from '../../../../../spinner';
import templateFactory from '../../../helpers/template-factory';

import classes from './preview.module.scss';

const Preview = ({ isOpen, template, content, onClose }) => {
  const previewApi = useApi(previewTemplate, { skip: true });

  React.useEffect(() => {
    if (isOpen) {
      const emailTemplate = templateFactory.create(template, content);
      previewApi.request(emailTemplate);
    } else {
      previewApi.setResponse(undefined);
    }
  }, [isOpen]);

  const handleLoad = event => {
    const style = document.createElement('style');
    style.textContent = 'a, button { pointer-events: none; }';
    event?.target?.contentDocument?.head?.appendChild(style);
  };

  return (
    <Drawer
      position="right"
      usePortal={false}
      size="100%"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={isOpen}
      className={classes.preview}
      backdropClassName={classes.backdrop}
      onClose={onClose}
    >
      <div className={classes.header}>
        <Button icon="cross" minimal onClick={onClose} />
        <span>Preview</span>
      </div>
      <div className={classes.content}>
        <div className={classes.previewIframe}>
          {previewApi.loading ? (
            <Spinner className={classes.previewLoading} global={false} blend={true} />
          ) : previewApi.response ? (
            <iframe srcDoc={previewApi.response} onLoad={handleLoad} title="Document Template Preview" frameBorder="0" height="100%" width="100%" />
          ) : (
            <div>
              <Callout intent="danger" title="Error occurred during preview">
                {previewApi.error}
              </Callout>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default Preview;
