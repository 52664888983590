import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../components/dialog';
import Form from '../../../../../../components/form';
import BankAccountField from '../../../../../../components/fields/bank-account/bank-account';

import useApi from '../../../../../../hooks/use-api';
import { createBankAccount, updateBankAccount } from '../../../../../../apis/banking';

function createOrUpdateBank(bank) {
  return bank.id ? updateBankAccount(bank.id, bank) : createBankAccount(bank);
}
const BankingForm = ({ isOpen = false, bank, owner, countryId, readOnly, onClose, onSave }) => {
  const createOrUpdateBankApi = useApi(createOrUpdateBank, { skip: true });

  const submit = async values => {
    await createOrUpdateBankApi.request(values);
    onSave();
  };

  return (
    <Dialog title="Bank account" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          countryId,
          ...bank,
          ...owner,
        }}
        enableReinitialize
        onSubmit={submit}
      >
        <BankAccountField countryId={countryId} disabled={readOnly} />
        <Button type="submit" fill outlined text="Submit" loading={createOrUpdateBankApi.loading} />
      </Form>
    </Dialog>
  );
};

export default BankingForm;
