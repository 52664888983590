export const isFunction = value => {
  return typeof value === 'function';
};

export function safeInvoke(func) {
  var args = [];
  // eslint-disable-next-line no-undef
  for (var _i = 1; _i < arguments?.length; _i++) {
    // eslint-disable-next-line no-undef
    args[_i - 1] = arguments?.[_i];
  }
  if (isFunction(func)) {
    return func.apply(void 0, args);
  }
  return undefined;
}
