import moment from 'moment/moment';

import { ObjectTypes } from '../../wizards/domain/types';
import { EMAIL_DATE_FORMATS } from '../../email/enumerations/constants';

import MacroTypes from '../enumerations/macro-types';

const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

const formatAvailableMacros = (data, array, refName, options) => {
  data.forEach(obj => {
    if (obj.type !== 'iterator') {
      const name = obj.refName ? refName : `${refName}.${obj.name}`;
      const label = obj.name.replace(regExp, '$1$4 $2$3$5');
      let items = [];

      if (options.formatDate && obj.baseType === 'datetime') {
        items = EMAIL_DATE_FORMATS.map(format => ({
          name: `moment(${name}).format('${format}')`,
          label: moment(obj.example).format(format),
          example: moment(obj.example).format(format),
        }));
      } else if (obj.children.length) {
        items = formatAvailableMacros(obj.children, [], name, options);
        items.sort((x, y) => x.items.length - y.items.length);
      }

      if (obj.signatory) {
        items = items.map(signature => {
          return {
            ...signature,
            roleId: obj.signatory,
          };
        });
      }

      array.push({ name, label, example: obj.example, items, type: MacroTypes.DEFAULT });
    }
  });

  return array;
};

const extractMacrosFromWizard = (refName, wizardTemplate) => {
  const isDisabled = type =>
    [ObjectTypes.BankAccount, ObjectTypes.Signature, ObjectTypes.Document, ObjectTypes.Address, ObjectTypes.File, ObjectTypes.MultiSelect].includes(type);
  const isSkipped = type => [ObjectTypes.Paragraph, ObjectTypes.Link].includes(type);

  const items = wizardTemplate?.pages?.reduce((ps, page) => {
    const groups = page?.groups?.reduce((gs, group) => {
      const fields = group?.fields
        ?.filter(field => !isSkipped(field.type))
        ?.map(field => ({
          name: `${refName}.WizardInstance?.['${page.id}']?.['${group.id}']?.['${field.id}']`,
          label: field.label,
          example: field.label,
          field: field.type,
          disabled: isDisabled(field.type),
          type: MacroTypes.DEFAULT,
        }));

      if (fields.length) {
        gs.push({ name: group.id, label: group.title, items: fields });
      }

      return gs;
    }, []);

    if (groups.length) {
      ps.push({ name: page.id, label: page.title, items: groups });
    }

    return ps;
  }, []);

  return { refName, items, name: wizardTemplate.title, label: wizardTemplate.title };
};

export const getDocumentTemplateSpecificMacros = (descriptors, template, wizardTemplate, options) => {
  const { contextTypeName, refName } = template;
  const templateDescriptor = descriptors.find(d => d.contextTypeName === contextTypeName);
  const templateAvailableMacros = formatAvailableMacros(templateDescriptor?.children, [], refName, options);
  if (!wizardTemplate) {
    return templateAvailableMacros;
  }
  const wizardAvailableMacros = extractMacrosFromWizard(refName, wizardTemplate);
  return templateAvailableMacros.concat(wizardAvailableMacros);
};
