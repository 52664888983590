import React, { useState } from 'react';
import moment from 'moment';
import { Popover, ResizeSensor } from '@blueprintjs/core';
import classNames from 'classnames';

import Avatar from '../../../../../../../components/avatar';
import Comments from '../../../../../../../components/time/comments';

import { trackingRecordStatusToLabel } from '../../../../../../../domain/tracking-record-status-type';

import { getColor } from '../../../../../../../utilities/common';

import { getSegments } from './utilities';

import classes from './shift-graph.module.scss';

const ShiftGraphDay = ({ engagements, day, shifts = [] }) => {
  const items = getSegments(day, shifts, { includeBreakSegments: true, includeEmptySegments: true });
  const total = shifts?.reduce?.((a, b) => a + moment.duration(b.duration).asMinutes(), 0) / 60;
  const totalLabel = total.toString().length > 5 ? total.toFixed(2) : total;

  return (
    <div className={classes.day}>
      <div className={classes.indicator}>
        <div className={classes.name}>{moment(day).format('ddd').toUpperCase()}</div>
        <div className={classes.date}>{moment(day).format('MM/DD')}</div>
      </div>
      <div className={classes.bar}>
        <div className={classes.background}>{items?.map?.((r, i) => renderSegment(r, engagements, i))}</div>
      </div>
      <div className={classes.sum}>{`${totalLabel}h`}</div>
    </div>
  );
};

const renderSegment = (record, engagements, index) => {
  switch (record?.type) {
    case 'break':
      return <Segments.Break key={index} record={record} />;

    case 'empty':
      return <Segments.Empty key={index} record={record} />;

    default:
      return <Segments.Shift key={index} record={record} engagements={engagements} />;
  }
};

const Segments = {
  Break: ({ record }) => {
    const { offset, width, duration } = getDimensions(record);
    const span = `${moment(record.date).format('h:mm A')} - ${moment(record.date).add(record.duration).format('h:mm A')}`;
    return (
      <div className={classNames(classes.shift, classes.break)} style={{ left: `${offset}%`, width: `${width}%` }}>
        <Popover
          content={
            <div className={classes.popover}>
              <div className={classes.project}>Break</div>
              <div className={classes.info}>
                {span}, {`${(duration / 60).toFixed(2)} hours`}
              </div>
            </div>
          }
        >
          <div className={classes.target} />
        </Popover>
      </div>
    );
  },
  Empty: ({ record }) => {
    const { offset, width } = getDimensions(record);
    return <div className={classNames(classes.shift, classes.empty)} style={{ left: `${offset}%`, width: `${width}%` }} />;
  },
  Shift: ({ record, engagements = [] }) => {
    const [showBounds, setShowBounds] = useState(0);
    const { offset, width, duration } = getDimensions(record);
    const start = moment(record.date).format('h:mm A');
    const end = moment(record.date).add(record.duration).format('h:mm A');
    const span = `${start} - ${end}`;
    const engagement = engagements?.find(e => e.engagementId === record?.engagementId);

    return (
      <div className={classes.shift} style={{ left: `${offset}%`, width: `${width}%` }}>
        <Popover
          content={
            <div className={classes.popover}>
              <div className={classes.meta}>
                <Avatar classOverrides={classes.avatar} abbreviate name={engagement?.client} size={{ box: 60 }} />
                <div className={classes.text}>
                  <div className={classes.title}>{engagement?.title}</div>
                  <div className={classes.client}>{engagement?.client}</div>
                </div>
              </div>

              <div className={classes.status}>Status: {trackingRecordStatusToLabel(record?.status)}</div>
              <div className={classes.info}>{`${span}, ${(duration / 60).toFixed(2)} hours`}</div>
              {record.notes && <div className={classes.notes}>{record.notes}</div>}
              <Comments recordId={record?.id} />
            </div>
          }
        >
          <ResizeSensor onResize={e => setShowBounds(e?.[0]?.contentRect?.width >= 85)}>
            <div
              className={classes.target}
              style={engagement?.client && { backgroundColor: getColor(engagement?.client), color: getColor(engagement?.client) }}
            >
              {!!record?.comments?.length && <div className={classes.commentsIndicator} />}
            </div>
          </ResizeSensor>
        </Popover>
        {showBounds && (
          <div className={classes.span}>
            <div className={classes.start}>{start}</div>
            <div className={classes.end}>{end}</div>
          </div>
        )}
      </div>
    );
  },
};

const getDimensions = record => {
  const start = moment.duration(moment(record.date).format('HH:mm')).asMinutes();
  const duration = moment.duration(record.duration).asMinutes();
  const end = start + duration;

  const full = 24 * 60;
  const offset = (start * 100) / full;
  const width = (duration * 100) / full;

  return {
    full,
    start,
    end,
    duration,
    offset,
    width,
  };
};

export default ShiftGraphDay;
