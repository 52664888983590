import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import Avatar from '../../avatar';

import { getColor } from '../../../utilities/common';
import { getFullName } from '../../../utilities/user';
import { logout } from '../../../state/identity/actions';

import RoleSwitcher from '../switchers/role-switcher';
import ThemeSwitcher from '../switchers/theme-switcher';

import classes from './user.module.scss';

const UserMenu = ({ isMobile, closeMenu, user, logout }) => {
  const name = getFullName(user);

  return (
    <div className={classes.userMenu}>
      <div className={classes.personal}>
        <Avatar name={name} url={user.avatar} abbreviate classOverrides={classes.avatar} />
        <div className={classes.info}>
          <div className={classes.name} style={{ color: getColor(name) }}>
            {name}
          </div>
          <div className={classes.email}>{user?.email}</div>
          <Link onClick={closeMenu} className={classNames(classes.link, 'bp5-popover-dismiss')} to="/profile">
            View profile
          </Link>
        </div>
        <ThemeSwitcher />
      </div>
      <RoleSwitcher isMobile={isMobile} />
      <div className={classes.buttons}>
        <Button minimal text="Logout" fill className={classNames(classes.button, 'bp5-popover-dismiss')} onClick={logout} />
      </div>
      <div className={classes.footer}>
        <Link onClick={closeMenu} to="/support">
          Support
        </Link>
        <Link onClick={closeMenu} to="/privacy">
          Privacy
        </Link>
        <div className={classes.copy}>{`GreenLight.ai © ${new Date().getFullYear()}`}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
