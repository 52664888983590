import React from 'react';
import classNames from 'classnames';

import Box from '../../../../box';

import classes from '../appearance.module.scss';

const AppearanceInstructions = ({ className }) => {
  return (
    <Box outlined monochrome loading={false} className={classNames(classes.instructionsBox, className)}>
      <div className={classes.boxContent}>
        <div className={classes.boxInfo}>
          <p>Please ensure that your logo and icon meet the following requirements:</p>
          <ul>
            <li>
              <strong>Background:</strong> Please upload an image without a background (transparent PNG is preferred).
            </li>
            <li>
              <strong>Aspect Ratio:</strong> Your logo will need to fit our required aspect ratio of 165:90, while icon will need to fit our required aspect
              ratio of 1:1.
            </li>
            <li>
              <strong>Cropping and Resizing:</strong> After selecting image, you will be prompted to crop and resize your image to fit the specified aspect
              ratio. Make sure your logo looks good in the resized format, as it will be displayed in this dimension.
            </li>
          </ul>
          <span>If you have any questions or need assistance, feel free to reach out!</span>
        </div>
      </div>
    </Box>
  );
};

export default AppearanceInstructions;
