import moment from 'moment';

const endOfDayInMinutes = 24 * 60;
export const extractStartTime = shiftStartDate => shiftStartDate?.split('T')[1];
export const extractDateStr = shiftStartDate => shiftStartDate?.split('T')[0];
export const formatDuration = duration => {
  if (duration < 0) {
    return 'n/a';
  }
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};
export const calculateDuration = (start, end) => {
  const s = moment.duration(start).asMinutes();
  const e = moment.duration(end).asMinutes();
  let diff = e - s;
  if (e < s) {
    diff = endOfDayInMinutes + diff;
  }
  const duration = moment.duration(diff, 'minutes');
  return formatDuration(duration);
};
export const displayDuration = (duration, display) => {
  const d = moment.duration(duration);
  const hours = d.asHours();
  if (display === 'short') {
    return `${hours.toFixed(2)}h`;
  }
  return `${hours.toFixed(2)} hour${hours === 1 ? '' : 's'}`;
};
export const calculateShiftEnd = (start, duration = '4:00') => {
  const end = moment.duration(start).add(duration);
  return formatDuration(end);
};
