export const EngagementLocationType = {
  OnSite: 'on-site',
  OffSite: 'off-site',
};

export const EngagementLocationTypeOptions = [
  {
    label: 'On-Site',
    value: EngagementLocationType.OnSite,
  },
  {
    label: 'Remote',
    value: EngagementLocationType.OffSite,
  },
];

export const engagementLocationTypeToLabel = type => EngagementLocationTypeOptions.find(o => o.value === type)?.label;
