import moment from 'moment';
import { TrackingRecordTypes } from '../../domain/tracking-record-type';
import { DateTimeFormat } from '../../models/enumerations/date-format';

export const isSameDay = (date1, date2) => {
  const d1 = moment(date1).clone();
  const d2 = moment(date2).clone();
  return d1.dayOfYear() === d2.dayOfYear() && d1.year() === d2.year();
};

export const displayPeriod = worker => {
  const periodStart = moment(worker?.period, DateTimeFormat.DEFAULT);
  if (worker.frequency === 'weekly') {
    return `${periodStart.format('MM/DD')} - ${periodStart.add(6, 'days').format('MM/DD/YYYY')}`;
  }

  if (worker.frequency === 'monthly') {
    return periodStart.format('MMMM YYYY');
  }

  return periodStart.format('MM/DD/YYYY');
};

export const getTotalHours = records => {
  const total =
    records?.reduce((a, b) => {
      if (b?.type === TrackingRecordTypes.Shift) {
        return a + moment.duration(b?.duration).asHours();
      }
      return a;
    }, 0) || 0;
  const hours = total.toString().length > 5 ? total.toFixed(2) : total;
  return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
};

export const getTotalAmount = (records, currencySymbol) => {
  const amount =
    records?.reduce((a, b) => {
      if (b.type === TrackingRecordTypes.Expenses || b.type === TrackingRecordTypes.Deliverables) {
        return a + b.amount;
      }
      return a;
    }, 0) || 0;
  const value = amount.toFixed(2);
  return `${currencySymbol || ''}${value}`;
};

export const convertTimeTo12Hour = time => {
  const [hours, minute] = time.split(':');
  const AmOrPm = hours >= 12 ? 'pm' : 'am';
  const newHour = hours % 12 || 12;
  return `${newHour}:${minute} ${AmOrPm}`;
};
