import React from 'react';
import classNames from 'classnames';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../box';

import useApi from '../../../../hooks/use-api';

import { SignatoriesRolesMapper } from '../../../../models/enumerations/roles';

import { downloadDocument } from '../../../../apis/documents';
import { getSignedDocument } from '../../../../apis/signature';

import classes from '../timeline-step.module.scss';

const resolveField = async (field, instanceId) => {
  if (!field.value || field.value.length === 0) {
    return { text: 'Not provided' };
  }

  const everyoneSigned = field.value?.every(s => !!s.signatureId);
  if (!everyoneSigned) {
    return { text: 'Not provided' };
  }

  const target = field.value[0].target;
  const document = await getSignedDocument({ target, instanceId });
  return { text: document.name, entity: document };
};

const SignatureFieldValue = ({ instanceId, field }) => {
  const everyoneSigned = field.value?.every(s => !!s.signatureId);
  const api = useApi(() => resolveField(field, instanceId));

  const list = field.value?.map(s => {
    return (
      <Box.ItemContainer key={s.roleId}>
        <Box.ItemLabel>{SignatoriesRolesMapper[s.roleId]?.name}</Box.ItemLabel>
        <Box.ItemValue className={classes.fieldValue}>{s.signatureId ? 'Signed' : 'Not provided'}</Box.ItemValue>
      </Box.ItemContainer>
    );
  });

  return (
    <>
      {list}
      <Box.ItemContainer>
        <Box.ItemLabel>{field.label}</Box.ItemLabel>
        <Box.ItemValue className={classNames(classes.fieldValue, api.loading && Classes.SKELETON)}>
          {api.response?.text || 'Not provided'}
          {everyoneSigned && api.response?.entity && (
            <ButtonGroup>
              <Button outlined small icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(api.response?.entity)} />
            </ButtonGroup>
          )}
        </Box.ItemValue>
      </Box.ItemContainer>
    </>
  );
};

export default SignatureFieldValue;
