import React, { useContext } from 'react';
import { Button, Drawer } from '@blueprintjs/core';
import { connect } from 'formik';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import * as Icons from 'react-feather';

import { WizardContext } from '../../../state';
import { WizardInstanceContext } from '../../../state/instance-provider';

import classes from './navigator.module.scss';

const Navigator = ({ isOpen, onClose, formik }) => {
  const wizardContext = useContext(WizardContext);
  const instanceContext = useContext(WizardInstanceContext);

  const { template } = wizardContext;
  const { step, progress, getVisiblePages, setStepById } = instanceContext;

  const itemAction = (page, index) => {
    if (!page?.id) {
      return;
    }

    if (index <= progress && index !== step) {
      setStepById(page?.id);
      onClose();
    }
  };

  const isVisible = page => {
    const visiblePages = getVisiblePages(formik?.values);
    return !!visiblePages?.map?.(p => p.id)?.includes(page?.id);
  };

  return (
    <Drawer
      className={classes.navigator}
      backdropClassName={classes.backdrop}
      isOpen={isOpen}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      onClose={onClose}
      usePortal={false}
    >
      <div className={classes.header}>
        <div className={classes.title}>Navigation</div>
        <Button icon="cross" minimal onClick={onClose} />
      </div>
      <div className={classes.content}>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.content}>
            {template?.pages?.map((page, index) => (
              <div
                key={index}
                className={classNames(
                  classes.step,
                  isVisible(page) ? (index === progress ? classes.current : index <= progress ? classes.complete : classes.pending) : classes.hidden,
                )}
                onClick={() => itemAction(page, index)}
              >
                <div className={classes.label}>{page?.title}</div>
                {index < progress && <Icons.Check size={15} strokeWidth={1} />}
              </div>
            ))}
          </div>
        </SimpleBar>
      </div>
    </Drawer>
  );
};

export default connect(Navigator);
