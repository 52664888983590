import { Button, ButtonGroup } from '@blueprintjs/core';
import cn from 'classnames';
import classNames from 'classnames';
import React from 'react';

import { Field } from '../../form';
import { itemsEqual, labelRenderer, loadingArray, valueRenderer } from './helper';
import styles from './radio-group.module.scss';

const ButtonGroupField = ({ classes, options, label, description, inline, disabled, name, validate, loading, fill, submitOnChange = false, renderer }) => (
  <Field
    classes={{ ...classes, wrapper: cn(styles.wrapper, classes?.wrapper) }}
    label={label}
    description={description}
    name={name}
    validate={validate}
    outline={false}
  >
    {({ field, form }) => {
      const onChange = value => {
        const item = options.find(item => itemsEqual(item, value));
        form.setFieldValue(name, valueRenderer(item));
        if (submitOnChange) {
          form.submitForm();
        }
      };

      return (
        <ButtonGroup fill={fill} vertical={!inline}>
          {(loading ? loadingArray : options)?.map(item => {
            const value = valueRenderer(item);
            const label = labelRenderer(item);
            const selected = field.value === value;
            if (!renderer) {
              return (
                <Button
                  fill
                  outlined
                  className={classNames(styles.button, selected && styles.active)}
                  text={label}
                  value={value}
                  loading={loading}
                  onClick={() => onChange(value)}
                  disabled={disabled}
                  key={value}
                />
              );
            }

            return renderer({ value, label, selected, onChange });
          })}
        </ButtonGroup>
      );
    }}
  </Field>
);

export default ButtonGroupField;
