import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ResizeSensor } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { urlSanitize } from '../../utilities/common';

import Item from './item';
import ActionItem from './action-item';

import classes from './tabs.module.scss';

function isTabActive({ title, searchParams }) {
  const sanitized = urlSanitize(title);
  return searchParams.get('t') === sanitized;
}

const Container = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  const items = React.Children.toArray(children);

  useEffect(() => {
    setIsMobile(document.body.clientWidth < 800);
  }, []);

  useEffect(() => {
    const activeTab = items.find(item => item?.type?.displayName === Item.displayName && isTabActive({ title: item?.props?.title, searchParams }));
    if (!activeTab) {
      const title = urlSanitize(React.Children.toArray(children).find(item => item?.type?.displayName === Item.displayName)?.props?.title);
      navigate(`${pathname}?t=${title}`, { replace: true });
    }
  }, [pathname]);

  const navigation = items.map((item, index) => {
    switch (item.type?.displayName) {
      case Item.displayName: {
        const { title } = item.props;
        const isActive = searchParams.get('t') === urlSanitize(title);
        return <Item item={item} to={pathname} isActive={isActive} isMobile={isMobile} key={title} />;
      }

      case ActionItem.displayName: {
        const { title } = item.props;
        return <ActionItem item={item} isMobile={isMobile} key={title} />;
      }

      default:
        return item;
    }
  });

  return (
    <ResizeSensor observeParents={true} onResize={() => setIsMobile(document.body.clientWidth < 800)}>
      <div className={classNames(classes.tabs, !!isMobile && classes.mobile)}>
        <div className={classes.navigation}>
          <SimpleBar className={classes.wrapper}>{navigation}</SimpleBar>
        </div>
        {renderTab({ items, isMobile, searchParams })}
      </div>
    </ResizeSensor>
  );
};

export default Container;

const renderTab = ({ items, isMobile, searchParams }) => {
  if (isMobile) {
    return null;
  }

  const activeTab = items.find(item => item?.type?.displayName === Item.displayName && isTabActive({ title: item?.props?.title, searchParams }));
  if (!!activeTab?.props?.noScroll) {
    return (
      <div className={classes.panel}>
        <div className={classes.noScrollWrapper}>{Item.render?.(activeTab)}</div>
      </div>
    );
  }

  return (
    <div className={classes.panel}>
      <SimpleBar className={classes.wrapper}>{Item.render?.(activeTab)}</SimpleBar>
    </div>
  );
};
