import React from 'react';
import { Link } from 'react-router-dom';

import classes from './breadcrumbs.module.scss';

const Breadcrumbs = ({ path }) => {
  if (!path || !path.length) return null;

  const dashboard = { name: 'Dashboard', to: '/dashboard' };
  const dots = { name: '...' };
  const getParts = () => {
    const parts = path?.length >= 3 ? [dashboard, dots, path[path.length - 2], path[path.length - 1]] : [dashboard, ...path];
    const shortened = parts.map(p => (p?.name?.length > 45 ? { ...p, name: `${p?.name?.substring(0, 45)}...` } : p));
    return shortened;
  };

  return (
    <div className={classes.breadcrumbs}>
      {getParts().map((part, index) => {
        const Component = !!part.to ? Link : 'div';
        return (
          <Component key={index} className={classes.link} to={part.to}>
            {part.name}
          </Component>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
