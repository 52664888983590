import React from 'react';
import Form from '../../../../../../../../form';
import Fields from '../../../../../../../../fields';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

const ExecupayPayrollProvider = ({ configurationType, scopeId, data, onSubmit, loading }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        configurationType,
        scopeId,
        data: {
          providerName: 'Execupay',
          configuration: {
            baseUrl: data?.configuration?.baseUrl || '',
            tokenUrl: data?.configuration?.tokenUrl || '',
            userName: data?.configuration?.userName || '',
            password: data?.configuration?.password || '',
            client: data?.configuration?.client || '',
            clientId: data?.configuration?.clientId || 0,
            licenseeId: data?.configuration?.licenseeId || 0,
            scope: data?.configuration?.scope || '',
            secret: data?.configuration?.secret || '',
            searchPeriodInDays: 3000,
          },
        },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.string().required('Scope is required'),
        data: Yup.object().shape({
          providerName: Yup.string().required('Provider is required'),
          configuration: Yup.object().shape({
            baseUrl: Yup.string().required('Base URL is required'),
            tokenUrl: Yup.string().required('Token URL is required'),
            userName: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required'),
            client: Yup.string().required('Client is required'),
            clientId: Yup.number().required('Client Identifier is required'),
            licenseeId: Yup.number().required('Licensee Identifier is required'),
            scope: Yup.string().required('Scope is required'),
            secret: Yup.string().required('Secret is required'),
          }),
        }),
      })}
      enableReinitialize
    >
      <Fields.Text label="Base URL" name="data.configuration.baseUrl" />
      <Fields.Text label="Token URL" name="data.configuration.tokenUrl" />
      <Fields.Text label="Username" name="data.configuration.userName" />
      <Fields.Text label="Password" name="data.configuration.password" />
      <Fields.Text label="Client" name="data.configuration.client" />
      <Fields.Text label="Client Identifier" name="data.configuration.clientId" type="number" />
      <Fields.Text label="Licensee Identifier" name="data.configuration.licenseeId" type="number" />
      <Fields.Text label="Scope" name="data.configuration.scope" />
      <Fields.Text label="Secret" name="data.configuration.secret" />
      <Button type="submit" text="Submit" loading={loading} outlined fill />
    </Form>
  );
};

export default ExecupayPayrollProvider;
