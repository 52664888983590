import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon, Menu, MenuItem, Popover } from '@blueprintjs/core';

import Form, { Field } from '../../form';
import { CheckboxGroupField } from '../group-input';
import TextField from '../text';

import styles from './select.module.scss';

/**
 * Multi-Select filed.
 * @param classes css overrides.
 * @param name field name.
 * @param label field label.
 * @param placeholder field placeholder.
 * @param description field description.
 * @param options field options options provided upfront.
 * @param remoteOptions field options options provided via api request. Configuration object properties are loading, request, mapper (response mapper) and filter ('local' or 'remote')
 * @param disabled a flag indicating if the field is disabled.
 * @param validate field validation.
 * @param searchable a flag indicating if the field options are filterable.
 * @param submitOnChange a flag indicating if the form containing the field will be submitted once value is selected.
 */

const displayType = {
  DEFAULT: 'default',
  CHECKBOX: 'checkbox',
};

const MultiSelectField = ({
  classes,
  name,
  label,
  placeholder = 'Select...',
  description,
  options = [],
  remoteOptions,
  validate,
  submitOnChange = false,
  display = displayType.DEFAULT,
  direction = 'horizontal',
  outline,
  minimal,
  showError = true,
  disabled,
  searchable = false,
}) => {
  const isMounted = useRef(false);
  const [internalOptions, setInternalOptions] = useState(!!remoteOptions ? [] : options);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    async function load() {
      if (remoteOptions?.request && remoteOptions?.mapper) {
        const result = await remoteOptions.request(searchString);
        setInternalOptions(remoteOptions.mapper(result || []));
      }
    }
    void load();
  }, []);

  useEffect(() => {
    if (!remoteOptions) {
      setInternalOptions(options);
    }
  }, [options, remoteOptions]);

  useEffect(() => {
    async function search() {
      if (isMounted.current) {
        if (remoteOptions?.request && remoteOptions?.mapper && remoteOptions?.filter === 'remote') {
          const result = await remoteOptions.request(searchString);
          setInternalOptions(remoteOptions.mapper(result || []));
        }
      } else {
        isMounted.current = true;
      }
    }
    void search();
  }, [searchString]);

  if (display === displayType.CHECKBOX) {
    return (
      <CheckboxGroupField
        name={name}
        label={label}
        description={description}
        disabled={disabled}
        inline={direction === 'horizontal'}
        options={internalOptions}
        submitOnChange={submitOnChange}
      />
    );
  }

  return (
    <Field classes={classes} name={name} label={label} description={description} validate={validate} outline={outline} minimal={minimal} showError={showError}>
      {({ field, form }) => {
        const onSelect = value => {
          const selected = !field?.value || !Array.isArray(field?.value) ? [] : field?.value;
          const updates = selected?.includes(value) ? selected?.filter(s => s !== value) : [...selected, value];
          form.setFieldValue(name, updates);
          if (submitOnChange) {
            form.submitForm();
          }
        };

        return (
          <MultiSelectPopover
            placeholder={placeholder}
            value={field?.value}
            internalOptions={internalOptions}
            remoteOptions={remoteOptions}
            disabled={disabled}
            searchable={searchable}
            searchString={searchString}
            setSearchString={setSearchString}
            onSelect={onSelect}
          />
        );
      }}
    </Field>
  );
};

const MultiSelectPopover = ({ placeholder, value, internalOptions, remoteOptions, disabled, searchable, searchString, setSearchString, onSelect }) => {
  // Render options.
  const content = useMemo(() => {
    const filterOptions = option =>
      searchString === '' ||
      option?.value?.toString()?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
      option?.label?.toString()?.toLowerCase()?.includes(searchString?.toLowerCase());

    const filtered = !!searchable || !!remoteOptions ? internalOptions.filter(filterOptions) : internalOptions;
    return (
      <Menu>
        {(!!searchable || !!remoteOptions) && (
          <div className={styles.searchField}>
            <Form initialValues={{ searchString }} onSubmit={v => setSearchString(v.searchString)}>
              <TextField placeholder="Search..." name="searchString" submitOnChange minimal showError={false} outline={false} />
            </Form>
          </div>
        )}
        {filtered?.length ? (
          <div className={styles.wrapper}>
            {filtered?.map((obj, index) => (
              <MenuItem
                key={index}
                text={obj.label}
                shouldDismissPopover={false}
                onClick={() => onSelect(obj.value)}
                className={styles.item}
                label={value?.includes(obj.value) ? <Icon icon="small-tick" /> : null}
              />
            ))}
          </div>
        ) : (
          <MenuItem text="Empty..." disabled />
        )}
      </Menu>
    );
  }, [value, searchString, internalOptions]);

  // Render current value label.
  const text = useMemo(() => {
    if (!value || (Array.isArray(value) ? !value?.length : !!value?.length)) {
      return placeholder;
    }

    return `${value?.length} Selected`;
  }, [value, internalOptions]);

  return (
    <Popover
      fill
      placement="bottom"
      popoverClassName={styles.popover}
      content={content}
      disabled={disabled || (!!remoteOptions?.loading && remoteOptions?.filter !== 'remote')}
      onClosed={() => setSearchString('')}
    >
      <Button className={styles.selectButton} text={text} alignText="left" rightIcon="caret-down" disabled={disabled || !!remoteOptions?.loading} />
    </Popover>
  );
};
export default MultiSelectField;
