import React, { useState } from 'react';

export const WizardExpandContext = React.createContext({});

const WizardExpandProvider = ({ children }) => {
  const [page, setPage] = useState();
  const [group, setGroup] = useState();

  const togglePage = id => setPage(id === page ? undefined : id);
  const toggleGroup = id => setGroup(id === group ? undefined : id);
  const isPageExpanded = id => id === page;
  const isGroupExpanded = id => id === group;

  const expanded = { page, group };

  return <WizardExpandContext.Provider value={{ expanded, togglePage, toggleGroup, isPageExpanded, isGroupExpanded }}>{children}</WizardExpandContext.Provider>;
};

export default WizardExpandProvider;
