import React from 'react';
import NavigationMenu from './menu';
import NavigationItem from './item';
import MobileMenu from './mobile';
import NavigationContainer from './container';

const Navigation = {
  Container: NavigationContainer,
  Menu: NavigationMenu,
  Item: NavigationItem,
  Spacer: () => <NavigationItem />,
  Mobile: MobileMenu,
};

export default Navigation;
