import moment from 'moment/moment';
import { DateFormat } from '../../../../../models/enumerations/date-format';

export const minDateOptions = [
  { label: 'days ago', value: 'days' },
  { label: 'months ago', value: 'months' },
  { label: 'years ago', value: 'years' },
];

export const maxDateOptions = [
  { label: 'days from now', value: 'days' },
  { label: 'months from now', value: 'months' },
  { label: 'years from now', value: 'years' },
];

export function getMinDateValidationValue(value, unit, targetFieldValue, format = DateFormat.DEFAULT) {
  let date;
  if (targetFieldValue) {
    date = moment(targetFieldValue, format).startOf('day').toDate();
  } else if (value !== '' && !Number.isNaN(Number(value))) {
    date = moment().subtract(Number(value), unit).startOf('day').toDate();
  }
  return date;
}

export function getMaxDateValidationValue(value, unit, targetFieldValue, format = DateFormat.DEFAULT) {
  let date;
  if (targetFieldValue) {
    date = moment(targetFieldValue, format).startOf('day').toDate();
  } else if (value !== '' && !Number.isNaN(Number(value))) {
    date = moment().add(Number(value), unit).startOf('day').toDate();
  }
  return date;
}
