import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Fields from '../../../components/fields';
import Form from '../../../components/form';
import Avatar from './../../../components/avatar';
import Page from './../../../components/page';
import Tabs from './../../../components/tabs';

import { getFullName } from '../../../utilities/user';

import { uploadAvatar } from '../../../apis/profile';
import { getUserProfile } from '../../../state/identity';
import Banking from './banking';
import Documents from './documents';
import General from './general';
import Security from './security';
import SignatureSettings from './signature-settings';

import classes from './profile.module.scss';

const Profile = ({ user, getUserProfile }) => {
  const avatar = (
    <Form initialValues={{ avatar: null }} onSubmit={() => null}>
      <Fields.File
        crop={{ aspectRatio: 1, width: 220, height: 220 }}
        label="Upload avatar"
        mimeTypes={'image/*'}
        name="avatar"
        maxSize={5}
        api={avatar => uploadAvatar({ avatar })}
        onUploaded={() => getUserProfile()}
        outline={false}
        minimal={true}
        showError={false}
      >
        <Avatar name={getFullName(user)} url={user.avatar} size={{ box: 220, text: 30 }} styles={{ marginBottom: '15px' }} upload />
      </Fields.File>
    </Form>
  );

  return (
    <Page title={'Profile'} scroll={false} path={[{ name: 'Profile', to: '/profile' }]}>
      <div className={classNames(classes.profile)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} />
          <Tabs.Item title="Documents" component={Documents} />
          <Tabs.Item title="Signature Settings" component={SignatureSettings} />
          <Tabs.Item title="Banking" component={Banking} />
          <Tabs.Item title="Security" component={Security} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({ getUserProfile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
