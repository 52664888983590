import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Routes, Route } from 'react-router-dom';

import Authentication from '../authentication';
import TelemetryProvider from '../telemetry/provider';
import Offline from '../components/offline';
import ErrorHandler from '../components/error-handler';
import { Roles } from '../models/enumerations/roles';

import Platform from '../containers/platform';
import Vendor from '../containers/vendor';
import Client from '../containers/client';
import Supplier from '../containers/supplier';
import Individual from '../containers/individual';

const AppRoutes = ({ authenticated, loggedOut, membership, offline }) => {
  if (offline) {
    return (
      <TelemetryProvider>
        <ErrorHandler>
          <Offline />
        </ErrorHandler>
      </TelemetryProvider>
    );
  }

  if (!authenticated) {
    if (loggedOut) {
      return (
        <TelemetryProvider>
          <ErrorHandler>
            <Authentication.Logout />
          </ErrorHandler>
        </TelemetryProvider>
      );
    }

    return (
      <TelemetryProvider>
        <ErrorHandler>
          <Routes>
            <Route path="/authentication/logout" Component={Authentication.Logout} />
            <Route path="/authentication/callback" Component={Authentication.Callback} />
            <Route path="*" Component={Authentication.Initial} />
          </Routes>
        </ErrorHandler>
      </TelemetryProvider>
    );
  }

  const getLayout = () => {
    switch (membership?.roleId) {
      case Roles.Platform.Administrator:
      case Roles.Platform.Manager:
      case Roles.Platform.RegionalManager:
      case Roles.Platform.EvaluationManager:
        return Platform;

      case Roles.Vendor.Manager:
      case Roles.Vendor.Subordinate:
        return Vendor;

      case Roles.Client.Manager:
      case Roles.Client.HiringManager:
      case Roles.Client.ProjectManager:
        return Client;

      case Roles.Supplier.Manager:
      case Roles.Supplier.Subordinate:
      case Roles.Supplier.Witness:
        return Supplier;

      default:
        return Individual;
    }
  };

  return (
    <TelemetryProvider>
      <ErrorHandler>
        <Routes>
          <Route exact={true} path="/authentication/impersonate/:email" Component={Authentication.Impersonate} />
          <Route exact={true} path="/authentication/logout" Component={Authentication.Logout} />
          <Route path="*" Component={getLayout()} key={`${membership?.roleId}-${membership?.organization?.id}`} />
        </Routes>
      </ErrorHandler>
    </TelemetryProvider>
  );
};

const mapStateToProps = state => ({
  offline: state.application.offline,
  authenticated: state.identity.authenticated,
  loggedOut: state.identity.loggedOut,
  membership: state.identity.membership,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
