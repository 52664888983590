const data = {
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECT,
  scope: process.env.REACT_APP_IDENTITY_SCOPES,
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
  checkSessionInterval: 10,
};

const getAuthority = () => (data.authority.endsWith('/') ? data.authority : `${data.authority}/`);

const configuration = { ...data, authority: getAuthority() };

export default configuration;
