import { Button, ButtonGroup, Spinner } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';

import useApi from '../../../hooks/use-api';

import { getDocument, updateDocument, uploadDocument } from '../../../apis/documents';
import Dialog from '../../dialog';
import { Field } from '../../form';
import DatePicker from '../date/date-picker';
import FileDropzone from '../file/file-dropzone';
import styles from './document.module.scss';
import classNames from 'classnames';

const DialogType = {
  Upload: 'upload',
  DatePicker: 'date-picker',
};

const DocumentFileUploader = ({
  form,
  name,
  value,
  expirationDate,
  placeholder,
  expiration,
  disabled,
  readonly,
  maxSize,
  extensions,
  mimeTypes,
  submitOnChange,
  owner,
  setExpirationDate,
}) => {
  const [dialog, setDialog] = useState();
  const getDocumentApi = useApi(getDocument, { skip: true });
  const updateDocumentApi = useApi(updateDocument, { skip: true });

  useEffect(() => {
    if (readonly || !value) {
      return;
    }
    void getDocumentApi.request(value);
  }, [value]);

  const handleUpdate = async expirationDate => {
    setExpirationDate(expirationDate);
    if (readonly || !value) {
      return;
    }

    await updateDocumentApi.request({ ...getDocumentApi.response, expiration_date: expirationDate });
    form.validateField(name);
  };

  const onDrop = async file => {
    if (readonly) {
      setTimeout(() => setDialog(undefined), 800);
      return;
    }

    const result = await uploadDocument({ file, type: owner?.ownerType, id: owner?.ownerId, expirationDate });
    form.setFieldValue(name, result.id);
    if (submitOnChange) {
      form.submitForm();
    }
    setTimeout(() => setDialog(undefined), 800);
  };

  const text = value ? getDocumentApi.response?.name || '...' : placeholder || 'Browse';

  return (
    <div className={styles.document}>
      <ButtonGroup fill className={styles.btnGroup}>
        <Button
          className={classNames(styles.button, styles.browse)}
          icon={value ? 'paperclip' : undefined}
          fill
          text={text}
          rightIcon={getDocumentApi.loading ? <Spinner size={14} /> : null}
          disabled={disabled}
          onClick={() => setDialog(DialogType.Upload)}
        />
        {!!expiration && (
          <React.Fragment>
            <div className={classNames(styles.button, styles.datePicker)}>
              <DatePicker
                rightIcon={expirationDate && updateDocumentApi.loading ? <Spinner size={14} /> : undefined}
                value={expirationDate}
                handleChange={handleUpdate}
                disabled={disabled}
                placeholder="Expiration date"
              />
            </div>
            <Button
              className={classNames(styles.button, styles.expiration)}
              small
              text="Does not expire."
              disabled={disabled}
              rightIcon={expirationDate === null ? updateDocumentApi.loading ? <Spinner size={14} /> : 'tick' : undefined}
              onClick={() => handleUpdate(null)}
            />
          </React.Fragment>
        )}
      </ButtonGroup>
      <Dialog isOpen={dialog === DialogType.Upload} title="Browse document" onClose={() => setDialog(undefined)}>
        <FileDropzone maxSize={maxSize * 1000 * 1000} extensions={extensions} mimeTypes={mimeTypes} onDrop={onDrop} />
      </Dialog>
    </div>
  );
};

const DocumentField = props => {
  const {
    classes,
    label,
    placeholder,
    description,
    name,
    validate,
    extensions,
    mimeTypes,
    maxSize = 100,
    submitOnChange = false,
    expiration,
    required,
    disabled,
    readonly,
    owner,
  } = props;
  const [expirationDate, setExpirationDate] = useState();

  const _validate = value => {
    if (required && !value) {
      return 'Document is not uploaded';
    }

    if (required && expiration && expirationDate === undefined) {
      return 'Expiration date missing';
    }

    return validate?.(value);
  };

  return (
    <Field classes={classes} label={label} description={description} name={name} validate={_validate}>
      {({ form, field }) => {
        return (
          <DocumentFileUploader
            form={form}
            name={name}
            value={field.value}
            placeholder={placeholder}
            expirationDate={expirationDate}
            maxSize={maxSize}
            mimeTypes={mimeTypes}
            extensions={extensions}
            expiration={expiration}
            disabled={disabled}
            readonly={readonly}
            owner={owner}
            submitOnChange={submitOnChange}
            setExpirationDate={setExpirationDate}
          />
        );
      }}
    </Field>
  );
};

export default DocumentField;
