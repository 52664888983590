import * as Yup from 'yup';

import { ObjectTypes } from '../../domain/types';

const excludedFieldTypes = [ObjectTypes.Signature];
const validateAndCastContent = (template, content, descriptor) => {
  try {
    const json = JSON.parse(content);
    let temp;
    try {
      temp = templateSchema.validateSync(json, { abortEarly: true });
    } catch (e) {
      return {
        error: `The file content is not valid. Error: ${e.errors.join(', ')}`,
      };
    }

    if (template.contextTypeName !== temp.contextTypeName) {
      return {
        error: `contextTypeName must be a ${template.contextTypeName} but the final value was: ${temp.contextTypeName}`,
      };
    }

    const requiredFieldsMap = getRequiredFieldsMap({}, descriptor);

    const excludedFields = [];
    const pages = temp.pages.map(p => ({
      ...p,
      groups: p?.groups?.map(g => {
        return {
          ...g,
          fields: g?.fields?.filter(f => {
            if (requiredFieldsMap.hasOwnProperty(f.path)) {
              requiredFieldsMap[f.path] = true;
            }
            const excluded = excludedFieldTypes.includes(f.type);
            if (excluded) {
              excludedFields.push(f.label);
            }
            return !excluded;
          }),
        };
      }),
    }));

    const missingRequiredFields = Object.keys(requiredFieldsMap).filter(path => !requiredFieldsMap[path]);
    if (missingRequiredFields.length) {
      return {
        error: `Missing required fields in template you selected: ${missingRequiredFields.join(', ')}`,
      };
    }

    return {
      template: {
        ...template,
        categories: temp.categories,
        pages,
      },
      excluded: excludedFields,
    };
  } catch (e) {
    return {
      error: 'The file format is invalid',
    };
  }
};

const validObjectTypes = Object.values(ObjectTypes);

const templateSchema = Yup.object({
  id: Yup.string().required().uuid(),
  type: Yup.string().required().matches(new RegExp(ObjectTypes.Wizard)),
  title: Yup.string().required(),
  refName: Yup.string().required(),
  contextTypeName: Yup.string().required(),
  pages: Yup.array().of(
    Yup.object({
      id: Yup.string().required().uuid(),
      type: Yup.string().required().matches(new RegExp(ObjectTypes.Page)),
      title: Yup.string().required(),
      groups: Yup.array().of(
        Yup.object({
          id: Yup.string().required().uuid(),
          type: Yup.string().required().matches(new RegExp(ObjectTypes.Group)),
          fields: Yup.array().of(
            Yup.object({
              id: Yup.string().required().uuid(),
              type: Yup.string()
                .required()
                .test('is-valid-field-type', 'Invalid or unsupported field type', value => {
                  return validObjectTypes.includes(value);
                }),
            }),
          ),
        }),
      ),
    }),
  ),
});

function getRequiredFieldsMap(requiredFields, obj) {
  if (obj?.children?.length) {
    obj.children.filter(child => !child.isInitialData).forEach(child => getRequiredFieldsMap(requiredFields, child));
    return requiredFields;
  }

  if (obj.required) {
    requiredFields[obj.path] = false;
  }
  return requiredFields;
}

export default validateAndCastContent;
