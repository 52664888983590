import React, { useState } from 'react';
import { Button, ButtonGroup, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import Audit from '../../../../../../components/audit';
import Box from '../../../../../../components/box';

import { ClassificationType, abbreviateClassification, classificationTypeToLabel } from '../../../../../../domain/classification-type';
import { classificationPreferenceTypeToLabel } from '../../../../../../domain/classification-preference-type';
import { purchaseOrderStatusToLabel, PurchaseOrderType } from '../../../../../../domain/purchase-order-type';

import { getClientDisplayName } from '../../../../../../utilities/client';
import {
  displayAllocation,
  displayCompensationFrequency,
  displayCompensationType,
  displayEndDate,
  displayRate,
  displayStartDate,
} from '../../../../../../utilities/engagement';
import { getLocationTypeName } from '../../../../../../utilities/location';

import WithConfirmation from '../../../../../../hooks/with-confirmation';
import useApi from '../../../../../../hooks/use-api';
import { getEngagementUpdate, removeEngagementDepartment } from '../../../../../../apis/client/engagements';

import EngagementUpdates from '../../../../../../components/organizational/client/engagement-updates';

import PurchaseOrderForm from './forms/purchase-order-form';
import DepartmentForm from './forms/department-form';
import HiringManagerForm from './forms/hiring-manager-form';
import Metadata from './metadata';
import EngagementTerminationStatus from './status';
import ViewEngagementUpdates from './updates';

import classes from './../details.module.scss';

const General = ({ api }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const { loading, response: engagement } = api;

  const getEngagementUpdateApi = useApi(getEngagementUpdate, { params: { engagementId: engagement?.id }, skip: !engagement?.id });

  const openDialog = type => setDialog({ open: true, type });
  const closeDialog = () => setDialog({ open: false, type: undefined });

  const onSave = () => {
    void api.request(engagement.id);
    void getEngagementUpdateApi?.request({ engagementId: engagement?.id });
    closeDialog();
  };

  const handleDepartmentRemove = async () => {
    await removeEngagementDepartment({ engagementId: engagement.id, clientId: engagement.clientId, departmentId: engagement.departmentId });
    void api.request(engagement.id);
  };

  const allocation = displayAllocation(engagement?.compensation?.allocation, engagement?.compensation?.type);

  return (
    <div className={classes.tab}>
      <Box
        title="Engagement details"
        outlined
        actions={
          <ButtonGroup>
            <ViewEngagementUpdates engagement={engagement} updates={getEngagementUpdateApi?.response} />
            <Button
              small
              outlined
              loading={getEngagementUpdateApi.loading}
              disabled={getEngagementUpdateApi?.loading || !!getEngagementUpdateApi.response}
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Request engagement updates"
              onClick={() => openDialog('request-updates')}
            />
            <Audit id={engagement?.id} type="engagement" icon small outlined />
          </ButtonGroup>
        }
        loading={loading}
        monochrome
        accordion
      >
        <Box.Item label="Supplier">
          {engagement?.supplierName} ({engagement?.classification?.result === 'unknown' ? 'tbd' : abbreviateClassification(engagement?.classification?.result)})
        </Box.Item>
        {engagement?.classification?.result === ClassificationType.IndependentContractor && (
          <Box.Item label="Manager">
            {engagement?.supplierManagerName || 'Not provided'}
            {engagement?.supplierManagerName && (
              <Popover content={<SupplierManagerContactInfo engagement={engagement} />}>
                <Button small outlined icon={<Icons.Info size={16} strokeWidth={1.5} />} title="Show manager contact info." />
              </Popover>
            )}
          </Box.Item>
        )}
        <Box.Item label="Title">
          {engagement?.title}
          <Popover
            content={
              <div className={classes.description}>
                <div className={classes.label}>Engagement description</div>
                <SimpleBar className={classes.descriptionWrapper}>
                  <div className={classes.descriptionContent}>{engagement?.description}</div>
                </SimpleBar>
              </div>
            }
          >
            <Button small outlined icon={<Icons.Info size={16} strokeWidth={1.5} />} title="Show engagement description" />
          </Popover>
        </Box.Item>
        <Box.Item label="Client">{getClientDisplayName(engagement?.clientName, engagement?.parentClientName)}</Box.Item>
        <Box.Item label="Hiring manager">
          {engagement?.managerName}
          <Button
            small
            outlined
            icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
            title="Update hiring manager"
            onClick={() => openDialog('hiring-manager')}
          />
        </Box.Item>
        <Box.Item label="Department">
          {engagement?.department || '-'}
          <ButtonGroup>
            <WithConfirmation
              disabled={!engagement?.department}
              messages={{ question: `Remove supplier from the "${engagement?.department}" department ?`, confirmButton: 'Remove' }}
            >
              <Button
                small
                outlined
                disabled={!engagement?.department}
                onClick={handleDepartmentRemove}
                icon={<Icons.X size={16} strokeWidth={1.5} />}
                title="Remove supplier's department"
              />
            </WithConfirmation>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update department details"
              onClick={() => openDialog('department-details')}
            />
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Contract period">
          {displayStartDate(engagement?.startDate)} - {displayEndDate(engagement?.endDate)}
        </Box.Item>
        <Box.Item label="Country">{engagement?.country?.name}</Box.Item>
        <Box.Item label="Location type">{getLocationTypeName(engagement?.locationType)}</Box.Item>
        <Box.Item label="Address">{engagement?.location?.prettyName || 'Not provided'}</Box.Item>
      </Box>
      <Box title="Classification" outlined loading={loading} monochrome accordion>
        <Box.Item label="Classification result">
          {classificationTypeToLabel(engagement?.classification?.result)}
          <Popover
            content={
              <div className={classes.pad15}>
                <p>
                  <strong>Classification result elaboration</strong>
                </p>
                {engagement?.classification?.elaboration}
              </div>
            }
          >
            <Button small outlined icon={<Icons.Info size={16} strokeWidth={1.5} />} title="Show engagement classification elaboration" />
          </Popover>
        </Box.Item>
        <Box.Item label="Client preference">{classificationPreferenceTypeToLabel(engagement?.classification?.clientPreference)}</Box.Item>
        <Box.Item label="Supplier preference">{classificationTypeToLabel(engagement?.classification?.supplierPreference)}</Box.Item>
      </Box>

      <Box
        title="Payment details"
        outlined
        actions={
          <ButtonGroup>
            <ViewEngagementUpdates engagement={engagement} updates={getEngagementUpdateApi?.response} />
            <Button
              small
              outlined
              loading={getEngagementUpdateApi.loading}
              disabled={getEngagementUpdateApi?.loading || !!getEngagementUpdateApi.response}
              icon={<Icons.Edit2 size={16} strokeWidth={1.5} />}
              title="Request engagement updates"
              onClick={() => openDialog('request-updates')}
            />
            <Audit id={engagement?.compensation?.id} type="compensation" icon small outlined />
          </ButtonGroup>
        }
        loading={loading}
        monochrome
      >
        <Box.Item label="Payment type">{displayCompensationType(engagement?.compensation?.type)}</Box.Item>
        <Box.Item label="Rate">
          {displayRate(engagement?.compensation?.amount, engagement?.compensation?.currency?.symbol, engagement?.compensation?.type)}
        </Box.Item>
        <Box.Item label="Time allocation">{allocation || '-'}</Box.Item>
        <Box.Item label="Frequency">{displayCompensationFrequency(engagement?.compensation?.frequency)}</Box.Item>
        {engagement?.classification?.result === ClassificationType.Employee && (
          <Box.Item label="Is exempt ?">{!!engagement?.compensation?.isExempt ? 'Yes' : 'No'}</Box.Item>
        )}
        <Box.Item label="Tracks work">{!!engagement?.compensation?.tracksWork ? 'Yes' : 'No'}</Box.Item>
      </Box>

      <Box
        title="Purchase order"
        outlined
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update purchase order details"
              onClick={() => openDialog('purchase-order')}
            />
            <Audit id={engagement?.compensation?.id} type="purchaseOrder" icon small outlined />
          </ButtonGroup>
        }
        loading={loading}
        monochrome
      >
        <Box.Item label="Status">{purchaseOrderStatusToLabel(engagement?.purchaseOrderStatus)}</Box.Item>
        {engagement?.purchaseOrderStatus === PurchaseOrderType.Available && (
          <React.Fragment>
            <Box.Item label="Number">{engagement?.purchaseOrder?.number}</Box.Item>
            <Box.Item label="Amount">{engagement?.purchaseOrder?.amount}</Box.Item>
            <Box.Item label="Currency">{engagement?.purchaseOrder?.currencyId}</Box.Item>
            <Box.Item label="Reference">{engagement?.purchaseOrder?.reference}</Box.Item>
          </React.Fragment>
        )}
      </Box>
      <Metadata dialog={dialog} engagement={engagement} closeDialog={closeDialog} openDialog={openDialog} onSave={onSave} />
      <EngagementTerminationStatus engagement={engagement} loading={loading} />
      <EngagementUpdates
        {...{ engagement, open: !!dialog?.open && dialog?.type === 'request-updates', onClose: closeDialog, initialize: true, onComplete: onSave }}
      />
      <DepartmentForm isOpen={!!dialog?.open && dialog?.type === 'department-details'} engagement={engagement} onClose={closeDialog} onSave={onSave} />
      <HiringManagerForm isOpen={!!dialog?.open && dialog?.type === 'hiring-manager'} engagement={engagement} onClose={closeDialog} onSave={onSave} />
      <PurchaseOrderForm isOpen={!!dialog?.open && dialog?.type === 'purchase-order'} engagement={engagement} onClose={closeDialog} onSave={onSave} />
    </div>
  );
};

export default General;

const SupplierManagerContactInfo = ({ engagement }) => {
  return (
    <Box title="Contact Info" monochrome className={classes.supplierContactInfoBox}>
      <Box.Item label="Name">{engagement?.supplierManagerName}</Box.Item>
      <Box.Item label="Email">
        <a href={`mailto:${engagement?.supplierManagerEmail}`}>{engagement?.supplierManagerEmail}</a>
      </Box.Item>
      <Box.Item label="Phone">{engagement?.supplierManagerPhone}</Box.Item>
      <Box.Item label="Address">{engagement?.supplierManagerAddress}</Box.Item>
    </Box>
  );
};
