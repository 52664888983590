import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, ButtonGroup, Classes, Popover } from '@blueprintjs/core';
import moment from 'moment';
import classNames from 'classnames';

import useApi from '../../../../../../../hooks/use-api';
import Box from '../../../../../../../components/box';

import { getUserLogons } from '../../../../../../../apis/profile';

import classes from '../../details.module.scss';

const LogonHistory = ({ user }) => {
  const logonsApi = useApi(() => getUserLogons({ userId: user?.id }), { skip: !user?.id });

  return (
    <div className={classes.tab}>
      <Box title="Logon History" outlined monochrome>
        {logonsApi?.loading ? (
          [1, 2, 3, 4, 5].map((_, i) => (
            <Box.TabularItem key={i}>
              <Box.ItemValue className={Classes.SKELETON}>date</Box.ItemValue>
              <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
              <ButtonGroup>
                <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
              </ButtonGroup>
            </Box.TabularItem>
          ))
        ) : !logonsApi?.response?.length ? (
          <Box.TabularItem key={document.name}>
            <Box.ItemLabel fill>No activity recorded.</Box.ItemLabel>
          </Box.TabularItem>
        ) : (
          logonsApi?.response?.map(({ id, ipAddress, loginType, logonDateTime, platform, platformVersion, userDevice }, i) => (
            <Box.TabularItem key={id}>
              <Box.ItemLabel>{moment(logonDateTime).format('LLL')}</Box.ItemLabel>
              <Box.ItemValue>{!!loginType ? loginType : 'Password'} authenticated.</Box.ItemValue>
              <Popover
                content={
                  <div className={classes.logonPopover}>
                    <div className={classes.row}>
                      <div className={classes.label}>IP Address</div>
                      <div className={classNames(classes.value, classes.monospace)}>{ipAddress}</div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.label}>Platform</div>
                      <div className={classNames(classes.value, classes.monospace)}>
                        {platform?.split('"')?.join('')} {platformVersion}
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.label}>Device</div>
                      <div className={classNames(classes.value, classes.monospace)}>{userDevice}</div>
                    </div>
                  </div>
                }
              >
                <Button small outlined text="Details" />
              </Popover>
            </Box.TabularItem>
          ))
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LogonHistory);
