import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Form from '../../../../../../components/form';
import Fields from '../../../../../../components/fields';

import { createTimeImportRequest } from '../../../../../../apis/platform/time';

import { MimeTypes } from '../../../../../../utilities/mime-types';
import { downloadSampleTimeImportCsv } from '../../../../../../utilities/files';

import classes from '../../../time.module.scss';

const CreateImportRequestForm = ({ onClose, reloadList }) => {
  const onUploaded = () => {
    reloadList();
    onClose();
  };

  return (
    <Form initialValues={{ file: null }} onSubmit={() => null}>
      {form => {
        return (
          <div className={classes.importForm}>
            {renderInfo()}
            <Fields.File
              fill
              outline={false}
              name="file"
              mimeTypes={[MimeTypes.CSV]}
              label="Upload file"
              api={file => createTimeImportRequest({ file })}
              resultValueParser={r => r?.id}
              onUploaded={onUploaded}
              disabled={!form?.values?.clientId}
              showError={false}
            >
              <Button fill outlined icon={<Icons.Upload size={16} strokeWidth={1.5} />} text="Choose file" />
            </Fields.File>
          </div>
        );
      }}
    </Form>
  );
};

const renderInfo = () => {
  const info = <Icons.AlertTriangle className={classes.info} size={16} strokeWidth={1.5} />;
  const download = <Icons.Paperclip className={classes.info} size={16} strokeWidth={1.5} />;

  return (
    <div className={classes.importGuides}>
      <div>Please review list and import example file available below:</div>
      <ul>
        <li>{info}All columns present in sample import file are required and need to remain in the file, however some values are optional.</li>
        <li>{info}Data delimiter used in import process is "," comma, make sure that any column which contains comma is under double quotes ("...").</li>

        <li className={classes.column}>
          <strong>EngagementId</strong>
          <span>engagement identifier, can be found on each engagement profile page as part of the url.</span>
        </li>
        <li className={classes.column}>
          <strong>UserEmail</strong>
          <span>work user email address for which records are being imported.</span>
        </li>
        <li className={classes.column}>
          <strong>Type</strong>
          <span>record type, shift, deliverable or expense.</span>
        </li>
        <li className={classes.column}>
          <strong>Date</strong>
          <span>
            record date and time, represents a date for which record is made, however in case of shifts make sure that it includes accurate time when the shift
            begun. Valid format looks like this "YYYY-MM-DDTHH:mm" or "2024-05-21T09:00"
          </span>
        </li>
        <li className={classes.column}>
          <strong>Duration</strong>
          <span>required in case of shifts and it has to be provided in following format "HH:mm" or "04:30" (4 hours and 30 minutes).</span>
        </li>
        <li className={classes.column}>
          <strong>Amount</strong>
          <span>required in case of deliverables or expenses, floating point numerical value.</span>
        </li>
        <li className={classes.column}>
          <strong>Notes</strong>
          <span>optional record notes.</span>
        </li>

        <li>
          {download}
          <span className={classes.sampleFile} onClick={downloadSampleTimeImportCsv}>
            Sample file
          </span>
        </li>
      </ul>
    </div>
  );
};

export default CreateImportRequestForm;
