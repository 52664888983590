import { StorageManager } from '../../authentication/storage';
import {
  IDENTITY_LOGOUT,
  IDENTITY_AUTHENTICATE,
  IDENTITY_SET_AUTHENTICATED,
  IDENTITY_SET_CLAIMS,
  IDENTITY_SET_PROFILE,
  IDENTITY_CHANGE_ROLE,
  IDENTITY_UPDATE_SIGNATURE,
} from '../types';

const initialState = {
  loggedOut: false,
  authenticated: false,
  claims: undefined,
  user: undefined,
  memberships: [],
  membership: undefined,
  organizationType: undefined,
};

const IdentityReducer = (state = initialState, action) => {
  const manager = new StorageManager();

  switch (action.type) {
    case IDENTITY_AUTHENTICATE:
      return { ...state, authenticated: true, claims: action.data };

    case IDENTITY_LOGOUT:
      return { ...initialState, loggedOut: true };

    case IDENTITY_SET_AUTHENTICATED:
      return { ...state, authenticated: action.data };

    case IDENTITY_SET_CLAIMS:
      return { ...state, claims: action.data };

    case IDENTITY_SET_PROFILE:
      const storedMembership = manager.getActiveMembership();
      const activeMembership = action?.data?.memberships?.length
        ? action?.data?.memberships?.find(m => m?.roleId === storedMembership?.roleId && m?.organization?.id === storedMembership?.organization?.id) ||
          action?.data?.memberships[0]
        : undefined;
      manager.setActiveMembershipIfNonePresent(activeMembership, action.data?.memberships || []);

      return {
        ...state,
        authenticated: true,
        user: action.data?.user,
        memberships: action.data?.memberships || [],
        membership: activeMembership,
        organizationType: activeMembership?.roleId?.split('-')[0],
      };

    case IDENTITY_CHANGE_ROLE:
      manager.setActiveMembership(action.data);
      return { ...state, membership: action.data, organizationType: action.data?.roleId?.split('-')[0] };

    case IDENTITY_UPDATE_SIGNATURE:
      return { ...state, user: { ...state.user, signatureSettings: action.data } };

    default:
      return state;
  }
};

export default IdentityReducer;
