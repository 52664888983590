import React, { useState } from 'react';
import moment from 'moment';

import Page from '../../../components/page';
import Table from '../../../components/table';
import Avatar from '../../../components/avatar';
import { capitalize } from '../../../utilities/common';
import { downloadStringAsFile } from '../../../utilities/files';
import useTable from '../../../hooks/use-table';
import { getInvoices, getCsvData } from '../../../apis/client/invoice';
import toaster from '../../../components/toaster';
import { DateFormat } from '../../../models/enumerations/date-format';

import InvoiceDetails from './details';
import InvoiceFilters from './filters';

const Invoices = () => {
  const [dialog, setDialog] = useState({ open: false });

  const defaultFilters = {
    unassigned: false,
    currencyId: undefined,
    classification: undefined,
    periodType: 'weekly',
    range: [moment().startOf('isoWeek').add(-6, 'months').format(DateFormat.DEFAULT), moment().endOf('isoWeek').format(DateFormat.DEFAULT)],
  };
  const { items, loading, filters, initialFilters, pageCount, page, reload, setFilters, resetFilters, goToPage } = useTable(
    getInvoices,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const renderPeriod = d =>
    d?.periodType === 'weekly'
      ? `Week ${moment(d?.period).format('Do MMMM')} - ${moment(d?.period).add(6, 'days').format('Do MMMM YYYY')}`
      : `${moment(d?.period).format('MMMM YYYY')}`;

  const onExport = data => {
    void toaster.export({
      progress: {
        title: 'Exporting invoice details',
      },
      promise: () => getCsvData(data?.invoiceId),
      onSuccess: csv => downloadStringAsFile(csv, `Invoice_${data?.invoiceId}_${renderPeriod(data).split(' ').join('_')}_${data?.currency?.id}`, 'csv'),
    });
  };

  return (
    <Page title="Invoices" path={[{ name: 'Invoices', to: '/invoices' }]}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters setFilters={setFilters} resetFilters={resetFilters} initialFilters={initialFilters} filters={filters} content={<InvoiceFilters />} />
        <Table.Columns.Default
          name="Client"
          showOnMobile
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} abbreviate name={d?.client?.name} />}
        />
        <Table.Columns.Default showOnMobile render={d => d?.client?.name} />
        <Table.Columns.Default name="Period" width="3fr" showOnMobile render={d => renderPeriod(d)} />
        <Table.Columns.Default name="Type" render={d => capitalize(d?.periodType)} />
        <Table.Columns.Default name="Date" showOnMobile render={d => moment(d?.date).format('MM/DD/YYYY')} />
        <Table.Columns.TwoLines
          name="Total"
          lines={[d => `${d?.currency?.symbol}${Number.parseFloat(`${d?.total || 0}`).toFixed(2)}`, d => d?.currency?.name]}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', onClick: d => setDialog({ open: true, invoiceId: d?.invoiceId }) },
            { divider: true },
            { name: 'Export', onClick: d => onExport(d) },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <InvoiceDetails
        open={!!dialog?.open}
        invoice={items?.find?.(i => i?.invoiceId === dialog?.invoiceId)}
        onExport={() => onExport(items?.find?.(i => i?.invoiceId === dialog?.invoiceId))}
        onClose={() => setDialog({ open: false })}
        reloadList={reload}
      />
    </Page>
  );
};

export default Invoices;
