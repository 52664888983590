export const CompensationFrequency = {
  Weekly: 'weekly',
  Monthly: 'monthly',
};

export const CompensationFrequencyOptions = [
  {
    label: 'Weekly',
    value: CompensationFrequency.Weekly,
  },
  {
    label: 'Monthly',
    value: CompensationFrequency.Monthly,
  },
];
