import React from 'react';

import Page from '../../../../components/page';
import Tabs from '../../../../components/tabs';

import useApi from '../../../../hooks/use-api';

import { getEngagements } from '../../../../apis/supplier/engagements';
import EngagementTab from './tab';
import { EngagementStatusType } from '../../../../domain/engagement-status-type';

const List = () => {
  const { loading, response } = useApi(getEngagements);

  const buckets = {
    active: response?.filter?.(e => e?.status === EngagementStatusType.Active),
    upcoming: response?.filter?.(e => e?.status === EngagementStatusType.Pending),
    complete: response?.filter?.(e => e?.status === EngagementStatusType.Complete),
  };

  return (
    <Page title="Engagements" path={[{ name: 'Engagements', to: '/engagements' }]} scroll={false}>
      <Tabs.Container>
        <Tabs.Item title="Active" props={{ type: 'active', loading, engagements: buckets.active }} component={EngagementTab} />
        <Tabs.Item title="Upcoming" props={{ type: 'upcoming', loading, engagements: buckets.upcoming }} component={EngagementTab} />
        <Tabs.Item title="Complete" props={{ type: 'complete', loading, engagements: buckets.complete }} component={EngagementTab} />
      </Tabs.Container>
    </Page>
  );
};

export default List;
