import React from 'react';
import classNames from 'classnames';

import classes from './boxes.module.scss';

const StatusLabel = ({ status }) => {
  const { className, label } = getStatusDisplay(status);
  return <div className={classNames(classes.statusLabel, className)}>{label}</div>;
};

const getStatusDisplay = status => {
  switch (status) {
    case 'pending':
      return { className: classes.pending, label: 'Review Pending' };

    case 'in-review':
      return { className: classes.inReview, label: 'In Review' };

    case 'approved':
      return { className: classes.approved, label: 'Approved' };

    case 'disputed':
      return { className: classes.disputed, label: 'Disputed' };

    case 'empty':
      return { className: classes.empty, label: 'Empty' };

    default:
      return { className: classes.empty, label: 'N/A' };
  }
};

export default StatusLabel;
