import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import cn from 'classnames';
import { Button, Drawer } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import useDocuments from '../../state/use-documents';
import Spinner from '../../../../spinner';
import useApi from '../../../../../hooks/use-api';
import { previewTemplate, validateTemplate } from '../../../../../apis/templates';
import { getContextTypeLabel } from '../../helpers/get-context-type-label';
import { formatMacro } from '../../helpers/macro-item-factory';

import classes from './preview.module.scss';
import { renderDocStream } from '../../helpers/render-doc-stream';

const Preview = () => {
  const { stream, template, preview, wizard, togglePreview } = useDocuments();
  const ref = React.useRef(null);
  const previewTemplateApi = useApi(
    async () => {
      const templatePreview = {
        ...template,
        metadata: template.metadata.map(macro => formatMacro(macro, true)),
      };
      await validateTemplate(templatePreview);
      return previewTemplate(templatePreview);
    },
    { skip: true },
  );

  useEffect(() => {
    async function load() {
      if (!preview) {
        return;
      }

      const html = await previewTemplateApi.request(template);

      if (ref?.current) {
        renderDocStream({ response: html, contentRef: ref, ratio: 1, fromScratch: stream.fromScratch });
      }
    }

    void load();

    return () => {
      if (ref.current) {
        ref.current.innerHTML = '';
      }
    };
  }, [preview]);

  function getContextTypeName() {
    const [documentType] = template?.description?.split(', ') || [];
    return documentType || getContextTypeLabel(template?.contextTypeName);
  }

  function getWizardReferenceName() {
    const [, wizardName, organizationName, scope] = template?.description?.split(', ') || [];
    const name = wizardName || wizard?.title;
    return organizationName ? `${name} ${organizationName} (${scope})` : name;
  }

  const loading = previewTemplateApi.loading;
  return (
    <Drawer
      position="right"
      usePortal={false}
      size="100%"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={preview}
      className={classes.drawer}
      onClose={togglePreview}
    >
      <div className={classes.header}>
        <div className={classes.document}>
          <div className={classes.name}>
            {template?.name}
            {template?.status !== 1 && <span className={classes.draft}> (draft)</span>}
          </div>
          <div className={classes.info}>
            <div className={classes.type}>{getContextTypeName()}</div>
            {template?.objectReferences && (
              <div className={classes.reference}>
                <Link to={`/settings/wizards/${template.objectReferences}`}>{getWizardReferenceName()}</Link>
              </div>
            )}
          </div>
        </div>
        <div>
          <Button icon="cross" minimal onClick={togglePreview} />
        </div>
      </div>

      <div className={classes.preview}>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.document}>
              {loading && <Spinner blend global={false} />}
              <div className={cn(stream.fromScratch && classes.fromScratchPadding)} ref={ref} />
            </div>
          </div>
        </SimpleBar>
      </div>
    </Drawer>
  );
};

export default Preview;
