import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';

import useApi from '../../../../../../../../hooks/use-api';
import { updateEngagementHiringManager } from '../../../../../../../../apis/platform/engagements';
import { getMembers } from '../../../../../../../../apis/members';

import { getFullName } from '../../../../../../../../utilities/user';

const HiringManagerForm = ({ isOpen = false, onClose, onSave, engagement }) => {
  const getMembersApi = useApi(getMembers, { skip: true });
  const updateApi = useApi(updateEngagementHiringManager, { skip: true });

  const submit = async ({ managerId }) => {
    await updateApi.request({ engagementId: engagement.id, managerId });
    onSave();
  };

  return (
    <Dialog title="Hiring manager update" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          managerId: engagement?.managerId,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          managerId: Yup.string().required('Hiring manager is required'),
        })}
        onSubmit={submit}
      >
        <Fields.Select
          label="Hiring manager"
          name="managerId"
          remoteOptions={{
            loading: getMembersApi.loading,
            request: (query = '') => getMembersApi.request({ filters: { organizationId: engagement?.clientId, includeParentMembers: true, query } }),
            mapper: result => result?.data?.map(m => ({ label: getFullName(m.user), value: m.id })),
            filter: 'remote',
          }}
        />
        <Button type="submit" fill outlined text="Save" disabled={updateApi.loading} loading={updateApi.loading} />
      </Form>
    </Dialog>
  );
};

export default HiringManagerForm;
