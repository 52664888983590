export const GenderType = {
  Woman: 1000,
  Man: 2000,
  Transgender: 3000,
  NonBinary: 4000,
  PreferNotToRespond: 5000,
};

export const GenderOptions = [
  { value: GenderType.Woman, label: 'Woman' },
  { value: GenderType.Man, label: 'Man' },
  { value: GenderType.Transgender, label: 'Transgender' },
  { value: GenderType.NonBinary, label: 'Non binary / Non conforming' },
  { value: GenderType.PreferNotToRespond, label: 'Prefer not to respond' },
];

const GenderLabels = GenderOptions.reduce((mapper, status) => {
  mapper[status.value] = status.label;
  return mapper;
}, {});

export const genderStatusToLabel = status => GenderLabels[status] || '-';
