import classNames from 'classnames';
import React from 'react';

import classes from './tabs.module.scss';

const ActionItem = ({ item }) => {
  const { title, onClick } = item?.props || {};
  return (
    <div className={classNames(classes.link)} onClick={onClick}>
      {title}
    </div>
  );
};

ActionItem.displayName = 'Tabs.ActionItem';

export default ActionItem;
