import React from 'react';
import { Field } from 'formik';
import { Button, FormGroup, InputGroup, Tooltip } from '@blueprintjs/core';

const SearchBar = ({ name, type = 'text', label, placeholder, info, className, extra, ...rest }) => {
  return (
    <Field name={name} {...rest}>
      {({ field, form }) => {
        const errorPresent = !!form && !!form.errors && !!form.errors[name];
        return (
          <FormGroup label={label} labelInfo={info} className={className}>
            <InputGroup
              {...field}
              {...extra}
              {...rest}
              onChange={e => field.onChange(e)}
              name={name}
              type={type}
              placeholder={placeholder}
              fill={true}
              rightElement={
                !!errorPresent ? (
                  <Tooltip intent="danger" placement="right" content={form.errors[name]}>
                    <Button icon="warning-sign" minimal />
                  </Tooltip>
                ) : !!extra && !!extra.rightElement ? (
                  extra.rightElement
                ) : null
              }
            />
          </FormGroup>
        );
      }}
    </Field>
  );
};

export default SearchBar;
