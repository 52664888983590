import React from 'react';

import Fields from '../../../../../../../fields';
import FormGroupHeader from './form-group-header';

import classes from '../properties.module.scss';

const SubstringConfigForm = ({ disabled }) => {
  return (
    <div className={classes.group}>
      <FormGroupHeader title="Substring settings">
        <div>
          <strong>Indices</strong>, enter comma separated indices of characters that need to be included in result value. (ie. <strong>1,2,3,4</strong>)
        </div>
      </FormGroupHeader>
      <div className={classes.line}>
        <Fields.Text name="config.indices" label="Indices" placeholder="1,2,3,4" submitOnChange={true} showError={false} disabled={disabled} />
      </div>
    </div>
  );
};

export default SubstringConfigForm;
