import React, { useEffect, useMemo, useReducer } from 'react';

import { getTemplate, getEmailDescriptors } from '../../../../apis/templates';

import EmailConfiguratorContext from './email-configurator-context';
import emailConfiguratorReducer from './email-configurator-reducer';
import { getEmailTemplateSpecificMacros } from '../helpers/get-email-template-specific-macros';
import templateFactory from '../helpers/template-factory';

const EmailConfiguratorProvider = ({ children, id }) => {
  const [state, dispatch] = useReducer(emailConfiguratorReducer, {
    isLoading: true,
    isReady: false,
    selection: undefined,
    hovered: undefined,
    content: [],
    id,
  });

  useEffect(() => {
    const promises = [getEmailDescriptors(id), getTemplate(id)];
    Promise.all(promises).then(([descriptors, _template]) => {
      const template = { ..._template, metadata: templateFactory.createOrUpdateMetadata(_template.metadata) };
      const { content } = template.metadata.render.body;
      const { availableMacros, iterators } = getEmailTemplateSpecificMacros(descriptors, template);
      dispatch({
        type: 'EMAIL_CONFIGURATOR_LOAD',
        data: {
          availableMacros,
          iterators,
          template,
          content,
        },
      });
    });
  }, [id, dispatch]);

  const value = useMemo(() => [state, dispatch], [state, dispatch]);

  return <EmailConfiguratorContext.Provider value={value}>{children}</EmailConfiguratorContext.Provider>;
};

export default EmailConfiguratorProvider;
