import React from 'react';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';

import { Roles } from '../../../../../../../../models/enumerations/roles';

import useApi from '../../../../../../../../hooks/use-api';
import { inviteMember } from '../../../../../../../../apis/members';

const InviteMemberForm = ({ organizationId, onComplete }) => {
  const inviteApi = useApi(inviteMember, { skip: true });

  const submit = async values => {
    await inviteApi.request(values);
    onComplete();
  };

  const getRoleOptions = () => [
    { label: 'Supplier Manager', value: Roles.Supplier.Manager },
    { label: 'Supplier Subordinate', value: Roles.Supplier.Subordinate },
    { label: 'Supplier Witness', value: Roles.Supplier.Witness },
  ];

  return (
    <Form
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        organizationId,
        roleId: '',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        email: Yup.string().email('Not valid email address.').required('Email is required.'),
        roleId: Yup.string().required('Role is required.'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      <Fields.Text label="First Name" name="firstName" />
      <Fields.Text label="Last Name" name="lastName" />
      <Fields.Text label="Email" name="email" />
      <Fields.Select label="Role" name="roleId" options={getRoleOptions()} />
      <Button type="submit" outlined fill text="Submit" disabled={inviteApi.loading} loading={inviteApi.loading} />
    </Form>
  );
};

export default InviteMemberForm;
