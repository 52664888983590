import React, { useEffect } from 'react';

import { BillableItemType } from '../../../../domain/billable-item-type';

import useApi from '../../../../hooks/use-api';
import { getEngagementFees } from '../../../../apis/platform/invoice';

import classes from '../invoices.module.scss';

const getTargetType = type => {
  switch (type) {
    case BillableItemType.Regular:
    case BillableItemType.Overtime:
    case BillableItemType.DoubleTime:
      return 'hours';

    case BillableItemType.Salary:
    case BillableItemType.SalaryOvertime:
    case BillableItemType.SalaryDoubleTime:
      return 'salaries';

    case BillableItemType.Deliverable:
      return 'deliverables';

    case BillableItemType.Expense:
      return 'expenses';

    default:
      return null;
  }
};

const DisplayFee = ({ setFieldValue, clientId, engagementId, amount = 0, type, currency }) => {
  const getEngagementFeesApi = useApi(() => getEngagementFees({ clientId, engagementId }), { skip: true });

  const fee = getFee(getEngagementFeesApi.response, type);

  function getFee(response, type) {
    if (type && response) {
      const fees = JSON.parse(response?.fees);
      const fee = fees?.find(f => f.targetType === getTargetType(type));
      return fee ? { ...fee, amount: Number(fee?.amount) } : { amount: 0, feeType: 'percentage' };
    }

    return { amount: 0, feeType: 'percentage' };
  }

  function getAmountFeeValue(fee) {
    if (fee?.feeType === 'percentage') {
      return amount - amount / (1 + fee?.amount / 100);
    }

    return fee?.amount;
  }

  function getFeeText() {
    const feeAmount = getAmountFeeValue(fee);
    if (fee?.feeType === 'percentage') {
      return (
        <>
          <div>{`${fee?.amount}%`}</div>
          <div>{`(${currency?.symbol}${feeAmount?.toFixed(2)})`}</div>
        </>
      );
    }

    return <div>{`${currency?.symbol}${feeAmount}`}</div>;
  }

  useEffect(() => {
    async function load() {
      const response = await getEngagementFeesApi.request();
      const fee = getFee(response, type);
      const feeAmount = getAmountFeeValue(fee);
      setFieldValue('fee', feeAmount);
    }

    if (clientId) {
      void load();
    }
  }, [clientId]);

  useEffect(() => {
    const fee = getFee(getEngagementFeesApi.response, type);
    const feeAmount = getAmountFeeValue(fee);
    setFieldValue('fee', feeAmount);
  }, [type]);

  if (!fee) {
    return null;
  }

  return (
    <div className={classes.feeBox}>
      <div className={classes.feeBoxTitle}>Fee:</div>
      <div className={classes.feeBoxContent}>{getFeeText()}</div>
    </div>
  );
};

export default DisplayFee;
