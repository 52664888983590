import React from 'react';
import sortBy from 'lodash/sortBy';

import Table from '../../../../components/table';

import { getColor } from '../../../../utilities/common';
import { abbreviateClassification } from '../../../../domain/classification-type';

import classes from './details.module.scss';

const InvoiceSupplier = ({ supplier, currency }) => {
  return (
    <div className={classes.item}>
      <div className={classes.bar}>
        <div className={classes.info}>
          <div className={classes.name}>{supplier?.supplierName}</div>
          <div className={classes.engagement}>
            <div className={classes.client} style={{ color: getColor(supplier?.clientName) }}>
              {supplier?.clientName}
            </div>
            <div>{supplier?.engagement?.title}</div>
            <div>{`(${abbreviateClassification(supplier?.engagement?.classification?.result)})`}</div>
          </div>
        </div>
      </div>
      <div className={classes.table}>
        <Table.Container data={sortBy(supplier?.items, ['type']).reverse()}>
          <Table.Columns.Default name="Type" showOnMobile render={d => mapItemTypes(d?.type)} />
          <Table.Columns.Default name="Description" width="2fr" render={d => d?.description} />
          <Table.Columns.Default name="Hours" render={renderHours} />
          <Table.Columns.Default
            name="Fee"
            width="100px"
            alt={d => d?.fee}
            render={d => `${currency?.symbol}${Number.parseFloat(`${d?.fee || 0}`).toFixed(2)}`}
          />
          <Table.Columns.Default
            name="Amount"
            width="100px"
            showOnMobile
            alt={d => d?.amount}
            render={d => `${currency?.symbol}${Number.parseFloat(`${d?.amount || 0}`).toFixed(2)}`}
          />
        </Table.Container>
      </div>
      <div className={classes.subTotal}>
        <div className={classes.label}>Total</div>
        <div className={classes.value} title={supplier?.total}>{`${currency?.symbol}${Number.parseFloat(`${supplier?.total || 0}`).toFixed(2)}`}</div>
      </div>
    </div>
  );
};

export default InvoiceSupplier;

const renderHours = d => ['regular-hours', 'over-time-hours', 'double-time-hours'].includes(d?.type) && `${Number(d?.quantity).toFixed(2)}`;

const mapItemTypes = type => {
  switch (type) {
    case 'regular-hours':
    case 'over-time-hours':
    case 'double-time-hours':
      return 'Shifts';

    case 'salary':
    case 'salary-over-time':
    case 'salary-double-time':
      return 'Salary';

    case 'expense':
      return 'Expense';

    case 'deliverable':
      return 'Deliverable';

    case 'benefits-charge':
      return 'Benefits Charge';

    case 'background-check-charge':
      return 'Background Check Charge';

    case 'miscellaneous-charge':
      return 'Misc. Charge';

    case 'classification-fee':
      return 'Classification Fee';

    default:
      return 'Other';
  }
};
