import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';

export const filterSuppliers = async ({ filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier${toQueryString(query)}`);
  return response?.data;
};

export const getSupplier = async supplierId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier/${supplierId}`);
  return response?.data;
};

export const updateSupplier = async supplier => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/supplier/${supplier.id}`, supplier);
  return response?.data;
};

export const setSupplierAddress = async ({ supplierId, placeId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/supplier/${supplierId}/address`, { placeId });
  return response?.data;
};

export const getSupplierEngagements = async ({ supplierId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier/${supplierId}/engagements`);
  return response?.data;
};

export const uploadSupplierLogo = async ({ supplierId, logo }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('logo', logo);
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/${supplierId}/logo`, data, { onUploadProgress });
  return response?.data;
};

export const uploadSupplierIcon = async ({ supplierId, icon }, onUploadProgress = () => null) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('icon', icon);
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/${supplierId}/icon`, data, { onUploadProgress });
  return response?.data;
};

export const getSupplierExternalIdentities = async ({ organizationId, filters = {}, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier/${organizationId}/external_identity${toQueryString(query)}`);
  return response?.data;
};

export const createSupplierExternalIdentity = async ({ providerType, providerName, organizationId, externalOrganizationId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const payload = { providerType, providerName, organizationId, externalOrganizationId };
  const response = await Clients.Api.post(`ui/${organizationType}s/supplier/external_identity`, payload);
  return response?.data;
};

export const updateSupplierExternalIdentity = async ({ id, providerType, providerName, organizationId, externalOrganizationId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const payload = { providerType, providerName, organizationId, externalOrganizationId };
  const response = await Clients.Api.put(`ui/${organizationType}s/supplier/external_identity/${id}`, payload);
  return response?.data;
};

export const removeSupplierExternalIdentity = async ({ id }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/supplier/external_identity/${id}`);
  return response?.data;
};

export const getSupplierAddress = async ({ supplierId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/supplier/${supplierId}/address`);
  return response?.data;
};
