import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';
import cn from 'classnames';

import Dialog from '../../../../../components/dialog';
import Form from '../../../../../components/form';
import FileDropzone from '../../../../fields/file/file-dropzone';

import { MimeTypes } from '../../../../../utilities/mime-types';

import { WizardContext } from '../../state';
import validateAndCastContent from './validate-and-cast-content';

import classes from './import-dialog.module.scss';

const ImportDialog = ({ isOpen, onClose }) => {
  const { template, descriptor, setTemplate } = useContext(WizardContext);

  const submit = values => {
    setTemplate(values.template);
    onClose();
  };

  const onUploaded = (file, form) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        const content = reader.result;
        const result = validateAndCastContent(template, content, descriptor);
        if (result.error) {
          return reject(result.error);
        }
        form.setValues({
          template: result.template,
          excluded: result.excluded,
        });
        resolve();
      };
      reader.readAsText(file);
    });
  };

  const loading = false;
  return (
    <Dialog title="Import template from file" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          template: undefined,
          excluded: undefined,
        }}
        onSubmit={submit}
      >
        {form => (
          <React.Fragment>
            <FileDropzone mimeTypes={MimeTypes.JSON} onDrop={file => onUploaded(file, form)} />
            <div className={classes.container}>
              {form.values.template?.id && (
                <div className={cn(classes.excluded, form.values.excluded?.length && classes.error)}>
                  Excluded Fields: {form.values.excluded?.join(', ') || 'None'}
                </div>
              )}
              {form.values.template?.id && <Button type="submit" fill outlined text="Apply changes" disabled={loading} loading={loading} />}
            </div>
          </React.Fragment>
        )}
      </Form>
    </Dialog>
  );
};

export default ImportDialog;
