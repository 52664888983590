import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import Audit from '../../../../components/audit';
import Box from '../../../../components/box';
import SignatureSettingsDialog from '../../../../components/fields/signature/signature-settings-dialog';

import classes from '../profile.module.scss';

const SignatureSettings = ({ settings }) => {
  const [dialog, setDialog] = useState({ open: false, resolve: null });

  const element = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (element && element.current && settings?.svg) {
        element.current.innerHTML = settings.svg;
      }
    }, 0);
  }, [settings?.svg]);

  const handleClose = () => setDialog({ open: false });

  const handleOpen = () => setDialog({ open: true, resolve: handleClose });

  return (
    <div className={classes.tab}>
      <Box
        title="Signature Settings"
        actions={
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update" onClick={handleOpen} />
            <Audit id={settings?.id} type="signatureSettings" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
      >
        <Box.ItemContainer>
          <div className={classes.signatureBlock}>
            <div className={classNames(classes.renderer, classes.preview)}>
              <div className={classNames(classes.svg, !settings?.svg && classes.empty)} ref={element} />
            </div>
          </div>
        </Box.ItemContainer>
      </Box>
      <SignatureSettingsDialog dialog={dialog} handleClose={handleClose} />
    </div>
  );
};

const mapStateToProps = state => ({
  settings: state.identity.user.signatureSettings,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignatureSettings);
