import React from 'react';

import QuickTimekeepingProvider from './state';
import Week from './components/week';
// import Month from './components/month';

import classes from './quick.module.scss';

const QuickTimekeeping = () => {
  return (
    <QuickTimekeepingProvider>
      <div className={classes.time}>
        <Week />
      </div>
    </QuickTimekeepingProvider>
  );
};

export default QuickTimekeeping;
