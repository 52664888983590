import React from 'react';
import { Button, Popover } from '@blueprintjs/core';

import { FormGroup } from '../../../form';
import { EXPRESSION_UNITS } from '../constants';
import { displayTime } from '../utilities';
import TableMenu from './table-menu';

import classes from '../cron-expression.module.scss';

const HourConfigItem = ({ hour, minute, onChange }) => {
  const text = displayTime(hour, minute);

  const handleChangeHour = hour => {
    onChange({ hour, minute: minute || ['0'] });
  };

  const handleChangeMinute = minute => {
    onChange({ minute, hour: hour || ['0'] });
  };

  const content = (
    <div className={classes.popoverContent}>
      <div>
        <TableMenu
          value={hour}
          selectAllBtnText={`Every ${EXPRESSION_UNITS.HOUR.name}`}
          min={EXPRESSION_UNITS.HOUR.min}
          max={EXPRESSION_UNITS.HOUR.max}
          itemsPerRow={6}
          onChange={handleChangeHour}
        />
      </div>
      <div className={classes.divider} />
      <div>
        <TableMenu
          value={minute}
          selectAllBtnText={`Every ${EXPRESSION_UNITS.MINUTE.name}`}
          min={EXPRESSION_UNITS.MINUTE.min}
          max={EXPRESSION_UNITS.MINUTE.max}
          itemsPerRow={10}
          onChange={handleChangeMinute}
        />
      </div>
    </div>
  );

  return (
    <FormGroup classes={{ wrapper: classes.configItemFormGroup }} label="Specific time (choose one or many)">
      <Popover fill placement="bottom" content={content}>
        <Button text={text} alignText="left" rightIcon="caret-down" minimal />
      </Popover>
    </FormGroup>
  );
};

export default HourConfigItem;
