import React from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';

import WithConfirmation from '../../../../../../hooks/with-confirmation';
import { ElementTypes, fieldOptions } from '../../../enumerations/field-definitions';
import FieldMenuItem from '../field-menu-item/field-menu-item';
import { createContentItem } from '../../../helpers/create-content-item';
import classes from './block.module.scss';

const BlockMenu = ({ block, path, dispatch, setIsExpanded }) => {
  const addContent = field => {
    dispatch({
      type: 'EMAIL_CONFIGURATOR_ADD_CONTENT',
      data: {
        path: [...path],
        contentItem: createContentItem(field),
      },
    });
    setIsExpanded(true);
  };

  const removeContent = () => {
    dispatch({
      type: 'EMAIL_CONFIGURATOR_REMOVE_CONTENT',
      data: { path },
    });
  };

  const selectContent = () => {
    dispatch({
      type: 'EMAIL_CONFIGURATOR_SET_SELECTION',
      data: { path, contentItem: block },
    });
  };

  return (
    <Popover
      content={
        <Menu>
          <MenuItem text="Properties" icon="settings" onClick={selectContent} />
          <FieldMenuItem
            text="Add field"
            icon="plus"
            items={fieldOptions}
            onClick={addContent}
            isDisabled={t =>
              (block.elementType === ElementTypes.TABLE && t.name !== ElementTypes.COLUMN) ||
              (block.elementType !== ElementTypes.TABLE && t.name === ElementTypes.COLUMN)
            }
          />
          <MenuDivider />
          <WithConfirmation
            messages={{
              question: `Are you sure you want to remove "${block?.title}" block ? All blocks and fields in this group will also be removed, as well as any visibility conditions that are based on these fields.`,
            }}
            icon={true}
          >
            <MenuItem text="Remove" icon="cross" onClick={removeContent} shouldDismissPopover={false} />
          </WithConfirmation>
        </Menu>
      }
    >
      <Button className={classes.actions} minimal icon="more" />
    </Popover>
  );
};

export default BlockMenu;
