import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../../../components/dialog';
import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';
import useApi from '../../../../../../../../hooks/use-api';
import { setUserAddress } from '../../../../../../../../apis/profile';
import { setSupplierAddress } from '../../../../../../../../apis/platform/suppliers';

async function setOwnerAddress(isOwnerUser, id, placeId) {
  return isOwnerUser ? setUserAddress(id, placeId) : setSupplierAddress({ supplierId: id, placeId });
}

function extractAddress(owner) {
  if (owner?.personalDetails?.address?.json) {
    return JSON.parse(owner.personalDetails.address?.json);
  }

  if (owner?.address?.json) {
    return JSON.parse(owner?.address?.json);
  }

  return null;
}

const AddressForm = ({ isOpen = false, owner, onClose, onSave }) => {
  const setOwnerAddressApi = useApi(setOwnerAddress, { skip: true });

  const submit = async (values, { setFieldError }) => {
    const { address } = values;
    if (!address?.place_id) {
      setFieldError('address', 'Unable to resolve requested location.');
      return;
    }
    const isOwnerUser = !!owner?.personalDetails;
    await setOwnerAddressApi.request(isOwnerUser, owner.id, address.place_id);
    onSave(isOwnerUser);
  };

  const loading = setOwnerAddressApi.loading;
  const address = extractAddress(owner);
  return (
    <Dialog title="Address" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ address }} onSubmit={submit}>
        <Fields.Address fill name="address" label="Address" precision="address" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default AddressForm;
