import React from 'react';
import { Field as FormikField, getIn } from 'formik';

import FormGroup from './form-group';

const Field = ({ children, classes, label, description, name, validate, outline, showError = true, minimal = false, ...rest }) => (
  <FormikField name={name} validate={validate} {...rest}>
    {({ field, form }) => (
      <FormGroup
        classes={{ wrapper: classes?.wrapper, formGroup: classes?.formGroup, content: classes?.content, fieldset: classes?.fieldset }}
        name={name}
        label={label}
        description={description}
        outline={outline}
        showError={showError}
        minimal={minimal}
        error={!!form?.submitCount && getIn(form.errors, name)}
      >
        {children({ field, form })}
      </FormGroup>
    )}
  </FormikField>
);

export default Field;
