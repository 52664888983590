import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, ButtonGroup, Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Form from '../../../../../../../../form';
import Fields from '../../../../../../../../fields';
import WithConfirmation from '../../../../../../../../../hooks/with-confirmation';

import classes from '../background-check.module.scss';

const CheckRBackgroundCheckProvider = ({ configurationType, scopeId, data, onSubmit, loading }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined, index: undefined });
  const closeDialog = () => setDialog({ open: false, type: undefined, index: undefined });

  const addOption = (form, { name, slug, description }) => {
    const data = form?.values?.data;
    const options = data?.options || [];

    if (options.find(o => o?.slug === slug)) return;

    const updates = [...options, { name, slug, description }];
    form.setFieldValue('data', { ...data, options: updates });
    closeDialog();
  };
  const updateOption = (index, form, { name, slug, description }) => {
    const data = form?.values?.data;
    const options = data?.options || [];

    const updates = options?.map?.((option, i) => (i === index ? { ...option, name, slug, description } : option));
    form.setFieldValue('data', { ...data, options: updates });
    closeDialog();
  };
  const removeOption = (form, { slug }) => {
    const data = form?.values?.data;
    const options = data?.options || [];

    if (!options.find(o => o?.slug === slug)) return;

    const updates = options?.filter?.(o => o?.slug !== slug);
    form.setFieldValue('data', { ...data, options: updates });
    closeDialog();
  };

  const urls = [
    { label: 'Staging', value: 'https://api.checkr-staging.com/v1/' },
    { label: 'Production', value: 'https://api.checkr.com/v1/' },
  ];

  const packages = [
    {
      name: 'Basic',
      slug: 'pro_criminal',
      description: 'County Criminal Search (Last 7 Years), National Criminal Search (Standard), Sex Offender Search, Ssn Trace, Global Watchlist Search ',
    },
    {
      name: 'Premium',
      slug: 'premium',
      description:
        'County Criminal Search (Last 7 Years), Education Verification (Out-of-country history), Employment Verification (Out-of-country history), National Criminal Search (Standard), Sex Offender Search, Ssn Trace, Global Watchlist Search',
    },
    {
      name: 'Premium Plus',
      slug: 'premium_plus_drug',
      description:
        'County Criminal Search (Last 7 Years), Drug Screening (DS 9 PANEL NO THC), Education Verification, Employment Verification, National Criminal Search (Standard), Sex Offender Search, Ssn Trace, Global Watchlist Search',
    },
    { name: 'Education', slug: 'education_highest_degree_obtained_verification', description: 'Education Verification' },
    { name: 'Work History', slug: '7_year_work_history_verification', description: 'Employment Verification' },
    { name: 'Drug Screening', slug: 'drug_screening', description: 'Drug Screening (DS 9 PANEL NO THC)' },
    {
      name: 'International Criminal',
      slug: 'international_criminal',
      description: 'International Criminal (Or Adverse Media), International Global Watchlist Search, International Identity Document Validation',
    },
  ];

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        configurationType,
        scopeId,
        data: {
          providerName: 'Checkr',
          configuration: { baseUrl: data?.configuration?.baseUrl || urls[0].value, clientSecret: data?.configuration?.clientSecret || '' },
          options: !!data?.options?.length ? data?.options : packages,
        },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.string().required('Scope is required'),
        data: Yup.object().shape({
          providerName: Yup.string().required('Provider is required'),
          configuration: Yup.object().shape({
            baseUrl: Yup.string().required('Base URL is required'),
            clientSecret: Yup.string().required('Client Secret is required'),
          }),
          options: Yup.array().min(1, 'At least one package option must be provided.'),
        }),
      })}
      enableReinitialize
    >
      {form => {
        return (
          <div className={classes.checkr}>
            <div className={classes.url}>
              <Fields.Text label="Base URL" description="Enter url or choose from options." name="data.configuration.baseUrl" />
              <Fields.Select label="URL" name="data.configuration.baseUrl" options={urls} />
            </div>
            <Fields.Text label="Client Secret" name="data.configuration.clientSecret" />
            <div className={classes.packages}>
              <SimpleBar className={classes.wrapper}>
                <div className={classes.options}>
                  {!!form?.errors?.data?.options && <div className={classes.optionsError}>{form?.errors?.data?.options}</div>}
                  {form?.values?.data?.options?.map?.((o, i) => (
                    <div className={classes.option} key={i}>
                      <div className={classes.info}>
                        <div className={classes.name}>{o?.name}</div>
                        <div className={classes.slug}>{o?.slug}</div>
                        <div className={classes.description}>{o?.description}</div>
                      </div>
                      <div className={classes.actions}>
                        <Popover
                          isOpen={dialog?.open && dialog?.type === 'edit' && dialog?.index === i}
                          content={<CheckOptionsForm onSubmit={v => updateOption(i, form, v)} values={o} onCancel={closeDialog} />}
                        >
                          <Button
                            type="button"
                            outlined
                            small
                            icon={<Icons.Edit2 size={16} strokeWidth={1.5} />}
                            title="Change package"
                            onClick={() => setDialog({ open: true, type: 'edit', index: i })}
                          />
                        </Popover>
                        <WithConfirmation messages={{ question: `Remove package (${o?.name}) ?`, confirmButton: 'Remove' }}>
                          <Button
                            type="button"
                            outlined
                            small
                            intent="danger"
                            icon={<Icons.X size={16} strokeWidth={1.5} />}
                            title="Remove package"
                            onClick={() => removeOption(form, o)}
                          />
                        </WithConfirmation>
                      </div>
                    </div>
                  ))}
                  <Popover
                    isOpen={dialog?.open && dialog?.type === 'add'}
                    content={<CheckOptionsForm onSubmit={v => addOption(form, v)} onCancel={closeDialog} />}
                  >
                    <Button outlined fill type="button" text="Add new package" disabled={loading} onClick={() => setDialog({ open: true, type: 'add' })} />
                  </Popover>
                </div>
              </SimpleBar>
            </div>
            <Button type="submit" text="Submit" loading={loading} outlined fill />
          </div>
        );
      }}
    </Form>
  );
};

const CheckOptionsForm = ({ onSubmit, loading, disabled, onCancel, values = {} }) => {
  return (
    <div className={classes.checkROptionsForm}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ name: '', slug: '', description: '', ...values }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required'),
          slug: Yup.string().required('Slug is required'),
          description: Yup.string().required('Description is required'),
        })}
        enableReinitialize
      >
        <Fields.Text label="Name" description="provider package name" name="name" />
        <Fields.Text label="Slug" description="snake-case package name" name="slug" />
        <Fields.Text label="Description" name="description" />
        <ButtonGroup fill>
          <Button outlined fill type="button" text="Cancel" loading={loading} disabled={disabled} onClick={onCancel} />
          <Button outlined fill type="submit" text="Submit" loading={loading} disabled={disabled} />
        </ButtonGroup>
      </Form>
    </div>
  );
};

export default CheckRBackgroundCheckProvider;
