import React from 'react';

import Box from '../../box';

import useApi from '../../../hooks/use-api';
import { getConfiguration } from '../../../apis/configuration';

const Metadata = ({ engagement }) => {
  const getConfigurationApi = useApi(() => getConfiguration(engagement?.clientId, 'ExternalMetadata|EngagementMetadata'));
  const metadata = React.useMemo(() => JSON.parse(engagement.metadata), [engagement.metadata]);
  const fields = React.useMemo(() => {
    if (getConfigurationApi.response?.[0]?.schema) {
      return JSON.parse(getConfigurationApi.response?.[0]?.schema);
    }
    return [];
  }, [getConfigurationApi.response]);

  return (
    <Box monochrome small expandible title="Metadata" loading={getConfigurationApi.loading}>
      {fields.map(field => (
        <Box.Item label={field.name} key={field.name}>
          {metadata[field.name]}
        </Box.Item>
      ))}
      {!getConfigurationApi.loading && !fields?.length && (
        <Box.TabularItem>
          <Box.ItemLabel fill>No records found.</Box.ItemLabel>
        </Box.TabularItem>
      )}
    </Box>
  );
};

export default Metadata;
