import AddressField from './address';
import BankAccountField from './bank-account/bank-account';
import ColorField from './color';
import CronExpressionField from './cron-expression';
import { DateField, DateRangeField, TimeField } from './date';
import DocumentField from './document';
import FileField from './file';
import { CheckboxField, CheckboxGroupField, RadioGroupField } from './group-input';
import MacroTextareaField from './macro-textarea';
import PhoneField from './phone';
import { MultiSelectField, SelectField } from './select';
import SignatureField from './signature';
import SliderField from './slider';
import TextField from './text';
import { TextareaField } from './textarea';
import TimezoneField from './timezone';
import RichTextField from './rich-text';
import CreatableField from './creatable';

const Fields = {
  Address: AddressField,
  BankAccount: BankAccountField,
  Checkbox: CheckboxField,
  CheckboxGroup: CheckboxGroupField,
  CronConfig: CronExpressionField,
  Document: DocumentField,
  File: FileField,
  RadioGroup: RadioGroupField,
  Select: SelectField,
  MultiSelect: MultiSelectField,
  CreatableField: CreatableField,
  Text: TextField,
  Textarea: TextareaField,
  Timezone: TimezoneField,
  Slider: SliderField,
  Color: ColorField,
  Date: DateField,
  DateRange: DateRangeField,
  Time: TimeField,
  Phone: PhoneField,
  Signature: SignatureField,
  MacroTextarea: MacroTextareaField,
  RichText: RichTextField,
};

export {
  AddressField,
  CheckboxField,
  CheckboxGroupField,
  CronExpressionField,
  DocumentField,
  FileField,
  RadioGroupField,
  SelectField,
  MultiSelectField,
  TextField,
  TextareaField,
  TimezoneField,
  SliderField,
  ColorField,
  DateField,
  DateRangeField,
  TimeField,
  PhoneField,
  SignatureField,
  MacroTextareaField,
  RichTextField,
  CreatableField,
};

export default Fields;
