import React, { useState } from 'react';
import moment from 'moment';
import { Button } from '@blueprintjs/core';

import Box from '../../../../../../components/box';
import EngagementTermination from '../../../../../../components/organizational/client/engagement-termination';

import { EngagementStatusType, engagementStatusToLabel } from '../../../../../../domain/engagement-status-type';

import useApi from '../../../../../../hooks/use-api';
import { getEngagementTermination } from '../../../../../../apis/client/engagements';

const EngagementTerminationStatus = ({ engagement, loading }) => {
  const [drawer, setDrawer] = useState({ open: false, type: undefined });
  const getEngagementTerminationApi = useApi(getEngagementTermination, { params: { engagementId: engagement?.id }, skip: !engagement?.id });

  return (
    <React.Fragment>
      <Box title="Engagement status" outlined loading={loading || getEngagementTerminationApi?.loading} monochrome>
        {!!getEngagementTerminationApi?.response ? (
          <React.Fragment>
            <Box.Item label="End date">{moment(getEngagementTerminationApi?.response?.endDate).format('MMMM Do, YYYY')}</Box.Item>
            <Box.Item label="Reason">{getEngagementTerminationApi?.response?.reason}</Box.Item>
            <Box.Item label="Notes">{getEngagementTerminationApi?.response?.notes}</Box.Item>
          </React.Fragment>
        ) : (
          <Box.Item label="Status">{engagementStatusToLabel(engagement?.status)}</Box.Item>
        )}
        {engagement?.status !== EngagementStatusType.Complete && !getEngagementTerminationApi?.response && (
          <Box.Item>
            <Button outlined intent="danger" text="Request termination" onClick={() => setDrawer({ open: true, type: 'termination' })} />
          </Box.Item>
        )}
      </Box>
      <EngagementTermination
        open={!!drawer.open && drawer.type === 'termination'}
        initialize={true}
        engagement={engagement}
        onClose={() => setDrawer({ open: false, type: undefined, data: undefined })}
        onComplete={() => {
          getEngagementTerminationApi.request({ engagementId: engagement?.id });
        }}
      />
    </React.Fragment>
  );
};

export default EngagementTerminationStatus;
