import React from 'react';

import { ObjectTypes, TypeDefinitions } from '../../../../../domain/types';

import useApi from '../../../../../../../../hooks/use-api';

import { getCountries } from '../../../../../../../../apis/lookups';
import Fields, { SelectField } from '../../../../../../../fields';
import SelectOptions from '../select-options';
import ParagraphProperties from './paragraph-properties';
import ResourceFiles from '../../../../../../../resource-files';
import TargetDocument from '../target-document';

import classes from '../../../properties.module.scss';

export const TypeSpecificProperties = ({ form }) => {
  const countriesApi = useApi(getCountries, { skip: form?.values?.type !== ObjectTypes.BankAccount });
  const values = form?.values;
  const validateDefaultValue = value => {
    if (values?.hidden === true && values.mandatory && values?.required && !value) {
      return 'Default value is required for hidden fields.';
    }
  };

  const isDefaultValueInvalid = values?.hidden === true && values?.mandatory && !values?.defaultValue && values?.required;

  switch (form?.values?.type) {
    case ObjectTypes.Text:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[
              TypeDefinitions.Text,
              TypeDefinitions.Number,
              TypeDefinitions.Select,
              TypeDefinitions.Phone,
              TypeDefinitions.Date,
              TypeDefinitions.Time,
              TypeDefinitions.Document,
              TypeDefinitions.Signature,
            ].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            clearable={false}
            submitOnChange={true}
          />
          <div className={classes.botPad15}>
            <Fields.Text name="defaultValue" label="Default value" submitOnChange={true} validate={validateDefaultValue} showError={false} />
            {isDefaultValueInvalid && <div className={classes.defaultValueRequiredMessage}>Default value is required for hidden fields.</div>}
          </div>
          <Fields.Select
            name="display"
            label="Display"
            options={[
              { label: 'Default', value: 'default' },
              { label: 'Textarea', value: 'textarea' },
            ]}
            submitOnChange={true}
            showError={false}
          />
        </div>
      );

    case ObjectTypes.Number:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[TypeDefinitions.Text, TypeDefinitions.Number].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            disabled={values.type === values.originalType}
            clearable={false}
            submitOnChange={true}
          />
          <Fields.Text type="number" name="defaultValue" label="Default value" submitOnChange={true} showError={false} />
          {isDefaultValueInvalid && <div className={classes.defaultValueRequiredMessage}>Default value is required for hidden fields.</div>}
        </div>
      );

    case ObjectTypes.Select:
      return (
        <React.Fragment>
          <div className={classes.group}>
            <SelectOptions form={form} />
          </div>
          <div className={classes.group}>
            <Fields.Select
              name="type"
              label="Type"
              options={[TypeDefinitions.Text, TypeDefinitions.Select].map(t => ({
                label: t.label,
                value: t.name,
              }))}
              disabled={values.type === values.originalType}
              clearable={false}
              submitOnChange={true}
            />
            <Fields.Select
              name="defaultValue"
              label="Default value"
              key={form?.values?.options}
              disabled={!form?.values?.options?.length}
              options={form?.values?.options || []}
              submitOnChange={true}
              showError={false}
            />
            {isDefaultValueInvalid && <div className={classes.defaultValueRequiredMessage}>Default value is required for hidden fields.</div>}
          </div>
          <div className={classes.group}>
            <Fields.Select
              name="display"
              label="Display"
              options={[
                { label: 'Default', value: 'default' },
                { label: 'Radio', value: 'radio' },
                { label: 'Button Group', value: 'button-group' },
              ]}
              submitOnChange={true}
            />
            <Fields.Select
              name="direction"
              label="Display direction"
              options={[
                { label: 'Horizontal', value: 'horizontal' },
                { label: 'Vertical', value: 'vertical' },
              ]}
              disabled={!['radio', 'button-group'].includes(form.values.display)}
              submitOnChange={true}
              showError={false}
            />
          </div>
        </React.Fragment>
      );

    case ObjectTypes.MultiSelect:
      return (
        <div className={classes.group}>
          <SelectOptions form={form} />
        </div>
      );

    case ObjectTypes.Boolean:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="defaultValue"
            label="Is Checked ?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            submitOnChange={true}
            showError={false}
          />
        </div>
      );

    case ObjectTypes.Address:
      return null;

    case ObjectTypes.Signature:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[TypeDefinitions.Text, TypeDefinitions.Signature].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            disabled={values.type === values.originalType}
            clearable={false}
            submitOnChange={true}
          />
          <TargetDocument name="target" />
        </div>
      );

    case ObjectTypes.Paragraph:
      return (
        <div className={classes.group}>
          <ParagraphProperties form={form} />
        </div>
      );

    case ObjectTypes.Link:
      return (
        <div className={classes.group}>
          <Fields.Textarea name="text" label="Text" submitOnChange={true} />
          <Fields.Textarea name="url" label="Url" submitOnChange={true} />
          <div className={classes.botPad15}>
            <ResourceFiles
              upload
              browse
              minimal={false}
              current={form?.values?.url}
              onSubmit={url => {
                form.setFieldValue('url', url);
                void form.submitForm();
              }}
            />
          </div>
          <Fields.Select
            label="Display bottom padding ?"
            name="paddingBottom"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            submitOnChange={true}
            showError={false}
          />
        </div>
      );

    case ObjectTypes.Document:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[TypeDefinitions.Text, TypeDefinitions.Document].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            disabled={values.type === values.originalType}
            clearable={false}
            submitOnChange={true}
          />
          <Fields.Select
            label="Expires ?"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            name="validation.expiration"
            submitOnChange={true}
          />
          <Fields.Text type="number" description="In MB" name="maxSize" label="Max size" submitOnChange={true} />
          <Fields.MultiSelect
            name="mimeTypes"
            label="Allowed file type(s)"
            options={[
              { value: 'application/pdf', label: 'PDF' },
              { value: 'image/*', label: 'Images' },
            ]}
            submitOnChange={true}
            showError={false}
          />
        </div>
      );

    case ObjectTypes.BankAccount:
      return (
        <div className={classes.group}>
          <SelectField
            name="countryId"
            label="Country"
            remoteOptions={{
              loading: countriesApi.loading,
              request: countriesApi.request,
              mapper: result => result?.map(d => ({ value: d.id, label: d.name })),
              filter: 'local',
            }}
            submitOnChange={true}
          />
          <Fields.Select
            name="ownership"
            label="Ownership"
            options={[
              { value: 'company', label: 'Company' },
              { value: 'individual', label: 'Individual' },
            ]}
            submitOnChange={true}
            showError={false}
          />
        </div>
      );

    case ObjectTypes.Phone:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[TypeDefinitions.Text, TypeDefinitions.Phone].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            disabled={values.type === values.originalType}
            clearable={false}
            showError={false}
            submitOnChange={true}
          />
        </div>
      );

    case ObjectTypes.Date:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[TypeDefinitions.Text, TypeDefinitions.Date].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            disabled={values.type === values.originalType}
            clearable={false}
            showError={false}
            submitOnChange={true}
          />
        </div>
      );

    case ObjectTypes.Time:
      return (
        <div className={classes.group}>
          <Fields.Select
            name="type"
            label="Type"
            options={[TypeDefinitions.Text, TypeDefinitions.Time].map(t => ({
              label: t.label,
              value: t.name,
            }))}
            disabled={values.type === values.originalType}
            clearable={false}
            showError={false}
            submitOnChange={true}
          />
        </div>
      );

    default:
      return null;
  }
};
