import React from 'react';
import classNames from 'classnames';

import styles from './address.module.scss';

const LocationResults = ({ results, setResult }) => {
  if (!results) return null;

  if (results?.length === 0) {
    return (
      <div className={styles.results}>
        <div className={classNames(styles.result, styles.empty)}>Unable to resolve requested location.</div>
      </div>
    );
  }

  const renderResult = (item, index = 0) => (
    <div key={index} className={styles.result} onClick={() => setResult(item)}>
      {item?.formatted_address}
    </div>
  );

  return (
    <div className={styles.results}>
      <div className={styles.heading}>Please confirm requested location:</div>
      {results?.map((r, i) => renderResult(r, i))}
    </div>
  );
};

export default LocationResults;
