import React from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';

import { OperatorDefinitions } from '../../../../../../domain/operator-definitions';

import { Field } from '../../../../../../../../form';
import { getFieldTypeProps } from '../../../../../../state/template-initialization';

import classes from '../../custom-fields.module.scss';

const FieldSelection = ({ name, selected, categories, pages, initialDataFields, disabled }) => {
  const getContent = setValues => {
    const readAll = () => {
      const content = [];
      pages?.forEach(page => {
        const pageMenuItems = [];
        page.groups.forEach(group => {
          const groupMenuItems = [];
          group.fields
            .filter(field => OperatorDefinitions[field?.type])
            .forEach(field => {
              const value = `${page.id}.${group.id}.${field.id}`;
              groupMenuItems.push(<MenuItem text={field.label} onClick={() => setValues({ field: value, operator: '', value: '' })} key={value} />);
            });
          if (groupMenuItems.length) {
            pageMenuItems.push(
              <MenuItem text={group.title} key={group.id}>
                {groupMenuItems}
              </MenuItem>,
            );
          }
        });
        if (pageMenuItems.length) {
          content.push(
            <MenuItem text={page.title} key={page.id}>
              {pageMenuItems}
            </MenuItem>,
          );
        }
      });
      return content;
    };

    const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    function getWizardSpecificMacros(data, array, parentInitialData) {
      data?.forEach(obj => {
        if (parentInitialData || obj.isInitialData) {
          const label = obj.name.replace(regExp, '$1$4 $2$3$5');
          let items;
          if (obj.children.length) {
            items = getWizardSpecificMacros(obj.children, [], parentInitialData || obj.isInitialData);
            array.push(
              <MenuItem text={label} key={obj.path}>
                {items}
              </MenuItem>,
            );
          } else {
            const { type } = getFieldTypeProps(obj);
            array.push(<MenuItem text={label} onClick={() => setValues({ field: obj.path, type, operator: '', value: '' })} key={obj.path} />);
          }
        }
      });

      return array;
    }

    let content = readAll(pages);
    if (content.length) {
      content.unshift(<MenuDivider title="Form Values" key="form-values" />);
    }

    const initialDataFieldsOptions = getWizardSpecificMacros(initialDataFields, []);

    if (initialDataFieldsOptions.length) {
      content.push(<MenuDivider title="Initial Values" key="initial-values" />);
      content = content.concat(initialDataFieldsOptions);
    }

    if (categories.length) {
      content.push(<MenuDivider title="Categories" key="categories" />);
      const categoryMenuItems = categories.map(c => (
        <MenuItem text={c.name} onClick={() => setValues({ field: c.name, type: 'OT_NUMBER', operator: '', value: '' })} key={c.name} />
      ));
      content = content.concat(categoryMenuItems);
    }

    if (!content.length) {
      return null;
    }

    return <Menu>{content}</Menu>;
  };

  return (
    <Field label="Field" name={name}>
      {({ _, form }) => {
        const content = getContent(form.setValues);
        return (
          <Popover fill placement="bottom" content={content}>
            <Button
              className={classes.visibilityField}
              fill
              text={selected?.label || selected?.title || 'Field...'}
              alignText="left"
              rightIcon="caret-down"
              disabled={disabled}
            />
          </Popover>
        );
      }}
    </Field>
  );
};

export default FieldSelection;
