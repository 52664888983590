import React from 'react';
import * as Yup from 'yup';

import Form from '../../../../form';
import Dialog from '../../../../dialog';

import useApi from '../../../../../hooks/use-api';
import { createCountryRecord, createRecord, getScopes, updateRecord } from '../../../../../apis/configuration';

import { ClientConfigurationType } from '../enumerations';
import BackgroundCheckForm from './background-check-form';

import classes from './background-check.module.scss';

const BackgroundCheckDialog = ({ isOpen, client, record, records, onSave, onClose }) => {
  const createCountryRecordApi = useApi(createCountryRecord, { skip: true });
  const createRecordApi = useApi(createRecord, { skip: true });
  const updateRecordApi = useApi(updateRecord, { skip: true });
  const scopesApi = useApi(() => getScopes({ organizationId: client?.id }), { skip: !client?.id });

  const submit = async values => {
    if (values.id) {
      await updateRecordApi.request(values?.id, values);
    } else {
      const [countryId, scopeId] = values.location.split('_');

      if (countryId) {
        const payload = {
          ...values,
          countryId,
        };
        await createCountryRecordApi.request(payload);
      } else {
        const payload = {
          ...values,
          scopeId,
        };
        await createRecordApi.request(payload);
      }
    }
    onSave();
  };

  const loading = createCountryRecordApi.loading || createRecordApi.loading || updateRecordApi.loading;

  let location;
  if (record?.recordId) {
    const json = JSON.parse(record.location?.json);
    const country = json?.address_components?.find?.(c => c.types.includes('country'))?.short_name;
    location = country ? `${country}_${record.scopeId}` : `_${record.scopeId}`; // global scope doesn't have country
  }
  return (
    <Dialog isOpen={isOpen} title="Background check policies" onClose={onClose}>
      <div className={classes.backgroundCheckRequirements}>
        <Form
          onSubmit={submit}
          enableReinitialize
          validationSchema={Yup.object().shape({
            location: Yup.string().required('Location is required'),
            data: Yup.object().shape({
              providerName: Yup.string().required('Provider is required'),
            }),
          })}
          initialValues={{
            id: record?.recordId,
            configurationType: ClientConfigurationType.BackgroundCheckRequirements,
            location,
            organizationId: client?.id,
            description: null,
            scopeId: record?.recordId ? record?.scopeId : undefined,
            data: {
              providerName: record?.data?.providerName || '',
              employeeOption: record?.data?.employeeOption || null,
              independentContractorOption: record?.data?.independentContractorOption || null,
            },
          }}
        >
          {form => <BackgroundCheckForm scopesApi={scopesApi} client={client} records={records} record={record} values={form.values} loading={loading} />}
        </Form>
      </div>
    </Dialog>
  );
};

export default BackgroundCheckDialog;
