import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Menu, MenuDivider, MenuItem, Spinner } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import moment from 'moment';

import Fields from '../../../../../components/fields';
import { loadCountries } from '../../../../../state/lookups';
import { parsedFilterValue, toggleFilterValue } from '../../../../../utilities/filter';
import { CompensationTypeOptions, compensationTypeToLabel } from '../../../../../domain/compensation-type';
import { ClassificationTypeOptions, abbreviateClassification } from '../../../../../domain/classification-type';
import { EngagementLocationTypeOptions, engagementLocationTypeToLabel } from '../../../../../domain/engagement-location-type';
import { EngagementStatusOptions, engagementStatusToLabel } from '../../../../../domain/engagement-status-type';

import useApi from '../../../../../hooks/use-api';
import { getClients } from '../../../../../apis/client/organizations';

import classes from '../../engagements.module.scss';

const EngagementsFilters = ({ values, filterCounter, setFieldValue, submitForm, resetFilters, countries, loadCountries }) => {
  const clientsApi = useApi(getClients, { params: { includeParent: true } });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!countries) {
      setLoading(true);
      loadCountries(() => setLoading(false));
    }
  }, []);

  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;
  const parsedCountries = parsedFilterValue(values?.countries);
  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCounter}
        onClick={() => resetFilters()}
        text="Clear Filters"
        label={!!filterCounter ? <Icon icon="cross" /> : null}
      />
      <MenuDivider />
      <MenuItem
        className={classes.rangeFilter}
        text={
          <Fields.Date
            name="start"
            placeholder="Start date"
            maxDate={values.end && moment(values.end).toDate()}
            showActionsBar
            submitOnChange={true}
            outline={false}
            minimal={true}
            showError={false}
          />
        }
        shouldDismissPopover={false}
      />
      <MenuItem
        className={classes.rangeFilter}
        text={
          <Fields.Date
            name="end"
            placeholder="End date"
            minDate={values.start && moment(values.start).toDate()}
            showActionsBar
            submitOnChange={true}
            outline={false}
            minimal={true}
            showError={false}
          />
        }
        shouldDismissPopover={false}
      />
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Countries (${parsedCountries?.length || 'any'})`}
        disabled={!!loading}
        icon={loading ? <Spinner size={20} /> : undefined}
      >
        {!loading && (
          <SimpleBar style={{ maxHeight: '35vh' }}>
            {countries?.map(country => (
              <MenuItem
                key={country.id}
                onClick={() => {
                  const selection = toggleFilterValue(country.id, values?.countries);
                  setFieldValue('countries', selection);
                  submitForm();
                }}
                label={tick(parsedCountries?.includes(country.id))}
                text={country.name}
              />
            ))}
          </SimpleBar>
        )}
      </MenuItem>
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Client (${clientsApi.response?.find?.(c => c.id === values?.clientId)?.name || 'any'})`}
        disabled={clientsApi.loading || !clientsApi.response?.length}
        icon={clientsApi.loading ? <Spinner size={20} /> : undefined}
      >
        {!clientsApi.loading && (
          <SimpleBar style={{ maxHeight: '35vh' }}>
            {clientsApi.response?.map?.((client, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  setFieldValue('clientId', values?.clientId === client.id ? '' : client?.id);
                  submitForm();
                }}
                label={tick(values?.clientId === client.id)}
                text={client.name}
              />
            ))}
          </SimpleBar>
        )}
      </MenuItem>
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Classification (${!!values?.classificationType ? abbreviateClassification(values?.classificationType) : 'any'})`}
      >
        {ClassificationTypeOptions?.map(c => (
          <MenuItem
            key={c.value}
            onClick={() => {
              setFieldValue('classificationType', values.classificationType === c?.value ? '' : c?.value);
              submitForm();
            }}
            label={tick(values?.classificationType === c.value)}
            text={c.label}
          />
        ))}
      </MenuItem>
      <MenuItem shouldDismissPopover={false} text={`Frequency (${compensationTypeToLabel(values?.compensationType) || 'any'})`}>
        {CompensationTypeOptions?.map(c => (
          <MenuItem
            key={c.value}
            onClick={() => {
              setFieldValue('compensationType', values?.compensationType === c.value ? '' : c?.value);
              submitForm();
            }}
            label={tick(values?.compensationType === c.value)}
            text={c.label}
          />
        ))}
      </MenuItem>
      <MenuItem shouldDismissPopover={false} text={`Location (${engagementLocationTypeToLabel(values?.locationType) || 'any'})`}>
        {EngagementLocationTypeOptions?.map(locationType => (
          <MenuItem
            key={locationType.value}
            onClick={() => {
              setFieldValue('locationType', values?.locationType === locationType.value ? '' : locationType.value);
              submitForm();
            }}
            label={tick(values?.locationType === locationType.value)}
            text={locationType.label}
          />
        ))}
      </MenuItem>
      <MenuItem shouldDismissPopover={false} text={`Status (${engagementStatusToLabel(values?.status) || 'any'})`}>
        {EngagementStatusOptions?.map((statusType, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setFieldValue('status', `${values?.status}` === `${statusType?.value}` ? '' : `${statusType?.value}`);
              submitForm();
            }}
            label={tick(`${values?.status}` === `${statusType?.value}`)}
            text={statusType.label}
          />
        ))}
      </MenuItem>
    </Menu>
  );
};

const mapStateToProps = state => ({
  countries: state.lookups.countries,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadCountries,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EngagementsFilters);
