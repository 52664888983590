import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Toaster } from '@blueprintjs/core';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import Timeline from '../../../../../components/timeline';
import FlowStatus from '../../../../../components/flow-status';

import { classificationTypeToLabel } from '../../../../../domain/classification-type';
import { engagementStatusToLabel } from '../../../../../domain/engagement-status-type';

import { getClientDisplayName } from '../../../../../utilities/client';
import { getLocationTypeName } from '../../../../../utilities/location';

import useApi from '../../../../../hooks/use-api';
import useTable from '../../../../../hooks/use-table';
import { getEvaluationEngagements, resendEngagementInvite } from '../../../../../apis/platform/engagements';

import EvaluationsFilters from './filters';

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialog, setDialog] = useState({ open: false, engagementId: undefined });
  const { items, loading, pageCount, page, initialFilters, reload, goToPage, setFilters, resetFilters, filters } = useTable(
    getEvaluationEngagements,
    { pageSize: 10, controlled: true },
    { flowType: 'engagement' },
  );

  const resendApi = useApi(resendEngagementInvite, { skip: true });

  const onResend = async engagementId => {
    await resendApi.request({ engagementId });
    if (!resendApi.error) {
      Toaster.create({ position: 'top' }).show({ message: 'Engagement invite re-sent.', intent: 'success' });
    }
  };

  const engagementId = searchParams.get('id');
  useEffect(() => {
    if (engagementId) {
      setDialog({ open: true, engagementId });
    } else {
      setDialog({ open: false });
    }
  }, [engagementId]);

  const formatDates = d =>
    !!d?.startDate && !!d?.endDate
      ? `${moment(d?.startDate).format('DD/MM/YYYY')} - ${moment(d?.endDate).format('DD/MM/YYYY')}`
      : `${moment(d?.startDate).format('DD/MM/YYYY')} - Ongoing`;

  const toggleDialog = id => {
    if (id) {
      searchParams.set('id', id);
    } else {
      searchParams.delete('id');
    }
    setSearchParams(searchParams);
  };

  return (
    <Page title="Evaluations" path={[{ name: 'Evaluations', to: '/evaluations' }]} scroll={false}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters setFilters={setFilters} resetFilters={resetFilters} initialFilters={initialFilters} filters={filters} content={<EvaluationsFilters />} />
        <Table.Columns.Default
          showOnMobile
          name="Supplier"
          width="30px"
          render={d => <Avatar abbreviate size={{ box: 30, text: 12 }} url={d?.supplierIcon} name={d?.supplierName} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName, d => classificationTypeToLabel(d?.classification?.result)]} />
        <Table.Columns.TwoLines showOnMobile name="Engagement" lines={[d => d?.title, d => getClientDisplayName(d?.clientName, d?.parentClientName)]} />
        <Table.Columns.TwoLines name="Location" showOnMobile lines={[d => getLocationTypeName(d?.locationType), d => d?.location?.prettyName]} />
        <Table.Columns.TwoLines name="Status" width="140px" lines={[d => engagementStatusToLabel(d?.status), d => formatDates(d)]} />
        <Table.Columns.Default
          width="175px"
          render={d => (
            <FlowStatus flowType={filters?.flowType} referenceId={d?.id} signatureContextProps={{ engagementId: d?.id }} loading={loading} align="right" />
          )}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            {
              name: 'Review',
              onClick: d => toggleDialog(d?.id),
            },
            { divider: true },
            { name: 'View engagement', href: d => `/engagements/${d.id}` },
            { name: 'View supplier', href: d => `/suppliers/${d.supplierId}` },
            { divider: true },
            {
              name: 'Re-send invite',
              onClick: d => onResend(d.id),
              disabled: d => !!d?.supplierManagerEmail,
              hidden: d => filters?.flowType !== 'engagement',
            },
            { divider: true, hidden: d => filters?.flowType !== 'engagement' },
            {
              name: 'Impersonate',
              disabled: d => !d?.supplierManagerEmail,
              href: d => `/authentication/impersonate/${Buffer.from(d?.supplierManagerEmail).toString('base64')}`,
              target: '_blank',
            },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      {dialog?.open && <Timeline open={dialog?.open} engagementId={dialog?.engagementId} onClose={() => toggleDialog(undefined)} reloadList={reload} />}
    </Page>
  );
};

export default List;
