import { Button } from '@blueprintjs/core';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFullName } from '../../../utilities/user';
import { fonts, makeSVG } from './utilities';

import useApi from '../../../hooks/use-api';

import { updateSignatureSettings } from '../../../state/identity';
import Dialog from '../../dialog';
import Form from '../../form';
import Fields from '../../fields';

import classes from './signature-field.module.scss';

const SignatureSettingsDialog = ({ dialog, user, settings, handleClose, updateSignatureSettings }) => {
  const [loading, setLoading] = useState(false);
  const makeSVGApi = useApi(makeSVG, { skip: true });

  const element = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (element && element.current && settings?.svg) {
        element.current.innerHTML = settings.svg;
      }
    }, 0);
  }, [dialog?.open]);

  const generateSignature = async ({ text, font }, form) => {
    const svg = await makeSVGApi.request(font, text);
    if (element && element.current) {
      element.current.innerHTML = svg;
      form.setFieldValue('svg', svg);
    }
  };

  const submit = async values => {
    setLoading(true);
    await updateSignatureSettings(values);
    setLoading(false);
    dialog.resolve(values);
  };

  const close = () => {
    if (!loading) {
      handleClose();
      dialog.resolve(null);
    }
  };

  return (
    <Dialog title="Signature settings" isOpen={dialog?.open} onClose={close}>
      <Form initialValues={{ text: settings?.text || getFullName(user), font: settings?.font || '', svg: settings?.svg }} onSubmit={generateSignature}>
        {({ values }) => {
          const disabled = loading || !values.text || !values.font || !values.svg;
          return (
            <React.Fragment>
              <p>Setup your signature by providing signature text and choosing preferred signature font.</p>
              <Fields.Text submitOnChange={true} name="text" label="Text" />
              <Fields.Select submitOnChange={true} name="font" label="Style" options={fonts.map(f => ({ value: f, label: f }))} />
              <div className={classNames(classes.renderer, classes.preview)}>
                <div className={classNames(classes.svg, !values.svg && classes.empty)} ref={element} />
              </div>
              <Button outlined fill text="Submit" disabled={disabled} loading={loading} onClick={() => (disabled ? null : submit(values))} />
            </React.Fragment>
          );
        }}
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
  settings: state.identity.user.signatureSettings,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSignatureSettings,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(SignatureSettingsDialog);
