import React from 'react';
import moment from 'moment';
import { Button, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../../../components/box';

import { displayAllocation, displayCompensationFrequency, displayCompensationType, displayEndDate } from '../../../../../../utilities/engagement';

import classes from './../details.module.scss';

const ViewEngagementUpdates = ({ updates, engagement }) => {
  if (!updates || !engagement) {
    return null;
  }

  const allocation = displayAllocation(updates?.data?.allocation, updates?.data?.type);
  return (
    <Popover
      popoverClassName={classes.updatesPopover}
      content={
        <Box title={`Updates requested for ${moment(updates?.updateDate).format('MMMM Do, YYYY')}`} monochrome>
          <Box.Item label="Title">{updates?.data?.title}</Box.Item>
          <Box.Item label="Description">{updates?.data?.description}</Box.Item>
          <Box.Item label="End Date">{displayEndDate(updates?.data?.endDate)}</Box.Item>
          <Box.Item label="Compensation"></Box.Item>
          <Box.Item label="Type">{displayCompensationType(updates?.data?.type)}</Box.Item>
          <Box.Item label="Amount">{updates?.data?.amount}</Box.Item>
          <Box.Item label="Allocation">{allocation || '-'}</Box.Item>
          <Box.Item label="Frequency">{displayCompensationFrequency(updates?.data?.frequency)}</Box.Item>
          <Box.Item label="Tracks work">{!!updates?.data?.tracksWork ? 'Yes' : 'No'}</Box.Item>
        </Box>
      }
    >
      <Button className={classes.updatesButton} outlined small icon={<Icons.Clock size={16} strokeWidth={1.5} />} text="Updates requested!" />
    </Popover>
  );
};

export default ViewEngagementUpdates;
