import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import classes from './columns.module.scss';

const TwoLinesColumn = ({ row, lines = [], loading }) => {
  if (!!loading) {
    return <div className={classNames(classes.loading, Classes.SKELETON)}>.</div>;
  }

  return (
    <div className={classes.twoLines}>
      {lines.map((line, index) => {
        const content = line?.(row);
        if (content === undefined || content === null || content?.trim?.() === '') {
          return null;
        }

        return (
          <div key={index} className={classes.line}>
            {content}
          </div>
        );
      })}
    </div>
  );
};

TwoLinesColumn.definition = 'column';
TwoLinesColumn.width = '1fr';

export default TwoLinesColumn;
