import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import Spinner from '../spinner';
import { uuid } from '../../utilities/common';
import useApi from '../../hooks/use-api';

import { getCurrentLocation, getLocation } from './api';
import { fitMapBounds, formatViewportBounds, getBoundsRectangle, getMapSize } from './utilities';

import styles from './map.module.scss';

const Map = ({ placeId, query, height = 350 }) => {
  const [map, setMap] = useState();
  const addressBoundsRect = useRef();
  const [address, setAddress] = useState();
  const location = useApi(getCurrentLocation);
  const center = address?.geometry?.location || location.response;
  const mapId = uuid();

  const updateMap = () => {
    if (address) {
      addressBoundsRect.current.setBounds(formatViewportBounds(address.geometry.viewport));
      const { center, zoom } = fitMapBounds(address.geometry.viewport, getMapSize(map));
      map.setCenter(center);
      map.setZoom(zoom - 1);
    }
  };

  useEffect(() => {
    async function load() {
      const result = await getLocation({ query, placeId });
      if (result?.length) {
        setAddress(result[0]);
      }
    }
    void load();
  }, [placeId, query]);

  useEffect(() => {
    if (map) {
      updateMap();
    }
  }, [map, address]);

  return (
    <div id={mapId} className={styles.map} style={{ width: '100%', height: `${height}px` }}>
      {location.loading ? (
        <Spinner global={false} blend />
      ) : (
        <GoogleMapReact
          id="map"
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          defaultCenter={location.response}
          center={center}
          defaultZoom={11}
          yesIWantToUseGoogleMapApiInternals={true}
          options={{ gestureHandling: 'cooperative', disableDefaultUI: true }}
          onGoogleApiLoaded={options => {
            addressBoundsRect.current = getBoundsRectangle(options.map);
            setMap(options.map);
          }}
        />
      )}
    </div>
  );
};

export default Map;
