import { Button, ButtonGroup } from '@blueprintjs/core';
import React, { useState } from 'react';
import * as Icons from 'react-feather';

import Box from '../../../../../components/box';

import useApi from '../../../../../hooks/use-api';
import WithConfirmation from '../../../../../hooks/with-confirmation';

import { getWorkLocations, removeWorkLocation } from '../../../../../apis/client/organizations';
import AddressForm from './forms/address-form';
import CompanyInformationForm from './forms/company-information-form';
import WorkLocationForm from './forms/work-location-form';
import Billing from './billing';

import classes from '../../company-info.module.scss';

const General = ({ clientId, api = {} }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const openDialog = type => setDialog({ open: true, type });
  const closeDialog = () => setDialog({ open: false, type: undefined });
  const { loading: companyInfoLoading, response: client } = api;
  const getWorkLocationsApi = useApi(() => getWorkLocations({ clientId }));
  const removeWorkLocationApi = useApi(removeWorkLocation, { skip: true });

  const handleRemoveWorkLocation = async workLocationId => {
    await removeWorkLocationApi?.request({ clientId, workLocationId });
    await getWorkLocationsApi?.request();
  };

  return (
    <div className={classes.tab}>
      <Box
        title="Company Information"
        outlined
        loading={companyInfoLoading}
        monochrome
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update company information"
              onClick={() => openDialog('general-info')}
            />
          </ButtonGroup>
        }
      >
        <Box.Item label="Name">{client?.name}</Box.Item>
        <Box.Item label="Contact person">{client?.managerName}</Box.Item>
        <Box.Item label="Primary address">
          {client?.address?.prettyName || 'Not provided'}
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update address" onClick={() => openDialog('address')} />
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Website">{client?.website}</Box.Item>
      </Box>

      <Billing client={client} />
      <Box
        title="Work Locations"
        outlined
        monochrome
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Plus size={16} strokeWidth={1.5} />}
              title={client?.address ? 'Add new work location' : 'Please setup company primary address first.'}
              onClick={() => openDialog('work-location')}
              disabled={!client?.address}
            />
          </ButtonGroup>
        }
        loading={getWorkLocationsApi?.loading || removeWorkLocationApi?.loading}
      >
        {!getWorkLocationsApi?.response?.length ? (
          <Box.ItemContainer>
            <Box.ItemLabel fill>No additional work locations set up.</Box.ItemLabel>
          </Box.ItemContainer>
        ) : (
          getWorkLocationsApi?.response?.map((location, index) => (
            <Box.ItemContainer key={location.id}>
              <Box.ItemValue>{location?.address?.prettyName}</Box.ItemValue>
              {location?.description && <Box.ItemLabel>{location?.description}</Box.ItemLabel>}
              <WithConfirmation messages={{ question: `Remove work location: (${location?.address?.prettyName}) ?`, confirmButton: 'Remove' }}>
                <Button
                  small
                  outlined
                  onClick={() => handleRemoveWorkLocation(location?.id)}
                  icon={<Icons.X size={16} strokeWidth={1.5} />}
                  title="Remove work location"
                />
              </WithConfirmation>
            </Box.ItemContainer>
          ))
        )}
      </Box>
      <CompanyInformationForm isOpen={!!dialog?.open && dialog?.type === 'general-info'} onClose={closeDialog} api={api} />
      <AddressForm isOpen={!!dialog?.open && dialog?.type === 'address'} onClose={closeDialog} api={api} />
      <WorkLocationForm isOpen={!!dialog?.open && dialog?.type === 'work-location'} onClose={closeDialog} api={getWorkLocationsApi} clientId={clientId} />
    </div>
  );
};

export default General;
