export const IDENTITY_LOGOUT = 'IDENTITY_LOGOUT';
export const IDENTITY_AUTHENTICATE = 'IDENTITY_AUTHENTICATE';
export const IDENTITY_SET_AUTHENTICATED = 'IDENTITY_SET_AUTHENTICATED';
export const IDENTITY_SET_CLAIMS = 'IDENTITY_SET_CLAIMS';
export const IDENTITY_SET_PROFILE = 'IDENTITY_SET_PROFILE';
export const IDENTITY_CHANGE_ROLE = 'IDENTITY_CHANGE_ROLE';
export const IDENTITY_UPDATE_SIGNATURE = 'IDENTITY_UPDATE_SIGNATURE';

export const APPLICATION_SET_OFFLINE = 'APPLICATION_SET_OFFLINE';
export const APPLICATION_SET_ACTIVITY_OPEN = 'APPLICATION_SET_ACTIVITY_OPEN';
export const APPLICATION_TOGGLE_ACTIVITY_OPEN = 'APPLICATION_TOGGLE_ACTIVITY_OPEN';
export const APPLICATION_SET_THEMES = 'APPLICATION_SET_THEMES';
export const APPLICATION_CHANGE_THEME = 'APPLICATION_CHANGE_THEME';

export const FORM_DESIGNER_LOAD = 'FORM_DESIGNER_LOAD';
export const FORM_DESIGNER_UNLOAD = 'FORM_DESIGNER_UNLOAD';
export const FORM_DESIGNER_TOGGLE_PREVIEW = 'FORM_DESIGNER_TOGGLE_PREVIEW';
export const FORM_DESIGNER_SET_SELECTION = 'FORM_DESIGNER_SET_SELECTION';
export const FORM_DESIGNER_SET_PROPERTIES_VISIBILITY = 'FORM_DESIGNER_SET_PROPERTIES_VISIBILITY';
export const FORM_DESIGNER_UPDATE = 'FORM_DESIGNER_UPDATE';
export const FORM_DESIGNER_SET_STATE = 'FORM_DESIGNER_SET_STATE';

export const LOOKUPS_SET_COUNTRIES = 'LOOKUPS_LOAD_COUNTRIES';
export const LOOKUPS_SET_CURRENCIES = 'LOOKUPS_LOAD_CURRENCIES';

export const CONTEXTS_SET_TREES = 'CONTEXTS_SET_TREES';
