import React from 'react';
import classNames from 'classnames';

import styles from './form.module.scss';

const FormGroup = ({ children, classes, name, label, description, error, outline = true, showError = true, minimal = false }) => {
  const labelText = description ? `${label} (${description})` : label;
  const errorText = error && typeof error === 'string' && error;

  return (
    <div className={classNames(styles.wrapper, outline && styles.outlined, minimal && styles.minimal, classes?.wrapper)} data-id={name}>
      <fieldset className={classNames(classes?.fieldset)}>
        <legend>{labelText}</legend>
        {children}
      </fieldset>
      {showError && <div className={classNames(styles.error, classes?.error)}>{errorText}</div>}
    </div>
  );
};

export default FormGroup;
