import React from 'react';
import { useDrop } from 'react-dnd';

const Dropzone = ({ children, handleDrop }) => {
  const [, drop] = useDrop(
    () => ({
      accept: 'macro-item',
      drop: handleDrop,
    }),
    [handleDrop],
  );

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <div ref={drop} role="Dropzone">
      {children}
    </div>
  );
};

export default Dropzone;
