import React from 'react';

import { DocumentsContext } from './documents-provider';

const useDocuments = () => {
  const context = React.useContext(DocumentsContext);
  if (context === undefined) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }

  return context;
};

export default useDocuments;
