import React, { useState } from 'react';
import moment from 'moment';
import { Drawer } from '@blueprintjs/core';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import Dialog from '../../../../../components/dialog';

import useTable from '../../../../../hooks/use-table';
import { cancelEngagementImportRequest, getEngagementImportRequests } from '../../../../../apis/platform/engagements';

import { capitalize } from '../../../../../utilities/common';

import EngagementsImportFilters from './filters';
import EngagementsImportMenu from './menu';
import ImportRequestDetails from './details';
import CreateImportRequestForm from './forms/create-import-request';

const EngagementImport = () => {
  const { items, loading, filters, initialFilters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    getEngagementImportRequests,
    { pageSize: 10, controlled: true },
    { complete: false, validated: false },
  );

  const handleRemove = async ({ id }) => {
    await cancelEngagementImportRequest({ id });
    reload();
  };

  const [dialog, setDialog] = useState({ open: false, title: undefined, type: undefined, data: undefined, ui: undefined });
  const onCloseDialog = () => setDialog({ open: false, title: undefined, type: undefined, data: undefined, ui: undefined });

  return (
    <Page
      title="Engagement import"
      path={[
        { name: 'Engagements', to: '/engagements' },
        { name: 'Import', to: '/engagements/import' },
      ]}
      scroll={false}
    >
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          content={<EngagementsImportFilters />}
          menu={
            <EngagementsImportMenu
              onImport={() => setDialog({ open: true, title: 'Create new import request', type: 'create-engagement-import', ui: 'dialog' })}
            />
          }
        />
        <Table.Columns.Default
          showOnMobile
          name="Client"
          width="30px"
          render={d => <Avatar abbreviate size={{ box: 30, text: 12 }} url={d?.clientLogo} name={d?.clientName} />}
        />
        <Table.Columns.TwoLines showOnMobile width="2fr" lines={[d => d?.clientName, d => d?.parentClientName]} />

        <Table.Columns.Default width="90px" name="Status" render={d => capitalize(d?.status)} />
        <Table.Columns.Default width="60px" name="Total" render={d => d?.total} />
        <Table.Columns.Default width="60px" name="Validated" render={d => d?.totalValidated} />
        <Table.Columns.Default width="60px" name="imported" render={d => d?.totalImported} />
        <Table.Columns.Default name="Created by" render={d => d?.createdBy} />
        <Table.Columns.Default name="Created at" render={d => (!!d?.createdAt ? moment(d?.createdAt).format('MM/DD/YYYY [at] HH:mm') : '-')} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', onClick: d => setDialog({ open: true, title: 'Import request', type: 'view-engagement-import', ui: 'drawer', data: d }) },
            { divider: true },
            { name: 'View client', href: d => `/clients/${d?.clientId}` },
            { divider: true },
            { name: 'Remove', onClick: d => handleRemove(d), confirmation: true },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <Dialog isOpen={dialog?.open && dialog?.ui === 'dialog'} title={dialog?.title} onClose={onCloseDialog}>
        {dialog?.type === 'create-engagement-import' && <CreateImportRequestForm onClose={onCloseDialog} reloadList={reload} />}
      </Dialog>
      <Drawer position="right" isOpen={dialog?.open && dialog?.ui === 'drawer'}>
        {dialog?.type === 'view-engagement-import' && <ImportRequestDetails data={dialog?.data} onClose={onCloseDialog} reloadList={reload} />}
      </Drawer>
    </Page>
  );
};

export default EngagementImport;
