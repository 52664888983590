import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../dialog';
import Form from '../../../../../../form';
import Fields from '../../../../../../fields';

import useApi from '../../../../../../../hooks/use-api';
import { createWizardLookupData } from '../../../../../../../apis/wizards';

import styles from './select-renderer.module.scss';

const CreateItemDialog = ({ field, data, onSuccess }) => {
  const { lookup } = field;
  const [open, setOpen] = React.useState(false);
  const createLookupDataApi = useApi(createWizardLookupData, { skip: true });

  const submit = async values => {
    const response = await createLookupDataApi.request(lookup.type, { ...values, ...data });
    if (!response.error) {
      setOpen(false);
      onSuccess(Math.random());
    }
  };

  return (
    <div className={styles.createItem}>
      <Button fill outlined icon="plus" onClick={() => setOpen(true)} />
      <Dialog title={`New ${field?.label?.toLowerCase()}`} isOpen={open} onClose={() => setOpen(false)}>
        <Form initialValues={{}} onSubmit={submit}>
          {lookup.create.map(field => {
            switch (field.type) {
              case 'bool':
                return (
                  <Fields.Select
                    name={field.name}
                    label={field.label}
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    key={field.name}
                  />
                );

              case 'address': {
                const validate = value => {
                  if (field.isRequired && !value) {
                    return `${field.label} is required`;
                  }
                  return undefined;
                };
                return <Fields.Address name={field.name} label={field.label} precision="address" key={field.name} validate={validate} />;
              }

              case 'string':
              default:
                const validate = value => {
                  if (field.isRequired && !value) {
                    return `${field.label} is required`;
                  }
                  return undefined;
                };

                if (field.options) {
                  return <Fields.Select name={field.name} label={field.label} options={field.options} validate={validate} key={field.name} />;
                }

                return <Fields.Text name={field.name} label={field.label} validate={validate} key={field.name} />;
            }
          })}

          <Button type="submit" text="Submit" loading={createLookupDataApi.loading} outlined fill />
        </Form>
      </Dialog>
    </div>
  );
};

export default CreateItemDialog;
