import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';
import { startCase } from 'lodash';

import Spinner from '../../../../../../spinner';
import { organizationTypeToLabel } from '../../../../../../../domain/organization-type';

import { createRecord, getRecordValue, updateRecord } from '../../../../../../../apis/configuration';
import { SystemContext } from '../../../../system-provider';

import classes from './flows.module.scss';

const EngagementFlow = ({ record, onClose, api }) => {
  const [loading, setLoading] = useState(false);
  const [configurationRecord, setRecord] = useState({ ...record });
  const [configurationData, setData] = useState();
  const [definition, setDefinition] = useState();
  const { selectedScope } = useContext(SystemContext);

  useEffect(() => {
    async function load() {
      setLoading(true);
      if (!!configurationRecord?.id) {
        const dataResult = await getRecordValue(configurationRecord?.id);
        setData({ ...dataResult });
        setDefinition(JSON.parse(dataResult?.definition));
      } else {
        const recordResult = await createRecord({ ...configurationRecord, scopeId: selectedScope?.id, description: '', data: {} });
        setRecord({ ...recordResult?.data });
        const dataResult = await getRecordValue(recordResult?.data?.id);
        setData({ ...dataResult });
        setDefinition(JSON.parse(dataResult?.definition));
      }
      setLoading(false);
    }
    void load();
  }, []);

  const submit = async () => {
    setLoading(true);
    await updateRecord(configurationRecord?.id, {
      ...configurationRecord,
      data: { ...configurationData, definition: JSON.stringify(definition), id: undefined },
    });
    await api?.request();
    setLoading(false);
    onClose();
  };

  if (!definition) {
    return <Spinner global={false} blend />;
  }

  var groups = Object.keys(definition);
  const getElementName = element => startCase(element?.ConfigurationType?.split?.('|')?.[1]);
  const getTenant = element => organizationTypeToLabel(element?.TenantType);

  const toggleEnabled = async (group, index) => {
    const updates = {
      ...definition,
      [group]: [...definition[group]?.map((element, i) => (i === index ? { ...element, Enabled: !element.Enabled } : element))],
    };
    setDefinition(updates);
  };
  const toggleReview = async (group, index) => {
    const updates = {
      ...definition,
      [group]: [...definition[group]?.map((element, i) => (i === index ? { ...element, RequiresReview: !element.RequiresReview } : element))],
    };
    setDefinition(updates);
  };

  const getIcon = condition => (condition ? <Icons.Check size={16} strokeWidth={1.5} /> : <Icons.X size={16} strokeWidth={1.5} />);

  return (
    <div className={classes.flows}>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.groups}>
          {groups.map(group => (
            <div key={group} className={classes.group}>
              <div className={classes.title}>{startCase(group)}</div>
              <div className={classes.elements}>
                {definition?.[group]?.map?.((element, i) => (
                  <div className={classes.element} key={i}>
                    <div className={classes.name}>{getElementName(element)}</div>
                    <div className={classes.info}>
                      <div className={classes.line}>
                        <div className={classes.label}>Tenant</div>
                        <div className={classes.value}>{getTenant(element)}</div>
                      </div>
                      <div className={classes.line}>
                        <div className={classes.label}>Can be reset/skipped</div>
                        <div className={classes.value}>{element?.Manageable ? 'Yes' : 'No'}</div>
                      </div>
                    </div>
                    <div className={classes.controls}>
                      <ButtonGroup vertical fill alignText="left">
                        <Button
                          outlined
                          fill
                          text="Enabled ?"
                          intent={element?.Enabled ? 'success' : 'danger'}
                          rightIcon={getIcon(element?.Enabled)}
                          onClick={() => toggleEnabled(group, i)}
                        />
                        <Button
                          outlined
                          fill
                          text="Requires review ?"
                          intent={element?.RequiresReview ? 'success' : 'danger'}
                          rightIcon={getIcon(element?.RequiresReview)}
                          onClick={() => toggleReview(group, i)}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </SimpleBar>
      <Button type="button" outlined fill text="Submit" loading={loading} onClick={submit} />
    </div>
  );
};

export default EngagementFlow;
