export const getContextTypeLabel = contextTypeName => {
  if (!contextTypeName) {
    return '';
  }

  let type = contextTypeName?.replace('objectcontext', '');
  type = type.replace('engaged', 'engaged ');
  type = type.replace('engagement', 'engagement ');
  type = type.replace('supplier', 'supplier ');
  type = type.replace('wizard', 'wizard ');
  type = type.replace('update', 'update ');
  type = type.replace('updates', 'updates ');
  return type;
};
