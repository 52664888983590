import React from 'react';
import { useParams } from 'react-router';

import Configurators from '../../../../../components/configurators';
import Page from '../../../../../components/page';

import classes from './email.module.scss';

const Email = () => {
  const { id } = useParams();
  return (
    <Page
      title="Email"
      path={[
        { name: 'Settings', to: '/settings' },
        { name: 'Email', to: `/settings?group=messages` },
      ]}
      scroll={false}
      allowMobile={false}
    >
      <div className={classes.email}>
        <Configurators.Email id={id} />
      </div>
    </Page>
  );
};

export default Email;
