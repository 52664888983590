export const ObjectTypes = {
  Wizard: 'OT_WIZARD',
  Page: 'OT_PAGE',
  Group: 'OT_GROUP',
  Text: 'OT_TEXT',
  Number: 'OT_NUMBER',
  Select: 'OT_SELECT',
  MultiSelect: 'OT_MULTI_SELECT',
  Boolean: 'OT_BOOLEAN',
  Date: 'OT_DATE',
  DateRange: 'OT_DATE_RANGE',
  Time: 'OT_TIME',
  File: 'OT_FILE',
  Phone: 'OT_PHONE',
  Address: 'OT_ADDRESS',
  Signature: 'OT_SIGNATURE',
  Paragraph: 'OT_PARAGRAPH',
  Link: 'OT_LINK',
  Document: 'OT_DOCUMENT',
  BankAccount: 'OT_BANK_ACCOUNT',
  Metadata: 'OT_METADATA',
};

export const TypeDefinitions = {
  Wizard: { name: ObjectTypes.Wizard, label: 'Wizard' },
  Page: { name: ObjectTypes.Page, label: 'Page' },
  Group: { name: ObjectTypes.Group, label: 'Group' },
  Text: {
    name: ObjectTypes.Text,
    field: true,
    label: 'Text',
    description: 'Capture "free-form" textual data.',
    icon: 'text-highlight',
  },
  Number: {
    name: ObjectTypes.Number,
    field: true,
    label: 'Number',
    description: 'Capture all numerical data points.',
    icon: 'calculator',
  },
  Select: {
    name: ObjectTypes.Select,
    field: true,
    label: 'Select',
    description: 'Capture single choice out of offered data points.',
    icon: 'property',
  },
  MultiSelect: {
    name: ObjectTypes.MultiSelect,
    field: true,
    label: 'Multi-Select',
    description: 'Capture single or multiple out of choice data points.',
    icon: 'properties',
  },
  Boolean: {
    name: ObjectTypes.Boolean,
    field: true,
    label: 'Checkbox',
    description: 'Capture boolean data points.',
    icon: 'form',
  },
  Date: {
    name: ObjectTypes.Date,
    field: true,
    label: 'Date',
    description: 'Capture data.',
    icon: 'calendar',
  },
  DateRange: {
    name: ObjectTypes.DateRange,
    field: true,
    label: 'Date Range',
    description: 'Capture date range.',
    icon: 'timeline-events',
  },
  Time: {
    name: ObjectTypes.Time,
    field: true,
    label: 'Time',
    description: 'Capture time.',
    icon: 'time',
  },
  Phone: {
    name: ObjectTypes.Phone,
    field: true,
    label: 'Phone',
    description: 'Capture users phone number.',
    icon: 'phone',
  },
  Address: {
    name: ObjectTypes.Address,
    field: true,
    label: 'Address',
    description: 'Capture users geolocation.',
    icon: 'path-search',
  },
  BankAccount: {
    name: ObjectTypes.BankAccount,
    field: true,
    label: 'Bank Account',
    description: 'Bank account',
    icon: 'bank-account',
  },
  Document: {
    name: ObjectTypes.Document,
    field: true,
    label: 'Document',
    description: 'Enable users to upload various requested documents',
    icon: 'cloud-upload',
  },
  Signature: {
    name: ObjectTypes.Signature,
    field: true,
    label: 'Signature',
    description: 'Enable users to sign various documents.',
    icon: 'draw',
  },
  Paragraph: {
    name: ObjectTypes.Paragraph,
    field: true,
    label: 'Paragraph',
    description: 'Static text content.',
    icon: 'paragraph',
  },
  Link: {
    name: ObjectTypes.Link,
    field: true,
    label: 'Link',
    description: 'Static hyperlink.',
    icon: 'link',
  },
};
