import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Table from '../../../../table';

import { getAppliedConfiguration, removeRecord } from '../../../../../apis/configuration';
import useApi from '../../../../../hooks/use-api';

import { ClientConfigurationType } from '../enumerations';
import BackgroundCheckDialog from './background-check-dialog';

import classes from '../policies.module.scss';

const BackgroundChecksPolicy = ({ client, scopes }) => {
  const [dialog, setDialog] = React.useState({ open: false });
  const getBackgroundCheckRecordsApi = useApi(
    () => getAppliedConfiguration({ organizationId: client?.id, type: ClientConfigurationType.BackgroundCheckRequirements }),
    { skip: !client?.id },
  );

  const openDialog = record => setDialog({ open: true, record });

  const close = () => setDialog({ open: false });

  return (
    <Box
      title="Background checks"
      outlined
      monochrome
      actions={
        <ButtonGroup>
          <Button small outlined icon={<Icons.Settings size={16} strokeWidth={1.5} />} title="Setup background check policies" onClick={() => openDialog()} />
        </ButtonGroup>
      }
      loading={getBackgroundCheckRecordsApi.loading}
    >
      <Box.ItemContainer>
        Setup background check preferences which will be executed every time supplier is invited. Here you have an option to specify different type of
        background check for employee and independent contractor candidates. Also background check preferences can be specified differently for each supported
        country.
      </Box.ItemContainer>
      <Box.ItemContainer className={classes.table}>
        <Table.Container
          data={!!getBackgroundCheckRecordsApi.response?.length ? getBackgroundCheckRecordsApi.response : [{ location: { prettyName: 'Global' }, data: {} }]}
          dark
          showHeadings={false}
          loading={getBackgroundCheckRecordsApi.loading}
        >
          <Table.Columns.Default showOnMobile name="Location" render={d => d.location?.prettyName || 'Global'} />
          <Table.Columns.TwoLines
            width="2fr"
            lines={[
              _ => 'Employees',
              d =>
                !!d.data?.employeeOption ? `will undergo ${d.data?.employeeOption?.split?.('_').join?.(' ')} check.` : 'will not undergo background checks.',
            ]}
          />
          <Table.Columns.TwoLines
            width="2fr"
            lines={[
              _ => 'Independent contractors',
              d =>
                !!d.data?.independentContractorOption
                  ? `will undergo ${d.data?.independentContractorOption?.split?.('_').join?.(' ')} check.`
                  : 'will not undergo background checks.',
            ]}
          />
          <Table.Columns.Actions
            showOnMobile
            actions={[
              {
                name: 'Edit',
                disabled: d => !d?.recordId,
                onClick: d => openDialog(d),
              },
              {
                name: 'Remove',
                disabled: d => !d?.recordId,
                onClick: async d => {
                  await removeRecord(d?.recordId);
                  void getBackgroundCheckRecordsApi.request();
                },
                confirmation: true,
              },
            ]}
          />
        </Table.Container>
      </Box.ItemContainer>
      <BackgroundCheckDialog
        isOpen={dialog?.open}
        client={client}
        record={dialog?.record}
        records={getBackgroundCheckRecordsApi.response}
        scopes={scopes}
        onSave={() => {
          void getBackgroundCheckRecordsApi.request();
          close();
        }}
        onClose={close}
      />
    </Box>
  );
};

export default BackgroundChecksPolicy;
