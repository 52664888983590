import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Drawer } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';

import { getFlowStatus } from '../../apis/wizards';
import { getEngagement, getEngagementTermination, getEngagementUpdate } from '../../apis/platform/engagements';

import TimelineEngagement from './timeline-engagement';
import TimelineItem from './timeline-item';
import TimelineStep from './timeline-step';

import classes from './timeline.module.scss';

const Timeline = ({ onClose, engagementId, open, reloadList = () => null }) => {
  const [timeline, setTimeline] = useState({});
  const [showStep, setShowStep] = useState(undefined);

  const reload = async () => {
    setTimeline(prevState => ({
      ...prevState,
      loading: true,
    }));
    const engagement = await getEngagement(engagementId);
    const engagementFlow = await getFlowStatus({ flowType: 'engagement', referenceId: engagementId });
    const updates = await getEngagementUpdate({ engagementId });
    const terminations = await getEngagementTermination({ engagementId });
    setTimeline(prevState => ({
      ...prevState,
      loading: false,
      engagement,
      engagementFlow,
      updates,
      terminations,
    }));
    reloadList();
  };

  const loadTimeline = async () => {
    setTimeline(prevState => ({
      ...prevState,
      loading: true,
    }));
    const engagement = await getEngagement(engagementId);
    const updates = await getEngagementUpdate({ engagementId });
    const terminations = await getEngagementTermination({ engagementId });

    const engagementFlow = await getFlowStatus({ flowType: 'engagement', referenceId: engagementId });
    const updatesFlow = await getFlowStatus({ flowType: 'engagement-updates', referenceId: engagementId });
    const terminationFlow = await getFlowStatus({ flowType: 'engagement-termination', referenceId: engagementId });

    let foundCurrent = false;
    const visibleSteps = [...(engagementFlow?.steps || []), ...(updatesFlow?.steps || []), ...(terminationFlow?.steps || [])].map(s => {
      if (s?.isComplete || foundCurrent) {
        return s;
      }
      foundCurrent = !s?.isComplete;
      return { ...s, isCurrent: !s?.isComplete };
    });
    const steps = _.reverse(visibleSteps);
    setTimeline({
      loading: false,
      steps,
      engagement,
      updates,
      terminations,
      engagementFlow,
      updatesFlow,
      terminationFlow,
    });
  };

  useEffect(() => {
    if (open) {
      void loadTimeline();
    } else {
      setShowStep(undefined);
      setTimeline({});
    }
  }, [open]);

  const { engagement, engagementFlow, steps, loading } = timeline;

  return (
    <Drawer isOpen={!!open} onClose={onClose} className={classNames(classes.timelineDrawer, showStep && classes.expanded)} position="right" hasBackdrop={false}>
      <div className={classes.container}>
        {!!showStep && (
          <div className={classes.insight}>
            <TimelineStep engagement={engagement} step={showStep} onClose={() => setShowStep(undefined)} loadTimeline={loadTimeline} />
          </div>
        )}
        <div className={classes.timeline}>
          <div className={classes.header}>
            <div className={classes.title}>Timeline</div>
            <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
          </div>
          <SimpleBar className={classes.wrapper}>
            <div className={classes.content}>
              <TimelineEngagement loading={loading} engagement={engagement} engagementFlow={engagementFlow} reload={reload} />
              <ul className={classes.timelineList}>
                {loading
                  ? new Array(5).fill({}).map((step, idx) => <TimelineItem step={step} loading key={idx} />)
                  : steps?.map((step, index) => (
                      <TimelineItem engagement={engagement} step={step} key={index} onClose={onClose} onExpand={() => setShowStep(step)} />
                    ))}
              </ul>
            </div>
          </SimpleBar>
        </div>
      </div>
    </Drawer>
  );
};

export default Timeline;
