import React from 'react';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getColor } from '../../../utilities/common';

const data = [
  {
    month: 'Mar',
    profit: 1000,
    payments: 1400,
    taxes: 1100,
  },
  {
    month: 'Apr',
    profit: 1000,
    payments: 1210,
    taxes: 1210,
  },
  {
    month: 'May',
    profit: 2000,
    payments: 6800,
    taxes: 2200,
  },
  {
    month: 'Jun',
    profit: 2780,
    payments: 3908,
    taxes: 1488,
  },
  {
    month: 'Jul',
    profit: 1890,
    payments: 4800,
    taxes: 1390,
  },
  {
    month: 'Aug',
    profit: 2390,
    payments: 4800,
    taxes: 1190,
  },
  {
    month: 'Sep',
    profit: 3490,
    payments: 4300,
    taxes: 2390,
  },
];

const RevenueChart = () => {
  return (
    <ResponsiveContainer>
      <LineChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 30,
          right: 30,
          left: 30,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <Tooltip />
        <Line type="monotone" dataKey="profit" name="Profit" stackId="1" stroke={getColor('profit')} fill={getColor('profit')} />
        <Line type="monotone" dataKey="payments" name="Payments" stackId="1" stroke={getColor('payments')} fill={getColor('payments')} />
        <Line type="monotone" dataKey="taxes" name="Taxes" stackId="1" stroke={getColor('taxes')} fill={getColor('taxes')} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RevenueChart;
