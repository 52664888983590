import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

import { useEmailConfigurator } from '../../../context';
import { Container, Handle } from '../sortable';
import BlockLoading from './block-loading';
import BlockMenu from './block-menu';
import Field from './field';
import ValidationMark from '../../../../../validation-mark';
import ValidationSchemas from '../../../helpers/validation-schemas';

import classes from './block.module.scss';

const Item = SortableElement(({ contentItem, path }) => <Field field={contentItem} path={path} />);

const Block = ({ block, path, isLoading }) => {
  const [{ selection }, dispatch] = useEmailConfigurator();
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (isLoading) {
    return <BlockLoading />;
  }

  if (!block) {
    return null;
  }

  const onMouseEnter = () => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_SET_HOVERED', data: block.id });
  };

  const onMouseLeave = () => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_SET_HOVERED', data: undefined });
  };

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const reorderItems = updates => {
    dispatch({
      type: 'EMAIL_CONFIGURATOR_REORDER_CONTENT',
      data: { updates, path },
    });
  };

  return (
    <div className={classNames(classes.block, selection?.contentItem?.id === block.id && classes.selected)}>
      <div className={classes.header} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Handle />
        <div className={classes.title}>{block.title || 'Unset'}</div>
        <ButtonGroup>
          <ValidationMark intent="danger" entity={block} validationSchema={ValidationSchemas[block.elementType]} />
          <Button className={classes.actions} minimal icon={isExpanded ? 'chevron-up' : 'chevron-down'} onClick={toggle} disabled={!block.content.length} />
          <BlockMenu block={block} path={path} dispatch={dispatch} setIsExpanded={setIsExpanded} />
        </ButtonGroup>
      </div>
      {isExpanded && (
        <div className={classes.fields}>
          <Container useDragHandle onSortEnd={reorderItems}>
            {block.content?.map?.((contentItem, index) => (
              <Item key={index} index={index} path={[...path, index]} contentItem={contentItem} isLoading={isLoading} />
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default Block;
