import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Configurators from '../../../../../../../components/configurators';
import Spinner from '../../../../../../../components/spinner';

import classes from '../../details.module.scss';

const Configuration = ({ membership, api }) => {
  const { loading, response: client } = api;

  if (loading) {
    return <Spinner global={false} blend />;
  }

  return (
    <div className={classes.tab}>
      <Configurators.System organizationId={client?.id} currentOrganization={membership?.organization?.id} embedded={true} offset={150} />
    </div>
  );
};

const mapStateToProps = state => ({
  membership: state.identity.membership,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
