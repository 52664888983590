import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Form from '../../../../../../../../form';
import Fields from '../../../../../../../../fields';

import classes from '../invoice.module.scss';

const BillComInvoiceProvider = ({ configurationType, scopeId, data, onSubmit, loading }) => {
  const urls = [
    { label: 'Staging', value: 'https://api-sandbox.bill.com/api/v2/' },
    { label: 'Production', value: 'https://api.bill.com/api/v2/' },
  ];
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        configurationType,
        scopeId,
        data: {
          providerName: 'Bill.com',
          configuration: {
            baseUrl: data?.configuration?.baseUrl || '',
            userName: data?.configuration?.userName || '',
            password: data?.configuration?.password || '',
            devKey: data?.configuration?.devKey || '',
            origId: data?.configuration?.origId || '',
          },
        },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.string().required('Scope is required'),
        data: Yup.object().shape({
          providerName: Yup.string().required('Provider is required'),
          configuration: Yup.object().shape({
            baseUrl: Yup.string().required('Base URL is required'),
            userName: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required'),
            devKey: Yup.string().required('DevKey is required'),
            origId: Yup.string().required('Organization Identifier is required'),
          }),
        }),
      })}
      enableReinitialize
    >
      {form => {
        return (
          <div className={classes.billCom}>
            <div className={classes.url}>
              <Fields.Text label="Base URL" name="data.configuration.baseUrl" />
              <Fields.Select label="URL" name="data.configuration.baseUrl" options={urls} />
            </div>
            <Fields.Text label="Username" name="data.configuration.userName" />
            <Fields.Text label="Password" name="data.configuration.password" />
            <Fields.Text label="DevKey" name="data.configuration.devKey" />
            <Fields.Text label="Organization Identifier" name="data.configuration.origId" />
            <Button type="submit" text="Submit" loading={loading} outlined fill />
          </div>
        );
      }}
    </Form>
  );
};

export default BillComInvoiceProvider;
