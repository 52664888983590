import React, { useContext } from 'react';
import { ButtonGroup, Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { TypeDefinitions } from '../../domain/types';
import { WizardSelectionContext } from '../../state/selection-provider';

import { Handle } from '../../common/components/sortable';
import FieldMenu from './menu';

import classes from './field.module.scss';

const Field = ({ page, group, field }) => {
  const { getSelection } = useContext(WizardSelectionContext);
  const icon = Object.values(TypeDefinitions).find(d => d.name === field.type)?.icon;

  return (
    <div className={classNames(classes.field, getSelection()?.id === field.id && classes.selected)}>
      <Handle />
      <div className={classes.title}>{field.label || 'Unset'}</div>
      <ButtonGroup>
        <Icon className={classes.icon} size={14} icon={icon} />
        <FieldMenu page={page} group={group} field={field} />
      </ButtonGroup>
    </div>
  );
};

export default Field;
