import React, { useState } from 'react';

export const WizardSelectionContext = React.createContext({});

const WizardSelectionProvider = ({ template, children }) => {
  const [selection, setSelection] = useState([]);
  const selectElement = data => {
    if (JSON.stringify(selection) === JSON.stringify(data)) return;

    setSelection([]);
    setTimeout(() => {
      setSelection(data);
    }, 150);
  };

  const getSelectionPath = (lastOnly = false) => {
    if (!selection) {
      return;
    }

    const [, page, group, field] = selection;
    let last = undefined;
    switch (selection?.length) {
      case 4:
        last = template?.pages
          ?.find(p => p.id === page.id)
          ?.groups?.find(g => g.id === group.id)
          ?.fields?.find(f => f.id === field.id);
        return lastOnly
          ? last
          : [template, template?.pages?.find(p => p.id === page.id), template?.pages?.find(p => p.id === page.id)?.groups?.find(g => g.id === group.id), last];

      case 3:
        last = template?.pages?.find(p => p.id === page.id)?.groups?.find(g => g.id === group.id);
        return lastOnly ? last : [template, template?.pages?.find(p => p.id === page.id), last];

      case 2:
        last = template?.pages?.find(p => p.id === page.id);
        return lastOnly ? last : [template, last];

      case 1:
        last = template;
        return lastOnly ? last : [last];

      default:
        return last;
    }
  };

  const selectTemplate = () => selectElement([template]);
  const selectPage = page => selectElement([template, page]);
  const selectGroup = (page, group) => selectElement([template, page, group]);
  const selectField = (page, group, field) => selectElement([template, page, group, field]);
  const getSelection = () => getSelectionPath(true);
  const clearSelection = () => selectElement([]);

  return (
    <WizardSelectionContext.Provider
      value={{
        selectTemplate,
        selectPage,
        selectGroup,
        selectField,
        getSelection,
        getSelectionPath,
        clearSelection,
      }}
    >
      {children}
    </WizardSelectionContext.Provider>
  );
};

export default WizardSelectionProvider;
