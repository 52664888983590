import React from 'react';
import { Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Box from '../../../../../../../components/box';
import { getClientDisplayName } from '../../../../../../../utilities/client';
import { displayAllocation, displayEndDate, displayRate, displayStartDate } from '../../../../../../../utilities/engagement';

import classes from './engagement-info.module.scss';

const EngagementInfo = ({ engagement }) => {
  const client = getClientDisplayName(engagement.client, engagement.parentClient);
  const rate = displayRate(engagement.compensationAmount, engagement.currencySymbol, engagement.compensationType);
  const allocation = displayAllocation(engagement.compensationAllocation, engagement.compensationType);
  const payRate = !!allocation ? `${rate}, ${allocation}` : rate;
  const start = displayStartDate(engagement.startDate);
  const end = displayEndDate(engagement.endDate);

  const getMetadata = () => {
    try {
      const properties = Object.entries(JSON.parse(engagement?.metadata))?.map?.(o => ({ label: o?.[0], value: o?.[1] }));
      return properties;
    } catch (error) {
      return [];
    }
  };

  return (
    <div className={classes.info}>
      <Popover
        popoverClassName={classes.popover}
        content={
          <SimpleBar className={classes.wrapper}>
            <div className={classes.container}>
              <Box title={engagement?.title} monochrome>
                <Box.Item label="Client">{client}</Box.Item>
                <Box.Item label="Start/End Date">
                  {start} - {end}
                </Box.Item>
                <Box.Item label="Compensation">{payRate}</Box.Item>
                <Box.Item label="Manager">{engagement.managerName || '-'}</Box.Item>
                <Box.ItemContainer>
                  <div className={classes.description}>
                    <p>Engagement description</p>
                    {engagement.description || '-'}
                  </div>
                </Box.ItemContainer>
                {getMetadata()?.map?.((d, i) => (
                  <Box.Item key={i} label={d?.label}>
                    {d?.value || '-'}
                  </Box.Item>
                ))}
              </Box>
            </div>
          </SimpleBar>
        }
      >
        <div>
          <span>{engagement?.title}</span>
          <Icons.HelpCircle size={17} strokeWidth={1.5} />
        </div>
      </Popover>
    </div>
  );
};

export default EngagementInfo;
