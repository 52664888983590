import React from 'react';
import { connect } from 'react-redux';

import useApi from '../../../hooks/use-api';
import { generateDocumentStream, getPreviewStream } from '../../../apis/wizards';

import { Field } from '../../form';

import useVisibilityCheck from '../../../hooks/use-visibility-check';

import SignatureButton from './signature-button';
import DocumentContent from './document-content';

import styles from './signature-field.module.scss';

const SignatureField = props => {
  const { classes, name, label, description, target, placeholder, readonly, contextData, validate, showError, membership, eligibleSignatories, onSign } = props;
  const previewApi = useApi(getPreviewStream, { skip: true });
  const generateApi = useApi(generateDocumentStream, { skip: true });
  const rootRef = React.useRef();
  const buttonsRef = React.useRef();

  const loaded = previewApi.loading === false || generateApi.loading === false;
  const loading = !loaded;

  const parent = document.getElementById('wizard_container');
  const componentVisible = useVisibilityCheck(rootRef);
  const buttonsVisible = useVisibilityCheck(buttonsRef);

  const shouldShowFloating = value => {
    const signed = value?.some(v => v.roleId === membership.roleId && v.signatureId);
    return !!parent && !!componentVisible && !buttonsVisible && !signed;
  };

  // this is commented because it should be inverted value from shouldShowFloating function result
  /* const shouldShowStatic = value => {
    const signed = value?.some(v => v.roleId === membership.roleId && v.signatureId);
    return !parent || (!!componentVisible && !!buttonsVisible) || !!signed;
  };*/

  return (
    <div className={styles.signature} ref={rootRef}>
      <DocumentContent target={target} readonly={readonly} contextData={contextData} loading={loading} previewApi={previewApi} generateApi={generateApi} />
      <Field
        classes={classes}
        label={label}
        description={description}
        name={name}
        validate={validate}
        outline={false}
        minimal={true}
        showError={showError && !loading}
      >
        {({ field, form }) => {
          const onChange = value => {
            form.setFieldValue(name, value);
            onSign?.(value);
          };

          return (
            <div ref={buttonsRef} className={styles.buttonsWrapper}>
              <SignatureButton
                loading={loading}
                disabled={loading}
                readonly={readonly}
                value={field.value}
                floating={shouldShowFloating(field.value)}
                target={target}
                placeholder={placeholder}
                eligibleSignatories={eligibleSignatories}
                onChange={onChange}
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

const mapStateToProps = state => ({ membership: state.identity.membership });

export default connect(mapStateToProps)(SignatureField);
