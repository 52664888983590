import classNames from 'classnames';
import React from 'react';

import classes from './spinner.module.scss';

const Spinner = ({ className, text, global = true, blend = false }) => {
  return (
    <div className={classNames(classes.spinner, !!global && classes.global, !!blend && classes.blend, className)}>
      <div className={classes.circle} />
      {!!text && <div className={classes.text}>{text}</div>}
    </div>
  );
};

export default Spinner;
