import React from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import PopoverContent from './popover-content';

import classes from './notes-popover.module.scss';

const NotesPopover = ({ fieldId, categoryId, review, readonly, onComplete, buttonClassName }) => {
  if (!review) {
    return null;
  }

  const insights = review?.insights?.[fieldId || categoryId];
  const text = insights?.length ? insights?.length : undefined;
  const title = readonly ? `${text} note(s)` : !!text ? 'Show note(s)' : 'Add note';

  const content = <PopoverContent insights={insights} reviewId={review?.id} fieldId={fieldId} categoryId={categoryId} onComplete={onComplete} />;

  if (readonly && !text) {
    return null;
  }

  return (
    <Popover popoverClassName={classes.popover} content={content} disabled={readonly}>
      <Button
        className={classNames(classes.insightIcon, readonly && classes.readonly, buttonClassName)}
        outlined={!readonly}
        minimal={readonly}
        small
        rightIcon={readonly ? <Icons.List size={16} strokeWidth={1.5} /> : <Icons.MessageSquare size={16} strokeWidth={1.5} />}
        text={text}
        title={title}
      />
    </Popover>
  );
};

export default NotesPopover;
