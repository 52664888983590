import React from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import TimeRecordCommentsProvider from './state';
import CommentsForm from './form';
import Messages from './messages';

import classes from './comments.module.scss';

const Comments = ({ recordId, element, asPopover = false }) => {
  if (!recordId) {
    return null;
  }

  const comments = (
    <TimeRecordCommentsProvider recordId={recordId}>
      <div className={classes.chat}>
        <SimpleBar className={classes.wrapper}>
          <Messages />
        </SimpleBar>
        <CommentsForm recordId={recordId} />
      </div>
    </TimeRecordCommentsProvider>
  );

  if (!asPopover) {
    return comments;
  }

  return (
    <Popover popoverClassName={classes.popover} content={comments}>
      {!!element ? element : <Button outlined small icon={<Icons.MessageSquare size={16} strokeWidth={1.25} />} />}
    </Popover>
  );
};

export default Comments;
