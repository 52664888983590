import { useRef, useEffect } from 'react';

import { renderDocStream } from '../../../helpers/render-doc-stream';
import useDocuments from '../../../state/use-documents';

import classes from './pdf-editor.module.scss';

const DocumentContent = () => {
  const { stream } = useDocuments();
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current && stream.html) {
      renderDocStream({ response: stream.html, contentRef: ref, ratio: 1, fromScratch: stream.fromScratch });
    }
  }, [ref, stream]);

  return (
    <div className={classes.document}>
      <div className={classes.htmlContent} ref={ref} />
    </div>
  );
};

export default DocumentContent;
