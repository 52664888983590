export const renderDocStream = ({ response, contentRef, ratio, fromScratch }) => {
  if (!response) {
    return;
  }

  // cleanup existing content and styles
  contentRef.current.innerHTML = '';
  contentRef.current.style = null;

  const parser = new DOMParser();
  const dom = parser.parseFromString(response, 'text/html');

  if (fromScratch) {
    contentRef.current.innerHTML = response;
    contentRef.current?.style?.setProperty('width', `100%`);
    contentRef.current?.style?.setProperty('height', `100%`);
    contentRef.current?.style?.setProperty('padding', `60px`);
    contentRef.current?.style?.setProperty('box-sizing', `content-box`);
    return;
  }

  const pages = dom.getElementsByTagName('svg');
  const elements = dom.getElementsByTagName('div');
  let height = 0;

  // add new pages
  while (pages.length) {
    const page = pages.item(0);
    height += page.height.baseVal.value;
    contentRef.current.appendChild(page);
  }

  while (elements.length) {
    const element = elements.item(0);
    contentRef.current.appendChild(element);
  }

  contentRef.current?.style?.setProperty('height', `${height * ratio}px`);
  contentRef.current?.style?.setProperty('width', `${816 * ratio}px`);
  contentRef.current?.style?.setProperty('transform', `scale(${ratio})`);
  contentRef.current?.style?.setProperty('transform-origin', `0 0`);
};
