import * as Yup from 'yup';

import { ObjectTypes } from '../types';

export const YesNoOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

export function getInitSchema(field) {
  let schema;

  switch (field.type) {
    case ObjectTypes.Number:
      schema = Yup.number();
      break;

    case ObjectTypes.MultiSelect:
    case ObjectTypes.DateRange:
      schema = Yup.array();
      break;

    case ObjectTypes.Boolean:
      schema = Yup.boolean();
      break;

    case ObjectTypes.Signature:
    case ObjectTypes.Address:
      schema = Yup.mixed();
      break;

    default:
      schema = Yup.string();
      break;
  }

  return schema.label(field.label);
}

export const Validators = {
  required: (schema, field) => {
    if (!field?.validation?.required) {
      return schema;
    }
    switch (field?.type) {
      case ObjectTypes.DateRange: {
        // eslint-disable-next-line no-template-curly-in-string
        return schema.ensure().length(2, '${path} is a required field');
      }

      case ObjectTypes.MultiSelect: {
        return schema.ensure();
      }

      default:
        return schema.required();
    }
  },
  email: (schema, field) => {
    if (!field?.validation?.email) {
      return schema;
    }

    return schema.email();
  },
  max: (schema, field) => {
    if (!field?.validation?.max) {
      return schema;
    }
    switch (field?.type) {
      case ObjectTypes.Date:
      case ObjectTypes.DateRange:
      case ObjectTypes.Time:
        // validation for max property was done in component
        return schema;

      default:
        return schema.max(Number(field?.validation?.max));
    }
  },
  min: (schema, field) => {
    if (!field?.validation?.min) {
      return schema;
    }
    switch (field?.type) {
      case ObjectTypes.Date:
      case ObjectTypes.DateRange:
      case ObjectTypes.Time:
        // validation for min property was done in component
        return schema;

      default:
        return schema.min(Number(field?.validation?.min));
    }
  },
  regex: (schema, field) => {
    if (!field?.validation?.regex) {
      return schema;
    }
    const regExp = new RegExp(field?.validation?.regex);
    return schema.matches(regExp);
  },
};
