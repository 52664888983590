import Clients from '../configuration/client';
import { toQueryString } from '../utilities/common';

export const getExternalIdentities = async ({ filters = {}, page, pageSize }) => {
  const { userId, supplierId, clientId, ...rest } = filters;
  const query = {
    ...rest,
    userId,
    organizationId: supplierId || clientId,
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/externalIdentities${toQueryString(query)}`);
  return response?.data;
};

export const getExternalIdentity = async ({ id }) => {
  const response = await Clients.Api.get(`ui/externalIdentities/${id}`);
  return response?.data;
};

export const createExternalIdentity = async ({ providerType, providerName, userId, organizationId, externalUserId, externalOrganizationId }) => {
  const payload = {
    providerType,
    providerName,
    userId,
    organizationId,
    externalUserId,
    externalOrganizationId,
  };
  const response = await Clients.Api.post(`ui/externalIdentities`, payload);
  return response?.data;
};

export const registerNewExternalIdentity = async ({ providerType, providerName, userId, organizationId, externalUserId, externalOrganizationId }) => {
  const payload = {
    providerType,
    providerName,
    userId,
    organizationId,
    externalUserId,
    externalOrganizationId,
  };
  const response = await Clients.Api.post(`ui/externalIdentities/register`, payload);
  return response?.data;
};

export const updateExternalIdentity = async ({ id, providerType, providerName, userId, organizationId, externalUserId, externalOrganizationId }) => {
  const payload = {
    providerType,
    providerName,
    userId,
    organizationId,
    externalUserId,
    externalOrganizationId,
  };
  const response = await Clients.Api.put(`ui/externalIdentities/${id}`, payload);
  return response?.data;
};

export const removeExternalIdentity = async ({ id }) => {
  const response = await Clients.Api.delete(`ui/externalIdentities/${id}`);
  return response?.data;
};
