import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { useParams } from 'react-router';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Tabs from '../../../../../components/tabs';

import useApi from '../../../../../hooks/use-api';
import { getEngagement } from '../../../../../apis/client/engagements';

import Documents from './documents';
import General from './general';
import Time from './time';

import classes from './details.module.scss';

const Details = () => {
  const { id: engagementId } = useParams();
  const api = useApi(() => getEngagement(engagementId));

  const avatar = (
    <div className={classes.avatarContainer}>
      <Avatar
        loading={api?.loading}
        name={api?.response?.supplierName}
        size={{ box: 220, text: 30 }}
        styles={{ marginBottom: '15px' }}
        url={api?.response?.supplierIcon}
      />
    </div>
  );

  const commonProps = { api, engagementId };

  const title = `${api?.response?.supplierName} - ${api?.response?.title}`;
  return (
    <Page
      title={!!api?.loading ? <span className={Classes.SKELETON}>Engagement</span> : title}
      scroll={false}
      path={[
        { name: 'Engagements', to: '/engagements' },
        {
          name: !!api?.loading ? <span className={Classes.SKELETON}>Engagement</span> : title,
          to: `/engagements/${api?.response?.id}`,
        },
      ]}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Time" component={Time} props={commonProps} />
          <Tabs.Item title="Documents" component={Documents} props={commonProps} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

export default Details;
