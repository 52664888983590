import React from 'react';

import { ElementTypes } from '../../../enumerations/field-definitions';
import PreviewLinkContentItem from './preview-link-content-item';
import PreviewDivContentItem from './preview-div-content-item';
import PreviewButtonContentItem from './preview-button-content-item';
import PreviewTextContentItem from './preview-text-content-item';
import PreviewImageContentItem from './preview-image-content-item';
import PreviewTableContentItem from './preview-table-content-item';

const PreviewContentItem = ({ contentItem, hovered }) => {
  let PreviewComponent = null;

  switch (contentItem.elementType) {
    case ElementTypes.BUTTON: {
      PreviewComponent = <PreviewButtonContentItem contentItem={contentItem} hovered={hovered} />;
      break;
    }

    case ElementTypes.LINK: {
      PreviewComponent = <PreviewLinkContentItem contentItem={contentItem} hovered={hovered} />;
      break;
    }

    case ElementTypes.IMAGE: {
      PreviewComponent = <PreviewImageContentItem contentItem={contentItem} hovered={hovered} />;
      break;
    }

    case ElementTypes.TABLE:
      PreviewComponent = <PreviewTableContentItem contentItem={contentItem} hovered={hovered} />;
      break;

    case ElementTypes.TEXT: {
      PreviewComponent = <PreviewTextContentItem contentItem={contentItem} hovered={hovered} />;
      break;
    }

    case ElementTypes.DIV:
    default: {
      const children = contentItem.content.map(item => <PreviewContentItem contentItem={item} hovered={hovered} key={item.id} />);
      PreviewComponent = (
        <PreviewDivContentItem contentItem={contentItem} hovered={hovered}>
          {children}
        </PreviewDivContentItem>
      );
      break;
    }
  }

  return PreviewComponent;
};

export default PreviewContentItem;
