import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Callout } from '@blueprintjs/core';
import * as Yup from 'yup';
import classNames from 'classnames';

import Dialog from '../../../../../components/dialog';
import Form from '../../../../../components/form';
import Fields from '../../../../../components/fields';

import useApi from '../../../../../hooks/use-api';
import { getProfile } from '../../../../../apis/profile';
import { setProfile } from '../../../../../state/identity/actions';

import classes from '../../profile.module.scss';

const EmailAddressForm = ({ isOpen = false, onClose, user, setProfile }) => {
  const getProfileApi = useApi(getProfile, { skip: true });

  const submit = async () => {
    // TODO: call update email api.
    const profile = await getProfileApi.request();
    setProfile(profile);
    onClose();
  };

  const loading = getProfileApi.loading;

  return (
    <Dialog title="Email Address" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Not valid email address.').required('Email is required.'),
        })}
        onSubmit={submit}
      >
        <p>Changing email address will require validation code which will be sent to {user.email}.</p>
        <Callout className={classNames('bp5-form-group', classes.botMar15)}>
          This action will unlink all existing external identity provider logins (Google, LinkedIn, ...) you may be using and those will need to be set up again
          for your new email address.
        </Callout>
        <Fields.Text fill label="New email address" name="email" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmailAddressForm);
