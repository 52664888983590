import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';

import Spinner from '../../../../../../../spinner';
import Form from '../../../../../../../form';
import Fields from '../../../../../../../fields';

import useApi from '../../../../../../../../hooks/use-api';
import { createRecord, updateRecord, getConfigurationInScope } from '../../../../../../../../apis/configuration';

import { SystemContext } from '../../../../../system-provider';

import classes from './background-check-requirements.module.scss';

const BackgroundCheckRequirements = ({ record, onClose, loading: valuesLoading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);
  const getProviderPackagesApi = useApi(() => getConfigurationInScope(selectedScope?.organizationId, 'ServiceProviders|BackgroundCheck', selectedScope?.id));

  const loading = valuesLoading || getProviderPackagesApi?.loading;

  if (loading) {
    return (
      <div className={classes.backgroundCheckRequirements}>
        <div className={classes.loading}>
          <Spinner blend global={false} />
        </div>
      </div>
    );
  }

  const providers = parseProviderPackages(getProviderPackagesApi?.response);

  const submit = async values => {
    const payload = {
      ...(record || {}),
      scopeId: selectedScope?.id,
      data: values,
    };

    setLoading(true);
    if (record?.id) {
      await updateRecord(record?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <div className={classes.backgroundCheckRequirements}>
      <Form
        onSubmit={submit}
        enableReinitialize
        initialValues={{
          providerName: data?.providerName || '',
          employeeOption: data?.employeeOption || null,
          independentContractorOption: data?.independentContractorOption || null,
        }}
      >
        {form => {
          const options = !!form?.values?.providerName
            ? [
                { value: null, label: 'None', description: 'No background check will be conducted.' },
                ...providers
                  ?.find?.(p => p.providerName === form?.values?.providerName)
                  ?.packages?.map?.(p => ({ value: p?.slug, label: p?.name, description: p?.description })),
              ]
            : [];

          const getDescription = prop => options.find?.(p => p?.value === prop)?.description;

          return (
            <React.Fragment>
              <Fields.Select
                name="providerName"
                label="Provider"
                placeholder="Select background check provider"
                options={providers?.map?.(p => ({ value: p?.providerName, label: p?.providerName }))}
              />

              {!!form?.values?.providerName && (
                <div className={classes.panels}>
                  <div className={classes.panel}>
                    <Fields.Select name="employeeOption" label="Employee package" placeholder="Select package" options={options} />
                    <div className={classes.description}>{getDescription(form?.values?.employeeOption)}</div>
                  </div>
                  <div className={classes.panel}>
                    <Fields.Select name="independentContractorOption" label="Independent contractor package" placeholder="Select package" options={options} />
                    <div className={classes.description}>{getDescription(form?.values?.independentContractorOption)}</div>
                  </div>
                </div>
              )}
              <Button type="submit" text="Submit" loading={loading} outlined fill />
            </React.Fragment>
          );
        }}
      </Form>
    </div>
  );
};

const parseProviderPackages = response => {
  if (!response?.length) return [];

  const providers = response?.map?.(i => {
    const packages = JSON.parse(i?.options) || [];
    return { providerName: i?.providerName, packages };
  });

  return providers;
};

export default BackgroundCheckRequirements;
