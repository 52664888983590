import React, { useContext, useState } from 'react';
import SimpleBar from 'simplebar-react';

import Box from '../../../box';

import { SystemConfiguratorElements } from '../common/elements';

import { SystemContext } from '../system-provider';
import AddScopeDialog from './dialogs/add-scope';
import RemoveScopeDialog from './dialogs/remove-scope';
import ViewScopeDialog from './dialogs/view-scope';
import Scope from './scope';

import classes from './scopes.module.scss';

const Scopes = ({ height, embedded }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined, scope: undefined });
  const { scopes, scopesLoading: loading } = useContext(SystemContext);

  const nested = nestScopes(scopes, undefined);
  const Dialog = dialog.type === 'view' ? ViewScopeDialog : dialog.type === 'add' ? AddScopeDialog : RemoveScopeDialog;

  return (
    <Box title="Scopes" className={classes.scopes} contentClassName={classes.content} outlined={embedded} monochrome={true}>
      <SimpleBar className={classes.wrapper} style={{ height: `${height - 50}px` }}>
        <SystemConfiguratorElements.Loader loading={loading} height={height} />
        <SystemConfiguratorElements.EmptyList loading={loading} height={height} response={scopes} message={`No scopes setup.`} />
        {!loading &&
          nested?.map((s, i) => (
            <Scope
              key={i}
              scope={s}
              onAdd={scope => setDialog({ open: true, type: 'add', scope })}
              onRemove={scope => setDialog({ open: true, type: 'remove', scope })}
              onView={scope => setDialog({ open: true, type: 'view', scope })}
            />
          ))}
      </SimpleBar>
      <Dialog isOpen={!!dialog?.open} scope={dialog.scope} onClose={() => setDialog({ open: false, type: undefined, scope: undefined })} />
    </Box>
  );
};

const nestScopes = (scopes, parentId = undefined) => {
  if (!scopes?.length) {
    return [];
  }
  if (!parentId) {
    const node = scopes.find(s => s.parentId === null || s.parentId === undefined);
    node.subScopes = nestScopes(scopes, node.id);
    return [node];
  } else {
    const nodes = scopes.filter(s => s.parentId === parentId).map(n => ({ ...n, subScopes: nestScopes(scopes, n.id) }));
    return nodes;
  }
};

export default Scopes;
