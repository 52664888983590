import { useCallback, useEffect, useState } from 'react';
import { hash } from '../../utilities/common';
import useIsMounted from '../is-mounted';

const useApi = (api, config = {}) => {
  const { params, body, skip } = config;
  const [state, setState] = useState({ loading: skip ? undefined : true, error: undefined, response: undefined });
  const isMounted = useIsMounted();

  const request = useCallback(
    (...args) => {
      setState(prevState => ({ ...prevState, loading: true, error: undefined }));
      return api(...args)
        .then(response => {
          if (!isMounted.current) return;

          setState({ loading: false, error: undefined, response });
          return Promise.resolve(response);
        })
        .catch(error => {
          if (error.isAxiosError) {
            if (!isMounted.current) return;

            setState({ loading: false, error: error.message, response: undefined });
            return Promise.resolve({ error });
          }

          throw error;
        });
    },
    [api],
  );

  const setLoading = loading => setState(prevState => ({ ...prevState, loading }));

  const setResponse = response => setState(prevState => ({ ...prevState, response }));

  useEffect(() => {
    if (!skip) {
      request(params, body);
    }
  }, [hash({ params, body, skip, request })]);

  const { response, error, loading } = state;
  return {
    response,
    error,
    loading,
    request,
    setLoading,
    setResponse,
  };
};

export default useApi;
