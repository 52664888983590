import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Table from '../../../../table';
import Dialog from '../../../../dialog';

import { getAppliedConfiguration, removeRecord } from '../../../../../apis/configuration';
import useApi from '../../../../../hooks/use-api';

import ContractorInsuranceForm from './contractor-insurance-form';
import { ClientConfigurationType } from '../enumerations';

import classes from '../policies.module.scss';
import WithConfirmation from '../../../../../hooks/with-confirmation';

const IndependentContractorInsurancePolicy = ({ client, globalScope }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const getContractorInsuranceApi = useApi(
    () => getAppliedConfiguration({ organizationId: client?.id, type: ClientConfigurationType.IndependentContractorInsuranceRequirements }),
    { skip: !client?.id },
  );
  const removeRecordApi = useApi(removeRecord, { skip: true });

  const close = () => setIsOpen(false);

  const record = getContractorInsuranceApi.response?.[0];

  const handleRemove = async () => {
    await removeRecordApi.request(record?.recordId);
    getContractorInsuranceApi.setResponse([]);
  };

  return (
    <Box
      title="Independent contractor insurance"
      outlined
      monochrome
      actions={
        <ButtonGroup>
          <WithConfirmation
            captureDismiss={false}
            messages={{
              question: `Are you sure you want to remove contractor insurance configuration record?`,
            }}
          >
            <Button
              small
              outlined
              loading={removeRecordApi.loading}
              icon={<Icons.Trash size={16} strokeWidth={1.5} />}
              title="Delete contractor insurance"
              disabled={!record}
              onClick={handleRemove}
            />
          </WithConfirmation>
          <Button
            small
            outlined
            icon={<Icons.Settings size={16} strokeWidth={1.5} />}
            title="Setup contractor insurance policy"
            disabled={!globalScope}
            onClick={() => setIsOpen(true)}
          />
        </ButtonGroup>
      }
      loading={getContractorInsuranceApi.loading}
    >
      <Box.ItemContainer>
        <div>
          <p>
            In an effort to protect {client?.name} and to minimize the risk that GreenLight takes on, we have requirements all Independent Contractors must
            meet.
          </p>
          <p>All prospective Independent Contractors will be required to provide proof of business and carry insurances.</p>
          <br />
          <p>The default minimum requirements are as follows:</p>
          <ul>
            <li>General liability coverage of $2,000,000</li>
            <li>Errors and Omissions coverage of $1,000,000</li>
            <li>Auto liability coverage of $1,000,000</li>
            <li>Suppliers compensation coverage of $1,000,000</li>
          </ul>
          <span>To set higher insurance requirements, contact your GreenLight account representative.</span>
        </div>
      </Box.ItemContainer>
      {!!record && (
        <Box.ItemContainer className={classes.table}>
          <Table.Container data={[record]} loading={getContractorInsuranceApi.loading} showHeadings={false} dark>
            <Table.Columns.Default
              render={d => (
                <span>
                  {`Proof of independent contractor insurance `}
                  {!!d?.data?.isRequired ? (
                    <strong>is required</strong>
                  ) : (
                    <span>
                      <strong>is not</strong> required
                    </span>
                  )}
                  .
                </span>
              )}
            />
          </Table.Container>
        </Box.ItemContainer>
      )}
      <Dialog isOpen={isOpen} title="Contractor insurance policy" onClose={close}>
        {isOpen && (
          <ContractorInsuranceForm
            scopeId={globalScope?.id}
            record={record}
            onSave={() => {
              void getContractorInsuranceApi.request();
              close();
            }}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default IndependentContractorInsurancePolicy;
