import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { TrackingRecordStatusTypes } from '../../../../domain/tracking-record-status-type';
import WithConfirmation from '../../../../hooks/with-confirmation';

const TimeMenu = ({ suppliers, updateStatus, onExport }) => {
  const records = suppliers
    ?.map?.(s => s?.records || [])
    ?.flat(1)
    ?.map?.(r => r?.id);

  return (
    <Menu>
      <MenuItem text="Export" label=".csv" onClick={onExport} />
      <MenuDivider />
      <WithConfirmation messages={{ confirmButton: 'Approve', title: 'Approve records for all listed suppliers ?' }} icon={true}>
        <MenuItem text="Approve all" onClick={() => updateStatus({ status: TrackingRecordStatusTypes.Approved, records })} shouldDismissPopover={false} />
      </WithConfirmation>
      <WithConfirmation messages={{ confirmButton: 'Dispute', title: 'Dispute records for all listed suppliers ?' }} icon={true}>
        <MenuItem text="Dispute all" onClick={() => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records })} shouldDismissPopover={false} />
      </WithConfirmation>
    </Menu>
  );
};

export default TimeMenu;
