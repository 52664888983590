import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Details from './containers/details';
import List from './containers/list';

const Users = () => {
  return (
    <Routes>
      <Route path="/" Component={List} />
      <Route path="/:id" Component={Details} />
    </Routes>
  );
};

export default Users;
