import { uuid } from '../../../../utilities/common';

import Page from './page';
import { ObjectTypes } from './types';

export default class Wizard {
  constructor(wizard = {}) {
    const { id = uuid(), title = 'New Wizard', description = 'Template', categories = [], pages = [], refName, contextTypeName } = wizard;

    this.id = id;
    this.type = ObjectTypes.Wizard;
    this.title = title;
    this.description = description;
    this.categories = categories;
    this.pages = pages?.map(p => new Page(p));
    this.refName = refName;
    this.contextTypeName = contextTypeName;
  }
}
