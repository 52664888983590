import React from 'react';
import { Button, Popover } from '@blueprintjs/core';

import { FormGroup } from '../../../form';
import { EXPRESSION_UNITS } from '../constants';
import { displayDay } from '../utilities';
import ListMenu from './list-menu';
import TableMenu from './table-menu';

import classes from '../cron-expression.module.scss';

const DayConfigItem = ({ day, weekday, onChange }) => {
  const text = displayDay(day, weekday);
  const handleChangeDay = day => {
    onChange({ day });
  };

  const handleChangeWeekday = weekday => {
    onChange({ weekday });
  };

  const content = (
    <div className={classes.popoverContent}>
      <ListMenu
        value={weekday}
        min={EXPRESSION_UNITS.WEEKDAY.min}
        max={EXPRESSION_UNITS.WEEKDAY.max}
        names={EXPRESSION_UNITS.WEEKDAY.names}
        alt={EXPRESSION_UNITS.WEEKDAY.alt}
        onChange={handleChangeWeekday}
      />
      <div className={classes.divider} />
      <div>
        <TableMenu
          value={day}
          selectAllBtnText={`Every ${EXPRESSION_UNITS.DAY.name}`}
          min={EXPRESSION_UNITS.DAY.min}
          max={EXPRESSION_UNITS.DAY.max}
          itemsPerRow={7}
          onChange={handleChangeDay}
        />
      </div>
    </div>
  );

  return (
    <FormGroup classes={{ wrapper: classes.configItemFormGroup }} label="Specific day (choose one or many)">
      <Popover fill placement="bottom" content={content}>
        <Button text={text} alignText="left" rightIcon="caret-down" minimal />
      </Popover>
    </FormGroup>
  );
};

export default DayConfigItem;
