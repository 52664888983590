import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';
import { Field } from 'formik';
import * as Yup from 'yup';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import useApi from '../../../../../../../hooks/use-api';
import { getTemplate, getWizardDocuments } from '../../../../../../../apis/wizards';

import Dialog from '../../../../../../dialog';
import Fields from '../../../../../../fields';
import Form from '../../../../../../form';
import Spinner from '../../../../../../spinner';

import { WizardContext } from '../../../../state';

import classes from './target-document.module.scss';

const TargetDocument = ({ name }) => {
  const { templateInfo } = useContext(WizardContext);
  const [open, setOpen] = React.useState(false);
  const getDocumentsApi = useApi(getWizardDocuments, { params: { templateId: templateInfo.id } });
  const getTemplateApi = useApi(getTemplate, { skip: true });
  const templateId = templateInfo.id;

  const documentMatchQuery = (record, inputValue) => {
    if (!inputValue) return true;

    const query = inputValue.toLowerCase();
    return (
      record?.data?.documentName?.toLowerCase().includes(query) ||
      record?.organization?.name?.toLowerCase().includes(query) ||
      (record.location?.prettyName || 'Global').toLowerCase().includes(query)
    );
  };

  return (
    <React.Fragment>
      <div className={classes.groupTitle}>Select document</div>
      <div className={classNames(classes.groupDescription, classes.botPad15)}>What is the user supposed to sign ? ie.: NDA, Contract ...</div>
      <Field name={name}>
        {({ form, field }) => {
          const submit = async (values, actions) => {
            const template = await getTemplateApi.request(values.document);
            if (template?.objectReferences === templateId) {
              void actions.setFieldError('document', 'Document has reference to the current wizard template');
            } else {
              await form.setFieldValue(name, values.document);
              setTimeout(() => void form.submitForm());
              setOpen(false);
            }
          };

          const targetDocument = field.value;
          const label = targetDocument ? getDocumentsApi.response?.find(d => d?.data?.templateId === targetDocument)?.data?.documentName : 'Choose document';

          return (
            <React.Fragment>
              <Button fill outlined text={label} alignText="left" icon="document" loading={getDocumentsApi.loading} onClick={() => setOpen(true)} />
              <Dialog title="Choose document" isOpen={open} onClose={() => setOpen(false)}>
                <Form
                  initialValues={{ query: '', document: targetDocument }}
                  validationSchema={Yup.object({
                    document: Yup.string().required('Document is required'),
                  })}
                  onSubmit={submit}
                >
                  {({ values, setFieldValue, errors }) => {
                    const documents = getDocumentsApi.response?.filter?.(record => documentMatchQuery(record, values?.query));
                    return (
                      <React.Fragment>
                        <Fields.Text name="query" label="Search" />
                        <div className={classes.documents}>
                          <SimpleBar className={classes.wrapper}>
                            <div className={classes.container}>
                              {getDocumentsApi.response?.loading ? (
                                <Spinner blend global={false} />
                              ) : !documents?.length ? (
                                <div className={classes.message}>
                                  <div className={classes.regular}>No documents found.</div>
                                </div>
                              ) : (
                                documents.map((record, i) => (
                                  <div
                                    className={classNames(classes.row, values?.document === record?.data?.templateId && classes.active)}
                                    onClick={() => setFieldValue('document', record.data?.templateId)}
                                    key={record.data?.templateId}
                                  >
                                    <div className={classes.controls}>
                                      {values?.document === record?.data?.templateId ? <Icons.CheckCircle className={classes.active} /> : <Icons.Circle />}
                                    </div>
                                    <div className={classes.info}>
                                      <div className={classes.name}>{record?.data?.documentName}</div>
                                      <div className={classes.meta}>
                                        <div className={classes.organization}>{record?.organization?.name}</div>
                                        <div className={classes.scope}>({record.location?.prettyName || 'Global'})</div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </SimpleBar>
                        </div>
                        <div className={classes.error}>
                          <small>{errors.document} &nbsp;</small>
                        </div>
                        <Button outlined fill text="Submit" type="submit" loading={getDocumentsApi.loading || getTemplateApi.loading} />
                      </React.Fragment>
                    );
                  }}
                </Form>
              </Dialog>
            </React.Fragment>
          );
        }}
      </Field>
    </React.Fragment>
  );
};

export default TargetDocument;
