import React from 'react';
import { Button, Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import * as Icons from 'react-feather';

import useApi from '../../hooks/use-api';
import { getTemplate } from '../../apis/wizards';
import { roleIdToFullRoleName } from '../../utilities/roles';

import classes from './flow-status.module.scss';

const FlowStepDocumentSignatures = ({ signatories, handleSignClick }) => {
  const target = signatories[0].target;
  const api = useApi(() => getTemplate(target));
  const signBtnOpts = signatories.find(s => s.showSignButton);
  return (
    <div className={classes.document}>
      <div className={classNames(classes.name, api?.loading && Classes.SKELETON)}>{api?.response?.name || 'Document'}</div>
      <div className={classes.signatories}>
        {signatories?.map?.(signatory => (
          <div className={classNames(classes.signatory, !!signatory?.signatureId && classes.signed)} key={signatory?.roleId}>
            <div className={classes.role}>{roleIdToFullRoleName(signatory?.roleId)}</div>
            <div className={classes.indicator}>
              {!!signatory?.signatureId ? <Icons.Check size={16} strokeWidth={2} /> : <Icons.X size={16} strokeWidth={1.5} />}
            </div>
          </div>
        ))}
      </div>
      {signBtnOpts?.target && (
        <React.Fragment>
          <Button outlined fill onClick={() => handleSignClick(signBtnOpts.target)} text="Sign" />
          <div className={classes.notice}>{`You are signing as following system tenant(s): ${signBtnOpts.roles
            ?.map(r => roleIdToFullRoleName(r))
            ?.join(', ')}`}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default FlowStepDocumentSignatures;
