import { Button } from '@blueprintjs/core';
import React from 'react';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';

import { getFullName } from '../../../../../../../../utilities/user';

import useApi from '../../../../../../../../hooks/use-api';
import { updateSupplier } from '../../../../../../../../apis/platform/suppliers';
import { getMembers } from '../../../../../../../../apis/members';

const CompanyInfoForm = ({ isOpen = false, supplier, onClose, onSave }) => {
  const updateSupplierApi = useApi(updateSupplier, { skip: true });
  const getMembersApi = useApi(getMembers, { skip: true });

  const submit = async values => {
    await updateSupplierApi.request(values);
    onSave();
  };

  return (
    <Dialog title="Personal Information" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          id: supplier?.id,
          name: supplier?.name || '',
          taxIdentifier: supplier?.taxIdentifier || '',
          incorporationType: supplier?.incorporationType || '',
          managerId: supplier?.managerId,
          phone: supplier?.phone || '',
          website: supplier?.website || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Business name is required'),
          incorporationType: Yup.string().required('Incorporation type is required'),
          taxIdentifier: Yup.string().required('Tax identifier is required'),
          managerId: Yup.string().required('Manager is required'),
          phone: Yup.string().required('Phone number is required'),
        })}
        onSubmit={submit}
      >
        <Fields.Text label="Business name" name="name" />
        <Fields.Select
          label="Manager"
          name="managerId"
          remoteOptions={{
            loading: getMembersApi.loading,
            request: (query = '') => getMembersApi.request({ filters: { organizationId: supplier?.id, includeParentMembers: true, query } }),
            mapper: result => result?.data?.map(m => ({ label: getFullName(m.user), value: m.id })),
            filter: 'remote',
          }}
        />
        <Fields.Text label="Tax identifier" name="taxIdentifier" />
        <Fields.Text label="Incorporation type" name="incorporationType" />
        <Fields.Text label="Phone" name="phone" />
        <Fields.Text label="Website" name="website" />
        <Button type="submit" fill outlined text="Update" disabled={updateSupplierApi.loading} loading={updateSupplierApi.loading} />
      </Form>
    </Dialog>
  );
};

export default CompanyInfoForm;
