import React, { useState } from 'react';
import moment from 'moment';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import NewEngagement from '../../../../../components/organizational/platform/new-engagement';
import { EmailDialog, useEmailDialog } from '../../../../../components/organizational/platform/email';

import { classificationTypeToLabel } from '../../../../../domain/classification-type';

import useTable from '../../../../../hooks/use-table';
import { filterSuppliers } from '../../../../../apis/platform/suppliers';

import SuppliersFilters from './filters';
import SuppliersMenu from './menu';

const Suppliers = () => {
  const [dialog, setDialog] = useState({ open: false, supplier: undefined });
  const { items, loading, filters, initialFilters, pageCount, page, setFilters, resetFilters, goToPage } = useTable(
    filterSuppliers,
    { pageSize: 10, controlled: true },
    { isActive: 'true' },
  );
  const emailDialog = useEmailDialog({ recipients: 'suppliers' });

  return (
    <Page title="Suppliers" path={[{ name: 'Suppliers', to: '/suppliers' }]}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          content={<SuppliersFilters />}
          menu={<SuppliersMenu openEmailDialog={emailDialog.handleOpen} />}
        />
        <Table.Columns.Default
          showOnMobile
          name="Supplier"
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} abbreviate name={d?.name} url={d?.icon} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.name, d => classificationTypeToLabel(d?.supplierType)]} />
        <Table.Columns.TwoLines name="Manager" lines={[d => d.managerName, d => d.managerEmail]} />
        <Table.Columns.Default name="Location" showOnMobile render={d => d?.address?.prettyName} />
        <Table.Columns.Default name="Joined On" showOnMobile width="100px" render={d => (!!d?.createdAt ? moment(d?.createdAt).format('LL') : '-')} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', href: d => `/suppliers/${d.id}` },
            { name: 'New Engagement', onClick: d => setDialog({ open: true, supplier: d }) },
            { divider: true },
            {
              name: 'Impersonate',
              disabled: d => !d?.managerEmail,
              href: d => `/authentication/impersonate/${Buffer.from(d?.managerEmail).toString('base64')}`,
              target: '_blank',
            },
            ...emailDialog.getTableActions({ emailRenderer: d => d.managerEmail }),
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <EmailDialog
        isMinimized={emailDialog.isMinimized}
        isOpen={emailDialog.isOpen}
        formValues={emailDialog.values}
        handleClose={emailDialog.handleClose}
        handleMinimize={emailDialog.handleMinimize}
      />
      <NewEngagement open={dialog?.open} onClose={() => setDialog({ open: false, supplier: undefined })} supplier={dialog?.supplier} />
    </Page>
  );
};

export default Suppliers;
