import React from 'react';
import { Button, Popover } from '@blueprintjs/core';

import { FormGroup } from '../../../form';
import { EXPRESSION_UNITS } from '../constants';
import { displayMonth } from '../utilities';
import ListMenu from './list-menu';

import classes from '../cron-expression.module.scss';

const MonthConfigItem = ({ month, onChange }) => {
  const text = displayMonth(month);

  const handleChangeMonth = month => {
    onChange({ month });
  };

  const content = (
    <div className={classes.popoverContent}>
      <ListMenu
        value={month}
        min={EXPRESSION_UNITS.MONTH.min}
        max={EXPRESSION_UNITS.MONTH.max / 2}
        names={EXPRESSION_UNITS.MONTH.names}
        alt={EXPRESSION_UNITS.MONTH.alt}
        onChange={handleChangeMonth}
      />
      <div className={classes.divider} />
      <ListMenu
        value={month}
        min={EXPRESSION_UNITS.MONTH.max / 2 + 1}
        max={EXPRESSION_UNITS.MONTH.max}
        names={EXPRESSION_UNITS.MONTH.names}
        alt={EXPRESSION_UNITS.MONTH.alt}
        onChange={handleChangeMonth}
      />
    </div>
  );

  return (
    <FormGroup classes={{ wrapper: classes.configItemFormGroup }} label="Specific month (choose one or many)">
      <Popover fill placement="bottom" content={content}>
        <Button text={text} alignText="left" rightIcon="caret-down" minimal />
      </Popover>
    </FormGroup>
  );
};

export default MonthConfigItem;
