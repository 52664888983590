import { ObjectTypes } from '../../../../../domain/types';
import Page from '../../../../../domain/page';
import Group from '../../../../../domain/group';
import { OperatorDefinitions } from '../../../../../domain/operator-definitions';
import { getFieldTypeProps } from '../../../../../state/template-initialization';

export const getEligibleFields = ({ template, selection, selectionPath, descriptor }) => {
  const [, page, group, field] = selectionPath || [];
  let pageIndex = 0;
  let groupIndex = 0;
  let fieldIndex = 0;
  let pages = [];
  const initialDataFields = descriptor.children;

  switch (selection?.type) {
    case ObjectTypes.Wizard:
      return [];

    // get all fields from pages up to this one.
    case ObjectTypes.Page:
      pageIndex = template?.pages?.map(p => p.id)?.indexOf(page?.id);
      pages = template?.pages?.filter((_, i) => i < pageIndex);
      break;

    // get all fields from pages and groups up to this one.
    case ObjectTypes.Group:
      pageIndex = template?.pages?.map(p => p.id)?.indexOf(page?.id);
      groupIndex = template?.pages?.[pageIndex]?.groups?.map(g => g.id)?.indexOf(group?.id);
      pages = template?.pages
        ?.filter((_, i) => i <= pageIndex)
        .map((p, pi) => new Page({ ...p, groups: p?.groups?.filter((_, j) => pi < pageIndex || j < groupIndex) }));
      break;

    // get all fields from pages, groups and within same group up to this one.
    default:
      pageIndex = template?.pages?.map(p => p.id)?.indexOf(page?.id);
      groupIndex = template?.pages?.[pageIndex]?.groups?.map(g => g.id)?.indexOf(group?.id);
      fieldIndex = template?.pages?.[pageIndex]?.groups?.[groupIndex]?.fields?.map(f => f.id)?.indexOf(field?.id);
      pages = template?.pages
        ?.filter((_, i) => i <= pageIndex)
        .map((p, pi) => {
          const groups = p?.groups
            ?.filter((_, gi) => gi <= groupIndex)
            .map((g, gi) => new Group({ ...g, fields: g?.fields?.filter((_, fi) => pi < pageIndex || gi < groupIndex || fi < fieldIndex) }));
          return new Page({ ...p, groups });
        });
      break;
  }

  let fields = {};

  pages?.forEach(page => {
    page.groups.forEach(group => {
      group.fields
        .filter(field => OperatorDefinitions[field.type])
        .forEach(field => {
          const value = `${page.id}.${group.id}.${field.id}`;
          fields[value] = field;
        });
    });
  });

  const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
  function addToMapInitialDataFields(data, mapper, parentInitialData) {
    data?.forEach(obj => {
      if (parentInitialData || obj.isInitialData) {
        const label = obj.name.replace(regExp, '$1$4 $2$3$5');
        if (obj.children.length) {
          mapper = addToMapInitialDataFields(obj.children, mapper, parentInitialData || obj.isInitialData);
        } else {
          const props = getFieldTypeProps(obj);
          mapper[obj.path] = { ...obj, ...props, label };
        }
      }
    });

    return mapper;
  }

  fields = addToMapInitialDataFields(initialDataFields, fields);

  template?.categories?.forEach(category => {
    fields[category.name] = {
      type: 'OT_NUMBER',
      label: `Category ${category.name}`,
      options: [],
    };
  });

  return { fields, hasFields: Object.keys(fields), pages, initialDataFields, categories: template.categories };
};

export function getRuleValue(rule) {
  if (!rule || !rule?.rules?.length) {
    return { simple: true, value: {} };
  }

  const [obj] = rule.rules;

  if (rule.rules.length > 1 || obj.rules !== undefined) {
    return { simple: false, value: rule };
  }

  return { simple: true, value: obj }; // return first rule as value
}
