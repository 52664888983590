import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

import Dialog from '../../../../dialog';
import Spinner from '../../../../spinner';

import useApi from '../../../../../hooks/use-api';
import { copyShifts } from '../../../../../apis/supplier/time';

import { QuickTimekeepingContext } from '../../state';

import { createNextShiftSession, getSegments } from '../../utilities';
import { DateFormat } from '../../../../../models/enumerations/date-format';

import Break from './break';
import DaySwitcher from './day-switcher';
import ShiftPreview from './shift-preview';
import Shift from '../forms/shift';

import classes from '../../quick.module.scss';

const DayDialog = () => {
  const [record, setRecord] = useState(undefined);
  const { loading, timeRecords, date, dialog, setDialog, changeDate, loadRecords, engagements, ...rest } = useContext(QuickTimekeepingContext);
  const copyShiftsApi = useApi(copyShifts, { skip: true });

  const dialogDate = moment(dialog.date).startOf('day');
  const dateStr = dialogDate.format(DateFormat.DEFAULT);
  const yesterdayStr = dialogDate.add(-1, 'day').format(DateFormat.DEFAULT);

  const records = getSegments(dateStr, timeRecords, { includeBreakSegments: true });
  const recordsFromYesterday = getSegments(yesterdayStr, timeRecords, { includeBreakSegments: false, includeEmptySegments: false });

  const copyFromYesterday = async () => {
    await copyShiftsApi.request(yesterdayStr, dateStr);
    await loadRecords();
  };

  const onChangeDate = newDate => {
    const d = moment(newDate);
    if (!d.isSame(date, 'isoWeek')) {
      changeDate(d);
    }
    setRecord(undefined);
    setDialog((prevState = {}) => ({ ...prevState, date: d }));
  };

  const onEdit = shift => {
    setRecord(shift);
  };

  const onSave = () => {
    setRecord(undefined);
    void loadRecords();
  };

  const onCancel = () => {
    setRecord(undefined);
  };
  const onClose = () => {
    setRecord(undefined);
    rest.closeDialog(records);
  };

  const addSession = () => {
    const newRecord = createNextShiftSession(dateStr, records);
    setRecord(newRecord);
  };

  const totalDuration = records?.reduce?.((acc, shift) => {
    if (shift.type !== 'break') {
      return acc + moment.duration(shift.duration).asMinutes();
    }
    return acc;
  }, 0);
  const total = moment.duration(totalDuration, 'minutes');
  const totalHours = `${total.asHours()?.toFixed(2)}h`;

  return (
    <Dialog isOpen={dialog?.open && dialog?.type === 'day'} title={dialog?.title} bodyClassName={classes.dialogOverride} onClose={onClose}>
      {record ? (
        <div className={classes.shiftForm}>
          <Shift data={record} engagements={engagements} onCancel={onCancel} onSave={onSave} />
        </div>
      ) : (
        <div className={classes.dayDialog}>
          <div className={classes.daySwitcher}>
            <DaySwitcher date={dateStr} onSubmit={values => onChangeDate(values.date)} />
          </div>

          {!!records?.length && (
            <SimpleBar className={classes.scrollbar}>
              <div className={classes.records}>
                {loading && (
                  <div className={classes.loading}>
                    <Spinner global={false} blend />
                  </div>
                )}
                {records?.map((record, i) =>
                  record?.type === 'break' ? (
                    <Break record={record} key={record.id} />
                  ) : (
                    <ShiftPreview shift={record} engagements={engagements} handleEdit={onEdit} loadRecords={loadRecords} key={record.id} />
                  ),
                )}
              </div>
            </SimpleBar>
          )}

          {total > 0 && (
            <div className={classes.totalContainer}>
              <span className={classes.totalLabel}>Total hours</span>
              <span className={classes.totalValue}>{totalHours}</span>
            </div>
          )}

          <div className={classes.actions}>
            <Button
              fill
              className={classes.copyPreviousDay}
              text="Copy previous day"
              loading={copyShiftsApi.loading}
              disabled={copyShiftsApi.loading || dialogDate.weekday() === 0 || !recordsFromYesterday?.length || total > 0}
              onClick={copyFromYesterday}
            />
            <Button className={classes.addSession} fill text={'Add session'} disabled={record?.id} onClick={addSession} />
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default DayDialog;
