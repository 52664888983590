import React, { useEffect, useState } from 'react';
import { ResizeSensor } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import * as Icons from 'react-feather';

import classes from './container.module.scss';

const TableContainer = ({ data, loading, children, showHeadings = true, handleScroll = false, dark = false, emptyMessage }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [bodyHeight, setHeight] = useState(0);

  const all = React.Children.toArray(children);

  let filters = all.find(c => c?.type?.definition === 'filters');
  if (!!filters && !!dark) {
    filters = React.cloneElement(filters, { dark });
  }

  let pagination = all.find(c => c?.type?.definition === 'pagination');
  if (!!pagination && !!dark) {
    pagination = React.cloneElement(pagination, { dark });
  }

  const setBodyHeight = () => {
    const total = document.body.clientHeight;
    const generalOffset = 155;
    const filterSize = filters ? 65 : 0;
    const headerSize = showHeadings ? 29 : 0;
    const paginationSize = pagination ? 35 : 0;
    setHeight(total - generalOffset - filterSize - headerSize - paginationSize);
  };

  const applyDimChanges = () => {
    setIsMobile(document.body.clientWidth < 800);
    setBodyHeight();
  };

  useEffect(() => {
    applyDimChanges();
  }, []);

  let columns = all.filter(c => c?.type?.definition === 'column');
  if (isMobile) {
    columns = columns.filter(c => c?.props?.showOnMobile);
  }

  const mapToColumns = row => columns.map(c => React.cloneElement(c, { row, loading }));
  const rowStyle = { gridTemplateColumns: columns.map(c => c?.props?.width || c?.type?.width).join(' ') };

  const renderBody = () => {
    if (loading) {
      // todo pass pageSize to table component and render only that amount of rows. We are using Table component to display
      // different number of rows:
      //  - dashboard: 5
      //  - table: 10
      const numberOfRows = Math.max(data?.length, 5); // this is a temporary solution
      return new Array(numberOfRows).fill({}).map?.((row, index) => (
        <div key={index} className={classNames(classes.row, classes.data, !!loading && classes.loading)} style={rowStyle}>
          {mapToColumns(row)}
        </div>
      ));
    }

    if (!data?.length) {
      return (
        <div className={classNames(classes.empty, !!dark && classes.dark)}>
          {!!emptyMessage ? (
            <div>{emptyMessage}</div>
          ) : (
            <React.Fragment>
              <Icons.Search strokeWidth={1.25} size={25} />
              <div>Nothing found. Clear filters or add some new records.</div>
            </React.Fragment>
          )}
        </div>
      );
    }

    const body = data?.map?.((row, index) => (
      <div key={index} className={classNames(classes.row, classes.data, !!loading && classes.loading)} style={rowStyle}>
        {mapToColumns(row)}
      </div>
    ));

    return handleScroll ? (
      <SimpleBar style={{ maxHeight: `${bodyHeight}px`, overflowX: 'hidden' }}>
        <div className={classes.wrapper}>{body}</div>
      </SimpleBar>
    ) : (
      <React.Fragment>{body}</React.Fragment>
    );
  };

  return (
    <ResizeSensor observeParents onResize={applyDimChanges}>
      <div className={classNames(classes.container, !!dark && classes.dark)}>
        {filters}
        <div className={classes.content}>
          {/* headings */}
          {showHeadings && (
            <div className={classNames(classes.row, classes.header)} style={rowStyle}>
              {columns.map((column, index) => (
                <div key={index}>{column?.props?.name}</div>
              ))}
            </div>
          )}
          {/* body */}
          {renderBody()}
        </div>
        {pagination && <div className={classes.pagination}>{pagination}</div>}
      </div>
    </ResizeSensor>
  );
};

export default TableContainer;
