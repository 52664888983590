import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

  useEffect(() => {
    const handleResize = debounce(() => setWindowSize({ width: window.innerWidth, height: window.innerHeight }), 25);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowSize, isMobile: windowSize.width <= 800 };
}

export default useWindowSize;
