import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import { SelectField, TextareaField } from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';
import useApi from '../../../../../../../../hooks/use-api';

import { updateEngagementClassification } from '../../../../../../../../apis/platform/engagements';
import { ClassificationType, ClassificationTypeOptions } from '../../../../../../../../domain/classification-type';
import { ClassificationPreferenceTypeOptions } from '../../../../../../../../domain/classification-preference-type';

const ClassificationDetailsForm = ({ isOpen = false, onClose, onSave, engagement }) => {
  const updateEngagementClassificationApi = useApi(updateEngagementClassification, { skip: true });

  const submit = async classification => {
    await updateEngagementClassificationApi.request({ engagementId: engagement.id, classification });
    onSave();
  };

  const loading = updateEngagementClassificationApi.loading;

  return (
    <Dialog title="Classification details" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          result: engagement?.classification?.result,
          clientPreference: engagement?.classification?.clientPreference,
          supplierPreference: engagement?.classification?.supplierPreference,
          elaboration: engagement?.classification?.elaboration || '',
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          result: Yup.string().required('Classification result is required'),
          clientPreference: Yup.string().required('Client preference result is required'),
          supplierPreference: Yup.string().required('Supplier preference result is required'),
          elaboration: Yup.string().required('Elaboration is required'),
        })}
        onSubmit={submit}
      >
        <SelectField label="Result" options={ClassificationTypeOptions} name="result" />
        <SelectField label="Client preference" options={ClassificationPreferenceTypeOptions} name="clientPreference" />
        <SelectField
          label="Supplier preference"
          options={ClassificationTypeOptions.filter(c => c.value !== ClassificationType.Unknown)}
          name="supplierPreference"
        />
        <TextareaField label="Elaboration" name="elaboration" />
        <Button type="submit" fill outlined text="Save" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default ClassificationDetailsForm;
