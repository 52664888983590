import React from 'react';
import { Button, Menu, MenuItem, Icon, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import _ from 'lodash';

import Audit from '../../../../components/audit';
import Table from '../../../../components/table';
import { getColor } from '../../../../utilities/common';
import { abbreviateClassification } from '../../../../domain/classification-type';

import { removeInvoiceItem } from '../../../../apis/platform/invoice';
import { invoiceItemTypeToLabel, isHourly } from '../../../../domain/billable-item-type';

import classes from './details.module.scss';

const InvoiceSupplier = ({ invoice, supplier, currency, setDialog, reloadList }) => {
  const onRemoveCharge = async d => {
    await removeInvoiceItem(d?.id);
    reloadList();
  };

  return (
    <div className={classes.item}>
      <div className={classes.bar}>
        <div className={classes.info}>
          <div className={classes.name}>{supplier?.supplierName}</div>
          <div className={classes.engagement}>
            <div className={classes.client} style={{ color: getColor(supplier?.clientName) }}>
              {supplier?.clientName}
            </div>
            <div>{supplier?.engagement?.title}</div>
            <div>{`(${abbreviateClassification(supplier?.engagement?.classification?.result)})`}</div>
          </div>
        </div>
        <div className={classes.actions}>
          <Popover
            content={
              <Menu>
                <MenuItem
                  text="Add"
                  label={<Icon icon="plus" />}
                  onClick={() =>
                    setDialog({
                      open: true,
                      initialValues: {
                        invoiceId: invoice?.invoiceId,
                        userId: supplier?.userId,
                        supplierId: supplier?.engagement?.supplierId,
                        engagementId: undefined,
                        type: undefined,
                        description: undefined,
                        amount: undefined,
                        quantity: undefined,
                        fee: undefined,
                      },
                    })
                  }
                />
              </Menu>
            }
          >
            <Button small outlined icon={<Icons.MoreVertical size={16} strokeWidth={1.5} />} />
          </Popover>
        </div>
      </div>
      <div className={classes.table}>
        <Table.Container data={_.sortBy(supplier?.items, ['type']).reverse()}>
          <Table.Columns.Default name="Type" showOnMobile render={d => invoiceItemTypeToLabel(d?.type)} />
          <Table.Columns.Default name="Description" width="2fr" render={d => d?.description} />
          <Table.Columns.Default name="Hours" render={renderHours} />
          <Table.Columns.Default
            name="Fee"
            width="100px"
            alt={d => d?.fee}
            render={d => `${currency?.symbol}${Number.parseFloat(`${d?.fee || 0}`).toFixed(2)}`}
          />
          <Table.Columns.Default
            name="Amount"
            width="100px"
            showOnMobile
            alt={d => d?.amount}
            render={d => `${currency?.symbol}${Number.parseFloat(`${d?.amount || 0}`).toFixed(2)}`}
          />
          <Table.Columns.Default showOnMobile width="36px" render={d => <Audit id={d?.id} type="billableItem" icon small outlined />} />;
          <Table.Columns.Actions
            showOnMobile
            actions={[
              {
                name: 'Edit',
                onClick: d =>
                  setDialog({
                    open: true,
                    initialValues: {
                      id: d?.id,
                      invoiceId: invoice?.invoiceId,
                      userId: supplier?.userId,
                      supplierId: supplier?.engagement?.supplierId,
                      engagementId: d?.engagementId,
                      type: d?.type,
                      description: d?.description,
                      amount: d?.amount,
                      quantity: d?.quantity,
                      fee: d?.fee,
                    },
                  }),
              },
              { divider: true },
              {
                name: 'Remove',
                onClick: onRemoveCharge,
                confirmation: { messages: { confirmButton: 'Remove' } },
              },
            ]}
          />
        </Table.Container>
      </div>
      <div className={classes.subTotal}>
        <div className={classes.label}>Total</div>
        <div className={classes.value} title={supplier?.total}>{`${currency?.symbol}${Number.parseFloat(`${supplier?.total || 0}`).toFixed(2)}`}</div>
      </div>
    </div>
  );
};

export default InvoiceSupplier;

const renderHours = d => isHourly(d?.type) && `${Number(d?.quantity).toFixed(2)}`;
