import React from 'react';
import { Drawer, Toaster } from '@blueprintjs/core';

import Configurators from '../../../../components/configurators';

import classes from './engagement-termination.module.scss';

const EngagementTermination = ({ engagement, open, onClose, initialize = false, onComplete }) => {
  return (
    <Drawer className={classes.engagementTermination} isOpen={open} onClose={onClose} position="right" canEscapeKeyClose={false} canOutsideClickClose={false}>
      {!!open && !!engagement?.id && (
        <Configurators.FlowLoader
          flowType={'engagement-termination'}
          referenceId={engagement.id}
          data={{ referenceId: engagement.id }}
          initialize={initialize}
          canContinue={!initialize}
          onCancel={onClose}
          onComplete={() => {
            Toaster.create({ position: 'top' }).show({ message: 'Engagement termination requested.', intent: 'success' });
            if (!!onComplete && typeof onComplete === 'function') {
              onComplete();
            }
            onClose();
          }}
        />
      )}
    </Drawer>
  );
};

export default EngagementTermination;
