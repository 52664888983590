import React from 'react';

import Box from '../../../box';
import Table from '../../../table';

import { capitalize } from '../../../../utilities/common';

import useApi from '../../../../hooks/use-api';
import { getAppliedConfiguration } from '../../../../apis/configuration';

import classes from './fees.module.scss';

const ClientFees = ({ clientId }) => {
  const getAppliedConfigurationApi = useApi(() => getAppliedConfiguration({ organizationId: clientId, type: 'Fees|ClientFees' }), { skip: !clientId });
  const records = getAppliedConfigurationApi?.response?.length
    ? getAppliedConfigurationApi?.response?.map?.(record => ({
        ...record,
        location: { ...record?.location, prettyName: record?.location?.prettyName || 'Global' },
        data: { ...record.data, fees: JSON.parse(record.data?.fees) },
      }))
    : [];

  const hide = [];
  for (const record of records) {
    if (record?.organizationId !== clientId && !!records.find(r => r.organizationId === clientId && record?.location?.path === r?.location?.path)) {
      hide.push(record?.recordId);
    }
  }

  const renderTarget = target => {
    switch (target) {
      case 'hours':
        return 'Applied to total calculated amount of logged hours.';

      case 'salaries':
        return 'Applied to total calculated amount of each salary.';

      case 'deliverables':
        return 'Applied to each deliverable.';

      case 'expenses':
        return 'Applied to each expense.';

      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      <Box title="Fees" outlined monochrome>
        <Box.ItemContainer>
          Here is a comprehensive breakdown of configured fees categorized by region, classification, and associated costs. These amounts will be utilized
          during the invoice processing stage, where only fees from the nearest geographic location to the supplier will be considered.
        </Box.ItemContainer>
      </Box>
      {records
        ?.filter?.(record => !hide.includes(record?.recordId) && !!record?.data?.fees?.length)
        ?.map?.((record, i) => {
          return (
            <Box title={record?.location?.prettyName} outlined monochrome key={record?.recordId} expandible={{ expanded: i === 0 }} small>
              <Box.ItemContainer className={classes.table}>
                <Table.Container data={record?.data?.fees} dark showHeadings={false} loading={getAppliedConfigurationApi.loading}>
                  <Table.Columns.Default showOnMobile render={d => capitalize(`${d?.classification?.split?.('-').join(' ')}s`)} />
                  <Table.Columns.Bold showOnMobile width="90px" render={d => `${d?.amount}${d?.feeType === 'percentage' ? '%' : ' (flat)'}`} />
                  <Table.Columns.Default showOnMobile width="2fr" render={d => renderTarget(d?.targetType)} />
                </Table.Container>
              </Box.ItemContainer>
            </Box>
          );
        })}
      {!getAppliedConfigurationApi?.loading && !records?.filter?.(record => !hide.includes(record?.recordId) && !!record?.data?.fees?.length)?.length && (
        <Box outlined monochrome>
          <Box.ItemContainer>No fees have been set up yet.</Box.ItemContainer>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ClientFees;
