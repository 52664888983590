import React from 'react';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';

import Box from '../../../box';
import { ObjectTypes } from '../../../configurators/wizards/domain/types';

import useApi from '../../../../hooks/use-api';
import { getWizardLookupData } from '../../../../apis/wizards';

import NotesPopover from '../notes-popover';

import classes from '../timeline-step.module.scss';

const resolveField = async (field, clientId) => {
  switch (field.type) {
    case ObjectTypes.Select: {
      if (!field.value) {
        return null;
      }
      const options = field?.lookup ? await getWizardLookupData(field?.lookup?.type, { clientId }) : field.options;
      const item = options?.find?.(obj => obj.value === field.value);
      return { text: item?.label || item?.value };
    }

    default:
      return { text: field?.value };
  }
};

const getQuestionClasses = field => {
  // only question field (field with category) has color otherwise return undefined
  if (!field.category) {
    return [];
  }

  if (field.value === null || field.value === undefined) {
    return [classes.answer];
  }

  const idx = field.options?.findIndex(obj => obj.value === field.value);
  const score = Number(field.score[idx] || 0);
  return [score >= 0 ? classes.positiveScore : classes.negativeScore, classes.answer];
};

const QuestionFieldContainer = ({ field, clientId, review, onComplete }) => {
  const api = useApi(() => resolveField(field, clientId));
  const questionClasses = getQuestionClasses(field);

  return (
    <Box.ItemContainer>
      <Box.ItemLabel className={classes.question}>{field.label}</Box.ItemLabel>
      <Box.ItemValue className={classNames(classes.fieldValue, ...questionClasses, api.loading && Classes.SKELETON)}>
        {api.response?.text || 'Not provided'}
      </Box.ItemValue>
      <NotesPopover fieldId={field.fullPath} review={review} onComplete={onComplete} />
    </Box.ItemContainer>
  );
};

export default QuestionFieldContainer;
