import React from 'react';
import SimpleBar from 'simplebar-react';

import PreviewContentItem from './preview-content-item';
import { getContentItemStyle } from '../../helpers/get-content-item-style';

import classes from './browser.module.scss';

const Browser = ({ content, footer, logo, customStyle, hovered }) => {
  const style = getContentItemStyle(customStyle);
  return (
    <div className={classes.browser}>
      <SimpleBar className={classes.wrapper} style={{ overflowX: 'hidden' }}>
        <div className={classes.header}>
          <div className={classes.logo} style={{ backgroundImage: `url("${logo}")` }} />
        </div>
        <div className={classes.content} style={style}>
          {content.map(contentItem => (
            <PreviewContentItem hovered={hovered} contentItem={contentItem} key={contentItem.id} />
          ))}
          <div className={classes.footer}>
            <table cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td width="80%" />
                  <td width="5%" />
                  <td width="5%" />
                </tr>
              </tbody>
            </table>
            <div className={classes.footerText}>{footer}</div>
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Browser;
