import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Menu, MenuDivider, MenuItem, Spinner } from '@blueprintjs/core';
import moment from 'moment';
import SimpleBar from 'simplebar-react';

import Fields from '../../../components/fields';

import { loadCurrencies } from '../../../state/lookups';

import classes from './invoices.module.scss';

const InvoiceFilters = ({ values, filterCounter, setFieldValue, submitForm, resetFilters, currencies, loadCurrencies }) => {
  const [currenciesLoading, setCurrenciesLoading] = useState(false);

  useEffect(() => {
    if (!currencies) {
      setCurrenciesLoading(true);
      loadCurrencies(() => setCurrenciesLoading(false));
    }
  }, []);

  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;

  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCounter}
        onClick={() => resetFilters()}
        text="Clear Filters"
        label={!!filterCounter ? <Icon icon="cross" /> : null}
      />
      <MenuDivider />
      <MenuItem
        className={classes.rangeFilter}
        text={
          <Fields.DateRange
            name="range"
            submitOnChange={true}
            showShortcuts={false}
            outline={false}
            minimal={true}
            showError={false}
            maxDate={values?.periodType === 'weekly' ? moment().endOf('isoWeek').toDate() : moment().endOf('month').toDate()}
          />
        }
        shouldDismissPopover={false}
      />
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Currency (${values?.currencyId || 'any'})`}
        disabled={!!currenciesLoading}
        icon={currenciesLoading ? <Spinner size={20} /> : undefined}
      >
        {!currenciesLoading && (
          <SimpleBar style={{ maxHeight: '35vh' }}>
            {currencies?.map(currency => (
              <MenuItem
                key={currency.id}
                shouldDismissPopover={false}
                onClick={() => {
                  setFieldValue('currencyId', values?.currencyId === currency?.id ? null : currency?.id);
                  submitForm();
                }}
                label={tick(values?.currencyId === currency.id)}
                text={`${currency?.id} (${currency?.symbol}) ${currency.name}`}
              />
            ))}
          </SimpleBar>
        )}
      </MenuItem>
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Classification (${!!values?.classification ? (values?.classification === 'employee' ? 'EE' : 'IC') : 'any'})`}
      >
        <MenuItem
          shouldDismissPopover={false}
          onClick={() => {
            setFieldValue('classification', values?.classification === 'employee' ? null : 'employee');
            submitForm();
          }}
          label={tick(values?.classification === 'employee')}
          text="Employees"
        />
        <MenuItem
          shouldDismissPopover={false}
          onClick={() => {
            setFieldValue('classification', values?.classification === 'independent-contractor' ? null : 'independent-contractor');
            submitForm();
          }}
          label={tick(values?.classification === 'independent-contractor')}
          text="Independent contractors"
        />
      </MenuItem>
      <MenuItem shouldDismissPopover={false} text={`Frequency (${values?.periodType || 'any'})`}>
        <MenuItem
          shouldDismissPopover={false}
          onClick={() => {
            setFieldValue('periodType', 'weekly');
            submitForm();
          }}
          label={tick(values?.periodType === 'weekly')}
          text="Weekly"
        />
        <MenuItem
          shouldDismissPopover={false}
          onClick={() => {
            setFieldValue('periodType', 'monthly');
            submitForm();
          }}
          label={tick(values?.periodType === 'monthly')}
          text="Monthly"
        />
      </MenuItem>
    </Menu>
  );
};

const mapStateToProps = state => ({
  currencies: state.lookups.currencies,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadCurrencies,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFilters);
