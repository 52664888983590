import React from 'react';
import { Field as FormikField } from 'formik';

import RichTextEditor from './rich-text-editor';

const RichTextField = ({
  classes,
  name,
  disabled,
  validate,
  submitOnChange = false,
  options,
  literals = true,
  modalProps,
  size,
  fill,
  blockRendererFn,
  onOptionSelect,
}) => {
  return (
    <FormikField name={name} validate={validate}>
      {({ field, form }) => (
        <RichTextEditor
          classes={classes}
          value={field.value || ''}
          options={options}
          literals={literals}
          modalProps={modalProps}
          size={size}
          onChange={html => {
            form.setFieldValue(name, html);

            if (!!submitOnChange) {
              void form.submitForm();
            }
          }}
          fill={fill}
          disabled={disabled}
          onOptionSelect={onOptionSelect}
          blockRendererFn={blockRendererFn}
        />
      )}
    </FormikField>
  );
};

export default RichTextField;
