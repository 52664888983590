import React from 'react';
import { Icon, Spinner } from '@blueprintjs/core';

import classes from './progress-message.module.scss';

const ProgressMessage = ({ title, description, value, complete }) => {
  if (complete) {
    return (
      <div className={classes.message}>
        <Icon size={20} className={classes.tick} icon="tick" />
        <div className={classes.info}>
          <div className={classes.title}>{title}</div>
          <div className={classes.description}>{description || 'Data successfully exported!'}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.message}>
      <Spinner className={classes.spinner} size={30} value={value / 100} />
      <div className={classes.info}>
        <div className={classes.title}>{title || 'Export complete'}</div>
        <div className={classes.description}>{description || 'Compiling requested data...'}</div>
      </div>
    </div>
  );
};

export default ProgressMessage;
