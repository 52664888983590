import { arrayMoveImmutable } from 'array-move';
import React from 'react';

export const WizardOrderContext = React.createContext({});

const WizardOrderProvider = ({ template, setTemplate, children }) => {
  const reorderPages = updates => {
    const { oldIndex, newIndex } = updates || {};
    const pages = template?.pages;
    if (!pages?.length) return;

    const newOrder = arrayMoveImmutable(pages, oldIndex, newIndex);
    setTemplate({ ...template, pages: newOrder });
  };

  const reorderGroups = (updates, pageId) => {
    const { oldIndex, newIndex } = updates || {};
    const groups = template?.pages?.find(p => p.id === pageId)?.groups;
    if (!groups?.length) return;

    const newOrder = arrayMoveImmutable(groups, oldIndex, newIndex);
    setTemplate({ ...template, pages: template.pages.map(p => (p.id === pageId ? { ...p, groups: newOrder } : p)) });
  };

  const reorderFields = (updates, pageId, groupId) => {
    const { oldIndex, newIndex } = updates || {};
    const groups = template?.pages?.find(p => p.id === pageId)?.groups;
    const fields = groups?.find(g => g.id === groupId)?.fields;
    if (!groups?.length) return;

    const newOrder = arrayMoveImmutable(fields, oldIndex, newIndex);
    setTemplate({
      ...template,
      pages: template.pages.map(p => (p.id === pageId ? { ...p, groups: p?.groups?.map(g => (g.id === groupId ? { ...g, fields: newOrder } : g)) } : p)),
    });
  };

  return <WizardOrderContext.Provider value={{ reorderPages, reorderGroups, reorderFields }}>{children}</WizardOrderContext.Provider>;
};

export default WizardOrderProvider;
