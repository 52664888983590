import { CONTEXTS_SET_TREES } from '../types';

const initialState = {
  trees: undefined,
};

const ContextsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTEXTS_SET_TREES:
      return { ...state, trees: action.data };

    default:
      return state;
  }
};

export default ContextsReducer;
