import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Spinner from '../components/spinner';
import { endSession } from './service';

const Logout = () => {
  useEffect(() => {
    void endSession();
  }, []);

  return <Spinner text="Logging out..." />;
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
