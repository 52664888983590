import { Button, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import useDocuments from '../../../../state/use-documents';
import WithConfirmation from '../../../../../../../hooks/with-confirmation';

import classes from './macro-item.module.scss';

const MacroControls = ({ macro }) => {
  const { setSelection, duplicate, remove } = useDocuments();
  return (
    <div className={classes.macroControls}>
      <ul>
        <li>
          <Button
            className={Classes.POPOVER_DISMISS}
            small
            outlined
            icon={<Icons.Edit size={16} strokeWidth={1.5} />}
            onClick={() => setSelection({ macro, action: 'edit' })}
          />
        </li>
        <li>
          <Button
            className={Classes.POPOVER_DISMISS}
            small
            outlined
            icon={<Icons.Copy size={16} strokeWidth={1.5} />}
            onClick={() => duplicate({ id: macro.id })}
          />
        </li>
        <li>
          <WithConfirmation
            captureDismiss={false}
            messages={{
              question: `Are you sure you want to remove macro?`,
            }}
          >
            <Button small outlined icon={<Icons.Trash size={16} strokeWidth={1.5} />} onClick={() => remove(macro)} />
          </WithConfirmation>
        </li>
      </ul>
    </div>
  );
};

export default MacroControls;
