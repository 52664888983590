export const fontSizeOptions = [
  {
    label: '14px',
    value: '14px',
  },
  {
    label: '16px',
    value: '16px',
  },
  {
    label: '18px',
    value: '18px',
  },
  {
    label: '24px',
    value: '24px',
  },
];

export const fontWeightOptions = [
  {
    label: 'Lighter',
    value: 'lighter',
  },
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Bold',
    value: 'bold',
  },
];

export const textDecorationOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Underline',
    value: 'underline',
  },
];

export const textAlignmentOptions = [
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Center',
    value: 'center',
  },
  {
    label: 'Right',
    value: 'right',
  },
];
