import React from 'react';
import moment from 'moment';

import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import Avatar from '../../../../../components/avatar';

import { engagementStatusToLabel, EngagementStatusType } from '../../../../../domain/engagement-status-type';
import { classificationTypeToLabel } from '../../../../../domain/classification-type';

import { getClientDisplayName } from '../../../../../utilities/client';
import { getLocationTypeName } from '../../../../../utilities/location';
import { downloadStringAsFile } from '../../../../../utilities/files';
import { uuid } from '../../../../../utilities/common';

import useTable from '../../../../../hooks/use-table';
import { getEngagements, exportEngagements } from '../../../../../apis/platform/engagements';
import { EmailDialog, useEmailDialog } from '../../../../../components/organizational/platform/email';
import toaster from '../../../../../components/toaster';

import EngagementsFilters from './filters';
import EngagementsMenu from './menu';

const List = () => {
  const { items, loading, filters, initialFilters, pageCount, page, goToPage, setFilters, resetFilters } = useTable(
    getEngagements,
    { pageSize: 10, controlled: true },
    { classificationType: '', status: EngagementStatusType.Active },
  );
  const emailDialog = useEmailDialog({ recipients: 'engagements' });

  const formatDates = d =>
    !!d?.startDate && !!d?.endDate
      ? `${moment(d?.startDate).format('DD/MM/YYYY')} - ${moment(d?.endDate).format('DD/MM/YYYY')}`
      : `${moment(d?.startDate).format('DD/MM/YYYY')} - Ongoing`;

  const onExport = () => {
    void toaster.export({
      progress: {
        title: 'Exporting engagements',
      },
      promise: () => exportEngagements({ filters }),
      onSuccess: csv => downloadStringAsFile(csv, `Engagements_${moment().format('YYYY-MM-DD')}_${uuid()}`, 'csv'),
    });
  };

  return (
    <Page title="Engagements" path={[{ name: 'Engagements', to: '/engagements' }]} scroll={false}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          content={<EngagementsFilters />}
          menu={<EngagementsMenu openEmailDialog={emailDialog.handleOpen} onExport={onExport} />}
        />
        <Table.Columns.Default
          showOnMobile
          name="Supplier"
          width="30px"
          render={d => <Avatar abbreviate size={{ box: 30, text: 12 }} url={d?.supplierIcon} name={d?.supplierName} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName, d => classificationTypeToLabel(d?.classification?.result)]} />
        <Table.Columns.TwoLines showOnMobile name="Engagement" lines={[d => d?.title, d => getClientDisplayName(d?.clientName, d?.parentClientName)]} />
        <Table.Columns.TwoLines name="Location" showOnMobile lines={[d => getLocationTypeName(d?.locationType), d => d?.location?.prettyName]} />
        <Table.Columns.TwoLines name="Duration" width="140px" lines={[d => engagementStatusToLabel(d?.status), d => formatDates(d)]} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', href: d => `/engagements/${d?.id}` },
            { divider: true },
            { name: 'View supplier', href: d => `/suppliers/${d.supplierId}` },
            { divider: true },
            {
              name: 'Impersonate',
              disabled: d => !d?.supplierManagerEmail,
              href: d => `/authentication/impersonate/${Buffer.from(d?.supplierManagerEmail).toString('base64')}`,
              target: '_blank',
            },
            ...emailDialog.getTableActions({ emailRenderer: d => d.supplierManagerEmail }),
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <EmailDialog
        isMinimized={emailDialog.isMinimized}
        isOpen={emailDialog.isOpen}
        formValues={emailDialog.values}
        handleClose={emailDialog.handleClose}
        handleMinimize={emailDialog.handleMinimize}
      />
    </Page>
  );
};

export default List;
