import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../components/dialog';
import Form from '../../../../../../components/form';
import Fields from '../../../../../../components/fields';

import useApi from '../../../../../../hooks/use-api';
import { setAddress } from '../../../../../../apis/client/organizations';

const AddressForm = ({ isOpen = false, onClose, api }) => {
  const setAddressApi = useApi(setAddress, { skip: true });

  const submit = async ({ address }, { setFieldError }) => {
    if (!address?.place_id) {
      setFieldError('address', 'Unable to resolve requested location.');
      return;
    }
    const clientId = api?.response?.id;
    if (!clientId) {
      setFieldError('address', 'Unable to resolve client identifier.');
      return;
    }

    await setAddress({ clientId, placeId: address.place_id });
    await api.request({ clientId });
    onClose();
  };

  const loading = api?.loading || setAddressApi?.loading;
  const address = api.response?.address ? JSON.parse(api.response?.address?.json) : null;

  return (
    <Dialog title="Address" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ address }} onSubmit={submit}>
        <Fields.Address fill name="address" label="Address" precision="address" />
        <Button type="submit" fill outlined text="Submit" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
