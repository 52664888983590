import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Audit from '../../../../components/audit';
import Box from '../../../../components/box';

import { genderStatusToLabel } from '../../../../domain/gender';
import { maritalStatusToLabel } from '../../../../domain/marital-status';
import { getDateOfBirth } from '../../../../utilities/user';

import PersonalInfoForm from './forms/personal-info-form';
import AddressForm from './forms/address-form';
import EmailAddressForm from './forms/email-address-form';
import EmergencyContactForm from './forms/emergency-contact-form';

import classes from '../profile.module.scss';

const General = ({ user }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const openDialog = type => setDialog({ open: true, type });
  const closeDialog = () => setDialog({ open: false, type: undefined });

  return (
    <div className={classes.tab}>
      <Box
        title="Personal Information"
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update personal information"
              onClick={() => openDialog('personal-info')}
            />
            <Audit id={user?.personalDetails?.id} type="personalDetails" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
      >
        <Box.Item label="First Name">{user?.personalDetails?.firstName}</Box.Item>
        <Box.Item label="Middle name(s)" notProvidedText="-">
          {user?.personalDetails?.middleName}
        </Box.Item>
        <Box.Item label="Last Name">{user?.personalDetails?.lastName}</Box.Item>
        <Box.Item label="Email address">
          {user?.email}
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update email address"
              disabled
              onClick={() => openDialog('email-address')}
            />
            {/* <Audit id={user?.id} type="user" icon small outlined /> */}
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Date of birth">{getDateOfBirth(user)}</Box.Item>
        <Box.Item label="Gender">{genderStatusToLabel(user?.personalDetails?.gender)}</Box.Item>
        <Box.Item label="Marital status">{maritalStatusToLabel(user?.personalDetails?.maritalStatus)}</Box.Item>
        <Box.Item label="Address">
          {user?.personalDetails?.address?.prettyName || 'Not provided'}
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update address" onClick={() => openDialog('address')} />
            <Audit id={user?.personalDetails?.addressId} type="address" icon small outlined />
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Phone">{user?.personalDetails?.phone}</Box.Item>
        <Box.Item label="National id number (ssn, nic, ...)">{user?.personalDetails?.nationalIdentificationNumber}</Box.Item>
        <Box.Item label="Ethnicity">{user?.personalDetails?.ethnicity}</Box.Item>
      </Box>
      <Box
        title="Emergency Contact"
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update Emergency contact"
              onClick={() => openDialog('emergency-contact')}
            />
            <Audit id={user?.emergencyContact?.id} type="emergencyContact" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
      >
        <Box.Item label="First name">{user?.emergencyContact?.firstName}</Box.Item>
        <Box.Item label="Last name">{user?.emergencyContact?.lastName}</Box.Item>
        <Box.Item label="Relationship">{user?.emergencyContact?.relationship}</Box.Item>
        <Box.Item label="Phone">{user?.emergencyContact?.phone}</Box.Item>
        <Box.Item label="Email address">{user?.emergencyContact?.email}</Box.Item>
      </Box>
      <PersonalInfoForm isOpen={!!dialog?.open && dialog?.type === 'personal-info'} onClose={closeDialog} />
      <AddressForm isOpen={!!dialog?.open && dialog?.type === 'address'} onClose={closeDialog} />
      <EmailAddressForm isOpen={!!dialog?.open && dialog?.type === 'email-address'} onClose={closeDialog} />
      <EmergencyContactForm isOpen={!!dialog?.open && dialog?.type === 'emergency-contact'} onClose={closeDialog} />
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(General);
