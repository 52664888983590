import AvailableCountries from './availability/available-countries';
import AvailableCurrencies from './availability/available-currencies';

import SystemPopulatedDocument from './documents/system-populated-document';
import PublicNotice from './documents/public-notice';

import ClientFees from './fees/client-fees';

import BackgroundCheckRequirements from './client-policies/background-check-requirements';
import IndependentContractorInsuranceRequirements from './client-policies/independent-contractor-insurance-requirements';
import SupplierClassificationPreference from './client-policies/supplier-classification-preference';

import EmailMessage from './messages/email-message';

import EngagementMetadata from './metadata/engagement-metadata';

import EmployeeOverTime from './over-time/employee-over-time';

import ScheduledRecurringTask from './tasks/scheduled-recurring-task';

import Theme from './ui/theme';

import EngagementFlow from './flows/engagement-flow';
import EngagementTerminationFlow from './flows/engagement-termination-flow';
import EngagementUpdatesFlow from './flows/engagement-updates-flow';

import WizardsSetup from './wizards/wizards-setup';

import BackgroundCheckProvider from './service-providers/background-check';
import PayrollProvider from './service-providers/payroll';
import PayoutProvider from './service-providers/payout';
import InvoiceProvider from './service-providers/invoice';
import LoadingRecord from '../loading-record';

export const getForm = (configurationType, props) => {
  if (props.loading) {
    return <LoadingRecord />;
  }

  const [group, type] = (configurationType || '').split('|');

  switch (group) {
    case 'Availability':
      return getAvailabilityComponent(type, props);

    case 'Fees':
      return getFeesComponent(type, props);

    case 'ClientPolicies':
      return getClientPoliciesComponent(type, props);

    case 'Overtime':
      return getOvertimeComponent(type, props);

    case 'Documents':
      return getDocumentsComponent(type, props);

    case 'ExternalMetadata':
      return getExternalMetadataComponent(type, props);

    case 'Messages':
      return getMessagesComponent(type, props);

    case 'Tasks':
      return getTasksComponent(type, props);

    case 'UserInterface':
      return getUserInterfaceComponent(type, props);

    case 'Flows':
      return getFlowsComponent(type, props);

    case 'ServiceProviders':
      return getServiceProvidersComponent(type, props);

    case 'Wizards':
      return getWizardsComponent(type, props);

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Availability
 */
const getAvailabilityComponent = (type, props) => {
  switch (type) {
    case 'AvailableCountries':
      return <AvailableCountries {...props} />;

    case 'AvailableCurrencies':
      return <AvailableCurrencies {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Fees
 */
const getFeesComponent = (type, props) => {
  switch (type) {
    case 'ClientFees':
      return <ClientFees {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Fees
 */
const getClientPoliciesComponent = (type, props) => {
  switch (type) {
    case 'BackgroundCheckRequirements':
      return <BackgroundCheckRequirements {...props} />;

    case 'IndependentContractorInsuranceRequirements':
      return <IndependentContractorInsuranceRequirements {...props} />;

    case 'SupplierClassificationPreference':
      return <SupplierClassificationPreference {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Overtime
 */
const getOvertimeComponent = (type, props) => {
  switch (type) {
    case 'EmployeeOvertime':
      return <EmployeeOverTime {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Metadata
 */
const getExternalMetadataComponent = (type, props) => {
  switch (type) {
    case 'EngagementMetadata':
      return <EngagementMetadata {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Documents
 */
const getDocumentsComponent = (type, props) => {
  switch (type) {
    case 'SystemPopulatedDocument':
      return <SystemPopulatedDocument {...props} />;

    case 'PublicNotice':
      return <PublicNotice {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Messages
 */
const getMessagesComponent = (type, props) => {
  const contextTypes = {
    PasswordChange: 'changepasswordobjectcontext',
    PasswordForgotten: 'forgotpasswordobjectcontext',

    AddMember: 'memberaddobjectcontext',
    InviteMember: 'memberinviteobjectcontext',
    RemoveMember: 'memberremoveobjectcontext',
    UpdateMember: 'memberupdateobjectcontext',

    ClientTimeRecordsApproval: 'trackingrecordsapprovalreminderobjectcontext',
    SupplierTimeRecordsReminder: 'suppliertimerecordsreminderobjectcontext',

    PlatformEngagementCreated: 'platformengagementcreatedobjectcontext',
    PlatformEngagementApproved: 'platformengagementapprovedobjectcontext',
    SupplierInvite: 'suppliersetupinviteobjectcontext',
    SupplierNewEngagement: 'suppliernewengagementobjectcontext',
    EngagementAwaitingSignature: 'engagementawaitingsignatureobjectcontext',

    SupplierEngagementSetPending: 'supplierengagementpendingobjectcontext',
    SupplierEngagementSetActive: 'supplierengagementactiveobjectcontext',
    SupplierEngagementSetComplete: 'supplierengagementcompleteobjectcontext',
    SupplierEngagementApproved: 'supplierengagementapprovedobjectcontext',
    SupplierEngagementCanceled: 'supplierengagementcanceledobjectcontext',

    ContractorSupplierClassificationApproved: 'independentcontractorsupplierclassificationapprovedobjectcontext',
    ContractorSupplierClassificationRejected: 'independentcontractorsupplierclassificationrejectedobjectcontext',
    ContractorEngagementClassificationApproved: 'independentcontractorengagementclassificationapprovedobjectcontext',
    ContractorEngagementClassificationRejected: 'independentcontractorengagementclassificationrejectedobjectcontext',

    ClientEngagementTerminationRequested: 'clientengagementterminationrequestedobjectcontext',
    SupplierEngagementTerminationRequested: 'supplierengagementterminationrequestedobjectcontext',
    PlatformEngagementTerminationRequested: 'platformengagementterminationrequestedobjectcontext',

    ClientEngagementUpdatesRequested: 'clientengagementupdaterequestedobjectcontext',
    SupplierEngagementUpdatesRequested: 'supplierengagementupdaterequestedobjectcontext',
    PlatformEngagementUpdatesRequested: 'platformengagementupdaterequestedobjectcontext',

    AdHoc: 'adhocemailobjectcontext',
  };

  if (!contextTypes[type]) return <div>Not supported.</div>;

  return <EmailMessage {...props} contextTypeName={contextTypes[type]} />;
};

/**
 * Tasks
 */
const getTasksComponent = (type, props) => {
  switch (type) {
    case 'AdministrativeFetchPayrollReportsCadence':
    case 'AdministrativeTerminateEngagementsCadence':
    case 'AdministrativeUpdateEngagementsCadence':
    case 'SubmitWeeklyTrackingRecords':
    case 'SubmitMonthlyTrackingRecords':
    case 'ApproveWeeklyTrackingRecords':
    case 'ApproveMonthlyTrackingRecords':
    case 'ClientWeeklyTimeApprovalReminder':
    case 'ClientMonthlyTimeApprovalReminder':
    case 'SupplierWeeklyTimeReminder':
    case 'SupplierMonthlyTimeReminder':
      return <ScheduledRecurringTask {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * User Interface
 */
const getUserInterfaceComponent = (type, props) => {
  switch (type) {
    case 'Theme':
      return <Theme {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Flows
 */
const getFlowsComponent = (type, props) => {
  switch (type) {
    case 'EngagementFlow':
      return <EngagementFlow {...props} />;

    case 'EngagementTerminationFlow':
      return <EngagementTerminationFlow {...props} />;

    case 'EngagementUpdatesFlow':
      return <EngagementUpdatesFlow {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Service Providers
 */
const getServiceProvidersComponent = (type, props) => {
  switch (type) {
    case 'BackgroundCheck':
      return <BackgroundCheckProvider {...props} />;

    case 'Payroll':
      return <PayrollProvider {...props} />;

    case 'Payout':
      return <PayoutProvider {...props} />;

    case 'Invoice':
      return <InvoiceProvider {...props} />;

    default:
      return <div>Not supported.</div>;
  }
};

/**
 * Wizards
 */
const getWizardsComponent = (type, props) => {
  const contextTypes = {
    SupplierInvite: 'supplierinviteobjectcontext',
    SupplierInviteResponse: 'supplierinviteresponseobjectcontext',
    SupplierBackgroundCheck: 'supplierbackgroundcheckobjectcontext',
    EmployeeIntro: 'employeeintroobjectcontext',
    EmployeeCountryTaxInfo: 'employeeengagementcountrytaxinfoobjectcontext',
    EmployeeRegionTaxInfo: 'employeeengagementregiontaxinfoobjectcontext',
    EmployeeEngagementOnboarding: 'employeeengagementonboardingobjectcontext',
    EmployeeDocumentSigning: 'employeedocumentsigningobjectcontext',
    IndependentContractorIntro: 'independentcontractorintroobjectcontext',
    IndependentContractorDocumentVerification: 'independentcontractordocumentverificationobjectcontext',
    IndependentContractorSupplierClassification: 'independentcontractorsupplierclassificationobjectcontext',
    IndependentContractorEngagementClassification: 'independentcontractorengagementclassificationobjectcontext',
    IndependentContractorEngagementOnboarding: 'independentcontractorengagementonboardingobjectcontext',
    IndependentContractorDocumentSigning: 'independentcontractordocumentsigningobjectcontext',
    RequestEngagementTermination: 'requestengagementterminationobjectcontext',
    ClientEngagementTerminationSurvey: 'clientengagementterminationsurveyobjectcontext',
    SupplierEngagementTerminationSurvey: 'supplierengagementterminationsurveyobjectcontext',
    RequestEngagementUpdates: 'engagementupdaterequestobjectcontext',
    SupplierEngagementUpdateResponse: 'supplierengagementupdateresponseobjectcontext',
  };

  if (!contextTypes[type]) return <div>Not supported.</div>;

  return <WizardsSetup {...props} contextTypeName={contextTypes[type]} />;
};
