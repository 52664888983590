export const parseTemplate = (template, values) => {
  const categoryMap = {};
  const pages = template?.pages?.reduce((arr, page) => {
    const groups = page.groups?.reduce((arr, group) => {
      const fields = [];
      group.fields?.forEach(field => {
        const fullPath = `${page.id}.${group.id}.${field.id}`;
        field.value = values[page.id]?.[group.id]?.[field.id];
        field.fullPath = fullPath;
        if (field.category) {
          if (!categoryMap[field.category]) {
            categoryMap[field.category] = [];
          }
          categoryMap[field.category].push(field);
        } else {
          fields.push(field);
        }
      });

      if (fields.length) {
        arr.push({ ...group, fields });
      }
      return arr;
    }, []);

    if (groups.length) {
      arr.push({ ...page, groups });
    }

    return arr;
  }, []);

  const sections = template?.categories?.map(category => ({
    id: category.name,
    title: category.name,
    color: category.color,
    questions: categoryMap[category.name],
  }));

  return { ...template, pages, sections };
};

export const getInsightsMapper = review => {
  const insights = review?.insights?.reduce?.((mapper, insight) => {
    const id = insight.field || insight.category || 'global';
    let page;
    if (insight.field) {
      [page] = insight.field.split('.');
    }

    if (!mapper[page]) {
      mapper[page] = [];
    }
    mapper[page].push(insight);

    if (!mapper[id]) {
      mapper[id] = [];
    }
    mapper[id].push(insight);
    return mapper;
  }, {});

  return insights;
};
