import React from 'react';
import Box from '../../../components/box';

import Page from '../../../components/page';

import RevenueChart from './revenue-charts';
import WorkerCharts from './worker-charts';
import ClientCharts from './client-charts';

import classes from './reporting.module.scss';

const Reporting = () => {
  return (
    <Page title="Reporting" path={[{ name: 'Reporting', to: '/reporting' }]}>
      <div className={classes.reporting}>
        <div className={classes.line}>
          <Box className={classes.box} contentClassName={classes.boxContent} title="Supplier breakdown">
            <WorkerCharts />
          </Box>
          <Box className={classes.box} contentClassName={classes.boxContent} title="Revenue breakdown">
            <RevenueChart />
          </Box>
        </div>
        <div className={classes.line}>
          <Box className={classes.box} contentClassName={classes.boxContent} title="Client suppliers">
            <ClientCharts />
          </Box>
        </div>
      </div>
    </Page>
  );
};

export default Reporting;
