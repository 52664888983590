import React from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../form';
import Fields from '../../../../fields';

import { CompensationTypeOptions } from '../../../../../domain/compensation-type';
import { CompensationFrequencyOptions } from '../../../../../domain/compensation-frequency';
import { mapCompensationTypeToPaymentLabel } from '../../../../../utilities/engagement';

import useApi from '../../../../../hooks/use-api';
import { updateEngagementUpdateRequest } from '../../../../../apis/platform/engagements';
import { getCurrencies } from '../../../../../apis/lookups';

import classes from './updates.module.scss';

const UpdatesForm = ({ getUpdatesApi, onComplete = null }) => {
  const currenciesApi = useApi(getCurrencies, { skip: true });
  const updateRequestApi = useApi(updateEngagementUpdateRequest, { skip: true });

  const submit = async values => {
    await updateRequestApi?.request(values);
    await getUpdatesApi?.request({ engagementId: values?.engagementId });

    if (!!onComplete && typeof onComplete === 'function') {
      onComplete();
    }
  };

  const loading = getUpdatesApi?.loading || updateRequestApi?.loading;

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        engagementId: getUpdatesApi?.response?.engagementId,
        updateDate: getUpdatesApi?.response?.data?.updateDate || '',
        title: getUpdatesApi?.response?.data?.title || '',
        description: getUpdatesApi?.response?.data?.description || '',
        endDate: getUpdatesApi?.response?.data?.endDate || '',
        frequency: getUpdatesApi?.response?.data?.frequency || '',
        type: getUpdatesApi?.response?.data?.type || '',
        currencyId: getUpdatesApi?.response?.data?.currencyId || '',
        amount: getUpdatesApi?.response?.data?.amount,
        allocation: getUpdatesApi?.response?.data?.allocation,
        tracksWork: getUpdatesApi?.response?.data?.tracksWork,
      }}
    >
      {form => (
        <div className={classes.form}>
          <Fields.Date label="Update date" name="updateDate" />
          <div className={classes.general}>
            <div className={classes.title}>General</div>
            <Fields.Text label="Title" name="title" />
            <Fields.Text label="Description" name="description" />
            <Fields.Date label="End date" name="endDate" />
          </div>
          <div className={classes.compensation}>
            <div className={classes.title}>Compensation</div>
            <Fields.Select label="Frequency" options={CompensationFrequencyOptions} name="frequency" />
            <Fields.Select label="Type" options={CompensationTypeOptions} name="type" />
            <Fields.Text label="Allocation" extra={{ rightElement: <Button text="h/week" minimal small disabled /> }} type="number" name="allocation" />
            <Fields.Select
              label="Currency"
              name="currencyId"
              remoteOptions={{
                loading: currenciesApi.loading,
                request: () => currenciesApi.request(),
                mapper: result => result,
                filter: 'local',
              }}
            />
            <Fields.Text
              label="Amount"
              extra={{
                rightElement: <Button text={mapCompensationTypeToPaymentLabel(form?.values?.type, true)} minimal small disabled />,
              }}
              placeholder="Amount"
              type="number"
              name="amount"
            />
            <Fields.Select
              label="Tracks work ?"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              name="tracksWork"
            />
          </div>
          <Button fill outlined type="submit" text="Submit" disabled={loading} />
        </div>
      )}
    </Form>
  );
};
export default UpdatesForm;
