import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';

export const getEngagements = async () => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement`);
  return response?.data;
};

export const getEngagementTermination = async ({ engagementId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/termination`);
  return response?.data;
};

export const getEngagementUpdate = async ({ engagementId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/updates`);
  return response?.data;
};
