import React, { useEffect } from 'react';

import useApi from '../../../../../../../hooks/use-api';
import { getWizardLookupData } from '../../../../../../../apis/wizards';
import Fields from '../../../../../../fields';
import CreateItemDialog from './create-item-dialog';

import styles from './select-renderer.module.scss';

const SelectRenderer = ({ name, field, data, isDesigner, formik }) => {
  const { lookup, options } = field;
  const lookupApi = useApi(getWizardLookupData, { skip: true });
  const [key, setKey] = React.useState(0); // this is a hack to force the select to re-render when a new item is created

  const getOptions = result => {
    if (!options) {
      return result;
    }
    const optionsMapper = options?.reduce((mapper, option) => {
      mapper[option.value] = option.label;
      return mapper;
    }, {});
    return result.map(r => ({
      label: optionsMapper[r.value] || r.label,
      value: r.value,
    }));
  };

  useEffect(() => {
    if (lookupApi?.response) {
      const options = getOptions(lookupApi?.response);
      if (options?.length === 1 && field?.validation?.required) {
        formik?.setFieldValue(name, options?.[0]?.value);
      }
    }
  }, [lookupApi?.response]);

  const lookupProps = {
    remoteOptions: {
      loading: lookupApi.loading,
      request: () => lookupApi.request(lookup?.type, data),
      mapper: getOptions,
      filter: 'local',
    },
  };

  const props = { ...field, name, label: field?.showLabel ? field.label : '', description: !!field?.showDescriptionAsTooltip ? '' : field?.description };
  const selectProps = !!lookup?.type && !isDesigner ? { ...props, ...lookupProps } : { ...props, options: props?.options || [] };
  return (
    <div className={styles.selectRenderer}>
      <Fields.Select {...selectProps} key={key} />
      {!!lookup?.create?.length && <CreateItemDialog field={field} data={data} onSuccess={setKey} />}
    </div>
  );
};

export default SelectRenderer;
