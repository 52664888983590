import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import { SelectField } from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';
import useApi from '../../../../../../../../hooks/use-api';

import { updateEngagementDepartment } from '../../../../../../../../apis/platform/engagements';
import { getDepartments } from '../../../../../../../../apis/platform/departments';

const DepartmentForm = ({ isOpen = false, onClose, onSave, engagement }) => {
  const getClientDepartmentsApi = useApi(getDepartments, { skip: true });
  const updateEngagementDepartmentApi = useApi(updateEngagementDepartment, { skip: true });

  const submit = async ({ departmentId }) => {
    await updateEngagementDepartmentApi.request({ engagementId: engagement.id, departmentId });
    onSave();
  };

  const loading = updateEngagementDepartmentApi.loading;

  return (
    <Dialog title="Department details update" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          departmentId: engagement?.departmentId,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          departmentId: Yup.string().required('Department is required'),
        })}
        onSubmit={submit}
      >
        <SelectField
          label="Department"
          name="departmentId"
          remoteOptions={{
            loading: getClientDepartmentsApi.loading,
            request: () => getClientDepartmentsApi.request({ clientId: engagement?.clientId }),
            mapper: result =>
              result?.data?.map(d => ({
                label: d.name,
                value: d.id,
              })),
            filter: 'local',
          }}
        />
        <Button type="submit" fill outlined text="Save" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default DepartmentForm;
