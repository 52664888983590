import React from 'react';
import { Button, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import IMask from 'imask';

import Fields from '../../../../../../../fields';

import { ObjectTypes } from '../../../../../domain/types';
import { YesNoOptions } from '../../../../../domain/validation';
import { maxDateOptions, minDateOptions } from '../../../../../domain/validation/date-validation';

import DateValidation from './date-validation';

import classes from '../../../properties.module.scss';

export const TypeSpecificValidations = ({ form, template }) => {
  switch (form?.values?.type) {
    case ObjectTypes.Text:
      return (
        <React.Fragment>
          <Fields.Select
            label="Required"
            name="validation.required"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
          />
          <Fields.Select
            label="Email"
            name="validation.email"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
          />
          <Fields.Text
            label="Mask"
            name="validation.mask"
            submitOnChange={true}
            extra={{
              rightElement: (
                <Popover
                  popoverClassName={classes.maskHelpPopover}
                  content={
                    <div className={classes.helpContainer}>
                      <div>
                        <strong>Format</strong> input in a specific format. Here are some examples.
                      </div>
                      <table className="bp5-html-table bp5-html-table-bordered">
                        <thead>
                          <tr>
                            <th />
                            <th>Mask value</th>
                            <th>Example</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b>SSN</b>
                            </td>
                            <td>000-00-0000</td>
                            <td>123-321-3139</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Zip</b>
                            </td>
                            <td>00000</td>
                            <td>54223</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Card Number</b>
                            </td>
                            <td>0000-0000-0000-0000</td>
                            <td>4111-1111-1111-1111</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Invoice ID</b>
                            </td>
                            <td>INV-000-aaaaaaaaaaaaaaaaaaa</td>
                            <td>INV-001-greenlight</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Passport number</b>
                            </td>
                            <td>000000000</td>
                            <td>123456789</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  }
                >
                  <Button icon={<Icons.HelpCircle size={17} strokeWidth={2.1} />} outlined small />
                </Popover>
              ),
            }}
          />
          <div className={classes.row}>
            <Fields.Text label="Min length" name="validation.min" type="number" submitOnChange={true} />
            <Fields.Text label="Max length" name="validation.max" type="number" submitOnChange={true} />
          </div>
          <Fields.Text label="Regex Expression" name="validation.regex" submitOnChange={true} showError={false} />
        </React.Fragment>
      );

    case ObjectTypes.Number:
      return (
        <React.Fragment>
          <Fields.Select
            label="Required"
            name="validation.required"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
          />
          <div className={classes.row}>
            <Fields.Text label="Min value" name="validation.min" type="number" submitOnChange={true} showError={false} />
            <Fields.Text label="Max value" name="validation.max" type="number" submitOnChange={true} showError={false} />
          </div>
        </React.Fragment>
      );

    case ObjectTypes.Date:
    case ObjectTypes.DateRange:
      const targetFieldsOptions = [];
      template.pages?.forEach(p => {
        p.groups?.forEach(g => {
          g.fields?.forEach(f => {
            if (f.type === ObjectTypes.Date && f.id !== form.values?.id) {
              targetFieldsOptions.push({ label: f.label, value: `${p.id}.${g.id}.${f.id}` });
            }
          });
        });
      });

      return (
        <React.Fragment>
          <Fields.Select
            label="Required"
            name="validation.required"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
          />
          <DateValidation label="Minimum date" name="validation.min" relativeOptions={minDateOptions} targetFieldsOptions={targetFieldsOptions} />
          <DateValidation label="Maximum date" name="validation.max" relativeOptions={maxDateOptions} targetFieldsOptions={targetFieldsOptions} />
          <Fields.CronConfig label="Cadence" name="validation.cadence" submitOnChange={true} />
        </React.Fragment>
      );

    case ObjectTypes.Time:
      return (
        <React.Fragment>
          <Fields.Select
            label="Required"
            name="validation.required"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
          />
          <div className={classes.row}>
            <Fields.Text
              label="Minimum time"
              name="validation.min"
              maskConfig={{
                mask: 'HH:00',
                lazy: false,
                blocks: {
                  HH: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23,
                  },
                },
              }}
              submitOnChange={true}
              showError={false}
            />
            <Fields.Text
              label="Maximum time"
              name="validation.max"
              maskConfig={{
                mask: 'HH:59',
                lazy: false,
                blocks: {
                  HH: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23,
                  },
                },
              }}
              submitOnChange={true}
              showError={false}
            />
          </div>
        </React.Fragment>
      );

    case ObjectTypes.Phone:
      return (
        <React.Fragment>
          <Fields.Select
            label="Required"
            name="validation.required"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
          />
          <Fields.Text label="Phone mask" name="validation.mask" submitOnChange={true} showError={false} />
        </React.Fragment>
      );

    default:
      return (
        <React.Fragment>
          <Fields.Select
            label="Required"
            name="validation.required"
            submitOnChange={true}
            options={YesNoOptions}
            disabled={!!form?.values?.mandatory && form?.values?.initiallyRequired === true}
            showError={false}
          />
        </React.Fragment>
      );
  }
};
