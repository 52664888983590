import React from 'react';
import moment from 'moment/moment';
import { Button, Tooltip } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import Box from '../../../../../../components/box';
import Table from '../../../../../../components/table';
import { getGroupStatus } from '../../utilities';
import { getTotalAmount } from '../../../../../../utilities/time';
import { TrackingRecordStatusTypes, trackingRecordStatusToLabel } from '../../../../../../domain/tracking-record-status-type';
import { downloadDocument } from '../../../../../../apis/documents';
import Comments from '../../../../../../components/time/comments';

import HeaderActions from './actions';
import EngagementInfo from './engagement-info';

import classes from './boxes.module.scss';

const Expenses = ({ supplier, expenses, updateStatus }) => {
  return (
    <Box
      className={classes.box}
      title="Expenses"
      actions={
        <HeaderActions
          period={supplier?.period}
          items={expenses}
          status={getGroupStatus(expenses)}
          total={getTotalAmount(expenses, supplier?.currencySymbol)}
          updateStatus={updateStatus}
        />
      }
      headerClassName={classes.boxHeader}
      contentClassName={classes.container}
      outlined
      monochrome
      expandible={{ expanded: false }}
    >
      <Box.ItemContainer className={classNames(classes.table, classes.tablePad)}>
        <Table.Container data={expenses} dark showHeadings={false} emptyMessage="No expenses recorded.">
          <Table.Columns.TwoLines
            name="Engagement"
            width="2fr"
            lines={[d => <EngagementInfo engagement={d} />, d => (d?.parentClient ? `${d?.client} via ${d.parentClient}` : d?.client)]}
          />
          <Table.Columns.TwoLines showOnMobile name="Date" lines={[d => moment(d?.date).format('MMMM Do'), d => moment(d?.date).format('YYYY')]} />
          <Table.Columns.Default width="2fr" name="Notes" render={d => d?.notes} alt={d => d?.notes} />
          <Table.Columns.Bold showOnMobile width="90px" name="Amount" render={d => `${supplier?.currencySymbol}${d?.amount?.toFixed(2)}`} />
          <Table.Columns.Bold name="Status" width="100px" render={d => trackingRecordStatusToLabel(d?.status)} />
          <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />;
          <Table.Columns.Default
            showOnMobile
            width="32px"
            render={d => (
              <Tooltip disabled={!d?.receiptId} content="Download Receipt">
                <Button
                  disabled={!d?.receiptId}
                  outlined
                  small
                  icon={<Icons.Paperclip size={16} strokeWidth={1.25} />}
                  onClick={() => {
                    if (d?.receiptId) {
                      void downloadDocument({ id: d?.receiptId, name: `Receipt-${d?.receiptId}` });
                    }
                  }}
                />
              </Tooltip>
            )}
          />
          <Table.Columns.Actions
            showOnMobile
            actions={[
              {
                name: 'Approve',
                onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: [d?.id] }),
                confirmation: { messages: { confirmButton: 'Approve' } },
              },
              {
                name: 'Dispute',
                onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: [d?.id] }),
                confirmation: { messages: { confirmButton: 'Dispute' } },
              },
            ]}
          />
        </Table.Container>
      </Box.ItemContainer>
    </Box>
  );
};

export default Expenses;
