import Clients from '../configuration/client';

export const sign = async target => {
  const response = await Clients.Api.post(`ui/signature/${target}`);
  return response?.data;
};

export const getSignature = async signatureId => {
  const response = await Clients.Api.get(`ui/signature/${signatureId}`);
  return response?.data;
};

export const getSignedDocument = async ({ target, instanceId }) => {
  const response = await Clients.Api.get(`ui/signature/${target}/${instanceId}/document`);
  return response?.data;
};
