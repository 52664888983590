import React from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import SetupColorsDialog from './setup-colors-dialog';
import { capitalize } from '../../../../../utilities/common';

import classes from '../appearance.module.scss';

const regExp = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;

const Colors = ({ theme, handleSave, className }) => {
  const [dialog, setDialog] = React.useState({ isOpen: false });

  const colors = {
    primaryColor: theme?.primaryColor || getComputedStyle(document.body).getPropertyValue('--primary'),
  };

  return (
    <React.Fragment>
      <Box
        title="Colors"
        outlined
        monochrome
        loading={false}
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Settings size={16} strokeWidth={1.5} />}
              title="Setup color"
              onClick={() => {
                setDialog({ isOpen: true });
              }}
            />
          </ButtonGroup>
        }
        className={className}
      >
        <div className={classes.boxContent}>
          <div className={classes.colorsList}>
            {Object.keys(colors).map(color => (
              <div className={classes.colorRow} key={color}>
                <div className={classes.colorBox} style={{ backgroundColor: colors[color] }} />
                <div>
                  <strong>{capitalize(color?.replace(regExp, '$1$4 $2$3$5'))}</strong>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Box>
      <SetupColorsDialog isOpen={dialog.isOpen} colors={colors} onClose={() => setDialog({ isOpen: false })} handleSubmit={handleSave} />
    </React.Fragment>
  );
};
export default Colors;
