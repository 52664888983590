import React from 'react';
import classNames from 'classnames';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from './draftjs-to-html';

import RichTextContextMenu from './context-menu';
import RichTextFieldModal from './rich-text-modal';
import LinkOverride from './link-override';

import styles from './rich-text.module.scss';

function getEditorState(value) {
  const contentBlock = htmlToDraft(value);
  if (!contentBlock) {
    return EditorState.createEmpty();
  }
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
}

const RichTextEditor = ({ classes, value, options, disabled, literals, modalProps, size, fill, onChange, onOptionSelect, blockRendererFn }) => {
  const [editorState, setEditorState] = React.useState(getEditorState(value));

  const props = {
    editorState,
    blockRendererFn,
    toolbarClassName: classNames('toolbarClassName', styles.toolbar, classes?.toolbarClassName),
    wrapperClassName: classNames('wrapperClassName', styles.wrapper, classes?.wrapperClassName),
    editorClassName: classNames('editorClassName', styles.editor, classes?.editorClassName),
    // toolbarClassName: classNames('toolbarClassName', styles.toolbar, classes?.toolbarClassName),
    // wrapperClassName: classNames('wrapperClassName', styles.wrapper, classes?.wrapperClassName),
    // editorClassName: classNames(
    //   'editorClassName',
    //   styles.editor,
    //   modalProps && styles.modalEditor,
    //   size === 'sm' && styles.editorSmall,
    //   fill && styles.editorFull,
    //   classes?.editorClassName,
    // ),
    stripPastedStyles: true,
    readOnly: disabled,
    toolbar: {
      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'list', 'textAlign', 'link'],
      link: {
        component: LinkOverride,
        defaultTargetOption: '_blank',
      },
    },
  };

  const onSelect = macro => {
    const textToInsert = literals ? `\${${macro.name}}` : `${macro.name}`;
    let newEditorState;
    if (onOptionSelect) {
      newEditorState = onOptionSelect({ textToInsert, macro, editorState, draftToHtml });
      setEditorState(newEditorState);
      return;
    }
    // If onOptionSelect returns a new editorState, use it instead of creating a new one
    if (!newEditorState) {
      const contentState = Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), textToInsert, editorState.getCurrentInlineStyle());
      newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
    }
    setEditorState(newEditorState);
    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    onChange(html);
  };

  if (modalProps) {
    return (
      <RichTextFieldModal
        title={modalProps?.title}
        buttonProps={modalProps?.buttonProps}
        onSubmit={() => {
          const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
          onChange(html); // this is immediate update formik field value
        }}
      >
        <RichTextContextMenu editorState={editorState} options={options} literals={literals} onSelect={onSelect}>
          <Editor {...props} onEditorStateChange={setEditorState} />
        </RichTextContextMenu>
      </RichTextFieldModal>
    );
  }

  return (
    <RichTextContextMenu options={options} onSelect={onSelect}>
      <Editor
        {...props}
        onEditorStateChange={newState => {
          setEditorState(newState);
          const html = draftToHtml(convertToRaw(newState.getCurrentContent()));
          onChange(html);
        }}
      />
    </RichTextContextMenu>
  );
};

export default RichTextEditor;
