import React from 'react';
import { Button, Tooltip } from '@blueprintjs/core';

const validate = (title, entity, validationSchema) => {
  try {
    validationSchema?.validateSync(entity, { abortEarly: false });
    return null;
  } catch (ex) {
    return (
      <div>
        {title && <div>{title}</div>}
        <ul className="bp5-list-unstyled">
          {ex?.inner?.map(error => (
            <li key={error.message}>
              <small>{error.message}.</small>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

const ValidationMark = ({ title, entity, validationSchema, intent, minimal = true, outlined = undefined, icon = 'warning-sign', tooltipProps }) => {
  const content = validate(title, entity, validationSchema);
  if (!content) {
    return null;
  }

  return (
    <Tooltip intent="danger" placement="right" {...tooltipProps} content={content}>
      <Button intent={intent} icon={icon} minimal={minimal} outlined={outlined} />
    </Tooltip>
  );
};

export default ValidationMark;
