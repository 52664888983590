import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const ClientsMenu = ({ setDialog }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="New" label={<Icon icon="plus" />} onClick={() => setDialog({ open: true })} />
        <MenuDivider />
        <MenuItem text="Export" label=".csv">
          <MenuItem text="Current Page" label={<Icon icon="layer" />} disabled />
          <MenuItem text="All Pages" label={<Icon icon="layers" />} disabled />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ClientsMenu;
