import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';

export const getPayrollGroups = async ({ filters, page, pageSize }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/payroll/groups${toQueryString(parameters)}`);
  return response?.data;
};

export const removePayrollGroup = async ({ period, periodType, classification, currencyId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = { period, periodType, classification, currencyId };
  const response = await Clients.Api.delete(`ui/${organizationType}s/payroll/groups${toQueryString(parameters)}`);
  return response?.data;
};

export const removePayroll = async payrollId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = { payrollId };
  const response = await Clients.Api.delete(`ui/${organizationType}s/payroll${toQueryString(parameters)}`);
  return response?.data;
};

export const getCsvData = async ({ period, periodType, classification, currencyId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = { period, periodType, classification, currencyId };
  const response = await Clients.Api.get(`ui/${organizationType}s/payroll/export${toQueryString(parameters)}`);
  return response?.data;
};

export const generatePayroll = async ({ periodType, period, regenerate }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/payroll`, { periodType, period, regenerate });
  return response?.data;
};

export const processPayrolls = async ({ payrolls }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/payroll/pay`, payrolls);
  return response?.data;
};

export const runBulkPayrollProcessing = async () => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/payroll/bulk`);
  return response?.data;
};

export const addPayrollItem = async payload => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/payroll/item`, payload);
  return response?.data;
};

export const updatePayrollItem = async ({ id, payload }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/payroll/item/${id}`, payload);
  return response?.data;
};

export const removePayrollItem = async itemId => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/payroll/item/${itemId}`);
  return response?.data;
};
