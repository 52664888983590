import { Tooltip } from '@blueprintjs/core';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';

import { loadFonts, makeSVG } from './utilities';

import useApi from '../../../hooks/use-api';

import { getSignature } from '../../../apis/signature';
import Spinner from '../../spinner';
import classes from './signature-field.module.scss';

async function getSignatureInfo(signatureId, readonly, target) {
  if (readonly) {
    return new Promise(resolve => {
      setTimeout(() => {
        return resolve({
          id: '12ae711a-1950-4ec6-b1b8-65879453b175',
          userId: '78ae711a-1950-4ec6-b1b8-65879453b175',
          target,
          date: Date.now(),
          ipAddress: '127.0.0.1',
        });
      }, 200);
    });
  }
  return getSignature(signatureId);
}

const SignatureRenderer = ({ signatureId, readonly, target, settings }) => {
  const getSignatureApi = useApi(getSignatureInfo, { skip: true });
  const fontsApi = useApi(loadFonts);
  const makeSVGApi = useApi(makeSVG, { skip: true });
  const element = useRef();

  useEffect(() => {
    async function fetchAndDisplaySignature() {
      const response = await getSignatureApi.request(signatureId, readonly, target);
      if (response) {
        const { text, font, svg } = settings;
        if (svg) {
          element.current.innerHTML = svg;
        } else if (text && font) {
          element.current.innerHTML = await makeSVGApi.request(font, text);
        }
      }
    }
    if (element.current) {
      void fetchAndDisplaySignature();
    }
  }, []);

  return (
    <div className={classNames(classes.renderer)}>
      {(fontsApi.loading || getSignatureApi.loading) && <Spinner global={false} blend />}
      <div className={classNames(classes.svg, !settings.svg && classes.empty)} ref={element} />
      {getSignatureApi.response && (
        <div className={classes.tag}>
          <div className={classes.info}>{getSignatureApi.response.id}</div>
          <Tooltip
            popoverClassName={classes.tooltip}
            content={
              <div className={classes.meta}>
                <div className={classes.row}>
                  <div className={classes.label}>Id</div>
                  <div className={classes.value}>{getSignatureApi.response.id}</div>
                </div>
                <div className={classes.row}>
                  <div className={classes.label}>Target</div>
                  <div className={classes.value}>{getSignatureApi.response.target}</div>
                </div>
                <div className={classes.row}>
                  <div className={classes.label}>Date</div>
                  <div className={classes.value}>{moment(getSignatureApi.response.date).toString('LL')}</div>
                </div>
                <div className={classes.row}>
                  <div className={classes.label}>IP</div>
                  <div className={classes.value}>{getSignatureApi.response.ipAddress}</div>
                </div>
              </div>
            }
          >
            <div className={classes.icon}>i</div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SignatureRenderer;
