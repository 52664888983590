import React, { useState } from 'react';
import { Button, Collapse, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';
import * as Yup from 'yup';

import Form from '../../../form';
import Fields from '../../../fields';

import WithConfirmation from '../../../../hooks/with-confirmation';
import useApi from '../../../../hooks/use-api';
import { getInstance, removeInstance, overrideProgressInstance, updateReview } from '../../../../apis/wizards';

import NotesPopover from '../notes-popover';

import classes from './toolbar.module.scss';

const Toolbar = ({ instanceId, template, review, step, onComplete, onClose, loadTimeline }) => {
  const [action, setAction] = useState();
  const toggleAction = actionType => {
    if (actionType !== undefined && action === undefined) {
      setAction(actionType);
    } else if (actionType !== undefined && action !== actionType) {
      setAction(undefined);
      setTimeout(() => {
        setAction(actionType);
      }, 250);
    } else {
      setAction(undefined);
    }
  };

  const resetApi = useApi(removeInstance, { skip: true });
  const setProgressApi = useApi(overrideProgressInstance, { skip: true });
  const updateReviewApi = useApi(updateReview, { skip: true });

  const submit = async values => {
    const result = action === ActionTypes.APPROVE;
    const data = { reviewId: review.id, flowId: step.review?.flowReviewId, instanceId: step?.instanceId, result, elaboration: values.elaboration };
    await updateReviewApi.request(data);
    onComplete();
    loadTimeline();
    toggleAction(undefined);
  };

  const setProgress = async progress => {
    const instance = await getInstance(instanceId);
    await setProgressApi.request({ instanceId, data: JSON.parse(instance.data), progress, status: 'in-progress' });
    loadTimeline();
  };
  const resetStep = async () => {
    await resetApi.request({ instanceId });
    loadTimeline();
    onClose();
  };
  const skipStep = async () => {
    const instance = await getInstance(instanceId);
    await setProgressApi.request({ instanceId, data: JSON.parse(instance.data), progress: instance.progress, status: 'submitted' });
    loadTimeline();
  };

  const risk = calculateRisk(template);

  const renderReviewForm = () => {
    return (
      <Collapse className={classes.reviewForm} isOpen={!!action}>
        <div className={classes.reviewFormContent}>
          <Form
            initialValues={{ elaboration: '' }}
            validationSchema={Yup.object({ elaboration: Yup.string().required('Elaboration is required.') })}
            enableReinitialize
            onSubmit={submit}
          >
            <Fields.Textarea label="Elaboration..." name="elaboration" disabled={updateReviewApi.loading} />
            <div className={classes.actions}>
              <Button
                type="submit"
                outlined
                text={action}
                intent={action === ActionTypes.APPROVE ? 'success' : 'danger'}
                loading={updateReviewApi.loading}
                disabled={updateReviewApi.loading}
              />
              <Button type="button" outlined text="Cancel" onClick={() => toggleAction(undefined)} />
            </div>
          </Form>
        </div>
      </Collapse>
    );
  };

  return (
    <React.Fragment>
      {!!review?.elaboration && (
        <div className={classNames(classes.reviewResult, !!review?.result ? classes.approved : classes.rejected)}>
          <div className={classes.title}>{!!review?.result ? 'Approved' : 'Rejected'}</div>
          <div className={classes.elaboration}>{review?.elaboration}</div>
        </div>
      )}
      <div className={classes.toolbar}>
        <div className={classes.actions}>
          <Button
            outlined
            rightIcon={<Icons.Check size={16} strokeWidth={1.5} />}
            text="Approve"
            onClick={() => toggleAction(ActionTypes.APPROVE)}
            disabled={!review || !instanceId || isCustomStepWithDisabledReview(step)}
          />
          <Button
            outlined
            rightIcon={<Icons.X size={16} strokeWidth={1.5} />}
            text="Reject"
            onClick={() => toggleAction(ActionTypes.REJECT)}
            disabled={!review || !instanceId || isCustomStepWithDisabledReview(step)}
          />
          <Popover
            disabled={!step?.manageable || !instanceId}
            content={
              <Menu>
                {template?.pages?.map((page, i) => (
                  <MenuItem text={`${i + 1}. ${page.title}`} onClick={() => setProgress(i)} key={page.id} />
                ))}
              </Menu>
            }
          >
            <Button disabled={!step?.manageable || !instanceId} outlined rightIcon={<Icons.CornerRightUp size={16} strokeWidth={1.5} />} text="Go to" />
          </Popover>

          <WithConfirmation messages={{ question: `Are you sure you want to reset this wizard data ?`, confirmButton: 'Reset' }}>
            <Button disabled={!step?.manageable || !instanceId} outlined rightIcon={<Icons.X size={16} strokeWidth={1.5} />} text="Reset" onClick={resetStep} />
          </WithConfirmation>
          <Button
            disabled={!step?.manageable || !instanceId}
            outlined
            rightIcon={<Icons.ChevronsRight size={16} strokeWidth={1.5} />}
            text="Skip"
            onClick={skipStep}
          />
        </div>
        <div className={classes.indicators}>
          <NotesPopover review={review} onComplete={onComplete} buttonClassName={classNames(classes.notesButton)} />
          {!!risk && (
            <div className={classNames(classes.risk, risk?.color)} title="Risk factor">
              <span>{risk?.label}</span>
            </div>
          )}
        </div>
      </div>
      {renderReviewForm()}
    </React.Fragment>
  );
};

export default Toolbar;

const calculateRisk = template => {
  const answered = template?.sections?.reduce(
    (mapper, section) => {
      section.questions
        ?.filter(q => !!q.score?.length)
        ?.forEach(question => {
          const idx = question.options.findIndex(obj => obj.value === question.value);
          question.score = question.score.map(Number);
          if (idx !== -1) {
            mapper.score = (mapper.score || 0) + question.score[idx];
          }
          mapper.scoreMin += Math.min(...question.score);
          mapper.scoreMax += Math.max(...question.score);
        });

      return mapper;
    },
    { score: undefined, scoreMax: 0, scoreMin: 0 },
  );

  // not used ! mark because score can be equal to zero
  if (answered?.score === undefined) {
    return undefined;
  }

  const badness = (answered.score - answered.scoreMin) / (answered.scoreMax - answered.scoreMin);
  const percent = (1 - badness) * 100.0;
  const round = Math.floor(Math.round(percent * 10) / 10 + 0.5);
  const color = round >= 40 ? classes.red : round > 25 ? classes.yellow : classes.green;
  const label = `${round}%`;
  return { label, color };
};

const ActionTypes = { APPROVE: 'Approve', REJECT: 'Reject' };

const isCustomStepWithDisabledReview = step => {
  switch (step?.contextType) {
    case 'requestengagementterminationobjectcontext':
    case 'engagementupdaterequestobjectcontext':
      return true;

    default:
      return false;
  }
};
