import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';

import { loadTrees } from '../../../../../../../state/contexts';
import { createRecord } from '../../../../../../../apis/configuration';
import { createTemplate } from '../../../../../../../apis/templates';
import { SystemContext } from '../../../../system-provider';

import Spinner from '../../../../../../spinner';

import classes from './wizards-setup.module.scss';

const WizardSetup = ({ record, onClose, setLoading, api, data, trees, loadTrees, contextTypeName }) => {
  const navigate = useNavigate();
  const { selectedScope } = useContext(SystemContext);
  const descriptor = trees?.find?.(t => t.contextTypeName === contextTypeName);

  useEffect(() => {
    if (!trees) {
      loadTrees();
    }
  }, []);

  useEffect(() => {
    if (record?.id && data?.templateId) {
      navigate(`/settings/wizards/${data?.templateId}/${record?.id}`);
    }
  }, [data?.templateId]);

  useEffect(() => {
    if (!record?.id && !data?.templateId) {
      if (!!trees) {
        submit();
      }
    }
  }, [data?.templateId, trees]);

  const submit = async () => {
    setLoading(true);
    const template = {
      id: data?.templateId,
      type: 'Wizard',
      refName: descriptor?.refName,
      contextTypeName: descriptor?.contextTypeName,
      contentType: 'application/json',
      name: descriptor?.name,
      description: descriptor?.description,
    };
    const response = await createTemplate(template);

    const payload = {
      scopeId: selectedScope?.id,
      ...record,
      description: '(Draft)',
      data: {
        ...(data || {}),
        contextType: contextTypeName,
        description: descriptor?.name,
        templateId: response.id,
      },
    };

    const newRecord = await createRecord(payload);
    await api?.request();
    setLoading(false);
    onClose();
    if (payload.data?.templateId) {
      navigate(`/settings/wizards/${payload.data.templateId}/${newRecord?.data?.id}`);
    }
  };

  return (
    <div className={classes.wizards}>
      <Spinner global={false} blend />
    </div>
  );
};

const mapStateToProps = state => ({
  trees: state.contexts.trees,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadTrees,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(WizardSetup);
