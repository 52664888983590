import React from 'react';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';
import { useNavigate, useParams } from 'react-router';

import { getRecord, getRecordValue, updateRecord } from '../../../../../apis/configuration';
import { updateTemplate, validateTemplate } from '../../../../../apis/templates';

import Text from '../../../../fields/text';
import Form from '../../../../form';
import Dialog from '../../../../dialog';
import useDocuments from '../../state/use-documents';

const RenameDialog = ({ open, toggle }) => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const { template, renameDocument } = useDocuments();

  const submit = async values => {
    const name = values.name;
    await validateTemplate({ ...template, name });
    await updateTemplate({ ...template, name });
    const record = await getRecord(recordId);
    const recordValue = await getRecordValue(recordId);
    const newRecord = await updateRecord(recordId, {
      scopeId: record.scopeId,
      description: record.description,
      data: {
        documentTemplateType: recordValue?.documentTemplateType,
        documentName: name,
        templateId: recordValue.templateId,
      },
      configurationType: record.configurationType,
    });
    renameDocument(name);
    navigate(`/settings/documents/${template.id}/${newRecord?.data?.id}`, { replace: true });
    toggle();
    return newRecord;
  };

  return (
    <Dialog title="Rename Document" isOpen={open} onClose={toggle}>
      <Form
        initialValues={{ name: template?.name }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().required('Document name is required'),
        })}
        onSubmit={submit}
      >
        {form => (
          <>
            <Text name="name" label="Document name" />
            <Button fill outlined text="Submit" type="submit" disabled={form.isSubmitting} loading={form.isSubmitting} />
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default RenameDialog;
