import { StorageManager } from '../../authentication/storage';
import Clients from '../../configuration/client';
import { toQueryString } from '../../utilities/common';

export const filterClients = async ({ filters = {}, page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client${toQueryString(query)}`);
  return response?.data;
};

export const getClients = async ({ includeParent = false }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client/all${toQueryString({ includeParent })}`);
  return response?.data;
};

export const getClient = async ({ clientId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client/${clientId}`);
  return response?.data;
};

export const createClient = async client => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/client`, client);
  return response?.data;
};

export const updateClient = async updates => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/client`, updates);
  return response?.data;
};

export const removeClient = async clientId => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${organizationId}/client/${clientId}`);
  return response?.data;
};

export const uploadClientLogo = async ({ clientId, logo }, onUploadProgress = () => null) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('logo', logo);
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/client/${clientId}/logo`, data, { onUploadProgress });
  return response?.data;
};

export const uploadClientIcon = async ({ clientId, icon }, onUploadProgress = () => null) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const data = new FormData();
  data.append('icon', icon);
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/client/${clientId}/icon`, data, { onUploadProgress });
  return response?.data;
};

export const setAddress = async ({ clientId, placeId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/client/${clientId}/address`, { placeId });
  return response?.data;
};

export const getWorkLocations = async ({ clientId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client/${clientId}/work-locations`);
  return response?.data;
};

export const addWorkLocation = async ({ clientId, description, placeId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/client/${clientId}/work-locations`, { clientId, description, placeId });
  return response?.data;
};

export const removeWorkLocation = async ({ clientId, workLocationId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${organizationId}/client/${clientId}/work-locations/${workLocationId}`);
  return response?.data;
};

export const getIntegrations = async ({ clientId, readable }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client/${clientId}/integrations`, { params: { readable } });
  return response?.data;
};

export const regenerateIntegration = async ({ clientId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/client/${clientId}/integrations`);
  return response?.data;
};
