import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { evaluateCondition } from '../domain/operator-definitions';
import { ObjectTypes } from '../domain/types';

import { uuid } from '../../../../utilities/common';

import { getInstance, progressInstance, awaitSignatures, submitFlowStep } from '../../../../apis/wizards';
import { buildValidationSchema } from './validation-builder';

export const WizardInstanceContext = React.createContext({});

const WizardInstanceProvider = ({ templateId, template, instanceId, setInstanceId, isDesigner, onComplete, children }) => {
  const [loading, setLoading] = useState(false);
  const [instance, setInstance] = useState();
  const [error, setError] = useState();

  // Progress is a property carried by each wizard instance, which informs "how far" did the user get in filling it out.
  const [progress, setProgress] = useState(0);
  // Step(current) is state variable which represents currently rendered page. Its default value is derived from progress.
  const [step, setStep] = useState(undefined);

  const [validationSchema, setValidationSchema] = useState();

  const setStepById = id => {
    if (!id) {
      return;
    }
    const newIndex = template?.pages?.map(p => p.id).indexOf(id);
    setInstance({ ...instance, progress: newIndex });
    setStep(newIndex);
    setProgress(newIndex);
  };

  const saveInstanceValues = async (values, changeToStep = progress) => {
    await progressInstance({ instanceId, templateId, progress: changeToStep, data: values });
  };

  const moveForward = async (values = instance) => {
    const target = getFirstNextAvailableStep(values);
    if (target === -1) {
      if (!isDesigner) {
        setLoading(true);

        const stepSignatureFields = template?.pages?.flatMap(page =>
          page?.groups?.flatMap(group =>
            group?.fields?.filter(field => field?.type === ObjectTypes.Signature).map(field => ({ ...field, name: `${page?.id}.${group?.id}.${field?.id}` })),
          ),
        );

        const signatures = stepSignatureFields
          ?.map(field => {
            const path = field.name;
            const value = _.get(values, path);
            if (!value) {
              return null;
            }
            return value.map(signature => ({ ...signature, path }));
          })
          ?.filter(signatures => !!signatures);

        // if there is no signatures data, then no one is needed to sign
        const everyoneSigned = !signatures || signatures?.every(signatories => !signatories || signatories?.every(signatory => !!signatory.signatureId));
        const data = compileContextData(values);

        await progressInstance({ instanceId, progress: step, data });

        if (!everyoneSigned) {
          await awaitSignatures({ instanceId, aux: signatures });
        } else {
          await submitFlowStep({ instanceId });
        }

        if (!!onComplete && typeof onComplete === 'function') {
          onComplete();
        }
        setLoading(false);
      }

      return;
    }

    if (!isDesigner) {
      await saveInstanceValues(values, target);
    }

    setInstance({ ...values, progress: target });
    setProgress(target);
    setStep(target);
  };
  const moveBackward = async (values = instance) => {
    const target = getFirstPreviousAvailableStep(values);
    if (target === -1) {
      return;
    }
    if (!isDesigner) {
      await saveInstanceValues(values, target);
    }
    setInstance({ ...values, progress: target });
    setProgress(target);
    setStep(target);
  };

  const getFirstNextAvailableStep = (values = instance) => {
    if (step + 1 === template?.pages?.length) {
      return -1;
    }
    const visiblePages = getVisiblePages(values);
    const identifiers = visiblePages?.map(p => p.id);
    for (let index = step + 1; index < template?.pages?.length; index++) {
      const candidate = template?.pages?.[index];
      if (identifiers.includes(candidate?.id)) {
        return index;
      }
    }
    return -1;
  };
  const getFirstPreviousAvailableStep = (values = instance) => {
    if (step === 0) {
      return -1;
    }
    const visiblePages = getVisiblePages(values);
    const identifiers = visiblePages?.map(p => p.id);
    for (let index = step - 1; index >= 0; index--) {
      const candidate = template?.pages?.[index];
      if (identifiers.includes(candidate?.id)) {
        return index;
      }
    }
    return -1;
  };

  const compileContextData = values => {
    const data = { ...values, Signatures: null };
    const contextData = {};

    const pages = getVisiblePages(values);
    pages?.forEach?.(page => {
      const groups = getVisibleGroups(page, values);
      groups?.forEach?.(group => {
        if (!!group?.metadata) {
          data.Metadata = {};
        }

        const fields = getVisibleFields(group, values);
        fields?.forEach?.(field => {
          const path = `${page?.id}.${group?.id}.${field?.id}`;
          const value = _.get(values, path);
          if (!!field?.path) {
            contextData[path] = field?.path;
          }

          if (!!group?.metadata) {
            data.Metadata[field.label] = value;
          }

          if (field.type === ObjectTypes.Document && value) {
            if (!data.Documents) {
              data.Documents = [];
            }
            data.Documents.push(value);
          } else if (field.type === ObjectTypes.Signature && value) {
            if (!data.Signatures) {
              data.Signatures = [];
            }
            data.Signatures.push(...value);
          }
        });
      });
    });

    Object.entries(contextData).forEach(([k, v]) => {
      _.set(data, v, _.get(values, k));
    });

    return data;
  };

  const loadInstance = async () => {
    setLoading(true);
    let resultInstance = null;
    if (!!instanceId) {
      resultInstance = await getInstance(instanceId);
    } else if (!instanceId && !!isDesigner) {
      resultInstance = { id: uuid(), progress: 0, data: '{}' };
    } else {
      setError('Unable to load the flow step data. Please contact platform administrators for support.');
      setLoading(false);
      return;
    }

    const localInstance = JSON.parse(resultInstance?.data || '{}');
    setInstance(localInstance);

    setInstanceId(resultInstance?.id);
    setProgress(resultInstance?.progress);
    setLoading(false);

    let firstAvailableStep = 0;
    if (template?.pages?.length === 0) {
      firstAvailableStep = 0; // we need to set it to 0 to display first page when it's added in the template
    } else {
      firstAvailableStep = template?.pages?.findIndex((page, idx) => idx >= resultInstance?.progress && isVisible(page, localInstance));
    }
    // if first available step is not found, move forward (skip the wizard step)
    if (firstAvailableStep === -1) {
      await moveForward(localInstance);
      return;
    }

    setStep(firstAvailableStep);
  };

  useEffect(() => {
    if (!template?.pages) {
      return;
    }

    if (progress > template.pages.length - 1) {
      // If designer is on the last page and removes it, we need to go back to the previous page
      setStep(Math.max(progress - 1, 0)); // this will trigger the useEffect below;
      return;
    }

    let page = template?.pages?.[progress];
    if (!!page && !isDesigner) {
      const validationSchema = buildValidationSchema(page, (target, values = instance) => isVisible(target, values));
      setValidationSchema(validationSchema);
    }
  }, [template?.pages?.[progress]]);

  useEffect(() => {
    if (template && !instance) {
      // this should only run once but template is a dependency that changes a lot
      void loadInstance();
    }
  }, [template, instance]);

  const isVisible = (item, values = instance) => evaluateCondition(template, values, item?.visibility);

  const getVisiblePages = (values = instance) => template?.pages?.filter(page => isVisible(page, values));
  const getVisibleGroups = (page, values = instance) => page?.groups?.filter(group => isVisible(group, values));
  const getVisibleFields = (group, values = instance) => group?.fields?.filter(field => isVisible(field, values));

  return (
    <WizardInstanceContext.Provider
      value={{
        progress,
        step,
        instance,
        instanceId,
        validationSchema,
        loading,
        error,
        setInstance,
        saveInstanceValues,
        moveForward,
        moveBackward,
        setStep,
        setStepById,
        getVisiblePages,
        getVisibleGroups,
        getVisibleFields,
      }}
    >
      {children}
    </WizardInstanceContext.Provider>
  );
};

export default WizardInstanceProvider;
