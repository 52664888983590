import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Page from '../../../components/page';

import { getTimeBasedGreeting } from '../../../utilities/common';

import OnboardingBox from './components/onboarding-box';
import OffboardingBox from './components/offboarding-box';
import ExpiringBox from './components/expiring-box';
import UpdatesBox from './components/updates-box';
import ReviewBox from './components/review-box';
import EngagementUpdates from '../../../components/organizational/client/engagement-updates';
import EngagementTermination from '../../../components/organizational/client/engagement-termination';

import classes from './dashboard.module.scss';

const Dashboard = ({ user, membership }) => {
  const [drawer, setDrawer] = useState({ open: false, type: undefined, data: undefined, onComplete: undefined });

  const handleClose = () => setDrawer({ open: false, type: undefined, data: undefined, onComplete: undefined });
  return (
    <Page title={`${getTimeBasedGreeting()}, ${user?.personalDetails?.firstName}.`}>
      <div className={classes.dashboard}>
        <OnboardingBox membership={membership} />
        <ReviewBox />
        <UpdatesBox />
        <OffboardingBox membership={membership} setDrawer={setDrawer} />
        <ExpiringBox membership={membership} setDrawer={setDrawer} />
        <EngagementUpdates
          engagement={drawer?.data}
          initialize={true}
          open={!!drawer.open && drawer.type === 'request-updates'}
          onClose={handleClose}
          onComplete={drawer?.onComplete}
        />
        <EngagementTermination
          engagement={drawer?.data}
          initialize={true}
          open={!!drawer.open && drawer.type === 'termination'}
          onClose={handleClose}
          onComplete={drawer.onComplete}
        />
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
  membership: state.identity.membership,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
