import TextToSVG from './text-to-svg';

export const fonts = ['Birthstone Bounce', 'Dr Sugiyama', 'Ephesis', 'Herr Von Muellerhoff', 'Marck Script', 'Reenie Beanie'];
const fontsQueryString = fonts.map(f => `family=${f.replace(/\s/g, '+')}`).join('&');
export const signatureFontsApiUrl = `https://fonts.googleapis.com/css2?${fontsQueryString}&display=swap`;

export const insertLinkTagToHead = (src, attributes = {}) => {
  const link = document.createElement('link');
  link.href = src;
  link.rel = 'stylesheet';
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const loadFonts = async () => insertLinkTagToHead(signatureFontsApiUrl);

export const makeSVG = (font, svgText) => {
  const text = svgText.trim();

  return new Promise(resolve => {
    TextToSVG.load(`/fonts/${font?.replaceAll(' ', '')}-Regular.ttf`, function (err, textToSVG) {
      if (err) {
        return resolve();
      }

      const options = { x: 0, y: 0, fontSize: 27, anchor: 'top' };
      const metrics = textToSVG.getMetrics(text, options);

      const svg = textToSVG.getSVG(text, options);
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svg, 'text/html');
      const path = svgDoc.querySelector('path').getAttribute('d');

      resolve(`<svg width="${metrics.width + 5}px" height="${metrics.height}px" viewBox="0 0 ${metrics.width} ${
        metrics.height
      }" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="${path}" />
          </g>
        </svg>`);
    });
  });
};
