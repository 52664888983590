import React from 'react';

import Page from '../../../components/page';

import SupplierTime from '../../../components/organizational/supplier/time';

const Time = () => {
  return (
    <Page title="Time" path={[{ name: 'Time', to: '/time' }]} scroll={false}>
      <SupplierTime />
    </Page>
  );
};

export default Time;
