import React, { useContext, useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';

import Dialog from '../../../../dialog';

import { SystemContext } from '../../system-provider';

const RemoveScopeDialog = ({ scope, isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { removeScope } = useContext(SystemContext);

  const action = async () => {
    setLoading(true);
    await removeScope(scope.id);
    setLoading(false);
    onClose();
  };
  const close = () => (!!loading ? null : onClose());

  return (
    <Dialog title={`Remove scope ${scope?.prettyName || 'Global'}`} isOpen={isOpen} onClose={close}>
      <p>Are you sure you want to remove scope {scope?.prettyName} ?</p>
      <ButtonGroup fill>
        <Button outlined fill text="Cancel" disabled={loading} onClick={close} />
        <Button outlined fill intent="danger" text="I'm sure." disabled={loading} onClick={action} />
      </ButtonGroup>
    </Dialog>
  );
};

export default RemoveScopeDialog;
