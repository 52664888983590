import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import cn from 'classnames';

import useApi from '../../../hooks/use-api';

import { sign } from '../../../apis/signature';
import SignatureRenderer from './signature-renderer';
import SignatureSettingsDialog from './signature-settings-dialog';
import { getTemplate } from '../../../apis/wizards';
import { eligibleToSign, getRolesRequiredToSign } from './signature-helper';

import styles from './signature-field.module.scss';
import { createPortal } from 'react-dom';

async function signDocument(target, readonly) {
  if (readonly) {
    return new Promise(resolve => {
      setTimeout(() => {
        return resolve({
          id: '12ae711a-1950-4ec6-b1b8-65879453b175',
          userId: '78ae711a-1950-4ec6-b1b8-65879453b175',
          target,
          date: Date.now(),
          ipAddress: '127.0.0.1',
        });
      }, 200);
    });
  }
  return sign(target);
}

let SignatureButton = ({ value, target, placeholder, loading, readonly, onChange, settings, floating, eligibleSignatories, membership, user }) => {
  const [dialog, setDialog] = useState(undefined);
  const templateApi = useApi(() => getTemplate(target), { skip: !target });
  const signApi = useApi(signDocument, { skip: true });
  const configuredSettings = Boolean(settings?.text && settings?.font);
  //  extract the roles required to sign from the metadata of the document template
  const rolesRequiredToSign = getRolesRequiredToSign(templateApi.response?.metadata, membership);
  // filter the roles required to sign to get only the roles that the user is eligible to sign
  const rolesUserCanSign = rolesRequiredToSign.filter(roleId => eligibleToSign({ eligibleSignatories, roleId, user, organization: membership.organization }));
  const eligible = rolesUserCanSign.length > 0; // check if user is eligible to sign any of the roles required in the document
  const disabled = loading || !eligible;
  // check if the user has already signed the document and get the signature id which is same for all user roles
  const signatureId = value?.find(v => rolesUserCanSign.includes(v.roleId))?.signatureId;

  const openConfigurationDialog = type => new Promise((resolve, reject) => setDialog({ open: true, resolve, reject }));

  const handleClose = () => setDialog({ open: false });

  const sign = async () => {
    if (signatureId) {
      // already signed document
      return signatureId;
    }

    if (configuredSettings) {
      // sign document in case that user (supplier) signature settings are configured
      const response = await signApi.request(target, readonly);
      handleClose();
      return response.id;
    }

    // open dialog for user (supplier) to configure signature settings
    const config = await openConfigurationDialog();

    if (!config) {
      // user (supplier) closes the signature dialog
      return;
    }
    const response = await signApi.request(target, readonly);
    handleClose();
    return response.id;
  };

  // this function is used to sign the document from the outside of the component
  const handleSign = async () => {
    if (loading || signApi.loading) {
      return;
    }

    if (!eligible) {
      // user is not allowed to sign the document
      return;
    }

    const signId = await sign();
    const alreadySignedRoles = value?.reduce((acc, s) => ({ ...acc, [s.roleId]: s }), {});
    const signatures = rolesRequiredToSign.map(roleId => {
      const isEligible = eligibleToSign({ eligibleSignatories, roleId, user, organization: membership.organization });
      if (isEligible) {
        return { target, roleId, signatureId: signId, organizationId: membership.organization.id };
      }

      return alreadySignedRoles?.[roleId] || { target, roleId, signatureId: null, organizationId: null };
    });

    onChange(signatures);
  };

  const handleConfigSignature = () => {
    if (loading || signApi.loading) {
      return;
    }

    if (!eligible) {
      // user is not allowed to sign the document
      return;
    }

    openConfigurationDialog().then(handleClose);
  };

  if (signatureId && configuredSettings && !loading) {
    return <SignatureRenderer signatureId={signatureId} settings={settings} target={target} readonly={readonly} />;
  }

  const skeletonClassName = (loading || signApi.loading) && Classes.SKELETON;
  const signButton = (
    <div className={cn(styles.signatureButtons, loading && styles.hidden, disabled && styles.disabled)}>
      <div className={cn(styles.signButton, skeletonClassName)} onClick={handleSign}>
        {placeholder || 'Sign'}
      </div>
      <div className={cn(styles.settings, skeletonClassName)} onClick={handleConfigSignature}>
        <Icons.Tool size={23} strokeWidth={1} />
      </div>
    </div>
  );

  let content;
  if (floating) {
    const parent = document.getElementById('wizard_container');
    content = createPortal(<div className={styles.stickToParent}>{signButton}</div>, parent);
  } else {
    content = signButton;
  }
  return (
    <>
      {content}
      <SignatureSettingsDialog dialog={dialog} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = state => ({ settings: state.identity.user.signatureSettings, membership: state.identity.membership, user: state.identity.user });

SignatureButton = connect(mapStateToProps)(SignatureButton);

export default SignatureButton;
