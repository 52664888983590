import * as Yup from 'yup';

import { Validators, getInitSchema } from '../domain/validation';
import { evaluateCondition } from '../domain/operator-definitions';

export const buildValidationSchema = (template, page, values) => {
  const isVisible = item => evaluateCondition(template, values, item?.visibility);
  const groups = {};
  page?.groups?.forEach(group => {
    if (isVisible(group)) {
      const fields = {};
      group?.fields?.forEach(field => {
        if (isVisible(field)) {
          const keys = Object.keys(field?.validation);
          let schema = getInitSchema(field);
          keys.forEach(key => {
            if (Validators[key]) {
              schema = Validators[key](schema, field);
            }
          });

          fields[field?.id] = schema;
        }
      });
      groups[group?.id] = Yup.object().shape(fields);
    }
  });

  const groupsSchema = Yup.object().shape(groups);
  return Yup.object().shape({ [page?.id]: groupsSchema });
};
