import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tooltip } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Avatar from '../../../../../components/avatar';
import Page from '../../../../../components/page';
import Table from '../../../../../components/table';

import useTable from '../../../../../hooks/use-table';
import { filterClients, removeClient } from '../../../../../apis/client/organizations';

import ClientFilters from './filters';
import ClientsMenu from './menu';
import NewClient from './new';

import classes from '../../clients.module.scss';

const List = ({ clientId }) => {
  const [dialog, setDialog] = useState({ open: false });
  const { items, loading, filters, initialFilters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    filterClients,
    { pageSize: 10, controlled: true },
    { includeRemoved: null, countries: null },
  );

  const handleClientRemove = async client => {
    await removeClient(client?.id);
    reload();
  };

  return (
    <Page title="Clients" path={[{ name: 'Clients', to: '/clients' }]} scroll={false}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<ClientsMenu setDialog={setDialog} />}
          content={<ClientFilters />}
        />
        <Table.Columns.Default
          name="Name"
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d.name} url={d.icon} />}
        />
        <Table.Columns.TwoLines width="2fr" showOnMobile lines={[d => d?.name, d => d?.address?.prettyName]} />
        <Table.Columns.TwoLines width="2fr" name="Contact" lines={[d => d?.managerName || 'Not provided', d => d?.managerEmailAddress]} />
        <Table.Columns.Default
          width="65px"
          render={d => (
            <div className={classes.tags}>
              {!!d?.isInvoiced && (
                <Tooltip content={'Client is charged for invoices.'}>
                  <Icons.Book size={15} />
                </Tooltip>
              )}
              {d?.isApiEnabled && (
                <Tooltip content={`API integration enabled.`}>
                  <Icons.Share2 size={15} />
                </Tooltip>
              )}
            </div>
          )}
        />
        <Table.Columns.Default
          name="Created at"
          render={d => <small>{moment(d.createdAt).isValid() ? moment(d.createdAt).format('LL') : moment(d.updatedAt).format('LL')}</small>}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', href: d => `/clients/${d.id}` },
            { name: 'Remove', onClick: d => handleClientRemove(d), confirmation: true },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <NewClient isOpen={dialog.open} parentId={clientId} onClose={() => setDialog({ open: false })} reloadList={reload} />
    </Page>
  );
};

const mapStateToProps = state => ({
  clientId: state.identity.membership.organization.id,
});

export default connect(mapStateToProps)(List);
