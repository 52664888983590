import { Button, Popover } from '@blueprintjs/core';
import React, { useState } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';

import WithConfirmation from '../../../../../../../hooks/with-confirmation';

import Fields from '../../../../../../fields';
import Form from '../../../../../../form';

import classes from './custom-fields.module.scss';

const SelectOptions = ({ form }) => {
  const [dialog, setDialog] = useState({ open: false, item: undefined });

  const createOption = async values => {
    const existing = [...(form?.values?.options || [])];
    const options = [...existing, values];
    const anyDuplicates = options.find((option, i) => options.find((o, j) => option.value === o.value && i !== j));
    if (anyDuplicates) {
      setDialog({ open: false, item: undefined });
      return;
    }

    await form.setFieldValue('options', options);
    await form.submitForm();
    setDialog({ open: false, item: undefined });
  };

  const updateOption = async values => {
    const existing = [...(form?.values?.options || [])];
    const options = existing?.map?.((o, i) => (i === values?.index ? { value: values.value, label: values.label } : o));
    const anyDuplicates = options.find((option, i) => options.find((o, j) => option.value === o.value && i !== j));
    if (anyDuplicates) {
      setDialog({ open: false, item: undefined });
      return;
    }

    await form.setFieldValue('options', options);
    await form.submitForm();
    setDialog({ open: false, item: undefined });
  };

  const removeOption = async index => {
    const existing = [...(form?.values?.options || [])];
    const options = existing?.filter?.((_, i) => i !== index);
    await form.setFieldValue('options', options);
    await form.submitForm();
  };

  return (
    <React.Fragment>
      <div>
        <div className={classes.groupTitle}>Options</div>
        <div className={classNames(classes.groupDescription, classes.botPad15)}>Configure available selections / options.</div>
      </div>
      <div className={classes.options}>
        <div className={classes.list}>
          {form?.values?.options?.map?.((option, index) => (
            <div className={classes.option} key={index}>
              <div className={classes.value}>{option?.value}</div>
              <div className={classes.label}>{option?.label}</div>
              <Popover
                fill
                popoverClassName={classes.popover}
                isOpen={!!dialog?.open && dialog?.item === index}
                content={
                  <OptionsForm
                    initialValues={{ ...option, index }}
                    submit={updateOption}
                    cancel={() => setDialog({ open: false, item: undefined })}
                    disableValueEdits={form?.values?.mandatory}
                  />
                }
              >
                <Button className={classes.action} outlined icon="edit" onClick={() => setDialog({ open: true, item: index })} />
              </Popover>
              <WithConfirmation messages={{ question: `Are you sure you want to remove field option ?` }} icon={true}>
                <Button className={classes.action} outlined intent="danger" icon="small-minus" onClick={() => removeOption(index)} />
              </WithConfirmation>
            </div>
          ))}
        </div>
        <Popover
          fill
          popoverClassName={classes.popover}
          isOpen={!!dialog?.open && dialog?.item === 'new'}
          content={<OptionsForm submit={createOption} cancel={() => setDialog({ open: false, item: undefined })} />}
        >
          <Button
            outlined
            alignText="left"
            icon="small-plus"
            text="Add Option"
            onClick={() => setDialog({ open: true, item: 'new' })}
            // disabled={form.values.lookup}
          />
        </Popover>
      </div>
    </React.Fragment>
  );
};

const OptionsForm = ({ initialValues = { value: '', label: '' }, submit, cancel, disableValueEdits = false }) => {
  return (
    <div className={classes.content}>
      <div className={classes.inner}>
        <Form
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            value: Yup.mixed().required('Option value is required'),
          })}
          enableReinitialize
          onSubmit={submit}
        >
          <div className={classes.title}>Configure field options</div>
          <div className={classes.description}>Provide field option value and optionally provide a label for given value.</div>
          <Fields.Text name="value" label="Value" disabled={disableValueEdits} />
          <Fields.Text name="label" label="Label" />
          <div className={classes.row}>
            <Button fill type="submit" outlined text="Submit" />
            <Button fill outlined text="Cancel" onClick={cancel} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SelectOptions;
