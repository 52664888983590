import React from 'react';
import { NavLink } from 'react-router-dom';
import { Classes, Menu, MenuItem, MenuDivider, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import WithConfirmation from '../../../hooks/with-confirmation';

import classes from './columns.module.scss';

const ActionsColumn = ({ row, actions = [], disabled, loading }) => {
  const renderAction = (action, index) => {
    const { name, label, onClick, href, target, confirmation, divider = false, nested = false, hidden = undefined, items = [] } = action;
    const isHidden = !!hidden?.(row);
    if (isHidden) {
      return null;
    }

    const isDisabled = !!action?.disabled?.(row);
    const click = !!onClick && !isDisabled ? () => onClick(row) : undefined;
    const link = !!href && !isDisabled ? href(row) : undefined;
    const text = typeof name === 'function' ? name(row) : name;
    const _label = typeof label === 'function' ? label(row) : label;

    if (divider) {
      return <MenuDivider key={index} />;
    }

    if (nested) {
      return (
        <MenuItem key={index} text={text} label={_label} shouldDismissPopover={false}>
          {items?.map((item, i) => renderAction(item, `${index}-${i}`))}
        </MenuItem>
      );
    }

    if (click) {
      return !!confirmation ? (
        <WithConfirmation captureDismiss={false} key={index} messages={{ confirmButton: text, ...(confirmation?.messages || {}) }} icon={true}>
          <MenuItem text={text} onClick={click} label={_label} shouldDismissPopover={false} />
        </WithConfirmation>
      ) : (
        <MenuItem key={index} text={text} label={_label} onClick={click} />
      );
    }

    if (link) {
      const targetProps = target ? { target } : {};
      return (
        <li key={index}>
          <NavLink to={link} {...targetProps} className="bp5-menu-item bp5-popover-dismiss">
            <div className="bp5-fill bp5-text-overflow-ellipsis">{text}</div>
          </NavLink>
        </li>
      );
    }

    return <MenuItem disabled={isDisabled} key={index} text={text} label={_label} />;
  };

  const isDisabled = disabled?.(row);
  return (
    <div className={classes.actionColumn}>
      {!!loading ? (
        <div className={classNames(classes.loading, Classes.SKELETON)}>.</div>
      ) : (
        <Popover captureDismiss={false} content={<Menu>{actions.map((action, i) => renderAction(action, i))}</Menu>}>
          <button className={classes.button} disabled={isDisabled}>
            <Icons.MoreVertical size={20} />
          </button>
        </Popover>
      )}
    </div>
  );
};

ActionsColumn.definition = 'column';
ActionsColumn.width = '30px';

export default ActionsColumn;
