import React from 'react';

import classes from './progress.module.scss';

const Progress = ({ total, current }) => {
  return (
    <div className={classes.progress}>
      <div className={classes.indicator} style={{ width: `${(current * 100) / total}%` }} />
    </div>
  );
};

export default Progress;
