import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button } from '@blueprintjs/core';

import Fields from '../../fields';
import Form from '../../form';

import { TimeRecordCommentsContext } from './state';

import classes from './comments.module.scss';

const CommentForm = ({ user, recordId, comment = {}, onComplete = () => null }) => {
  const { loading, createComment, updateComment } = useContext(TimeRecordCommentsContext);

  const submit = async values => {
    if (comment?.id) {
      await updateComment(values);
    } else {
      await createComment(values);
    }

    onComplete();
  };

  return (
    <Form
      initialValues={{
        userId: user.id,
        date: moment().format(),
        message: '',
        recordId,
        ...comment,
      }}
      enableReinitialize
      onSubmit={submit}
    >
      {form => {
        return (
          <div className={classes.messageForm}>
            <Fields.Text outline={false} minimal={true} showError={false} placeholder="Message..." name="message" />
            <Button type="submit" minimal fill icon="send-message" disabled={loading || !form?.values?.message} loading={loading} />
          </div>
        );
      }}
    </Form>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
