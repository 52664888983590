import moment from 'moment/moment';
import { CompensationFrequency } from '../domain/compensation-frequency';
import { CompensationType, CompensationTypeOptions } from '../domain/compensation-type';

export const mapCompensationTypeToPaymentLabel = (compensationType, short = false) => {
  switch (compensationType) {
    case CompensationType.Hourly:
      return short ? '/hour' : 'per hour';

    case CompensationType.Daily:
      return short ? '/day' : 'per day';

    case CompensationType.Weekly:
      return short ? '/week' : 'per week';

    case CompensationType.Monthly:
      return short ? '/month' : 'per month';

    case CompensationType.ByDeliverable:
      return short ? '/project' : 'per project';

    default:
      return null;
  }
};

export const isContractor = classification => {
  return classification === CompensationType.IndependentContractor;
};
export const isEmployee = classification => {
  return classification === CompensationType.Employee;
};

export const displayCompensationFrequency = compensationFrequency => {
  switch (compensationFrequency) {
    case CompensationFrequency.Weekly:
      return 'Weekly';

    case CompensationFrequency.Monthly:
      return 'Monthly';

    default:
      return 'Not provided';
  }
};

export const displayCompensationType = compensationType => {
  return CompensationTypeOptions.find(obj => obj.value === compensationType)?.label || 'Not provided';
};

export const displayRate = (amount, currencySymbol, compensationType) => {
  const rate = `${currencySymbol}${amount}${mapCompensationTypeToPaymentLabel(compensationType, true)}`;
  return rate;
};

export const displayAllocation = (allocation, type) => {
  if (!allocation || type === CompensationType.ByDeliverable) {
    return '';
  }

  return `${allocation}h/week`;
};

export const displayStartDate = (startDate, format = 'MMMM Do, YYYY') => {
  return moment(startDate).format(format);
};

export const displayEndDate = (endDate, format = 'MMMM Do, YYYY') => {
  return endDate ? moment(endDate).format(format) : 'Ongoing';
};
