import React from 'react';

import Dialog from '../../../../dialog';
import Map from '../../../../map';

const ViewScopeDialog = ({ scope, isOpen, onClose }) => {
  const getMapProps = location => {
    try {
      if (!location?.json) return;
      const instance = JSON.parse(location.json);
      return instance.place_id ? { placeId: instance.place_id } : { query: instance.formatted_address };
    } catch {
      return {};
    }
  };

  return (
    <Dialog title={scope?.prettyName || 'Global'} isOpen={isOpen} onClose={onClose}>
      <Map {...getMapProps(scope)} />
    </Dialog>
  );
};

export default ViewScopeDialog;
