import { useEffect, useCallback, useRef } from 'react';
import { Popover } from '@blueprintjs/core';

import useDocuments from '../../../../state/use-documents';

import PropertiesForm from './properties-form';

import classes from './properties.module.scss';

const Properties = () => {
  const popoverRef = useRef(null);
  const { selection } = useDocuments();

  const renderTarget = useCallback(
    props => {
      const { isOpen: _isOpen, ...restProps } = props;

      if (selection?.action !== 'edit') {
        return <div style={{ width: 0, height: 0 }} {...restProps} />;
      }

      return (
        <div
          style={{
            left: selection?.macro?.config.style.left,
            top: selection?.macro?.config.style.top + selection?.macro?.config.style.height / 2,
            width: selection?.macro?.config.style.width,
            height: 0,
            position: 'relative',
          }}
          {...restProps}
        />
      );
    },
    [selection],
  );

  useEffect(() => {
    if (!selection) {
      return;
    }
    popoverRef.current?.reposition();
  }, [selection]);

  const isOpen = selection?.action === 'edit';
  return (
    <Popover
      popoverClassName={classes.propertiesPopover}
      ref={popoverRef}
      isOpen={isOpen}
      content={<PropertiesForm />}
      renderTarget={renderTarget}
      usePortal={false}
      enforceFocus={true}
      autoFocus={true}
      transitionDuration={0}
      captureDismiss={true}
    />
  );
};

export default Properties;
