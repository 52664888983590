import React, { useState, useEffect } from 'react';
import moment from 'moment';

import useApi from '../../../../hooks/use-api';

import { getTimeRecords } from '../../../../apis/supplier/time';
import { getEngagements } from '../../../../apis/supplier/engagements';

export const QuickTimekeepingContext = React.createContext({});

const getWeekdaysArray = date => {
  const startOfWeek = moment(date).startOf('isoWeek');
  return [...Array(7).keys()].map(i => startOfWeek.clone().add(i, 'days'));
};

const QuickTimekeepingProvider = ({ children }) => {
  const [week, setWeek] = useState({
    date: moment(),
    weekdays: getWeekdaysArray(moment()),
  });
  const [dialog, setDialog] = useState({ open: false, type: undefined, date: undefined, data: undefined, title: undefined });

  const getTimeRecordsApi = useApi(args => getTimeRecords({ ...args }), { skip: true });
  const getEngagementsApi = useApi(getEngagements);

  const changeDate = date => {
    setWeek({ date, weekdays: getWeekdaysArray(date) });
  };

  const openDialog = ({ type, date, data, ...rest }) => {
    let title = '';
    switch (type) {
      case 'day':
        title = `Add or update work or break sessions`;
        break;

      case 'deliverables':
        title = data ? `Update deliverable record` : `Record new deliverable`;
        break;

      case 'expenses':
        title = data ? `Update expense record` : `Record new expense`;
        break;

      default:
        title = '';
    }

    setDialog({ open: true, type, date, data, title, ...rest });
  };

  const closeDialog = () => setDialog({ open: false, type: undefined, date: undefined, title: undefined });

  const loadRecords = () => {
    const args = getCalendarArguments(week.date);
    void getTimeRecordsApi.request(args);
  };

  useEffect(() => {
    void loadRecords();
  }, [week]);

  const loading = getTimeRecordsApi.loading || getEngagementsApi.loading;
  const timeRecords = getTimeRecordsApi.response;
  const engagements = getEngagementsApi.response;

  return (
    <QuickTimekeepingContext.Provider
      value={{
        date: week.date,
        weekdays: week.weekdays,
        dialog,
        loadRecords,
        changeDate,
        loading,
        engagements,
        timeRecords,
        setDialog,
        openDialog,
        closeDialog,
      }}
    >
      {children}
    </QuickTimekeepingContext.Provider>
  );
};

export default QuickTimekeepingProvider;

const getCalendarArguments = date => {
  return {
    start: moment(date).clone().startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment(date).clone().endOf('isoWeek').add(1, 'day').format('YYYY-MM-DD'),
  };
};
