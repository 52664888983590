import React from 'react';

import { ElementTypes } from '../../../../enumerations/field-definitions';

import EmailConfigForm from './email-config';
import DivConfigForm from './div-config-form';
import TextConfigForm from './text-config-form';
import ButtonConfigForm from './button-config-form';
import LinkConfigForm from './link-config-form';
import ImageConfigForm from './image-config-form';
import TableConfigForm from './table-config-form';
import ColumnConfigForm from './column-config-form';

const PropertiesForm = ({ availableMacros, content, iterators, selection, dispatch }) => {
  const { config, contentItem, path } = selection;

  if (config) {
    const updateFormConfig = values => {
      dispatch({ type: 'EMAIL_CONFIGURATOR_UPDATE_CONFIG', data: values });
    };

    return <EmailConfigForm availableMacros={availableMacros} config={config} onSubmit={updateFormConfig} />;
  }

  const handleUpdateContentItem = values => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_UPDATE_CONTENT', data: { contentItem: values, path } });
  };

  switch (contentItem.elementType) {
    case ElementTypes.TEXT:
      return <TextConfigForm availableMacros={availableMacros} contentItem={contentItem} onSubmit={handleUpdateContentItem} />;

    case ElementTypes.BUTTON:
      return <ButtonConfigForm availableMacros={availableMacros} contentItem={contentItem} onSubmit={handleUpdateContentItem} />;

    case ElementTypes.LINK:
      return <LinkConfigForm availableMacros={availableMacros} contentItem={contentItem} onSubmit={handleUpdateContentItem} />;

    case ElementTypes.IMAGE:
      return <ImageConfigForm availableMacros={availableMacros} contentItem={contentItem} onSubmit={handleUpdateContentItem} />;

    case ElementTypes.TABLE:
      return <TableConfigForm availableMacros={availableMacros} contentItem={contentItem} iterators={iterators} onSubmit={handleUpdateContentItem} />;

    case ElementTypes.COLUMN: {
      let table;
      for (let i = 0; i < path.length - 1; i++) {
        table = content[path[i]];
      }
      return (
        <ColumnConfigForm
          availableMacros={availableMacros}
          contentItem={contentItem}
          iterators={iterators}
          tableIterator={table?.iterator}
          onSubmit={handleUpdateContentItem}
        />
      );
    }

    case ElementTypes.DIV:
    default:
      return <DivConfigForm availableMacros={availableMacros} contentItem={contentItem} onSubmit={handleUpdateContentItem} />;
  }
};

export default PropertiesForm;
