import React from 'react';
import { Button, Drawer } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

import { useEmailConfigurator } from '../../../context';
import PropertiesForm from './forms';

import classes from './properties.module.scss';

const Properties = () => {
  const [{ availableMacros, content, iterators, selection }, dispatch] = useEmailConfigurator();

  const clearSelection = () => {
    dispatch({ type: 'EMAIL_CONFIGURATOR_CLEAR_SELECTION' });
  };

  return (
    <div className={classes.properties}>
      <Drawer isOpen={Boolean(selection)} position="left" usePortal={false} size={300} className={classes.drawer} onClose={clearSelection}>
        <div className={classes.header}>
          <div className={classes.main}>
            <div className={classes.title}>{selection?.contentItem?.title || selection?.contentItem?.text}</div>
            <div className={classes.description}>{selection?.contentItem?.elementType} properties</div>
          </div>
          <Button minimal icon="cross" onClick={clearSelection} />
        </div>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.content}>
            {selection && (
              <PropertiesForm availableMacros={availableMacros} content={content} iterators={iterators} selection={selection} dispatch={dispatch} />
            )}
          </div>
        </SimpleBar>
      </Drawer>
    </div>
  );
};

export default Properties;
