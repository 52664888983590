import { evaluateCondition } from '../domain/operator-definitions';
import { getIn, setIn } from 'formik';

export const buildInitialValues = (template, page, values) => {
  const isVisible = (item, _values) => evaluateCondition(template, _values, item?.visibility);
  let initialValues = { ...values };

  const scores = template?.categories?.reduce((acc, category) => {
    acc[category.name] = 0;
    return acc;
  }, {});

  page?.groups?.forEach(g => {
    if (isVisible(g, initialValues)) {
      if (!initialValues[page.id]) {
        initialValues[page.id] = {};
      }

      g.fields?.forEach(f => {
        if (!initialValues[page.id][g.id]) {
          initialValues[page.id][g.id] = {};
        }

        let value = initialValues[page.id][g.id][f.id];
        if (isVisible(f, initialValues)) {
          if (value === undefined) {
            const instanceValue = f.path && getIn(values, f.path);
            if (instanceValue) {
              initialValues[page.id][g.id][f.id] = instanceValue;
            } else if (f.defaultValue) {
              initialValues[page.id][g.id][f.id] = f.defaultValue;
            }
          }
        } else if (value) {
          initialValues[page.id][g.id][f.id] = undefined;
        }

        // calculate score
        if (f.category) {
          if (value !== undefined && value !== null && value !== '') {
            const questions = f.options;
            const answerIdx = questions.findIndex(q => q.value === value);
            const scoreValue = f.score?.[answerIdx];
            if (scoreValue !== undefined && scoreValue !== null && scores[f.category] !== undefined && !Number.isNaN(Number(scoreValue))) {
              scores[f.category] += Number(scoreValue);
            }
          }
        }
      });
    } else {
      setIn(initialValues, `${page.id}.${g.id}`, undefined);
    }
  });

  return { ...initialValues, ...scores };
};
