import React, { useState } from 'react';
import { Button, Popover } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

import Form, { Field } from '../../../../../../form';
import Fields from '../../../../../../fields';

import classes from './custom-fields.module.scss';

const Score = ({ propertiesForm }) => {
  const [open, setOpen] = useState(false);

  const field = propertiesForm.values;

  const getOptions = () => field?.options || [];

  const getInitialScores = () => {
    return getOptions().map((_, i) => field?.score?.[i] || 0);
  };

  return (
    <React.Fragment>
      <div className={classes.botPad15}>
        <div className={classes.groupTitle}>Answer scoring</div>
        <div className={classes.groupDescription}>Configure answer scores, which are used for classification processes.</div>
      </div>
      <Field name="score" outline={false} minimal={true} showError={false}>
        {() => (
          <div className={classes.score}>
            <Popover
              isOpen={open}
              usePortal={true}
              popoverClassName={classes.popover}
              disabled={!getOptions()?.length}
              content={
                <SimpleBar style={{ width: '300px', maxHeight: `50vh` }}>
                  <div className={classes.content}>
                    <div className={classes.inner}>
                      <Form
                        initialValues={{ ...field, score: getInitialScores() }}
                        onSubmit={({ score }) => {
                          propertiesForm.setFieldValue('score', score);
                          propertiesForm.submitForm();
                          setOpen(false);
                        }}
                      >
                        <React.Fragment>
                          <div className={classes.title}>Answer scoring</div>
                          <div className={classes.description}>Configure answer scores, which are used for classification processes.</div>
                          {getOptions().map((o, i) => (
                            <Fields.Text key={i} label={`${o.label} score`} placeholder="Score" name={`score.${i}`} />
                          ))}

                          <div className={classes.row}>
                            <Button type="submit" outlined text="Submit" />
                            <Button outlined text="Cancel" onClick={() => setOpen(false)} />
                          </div>
                        </React.Fragment>
                      </Form>
                    </div>
                  </div>
                </SimpleBar>
              }
            >
              <Button
                fill
                alignText="left"
                outlined
                text="Scoring"
                icon="numerical"
                disabled={!getOptions()?.length || !field?.category}
                onClick={() => setOpen(true)}
              />
            </Popover>
          </div>
        )}
      </Field>
    </React.Fragment>
  );
};

export default Score;
