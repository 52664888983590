import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import { getColor } from '../../../../../utilities/common';

import WithConfirmation from '../../../../../hooks/with-confirmation';
import useApi from '../../../../../hooks/use-api';
import { removeTrackingRecord } from '../../../../../apis/supplier/time';

import { convertTimeTo12Hour } from '../../../../../utilities/time';
import { calculateShiftEnd, extractStartTime, displayDuration } from '../../../../../utilities/time/shift';
import { canBeEdited, trackingRecordStatusToLabel } from '../../../../../domain/tracking-record-status-type';

import classes from './shift-preview.module.scss';

const ShiftPreview = ({ shift, engagements, handleEdit, loadRecords }) => {
  const removeShiftApi = useApi(removeTrackingRecord, { skip: true });
  const engagement = engagements?.find(e => e.id === shift?.engagementId);

  const start = extractStartTime(shift.date);
  const end = calculateShiftEnd(start, shift.duration);
  const editable = canBeEdited(shift?.status);

  const removeShift = async () => {
    const response = await removeShiftApi.request(shift?.id);
    if (!response.error) {
      void loadRecords();
    }
  };

  return (
    <div className={classes.shift}>
      <div className={classes.body}>
        <div className={classes.indicator} style={{ borderColor: getColor(engagement?.clientName || '') }} />
        <div className={classes.info}>
          <div className={classes.engagement}>
            <div className={classes.title}>{engagement?.title}</div>
            <div className={classes.client}>{engagement?.clientName}</div>
          </div>
          {shift.notes && <div className={classes.description}>{shift.notes}</div>}
        </div>
        <div className={classes.actionsContainer}>
          <div className={classes.actions}>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} disabled={!editable} onClick={() => handleEdit(shift)} />
            <WithConfirmation messages={{ confirmButton: 'Remove' }}>
              <Button
                small
                outlined
                icon={<Icons.Trash size={16} strokeWidth={1.5} />}
                loading={removeShiftApi.loading}
                disabled={!editable}
                onClick={removeShift}
              />
            </WithConfirmation>
          </div>
        </div>
        <div className={classes.time}>
          <div>
            <span>Status:</span>
            <span>{trackingRecordStatusToLabel(shift.status)}</span>
          </div>
          <div>
            <span>Start:</span>
            <span>{convertTimeTo12Hour(start)}</span>
          </div>
          <div>
            <span>End:</span>
            <span>{convertTimeTo12Hour(end)}</span>
          </div>
          <div>
            <span>Duration:</span>
            <span>{displayDuration(shift.duration, 'short', true)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftPreview;
