import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';

import Avatar from '../../../avatar';

import { changeRole } from '../../../../state/identity/actions';

import classes from './role-switcher.module.scss';

const RoleSwitcher = ({ isMobile, memberships, membership, changeRole }) => {
  const [roleOptionsVisible, setRoleOptionsVisible] = useState(false);

  const roles = memberships?.map(m => {
    const id = `${m?.roleId}-${m?.organization?.id}`;
    const organization = m?.organization?.name;
    const logo = m?.organization?.logo;
    const icon = m?.organization?.icon;
    const role = m?.roleName;
    return { id, organization, role, logo, icon };
  });

  const activeRole = roles.find(r => r.id === `${membership?.roleId}-${membership?.organization?.id}`);

  return (
    <div className={classes.roleSwitcher}>
      <div className={classNames(classes.role, classes.active)}>
        <Avatar name={activeRole?.organization} url={activeRole?.icon} abbreviate classOverrides={classes.roleAvatar} />
        <div className={classes.details}>
          <div className={classes.organizationName}>{membership?.organization?.name || 'Not Available'}</div>
          <div className={classes.roleName}>{membership?.roleName || 'Not Available'}</div>
        </div>
      </div>
      <SimpleBar className={classNames(isMobile && classes.rolesWrapper, memberships.length > 1 && roleOptionsVisible ? classes.expanded : classes.collapsed)}>
        {
          // memberships.length > 1 &&
          // roleOptionsVisible &&
          memberships
            .filter(r => r?.roleId !== membership?.roleId || r?.organization?.id !== membership?.organization?.id)
            .map((role, index) => (
              <div
                key={index}
                className={classes.role}
                onClick={() => {
                  setRoleOptionsVisible(false);
                  changeRole(role);
                }}
              >
                <Avatar name={role?.organization?.name || 'Not Available'} url={role?.organization?.icon} abbreviate classOverrides={classes.roleAvatar} />
                <div className={classes.details}>
                  <div className={classes.organizationName}>{role?.organization?.name || 'Not Available'}</div>
                  <div className={classes.roleName}>{role?.roleName || 'Not Available'}</div>
                </div>
              </div>
            ))
        }
      </SimpleBar>
      {memberships.length > 1 && (
        <Button
          minimal
          fill
          className={classes.roleExpandButton}
          text={roleOptionsVisible ? 'Hide inactive roles' : `Show all roles (${roles.length})`}
          onClick={() => setRoleOptionsVisible(!roleOptionsVisible)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  memberships: state.identity.memberships,
  membership: state.identity.membership,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeRole,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoleSwitcher);
