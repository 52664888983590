import React from 'react';
import * as Icons from 'react-feather';

import Navigation from '../../components/navigation';

import Profile from '../common/profile';
import Privacy from '../common/privacy';
import Clients from './clients';
import Dashboard from './dashboard';
import Engagements from './engagements';
import Evaluations from './evaluations';
import Invoices from './invoices';
import Payrolls from './payrolls';
import Reporting from './reporting';
import Settings from './settings';
import Suppliers from './suppliers';
// import Support from './support';
import Time from './time';
import Users from './users';

const Platform = () => {
  return (
    <Navigation.Container>
      <Navigation.Item path="/dashboard" title="Dashboard" icon={Icons.Grid} component={Dashboard} index />
      <Navigation.Item path="/evaluations/*" title="Evaluations" icon={Icons.BookOpen} component={Evaluations} />
      <Navigation.Item path="/clients/*" title="Clients" icon={Icons.Briefcase} component={Clients} />
      <Navigation.Item path="/users/*" title="Users" icon={Icons.Users} component={Users} />
      <Navigation.Item path="/engagements/*" title="Engagements" icon={Icons.Briefcase} component={Engagements} />
      <Navigation.Item path="/suppliers/*" title="Suppliers" icon={Icons.Users} component={Suppliers} />
      <Navigation.Item path="/time/*" title="Time" icon={Icons.Clock} component={Time} />
      <Navigation.Item path="/payrolls" title="Payrolls" icon={Icons.Calendar} component={Payrolls} />
      <Navigation.Item path="/invoices" title="Invoices" icon={Icons.CreditCard} component={Invoices} />
      <Navigation.Item path="/reporting" title="Reporting" icon={Icons.BarChart} component={Reporting} />
      <Navigation.Item path="/settings/*" title="Settings" icon={Icons.Settings} component={Settings} />
      {/* <Navigation.Item path="/support" title="Support" icon={Icons.HelpCircle} component={Support} /> */}
      <Navigation.Item path="/profile" component={Profile} />
      <Navigation.Item path="/privacy" component={Privacy} />
    </Navigation.Container>
  );
};

export default Platform;
