import React, { useContext } from 'react';
import classNames from 'classnames';

import Score from './components/score';
import { TypeSpecificProperties } from './components/type-specific-properties';
import { TypeSpecificValidations } from './components/type-specific-validations';

import VisibilityConditions from './components/visibility';

import { ObjectTypes } from '../../../domain/types';
import { YesNoOptions } from '../../../domain/validation';

import Fields from '../../../../../fields';
import Form from '../../../../../form';
import { WizardContext } from '../../../state';
import { WizardSelectionContext } from '../../../state/selection-provider';

import classes from '../properties.module.scss';

const FieldForm = () => {
  const { template, updateField } = useContext(WizardContext);
  const { getSelectionPath } = useContext(WizardSelectionContext);

  const selection = getSelectionPath();
  if (!selection?.length) {
    return null;
  }
  const [, page, group, field] = selection;

  return (
    <Form initialValues={{ ...field }} onSubmit={v => updateField(page, group, v)} enableReinitialize={true}>
      {form => (
        <React.Fragment>
          <div className={classes.group}>
            <Fields.Text label="Label" name="label" submitOnChange={true} />
            {![ObjectTypes.Paragraph, ObjectTypes.Link].includes(form.values.type) && (
              <Fields.Select
                name="showLabel"
                label="Show label ?"
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                submitOnChange={true}
              />
            )}
            <Fields.Textarea label="Description" name="description" submitOnChange={true} />
            <Fields.Select
              name="showDescriptionAsTooltip"
              label="Show description as tooltip ?"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              submitOnChange={true}
              showError={false}
            />
          </div>
          <TypeSpecificProperties form={form} />
          <div className={classes.group}>
            <Fields.Select
              name="disabled"
              label="Disabled"
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              submitOnChange={true}
              showError={false}
            />
          </div>
          <div className={classes.group}>
            <div className={classes.sliderControls}>
              <Fields.Slider
                label="Desktop width"
                name="width"
                min={5}
                stepSize={5}
                submitOnChange={true}
                labelRenderer={(v, { isHandleTooltip }) => (isHandleTooltip ? v : null)}
                showError={false}
              />
            </div>
            <div className={classes.sliderControls}>
              <Fields.Slider
                label="Mobile width"
                name="mobileWidth"
                min={5}
                stepSize={5}
                submitOnChange={true}
                labelRenderer={(v, { isHandleTooltip }) => (isHandleTooltip ? v : null)}
                showError={false}
              />
            </div>
          </div>
          {form.values.type !== ObjectTypes.Paragraph && (
            <div className={classes.group}>
              <TypeSpecificValidations form={form} template={template} />
            </div>
          )}
          <div className={classes.group}>
            <Fields.Select
              label="Hidden"
              name="hidden"
              submitOnChange={true}
              options={YesNoOptions}
              showError={false}
              disabled={field?.mandatory && (form.values.defaultValue === undefined || form.values.defaultValue === null)}
            />
            <div className={classes.hiddenRequiredFieldDescription}>
              {field?.mandatory && field?.required && field?.hidden && !field?.defaultValue ? 'Default value is required for hidden fields.' : ''}
            </div>
          </div>
          <div className={classes.group}>
            <VisibilityConditions form={form} />
          </div>
          {![ObjectTypes.Paragraph, ObjectTypes.Link].includes(field?.type) && (
            <div className={classes.group}>
              <div className={classes.groupTitle}>Assign category</div>
              <div className={classNames(classes.groupDescription, classes.botPad15)}>
                Field/Question category used primarily for classification processing.
              </div>
              <Fields.Select
                label="Category"
                name="category"
                submitOnChange={true}
                options={(template?.categories || []).map(({ name }) => ({ value: name, label: name }))}
                disabled={!template?.categories?.length}
                showError={false}
              />
            </div>
          )}
          {[ObjectTypes.Select].includes(field?.type) && (
            <div className={classes.group}>
              <Score propertiesForm={form} />
            </div>
          )}
          <div className={classes.group}>
            <Fields.Text label="Identifier" name="id" disabled={true} />
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default FieldForm;
