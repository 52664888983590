import React from 'react';
import { MenuDivider, MenuItem } from '@blueprintjs/core';
import _ from 'lodash';

const FlowType = {
  Engagement: 'Engagement',
  EngagementUpdate: 'EngagementUpdates',
  EngagementTermination: 'EngagementTermination',
};

const WizardGroup = {
  Common: 'Common',
  Employee: 'Employee',
  Contractor: 'IndependentContractor',
  Other: 'Other',
};

function getFlowGroupNameByWizardName(wizardName) {
  if (wizardName?.includes('EngagementUpdate')) {
    return FlowType.EngagementUpdate;
  } else if (wizardName?.includes('EngagementTermination')) {
    return FlowType.EngagementTermination;
  }
  return FlowType.Engagement;
}
function getWizardTypeByWizardName(wizardName) {
  if (wizardName?.startsWith('Employee')) {
    return WizardGroup.Employee;
  } else if (wizardName?.startsWith('Independent')) {
    return WizardGroup.Contractor;
  } else if (wizardName?.startsWith('Supplier')) {
    return WizardGroup.Common;
  }
  return WizardGroup.Other;
}
function getWizardTypeByFlowGroupAndWizardName(flowGroup, wizardName) {
  if (flowGroup === FlowType.Engagement) {
    return getWizardTypeByWizardName(wizardName);
  }
  return WizardGroup.Common;
}

function getSubmenuTitleByFlowTypeAndWizardGroup(flowType, wizardGroup) {
  return `${_.startCase(wizardGroup)} Wizards`;
}

const FlowMenu = ({ sortedTypes, onAdd, isTypeDisabled }) => {
  const { flows, grouped } = sortedTypes.reduce(
    (reducer, group) => {
      if (group.name === 'Wizards') {
        group?.recordTypes?.forEach(wizard => {
          const wizardName = wizard?.name?.split('|')?.[1];
          const flowGroup = getFlowGroupNameByWizardName(wizardName);
          if (!reducer.grouped[flowGroup]) {
            reducer.grouped[flowGroup] = {
              flowGroup,
              wizards: {},
            };
          }
          const wizardType = getWizardTypeByFlowGroupAndWizardName(flowGroup, wizardName);
          if (!reducer.grouped[flowGroup].wizards[wizardType]) {
            reducer.grouped[flowGroup].wizards[wizardType] = [];
          }
          reducer.grouped[flowGroup].wizards[wizardType].push(wizard);
        });
      } else if (group.name === 'Flows') {
        reducer.flows = group;
      }
      return reducer;
    },
    { flows: [], grouped: {} },
  );

  return (
    <MenuItem text={flows?.name} popoverProps={{ interactionKind: 'click' }}>
      {flows?.recordTypes?.length > 0 &&
        flows?.recordTypes?.map((recordType, i) => {
          const flowName = recordType?.name?.split('|')?.[1];
          const flowKey = flowName?.replace('Flow', '');
          const flowWizards = Object.keys(grouped?.[flowKey]?.wizards || {});

          const flowSubmenu = [];
          flowWizards.forEach((wizardGroup, j) => {
            flowSubmenu.push(<MenuDivider title={getSubmenuTitleByFlowTypeAndWizardGroup(flowKey, wizardGroup)} key={wizardGroup} />);

            const list = grouped?.[flowKey]?.wizards?.[wizardGroup];
            list?.forEach((wizard, k) => {
              const wizardName = wizard?.name?.split('|')?.[1];
              const wizardText = _.startCase(wizardName);
              flowSubmenu.push(
                <MenuItem
                  text={wizardText}
                  disabled={isTypeDisabled(wizard)}
                  onClick={() => onAdd({ configurationType: wizard?.name })}
                  key={wizard?.name}
                  popoverProps={{ interactionKind: 'click' }}
                />,
              );
            });
          });

          const text = _.startCase(flowName);
          return (
            <MenuItem key={i} text={text} popoverProps={{ interactionKind: 'click' }}>
              <MenuDivider title="Flow" />
              <MenuItem text={text} disabled={isTypeDisabled(recordType)} onClick={() => onAdd({ configurationType: recordType?.name })} key={i} />
              {flowSubmenu}
            </MenuItem>
          );
        })}
    </MenuItem>
  );
};

export default FlowMenu;
