import { uuid } from '../../../../utilities/common';
import MacroTypes from '../enumerations/macro-types';
import getMacroRender from './get-macro-render';

const defaultWidth = 80;
const defaultHeight = 18;
const checkboxSquareSize = 16;
const defaultStyle = {
  position: 'absolute',
  whiteSpace: 'nowrap',
  left: 0,
  top: 0,
  width: defaultWidth,
  height: defaultHeight,
};

const createValidStyleObj = styleConfig => {
  const convertCamelCaseToDashCase = camel => {
    return camel.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
  };

  const convertValueToStyle = (name, value) => {
    const usePixels = ['width', 'height', 'left', 'top'];
    if (usePixels.includes(name)) {
      return `${value}px`;
    }
    return value;
  };

  const style = {};
  for (let key in styleConfig) {
    const nameInDashedCase = convertCamelCaseToDashCase(key);
    const value = convertValueToStyle(key, styleConfig[key]);
    style[nameInDashedCase] = value;
  }
  return style;
};

const getMacroConfig = ({ type, config }) => {
  switch (type) {
    case MacroTypes.SUBSTRING:
    case MacroTypes.CELL:
      return {
        indices: config.indices,
      };

    case MacroTypes.DATE:
      return {
        dateFormat: config.dateFormat,
      };

    case MacroTypes.CHECKBOX:
      return {
        operator: config.operator,
        comparedToValue: config.comparedToValue,
      };

    case MacroTypes.CUSTOM_JS:
      return {
        customJS: config.customJS,
      };

    default:
      return {};
  }
};

const getMacroStyle = ({ type, config }) => {
  const style = {
    ...config?.style,
    width: type === MacroTypes.CHECKBOX ? checkboxSquareSize : config?.style?.width,
    height: type === MacroTypes.CHECKBOX ? checkboxSquareSize : config?.style?.height,
  };
  Object.keys(defaultStyle).forEach(key => {
    if (style[key] === undefined || style[key] === null) {
      style[key] = defaultStyle[key];
    }
  });

  return style;
};

export const getNewMacro = ({ left, top, displayName }) => {
  return {
    id: uuid(),
    type: MacroTypes.DEFAULT,
    displayName,
    render: '',
    config: {
      style: {
        ...defaultStyle,
        left,
        top,
      },
    },
  };
};

export const formatMacro = ({ id, type, name, displayName, label, example, roleId, config, signatories, render }, includeExample) => {
  const fromScratch = type === MacroTypes.FROM_SCRATCH;
  const style = fromScratch ? {} : getMacroStyle({ type, config });
  const macroConfig = fromScratch ? {} : getMacroConfig({ type, config });
  const macro = {
    id,
    type,
    name,
    example,
    displayName,
    roleId,
    signatories: fromScratch ? signatories : undefined,
    style: createValidStyleObj(style),
  };
  macro.config = fromScratch ? undefined : { ...macroConfig, style };
  macro.render = fromScratch ? render : getMacroRender(macro, includeExample);
  return macro;
};

// todo this function can be deleted once we migrate all documents to the new format
export const backwardsCompatibleMacro = (obj, fromScratch) => {
  if (fromScratch) {
    const macro = {
      id: obj.id,
      type: MacroTypes.FROM_SCRATCH,
      name: MacroTypes.FROM_SCRATCH,
      displayName: obj.label,
      example: obj.example,
      signatories: obj.signatories || [],
      render: obj.render,
      style: {},
    };
    return macro;
  }

  const isOld = !!obj.config.width;
  if (isOld) {
    // list of variables that are modified in the new format or have a new default value
    const name = obj.type === MacroTypes.CUSTOM_JS ? '' : obj.name.split(' || ')[0];
    let height = defaultHeight;
    let displayName = obj.label;

    switch (obj.type) {
      case MacroTypes.CHECKBOX:
        height = checkboxSquareSize;
        break;

      case MacroTypes.SIGNATURE:
        displayName = name
          .split('.')[2]
          .split(/(?=[A-Z])/)
          .concat('Signature')
          .join(' ');
        height = obj.config.height;
        break;

      case MacroTypes.CUSTOM_JS:
        displayName = obj.config.render.replace(/"/g, '') || 'Custom JS';
        height = obj.config.height;
        break;

      default:
        break;
    }
    // 25px is the previous height of the macro elements
    const top = obj.type === MacroTypes.CHECKBOX ? obj.config.top : Math.round(obj.config.top + (25 - height) / 2);

    const macro = {
      id: obj.id,
      name,
      displayName,
      type: obj.type || MacroTypes.DEFAULT,
      example: obj.example,
      roleId: obj.roleId,
      config: {
        style: {
          top,
          height,
          width: obj.config.width,
          left: obj.config.left,
          position: obj.config.position,
          whiteSpace: obj.config['white-space'],
        },
        customJS: obj.config.render,
        indices: obj.config.indices,
        operator: obj.config.operator,
        comparedToValue: obj.config.comparedToValue,
        dateFormat: obj.config.dateFormat,
      },
    };
    return formatMacro(macro);
  }

  return obj;
};
