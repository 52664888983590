import React from 'react';
import classNames from 'classnames';
import * as Icons from 'react-feather';

import Box from '../../../../box';
import Form from '../../../../form';
import File from '../../../../fields/file';

import classes from '../appearance.module.scss';

const UploadIcon = ({ url, api, onUploaded, className }) => {
  return (
    <Box title="Icon" outlined monochrome loading={false} className={className}>
      <div className={classes.boxContent}>
        <div className={classes.boxImage}>
          <div className={classNames(classes.image, classes.icon)}>
            <Form initialValues={{ logo: null }} onSubmit={() => null}>
              <File
                label="Select icon"
                mimeTypes={'image/*'}
                name="logo"
                maxSize={5}
                crop={{ aspectRatio: 1, width: 90 * 5, height: 90 * 5 }}
                api={api}
                onUploaded={onUploaded}
                outline={false}
                minimal={true}
                showError={false}
              >
                <div className={classes.splash} style={{ backgroundImage: url ? `url(${url})` : '' }}>
                  <div className={classes.upload}>
                    <Icons.Upload size={30} strokeWidth={1.5} />
                  </div>
                </div>
              </File>
            </Form>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default UploadIcon;
