import { Button } from '@blueprintjs/core';
import React from 'react';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';

import useApi from '../../../../../../../../hooks/use-api';
import { EngagementLocationType, EngagementLocationTypeOptions } from '../../../../../../../../domain/engagement-location-type';
import { getWizardLookupData } from '../../../../../../../../apis/wizards';
import { getWorkLocationOptions, updateEngagement } from '../../../../../../../../apis/platform/engagements';

import classes from '../../../details.module.scss';

const GeneralDetailsForm = ({ isOpen = false, onClose, onSave, engagement }) => {
  const lookupApi = useApi(getWizardLookupData, { skip: true });
  const getWorkLocationOptionsApi = useApi(getWorkLocationOptions, { skip: true });
  const updateEngagementApi = useApi(updateEngagement, { skip: true });

  const submit = async values => {
    const { onsite, offsite, ...rest } = values;
    rest.locationId = rest.locationType === EngagementLocationType.OnSite ? onsite : offsite;
    await updateEngagementApi.request({ engagementId: engagement?.id, engagement: rest });
    onSave();
  };

  const loading = updateEngagementApi.loading;

  return (
    <Dialog title="Engagement details" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          title: engagement?.title,
          description: engagement?.description,
          startDate: engagement?.startDate,
          endDate: engagement?.endDate,
          status: engagement?.status,
          countryId: engagement?.country?.id,
          locationType: engagement?.locationType,
          onsite: engagement?.locationType === EngagementLocationType.OnSite ? engagement?.location?.id : undefined,
          offsite: engagement?.locationType === EngagementLocationType.OffSite ? engagement?.location?.id : undefined,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title is required'),
          description: Yup.string().required('Description is required'),
          startDate: Yup.string().required('Start date is required'),
          status: Yup.string().required('Status is required'),
          countryId: Yup.string().required('Country is required'),
          locationType: Yup.string().required('Location type is required'),
          onsite: Yup.string().when('locationType', {
            is: EngagementLocationType.OnSite,
            then: schema => schema.required('Work location is required'),
          }),
          offsite: Yup.string().when('locationType', {
            is: EngagementLocationType.OffSite,
            then: schema => schema.required('Work location is required'),
          }),
        })}
        onSubmit={submit}
      >
        {({ values }) => (
          <div>
            <Fields.Text label="Title" name="title" />
            <Fields.Textarea label="Description" name="description" />
            <div className={classes.hRow}>
              <Fields.Date fill label="Start date" name="startDate" />
              <Fields.Date fill label="End date" name="endDate" />
            </div>
            <Fields.Select
              name="countryId"
              label="Country"
              remoteOptions={{
                loading: lookupApi.loading,
                request: () => lookupApi.request('client-available-countries', { clientId: engagement?.clientId }),
                mapper: result => result,
                filter: 'remote',
              }}
            />
            <Fields.Select name="locationType" label="Location type" options={EngagementLocationTypeOptions} />
            {values.locationType === EngagementLocationType.OnSite && (
              <Fields.Select
                name="onsite"
                label="Work location"
                remoteOptions={{
                  loading: getWorkLocationOptionsApi.loading,
                  request: () => getWorkLocationOptionsApi.request({ engagementId: engagement?.id, type: EngagementLocationType.OnSite }),
                  mapper: result => result?.map(address => ({ label: address?.prettyName, value: address?.id })),
                  filter: 'remote',
                }}
              />
            )}
            {values.locationType === EngagementLocationType.OffSite && (
              <Fields.Select
                name="offsite"
                label="Work location"
                remoteOptions={{
                  loading: getWorkLocationOptionsApi.loading,
                  request: () => getWorkLocationOptionsApi.request({ engagementId: engagement?.id, type: EngagementLocationType.OffSite }),
                  mapper: address => [{ label: address.prettyName, value: address.id }],
                  filter: 'remote',
                }}
              />
            )}
            <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
          </div>
        )}
      </Form>
    </Dialog>
  );
};

export default GeneralDetailsForm;
