import { uuid } from '../../../../utilities/common';

import { ObjectTypes } from './types';
import Field from './field';

export default class Group {
  constructor(group = {}) {
    const {
      id = uuid(),
      title = 'New Group',
      description = '',
      showTitle = true,
      block = true,
      width = 100,
      mobileWidth = 100,
      fields = [],
      visibility = {},
      metadata = undefined,
    } = group;

    this.id = id;
    this.type = ObjectTypes.Group;
    this.title = title;
    this.description = description;
    this.showTitle = showTitle;
    this.block = block;
    this.width = width;
    this.mobileWidth = mobileWidth;
    this.fields = fields?.map(f => new Field(f));
    this.metadata = metadata;

    if (visibility?.field) {
      // if the field is set then set rules to support backward compatibility
      this.visibility = {
        combinator: 'AND',
        rules: [
          {
            field: visibility.field,
            operator: visibility.operator,
            value: visibility.value,
          },
        ],
      };
    } else {
      this.visibility = visibility;
    }
  }
}
