import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Documents from './containers/documents';
import Email from './containers/email';
import Wizards from './containers/wizards';
import System from './containers/system';

const Settings = () => {
  return (
    <Routes>
      <Route path="/wizards/:id/:recordId" Component={Wizards} />
      <Route path="/emails/:id/:recordId" Component={Email} />
      <Route path="/documents/:id/:recordId" Component={Documents} />
      <Route path="/" Component={System} />
    </Routes>
  );
};

export default Settings;

export { Documents, Email, Wizards };
