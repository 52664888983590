import React from 'react';
import moment from 'moment';
import { sortBy } from 'lodash';

import Table from '../../../../../../../components/table';
import { getClientDisplayName } from '../../../../../../../utilities/client';

import { engagementStatusToLabel } from '../../../../../../../domain/engagement-status-type';

import classes from '../../details.module.scss';

const Engagements = ({ api }) => {
  const formatDates = d =>
    !!d?.startDate && !!d?.endDate
      ? `${moment(d?.startDate).format('DD/MM/YYYY')} - ${moment(d?.endDate).format('DD/MM/YYYY')}`
      : `${moment(d?.startDate).format('DD/MM/YYYY')} - Ongoing`;

  return (
    <div className={classes.tab}>
      <Table.Container data={sortBy(api?.response?.engagements, 'status', 'startDate', 'endDate')} loading={api?.loading} dark>
        <Table.Columns.TwoLines showOnMobile name="Title" width="2fr" lines={[d => d?.title, d => getClientDisplayName(d?.clientName, d?.parentClientName)]} />
        <Table.Columns.TwoLines showOnMobile name="Duration" lines={[d => engagementStatusToLabel(d?.status), d => formatDates(d)]} />
        <Table.Columns.Actions showOnMobile actions={[{ name: 'View', href: d => `/engagements/${d?.id}` }]} />
      </Table.Container>
    </div>
  );
};

export default Engagements;
