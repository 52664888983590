import { Radio as BluePrintRadio, RadioGroup as BluePrintRadioGroup, Classes } from '@blueprintjs/core';
import cn from 'classnames';
import React from 'react';

import { Field } from '../../form';
import { itemsEqual, labelRenderer, loadingArray, valueRenderer } from './helper';
import styles from './radio-group.module.scss';

const RadioGroupField = ({ classes, options, label, description, inline, disabled, name, validate, loading, submitOnChange = false }) => (
  <Field
    classes={{ ...classes, wrapper: cn(styles.wrapper, classes?.wrapper) }}
    label={label}
    description={description}
    name={name}
    validate={validate}
    outline={false}
  >
    {({ field, form }) => {
      const onChange = event => {
        const item = options.find(item => itemsEqual(item, event.currentTarget.value));
        form.setFieldValue(name, valueRenderer(item));
        if (submitOnChange) {
          form.submitForm();
        }
      };

      return (
        <BluePrintRadioGroup disabled={disabled} inline={inline} onChange={onChange} selectedValue={field.value}>
          {(loading ? loadingArray : options)?.map(item => {
            const value = valueRenderer(item);
            return (
              <BluePrintRadio className={cn(styles.label, classes?.radio, loading && Classes.SKELETON)} label={labelRenderer(item)} value={value} key={value} />
            );
          })}
        </BluePrintRadioGroup>
      );
    }}
  </Field>
);

export default RadioGroupField;
