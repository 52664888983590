import React from 'react';
import { Icon, Menu, MenuItem } from '@blueprintjs/core';

const DepartmentsMenu = ({ setDialog }) => {
  return (
    <Menu>
      <MenuItem text="New" label={<Icon icon="plus" />} onClick={() => setDialog({ open: true })} />
    </Menu>
  );
};

export default DepartmentsMenu;
