import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@blueprintjs/core';

import Page from '../../../components/page';

import { getColor, getTimeBasedGreeting } from '../../../utilities/common';

import classes from './dashboard.module.scss';

const Dashboard = ({ user }) => {
  const options = [
    { title: 'Employee', info: 'Register as an individual, looking for work.', action: () => null },
    { title: 'Independent contractor', info: 'Register as a legal entity, looking for work.', action: () => null },
    { title: 'Company', info: 'Register as a legal entity, looking for workforce.', action: () => null },
    { title: 'Vendor', info: 'Register as a legal entity, who can provide workforce services.', action: () => null },
  ];

  return (
    <Page title={`${getTimeBasedGreeting()}, ${user?.personalDetails?.firstName}.`}>
      <div className={classes.dashboard}>
        <div className={classes.heading}>You are currently not a member of any organization, please choose how would you like to proceed.</div>
        <div className={classes.options}>
          {options.map(({ title, info, action }, index) => (
            <div key={index} className={classes.option}>
              <div className={classes.title} style={{ color: getColor(title) }}>
                {title}
              </div>
              <div className={classes.info}>
                <p>{info}</p>
                <ul>
                  <li>lorem ipsum sit amet dolor.</li>
                  <li>lorem ipsum sit amet dolor.</li>
                  <li>lorem ipsum sit amet dolor.</li>
                  <li>lorem ipsum sit amet dolor.</li>
                  <li>lorem ipsum sit amet dolor.</li>
                </ul>
              </div>
              <div className={classes.buttons}>
                <Button outlined fill text="Register" onClick={action} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
