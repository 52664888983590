import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Drawer, Tooltip } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';

import Table from '../../../../../components/table';
import Dialog from '../../../../../components/dialog';
import Comments from '../../../../../components/time/comments';

import { trackingRecordStatusToLabel, canBeEdited } from '../../../../../domain/tracking-record-status-type';

import useApi from '../../../../../hooks/use-api';
import { removeTrackingRecord } from '../../../../../apis/supplier/time';
import { downloadDocument } from '../../../../../apis/documents';

import ShiftForm from './forms/shift';
import DeliverableForm from './forms/deliverable';
import ExpenseForm from './forms/expense';

import classes from './details.module.scss';

const TimeDetails = ({ reloadRecords, onClose, engagements, details }) => {
  const [engagement, setEngagement] = useState();
  const [shifts, setShifts] = useState([]);
  const [deliverables, setDeliverables] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [dialog, setDialog] = useState({ open: false, type: undefined, data: undefined });

  useEffect(() => {
    const { id, period } = details;
    const engagement = engagements?.find?.(e => id && e.engagementId === id && e.period === period);
    if (engagement) {
      setEngagement(engagement);

      setShifts(engagement?.records?.filter?.(r => r.type === 0)?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf()));
      setDeliverables(engagement?.records?.filter?.(r => r.type === 1)?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf()));
      setExpenses(engagement?.records?.filter?.(r => r.type === 2)?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf()));
    }
  }, [details?.id, details?.open, engagements]);

  const removeRecordApi = useApi(removeTrackingRecord, { skip: true });
  const removeRecord = async id => {
    await removeRecordApi.request(id);
    reloadRecords();
  };

  const onAddOrEditComplete = () => {
    reloadRecords();
    setDialog({ open: false, type: undefined, data: undefined });
  };

  if (!details) return null;

  const dateColumn = <Table.Columns.TwoLines showOnMobile name="Date" lines={[d => moment(d?.date).format('MMMM Do'), d => moment(d?.date).format('YYYY')]} />;
  const amountColumn = <Table.Columns.Bold showOnMobile width="90px" name="Amount" render={d => `${engagement?.currencySymbol}${d?.amount?.toFixed(2)}`} />;
  const statusColumn = <Table.Columns.Default showOnMobile width="90px" name="Status" render={d => trackingRecordStatusToLabel(d?.status)} />;
  const commentsColumn = <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />;
  const notesColumn = <Table.Columns.Default width="2fr" name="Notes" render={d => d?.notes} />;
  const receiptColumn = (
    <Table.Columns.Default
      showOnMobile
      width="32px"
      render={d => (
        <Tooltip disabled={!d?.receiptId} content="Download Receipt">
          <Button
            disabled={!d?.receiptId}
            outlined
            small
            icon={<Icons.Paperclip size={16} strokeWidth={1.25} />}
            onClick={() => {
              if (d?.receiptId) {
                void downloadDocument({ id: d?.receiptId, name: `Receipt-${d?.receiptId}` });
              }
            }}
          />
        </Tooltip>
      )}
    />
  );
  const actionsColumn = (
    <Table.Columns.Actions
      showOnMobile
      actions={[
        {
          name: 'Edit',
          onClick: d => setDialog({ open: true, type: d?.type === 0 ? 'shifts' : d?.type === 1 ? 'deliverables' : 'expenses', data: d }),
          disabled: d => !canBeEdited(d?.status),
        },
        {
          name: 'Remove',
          onClick: d => removeRecord(d?.id),
          confirmation: true,
          disabled: d => !canBeEdited(d?.status),
        },
      ]}
    />
  );

  const round = value => (value?.toString()?.length > 5 ? value.toFixed(2) : value);
  const getTotalHours = shifts => {
    const total = shifts?.reduce?.((a, b) => a + moment.duration(b?.duration).asHours(), 0);
    return round(total);
  };

  return (
    <Drawer isOpen={!!details?.open} onClose={onClose} className={classes.details} position="right" canEscapeKeyClose canOutsideClickClose>
      <div className={classes.header}>
        <div className={classes.title}>{`${engagement?.firstName} ${engagement?.lastName}`}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.engagement}>{engagement?.title}</div>
            <div className={classes.client}>{!!engagement?.parentClient ? `${engagement?.client} (via ${engagement?.parentClient})` : engagement?.client}</div>
          </div>

          <div className={classes.table}>
            <div className={classes.heading}>
              <div className={classes.title}>Shifts</div>
              <Button
                className={classes.addButton}
                outlined
                text="Add"
                onClick={() => {
                  setDialog({ open: true, type: 'shifts', data: {} });
                }}
              />
            </div>
            <div className={classes.data}>
              <Table.Container data={shifts} handleScroll={false}>
                {dateColumn}
                {notesColumn}
                {statusColumn}
                <Table.Columns.Default name="Start" width="60px" render={d => moment(d?.date).format('h:mm A')} />
                <Table.Columns.Default
                  name="End"
                  width="60px"
                  render={d => moment(d?.date).add(moment.duration(d?.duration).asMilliseconds(), 'milliseconds').format('h:mm A')}
                />
                <Table.Columns.Bold width="60px" render={d => `${round(moment.duration(d?.duration).asHours())}h`} />
                {commentsColumn}
                {actionsColumn}
              </Table.Container>
              <div className={classes.total}>
                <div className={classes.label}>Total Hours</div>
                <div className={classes.value}>{`${getTotalHours(shifts)}h`}</div>
              </div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.heading}>
              <div className={classes.title}>Deliverables</div>
              <Button className={classes.addButton} outlined text="Add" onClick={() => setDialog({ open: true, type: 'deliverables' })} />
            </div>
            <div className={classes.data}>
              <Table.Container data={deliverables} handleScroll={false}>
                {dateColumn}
                {notesColumn}
                {statusColumn}
                {amountColumn}
                {commentsColumn}
                {actionsColumn}
              </Table.Container>
              <div className={classes.total}>
                <div className={classes.label}>Total Amount</div>
                <div className={classes.value}>{`${engagement?.currencySymbol}${deliverables?.reduce?.((a, b) => a + b?.amount, 0).toFixed(2)}`}</div>
              </div>
            </div>
          </div>
          <div className={classes.table}>
            <div className={classes.heading}>
              <div className={classes.title}>Expenses</div>
              <Button className={classes.addButton} outlined text="Add" onClick={() => setDialog({ open: true, type: 'expenses' })} />
            </div>
            <div className={classes.data}>
              <Table.Container data={expenses} handleScroll={false}>
                {dateColumn}
                {receiptColumn}
                {notesColumn}
                {statusColumn}
                {amountColumn}
                {commentsColumn}
                {actionsColumn}
              </Table.Container>
              <div className={classes.total}>
                <div className={classes.label}>Total Amount</div>
                <div className={classes.value}>{`${engagement?.currencySymbol}${expenses?.reduce?.((a, b) => a + b?.amount, 0).toFixed(2)}`}</div>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
      <Dialog
        isOpen={dialog?.open}
        title={dialog?.data?.id ? 'Update record' : 'Create new record'}
        onClose={() => setDialog({ open: false, type: undefined, data: undefined })}
      >
        {dialog?.type === 'shifts' && <ShiftForm engagement={engagement} data={dialog?.data} onComplete={onAddOrEditComplete} />}
        {dialog?.type === 'deliverables' && <DeliverableForm engagement={engagement} data={dialog?.data} onComplete={onAddOrEditComplete} />}
        {dialog?.type === 'expenses' && <ExpenseForm engagement={engagement} data={dialog?.data} onComplete={onAddOrEditComplete} />}
      </Dialog>
    </Drawer>
  );
};

export default TimeDetails;
