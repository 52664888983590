import React from 'react';
import { Formik as FormikBase } from 'formik';
import FormBase from './form-base';

const Form = ({ children, className, validationSchema, initialValues, enableReinitialize, onSubmit, ...rest }) => (
  <FormikBase {...rest} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={enableReinitialize} onSubmit={onSubmit}>
    {form => (
      <FormBase className={className} errors={form.errors} values={form.values} submitCount={form.submitCount} autoComplete="OFF">
        {typeof children === 'function' ? children(form) : children}
      </FormBase>
    )}
  </FormikBase>
);

export default Form;
