import React from 'react';
import moment from 'moment';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../../../../components/box';
import { EngagementStatusOptions, engagementStatusToLabel } from '../../../../../../../domain/engagement-status-type';
import useApi from '../../../../../../../hooks/use-api';
import { cancelEngagement, getEngagementTermination, setEngagementStatus } from '../../../../../../../apis/platform/engagements';
import WithConfirmation from '../../../../../../../hooks/with-confirmation';

const EngagementTerminationStatus = ({ engagement, loading, onUpdate }) => {
  const getEngagementTerminationApi = useApi(getEngagementTermination, { params: { engagementId: engagement?.id }, skip: !engagement?.id });

  return (
    <Box title="Engagement status" outlined loading={loading || getEngagementTerminationApi?.loading} monochrome>
      <Box.Item label="Status">
        {`${engagementStatusToLabel(engagement?.status)}${engagement?.isDeleted ? ' (Canceled)' : ''}`}
        <Popover
          content={
            <Menu>
              {EngagementStatusOptions.map(es => (
                <WithConfirmation key={es.value} messages={{ question: `Set engagement status to ${es.label} ?`, confirmButton: 'Submit' }}>
                  <MenuItem
                    text={es.label}
                    onClick={async () => {
                      await setEngagementStatus({ engagementId: engagement?.id, status: es.value });
                      void onUpdate();
                    }}
                    shouldDismissPopover={false}
                  />
                </WithConfirmation>
              ))}
              <MenuDivider />
              <WithConfirmation messages={{ question: `Cancel engagement (engagement will be deleted/archived) ?`, confirmButton: 'Submit' }}>
                <MenuItem
                  text="Cancel"
                  onClick={async () => {
                    await cancelEngagement({ engagementId: engagement?.id });
                    void onUpdate();
                  }}
                  shouldDismissPopover={false}
                />
              </WithConfirmation>
            </Menu>
          }
        >
          <Button small outlined icon={<Icons.Settings size={16} strokeWidth={1.5} />} title="Change status" />
        </Popover>
      </Box.Item>
      {!!getEngagementTerminationApi?.response && (
        <React.Fragment>
          <Box.Item label="End date">{moment(getEngagementTerminationApi?.response?.endDate).format('MMMM Do, YYYY')}</Box.Item>
          <Box.Item label="Reason">{getEngagementTerminationApi?.response?.reason}</Box.Item>
          <Box.Item label="Notes">{getEngagementTerminationApi?.response?.notes}</Box.Item>
        </React.Fragment>
      )}
    </Box>
  );
};

export default EngagementTerminationStatus;
