import { getIn } from 'formik';

import { ObjectTypes } from './types';

const eq = { value: 'eq', label: 'Equal (=)', description: 'Check if field value is equal to provided value.' };
const neq = { value: 'neq', label: 'Not equal (≠)', description: 'Check if field value is not equal to provided value.' };
const lt = { value: 'lt', label: 'Less than (<)', description: 'Check if field value is less than provided value.' };
const lte = { value: 'lte', label: 'Less than or equal (≤)', description: 'Check if field value is less than or equal to provided value.' };
const gt = { value: 'gt', label: 'Greater than (>)', description: 'Check if field value is greater than provided value.' };
const gte = { value: 'gte', label: 'Greater than or equal (≥)', description: 'Check if field value is greater than or equal to provided value.' };
const isTrue = { value: 'isTrue', label: 'Is checked', description: 'Check if field value is true (if checkbox is checked).' };
const isFalse = { value: 'isFalse', label: 'Is not checked', description: 'Check if field value is false (if checkbox is unchecked).' };
const isFileUploaded = { value: 'isFileUploaded', label: 'Provided', description: 'Check if requested file is uploaded.' };
const isFileNotUploaded = { value: 'isFileNotUploaded', label: 'Not provided', description: 'Check if file is not uploaded (true if file not provided).' };
const regex = { value: 'regex', label: 'Regex expression (.*)', description: 'Checks whether a string is matched by the regular expression.' };
const startsWith = { value: 'startsWith', label: 'Starts with', description: 'Checks whether a string value starts with provided value.' };
const endsWith = { value: 'endsWith', label: 'Ends with', description: 'Checks whether a string value ends with provided value.' };
const contains = { value: 'contains', label: 'Contains', description: 'Checks whether a string or an array contains provided value.' };
const doesNotContain = {
  value: 'doesNotContain',
  label: 'Does not contain',
  description: 'Checks whether a string or an array does not contain provided value.',
};

export const Operators = {
  eq,
  neq,
  lt,
  lte,
  gt,
  gte,
  isTrue,
  isFalse,
  isFileUploaded,
  isFileNotUploaded,
  regex,
  startsWith,
  endsWith,
  contains,
  doesNotContain,
};

export const OperatorDefinitions = {
  [ObjectTypes.Text]: [eq, neq, regex, startsWith, endsWith, contains, doesNotContain],
  [ObjectTypes.Number]: [eq, neq, lt, lte, gt, gte],
  [ObjectTypes.Select]: [eq, neq, contains, doesNotContain],
  [ObjectTypes.Boolean]: [isTrue, isFalse],
  // [ObjectTypes.Date]: [],
  [ObjectTypes.Document]: [isFileUploaded, isFileNotUploaded],
};

export const evaluateCondition = (template, instance = {}, condition) => {
  const isGroup = !!condition?.rules?.length && !!condition?.combinator;
  if (!isGroup) {
    return compareValues(instance, condition);
  }

  return condition.combinator === 'AND'
    ? condition?.rules?.every?.(c => evaluateCondition(template, instance, c))
    : condition?.rules?.some?.(c => evaluateCondition(template, instance, c));
};

const compareValues = (instance = {}, condition = {}) => {
  if (!condition?.field) {
    return true;
  }

  if (!condition.operator) {
    throw new Error(`Invalid condition set on path ${condition.field}.`);
  }

  const value = getIn(instance, condition.field);

  switch (condition.operator) {
    case eq.value:
      return InvariantCompare.eq(value, condition?.value);

    case neq.value:
      return InvariantCompare.neq(value, condition?.value);

    case lt.value:
      return InvariantCompare.lt(value, condition?.value);

    case lte.value:
      return InvariantCompare.lte(value, condition?.value);

    case gt.value:
      return InvariantCompare.gt(value, condition?.value);

    case gte.value:
      return InvariantCompare.gte(value, condition?.value);

    case isTrue.value:
      return InvariantCompare.isTrue(value);

    case isFalse.value:
      return InvariantCompare.isFalse(value);

    case isFileUploaded.value:
      return InvariantCompare.isFileUploaded(value);

    case isFileNotUploaded.value:
      return InvariantCompare.isFileNotUploaded(value);

    case regex.value:
      return InvariantCompare.regex(value, condition?.value);

    case startsWith.value:
      return InvariantCompare.startsWith(value, condition?.value);

    case endsWith.value:
      return InvariantCompare.endsWith(value, condition?.value);

    case contains.value: {
      return InvariantCompare.contains(condition?.value, value); // condition?.value can be array in some cases so variables are reversed
    }

    case doesNotContain.value: {
      return !InvariantCompare.contains(condition?.value, value); // condition?.value can be array in some cases so variables are reversed
    }

    default:
      throw new Error(`Wizard - Visibility conditions - Evaluate Condition - Unknown operator type: (${condition.operator}).`);
  }
};

const InvariantCompare = {
  eq: (v1, v2) => v1?.toString()?.toLowerCase() === v2?.toString()?.toLowerCase(),
  neq: (v1, v2) => v1?.toString()?.toLowerCase() !== v2?.toString()?.toLowerCase(),
  lt: (v1, v2) => v1 < v2,
  lte: (v1, v2) => v1 <= v2,
  gt: (v1, v2) => v1 > v2,
  gte: (v1, v2) => v1 >= v2,
  isTrue: v1 => !!v1,
  isFalse: v1 => !v1,
  isFileUploaded: v1 => !!v1,
  isFileNotUploaded: v1 => !v1,
  regex: (v1, expression) => new RegExp(expression).test(v1),
  startsWith: (v1, v2) => v1?.toString()?.toLowerCase()?.startsWith(v2?.toString()?.toLowerCase()),
  endsWith: (v1, v2) => v1?.toString()?.toLowerCase()?.endsWith(v2?.toString()?.toLowerCase()),
  contains: (v1, v2) => v1?.toString()?.toLowerCase()?.includes(v2?.toString()?.toLowerCase()),
};
