import { Button, ButtonGroup } from '@blueprintjs/core';
import React, { useState } from 'react';
import * as Icons from 'react-feather';

import Audit from '../../../../../components/audit';
import Box from '../../../../../components/box';

import AddressForm from './forms/address-form';
import EmailAddressForm from './forms/email-address-form';
import EmergencyContactForm from './forms/emergency-contact-form';
import PersonalInfoForm from './forms/personal-info-form';

import { getUser } from '../../../../../apis/supplier/users';
import useApi from '../../../../../hooks/use-api';
import { listBankAccounts, removeBankAccount } from '../../../../../apis/banking';
import { getDateOfBirth, getFullName } from '../../../../../utilities/user';
import { genderStatusToLabel } from '../../../../../domain/gender';
import { maritalStatusToLabel } from '../../../../../domain/marital-status';
import { classificationTypeToLabel } from '../../../../../domain/classification-type';
import CompanyInfoForm from './forms/company-info-form';
import WithConfirmation from '../../../../../hooks/with-confirmation';
import BankingForm from './forms/banking-form';
import { extractCountryFromAddress } from '../../../../../utilities/location';

import classes from '../../company.module.scss';

const General = ({ api }) => {
  const [dialog, setDialog] = useState({ open: false, type: undefined });
  const getUserApi = useApi(getUser, { skip: true });
  const listBankAccountsApi = useApi(listBankAccounts, { skip: true });

  const openDialog = (type, entity) => setDialog({ open: true, type, entity });
  const closeDialog = () => setDialog({ open: false, type: undefined, entity: undefined });

  const handleRemoveBankAccount = async id => {
    await removeBankAccount(id);
    void listBankAccountsApi.request(supplier);
  };

  const supplier = api.response;
  const loading = api.loading || getUserApi.loading;
  const user = getUserApi?.response?.user;

  React.useEffect(() => {
    if (supplier?.managerUserId) {
      void getUserApi.request({ userId: supplier?.managerUserId });
    }
    if (supplier?.id) {
      void listBankAccountsApi.request({ organizationId: supplier?.id });
    }
  }, [supplier?.id]);

  return (
    <div className={classes.tab}>
      <Box
        title="Company Information"
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title={'Update company information'}
              onClick={() => openDialog('company-info')}
            />
            <Audit id={supplier?.id} type="supplier" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
        loading={loading}
      >
        <Box.Item label="Business name" notProvidedText="-">
          {supplier?.name}
        </Box.Item>
        <Box.Item label="Manager" notProvidedText="-">
          {supplier?.managerName}
        </Box.Item>
        <Box.Item label="Classification" notProvidedText="-">
          {classificationTypeToLabel(supplier?.classification)}
        </Box.Item>
        <Box.Item label="Incorporation type">{supplier?.incorporationType}</Box.Item>
        <Box.Item label="Tax identifier">{supplier?.taxIdentifier}</Box.Item>
        <Box.Item label="Address">
          {supplier?.address?.prettyName || '-'}
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update address" onClick={() => openDialog('company-address')} />
            <Audit id={supplier?.address?.id} type="address" icon small outlined />
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Phone">{supplier?.phone}</Box.Item>
        <Box.Item label="Website">{supplier?.website}</Box.Item>
      </Box>
      <Box
        title="Manager Information"
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update manager personal information"
              onClick={() => openDialog('personal-info')}
            />
            <Audit id={user?.personalDetails?.id} type="personalDetails" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
      >
        <Box.Item label="Name" notProvidedText="-">
          {getFullName(user)}
        </Box.Item>
        <Box.Item label="Email address">{user?.email}</Box.Item>
        <Box.Item label="Date of birth">{getDateOfBirth(user)}</Box.Item>
        <Box.Item label="Gender">{genderStatusToLabel(user?.personalDetails?.gender)}</Box.Item>
        <Box.Item label="Marital status">{maritalStatusToLabel(user?.personalDetails?.maritalStatus)}</Box.Item>
        <Box.Item label="Address">
          {user?.personalDetails?.address?.prettyName || 'Not provided'}
          <ButtonGroup>
            <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update address" onClick={() => openDialog('address')} />
            <Audit id={user?.personalDetails?.addressId} type="address" icon small outlined />
          </ButtonGroup>
        </Box.Item>
        <Box.Item label="Phone">{user?.personalDetails?.phone}</Box.Item>
        <Box.Item label="National id number (ssn, nic, ...)">{user?.personalDetails?.nationalIdentificationNumber}</Box.Item>
        <Box.Item label="Ethnicity">{user?.personalDetails?.ethnicity}</Box.Item>
      </Box>
      <Box
        title="Emergency Contact"
        actions={
          <ButtonGroup>
            <Button
              small
              outlined
              icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
              title="Update Emergency contact"
              onClick={() => openDialog('emergency-contact')}
            />
            <Audit id={user?.emergencyContact?.id} type="emergencyContact" icon small outlined />
          </ButtonGroup>
        }
        outlined
        monochrome
        loading={getUserApi.loading}
      >
        <Box.Item label="First name">{user?.emergencyContact?.firstName}</Box.Item>
        <Box.Item label="Last name">{user?.emergencyContact?.lastName}</Box.Item>
        <Box.Item label="Relationship">{user?.emergencyContact?.relationship}</Box.Item>
        <Box.Item label="Phone">{user?.emergencyContact?.phone}</Box.Item>
        <Box.Item label="Email address">{user?.emergencyContact?.email}</Box.Item>
      </Box>
      <Box
        title="Banking"
        loading={listBankAccountsApi.loading}
        outlined
        monochrome
        actions={
          <ButtonGroup>
            <Button small outlined icon={<Icons.Plus size={16} strokeWidth={1.5} />} title={'Add new bank account'} onClick={() => openDialog('billing')} />
          </ButtonGroup>
        }
      >
        {!listBankAccountsApi?.response?.length ? (
          <Box.ItemContainer>
            <Box.ItemLabel fill>No bank accounts set up.</Box.ItemLabel>
          </Box.ItemContainer>
        ) : (
          listBankAccountsApi?.response?.map(bank => (
            <Box.ItemContainer key={bank.id}>
              <Box.ItemValue>{bank.accountName}</Box.ItemValue>
              <ButtonGroup>
                {bank.isPrimary && <Button small outlined icon={<Icons.Award size={16} strokeWidth={1.5} />} title="This is your primary bank account" />}
                <Button
                  small
                  outlined
                  icon={<Icons.Edit3 size={16} strokeWidth={1.5} />}
                  title="Update bank account"
                  onClick={() => openDialog('billing', bank)}
                />
                <WithConfirmation disabled={bank?.isPrimary} messages={{ question: `Remove bank account: (${bank.accountName}) ?`, confirmButton: 'Remove' }}>
                  <Button
                    small
                    outlined
                    disabled={bank?.isPrimary}
                    onClick={() => handleRemoveBankAccount(bank.id)}
                    icon={<Icons.X size={16} strokeWidth={1.5} />}
                    title="Remove bank account"
                  />
                </WithConfirmation>
              </ButtonGroup>
            </Box.ItemContainer>
          ))
        )}
      </Box>

      <CompanyInfoForm
        isOpen={!!dialog?.open && dialog?.type === 'company-info'}
        supplier={supplier}
        onClose={closeDialog}
        onSave={() => {
          void api.request(supplier?.id);
          closeDialog();
        }}
      />
      <PersonalInfoForm
        isOpen={!!dialog?.open && dialog?.type === 'personal-info'}
        user={user}
        onClose={closeDialog}
        onSave={() => {
          void getUserApi.request({ userId: supplier?.managerUserId });
          closeDialog();
        }}
      />
      <AddressForm
        isOpen={!!dialog?.open && dialog?.type === 'company-address'}
        supplier={supplier}
        onClose={closeDialog}
        onSave={() => {
          void api?.request(supplier?.id);
          closeDialog();
        }}
      />
      <AddressForm
        isOpen={!!dialog?.open && dialog?.type === 'address'}
        user={user}
        onClose={closeDialog}
        onSave={() => {
          void getUserApi.request({ userId: supplier?.managerUserId });
          closeDialog();
        }}
      />
      <EmailAddressForm isOpen={!!dialog?.open && dialog?.type === 'email-address'} user={user} onClose={closeDialog} />
      <EmergencyContactForm
        isOpen={!!dialog?.open && dialog?.type === 'emergency-contact'}
        user={user}
        onClose={closeDialog}
        onSave={() => {
          void getUserApi.request({ userId: supplier?.managerUserId });
          closeDialog();
        }}
      />
      <BankingForm
        isOpen={!!dialog?.open && dialog?.type === 'billing'}
        owner={{ organizationId: supplier?.id }}
        bank={dialog?.entity}
        countryId={extractCountryFromAddress(supplier?.address)}
        onClose={closeDialog}
        onSave={() => {
          void listBankAccountsApi.request({ organizationId: supplier?.id });
          closeDialog();
        }}
      />
    </div>
  );
};

export default General;
