import React from 'react';
import moment from 'moment/moment';
import classNames from 'classnames';

import Box from '../../../../../../components/box';
import Table from '../../../../../../components/table';

import { getGroupStatus } from '../../utilities';
import { getTotalAmount } from '../../../../../../utilities/time';
import { TrackingRecordStatusTypes, trackingRecordStatusToLabel } from '../../../../../../domain/tracking-record-status-type';
import Comments from '../../../../../../components/time/comments';

import HeaderActions from './actions';
import EngagementInfo from './engagement-info';

import classes from './boxes.module.scss';

const Deliverables = ({ supplier, deliverables, updateStatus }) => {
  return (
    <Box
      className={classes.box}
      title="Deliverables"
      actions={
        <HeaderActions
          period={supplier?.period}
          items={deliverables}
          status={getGroupStatus(deliverables)}
          total={getTotalAmount(deliverables, supplier?.currencySymbol)}
          updateStatus={updateStatus}
        />
      }
      headerClassName={classes.boxHeader}
      contentClassName={classes.container}
      outlined
      monochrome
      expandible={{ expanded: false }}
    >
      <Box.ItemContainer className={classNames(classes.table, classes.tablePad)}>
        <Table.Container data={deliverables} dark showHeadings={false} emptyMessage="No deliverables recorded.">
          <Table.Columns.TwoLines
            name="Engagement"
            width="2fr"
            lines={[d => <EngagementInfo engagement={d} />, d => (d?.parentClient ? `${d?.client} via ${d.parentClient}` : d?.client)]}
          />
          <Table.Columns.TwoLines showOnMobile name="Date" lines={[d => moment(d?.date).format('MMMM Do'), d => moment(d?.date).format('YYYY')]} />
          <Table.Columns.Default width="2fr" name="Notes" render={d => d?.notes} alt={d => d?.notes} />
          <Table.Columns.Bold showOnMobile width="90px" name="Amount" render={d => `${supplier?.currencySymbol}${d?.amount?.toFixed(2)}`} />
          <Table.Columns.Bold name="Status" width="100px" render={d => trackingRecordStatusToLabel(d?.status)} />
          <Table.Columns.Default width="36px" render={d => <Comments asPopover recordId={d?.id} />} />;
          <Table.Columns.Actions
            showOnMobile
            actions={[
              {
                name: 'Approve',
                onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: [d?.id] }),
                confirmation: { messages: { confirmButton: 'Approve' } },
              },
              {
                name: 'Dispute',
                onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: [d?.id] }),
                confirmation: { messages: { confirmButton: 'Dispute' } },
              },
            ]}
          />
        </Table.Container>
      </Box.ItemContainer>
    </Box>
  );
};

export default Deliverables;
