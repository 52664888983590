import React, { useContext, useEffect, useState } from 'react';

import Form from '../../../../../../../form';
import Fields from '../../../../../../../fields';

import { createRecord, updateRecord } from '../../../../../../../../apis/configuration';

import { SystemContext } from '../../../../../system-provider';
import ExecupayPayrollProvider from './forms/execupay';

import classes from './payroll.module.scss';

const PayrollProvider = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);
  const [provider, setProvider] = useState();
  const options = [{ value: 'Execupay', label: 'Execupay' }];

  useEffect(() => {
    if (!!data?.providerName) {
      setProvider(data?.providerName);
    }
  }, [data?.providerName]);

  const getForm = provider => {
    const props = {
      ...record,
      scopeId: selectedScope?.id,
      data: { ...(data || {}), configuration: !!data?.configuration ? JSON.parse(data?.configuration) : {} },
      onSubmit: submit,
      loading,
    };

    switch (provider) {
      case 'Execupay':
        return <ExecupayPayrollProvider {...props} />;

      default:
        return undefined;
    }
  };

  const submit = async values => {
    const payload = {
      ...(record || {}),
      ...values,
      description: provider,
      data: { ...values.data, configuration: JSON.stringify(values?.data?.configuration) },
    };

    setLoading(true);
    if (record?.id) {
      await updateRecord(record?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <div className={classes.provider}>
      <div className={classes.selection}>
        <Form onSubmit={values => setProvider(values?.provider)} enableReinitialize initialValues={{ provider }}>
          <Fields.Select name="provider" label="Provider" placeholder="Select payroll provider" options={options} submitOnChange />
        </Form>
      </div>
      {getForm(provider)}
    </div>
  );
};

export default PayrollProvider;
