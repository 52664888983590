import React from 'react';
import classNames from 'classnames';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../box';
import { ObjectTypes } from '../../../configurators/wizards/domain/types';

import useApi from '../../../../hooks/use-api';
import { getWizardLookupData } from '../../../../apis/wizards';
import { downloadDocument, getDocument } from '../../../../apis/documents';

import NotesPopover from '../notes-popover';

import classes from '../timeline-step.module.scss';

const resolveField = async (field, clientId) => {
  switch (field.type) {
    case ObjectTypes.Select: {
      if (!field.value) {
        return null;
      }
      const options = field?.lookup ? await getWizardLookupData(field?.lookup?.type, { clientId }) : field.options;
      const item = options?.find?.(obj => obj.value === field.value);
      return { text: item?.label || item?.value };
    }

    case ObjectTypes.Document: {
      const document = await getDocument(field.value);
      return { text: document.name, entity: document };
    }

    case ObjectTypes.Address: {
      return { text: field?.value?.formatted_address };
    }

    case ObjectTypes.Boolean: {
      if (field?.value === null || field?.value === undefined) {
        return { text: 'Not provided' };
      }

      return { text: field?.value ? 'Yes' : 'No' };
    }

    default:
      return { text: field?.value };
  }
};

function renderActions(field, data) {
  switch (field.type) {
    case ObjectTypes.Document: {
      return (
        <ButtonGroup>
          <Button outlined small icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(data.entity)} />
        </ButtonGroup>
      );
    }

    default:
      return null;
  }
}

const GenericFieldContainer = ({ field, clientId, review, onComplete }) => {
  const api = useApi(() => resolveField(field, clientId));

  return (
    <Box.ItemContainer>
      <Box.ItemLabel>{field.label}</Box.ItemLabel>
      <Box.ItemValue className={classNames(classes.fieldValue, api.loading && Classes.SKELETON)}>
        {api.response?.text || 'Not provided'}
        {renderActions(field, api.response)}
      </Box.ItemValue>
      <NotesPopover fieldId={field.fullPath} review={review} onComplete={onComplete} />
    </Box.ItemContainer>
  );
};

export default GenericFieldContainer;
