import React, { useContext } from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import classNames from 'classnames';

import { isEmpty } from '../../../../utilities/common';

import { SystemContext } from '../system-provider';

import classes from './scopes.module.scss';

const Scope = ({ scope, onAdd, onRemove, onView }) => {
  const { selectedScope, setSelectedScope } = useContext(SystemContext);

  return (
    <div className={classNames(classes.scope, !scope.parentId && classes.root, scope.id === selectedScope?.id && classes.active)}>
      <div className={classes.bar}>
        <div className={classes.name} onClick={() => setSelectedScope(scope)}>
          {scope?.prettyName || 'Global'}
        </div>
        <div className={classes.options}>
          <Popover
            content={
              <Menu>
                <MenuItem icon="send-to-map" text="View on map" disabled={isEmpty(scope?.prettyName)} onClick={() => onView(scope)} />
                <MenuItem icon="plus" text="Add scope" onClick={() => onAdd(scope)} />
                <MenuDivider />
                <MenuItem icon="cross" text="Remove" disabled={isEmpty(scope?.prettyName)} onClick={() => onRemove(scope)} />
              </Menu>
            }
          >
            <Button minimal icon="more" />
          </Popover>
        </div>
      </div>
      {!!scope?.subScopes?.length && (
        <div className={classes.content}>
          {scope?.subScopes?.map((s, i) => (
            <Scope key={i} scope={s} onAdd={onAdd} onRemove={onRemove} onView={onView} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Scope;
