import React, { useRef, useLayoutEffect, useCallback } from 'react';
import cn from 'classnames';

import { debounce } from '../../../../../../../utilities/common';
import useDocuments from '../../../../state/use-documents';

import classes from './resizers.module.scss';

const Resizers = ({ isDragging, horizontal = true, vertical }) => {
  const { resize, selection } = useDocuments();
  const ref = useRef();
  const onMouseDown = useRef([]);
  const delayedResize = useCallback(
    debounce(({ width, height }) => resize({ id: selection.macro.id, width, height }), 300),
    [],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizers = ref.current?.querySelectorAll?.(`.${classes.resizeSquare}`);

    const { parentElement } = ref.current;

    resizers.forEach((resizer, index) => {
      if (onMouseDown.current[index]) {
        resizer.removeEventListener('mousedown', onMouseDown.current[index]);
      }

      onMouseDown.current[index] = e => {
        e.preventDefault();
        e.stopPropagation();

        const startMouseX = e.pageX;
        const startMouseY = e.pageY;
        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = parentElement;

        let width = offsetWidth;
        let height = offsetHeight;

        function resize(e) {
          const directionX = resizer.classList.contains(classes.right) ? 1 : -1;
          const directionY = resizer.classList.contains(classes.bottom) ? 1 : -1;
          if (horizontal) {
            width = offsetWidth + directionX * (e.pageX - startMouseX);
          }

          if (vertical) {
            height = offsetHeight + directionY * (e.pageY - startMouseY);
          }
          parentElement.style.opacity = 0.5;
          parentElement.style.width = `${width || 10}px`;
          parentElement.style.height = `${height}px`;

          if (horizontal && directionX === -1) {
            parentElement.style.left = offsetLeft + (e.pageX - startMouseX) + 'px';
          }

          if (vertical && directionY === -1) {
            parentElement.style.top = offsetTop + (e.pageY - startMouseY) + 'px';
          }
        }

        function stopResize(e) {
          parentElement.style.opacity = 1;
          delayedResize({ width, height });

          window.removeEventListener('mousemove', resize);
          window.removeEventListener('mouseup', stopResize);
        }

        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResize);
      };

      resizer.addEventListener('mousedown', onMouseDown.current[index]);
    });

    return () => {
      resizers.forEach((resizer, index) => {
        resizer.removeEventListener('mousedown', onMouseDown.current[index]);
        onMouseDown.current[index] = null;
      });
      onMouseDown.current = [];
    };
  }, []);

  const commonClassName = cn(classes.resizeSquare, horizontal && classes.horizontal, vertical && classes.vertical);
  return (
    <div className={cn(classes.resizers, classes.resizersActive, isDragging && classes.resizersDragging)} ref={ref}>
      <div className={cn(commonClassName, classes.left, classes.top)} />
      <div className={cn(commonClassName, classes.left, classes.bottom)} />
      <div className={cn(commonClassName, classes.right, classes.top)} />
      <div className={cn(commonClassName, classes.right, classes.bottom)} />
    </div>
  );
};

export default Resizers;
