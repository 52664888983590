import React, { useState } from 'react';
import moment from 'moment';

import Avatar from '../../../../components/avatar';
import Table from '../../../../components/table';

import { abbreviateClassification } from '../../../../domain/classification-type';

import { capitalize, uuid } from '../../../../utilities/common';
import { downloadStringAsFile } from '../../../../utilities/files';
import { displayPeriod, getTotalHours, getTotalAmount } from '../../../../utilities/time';

import useTable from '../../../../hooks/use-table';
import { exportTimeRecords, getTimeRecords, reviewRecords } from '../../../../apis/client/time';
import toaster from '../../../toaster';

import TimeDetails from './details';
import TimeFilters from './filters';
import TimeMenu from './menu';
import { TrackingRecordStatusTypes } from '../../../../domain/tracking-record-status-type';
import { DateFormat } from '../../../../models/enumerations/date-format';

const ClientTime = ({ clientId, supplierId, engagementId, range = undefined, dark = false, handleScroll = true, additionalActions = [] }) => {
  const [details, setDetails] = useState({ open: false, id: undefined, period: undefined });
  const defaultFilters = {
    query: '',
    clientId,
    supplierId,
    engagementId,
    classification: undefined,
    status: 0,
    frequency: 'weekly',
    currencies: undefined,
    countries: undefined,
    range: range || [moment().startOf('isoWeek').format(DateFormat.DEFAULT), moment().endOf('isoWeek').format(DateFormat.DEFAULT)],
  };
  const { items, loading, filters, initialFilters, pageCount, page, goToPage, setFilters, reload, resetFilters } = useTable(
    getTimeRecords,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const updateStatus = async updates => {
    await reviewRecords(updates);
    reload();
  };

  const onExport = () => {
    void toaster.export({
      progress: {
        title: 'Exporting time records',
      },
      promise: () => exportTimeRecords(filters),
      onSuccess: csv => downloadStringAsFile(csv, `TimeRecords-${uuid()}`, 'csv'),
    });
  };

  return (
    <React.Fragment>
      <Table.Container data={items} loading={loading} handleScroll={handleScroll} dark={dark}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<TimeMenu updateStatus={updateStatus} onExport={onExport} engagements={items} />}
          content={<TimeFilters />}
        />
        <Table.Columns.Default
          showOnMobile
          name="Name"
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} name={abbreviateClassification(d?.classification)} />}
        />
        <Table.Columns.TwoLines width="2fr" showOnMobile lines={[d => `${d?.firstName} ${d?.lastName}`, d => d.email]} />
        <Table.Columns.TwoLines
          width="2fr"
          name="Engagement"
          lines={[d => d?.title, d => (d?.parentClient ? `${d?.client} via ${d.parentClient}` : d?.client)]}
        />
        <Table.Columns.TwoLines width="2fr" name="Location" lines={[d => d?.country, d => d?.location || '-']} />
        <Table.Columns.TwoLines showOnMobile lines={[d => getTotalHours(d.records), d => getTotalAmount(d.records, d.currencySymbol)]} />
        <Table.Columns.TwoLines width="130px" name="Period" lines={[d => displayPeriod(d), d => capitalize(d?.frequency) || '-']} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', onClick: d => setDetails({ open: true, id: d?.engagementId, period: d?.period }) },
            { divider: true },
            {
              name: 'Approve',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: d.records?.map(r => r.id) }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => filters?.status === TrackingRecordStatusTypes.Approved,
            },
            {
              name: 'Dispute',
              onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: d.records?.map(r => r.id) }),
              confirmation: { messages: { confirmButton: 'Update' } },
              disabled: d => filters?.status === TrackingRecordStatusTypes.Disputed,
            },
            ...additionalActions,
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <TimeDetails details={details} engagements={items} reloadRecords={reload} onClose={() => setDetails({ open: false, id: undefined, period: undefined })} />
    </React.Fragment>
  );
};

export default ClientTime;
