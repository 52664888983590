export const BillableItemType = {
  Regular: 'regular-hours',
  Overtime: 'over-time-hours',
  DoubleTime: 'double-time-hours',
  Salary: 'salary',
  SalaryOvertime: 'salary-over-time',
  SalaryDoubleTime: 'salary-double-time',
  Expense: 'expense',
  Deliverable: 'deliverable',
  BenefitsCharge: 'benefits-charge',
  BackgroundCheckCharge: 'background-check-charge',
  ClassificationFee: 'classification-fee',
  MiscellaneousCharge: 'miscellaneous-charge',
};

export const BillableItemTypeOptions = [
  {
    label: 'Regular Hours',
    value: BillableItemType.Regular,
  },
  {
    label: 'Overtime Hours',
    value: BillableItemType.Overtime,
  },
  {
    label: 'Double Time Hours',
    value: BillableItemType.DoubleTime,
  },
  {
    label: 'Salary',
    value: BillableItemType.Salary,
  },
  {
    label: 'Salary Overtime',
    value: BillableItemType.SalaryOvertime,
  },
  {
    label: 'Salary Double Time',
    value: BillableItemType.SalaryDoubleTime,
  },
  {
    label: 'Expense',
    value: BillableItemType.Expense,
  },
  {
    label: 'Deliverable',
    value: BillableItemType.Deliverable,
  },
  {
    label: 'Benefits Charge',
    value: BillableItemType.BenefitsCharge,
  },
  {
    label: 'Background Check Charge',
    value: BillableItemType.BackgroundCheckCharge,
  },
  {
    label: 'Classification Fee',
    value: BillableItemType.ClassificationFee,
  },
  {
    label: 'Miscellaneous Charge',
    value: BillableItemType.MiscellaneousCharge,
  },
];

export const isHourly = type => [BillableItemType.Regular, BillableItemType.Overtime, BillableItemType.DoubleTime].includes(type);

export const invoiceItemTypeToLabel = type => {
  switch (type) {
    case BillableItemType.Regular:
    case BillableItemType.Overtime:
    case BillableItemType.DoubleTime:
      return 'Shifts';

    case BillableItemType.Salary:
    case BillableItemType.SalaryOvertime:
    case BillableItemType.SalaryDoubleTime:
      return 'Salary';

    case BillableItemType.Expense:
      return 'Expense';

    case BillableItemType.Deliverable:
      return 'Deliverable';

    case BillableItemType.BenefitsCharge:
      return 'Benefits charge';

    case BillableItemType.BackgroundCheckCharge:
      return 'Background check charge';

    case BillableItemType.MiscellaneousCharge:
      return 'Misc. charge';

    case BillableItemType.ClassificationFee:
      return 'Classification fee';

    default:
      return 'Other';
  }
};
