import MacroTypes from './macro-types';

const MacroDefinitionTypes = [
  {
    value: MacroTypes.DEFAULT,
    label: 'Default',
    description: 'Default macro rendering type, macro value will be displayed in document as-is.',
  },
  {
    value: MacroTypes.CELL,
    label: 'Cell',
    description: 'Macro value will be rendered as one or more equally sized cells (suitable for form values such as SSN which have predefined input mask).',
  },
  {
    value: MacroTypes.SUBSTRING,
    label: 'Substring',
    description: 'Render a part of macro value.',
  },
  {
    value: MacroTypes.CHECKBOX,
    label: 'Tick (check-mark)',
    description: 'Depending on configured condition, check-mark will be rendered (in case condition is fulfilled), otherwise blank.',
  },
  {
    value: MacroTypes.DATE,
    label: 'Date',
    description: 'Macro value will be rendered as date (with applied date format).',
  },
  {
    value: MacroTypes.INLINE_IMG,
    label: 'Inline Image',
    description: 'Macro value will be rendered as image on specified location, and with specified size.',
  },
  {
    value: MacroTypes.STANDALONE_IMG,
    label: 'Standalone Image',
    description: 'Macro value will be rendered as image on its own page.',
  },
  {
    value: MacroTypes.SIGNATURE,
    label: 'Signature Image',
    description: 'Macro value will be rendered as image on specified location, and with specified size.',
  },
  {
    value: MacroTypes.CUSTOM_JS,
    label: 'Custom JS code',
    description: 'Macro value will be rendered as it is specified in the custom JS code bellow.',
  },
];

export const WHITE_SPACE_OPTIONS = [
  { value: 'wrap', label: 'Wrap' },
  { value: 'nowrap', label: 'No wrap' },
];

export default MacroDefinitionTypes;
