import React, { useState, useRef } from 'react';
import SimpleBar from 'simplebar-react';

import { Button, Tag } from '@blueprintjs/core';

import Form, { FormGroup } from '../../../../../../form';
import Fields from '../../../../../../fields';
import MacroDefinitionTypes, { WHITE_SPACE_OPTIONS } from '../../../../enumerations/macro-definitions';
import useDocuments from '../../../../state/use-documents';
import { formatMacro } from '../../../../helpers/macro-item-factory';
import MacroTypes from '../../../../enumerations/macro-types';

import MacrosTree from './macros-tree';
import TypeSpecificFields from './type-specific-fields';
import FormGroupHeader from './type-specific-fields/form-group-header';

import classes from './properties.module.scss';

const PropertiesForm = () => {
  const { selection, macros, update } = useDocuments();
  const [macrosTreeOptions, setMacrosTreeOptions] = useState();
  const fieldsContainerEl = useRef();

  if (selection?.action !== 'edit') {
    return null;
  }

  const submit = values => {
    const macro = formatMacro(values);
    update(macro);
  };

  const toggleMacrosTree = () => {
    const height = fieldsContainerEl.current.clientHeight;
    setMacrosTreeOptions(prevState => ({
      open: !prevState?.open,
      height,
    }));
  };

  const rightElement = <Tag minimal={true}>px</Tag>;

  const disabled = macrosTreeOptions?.open;
  return (
    <div className={classes.properties}>
      <Form initialValues={selection?.macro} onSubmit={submit}>
        {form => {
          return (
            <>
              <div className={classes.fields} ref={fieldsContainerEl}>
                <div className={classes.group}>
                  <div className={classes.line}>
                    <Fields.Select
                      name="type"
                      label="Type"
                      placeholder="Type"
                      options={MacroDefinitionTypes}
                      submitOnChange={true}
                      clearable={false}
                      showError={false}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className={classes.group}>
                  {form.values.type !== MacroTypes.CUSTOM_JS && (
                    <div className={classes.line}>
                      <FormGroup
                        classes={{
                          fieldset: macrosTreeOptions?.open ? classes.selectMacroActive : '',
                        }}
                        label="Macro"
                        showError={false}
                      >
                        <Button
                          fill
                          className={classes.selectMacro}
                          text={form.values.name ? form.values.name : 'Show macros'}
                          alignText="left"
                          rightIcon="caret-down"
                          onClick={toggleMacrosTree}
                        />
                      </FormGroup>
                    </div>
                  )}
                  <div className={classes.line}>
                    <Fields.Text name="displayName" label="Display name" submitOnChange={true} showError={false} disabled={disabled} />
                  </div>
                </div>

                <TypeSpecificFields type={selection?.macro?.type} disabled={disabled} />

                <div className={classes.group}>
                  <FormGroupHeader title="Size and position">
                    <div>
                      <strong>Width</strong> in pixels occupied by end value of the macro.
                    </div>
                    <div>
                      <strong>Height</strong> in pixels occupied by end value of the macro.
                    </div>
                    <div>
                      <strong>Top</strong> offset in pixels occupied by end value of the macro.
                    </div>
                    <div>
                      <strong>Left</strong> offset in pixels occupied by end value of the macro.
                    </div>
                  </FormGroupHeader>
                  <div className={classes.line}>
                    <Fields.Text
                      type="number"
                      name="config.style.width"
                      label="Width"
                      submitOnChange={true}
                      showError={false}
                      extra={{ rightElement }}
                      disabled={disabled || form.values.type === MacroTypes.CHECKBOX}
                    />
                    <Fields.Text
                      type="number"
                      name="config.style.height"
                      label="Height"
                      submitOnChange={true}
                      showError={false}
                      extra={{ rightElement }}
                      disabled={disabled || form.values.type === MacroTypes.CHECKBOX}
                    />
                  </div>
                  <div className={classes.line}>
                    <Fields.Text
                      type="number"
                      name="config.style.left"
                      label="Left"
                      submitOnChange={true}
                      showError={false}
                      extra={{ rightElement }}
                      disabled={disabled}
                    />
                    <Fields.Text
                      type="number"
                      name="config.style.top"
                      label="Top"
                      submitOnChange={true}
                      showError={false}
                      extra={{ rightElement }}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className={classes.group}>
                  <div className={classes.line}>
                    <Fields.Select
                      name="config.style.whiteSpace"
                      label="Text wrapping options"
                      options={WHITE_SPACE_OPTIONS}
                      submitOnChange={true}
                      clearable={false}
                      showError={false}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              {macrosTreeOptions?.open && (
                <div className={classes.macros}>
                  <SimpleBar style={{ height: macrosTreeOptions?.height }}>
                    <MacrosTree
                      macros={macros}
                      value={form.values.name}
                      handleClick={macro => {
                        form.setFieldValue('name', macro.name);
                        form.setFieldValue('example', macro.example);
                        if (!form.values.displayName) {
                          form.setFieldValue('displayName', macro.name);
                        }
                        if (!!macro?.roleId) {
                          form.setFieldValue('roleId', macro?.roleId);
                        }
                        void form.submitForm();
                        toggleMacrosTree();
                      }}
                    />
                  </SimpleBar>
                </div>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default PropertiesForm;
