import * as apis from '../../apis/profile';

import { safeInvoke } from '../../utilities/functions';
import { setProfile, setSignatureSettings } from './actions';

export const getUserProfile = onSuccess => async dispatch => {
  try {
    const profile = await apis.getProfile();
    dispatch(setProfile(profile));
    safeInvoke(onSuccess);
  } catch (error) {
    // safeInvoke(onError, error);
    throw error; // error is handled in the component
  }
};

export const updateSignatureSettings = (signature, onSuccess, onError) => async dispatch => {
  try {
    const result = await apis.updateSignatureSettings(signature);
    dispatch(setSignatureSettings(result));
    safeInvoke(onSuccess);
  } catch (error) {
    safeInvoke(onError, error);
  }
};
