import React from 'react';

import Form from '../../../../../../../form';
import Fields from '../../../../../../../fields';
import ResourceFiles from '../../../../../../../resource-files';

import classes from '../../properties.module.scss';

const EmailConfigForm = ({ availableMacros, config, onSubmit }) => {
  return (
    <Form initialValues={config} onSubmit={onSubmit}>
      {form => (
        <div className={classes.group}>
          <Fields.Text label="Name" name="name" submitOnChange={true} showError={false} />
          <Fields.Text label="Description" name="description" submitOnChange={true} showError={false} />
          <Fields.MacroTextarea
            label="Subject"
            name="subject"
            description="Right click to insert macros"
            options={availableMacros}
            submitOnChange={true}
            showError={false}
          />
          <Fields.MacroTextarea
            label="Logo"
            name="logo"
            description="Right click to insert macros"
            options={availableMacros}
            submitOnChange={true}
            showError={false}
          />
          <ResourceFiles
            upload
            browse
            extensions={['jpg', 'jpeg', 'png', 'gif']}
            onSubmit={url => {
              form.setFieldValue('logo', url);
              void form.submitForm();
            }}
          />
          <Fields.MacroTextarea
            label="Footer"
            name="footer"
            description="Right click to insert macros"
            options={availableMacros}
            submitOnChange={true}
            showError={false}
          />
          <Fields.Text label="Class name" name="class" submitOnChange={true} showError={false} />
          <Fields.Textarea label="Style" name="style" submitOnChange={true} showError={false} />
        </div>
      )}
    </Form>
  );
};

export default EmailConfigForm;
