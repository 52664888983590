import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../../../../form';
import Fields from '../../../../../../../fields';

import { createRecord, updateRecord } from '../../../../../../../../apis/configuration';

import { SystemContext } from '../../../../../system-provider';

import classes from './independent-contractor-insurance-requirements.module.scss';

const IndependentContractorInsuranceRequirements = ({ record, onClose, loading, setLoading, api, data }) => {
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    const payload = {
      ...(record || {}),
      scopeId: selectedScope?.id,
      data: { isRequired: !values?.isWaived },
    };

    setLoading(true);
    if (record?.id) {
      await updateRecord(record?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  return (
    <div className={classes.requirements}>
      <Form onSubmit={submit} enableReinitialize initialValues={{ isRequired: data?.isRequired }}>
        <Fields.Select
          name="isRequired"
          label="Proof of independent contractor insurance..."
          options={[
            { label: 'Is required.', value: true },
            { label: 'Is not required.', value: false },
          ]}
        />
        <Button type="submit" text="Submit" loading={loading} outlined fill />
      </Form>
    </div>
  );
};

export default IndependentContractorInsuranceRequirements;
