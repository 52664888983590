import React from 'react';

import PlatformTime from '../../../../../../../components/organizational/platform/time';

import classes from './time.module.scss';

const Time = ({ api, engagementId }) => {
  return (
    <div className={classes.time}>
      <PlatformTime engagementId={engagementId} handleScroll={false} dark />
    </div>
  );
};

export default Time;
