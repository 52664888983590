import React from 'react';
import { Icon, Menu, MenuItem } from '@blueprintjs/core';

const TimeImportFilters = ({ values, setFieldValue, submitForm, resetFilters }) => {
  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;
  const filterCount = Object.values(values).filter(v => !!v && (Array.isArray(v) ? !!v.length : true)).length;

  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCount}
        onClick={() => resetFilters()}
        text={`${filterCount} filter${filterCount === 1 ? '' : 's'} applied`}
        label={!!filterCount ? <Icon icon="cross" /> : null}
      />
      <MenuItem
        shouldDismissPopover={false}
        onClick={() => {
          setFieldValue('validated', !!values?.validated ? null : true);
          submitForm();
        }}
        label={tick(!!values?.validated)}
        text="Only Validated ?"
      />
      <MenuItem
        shouldDismissPopover={false}
        onClick={() => {
          setFieldValue('complete', !!values?.complete ? null : true);
          submitForm();
        }}
        label={tick(!!values?.complete)}
        text="Only Complete ?"
      />
    </Menu>
  );
};

export default TimeImportFilters;
