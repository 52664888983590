import React from 'react';
import { Classes } from '@blueprintjs/core';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Avatar from '../../../components/avatar';
import Fields from '../../../components/fields';
import Form from '../../../components/form';
import Page from '../../../components/page';
import Tabs from '../../../components/tabs';

import useApi from '../../../hooks/use-api';
import { getSupplier, uploadSupplierIcon } from '../../../apis/supplier/organizations';

import General from './containers/general';
import Staff from './containers/staff';
import Documents from './containers/documents';

import classes from './company.module.scss';

const Company = ({ membership }) => {
  const api = useApi(getSupplier, { params: membership?.organization?.id });

  const avatar = (
    <Form initialValues={{ icon: null }} onSubmit={() => null}>
      <Fields.File
        label="Upload company icon"
        mimeTypes={'image/*'}
        name="icon"
        maxSize={5}
        crop={{ aspectRatio: 1, width: 220, height: 220 }}
        api={icon => uploadSupplierIcon({ supplierId: membership?.organization?.id, icon })}
        onUploaded={() => api.request(membership?.organization?.id)}
        outline={false}
      >
        <Avatar loading={api?.loading} name={api?.response?.name} size={{ box: 220, text: 30 }} url={api?.response?.icon} upload />
      </Fields.File>
    </Form>
  );

  const commonProps = { api, supplierId: membership?.organization?.id };

  return (
    <Page
      title={!!api.loading ? <span className={Classes.SKELETON}>Company</span> : api?.response?.name}
      path={[{ name: 'Company', to: '/company' }]}
      scroll={false}
    >
      <div className={classNames(classes.details)}>
        <Tabs.Container>
          {avatar}
          <Tabs.Item title="General" component={General} props={commonProps} />
          <Tabs.Item title="Staff" component={Staff} props={commonProps} />
          <Tabs.Item title="Documents" component={Documents} props={commonProps} />
        </Tabs.Container>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  membership: state.identity.membership,
});

export default connect(mapStateToProps, null)(Company);
