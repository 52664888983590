import React, { useContext } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

import { WizardContext } from '../../state';
import { WizardOrderContext } from '../../state/order-provider';
import { WizardExpandContext } from '../../state/expand-provider';
import { WizardSelectionContext } from '../../state/selection-provider';
import { Container, Handle } from '../../common/components/sortable';

import Group from '../group';
import PageMenu from './menu';
import PageLoading from './loading';

import classes from './page.module.scss';

const Item = SortableElement(({ page, group }) => <Group page={page} group={group} />);

const Page = ({ page = {} }) => {
  const { loading } = useContext(WizardContext);
  const { reorderGroups } = useContext(WizardOrderContext);
  const { isPageExpanded, togglePage } = useContext(WizardExpandContext);
  const { getSelection } = useContext(WizardSelectionContext);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className={classNames(classes.page, getSelection()?.id === page.id && classes.selected)}>
      <div className={classes.header}>
        <Handle />
        <div className={classes.title}>{page.title || 'Unset'}</div>
        <ButtonGroup>
          <Button
            className={classes.actions}
            minimal
            icon={isPageExpanded(page.id) ? 'chevron-up' : 'chevron-down'}
            onClick={() => togglePage(page.id)}
            disabled={!page.groups?.length}
          />
          <PageMenu page={page} />
        </ButtonGroup>
      </div>
      {isPageExpanded(page.id) && (
        <div className={classes.groups}>
          <Container useDragHandle onSortEnd={updates => reorderGroups(updates, page.id)}>
            {page.groups?.map?.((group, i) => (
              <Item key={group.id} index={i} collection={page.id} page={page} group={group} />
            ))}
          </Container>
        </div>
      )}
    </div>
  );
};

export default Page;
