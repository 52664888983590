import { StorageManager } from '../../authentication/storage';
import Clients from '../../configuration/client';
import { toQueryString } from '../../utilities/common';

export const getClientsMap = async () => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client/all`);
  return response?.data;
};

export const getEngagements = async ({ filters = {}, page, pageSize }) => {
  const { organizationType, organizationId, roleId, membershipId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = {
    ...filters,
    manager: roleId === 'client-hiring-manager' ? membershipId : undefined,
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement${toQueryString(query)}`);
  return response?.data;
};

export const exportEngagements = async ({ filters = {} }) => {
  const { organizationType, organizationId, roleId, membershipId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = {
    ...filters,
    manager: roleId === 'client-hiring-manager' ? membershipId : undefined,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/export${toQueryString(query)}`);
  return response?.data;
};

export const getEngagement = async id => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${id}`);
  return response?.data;
};

export const removeEngagementDepartment = async ({ clientId, engagementId, departmentId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${clientId}/engagement/${engagementId}/department/${departmentId}`);
  return response?.data;
};

export const getOnboardingEngagements = async ({ page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/onboarding${toQueryString(query)}`);
  return response?.data;
};

export const resendEngagementInvite = async ({ engagementId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/resend-invite`);
  return response?.data;
};

export const getEvaluationEngagements = async ({ filters = {}, page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { ...filters, page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/evaluations${toQueryString(query)}`);
  return response?.data;
};

export const updateEngagementScheduled = async ({ engagementId, engagement, date }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/schedule/${date}`, engagement);
  return response?.data;
};

export const updateEngagementPurchaseOrder = async ({ engagementId, purchaseOrder }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/purchase-order`, purchaseOrder);
  return response?.data;
};

export const updateEngagementCompensationScheduled = async ({ engagementId, compensation, date }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/compensation/schedule/${date}`, compensation);
  return response?.data;
};

export const updateEngagementHiringManager = async ({ engagementId, managerId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/hiring-manager/${managerId}`);
  return response?.data;
};

export const updateEngagementDepartment = async ({ engagementId, departmentId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/department/${departmentId}`);
  return response?.data;
};

export const getTimeRecords = async id => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${id}`);
  return response?.data;
};

export const getWorkLocationOptions = async ({ engagementId, type }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/work-location-options/${type}`);
  return response?.data;
};

export const updateEngagementMetadata = async ({ engagementId, metadata }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/metadata`, metadata);
  return response?.data;
};

export const getEngagementUpdates = async ({ page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/updates${toQueryString(query)}`);
  return response?.data;
};

export const getEngagementUpdate = async ({ engagementId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/updates`);
  return response?.data;
};

export const getEngagementTerminations = async ({ page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/terminations${toQueryString(query)}`);
  return response?.data;
};

export const getEngagementTermination = async ({ engagementId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/${engagementId}/termination`);
  return response?.data;
};

export const getExpiringEngagements = async ({ page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const query = { page, pageSize };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/engagement/expiring${toQueryString(query)}`);
  return response?.data;
};
