import React from 'react';
import { Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import classes from '../properties.module.scss';

const FormGroupHeader = ({ title, children }) => (
  <div className={classes.propHeader}>
    <div>{title}</div>
    <Popover popoverClassName={classes.helpPopover} content={<div className={classes.helpContainer}>{children}</div>}>
      <Icons.HelpCircle className={classes.icon} size={17} strokeWidth={2.1} />
    </Popover>
  </div>
);

export default FormGroupHeader;
