import React from 'react';

import Page from '../../../../../components/page';

import PlatformTime from '../../../../../components/organizational/platform/time';

const Time = () => {
  return (
    <Page title="Time" path={[{ name: 'Time', to: '/time/' }]} scroll={false}>
      <PlatformTime />
    </Page>
  );
};

export default Time;
