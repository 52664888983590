import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';

export const getUser = async ({ userId }) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/user/${userId}`);
  return response?.data;
};

export const setPersonalDetails = async user => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/user/personal`, user);
  return response?.data;
};

export const setUserAddress = async (userId, placeId) => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/user/address`, { userId, placeId });
  return response?.data;
};

export const setEmergencyContact = async emergencyContact => {
  const { organizationType } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/user/emergency-contact`, emergencyContact);
  return response?.data;
};
