import { Icon, Menu, MenuDivider, MenuItem, Spinner } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleBar from 'simplebar-react';

import { loadCountries } from '../../../../../state/lookups';
import { parsedFilterValue, toggleFilterValue } from '../../../../../utilities/filter';

const ClientFilters = ({ values, filterCounter, setFieldValue, submitForm, resetFilters, countries, loadCountries }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!countries) {
      setLoading(true);
      loadCountries(() => setLoading(false));
    }
  }, []);

  const tick = condition => <Icon icon="tick" style={{ opacity: !!condition ? 1 : 0.15 }} />;
  const parsedCountries = parsedFilterValue(values?.countries);
  return (
    <Menu>
      <MenuItem
        shouldDismissPopover={false}
        disabled={!filterCounter}
        onClick={() => resetFilters()}
        text="Clear Filters"
        label={!!filterCounter ? <Icon icon="cross" /> : null}
      />
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        text={`Countries (${parsedCountries?.length || 'any'})`}
        disabled={!!loading}
        icon={loading ? <Spinner size={20} /> : undefined}
      >
        {!loading && (
          <SimpleBar style={{ maxHeight: '35vh' }}>
            {countries?.map(country => (
              <MenuItem
                key={country.id}
                shouldDismissPopover={false}
                onClick={() => {
                  const selection = toggleFilterValue(country.id, values?.countries);
                  setFieldValue('countries', selection);
                  submitForm();
                }}
                label={tick(parsedCountries?.includes(country.id))}
                text={country.name}
              />
            ))}
          </SimpleBar>
        )}
      </MenuItem>
      <MenuDivider />
      <MenuItem
        shouldDismissPopover={false}
        onClick={() => {
          setFieldValue('includeRemoved', !!values?.includeRemoved ? null : true);
          submitForm();
        }}
        label={tick(!!values?.includeRemoved)}
        text="Include Deleted"
      />
    </Menu>
  );
};

const mapStateToProps = state => ({
  countries: state.lookups.countries,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadCountries,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClientFilters);
