import React, { useContext } from 'react';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';

import WithConfirmation from '../../../../../hooks/with-confirmation';

import { WizardContext } from '../../state';
import { WizardExpandContext } from '../../state/expand-provider';
import { WizardInstanceContext } from '../../state/instance-provider';
import { WizardSelectionContext } from '../../state/selection-provider';

import classes from './page.module.scss';

const PageMenu = ({ page }) => {
  const { addGroup, clonePage, removePage } = useContext(WizardContext);
  const { setStepById } = useContext(WizardInstanceContext);
  const { isPageExpanded, togglePage } = useContext(WizardExpandContext);
  const { selectPage } = useContext(WizardSelectionContext);

  const canNotBeRemoved = page?.groups
    ?.map?.(g => g.fields)
    ?.flat?.(1)
    ?.some(f => !!f.mandatory);

  return (
    <Popover
      content={
        <Menu>
          <MenuItem text="Navigate to" icon="arrow-right" onClick={() => setStepById(page?.id)} />
          <MenuDivider />
          <MenuItem text="Properties" icon="settings" onClick={() => selectPage(page)} />

          <MenuItem
            text="Add group"
            icon="plus"
            onClick={() => {
              addGroup(page);
              if (!isPageExpanded(page.id)) {
                togglePage(page.id);
              }
            }}
          />
          <MenuItem text="Duplicate page" icon="duplicate" onClick={() => clonePage(page)} />
          <MenuDivider />
          <WithConfirmation
            messages={{
              question: `Are you sure you want to remove ${page?.title} page ? All groups and fields on this page will also be removed, as well as any visibility conditions that are based on these fields.`,
            }}
            icon={true}
            disabled={canNotBeRemoved}
          >
            <MenuItem text="Remove" icon="cross" disabled={canNotBeRemoved} onClick={() => removePage(page)} shouldDismissPopover={false} />
          </WithConfirmation>
        </Menu>
      }
    >
      <Button className={classes.actions} minimal icon="more" />
    </Popover>
  );
};

export default PageMenu;
