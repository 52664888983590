import React from 'react';
import moment from 'moment';
import { Toaster } from '@blueprintjs/core';

import Avatar from '../../../../../components/avatar';
import Box from '../../../../../components/box';
import Table from '../../../../../components/table';
import FlowStatus from '../../../../../components/flow-status';

import useApi from '../../../../../hooks/use-api';
import { resendEngagementInvite } from '../../../../../apis/platform/engagements';

import classes from '../../dashboard.module.scss';

const OnboardingBox = ({ table, setDrawer }) => {
  const { items, loading, pageCount, page, goToPage } = table;
  const resendApi = useApi(resendEngagementInvite, { skip: true });

  const onResend = async engagementId => {
    await resendApi.request({ engagementId });
    if (!resendApi.error) {
      Toaster.create({ position: 'top' }).show({ message: 'Engagement invite re-sent.', intent: 'success' });
    }
  };

  return (
    <Box
      title={'Onboarding suppliers'}
      className={classes.onboardingBox}
      contentClassName={classes.onboarding}
      footerClassName={classes.footer}
      footer={<Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />}
    >
      <Table.Container data={items} loading={loading} handleScroll={false} showHeadings={false} dark emptyMessage="No onboarding suppliers.">
        <Table.Columns.Default
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d?.supplierName || d?.supplierManagerName} url={d?.supplierIcon} />}
        />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.supplierName || d?.supplierManagerName, d => d?.supplierManagerEmail]} />
        <Table.Columns.TwoLines lines={[d => d?.clientName, d => d?.parentClientName]} />
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.engagementTitle, d => `Starts on ${moment(d?.startDate).format('MMM Do, YYYY')}`]} />
        <Table.Columns.Default
          width="175px"
          render={d => (
            <FlowStatus
              flowType="engagement"
              referenceId={d?.engagementId}
              flow={d?.flow}
              signatureContextProps={{ engagementId: d?.engagementId }}
              loading={loading}
              align="right"
            />
          )}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'Review', onClick: d => setDrawer({ open: true, engagementId: d?.engagementId }) },
            { divider: true },
            { name: 'View engagement', href: d => `/engagements/${d.engagementId}` },
            { name: 'View supplier', href: d => `/suppliers/${d.supplierId}` },
            { divider: true },
            {
              name: 'Re-send invite',
              onClick: d => onResend(d.engagementId),
              disabled: d => !!d?.supplierManagerEmail,
            },
            { divider: true },
            {
              name: 'Impersonate',
              disabled: d => !d?.supplierManagerEmail,
              href: d => `/authentication/impersonate/${Buffer.from(d?.supplierManagerEmail).toString('base64')}`,
              target: '_blank',
            },
          ]}
        />
      </Table.Container>
    </Box>
  );
};

export default OnboardingBox;
