import { toQueryString } from '../utilities/common';

import Clients from '../configuration/client';

export const getDescriptors = async () => {
  const response = await Clients.Api.get(`/ui/template/describe/wizard`);
  return response.data;
};

export const getTemplate = async templateId => {
  const response = await Clients.Api.get(`/ui/template/${templateId}`);
  return response.data;
};

export const getTemplateStream = async templateId => {
  const response = await Clients.Api.get(`/ui/template/${templateId}/stream`);
  return response.data;
};

export const getPreviewStream = async templateId => {
  const template = await getTemplate(templateId);
  const response = await Clients.Api.post('/ui/template/preview', template);
  return response.data;
};

export const generateDocumentStream = async (templateId, context) => {
  const response = await Clients.Api.post(`/ui/template/generate/${templateId}`, context);
  return response.data;
};

export const uploadTemplate = async (templateId, content) => {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(content)], { type: 'application/json' });
  formData.append('file', blob, `${templateId}.json`);
  const response = await Clients.Api.post(`/ui/template/upload/wizard/${templateId}`, formData);
  return response.data;
};

export const publishTemplate = async templateId => {
  const response = await Clients.Api.post(`/ui/template/publish/${templateId}`);
  return response.data;
};

export const getWizardLookupData = async (type, { clientId, engagementId = undefined, userId = undefined }) => {
  const parameters = { type, clientId, engagementId, userId };
  const response = await Clients.Api.get(`/ui/wizard/lookup${toQueryString(parameters)}`);
  return response.data;
};

export const getWizardMetadataFields = async ({ clientId, providedBy }) => {
  const parameters = { clientId, providedBy };
  const response = await Clients.Api.get(`/ui/wizard/lookup/metadata${toQueryString(parameters)}`);
  return response.data;
};

export const createWizardLookupData = async (type, data) => {
  const response = await Clients.Api.post(`/ui/wizard/lookup/${type}`, data);
  return response.data;
};

export const getWizardDocuments = async ({ templateId }) => {
  const response = await Clients.Api.get(`/ui/wizard/lookup/documents${toQueryString({ templateId })}`);
  return response.data;
};

export const getDocumentWizards = async ({ scopeId }) => {
  const response = await Clients.Api.get(`/ui/wizard/lookup/wizards${toQueryString({ scopeId })}`);
  return response.data;
};

export const getFlowStatus = async ({ flowType, referenceId }) => {
  const response = await Clients.Api.get(`/ui/flow/status/${flowType}/${referenceId}`);
  return response.data;
};

export const completeFlow = async ({ flowId, approved }) => {
  const response = await Clients.Api.post(`/ui/flow/complete/${flowId}${toQueryString({ approved })}`);
  return response.data;
};

export const initializeFlow = async ({ flowType, context }) => {
  const response = await Clients.Api.post(`/ui/flow/initialize`, { flowType, context });
  return response.data;
};

export const getNextStep = async ({ flowType, referenceId }) => {
  const response = await Clients.Api.get(`/ui/flow/next/${flowType}/${referenceId}`);
  return response.data;
};

export const submitFlowStep = async ({ instanceId }) => {
  const response = await Clients.Api.post(`/ui/flow/step/${instanceId}/submit`);
  return response.data;
};

export const progressInstance = async ({ instanceId, progress, data = {}, status }) => {
  const response = await Clients.Api.put(`/ui/flow/step/progress`, { instanceId, progress, data, status });
  return response.data;
};

export const awaitSignatures = async ({ instanceId, aux }) => {
  const response = await Clients.Api.post(`/ui/flow/step/${instanceId}/awaiting-signatures`, aux);
  return response.data;
};

export const overrideProgressInstance = async ({ instanceId, progress, data = {}, status }) => {
  const response = await Clients.Api.put(`/ui/flow/step/override-progress`, { instanceId, progress, data, status });
  return response.data;
};

export const removeFlow = async ({ instanceId, removeFlow = true }) => {
  const response = await Clients.Api.delete(`/ui/flow/step/${instanceId}${toQueryString({ removeFlow })}`);
  return response.data;
};

export const getInstance = async instanceId => {
  const response = await Clients.Api.get(`/ui/flow/step/${instanceId}`);
  return response.data;
};

export const getInstanceFlow = async instanceId => {
  const response = await Clients.Api.get(`/ui/flow/step/${instanceId}/flow`);
  return response.data;
};

export const createInstance = async ({ flowId, templateId, context }) => {
  const response = await Clients.Api.post(`/ui/flow/step`, { flowId, templateId, context });
  return response.data;
};

export const removeInstance = async ({ instanceId }) => {
  const response = await Clients.Api.delete(`/ui/flow/step/${instanceId}`);
  return response.data;
};

export const updateReview = async ({ reviewId, flowId, instanceId, result, elaboration }) => {
  const response = await Clients.Api.put(`/ui/flow/review/${reviewId}`, { flowId, instanceId, result, elaboration });
  return response.data;
};

export const getFlowStepReview = async reviewId => {
  const response = await Clients.Api.get(`/ui/flow/review/${reviewId}`);
  return response.data;
};

export const createFlowStepReviewInsight = async (reviewId, data) => {
  const response = await Clients.Api.post(`/ui/flow/review/${reviewId}/insight`, data);
  return response.data;
};

export const updateFlowStepReviewInsight = async (reviewId, data) => {
  const response = await Clients.Api.put(`/ui/flow/review/${reviewId}/insight/${data.id}`, data);
  return response.data;
};

export const deleteFlowStepReviewInsight = async (reviewId, insightId) => {
  const response = await Clients.Api.delete(`/ui/flow/review/${reviewId}/insight/${insightId}`);
  return response.data;
};

export const getEligibleSignatories = async ({ flowType, referenceId }) => {
  const response = await Clients.Api.get(`/ui/flow/${flowType}/${referenceId}/eligible-signatories`);
  return response.data;
};
