import React from 'react';

import Fields from '../../../../../../../../fields';

import { ObjectTypes } from '../../../../../../domain/types';
import { Operators } from '../../../../../../domain/operator-definitions';

const renderValueField = ({ field, operator }) => {
  if (!field || !operator) {
    return null;
  }

  const commonProps = { label: 'Compare to value', placeholder: 'Value', name: 'value' };

  switch (field.type) {
    case ObjectTypes.Text:
      return <Fields.Text {...commonProps} />;

    case ObjectTypes.Number:
      return <Fields.Text type="number" {...commonProps} />;

    case ObjectTypes.Select:
      if ([Operators.contains.value, Operators.doesNotContain.value].includes(operator)) {
        return <Fields.MultiSelect options={field.options} {...commonProps} />;
      }
      return <Fields.Select options={field.options} {...commonProps} />;

    case ObjectTypes.Date:
      break;

    case ObjectTypes.Document:
    case ObjectTypes.Boolean:
    default:
      return null;
  }
};

export default renderValueField;
