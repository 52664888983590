import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import _ from 'lodash';
import SimpleBar from 'simplebar-react';
import * as Icons from 'react-feather';
import { useSearchParams } from 'react-router-dom';

import Box from '../../../box';
import useApi from '../../../../hooks/use-api';
import { getRecords } from '../../../../apis/configuration';

import { SystemConfiguratorElements } from '../common/elements';
import { SystemContext } from '../system-provider';
import AddRecordDialog from './dialogs/add-record';
import CopyRecordDialog from './dialogs/copy-record';
import ViewRecordDialog from './dialogs/view-record';

import Record from './record';
import RecordTypesMenu from './typesMenu';

import classes from './records.module.scss';

const Records = ({ height, embedded }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedScope, includeInheritedRecords, setIncludeInheritedRecords } = useContext(SystemContext);
  const [dialog, setDialog] = useState({ open: false, type: undefined, record: undefined });
  const getRecordsApi = useApi(() => getRecords(selectedScope, includeInheritedRecords), { skip: true });

  useEffect(() => {
    if (selectedScope) {
      getRecordsApi?.request();
    }
  }, [selectedScope?.id, includeInheritedRecords]);

  let Dialog = null;
  switch (dialog.type) {
    case 'add':
      Dialog = AddRecordDialog;
      break;
    case 'view':
      Dialog = ViewRecordDialog;
      break;
    case 'copy':
    default:
      Dialog = CopyRecordDialog;
      break;
  }

  const groups = _.groupBy(getRecordsApi?.response || [], item => {
    const [group] = item.configurationType.split('|');
    return group;
  });

  const sortedGroups = _.sortBy(Object.keys(groups));

  const handleOpen = groupTitle => {
    const lowerCaseGroupTitle = groupTitle.toLowerCase();
    if (searchParams.get('group') === lowerCaseGroupTitle) {
      searchParams.delete('group');
    } else {
      searchParams.set('group', lowerCaseGroupTitle);
    }
    setSearchParams(searchParams);
  };

  return (
    <Box
      title="Records"
      className={classes.records}
      contentClassName={classes.content}
      outlined={embedded}
      monochrome={true}
      actions={
        <ButtonGroup>
          <Button
            small
            outlined
            icon={includeInheritedRecords ? <Icons.EyeOff size={16} strokeWidth={1.5} /> : <Icons.Eye size={16} strokeWidth={1.5} />}
            title={includeInheritedRecords ? 'Hide inherited records' : 'Show inherited records'}
            onClick={() => setIncludeInheritedRecords(!includeInheritedRecords)}
          />
          <RecordTypesMenu
            onAdd={record => setDialog({ open: true, type: 'add', record })}
            disabled={getRecordsApi?.loading}
            existingRecords={getRecordsApi?.response}
          />
        </ButtonGroup>
      }
    >
      <SimpleBar className={classes.wrapper} style={{ height: `${height - 50}px` }}>
        <SystemConfiguratorElements.Loader loading={getRecordsApi?.loading} height={height} />
        <SystemConfiguratorElements.EmptyList
          loading={getRecordsApi?.loading}
          height={height}
          response={getRecordsApi?.response}
          message={`No records found for ${selectedScope?.prettyName || 'Global'} scope.`}
        />
        {!getRecordsApi?.loading && (
          <div className={classes.list}>
            {sortedGroups.map(groupTitle => {
              const sortedTypes = _.sortBy(groups[groupTitle], s => s.configurationType);
              const lowerCaseGroupTitle = groupTitle.toLowerCase();
              const groupsStr = searchParams.get('group');
              const groupsArr = groupsStr ? groupsStr.split(',') : [];
              const expanded = groupsArr.includes(lowerCaseGroupTitle);
              return (
                <Box
                  title={groupTitle}
                  headerClassName={classes.header}
                  contentClassName={classes.content}
                  expandible={{ expanded }}
                  monochrome
                  outlined
                  small
                  onClick={() => handleOpen(groupTitle)}
                  key={groupTitle}
                >
                  {sortedTypes?.map(r => (
                    <Record
                      key={r.id}
                      record={r}
                      api={getRecordsApi}
                      onView={record => setDialog({ open: true, type: 'view', record })}
                      onCopy={record => setDialog({ open: true, type: 'copy', record })}
                    />
                  ))}
                </Box>
              );
            })}
          </div>
        )}
      </SimpleBar>
      <Dialog
        isOpen={!!dialog?.open}
        record={dialog.record}
        api={getRecordsApi}
        onClose={() => setDialog({ open: false, type: undefined, record: undefined })}
      />
    </Box>
  );
};

export default Records;
