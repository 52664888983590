import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import Dialog from '../../../../../../../../components/dialog';
import Form from '../../../../../../../../components/form';
import Fields from '../../../../../../../../components/fields';
import { getFullName } from '../../../../../../../../utilities/user';

import useApi from '../../../../../../../../hooks/use-api';
import { updateClient } from '../../../../../../../../apis/platform/organizations';
import { getMembers } from '../../../../../../../../apis/members';

const CompanyInformationForm = ({ isOpen = false, onClose, api }) => {
  const updateClientApi = useApi(updateClient, { skip: true });
  const membersApi = useApi(getMembers, { skip: true });

  const submit = async ({ clientId, name, managerId, taxId, website }, { setFieldError }) => {
    if (!clientId) {
      setFieldError('name', 'Unable to resolve client identifier.');
      return;
    }

    await updateClientApi.request({ clientId, name, managerId, taxId, website });
    await api.request({ clientId });
    onClose();
  };

  const loading = api?.loading || updateClientApi?.loading;

  return (
    <Dialog title="Company Information" isOpen={isOpen} onClose={onClose}>
      <Form
        initialValues={{
          clientId: api?.response?.id,
          name: api?.response?.name || '',
          managerId: api?.response?.managerId,
          website: api?.response?.website || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required'),
          managerId: Yup.string().nullable().required('Contact person is required'),
        })}
        onSubmit={submit}
      >
        <Fields.Text name="name" label="Name" />
        <Fields.Select
          name="managerId"
          label="Contact person"
          remoteOptions={{
            loading: membersApi.loading,
            request: (query = '') => membersApi.request({ filters: { organizationId: api?.response?.id, includeParentMembers: true, query } }),
            mapper: result => result?.data?.map(d => ({ value: d?.id, label: getFullName(d?.user) })),
            filter: 'remote',
          }}
        />
        <Fields.Text name="website" label="Website" />
        <Button type="submit" fill outlined text="Update" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default CompanyInformationForm;
