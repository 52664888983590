import React from 'react';

import Configurators from '../../../../../components/configurators';
import Page from '../../../../../components/page';

import classes from './system.module.scss';

const System = () => {
  return (
    <Page title="Settings" path={[{ name: 'Settings', to: '/settings' }]} scroll={false}>
      <div className={classes.system}>
        <Configurators.System />
      </div>
    </Page>
  );
};

export default System;
