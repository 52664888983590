import React, { useContext } from 'react';

import Form from '../../../../../form';
import Fields from '../../../../../fields';

import { WizardContext } from '../../../state';
import { WizardSelectionContext } from '../../../state/selection-provider';

import VisibilityConditions from './components/visibility';

import classes from '../properties.module.scss';

const GroupForm = () => {
  const { updateGroup } = useContext(WizardContext);
  const { getSelectionPath } = useContext(WizardSelectionContext);

  const selection = getSelectionPath();
  if (!selection?.length) {
    return null;
  }
  const [, page, group] = selection;

  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  return (
    <Form initialValues={{ ...group }} onSubmit={v => updateGroup(page, v)}>
      {form => (
        <React.Fragment>
          <div className={classes.group}>
            <Fields.Text label="Title" name="title" submitOnChange={true} />
            <Fields.Text label="Description" name="description" submitOnChange={true} showError={false} />
          </div>
          <div className={classes.group}>
            <div>
              <Fields.Select label="Display group title ?" options={options} name="showTitle" submitOnChange={true} />
              <Fields.Select label="Display as block ?" options={options} name="block" submitOnChange={true} showError={false} />
            </div>
          </div>
          <div className={classes.group}>
            <div className={classes.sliderControls}>
              <Fields.Slider
                label="Desktop width"
                name="width"
                min={5}
                stepSize={5}
                submitOnChange={true}
                labelRenderer={(v, { isHandleTooltip }) => (isHandleTooltip ? v : null)}
                showError={false}
              />
              <Fields.Slider
                label="Mobile width"
                name="mobileWidth"
                min={5}
                stepSize={5}
                submitOnChange={true}
                labelRenderer={(v, { isHandleTooltip }) => (isHandleTooltip ? v : null)}
                showError={false}
              />
            </div>
          </div>
          <div className={classes.group}>
            <VisibilityConditions form={form} />
          </div>
          <div className={classes.group}>
            <Fields.Text label="Identifier" name="id" disabled={true} />
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default GroupForm;
