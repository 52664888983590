import React, { useRef, useCallback } from 'react';
import SimpleBar from 'simplebar-react';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cn from 'classnames';

import useDocuments from '../../../state/use-documents';
import { formatMacro } from '../../../helpers/macro-item-factory';

import DocumentContent from './document-content';
import MacroItem from './macro-item';
import Properties from './properties';
import Dropzone from './dropzone';

import classes from './pdf-editor.module.scss';

const PdfEditor = () => {
  const { template, selection, addNew, update, setSelection, removeSelection, duplicate, move } = useDocuments();
  const contentRef = useRef();

  const renderContent = useCallback(
    ({ targetOffset }) => {
      if (!targetOffset) {
        return null;
      }

      const rect = contentRef.current?.getClientRects()?.[0];
      const left = targetOffset.left - rect.left;
      const top = contentRef.current?.scrollTop + targetOffset.top - rect.top;

      const handleClick = () => {
        if (!rect) {
          return;
        }
        addNew({ left, top });
      };

      return (
        <Menu className={classes.newMacroMenu}>
          <MenuItem icon="plus" text="Add macro" shouldDismissPopover disabled={!contentRef.current} onClick={handleClick} />
        </Menu>
      );
    },
    [addNew, contentRef.current],
  );

  const handleDrop = React.useCallback(
    (data, monitor) => {
      const rect = contentRef.current?.getClientRects()?.[0];
      const finalPosition = monitor.getSourceClientOffset();
      const left = finalPosition.x - rect.left;
      const top = contentRef.current?.scrollTop + finalPosition.y - rect.top;
      const macro = formatMacro({ ...data, config: { ...data.config, style: { ...data.config.style, left, top } } });
      update(macro);
    },
    [update, contentRef.current],
  );

  const outsideClick = (e, excludingChildren = true) => {
    e.preventDefault();
    e.stopPropagation();
    // e.target - the element that was clicked
    // e.currentTarget - the element that the event listener is attached to
    if (excludingChildren && e.target !== e.currentTarget) {
      return;
    }
    removeSelection();
  };

  const preventScroll = selection?.action === 'edit';
  return (
    <DndProvider backend={HTML5Backend}>
      <SimpleBar className={cn(classes.wrapper, preventScroll && classes.overflow)}>
        <div className={classes.dropzone} onClick={outsideClick}>
          <div ref={contentRef} className={classes.content}>
            <Properties />
            <Dropzone handleDrop={handleDrop}>
              {template.metadata.map(macro => {
                const isId = selection?.macro?.id === macro.id;
                const selected = isId && selection?.action === 'select';
                return (
                  <MacroItem
                    selected={selected}
                    selection={selection}
                    macro={macro}
                    setSelection={setSelection}
                    removeSelection={removeSelection}
                    duplicate={duplicate}
                    move={move}
                    key={macro.id}
                  />
                );
              })}
              <ContextMenu className={classes.contextMenu} content={renderContent} onClick={e => outsideClick(e, false)}>
                <DocumentContent />
              </ContextMenu>
            </Dropzone>
          </div>
        </div>
      </SimpleBar>
    </DndProvider>
  );
};

export default PdfEditor;
