import React from 'react';
import { Route, Routes } from 'react-router-dom';

import List from './containers/list';
import TimeImport from './containers/import';

const Time = () => {
  return (
    <Routes>
      <Route path="/" Component={List} />
      <Route path="/import" Component={TimeImport} />
    </Routes>
  );
};

export default Time;
