import React, { useContext } from 'react';

import { ObjectTypes } from '../../../domain/types';
import { WizardSelectionContext } from '../../../state/selection-provider';

import WizardForm from './wizard-form';
import PageForm from './page-form';
import GroupForm from './group-form';
import FieldForm from './field-form';

const PropertiesForm = () => {
  const { getSelection } = useContext(WizardSelectionContext);

  const selection = getSelection();
  if (!selection) {
    return null;
  }

  switch (selection.type) {
    case ObjectTypes.Wizard:
      return <WizardForm />;

    case ObjectTypes.Page:
      return <PageForm />;

    case ObjectTypes.Group:
      return <GroupForm />;

    default:
      return <FieldForm />;
  }
};

export default PropertiesForm;
