import React from 'react';
import * as Icons from 'react-feather';
import { Button } from '@blueprintjs/core';

import Box from '../../../../../components/box';
import Table from '../../../../../components/table';

import useApi from '../../../../../hooks/use-api';
import { getSupplierNoticeBoard } from '../../../../../apis/supplier/organizations';

import classes from './notice-board.module.scss';

const NoticeBoard = () => {
  const api = useApi(getSupplierNoticeBoard);
  if (api.loading || !api.response?.length) {
    return null;
  }

  return (
    <Box title="Notice board" contentClassName={classes.board}>
      <Table.Container data={api?.response} handleScroll={false} showHeadings={false} dark>
        <Table.Columns.TwoLines showOnMobile lines={[d => d?.name, d => d?.description]} />
        <Table.Columns.Default
          width="32px"
          showOnMobile
          render={d => (
            <div className={classes.link}>
              <a href={d?.url} target="_blank" rel="noreferrer">
                <Button small outlined icon={<Icons.ArrowUpRight size={16} strokeWidth={1.5} />} />
              </a>
            </div>
          )}
        />
      </Table.Container>
    </Box>
  );
};

export default NoticeBoard;
