const getValueFromObject = mapperFn => item => {
  if (!item || typeof item !== 'object') return item;

  return mapperFn(item);
};

export const labelRenderer = getValueFromObject(item => item.label);

export const valueRenderer = getValueFromObject(item => item.value);

export const itemsEqual = (itemA, itemB) => valueRenderer(itemA) === valueRenderer(itemB);

export const isItemSelected = (items, item) => items.some(selectedItem => itemsEqual(selectedItem, item));

export const loadingArray = new Array(3).fill({}).map(() => ({ label: Math.random(), value: Math.random() }));
