import { Icon, Popover } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';

import { filterCount } from '../../../utilities/filter';

import classes from './filters.module.scss';

const Filters = ({ form, initialFilters, resetFilters, element }) => {
  if (!element) return null;

  const filterCounter = filterCount(form.values, initialFilters);
  const content = React.cloneElement(element, { ...form, filterCounter, resetFilters });
  return (
    <div className={classes.actions}>
      <div className={classNames(classes.button)}>
        <Popover placement="bottom" content={content}>
          <Icon className={classes.actionTarget} icon="filter" />
        </Popover>
      </div>
    </div>
  );
};

export default Filters;
