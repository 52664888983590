import moment from 'moment';
import { TrackingRecordStatusTypes } from '../../../../domain/tracking-record-status-type';
import { DateTimeFormat } from '../../../../models/enumerations/date-format';

export const getStatusCounter = records => {
  const initialCounterState = {
    [TrackingRecordStatusTypes.Pending]: 0,
    [TrackingRecordStatusTypes.Submitted]: 0,
    [TrackingRecordStatusTypes.Approved]: 0,
    [TrackingRecordStatusTypes.Disputed]: 0,
  };
  const counter =
    records?.reduce((reducer, r) => {
      reducer[r.status]++;
      return reducer;
    }, initialCounterState) || {};

  return counter;
};

export const getGroupStatus = records => {
  const counter = getStatusCounter(records);
  return getGroupStatusByCounter(counter, records?.length);
};

export const getGroupStatusByCounter = (counter, total) => {
  var empty = c => Object.values(c).reduce((a, b) => a + b, 0) === 0;
  var pending = c => c[TrackingRecordStatusTypes.Pending] + c[TrackingRecordStatusTypes.Submitted] === total;
  var inReview = c => c[TrackingRecordStatusTypes.Approved] < total;
  var disputed = c => c[TrackingRecordStatusTypes.Disputed] === total;
  var approved = c => c[TrackingRecordStatusTypes.Approved] === total;

  if (empty(counter)) {
    return 'empty';
  }
  if (pending(counter)) {
    return 'pending';
  }
  if (disputed(counter)) {
    return 'disputed';
  }
  if (approved(counter)) {
    return 'approved';
  }
  if (inReview(counter)) {
    return 'in-review';
  }

  return 'n/a';
};

export const sortByDate = records => {
  records?.sort((a, b) => {
    const xDate = moment(a?.date, DateTimeFormat.DEFAULT);
    const yDate = moment(b?.date, DateTimeFormat.DEFAULT);
    return xDate.toDate().getTime() - yDate.toDate().getTime();
  });
  return records;
};

export const groupByDay = records => {
  const shifts = sortByDate(records);
  const days = {};
  shifts?.forEach(r => {
    const day = moment(r?.date, DateTimeFormat.DEFAULT);
    const name = day.format('dddd, MMMM DD');
    if (!days[name]) {
      days[name] = { name, records: [] };
    }
    days[name].records.push(r);
  });
  return Object.values(days);
};
