import React, { useState } from 'react';
import { Tooltip } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import moment from 'moment';

import useTable from '../../../../../hooks/use-table';
import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import Avatar from '../../../../../components/avatar';

import { filterClients, removeClient } from '../../../../../apis/platform/organizations';
import { EmailDialog, useEmailDialog } from '../../../../../components/organizational/platform/email';

import ClientsMenu from './menu';
import ClientFilters from './filters';
import NewClient from './new';

import classes from '../../clients.module.scss';

const List = () => {
  const [dialog, setDialog] = useState({ open: false });
  const { items, loading, filters, initialFilters, pageCount, page, pageSize, reload, goToPage, setFilters, resetFilters } = useTable(
    filterClients,
    { pageSize: 10, controlled: true },
    { includeSubClients: null, apiEnabled: null, includeRemoved: null, countries: null },
  );
  const emailDialog = useEmailDialog({ recipients: 'clients' });

  const handleClientRemove = async client => {
    await removeClient(client?.id);
    reload();
  };

  return (
    <Page title="Clients" path={[{ name: 'Clients', to: '/clients' }]} scroll={false}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          filters={filters}
          initialFilters={initialFilters}
          menu={<ClientsMenu filters={filters} page={page} pageSize={pageSize} setDialog={setDialog} openEmailDialog={emailDialog.handleOpen} />}
          content={<ClientFilters />}
        />
        <Table.Columns.Default
          name="Name"
          showOnMobile
          width="30px"
          render={d => <Avatar abbreviate={true} size={{ box: 30, text: 12 }} name={d.name} url={d.icon} />}
        />
        <Table.Columns.TwoLines width="2fr" showOnMobile lines={[d => d?.name, d => d?.address?.prettyName]} />
        <Table.Columns.TwoLines width="2fr" name="Contact" lines={[d => d?.managerName || 'Not provided', d => d?.managerEmailAddress]} />
        <Table.Columns.Default
          width="65px"
          render={d => (
            <div className={classes.tags}>
              {!!d?.parentClientId && (
                <Tooltip content={`${d?.parentClientName} sub-client.`}>
                  <Icons.Share2 size={15} />
                </Tooltip>
              )}
              {!!d?.isPartner && (
                <Tooltip content={'Client is partner.'}>
                  <Icons.Users size={15} />
                </Tooltip>
              )}
              {!!d?.isInvoiced && (
                <Tooltip content={'Client is charged for invoices.'}>
                  <Icons.Book size={15} />
                </Tooltip>
              )}
              {d?.isApiEnabled && (
                <Tooltip content={`API integration enabled.`}>
                  <Icons.Share2 size={15} />
                </Tooltip>
              )}
            </div>
          )}
        />
        <Table.Columns.Default
          name="Created at"
          width="100px"
          render={d => <small>{moment(d.createdAt).isValid() ? moment(d.createdAt).format('LL') : moment(d.updatedAt).format('LL')}</small>}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', href: d => `/clients/${d.id}` },
            { name: 'Remove', onClick: d => handleClientRemove(d), confirmation: true },
            ...emailDialog.getTableActions({ emailRenderer: d => d.manager?.email }),
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <NewClient isOpen={dialog.open} onClose={() => setDialog({ open: false })} reloadList={reload} />
      <EmailDialog
        isMinimized={emailDialog.isMinimized}
        isOpen={emailDialog.isOpen}
        formValues={emailDialog.values}
        handleClose={emailDialog.handleClose}
        handleMinimize={emailDialog.handleMinimize}
      />
    </Page>
  );
};

export default List;
