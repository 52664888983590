const MacroTypes = {
  FROM_SCRATCH: 'from-scratch',
  DEFAULT: 'default',
  CELL: 'cell',
  SUBSTRING: 'substring',
  CHECKBOX: 'tick',
  DATE: 'date',
  INLINE_IMG: 'inline-image',
  STANDALONE_IMG: 'standalone-image',
  SIGNATURE: 'signature',
  CUSTOM_JS: 'custom-js',
};

export default MacroTypes;
