import React, { useContext } from 'react';

import Form from '../../../../../form';
import Fields from '../../../../../fields';

import VisibilityConditions from './components/visibility';
import { WizardContext } from '../../../state';
import { WizardSelectionContext } from '../../../state/selection-provider';
import getWizardSpecificMacros from '../../../common/get-wizard-specific-macros';

import classes from '../properties.module.scss';

const PageForm = () => {
  const { descriptor, updatePage } = useContext(WizardContext);
  const { getSelection } = useContext(WizardSelectionContext);

  const macros = React.useMemo(() => getWizardSpecificMacros(descriptor.children, []), [descriptor]);

  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const page = getSelection();

  return (
    <Form initialValues={page} onSubmit={updatePage}>
      {form => (
        <React.Fragment>
          <div className={classes.group}>
            <Fields.Text label="Title" name="title" submitOnChange={true} />
            <Fields.RichText
              name="description"
              submitOnChange={true}
              outline={false}
              minimal={true}
              options={macros}
              modalProps={{
                title: 'Setup page description',
                buttonProps: {
                  icon: 'header',
                  text: 'Setup description',
                },
              }}
            />
            <Fields.Select label="Show header in wizard?" options={options} name="showHeader" submitOnChange={true} showError={false} />
          </div>
          <div className={classes.group}>
            <VisibilityConditions form={form} />
          </div>
          <div className={classes.group}>
            <div className={classes.line}>
              <div className={classes.btnName}>
                <Fields.Select
                  label="Close button"
                  options={[{ label: 'Cross', value: 'cross' }]}
                  name="buttons.close.icon"
                  clearable={false}
                  submitOnChange={true}
                />
              </div>
              <div className={classes.showDropdown}>
                <Fields.Select label="Show?" options={options} name="buttons.close.show" submitOnChange={true} clearable={false} />
              </div>
            </div>
            <div className={classes.line}>
              <div className={classes.btnName}>
                <Fields.Text label="Back button" name="buttons.back.text" submitOnChange={true} />
              </div>
              <div className={classes.showDropdown}>
                <Fields.Select label="Show?" options={options} name="buttons.back.show" submitOnChange={true} clearable={false} />
              </div>
            </div>
            <div className={classes.line}>
              <div className={classes.btnName}>
                <Fields.Text label="Next button" name="buttons.next.text" submitOnChange={true} showError={false} />
              </div>
              <div className={classes.showDropdown}>
                <Fields.Select label="Show?" options={options} name="buttons.next.show" submitOnChange={true} showError={false} clearable={false} />
              </div>
            </div>
          </div>
          <div className={classes.group}>
            <Fields.Text label="Identifier" name="id" disabled={true} />
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default PageForm;
