import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Drawer } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

import UserMenu from '../user';

import classes from './mobile.module.scss';

const MobileMenu = ({ children }) => {
  const [open, setOpen] = useState(false);

  const items = React.Children.map(children, child => React.cloneElement(child, { isMobile: true, onClick: () => setOpen(false) }));

  return (
    <React.Fragment>
      <div className={classes.mobileMenuButton}>
        <Button minimal icon="menu" onClick={() => setOpen(true)} />
      </div>
      <Drawer position="left" isOpen={open} className={classes.mobileMenu} onClose={() => setOpen(false)}>
        <div className={classes.header}>
          <div className={classes.title}>GL</div>
          <Button minimal icon="cross" className={classes.iconButton} onClick={() => setOpen(false)} />
        </div>
        <SimpleBar className={classes.wrapper}>
          <div className={classes.content}>
            {items}
            <div className={classes.space} />
            <div className={classes.userMenuContainer}>
              <UserMenu isMobile={true} closeMenu={() => setOpen(false)} />
            </div>
          </div>
        </SimpleBar>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
