import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';

export const getDepartments = async ({ clientId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/client/${clientId}/departments`);
  return response;
};

export const createDepartment = async ({ clientId, department }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/client/${clientId}/department`, department);
  return response?.data;
};

export const updateDepartment = async ({ clientId, department }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/client/${clientId}/department`, department);
  return response?.data;
};

export const removeDepartment = async ({ clientId, department }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${organizationId}/client/${clientId}/department/${department?.id}`);
  return response?.data;
};
