import React from 'react';

export const SIGNATURE_PLACEHOLDER = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAxIiBoZWlnaHQ9IjE1MiIgdmlld0JveD0iMCAwIDUwMSAxNTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGcgaWQ9InN2Z18xMiI+PHJlY3Qgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEwIiBpZD0ic3ZnXzEiIGhlaWdodD0iMTUxLjk5OTk5IiB3aWR0aD0iNTAxIiB5PSIwIiB4PSIwIiBmaWxsPSJub25lIi8+PGcgaWQ9InN2Z185Ij48bGluZSBpZD0ic3ZnXzIiIHkyPSIxMTQuNDUyOCIgeDI9IjQ2OS42OTQ2MSIgeTE9IjExNC40NTI4IiB4MT0iNDEuNzEwOTciIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSIjMDAwMDAwIiBmaWxsPSJub25lIi8+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNjEuNzExIDY3Ljk1MjgpIiBpZD0ic3ZnXzgiPjxyZWN0IHRyYW5zZm9ybT0icm90YXRlKDkwIDYxLjcxMSA2Ny45NTI4KSIgaWQ9InN2Z182IiBoZWlnaHQ9IjciIHdpZHRoPSI0MyIgeT0iNjQuNDUyOCIgeD0iNDAuMjEwOTciIHN0cm9rZS13aWR0aD0iMCIgc3Ryb2tlPSIjMDAwMDAwIiBmaWxsPSIjMDAwMDAwIi8+PHJlY3QgaWQ9InN2Z183IiBoZWlnaHQ9IjciIHdpZHRoPSI0MyIgeT0iNjQuNDUyOCIgeD0iNDAuMjEwOTciIHN0cm9rZS13aWR0aD0iMCIgc3Ryb2tlPSIjMDAwMDAwIiBmaWxsPSIjMDAwMDAwIi8+PC9nPjwvZz48L2c+PC9nPjwvc3ZnPg==`;

const RichTextMedia = props => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const type = entity.getType();
  const data = entity.getData();

  if (type === 'IMAGE') {
    return (
      <span className="rdw-image-alignment rdw-image-left">
        <span className="rdw-image-imagewrapper">
          <img src={SIGNATURE_PLACEHOLDER} alt="" width={data.width} height={data.height} />
        </span>
      </span>
    );
  }
};

export default RichTextMedia;
