import React from 'react';

import Box from '../../box';

import NotesPopover from './notes-popover';
import FieldValueContainer from './field-value-container';

import classes from './timeline-step.module.scss';

const TimelineQuestionnaire = ({ template, engagement, review, loadReview }) => {
  if (!template?.sections?.length) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.separator}>
        <span>Questionnaire</span>
      </div>
      <div className={classes.list}>
        {template?.sections
          ?.filter(s => !!s.questions?.length)
          ?.map(section => (
            <Box
              key={section.id}
              monochrome
              small
              expandible
              title={section.title}
              titleStyle={{ color: section?.color }}
              actions={<NotesPopover categoryId={section.id} review={review} onComplete={loadReview} />}
            >
              {section.questions?.map(question => (
                <FieldValueContainer field={question} type="question" clientId={engagement?.clientId} key={question.id} />
              ))}
            </Box>
          ))}
      </div>
    </React.Fragment>
  );
};

export default TimelineQuestionnaire;
