import React from 'react';
import cn from 'classnames';
import { Button } from '@blueprintjs/core';

import { itemClick } from '../utilities';
import classes from '../cron-expression.module.scss';

const CellItem = ({ value, active, onClick }) => (
  <div className={cn(classes.tableCell, active && classes.tableCellActive)} onClick={onClick}>
    <div className={classes.tableCellValue}>{value}</div>
  </div>
);

const TableMenu = ({ selectAllBtnText, value, min, max, itemsPerRow, onChange }) => {
  const [selected, setSelected] = React.useState(value);
  const rows = [];
  const rowCount = Math.ceil((max - min) / itemsPerRow);
  for (let i = 1; i <= rowCount; i++) {
    const cells = [];
    for (let j = 1; j <= itemsPerRow; j++) {
      const num = min + (i - 1) * itemsPerRow + j - 1;
      const val = `${num}`;
      cells.push(
        <CellItem
          value={val}
          active={selected?.includes(val)}
          onClick={() => {
            const s = itemClick(selected, val);
            setSelected(s);
            onChange(s);
          }}
          key={val}
        />,
      );
      if (num === max) {
        break;
      }
    }
    rows.push(
      <div className={classes.tableRow} key={i}>
        {cells}
      </div>,
    );
  }

  React.useEffect(() => {
    if (value) {
      setSelected(value);
    } else {
      setSelected(undefined);
    }
  }, [value]);

  const allSelected = value?.length === 0;

  return (
    <div className={classes.tableMenu}>
      <div className={classes.tableCaption}>
        <div className={classes.tableCaptionText}>
          <Button
            text={selectAllBtnText}
            minimal
            intent={allSelected ? 'primary' : 'default'}
            icon={allSelected ? 'small-tick' : undefined}
            onClick={() => {
              setSelected([]);
              onChange([]);
            }}
          />
        </div>
        <div className={classes.divider} />
      </div>
      <div className={classes.tableBody}>{rows}</div>
    </div>
  );
};
export default TableMenu;
