import { OverlayToaster } from '@blueprintjs/core';
import { createRoot } from 'react-dom/client';

import ProgressToast from './progress-toast';

import classes from './progress-message.module.scss';

const instance = await OverlayToaster.createAsync(
  { position: 'top', className: classes.toast },
  { domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster) },
);

const toaster = {
  instance,
  show: instance.show,
  export: async ({ progress, complete, error, promise, onSuccess }) => {
    const progressToast = new ProgressToast({ toaster: instance });
    try {
      progressToast.start(progress);
      const data = await promise();
      progressToast.complete(complete);
      onSuccess?.(data);
    } catch (e) {
      progressToast.error(error);
    }
  },
};

export default toaster;
