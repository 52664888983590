import React from 'react';
import { Button, Classes } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';

import Page from './page';

import classes from './toolbox.module.scss';

const ToolboxLoading = () => (
  <div className={classes.toolbox}>
    <div className={classes.header}>
      <div className={classes.main}>
        <div className={classNames(classes.title, Classes.SKELETON)}>title</div>
        <div className={classNames(classes.description, Classes.SKELETON)}>description</div>
      </div>
      <Button minimal className={classNames(Classes.SKELETON)} icon="more" />
    </div>
    <SimpleBar className={classes.wrapper}>
      <div className={classes.pages}>
        <Page />
        <Page />
        <Page />
      </div>
    </SimpleBar>
    <div className={classes.footer}>
      <Button fill outlined className={Classes.SKELETON} />
      <Button fill outlined className={Classes.SKELETON} />
    </div>
  </div>
);

export default ToolboxLoading;
