import React from 'react';

import Integrations from '../../../../../components/organizational/client/integrations';
// import Webhooks from '../../../../../../../components/organizational/platform/webhooks';

import classes from '../../company-info.module.scss';

const ClientIntegrations = ({ clientId }) => {
  return (
    <div className={classes.tab}>
      <Integrations clientId={clientId} />
      {/* <Webhooks clientId={clientId} /> */}
    </div>
  );
};

export default ClientIntegrations;
