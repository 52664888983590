import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router';

import useTable from '../../../../../../../hooks/use-table';
import Table from '../../../../../../../components/table';
import Dialog from '../../../../../../../components/dialog';
import ExternalIdentities from '../../../../../../../components/external-identities';

import { getExternalIdentities, removeExternalIdentity } from '../../../../../../../apis/external-identities';

import ProvidersMenu from './menu';

import classes from '../../details.module.scss';

const Providers = () => {
  const { id } = useParams();
  const { items, loading, pageCount, page, initialFilters, reload, goToPage, setFilters, resetFilters } = useTable(
    getExternalIdentities,
    { pageSize: 10, controlled: true },
    { clientId: id, query: '' },
  );
  const [dialog, setDialog] = useState({ open: false });
  const closeDialog = () => setDialog({ open: false, clientId: undefined, identity: undefined, title: undefined });

  const onRemoveIdentity = async identity => {
    await removeExternalIdentity({ id: identity?.id });
    reload();
  };

  return (
    <div className={classes.tab}>
      <Table.Container data={items} loading={loading} dark>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          menu={
            <ProvidersMenu
              onRegister={() => setDialog({ open: true, identity: undefined, register: true, title: 'Register external identity' })}
              onLink={() => setDialog({ open: true, identity: undefined, register: false, title: 'Add external identity' })}
            />
          }
        />
        <Table.Columns.TwoLines showOnMobile name="Provider" lines={[d => d?.providerName, d => d?.providerType]} />
        <Table.Columns.Default showOnMobile name="Identifier" render={d => d?.externalOrganizationId} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'Modify', onClick: d => setDialog({ open: true, clientId: id, identity: d, register: false, title: 'Update external identity' }) },
            { name: 'Remove', onClick: d => onRemoveIdentity(d), confirmation: { messages: { confirmButton: 'Remove' } } },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <Dialog isOpen={!!dialog?.open} onClose={closeDialog} title={dialog?.title}>
        <ExternalIdentities.Form
          clientId={id}
          identity={dialog?.identity}
          register={dialog?.register}
          onComplete={() => {
            reload();
            closeDialog();
          }}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
