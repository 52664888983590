import React from 'react';
import { Checkbox as BluePrintCheckbox } from '@blueprintjs/core';
import cn from 'classnames';

import { Field } from '../../form';

import styles from './checkbox.module.scss';

const CheckboxField = ({ classes, label, description, name, disabled, validate, submitOnChange = false }) => (
  <Field classes={{ ...classes, wrapper: cn(styles.wrapper, classes?.wrapper) }} name={name} validate={validate} outline={false}>
    {({ field, form }) => (
      <BluePrintCheckbox
        className={classes?.checkbox}
        checked={!!field.value}
        label={label}
        disabled={disabled}
        labelElement={description && <span> ({description})</span>}
        onChange={event => {
          form.setFieldValue(name, event.currentTarget.checked);
          if (!!submitOnChange) {
            form.submitForm();
          }
        }}
      />
    )}
  </Field>
);

export default CheckboxField;
