import Clients from '../../configuration/client';
import { StorageManager } from '../../authentication/storage';
import { toQueryString } from '../../utilities/common';
import moment from 'moment';

export const getTimeRecords = async ({ filters, page, pageSize }) => {
  const { organizationType, organizationId, roleId, membershipId } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    page,
    pageSize,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
    manager: roleId === 'client-hiring-manager' ? membershipId : undefined,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time${toQueryString(parameters)}`);
  return response?.data;
};

export const exportTimeRecords = async filters => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    range: undefined,
    start: moment(filters.range[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
    end: moment(filters.range[1]).endOf('day').format('YYYY-MM-DDTHH:mm'),
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/export${toQueryString(parameters)}`);
  return response?.data;
};

export const reviewRecords = async ({ status, records }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/review`, { status, records });
  return response?.data;
};

export const getRecordComments = async recordId => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/${recordId}/comments`);
  return response?.data;
};

export const createRecordComment = async ({ recordId, message }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.post(`ui/${organizationType}s/${organizationId}/time/${recordId}/comment`, { recordId, message });
  return response?.data;
};

export const updateRecordComment = async ({ commentId, recordId, message }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.put(`ui/${organizationType}s/${organizationId}/time/${recordId}/comment/${commentId}`, { recordId, message });
  return response?.data;
};

export const removeRecordComment = async ({ commentId, recordId }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.delete(`ui/${organizationType}s/${organizationId}/time/${recordId}/comment/${commentId}`);
  return response?.data;
};

export const getTimeReviewRecords = async ({ filters, page, pageSize }) => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const parameters = {
    ...filters,
    page,
    pageSize,
  };
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/review${toQueryString(parameters)}`);
  return response?.data;
};

export const exportTimeReviewRecords = async filters => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/review/export${toQueryString(filters)}`);
  return response?.data;
};

export const getTimeReviewRecordStats = async () => {
  const { organizationType, organizationId } = new StorageManager().getActiveMembershipIdentifiers();
  const response = await Clients.Api.get(`ui/${organizationType}s/${organizationId}/time/review/stats`);
  return response?.data;
};
