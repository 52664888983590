import React from 'react';
import { Route, Routes } from 'react-router-dom';

import List from './containers/list';
import Details from './containers/details';
import EngagementImport from './containers/import';

const Engagements = () => {
  return (
    <Routes>
      <Route path="/" Component={List} />
      <Route path="/:id" Component={Details} />
      <Route path="/import" Component={EngagementImport} />
    </Routes>
  );
};

export default Engagements;
