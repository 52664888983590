import React from 'react';
import classNames from 'classnames';
import * as Icons from 'react-feather';

import classes from './pagination.module.scss';

const TablePagination = ({ loading, currentPage, totalPages, goToPage }) => {
  if (!!loading) return null;
  if (totalPages <= currentPage) return null;

  return (
    <div className={classes.pagination}>
      <div className={classNames(classes.back, currentPage === 0 && classes.disabled)} onClick={() => (currentPage <= 0 ? null : goToPage(0))}>
        <Icons.ChevronsLeft size={24} strokeWidth={1} />
      </div>
      <div className={classNames(classes.back, currentPage === 0 && classes.disabled)} onClick={() => (currentPage <= 0 ? null : goToPage(currentPage - 1))}>
        <Icons.ChevronLeft size={24} strokeWidth={1.5} />
      </div>
      <div className={classes.position}>{`${currentPage + 1} of ${totalPages}`}</div>
      <div
        className={classNames(classes.forward, currentPage + 1 === totalPages && classes.disabled)}
        onClick={() => (currentPage + 1 >= totalPages ? null : goToPage(currentPage + 1))}
      >
        <Icons.ChevronRight size={24} strokeWidth={1.5} />
      </div>
      <div
        className={classNames(classes.forward, currentPage + 1 === totalPages && classes.disabled)}
        onClick={() => (currentPage + 1 >= totalPages ? null : goToPage(totalPages - 1))}
      >
        <Icons.ChevronsRight size={24} strokeWidth={1} />
      </div>
    </div>
  );
};

TablePagination.definition = 'pagination';

export default TablePagination;
