import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import SearchBar from './search-bar';
import Filters from './filters';
import Menu from './menu';

import classes from './filters.module.scss';

const TableFilters = ({ setFilters, resetFilters, filters, content, initialFilters = {}, menu, dark, disabled }) => {
  return (
    <div className={classNames(classes.component, !!dark && classes.dark)}>
      <Formik initialValues={{ query: '', ...filters }} onSubmit={async values => setFilters(values)} enableReinitialize={true}>
        {form => {
          const { values, setFieldValue, submitForm } = form;
          const clearSearch = () => {
            setFieldValue('query', '');
            submitForm();
          };

          return (
            <Form autoComplete="off">
              <div className={classes.filters}>
                <SearchBar
                  className={classes.search}
                  name="query"
                  placeholder="Search..."
                  disabled={disabled}
                  extra={{
                    leftIcon: 'search',
                    rightElement: !!values?.query && <Button className={classes.reset} minimal icon="cross" onClick={clearSearch} />,
                  }}
                />
                <Filters form={form} element={content} initialFilters={initialFilters} resetFilters={resetFilters} disabled={disabled} />
                <Menu form={form} element={menu} resetFilters={resetFilters} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

TableFilters.definition = 'filters';

export default TableFilters;
