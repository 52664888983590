import React from 'react';
import { Button } from '@blueprintjs/core';

import Fields, { SelectField } from '../../../../fields';
import Spinner from '../../../../spinner';

import useApi from '../../../../../hooks/use-api';
import { getConfigurationInCountry, getConfigurationInScope } from '../../../../../apis/configuration';
import { getWizardLookupData } from '../../../../../apis/wizards';

import classes from './background-check.module.scss';

const bgCheckType = 'ServiceProviders|BackgroundCheck';

function getProviders({ organizationId, countryId, scopeId }) {
  if (scopeId) {
    return getConfigurationInScope(organizationId, bgCheckType, scopeId);
  }
  return getConfigurationInCountry(organizationId, bgCheckType, countryId);
}
const BackgroundCheckForm = ({ client, record, records, values, loading, scopesApi }) => {
  const getProvidersApi = useApi(
    ({ countryId, scopeId }) =>
      getProviders({
        organizationId: client?.id,
        countryId,
        scopeId,
      }),
    {
      skip: true,
    },
  );

  const lookupApi = useApi(getWizardLookupData, { skip: true });
  const globalScope = scopesApi.response?.find?.(s => !s.parentId);
  const editing = !!record;

  React.useEffect(() => {
    if (record) {
      getProvidersApi.request({ scopeId: record.scopeId });
    }
  }, [record]);

  React.useEffect(() => {
    if (values.location) {
      const [countryId, scopeId] = values.location.split('_');
      if (scopeId) {
        getProvidersApi.request({ scopeId });
      } else {
        getProvidersApi.request({ countryId });
      }
    }
  }, [values.location]);

  const providers = parseProviderPackages(getProvidersApi.response);

  const alreadyAdded = records?.reduce((mapper, obj) => ({ ...mapper, [obj.scopeId]: obj }), {});
  const scopeMapper = scopesApi.response?.reduce((mapper, obj) => {
    if (obj.parentId) {
      const location = extractCountryId(JSON.parse(obj.json));
      return { ...mapper, [location]: obj };
    }
    return mapper;
  }, {});
  const options = getOptions(providers, values.data.providerName);
  const getDescription = prop => {
    return options.find?.(p => p?.value === prop)?.description;
  };

  const displayCountry = scopesApi.loading === false;
  const loadingData = getProvidersApi.loading || scopesApi.loading;
  return (
    <React.Fragment>
      {displayCountry && (
        <SelectField
          name="location"
          label="Location"
          remoteOptions={{
            loading: lookupApi.loading,
            request: () => lookupApi.request('client-available-countries', { clientId: client?.id }),
            mapper: result => {
              const formatted = result.map(c => {
                const scopeId = scopeMapper[c.value];
                const value = scopeId ? `${c.value}_${scopeId.id}` : c.value;
                return { label: c.label, value };
              });

              if (globalScope) {
                formatted.unshift({ label: 'Global', value: `_${globalScope.id}` });
              }

              const locations = formatted.filter(c => {
                const [, scopeId] = c.value.split('_');
                return editing || !alreadyAdded[scopeId]; // when editing we need all locations so select is populated but disabled
              });
              return locations;
            },
            filter: 'local',
          }}
          clearable={false}
          disabled={editing}
        />
      )}

      {loadingData ? (
        <Spinner global={false} blend={true} />
      ) : (
        <div>
          {getProvidersApi.response && (
            <div>
              <Fields.Select
                name="data.providerName"
                label="Provider"
                placeholder="Select background check provider"
                options={providers?.map?.(p => ({ value: p?.providerName, label: p?.providerName }))}
              />
              {values.data.providerName && (
                <div className={classes.panels}>
                  <div className={classes.panel}>
                    <Fields.Select name="data.employeeOption" label="Employee package" placeholder="Select package" options={options} />
                    <div className={classes.description}>{getDescription(values.data.employeeOption)}</div>
                  </div>
                  <div className={classes.panel}>
                    <Fields.Select
                      name="data.independentContractorOption"
                      label="Independent contractor package"
                      placeholder="Select package"
                      options={options}
                    />
                    <div className={classes.description}>{getDescription(values.data.independentContractorOption)}</div>
                  </div>
                </div>
              )}
              <Button type="submit" text="Submit" loading={loading} outlined fill />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const getOptions = (providers, providerName) => {
  if (!providerName || !providers) return [];
  return [
    { value: null, label: 'None', description: 'No background check will be conducted.' },
    ...(providers?.find?.(p => p.providerName === providerName)?.packages?.map?.(p => ({ value: p?.slug, label: p?.name, description: p?.description })) || []),
  ];
};

const parseProviderPackages = response => {
  if (!response?.length) return [];

  const providers = response?.map?.(i => {
    const packages = JSON.parse(i?.options) || [];
    return { providerName: i?.providerName, packages };
  });

  return providers;
};

function extractCountryId(location) {
  const country = location?.address_components?.find?.(c => c.types.includes('country'));
  return country?.short_name;
}

export default BackgroundCheckForm;
