import React from 'react';
import ReactDOM from 'react-dom';

import { RichTextField } from '../../../fields';
import FormGroup from '../../../form/form-group';
import { getAdHocEmailTemplate } from '../../../../apis/templates';
import useApi from '../../../../hooks/use-api';
import Spinner from '../../../spinner';

import classes from './email-dialog.module.scss';

const EmailPreview = () => {
  const getAdHocEmailTemplateApi = useApi(getAdHocEmailTemplate);
  const ref = React.useRef();
  const [ready, setReady] = React.useState(false);
  const parentRef = React.useRef();

  React.useEffect(() => {
    if (ref.current && getAdHocEmailTemplateApi.response) {
      parentRef.current = ref.current.querySelector('#gl-ad-hoc-content');
      parentRef.current.innerText = '';
      setReady(true);
    }
  }, [ref.current, getAdHocEmailTemplateApi.response]);

  return (
    <FormGroup classes={{ wrapper: classes.previewWrapper }} label="Email Content">
      {getAdHocEmailTemplateApi.loading && (
        <div className={classes.loading}>
          <Spinner global={false} blend />
        </div>
      )}
      <div dangerouslySetInnerHTML={{ __html: getAdHocEmailTemplateApi.response }} ref={ref} />
      {ready && ReactDOM.createPortal(<RichTextField name="content" size="sm" />, parentRef.current)}
    </FormGroup>
  );
};

export default EmailPreview;
