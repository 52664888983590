import React, { useState } from 'react';
import moment from 'moment';
import { Drawer } from '@blueprintjs/core';

import Page from '../../../../../components/page';
import Table from '../../../../../components/table';
import Dialog from '../../../../../components/dialog';

import useTable from '../../../../../hooks/use-table';
import { cancelTimeImportRequest, getTimeImportRequests } from '../../../../../apis/platform/time';

import { capitalize } from '../../../../../utilities/common';

import TimeImportFilters from './filters';
import TimeImportMenu from './menu';
import ImportRequestDetails from './details';
import CreateImportRequestForm from './forms/create-import-request';

const TimeImport = () => {
  const { items, loading, filters, pageCount, page, reload, goToPage, setFilters, resetFilters } = useTable(
    getTimeImportRequests,
    { pageSize: 10 },
    { complete: false, validated: false },
  );

  const handleRemove = async ({ id }) => {
    await cancelTimeImportRequest({ id });
    reload();
  };

  const [dialog, setDialog] = useState({ open: false, title: undefined, type: undefined, data: undefined, ui: undefined });
  const onCloseDialog = () => setDialog({ open: false, title: undefined, type: undefined, data: undefined, ui: undefined });

  return (
    <Page
      title="Time import"
      path={[
        { name: 'Time', to: '/time/' },
        { name: 'Import', to: '/time/import' },
      ]}
      scroll={false}
    >
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={{ ...filters }}
          filters={<TimeImportFilters />}
          menu={<TimeImportMenu onImport={() => setDialog({ open: true, title: 'Create new import request', type: 'create-time-import', ui: 'dialog' })} />}
        />
        <Table.Columns.Default name="Status" render={d => capitalize(d?.status)} />
        <Table.Columns.Default width="60px" name="Total" render={d => d?.total} />
        <Table.Columns.Default width="60px" name="Validated" render={d => d?.totalValidated} />
        <Table.Columns.Default width="60px" name="imported" render={d => d?.totalImported} />
        <Table.Columns.Default name="Created by" render={d => d?.createdBy} />
        <Table.Columns.Default name="Created at" render={d => (!!d?.createdAt ? moment(d?.createdAt).format('MM/DD/YYYY [at] HH:mm') : '-')} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'View', onClick: d => setDialog({ open: true, title: 'Import request', type: 'view-time-import', ui: 'drawer', data: d }) },
            { divider: true },
            { name: 'Remove', onClick: d => handleRemove(d), confirmation: true },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <Dialog isOpen={dialog?.open && dialog?.ui === 'dialog'} title={dialog?.title} onClose={onCloseDialog}>
        {dialog?.type === 'create-time-import' && <CreateImportRequestForm onClose={onCloseDialog} reloadList={reload} />}
      </Dialog>
      <Drawer position="right" isOpen={dialog?.open && dialog?.ui === 'drawer'}>
        {dialog?.type === 'view-time-import' && <ImportRequestDetails data={dialog?.data} onClose={onCloseDialog} reloadList={reload} />}
      </Drawer>
    </Page>
  );
};

export default TimeImport;
