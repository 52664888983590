import React from 'react';
import { Button } from '@blueprintjs/core';

import Dialog from '../../../../../../../components/dialog';
import Form from '../../../../../../../components/form';
import useApi from '../../../../../../../hooks/use-api';

import { updateEngagementMetadata } from '../../../../../../../apis/client/engagements';
import { TextField } from '../../../../../../../components/fields';

const MetadataForm = ({ isOpen = false, engagement, fields, metadata, onClose, onSave }) => {
  const updateEngagementMetadataApi = useApi(updateEngagementMetadata, { skip: true });

  const submit = async values => {
    await updateEngagementMetadataApi.request({ engagementId: engagement.id, metadata: values });
    onSave();
  };

  const loading = updateEngagementMetadataApi.loading;

  return (
    <Dialog title="Metadata details update" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={{ ...(metadata || {}) }} enableReinitialize onSubmit={submit}>
        {fields.map(field => (
          <TextField name={field.name} label={field.name} placeholder={field.example} type={field.type} key={field.name} />
        ))}
        <Button type="submit" fill outlined text="Save" disabled={loading} loading={loading} />
      </Form>
    </Dialog>
  );
};

export default MetadataForm;
