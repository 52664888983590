import React from 'react';

import Dialog from '../../../components/dialog';
import GenerateInvoiceForm from './forms/generate-invoices';

const GenerateInvoiceDialog = ({ onClose, open }) => {
  return (
    <Dialog isOpen={!!open} onClose={onClose} title="Generate invoice(s)">
      <div>
        <GenerateInvoiceForm onComplete={onClose} />
      </div>
    </Dialog>
  );
};

export default GenerateInvoiceDialog;
