import React, { useEffect, useState } from 'react';
import { Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import classNames from 'classnames';

import { getAbbreviation, getColor } from '../../utilities/common';

import classes from './avatar.module.scss';

const Avatar = ({
  name = '',
  url,
  size,
  abbreviate = false,
  loading = false,
  styles = {},
  classOverrides = '',
  showOnlineIndicator = false,
  showInviteIndicator = false,
  crossedOut = false,
  upload,
  ...rest
}) => {
  const [loader, setLoader] = useState(!!url);
  const color = !!name ? getColor(name) : 'transparent';
  const style = {
    backgroundImage: !!url ? `url("${url}")` : 'unset',
    backgroundColor: !!url ? undefined : color,
    fontSize: !!size?.text ? `${size?.text}px` : 'inherit',
    flexBasis: !!size?.box ? `${size?.box}px` : '0',
    width: !!size?.box ? `${size?.box}px` : 'inherit',
    height: !!size?.box ? `${size?.box}px` : 'inherit',
    ...styles,
  };

  useEffect(() => {
    function cacheImg() {
      if (url) {
        const img = new Image();
        img.onload = async () => {
          setLoader(false);
        };
        img.src = url;
      }
    }
    cacheImg();
  }, [url]);

  const compileName = name => {
    if (!name) {
      return ':)';
    }

    if (!!abbreviate) {
      return getAbbreviation(name);
    }

    var parts = [];
    name
      ?.split(' ')
      ?.map(p => p.length)
      ?.forEach(p => {
        if (parts.length === 0) {
          parts.push(p);
        } else {
          if (parts[parts.length - 1] + p <= 14) {
            parts[parts.length - 1] += p;
          } else {
            parts.push(p);
          }
        }
      }, []);

    if (parts.length <= 5 && parts.every(p => p <= 14)) {
      return name;
    }

    return getAbbreviation(name);
  };

  return (
    <div
      className={classNames(classes.avatar, classOverrides, !!upload && classes.upload, !!crossedOut && classes.crossedOut, loader && Classes.SKELETON)}
      style={style}
      {...rest}
    >
      {!loading && !!showOnlineIndicator && <div className={classes.online} />}
      {!loading && !!showInviteIndicator && (
        <div className={classes.invite}>
          <Icons.Mail size={size.text || 20} strokeWidth={2} />
        </div>
      )}
      {!!loading && <div className={Classes.SKELETON}>loading</div>}
      {!loading && !!name && !url && !showInviteIndicator && <div className={classes.name}>{compileName(name)}</div>}
      {!!upload && size?.box && size?.box > 50 && (
        <div className={classes.icon} style={!!url ? {} : { backgroundColor: color }}>
          <Icons.Upload size={size.text || 20} strokeWidth={1.5} />
        </div>
      )}
      {!!crossedOut && (
        <div className={classes.cross}>
          <Icons.X size={!!size?.box ? size?.box * 0.9 : 20} strokeWidth={2} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
