import React from 'react';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../../../../../components/fields';
import Form from '../../../../../../../../components/form';

import { Roles } from '../../../../../../../../models/enumerations/roles';

import useApi from '../../../../../../../../hooks/use-api';
import { updateMember } from '../../../../../../../../apis/members';

const UpdateMemberForm = ({ membership, onComplete }) => {
  const updateApi = useApi(updateMember, { skip: true });

  const submit = async values => {
    await updateApi.request(values);
    onComplete();
  };

  const getRoleOptions = () => [
    { label: 'Supplier Manager', value: Roles.Supplier.Manager },
    { label: 'Supplier Subordinate', value: Roles.Supplier.Subordinate },
    { label: 'Supplier Witness', value: Roles.Supplier.Witness },
  ];

  return (
    <Form
      initialValues={{
        firstName: membership?.user?.personalDetails?.firstName,
        lastName: membership?.user?.personalDetails?.lastName,
        email: membership?.user?.email,
        roleId: membership?.roleId,
        membershipId: membership?.id,
      }}
      validationSchema={Yup.object().shape({
        roleId: Yup.string().required('Role is required.'),
        membershipId: Yup.string().required('Membership identifier is required.'),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {form => (
        <React.Fragment>
          <Fields.Text label="First Name" name="firstName" disabled />
          <Fields.Text label="Last Name" name="lastName" disabled />
          <Fields.Text label="Email" name="email" disabled />
          <Fields.Select label="Role" name="roleId" options={getRoleOptions()} />
          <Button
            type="submit"
            outlined
            fill
            text="Submit"
            disabled={form?.values?.roleId === membership?.roleId || updateApi.loading}
            loading={updateApi.loading}
          />
        </React.Fragment>
      )}
    </Form>
  );
};

export default UpdateMemberForm;
