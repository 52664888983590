import React, { useState } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../../components/box';

import WithConfirmation from '../../../../../hooks/with-confirmation';
import useApi from '../../../../../hooks/use-api';
import { listBankAccounts, removeBankAccount } from '../../../../../apis/banking';

import BillingForm from './forms/billing-form';

const Billing = ({ client }) => {
  const [dialog, setDialog] = useState({ open: false, bank: undefined });
  const openDialog = bank => setDialog({ open: true, bank });
  const closeDialog = () => {
    setDialog({ open: false, bank: undefined });
  };
  const onSubmit = () => {
    setDialog({ open: false, bank: undefined });
    getBankAccountsApi?.request({ organizationId: client?.id });
  };

  const getBankAccountsApi = useApi(listBankAccounts, { params: { organizationId: client?.id }, skip: !client?.id });
  const removeBankAccountApi = useApi(removeBankAccount, { skip: true });

  const handleRemoveBankAccount = async id => {
    await removeBankAccountApi?.request(id);
    await getBankAccountsApi?.request({ organizationId: client?.id });
  };

  return (
    <Box
      title="Billing"
      outlined
      monochrome
      actions={
        <ButtonGroup>
          <Button small outlined icon={<Icons.Plus size={16} strokeWidth={1.5} />} title={'Add new bank account'} onClick={() => openDialog({})} />
        </ButtonGroup>
      }
      loading={getBankAccountsApi.loading || removeBankAccountApi.loading}
    >
      {!getBankAccountsApi?.response?.length ? (
        <Box.ItemContainer>
          <Box.ItemLabel fill>No bank accounts set up.</Box.ItemLabel>
        </Box.ItemContainer>
      ) : (
        getBankAccountsApi?.response?.map((bank, index) => (
          <Box.ItemContainer key={bank.id}>
            <Box.ItemValue>{bank.accountName}</Box.ItemValue>
            <ButtonGroup>
              {bank.isPrimary && <Button small outlined icon={<Icons.Award size={16} strokeWidth={1.5} />} title="This is your primary bank account" />}
              <Button small outlined icon={<Icons.Edit3 size={16} strokeWidth={1.5} />} title="Update bank account" onClick={() => openDialog(bank)} />
              <WithConfirmation disabled={bank?.isPrimary} messages={{ question: `Remove bank account: (${bank.accountName}) ?`, confirmButton: 'Remove' }}>
                <Button
                  small
                  outlined
                  disabled={bank?.isPrimary}
                  onClick={() => handleRemoveBankAccount(bank.id)}
                  icon={<Icons.X size={16} strokeWidth={1.5} />}
                  title="Remove bank account"
                />
              </WithConfirmation>
            </ButtonGroup>
          </Box.ItemContainer>
        ))
      )}
      <BillingForm isOpen={dialog.open} bank={dialog.bank} owner={{ organizationId: client?.id }} onClose={closeDialog} onSubmit={onSubmit} readOnly={false} />
    </Box>
  );
};

export default Billing;
