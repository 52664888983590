import { uuid } from './common';

export const downloadStringAsFile = (value, filename = uuid(), extension = 'txt') => {
  const element = document.createElement('a');
  const file = new Blob([value], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${filename}.${extension}`;
  document.body.appendChild(element);
  element.click();
  element.remove();
};

export const downloadFromResponse = response => {
  const content = response?.data;
  const filename = 'test.csv';
  const element = document.createElement('a');
  const file = new Blob([content], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  element.remove();
};

export const downloadSampleEngagementImportCsv = () => {
  const value =
    'Work.Title,Work.Description,Work.StartDate,Work.EndDate,Work.HiringManager,Work.Department,Compensation.Frequency,Compensation.Type,Compensation.Currency,Compensation.Amount,Compensation.Allocation,Compensation.TracksWork,Location.LocationType,Location.Country,Location.WorkLocation,PurchaseOrder.Status,PurchaseOrder.Number,PurchaseOrder.Amount,PurchaseOrder.Currency,PurchaseOrder.Reference,Supplier.FirstName,Supplier.LastName,Supplier.Email,Supplier.Phone,Supplier.BusinessName,ClassificationPreference,ClientRequiresBackgroundCheck,WaiveBackgroundCheck,Metadata,ClientId,ReferenceId\r\n"Software Engineer","Do something...",2024-04-01,2025-04-01,"brayan.thomas@greenlight.ai","R&D",weekly,hourly,USD,40,40,true,on-site,US,"12 21st Ave, San Francisco, CA 94121, USA",available,1,12,USD,2,John,Doe,"john@doe.com",123123123,"AcmeInc",ee-only,,Yes,,,,';
  const element = document.createElement('a');
  const file = new Blob([value], { type: 'text/csv' });
  element.href = URL.createObjectURL(file);
  element.download = 'engagement-import-sample.csv';
  document.body.appendChild(element);
  element.click();
  element.remove();
};

export const downloadSampleTimeImportCsv = () => {
  const value =
    'EngagementId,UserEmail,Type,Date,Duration,Amount,Notes\r\n00665872-0628-43a7-af39-e7775ad383f4,"quezsez@gmail.com",shift,"2024-06-21T09:00","04:00",,"did some work for 4 hours..."\r\n00665872-0628-43a7-af39-e7775ad383f4,"quezsez@gmail.com",deliverable,"2024-06-22T09:00",,125.00,"deliverable for amount of 125.00"\r\n00665872-0628-43a7-af39-e7775ad383f4,"quezsez@gmail.com",expense,"2024-06-24T09:00",,57.35,"expense for amount of 57.35"';
  const element = document.createElement('a');
  const file = new Blob([value], { type: 'text/csv' });
  element.href = URL.createObjectURL(file);
  element.download = 'time-import-sample.csv';
  document.body.appendChild(element);
  element.click();
  element.remove();
};
