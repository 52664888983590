import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon, Menu, MenuItem, Spinner, Popover } from '@blueprintjs/core';
import cn from 'classnames';

import Form, { Field } from '../../form';
import ButtonGroupField from '../group-input/button-group';
import RadioGroupField from '../group-input/radio-group';
import TextField from '../text';

import styles from './select.module.scss';

/**
 * Select filed.
 * @param classes css overrides.
 * @param name field name.
 * @param label field label.
 * @param placeholder field placeholder.
 * @param description field description.
 * @param options field options options provided upfront.
 * @param remoteOptions field options options provided via api request. Configuration object properties are loading, request, mapper (response mapper) and filter ('local' or 'remote')
 * @param disabled a flag indicating if the field is disabled.
 * @param validate field validation.
 * @param searchable a flag indicating if the field options are filterable.
 * @param submitOnChange a flag indicating if the form containing the field will be submitted once value is selected.
 */

const displayType = {
  DEFAULT: 'default',
  RADIO: 'radio',
  BUTTON_GROUP: 'button-group',
};

const defaultTextRenderer = option => option?.label;
const defaultLabelRenderer = option => option?.label;

const SelectField = ({
  classes,
  name,
  label,
  placeholder = 'Select...',
  description,
  options = [],
  remoteOptions,
  validate,
  submitOnChange = false,
  extra = {},
  display = displayType.DEFAULT,
  direction = 'horizontal',
  textRenderer = defaultTextRenderer,
  labelRenderer = defaultLabelRenderer,
  outline,
  minimal,
  showError = true,
  disabled,
  searchable = false,
  clearable = true,
  hidden,
}) => {
  const isMounted = useRef(false);
  const [internalOptions, setInternalOptions] = useState(!!remoteOptions ? [] : options);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    async function fetchRemoteOptions() {
      if (remoteOptions?.request && remoteOptions?.mapper) {
        const result = await remoteOptions.request(searchString);
        setInternalOptions(remoteOptions.mapper(result || []));
      }
    }
    void fetchRemoteOptions();
  }, [remoteOptions?.deps]);

  useEffect(() => {
    if (!remoteOptions) {
      setInternalOptions(options);
    }
  }, [options, remoteOptions]);

  useEffect(() => {
    async function fetchRemoteOptions() {
      if (isMounted.current) {
        if (remoteOptions?.request && remoteOptions?.mapper && remoteOptions?.filter === 'remote') {
          const result = await remoteOptions.request(searchString);
          setInternalOptions(remoteOptions.mapper(result || []));
        }
      } else {
        isMounted.current = true;
      }
    }
    void fetchRemoteOptions();
  }, [searchString]);

  if (display === displayType.RADIO) {
    return (
      <RadioGroupField
        classes={{ ...classes, wrapper: cn(hidden && styles.hidden, classes?.wrapper) }}
        loading={remoteOptions?.loading}
        label={label}
        description={description}
        name={name}
        options={internalOptions}
        inline={direction === 'horizontal'}
        disabled={disabled}
      />
    );
  }

  if (display === displayType.BUTTON_GROUP) {
    return (
      <ButtonGroupField
        classes={{ ...classes, wrapper: cn(hidden && styles.hidden, classes?.wrapper) }}
        loading={remoteOptions?.loading}
        label={label}
        description={description}
        name={name}
        options={internalOptions}
        inline={direction === 'horizontal'}
        intentSelected="primary"
        fill
        disabled={disabled}
      />
    );
  }

  // When enabled, clicks inside a Classes.POPOVER_DISMISS element will only close the current popover and not outer popovers.
  // When disabled, the current popover and any ancestor popovers will be closed.
  const captureDismiss = true;
  return (
    <Field
      classes={{ ...classes, wrapper: cn(hidden && styles.hidden, classes?.wrapper) }}
      name={name}
      label={label}
      description={description}
      validate={validate}
      outline={outline}
      minimal={minimal}
      showError={showError}
    >
      {({ field, form }) => {
        const onSelect = value => {
          if (value === field.value && clearable) {
            form.setFieldValue(name, undefined);
          } else {
            form.setFieldValue(name, value);
          }
          if (submitOnChange) {
            void form.submitForm();
            setTimeout(form.submitForm);
          }
        };

        return (
          <SelectPopover
            placeholder={placeholder}
            value={field?.value}
            internalOptions={internalOptions}
            remoteOptions={remoteOptions}
            searchable={searchable}
            disabled={disabled}
            searchString={searchString}
            captureDismiss={captureDismiss}
            extra={extra}
            textRenderer={textRenderer}
            labelRenderer={labelRenderer}
            setSearchString={setSearchString}
            onSelect={onSelect}
          />
        );
      }}
    </Field>
  );
};

const SelectPopover = ({
  placeholder,
  value,
  internalOptions,
  remoteOptions,
  disabled,
  searchable,
  searchString,
  captureDismiss,
  extra,
  textRenderer,
  labelRenderer,
  setSearchString,
  onSelect,
}) => {
  // Render options.
  const content = useMemo(() => {
    const filterOptions = option =>
      searchString === '' ||
      option?.value?.toString()?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
      option?.description?.toString()?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
      option?.label?.toString()?.toLowerCase()?.includes(searchString?.toLowerCase());

    const filtered = !!searchable || !!remoteOptions ? internalOptions.filter(filterOptions) : internalOptions;
    return (
      <Menu>
        {(!!searchable || !!remoteOptions) && (
          <div className={styles.searchField}>
            <Form initialValues={{ searchString }} onSubmit={v => setSearchString(v.searchString)}>
              <TextField placeholder="Search..." name="searchString" submitOnChange minimal showError={false} outline={false} />
            </Form>
          </div>
        )}
        {filtered?.length ? (
          <div className={styles.wrapper}>
            {filtered?.map?.((obj, index) => {
              const selected = value === obj?.value;
              return (
                <MenuItem
                  key={index}
                  text={labelRenderer(obj, selected)}
                  onClick={() => onSelect(obj.value)}
                  className={styles.item}
                  label={selected ? <Icon icon="small-tick" /> : null}
                  disabled={!!obj?.disabled}
                />
              );
            })}
          </div>
        ) : (
          <MenuItem text="Empty..." disabled />
        )}
      </Menu>
    );
  }, [value, searchString, internalOptions]);

  // Render current value label.
  const text = useMemo(() => {
    return value !== undefined ? textRenderer(internalOptions?.find(o => o.value === value)) || placeholder : placeholder;
  }, [value, internalOptions, textRenderer]);

  return (
    <Popover
      fill
      placement="bottom"
      popoverClassName={styles.popover}
      content={content}
      disabled={disabled || (!!remoteOptions?.loading && remoteOptions?.filter !== 'remote')}
      onClosed={() => setSearchString('')}
      captureDismiss={captureDismiss}
    >
      <Button
        fill
        className={styles.selectButton}
        text={text}
        alignText="left"
        rightIcon={!!remoteOptions?.loading ? <Spinner size={16} /> : 'caret-down'}
        disabled={disabled || (!!remoteOptions?.loading && remoteOptions?.filter !== 'remote')}
        {...extra}
      />
    </Popover>
  );
};
export default SelectField;
