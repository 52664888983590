import React from 'react';

import Box from '../../box';

import NotesPopover from './notes-popover';
import Metadata from './metadata';

import classes from './timeline-step.module.scss';
import Bank from './bank-box';
import FieldValueContainer from './field-value-container';

const TimelineFlow = ({ instanceId, template, originalTemplate, engagement, review, loadReview }) => {
  if (!template?.pages?.length) {
    return null;
  }

  const containsMetadataGroup = !!originalTemplate?.pages
    ?.map(p => p?.groups)
    ?.flat?.(1)
    ?.find?.(g => !!g?.metadata);

  const pages = [];
  const bankAccountFields = [];
  template?.pages?.forEach(page => {
    const groups = [];
    page?.groups?.forEach(group => {
      const fields = [];
      group?.fields?.forEach(field => {
        const ignoredFields = ['OT_PARAGRAPH', 'OT_LINK'];
        if (!ignoredFields.includes(field?.type)) {
          if (field?.type === 'OT_BANK_ACCOUNT') {
            bankAccountFields.push(field);
          } else {
            fields.push(field);
          }
        }
      });
      if (fields.length) {
        groups.push({ ...group, fields });
      }
    });
    if (groups.length) {
      pages.push({ ...page, groups });
    }
  });

  return (
    <React.Fragment>
      <div className={classes.separator}>
        <span>Flow</span>
      </div>
      <div className={classes.list}>
        {pages?.map(page => (
          <Box
            key={page.id}
            monochrome
            small
            expandible
            title={page.title}
            actions={<NotesPopover fieldId={page.id} readonly review={review} onComplete={loadReview} />}
          >
            {page?.groups?.map(group =>
              group.fields?.map(field => (
                <FieldValueContainer
                  instanceId={instanceId}
                  field={field}
                  type={field.type}
                  clientId={engagement?.clientId}
                  review={review}
                  onComplete={loadReview}
                  key={field.id}
                />
              )),
            )}
          </Box>
        ))}
        {bankAccountFields?.map(bankAccount => (
          <Bank field={bankAccount} review={review} loadReview={loadReview} key={bankAccount.id} />
        ))}
        {containsMetadataGroup && engagement?.metadata && <Metadata engagement={engagement} />}
      </div>
    </React.Fragment>
  );
};

export default TimelineFlow;
