import React, { useContext } from 'react';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';
import SimpleBar from 'simplebar-react';

import Dialog from '../../../../../../../../dialog';
import Form from '../../../../../../../../form';

import { WizardContext } from '../../../../../../state';
import { WizardSelectionContext } from '../../../../../../state/selection-provider';

import Rules from './rules';
import { getEligibleFields } from '../helper';

import classes from '../../custom-fields.module.scss';

const ruleSchema = Yup.object().shape({
  field: Yup.string().required(),
});

const nestedRuleSchema = Yup.object().shape({
  combinator: Yup.string().oneOf(['AND', 'OR']).required('Combinator is required'),
  rules: Yup.array()
    .of(Yup.lazy(value => (value.rules ? nestedRuleSchema : ruleSchema)))
    .required('Rules are required'),
});

const ConditionBuilderDialog = ({ dialog, onSubmit, onClose }) => {
  const { template, descriptor } = useContext(WizardContext);
  const { getSelection, getSelectionPath } = useContext(WizardSelectionContext);

  const selection = getSelection();
  const selectionPath = getSelectionPath(false);

  const eligibleData = React.useMemo(
    () =>
      getEligibleFields({
        template,
        selection,
        selectionPath,
        descriptor,
      }),
    [template, selection, selectionPath, descriptor],
  );

  return (
    <Dialog isOpen={dialog?.open} title="Visibility conditions" onClose={onClose} bodyClassName={classes.rulesDialog}>
      <Form initialValues={{ combinator: 'AND', rules: [], ...dialog.visibility }} validationSchema={nestedRuleSchema} onSubmit={onSubmit}>
        {({ values, errors, isValid, setFieldValue }) => {
          return (
            <div className={classes.rulesContent}>
              <SimpleBar className={classes.rulesWrapper}>
                <Rules eligibleData={eligibleData} index={0} prefix="" parent="" values={values} errors={errors} setFieldValue={setFieldValue} />
              </SimpleBar>
              <div className={classes.rulesButtons}>
                <Button type="submit" fill outlined text="Submit" disabled={!isValid} />
              </div>
            </div>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default ConditionBuilderDialog;
