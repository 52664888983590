import { combineReducers } from 'redux';
import IdentityReducer from './identity/reducer';
import ApplicationReducer from './application/reducer';
import ContextsReducer from './contexts/reducer';
import LookupsReducer from './lookups/reducer';

export default combineReducers({
  identity: IdentityReducer,
  application: ApplicationReducer,
  contexts: ContextsReducer,
  lookups: LookupsReducer,
});
