import { humanFileSize } from '../../../utilities/common';

export const validateFile = (extensions, mimeTypes, maxSize) => file => {
  if (!file?.name || !file?.type || file?.size === undefined || file?.size === null) {
    return { code: 'missing-file', message: 'Invalid file.' };
  }

  // Validate file size.
  if (file?.size > maxSize) {
    return { code: 'file-too-big', message: `File size is too big. Max size is ${humanFileSize(maxSize)}.` };
  }

  // Validate extensions, if provided.
  if (!!extensions?.length) {
    const normalizedName = file.name.toUpperCase();
    if (
      (typeof extensions === 'string' && !normalizedName.endsWith(extensions.toUpperCase())) ||
      (Array.isArray(extensions) && !extensions.some(ext => normalizedName.endsWith(ext.toUpperCase())))
    ) {
      const extensionsString = typeof extensions === 'string' ? extensions : Array.isArray(extensions) ? extensions?.join(', ') : 'n/a';
      return { code: 'invalid-extension', message: `Invalid file type. Must be one of: ${extensionsString}.` };
    }
  }

  // Validate mime types, if provided.
  if (!!mimeTypes?.length) {
    const normalizedType = file.type.toUpperCase();
    if (typeof mimeTypes === 'string') {
      if (mimeTypes.includes('*') && !normalizedType.startsWith(mimeTypes.split('/')?.[0]?.toUpperCase())) {
        return { code: 'invalid-mime-type', message: 'Invalid file type.' };
      } else if (!mimeTypes.includes('*') && normalizedType !== mimeTypes.toUpperCase()) {
        return { code: 'invalid-mime-type', message: 'Invalid file type.' };
      }
    }

    if (Array.isArray(mimeTypes)) {
      const valid = mimeTypes?.some(mime => {
        if (mime.includes('*') && !normalizedType.startsWith(mime.split('/')?.[0]?.toUpperCase())) {
          return false;
        } else if (!mime.includes('*') && normalizedType !== mime.toUpperCase()) {
          return false;
        }
        return true;
      });

      if (!valid) {
        return { code: 'invalid-mime-type', message: 'Invalid file type.' };
      }
    }
  }
};
