import React from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import classes from './offline.module.scss';

const Offline = () => {
  return (
    <div className={classes.offline}>
      <div className={classes.icon}>
        <Icons.WifiOff size={60} strokeWidth={0.85} />
      </div>
      <div className={classes.message}>
        Unable to connect to <span>GreenLight.ai</span> servers.
      </div>
      <Button outlined text="Try Again" onClick={() => window.location.reload()} />
    </div>
  );
};

export default Offline;
