export const FIELDS_TO_SKIP = ['t', 'id', 'page', 'orderBy'];

export const filterCount = (filters, initialFilters) => {
  const count = Object.entries(filters).reduce((acc, [key, value]) => {
    if (value && value !== initialFilters?.[key]) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return count;
};

/**
 * this function returns comma separated string with the value toggled.
 * @param {String} value - The value to toggle.
 * @param {Array} formValue - The array to toggle the value in.
 * @returns {String} The comma separated string with the value toggled.
 */
export const toggleFilterValue = (id, formValue) => {
  const values = parsedFilterValue(formValue);
  const index = values?.indexOf(id);
  if (index === -1) {
    return [...values, id].join(',');
  }
  const filtered = values?.filter(v => v !== id);
  if (filtered.length === 0) {
    return null;
  }
  return filtered.join(',');
};

export const parsedFilterValue = formValue => {
  const array = formValue?.split(',');
  return array || [];
};
