import React from 'react';

import { EditorState, AtomicBlockUtils, Modifier, convertToRaw } from 'draft-js';

import Form from '../../../../../form';
import { RichTextField } from '../../../../../fields';
import RichTextMedia from './rich-text-media';
import useDocuments from '../../../state/use-documents';

import classes from './blank-editor.module.scss';

const BlankDocumentEditor = () => {
  const { template, macros, update } = useDocuments();
  const { render, signatories } = template.metadata[0];

  return (
    <div className={classes.content}>
      <Form
        initialValues={{ html: render }}
        onSubmit={values => {
          update({ ...template.metadata[0], render: values.html });
        }}
      >
        <RichTextField
          classes={{
            wrapperClassName: classes.richTextWrapper,
            classes: classes.toolbar,
            editorClassName: classes.editor,
          }}
          name="html"
          options={macros}
          onOptionSelect={({ macro, editorState, draftToHtml }) => {
            const isSignatureImage = macro.roleId && macro.label === 'Image';
            if (isSignatureImage) {
              const alreadyExists = signatories.some(s => s.roleId === macro.roleId);
              if (!alreadyExists) {
                signatories.push({ roleId: macro.roleId, name: macro.name });
              }

              const src = `\${${macro.name} || '${macro.example}'}`;
              const contentState = editorState.getCurrentContent();
              const contentStateWithEntity = contentState.createEntity('IMAGE', 'MUTABLE', {
                src,
                alt: macro.name,
                height: '50px',
                width: 'auto',
              });
              const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
              const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
              const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
              update({ ...template.metadata[0], signatories, render: html });

              return newEditorState;
            }

            const contentState = Modifier.replaceText(
              editorState.getCurrentContent(),
              editorState.getSelection(),
              `\${${macro.name} || ''}`,
              editorState.getCurrentInlineStyle(),
            );

            const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
            const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
            update({ ...template.metadata[0], render: html });
            return newEditorState;
          }}
          blockRendererFn={block => {
            if (block.getType() === 'atomic') {
              return {
                component: RichTextMedia,
                editable: false,
              };
            }
            return null;
          }}
          outline
          showError={false}
          minimal
          fill={true}
          submitOnChange
        />
      </Form>
    </div>
  );
};

export default BlankDocumentEditor;
