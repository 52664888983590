import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { useNavigate } from 'react-router';

import { TrackingRecordStatusOptions } from '../../../../domain/tracking-record-status-type';

import WithConfirmation from '../../../../hooks/with-confirmation';

const TimeMenu = ({ engagements, updateStatus, onExport }) => {
  const navigate = useNavigate();
  const records = engagements?.reduce((a, b) => [...a, ...(b?.records?.map?.(r => r.id) || [])], []);

  return (
    <React.Fragment>
      <Menu>
        <MenuItem text="Set Status" shouldDismissPopover={false}>
          {TrackingRecordStatusOptions.map(({ label, value }) => (
            <WithConfirmation
              captureDismiss={false}
              key={value}
              messages={{ confirmButton: 'Update', title: 'Update status for all listed suppliers ?' }}
              icon={true}
            >
              <MenuItem text={label} onClick={() => updateStatus({ status: value, records })} shouldDismissPopover={false} />
            </WithConfirmation>
          ))}
        </MenuItem>
        <MenuDivider />
        <MenuItem text="Export" label=".csv" onClick={onExport} />
        <MenuItem text="Import" label={<Icon icon="plus" />} onClick={() => navigate(`/time/import`)} />
      </Menu>
    </React.Fragment>
  );
};

export default TimeMenu;
