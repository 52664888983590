import React, { useContext } from 'react';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import { Button, ButtonGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';

import Form from '../../../../../../form';
import Fields from '../../../../../../fields';
import useApi from '../../../../../../../hooks/use-api';

import { SystemContext } from '../../../../system-provider';

import { getCountries } from '../../../../../../../apis/lookups';
import { createRecord, updateRecord } from '../../../../../../../apis/configuration';

import classes from './available-countries.module.scss';

const AvailableCountries = ({ record, onClose, loading, setLoading, api, data }) => {
  const countriesApi = useApi(getCountries);
  const { selectedScope } = useContext(SystemContext);

  const submit = async values => {
    const payload = { ...values, data: { ...values.data, countries: JSON.stringify(values.data.countries) } };

    setLoading(true);
    if (payload?.id) {
      await updateRecord(values?.id, payload);
    } else {
      await createRecord(payload);
    }
    await api?.request();
    setLoading(false);
    onClose();
  };

  const options = [
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false },
  ];

  const disabled = loading || countriesApi.loading;

  return (
    <Form
      initialValues={{
        scopeId: selectedScope?.id,
        description: '',
        ...record,
        data: { countries: !!data?.countries ? JSON.parse(data?.countries) : [] },
      }}
      validationSchema={Yup.object().shape({
        scopeId: Yup.string().required('Scope is required'),
        data: Yup.object().shape({
          countries: Yup.array().of(
            Yup.object().shape({
              code: Yup.string().required('Country is required'),
            }),
          ),
        }),
      })}
      onSubmit={submit}
      enableReinitialize
    >
      {form => {
        return (
          <div className={classes.countries}>
            {!!form?.values?.data?.countries?.length && (
              <div className={classes.header}>
                <div>Country</div>
                <div>EE</div>
                <div>IC</div>
              </div>
            )}
            <FieldArray name="data.countries">
              {({ remove, push }) => (
                <SimpleBar className={classes.wrapper}>
                  <div className={classes.container}>
                    {form.values.data.countries?.map((c, i) => (
                      <div className={classes.row} key={i}>
                        <div className={classes.country}>{countriesApi?.response?.find?.(co => co.id === c.code)?.name}</div>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.countries.${i}.ee`}
                            placeholder="Employees"
                            options={options}
                            extra={{ intent: !!form.values.data.countries?.[i].ee ? 'success' : 'danger', outlined: true }}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.field}>
                          <Fields.Select
                            name={`data.countries.${i}.ic`}
                            placeholder="Independent Contractors"
                            options={options}
                            extra={{ intent: !!form.values.data.countries?.[i].ic ? 'success' : 'danger', outlined: true }}
                            outline={false}
                            minimal={true}
                          />
                        </div>
                        <div className={classes.removeButton}>
                          <Button icon="small-minus" outlined type="button" onClick={() => remove(i)} />
                        </div>
                      </div>
                    ))}
                    <ButtonGroup>
                      <Popover
                        content={
                          <Menu>
                            <SimpleBar style={{ maxHeight: '50vh' }}>
                              {countriesApi.response?.map(country => {
                                const idx = form.values.data.countries?.findIndex(obj => obj.code === country.id);
                                return (
                                  <MenuItem
                                    key={country.id}
                                    disabled={idx !== -1}
                                    onClick={() => {
                                      if (idx === -1) {
                                        push({ code: country.id, ic: false, ee: false });
                                      }
                                    }}
                                    text={`${country.name}`}
                                  />
                                );
                              })}
                            </SimpleBar>
                          </Menu>
                        }
                      >
                        <Button outlined fill icon={<Icons.Plus size={16} strokeWidth={1.5} />} text={'Add New'} disabled={!form.isValid} />
                      </Popover>
                    </ButtonGroup>
                  </div>
                </SimpleBar>
              )}
            </FieldArray>
            <Button type="submit" outlined fill text="Submit" disabled={disabled} loading={loading} />
          </div>
        );
      }}
    </Form>
  );
};

export default AvailableCountries;
