import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Box from '../../../../components/box';

import { displayAllocation, displayEndDate, displayRate, displayStartDate } from '../../../../utilities/engagement';
import { getClientDisplayName } from '../../../../utilities/client';

const Engagement = ({ engagement }) => {
  const [expanded, setExpanded] = useState(false);

  const client = getClientDisplayName(engagement.clientName, engagement.parentClientName);

  const rate = displayRate(engagement.compensation?.amount, engagement.compensation?.currency?.symbol, engagement.compensation?.type);
  const allocation = displayAllocation(engagement.compensation?.allocation, engagement.compensation?.type);
  const payRate = !!allocation ? `${rate}, ${allocation}` : rate;

  const start = displayStartDate(engagement.startDate);
  const end = displayEndDate(engagement.endDate);

  return (
    <Box
      title={engagement?.title}
      outlined
      actions={
        <Button
          small
          outlined
          icon={expanded ? <Icons.Minimize2 size={16} strokeWidth={1.5} /> : <Icons.Maximize2 size={16} strokeWidth={1.5} />}
          title={expanded ? 'Hide details' : 'Show details'}
          onClick={() => setExpanded(!expanded)}
        />
      }
    >
      <Box.Item label="Client">{client}</Box.Item>
      <Box.Item label="Start/End Date">
        {start} - {end}
      </Box.Item>
      {expanded && <Box.Item label="Compensation">{payRate}</Box.Item>}
      {expanded && <Box.Item label="Manager">{engagement.managerName || '-'}</Box.Item>}
      {expanded && (
        <Box.ItemContainer>
          <Box.ItemLabel>Engagement description</Box.ItemLabel>
          <Box.ItemValue>{engagement.description || '-'}</Box.ItemValue>
        </Box.ItemContainer>
      )}
    </Box>
  );
};

export default Engagement;
