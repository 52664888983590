import { useEffect, createRef, useRef } from 'react';
import { Form as FormikForm } from 'formik';
import cn from 'classnames';

import styles from './form.module.scss';

const FormBase = ({ children, className, errors, values, submitCount, ...rest }) => {
  const ref = createRef();
  const prevCount = useRef(submitCount);

  useEffect(() => {
    const fields = Object.keys(errors);

    if (fields.length && prevCount.current !== submitCount) {
      prevCount.current = submitCount;
      const element = ref.current?.querySelector?.(`[data-id="${fields[0]}"]`);
      element?.scrollIntoView();
      element?.querySelector('input')?.focus();
    }
  }, [submitCount, errors]);

  return (
    <FormikForm className={cn(styles.form, className)} {...rest} ref={ref} autoComplete="off" autoCorrect="off">
      {children}
    </FormikForm>
  );
};

export default FormBase;
