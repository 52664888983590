import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { FocusStyleManager } from '@blueprintjs/core';

import { store } from './configuration/store';
import { setupStyles, subscribe } from './styles';

import Routes from './routes';

import './styles/main.css';
import './styles/application.scss';

global.Buffer = Buffer;

FocusStyleManager.onlyShowFocusOnTabs();
setupStyles();
subscribe();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
);
