import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

const EngagementMenu = ({ onInvite, onExport }) => {
  return (
    <React.Fragment>
      <Menu>
        <MenuItem icon="send-message" text="Send invite" onClick={onInvite} />
        <MenuDivider />
        <MenuItem text="Export" label=".csv" onClick={onExport} />
      </Menu>
    </React.Fragment>
  );
};

export default EngagementMenu;
