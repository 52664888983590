import React from 'react';
import { Button } from '@blueprintjs/core';

import Form from '../../../../form';
import Dialog from '../../../../dialog';
import Fields from '../../../../fields';

const SetupColorsDialog = ({ isOpen, colors, onClose, handleSubmit }) => {
  if (!isOpen) {
    return null;
  }

  const submit = async values => {
    await handleSubmit(values);
    onClose();
  };

  return (
    <Dialog title="Setup colors" isOpen={isOpen} onClose={onClose}>
      <Form initialValues={colors} onSubmit={submit}>
        {form => (
          <React.Fragment>
            <Fields.Color label="Primary color" name="primaryColor" />
            <Button type="submit" fill outlined text="Submit" loading={form.isSubmitting} />
          </React.Fragment>
        )}
      </Form>
    </Dialog>
  );
};

export default SetupColorsDialog;
