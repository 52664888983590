import { Roles } from '../models/enumerations/roles';
import { capitalize } from './common';

export const isPlatformAdministrator = (memberships = []) => memberships.find(m => Object.values(Roles.Platform).includes(m.roleId));

export const roleIdToRoleName = roleId => {
  if (!roleId) return 'N/A';
  return roleId
    .split('-')
    .slice(1)
    .map(part => capitalize(part))
    .join(' ');
};

export const roleIdToFullRoleName = roleId => {
  if (!roleId) return 'N/A';
  return roleId
    .split('-')
    .map(part => capitalize(part))
    .join(' ');
};
