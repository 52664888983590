import React, { useState } from 'react';

import Table from '../../../../../../../components/table';
import Avatar from '../../../../../../../components/avatar';
import useTable from '../../../../../../../hooks/use-table';
import { getDepartments, removeDepartment } from '../../../../../../../apis/client/departments';

import DepartmentsMenu from './menu';
import NewDepartment from './new';
import classes from '../../details.module.scss';

const Departments = ({ clientId }) => {
  const [dialog, setDialog] = useState({ open: false });
  const { items, loading, filters, initialFilters, reload, setFilters, resetFilters } = useTable(() => getDepartments({ clientId }), {
    pageSize: 10,
    controlled: true,
  });

  const onRemoveDepartment = async department => {
    await removeDepartment({ clientId, department });
    reload();
  };

  return (
    <div className={classes.tab}>
      <Table.Container data={items} loading={loading} dark>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<DepartmentsMenu setDialog={() => setDialog({ open: true })} />}
        />
        <Table.Columns.Default name="Name" showOnMobile width="30px" render={d => <Avatar size={{ box: 30, text: 12 }} abbreviate name={d.name} />} />
        <Table.Columns.TwoLines showOnMobile lines={[d => d.name, d => d.description]} />
        <Table.Columns.Default name="Invoiced" width="100px" render={d => (d.hasOwnInvoice ? 'Yes' : 'No')} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            { name: 'Edit', onClick: d => setDialog({ open: true, department: d }) },
            { divider: true },
            { name: 'Remove', onClick: d => onRemoveDepartment(d), confirmation: { messages: { confirmButton: 'Remove' } } },
          ]}
        />
      </Table.Container>
      {dialog.open && (
        <NewDepartment
          open={dialog.open}
          department={dialog.department}
          clientId={clientId}
          onClose={() => setDialog({ open: false })}
          onSave={() => {
            reload();
            setDialog({ open: false });
          }}
        />
      )}
    </div>
  );
};
export default Departments;
