import Clients from '../configuration/client';

export const getDocuments = async ({ type, id }) => {
  const response = await Clients.Api.get(`ui/documents/${type}/${id}`);
  return response?.data;
};

export const getDocument = async id => {
  const response = await Clients.Api.get(`ui/documents/${id}`);
  return response?.data;
};

export const updateDocument = async document => {
  const response = await Clients.Api.put(`ui/documents`, document);
  return response?.data;
};

export const downloadDocument = async document => {
  // TODO: implement HOC with loading state instead of just saving once downloaded.
  const response = await Clients.Api.get(`ui/documents/download/${document?.id}`, { responseType: 'blob' });
  const url = window.URL.createObjectURL(response?.data);
  const a = window.document.createElement('a');
  a.style.display = 'none';
  window.document.body.appendChild(a);
  a.href = url;
  a.download = document.name;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const uploadDocument = async ({ file, type, id, name = null, description = null, expirationDate }, onUploadProgress = () => null) => {
  const data = new FormData();
  data.append('data', JSON.stringify({ ownerType: type, ownerId: id, name: name || file?.name, description, expiration_date: expirationDate }));
  data.append('file', file);
  const response = await Clients.Api.post(`ui/documents`, data, { onUploadProgress });
  return response?.data;
};

export const removeDocument = async ({ id }) => {
  const response = await Clients.Api.delete(`ui/documents/${id}`);
  return response?.data;
};

export const uploadResourceDocument = async ({ file }, onUploadProgress = () => null) => {
  const data = new FormData();
  data.append('file', file);
  const response = await Clients.Api.post(`ui/documents/resource`, data, { onUploadProgress });
  return response?.data;
};

export const getResourceDocuments = async () => {
  const response = await Clients.Api.get(`ui/documents/resources`);
  return response?.data;
};
