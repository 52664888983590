import React from 'react';
import cn from 'classnames';
import { Button, Dialog as BlueprintDialog } from '@blueprintjs/core';

import classes from './dialog.module.scss';

const Dialog = ({ title, footer, isOpen, onClose, bodyClassName = '', children, enforceFocus, large, small, ...rest }) => {
  return (
    <BlueprintDialog
      isOpen={isOpen}
      onClose={onClose}
      enforceFocus={enforceFocus}
      {...rest}
      className={cn(classes.dialog, large && classes.dialogLarge, small && classes.dialogSmall)}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
          <Button minimal icon="cross" onClick={onClose} />
        </div>
        <div className={cn(classes.body, bodyClassName)}>{children}</div>
        {!!footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </BlueprintDialog>
  );
};

export default Dialog;
