import React from 'react';
import moment from 'moment';
import { Button } from '@blueprintjs/core';

import Form from '../../../../form';
import Fields from '../../../../fields';

import { isNextDayDisabled, isPrevWeekDisabled } from '../../utilities';
import { DateFormat } from '../../../../../models/enumerations/date-format';

import classes from '../../quick.module.scss';

const DaySwitcher = ({ date, onSubmit }) => {
  const maxDate = moment().endOf('isoWeek').toDate();
  return (
    <Form initialValues={{ date }} enableReinitialize onSubmit={onSubmit}>
      {form => {
        const date = moment(form.values.date);
        const prevDateDisabled = isPrevWeekDisabled(date);
        const nextDateDisabled = isNextDayDisabled(date);

        return (
          <div className={classes.datePicker}>
            <Button
              outlined
              small
              disabled={prevDateDisabled}
              icon="chevron-left"
              onClick={() => {
                form.setFieldValue('date', date.add(-1, 'day').format('YYYY-MM-DD'));
                void form.submitForm();
              }}
            />
            <Fields.Date
              classes={{ wrapper: classes.dateField }}
              buttonProps={{ alignText: 'center', outlined: true, format: 'dddd, MMMM Do' }}
              name="date"
              format={DateFormat.DEFAULT}
              maxDate={maxDate}
              submitOnChange
              outline={false}
              minimal={true}
              showError={false}
            />
            <Button
              outlined
              small
              disabled={nextDateDisabled}
              icon="chevron-right"
              onClick={() => {
                form.setFieldValue('date', date.add(1, 'day').format('YYYY-MM-DD'));
                void form.submitForm();
              }}
            />
          </div>
        );
      }}
    </Form>
  );
};

export default DaySwitcher;
