import React, { useEffect, useMemo, useState } from 'react';

import { store } from '../../../../configuration/store';
import { getTemplate, getTemplateStream, getTrees } from '../../../../apis/templates';

import { TemplateTypes } from '../enumerations/template-types';
import { getDocumentTemplateSpecificMacros } from '../helpers/get-document-template-specific-macros';
import { CONTEXTS_SET_TREES } from '../../../../state/types';
import { uuid } from '../../../../utilities/common';
import MacroTypes from '../enumerations/macro-types';
import { backwardsCompatibleMacro, getNewMacro } from '../helpers/macro-item-factory';

export const DocumentsContext = React.createContext({});

export const DocumentsProvider = ({ children, id }) => {
  const [stream, setStream] = useState({ html: undefined, fromScratch: undefined }); // html is in the object because it its used in the dependency array of the useMemo. Its faster to compare objects than strings.
  const [state, setState] = useState({
    loading: true,
    preview: false,
    template: undefined,
    macros: undefined,
    wizard: undefined,
    selection: undefined,
  });

  useEffect(() => {
    async function loadDescriptors() {
      let descriptors = store.getState().contexts.trees;
      if (descriptors) {
        return descriptors;
      }
      descriptors = await getTrees();
      store.dispatch({ type: CONTEXTS_SET_TREES, data: descriptors });
      return descriptors;
    }

    async function loadStream() {
      const html = await getTemplateStream(id);
      const fromScratch = html.includes('id="gl-blank-document"');
      return { html, fromScratch };
    }

    async function loadTemplate() {
      const stream = await loadStream(id);
      const template = await getTemplate(id);

      // for the first time template metadata is not created so set it to default
      if (!template.metadata?.length) {
        if (stream.fromScratch) {
          template.metadata = [
            { id: uuid(), type: MacroTypes.FROM_SCRATCH, name: MacroTypes.FROM_SCRATCH, displayName: 'Blank document text', example: '', render: '' },
          ];
        } else {
          template.metadata = [];
        }
      }

      let wizard;
      if (template.subType === TemplateTypes.Wizard && template.objectReferences) {
        wizard = await getTemplateStream(template.objectReferences);
      }

      const descriptors = await loadDescriptors();
      const macros = getDocumentTemplateSpecificMacros(descriptors, template, wizard, {
        formatDate: stream.fromScratch,
      });

      template.metadata = template.metadata?.map(obj => backwardsCompatibleMacro(obj, stream.fromScratch)) || [];

      setStream(stream);
      setState(prevState => ({ ...prevState, loading: false, template, macros, wizard }));
    }

    void loadTemplate();
  }, []);

  const publish = status => {
    setState(prevState => ({ ...prevState, template: { ...prevState.template, status: 1 } }));
  };

  const renameDocument = name => {
    setState(prevState => ({ ...prevState, template: { ...prevState.template, name } }));
  };

  const addNew = ({ left, top }) => {
    setState(prevState => {
      const macro = getNewMacro({ left, top });
      return { ...prevState, template: { ...prevState.template, metadata: [...prevState.template.metadata, macro] } };
    });
  };

  const duplicate = ({ id }) => {
    setState(prevState => {
      const idx = prevState.template.metadata.findIndex(m => m.id === id);
      const macro = prevState.template.metadata[idx];
      const duplicated = {
        ...macro,
        id: uuid(),
        displayName: `${macro.displayName || 'New macro'} copy`,
        config: {
          ...macro.config,
          style: {
            ...macro.config.style,
            left: macro.config.style.left + macro.config.style.width + 10,
          },
        },
      };
      // add the duplicated macro just after the original macro in the array of macros
      const metadata = [...prevState.template.metadata];
      metadata.splice(idx + 1, 0, duplicated);
      return {
        ...prevState,
        template: { ...prevState.template, metadata },
        selection: {
          macro: duplicated,
          action: 'edit',
        },
      };
    });
  };

  const update = macro => {
    setState(prevState => {
      const metadata = prevState.template.metadata.map(m => (m.id === macro.id ? macro : m));
      let selection;
      if (prevState.selection?.macro?.id === macro.id) {
        selection = { ...prevState.selection, macro };
      } else if (prevState.selection) {
        selection = prevState.selection;
      }
      return { ...prevState, template: { ...prevState.template, metadata }, selection };
    });
  };

  const resize = ({ id, width, height }) => {
    setState(prevState => {
      const metadata = prevState.template.metadata.map(m => {
        if (m.id === id) {
          return { ...m, config: { ...m.config, style: { ...m.config.style, width, height } } };
        }
        return m;
      });
      let selection;
      if (prevState.selection?.macro?.id === id) {
        selection = { ...prevState.selection, macro: prevState.template.metadata.find(m => m.id === id) };
      } else if (prevState.selection) {
        selection = prevState.selection;
      }
      return { ...prevState, template: { ...prevState.template, metadata }, selection };
    });
  };

  const move = ({ id, left, top }) => {
    setState(prevState => {
      const metadata = prevState.template.metadata.map(m => {
        if (m.id === id) {
          return { ...m, config: { ...m.config, style: { ...m.config.style, left, top } } };
        }
        return m;
      });
      let selection;
      if (prevState.selection?.macro?.id === id) {
        selection = { ...prevState.selection, macro: prevState.template.metadata.find(m => m.id === id) };
      } else if (prevState.selection) {
        selection = prevState.selection;
      }
      return { ...prevState, template: { ...prevState.template, metadata }, selection };
    });
  };

  const setSelection = ({ macro, action }) => {
    setState(prevState => ({ ...prevState, selection: { macro, action } }));
  };

  const removeSelection = () => {
    if (state.selection) {
      setState(prevState => ({ ...prevState, selection: undefined }));
    }
  };

  const remove = macro => {
    setState(prevState => {
      const metadata = prevState.template.metadata.filter(m => m.id !== macro.id);
      const selection = macro.id === prevState.selection?.macro?.id ? undefined : prevState.selection;
      return { ...prevState, template: { ...prevState.template, metadata }, selection };
    });
  };

  const togglePreview = () => {
    setState(prevState => {
      return { ...prevState, preview: !prevState.preview };
    });
  };

  const refreshStream = async () => {
    const html = await getTemplateStream(id);
    const fromScratch = html.includes('id="gl-blank-document"');
    setStream({ html, fromScratch });
  };

  const value = useMemo(
    () => ({
      ...state,
      stream,
      renameDocument,
      publish,
      addNew,
      setSelection,
      removeSelection,
      remove,
      update,
      resize,
      move,
      duplicate,
      togglePreview,
      refreshStream,
    }),
    [state, stream],
  );

  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
};
