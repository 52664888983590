export const CompensationType = {
  Hourly: 'hourly',
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  ByDeliverable: 'by-deliverable',
};

export const CompensationTypeOptions = [
  {
    label: 'Hourly',
    value: CompensationType.Hourly,
  },
  {
    label: 'Daily',
    value: CompensationType.Daily,
  },
  {
    label: 'Weekly',
    value: CompensationType.Weekly,
  },
  {
    label: 'Monthly',
    value: CompensationType.Monthly,
  },
  {
    label: 'Per Project',
    value: CompensationType.ByDeliverable,
  },
];

export const compensationTypeToLabel = type => CompensationTypeOptions.find(o => o.value === type)?.label;

export const compensationTypeToShortLabel = type => {
  switch (type) {
    case 'hourly':
      return '/hour';
    case 'daily':
      return '/day';
    case 'weekly':
      return '/week';
    case 'monthly':
      return '/month';
    case 'by-deliverable':
      return '/project';
    default:
      return '';
  }
};
