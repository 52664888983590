import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Details from './containers/details';
import List from './containers/list';
import Invite from './containers/invite';

const Engagements = () => {
  return (
    <Routes>
      <Route path="/" Component={List} />
      <Route path="/:id" Component={Details} />
      <Route path="/invite" Component={Invite} />
    </Routes>
  );
};

export default Engagements;
