import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import Page from '../../../components/page';
import Table from '../../../components/table';
import Avatar from '../../../components/avatar';
import { capitalize } from '../../../utilities/common';
import { downloadStringAsFile } from '../../../utilities/files';

import useTable from '../../../hooks/use-table';
import { getInvoices, removeInvoice, getCsvData, sendInvoice } from '../../../apis/platform/invoice';
import toaster from '../../../components/toaster';

import GenerateInvoiceDialog from './generate';
import InvoiceDetails from './details';
import InvoiceMenu from './menu';
import InvoiceFilters from './filters';
import { DateFormat } from '../../../models/enumerations/date-format';

const Invoices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialog, setDialog] = useState({ open: false, invoiceId: undefined, unassignedInvoiceId: undefined });
  const [generateDialog, setGenerateDialog] = useState({ open: false });

  const defaultFilters = {
    unassigned: false,
    currencyId: undefined,
    classification: undefined,
    periodType: 'weekly',
    range: [moment().startOf('isoWeek').add(-6, 'months').format(DateFormat.DEFAULT), moment().endOf('isoWeek').format(DateFormat.DEFAULT)],
  };
  const { items, loading, filters, initialFilters, pageCount, page, reload, setFilters, resetFilters, goToPage } = useTable(
    getInvoices,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const onRemoveInvoice = async invoiceId => {
    await removeInvoice(invoiceId);
    reload();
  };

  const renderPeriod = d =>
    d?.periodType === 'weekly'
      ? `Week ${moment(d?.period).format('Do MMMM')} - ${moment(d?.period).add(6, 'days').format('Do MMMM YYYY')}`
      : `${moment(d?.period).format('MMMM YYYY')}`;

  const onExport = data => {
    void toaster.export({
      progress: {
        title: 'Exporting invoice details',
      },
      promise: () => getCsvData(data?.invoiceId),
      onSuccess: csv => downloadStringAsFile(csv, `Invoice_${data?.invoiceId}_${renderPeriod(data).split(' ').join('_')}_${data?.currency?.id}`, 'csv'),
    });
  };

  const id = searchParams.get('id');
  const isUnassigned = searchParams.get('unassigned');

  useEffect(() => {
    if (id) {
      setDialog({ open: true, invoiceId: isUnassigned ? undefined : id, unassignedInvoiceId: isUnassigned ? id : undefined });
    } else {
      setDialog({ open: false });
    }
  }, [id]);

  function matchInvoice(invoice) {
    if (isUnassigned && dialog?.unassignedInvoiceId) {
      return invoice?.unassignedInvoiceId === dialog?.unassignedInvoiceId;
    }

    if (dialog?.invoiceId) {
      return invoice?.invoiceId === dialog?.invoiceId;
    }

    return false; // should not match
  }

  return (
    <Page title="Invoices" path={[{ name: 'Invoices', to: '/invoices' }]}>
      <Table.Container data={items} loading={loading} handleScroll={true}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          content={<InvoiceFilters />}
          menu={<InvoiceMenu onGenerateInvoice={() => setGenerateDialog({ open: true })} />}
        />
        <Table.Columns.Default
          name="Client"
          showOnMobile
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} abbreviate name={d?.client?.name} />}
        />
        <Table.Columns.Default showOnMobile render={d => d?.client?.name} />
        <Table.Columns.Default name="Period" width="3fr" showOnMobile render={d => renderPeriod(d)} />
        <Table.Columns.Default name="Type" render={d => capitalize(d?.periodType)} />
        <Table.Columns.Default name="Date" showOnMobile render={d => moment(d?.date).format('MM/DD/YYYY')} />
        <Table.Columns.TwoLines
          name="Total"
          lines={[d => `${d?.currency?.symbol}${Number.parseFloat(`${d?.total || 0}`).toFixed(2)}`, d => d?.currency?.name]}
        />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            {
              name: 'View',
              onClick: d => {
                searchParams.set('id', d?.invoiceId || d?.unassignedInvoiceId);
                setSearchParams(searchParams);
              },
            },
            {
              name: 'Send',
              onClick: d => sendInvoice({ invoiceId: d?.invoiceId }),
              disabled: d => !!d?.unassignedInvoiceId, // disable for unassigned invoices
              confirmation: { messages: { confirmButton: 'Send', title: 'Send invoice to invoicing provider ?' } },
            },
            { divider: true },
            {
              name: 'Export',
              onClick: d => onExport(d),
              disabled: d => !!d?.unassignedInvoiceId, // disable for unassigned invoices
            },
            { divider: true },
            {
              name: 'Remove',
              onClick: d => onRemoveInvoice(d?.invoiceId),
              disabled: d => !!d?.unassignedInvoiceId, // disable for unassigned invoices
              confirmation: { messages: { confirmButton: 'Remove' } },
            },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />

      <InvoiceDetails
        open={!!dialog?.open}
        invoice={items?.find?.(matchInvoice)}
        onExport={() => onExport(items?.find?.(matchInvoice))}
        onClose={() => {
          searchParams.delete('id');
          setSearchParams(searchParams);
        }}
        reloadList={() => reload()}
      />
      <GenerateInvoiceDialog {...generateDialog} onClose={() => setGenerateDialog({ open: false })} />
    </Page>
  );
};

export default Invoices;
