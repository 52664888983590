import { getScrollableParent } from '../../../utilities/common';
import { SignatoriesRolesMapper } from '../../../models/enumerations/roles';

export const renderDoc = (response, contentRef, ratio) => {
  if (!response) {
    return;
  }

  // cleanup existing content and styles
  contentRef.current.replaceChildren();
  contentRef.current.style = null;

  const parser = new DOMParser();
  const dom = parser.parseFromString(response, 'text/html');

  const fromScratch = dom.body.id === 'gl-blank-document';

  if (fromScratch) {
    contentRef.current.innerHTML = response;
    contentRef.current?.style?.setProperty('width', `100%`);
    contentRef.current?.style?.setProperty('height', `100%`);
    contentRef.current?.style?.setProperty('padding', `60px`);
    contentRef.current?.style?.setProperty('box-sizing', `content-box`);
  } else {
    const pages = dom.getElementsByTagName('svg');
    const elements = dom.getElementsByTagName('div');
    let height = 0;

    // cleanup existing content
    contentRef.current.innerHTML = '';

    // add new pages
    while (pages.length) {
      const page = pages.item(0);
      height += page.height.baseVal.value;
      contentRef.current.appendChild(page);
    }

    while (elements.length) {
      const element = elements.item(0);
      contentRef.current.appendChild(element);
    }

    contentRef.current?.style?.setProperty('height', `${height * ratio}px`);
    contentRef.current?.style?.setProperty('width', `${816 * ratio}px`);
    contentRef.current?.style?.setProperty('transform', `scale(${ratio})`);
    contentRef.current?.style?.setProperty('transform-origin', `0 0`);
  }
};

// handle button position.
const handleScroll = ({ buttonsRef, scrollableElement, signatureRef, contextData }) => {
  if (!buttonsRef?.current || !scrollableElement) {
    return;
  }
  const child = scrollableElement?.firstChild?.getBoundingClientRect().top;
  const currentTop = signatureRef?.current?.getBoundingClientRect?.()?.top;
  const delta = currentTop - child;
  const parentHeight = scrollableElement.offsetHeight;
  const scroll = scrollableElement.scrollTop;

  const signatureHeight = signatureRef?.current?.offsetHeight;
  const max = signatureHeight - 70;

  let result = scroll + parentHeight - delta - 75;
  result = result < 0 ? 0 : result > max ? max : result;
  result = !!contextData.signatureId ? max : result;
  buttonsRef.current.style.top = `${result}px`;
};

export const handleButtonPosition = ({ buttonsRef, signatureRef, contextData }) => {
  const scrollableElement = getScrollableParent(buttonsRef.current);

  const onScroll = scrollableElement =>
    handleScroll({
      buttonsRef,
      scrollableElement,
      signatureRef,
      contextData,
    });

  scrollableElement?.addEventListener('scroll', e => onScroll(e.target), { passive: true });
  onScroll(scrollableElement);
};

export function getRolesRequiredToSign(metadata, membership) {
  let rolesRequiredToSign = [];
  if (metadata?.length === 1 && metadata[0].signatories) {
    // if first metadata object has "signatories" property, it is a text document created from scratch
    rolesRequiredToSign = metadata[0].signatories.map(s => s.roleId);
  } else {
    // PDF document for sure
    rolesRequiredToSign = metadata?.filter(m => m.type === 'signature').map(s => s.roleId);
  }

  if (!rolesRequiredToSign?.length) {
    // todo this is backward compatibility for older documents and should be removed in the future releases
    rolesRequiredToSign = [membership.roleId];
  }

  return rolesRequiredToSign;
}

export function eligibleToSign({ eligibleSignatories, roleId, user, organization }) {
  const role = SignatoriesRolesMapper[roleId];
  const rules = eligibleSignatories?.[role.key];
  const checkUser = rules?.users.includes(user.id);
  const checkRole = rules?.roles.includes(roleId);
  const checkOrganization = rules?.organizations.includes(organization.id);
  return checkUser && checkRole && checkOrganization;
}
