import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';

import { loadTrees } from '../../../../../../../state/contexts';
import { createRecord } from '../../../../../../../apis/configuration';
import { createTemplate } from '../../../../../../../apis/templates';
import { SystemContext } from '../../../../system-provider';
import templateFactory from '../../../../../email/helpers/template-factory';

import Spinner from '../../../../../../spinner';

import classes from './email-message.module.scss';

const EmailMessage = ({ record, onClose, setLoading, api, data, trees, loadTrees, contextTypeName }) => {
  const navigate = useNavigate();
  const { selectedScope } = useContext(SystemContext);
  const descriptor = trees?.find?.(t => t.contextTypeName === contextTypeName);

  useEffect(() => {
    if (!trees) {
      loadTrees();
    }
  }, []);

  useEffect(() => {
    if (record?.id && data?.templateId) {
      navigate(`/settings/emails/${data?.templateId}/${record?.id}`);
    }
  }, [data?.templateId]);

  useEffect(() => {
    if (!record?.id && !data?.templateId) {
      if (!!trees) {
        submit();
      }
    }
  }, [data?.templateId, trees]);

  const submit = async () => {
    setLoading(true);
    const template = {
      id: data?.templateId,
      type: 'Email',
      refName: descriptor?.refName,
      contextTypeName: descriptor?.contextTypeName,
      contentType: 'text/html',
      name: descriptor?.name,
      description: descriptor?.description,
      metadata: templateFactory.createMetadata({ subject: descriptor?.name }),
    };

    const response = await createTemplate(template);

    const templateId = response.id;
    const payload = {
      scopeId: selectedScope?.id,
      ...record,
      description: '(Draft)',
      data: { ...(data || {}), documentTemplateType: contextTypeName, documentName: descriptor?.name, templateId },
    };

    const newRecord = await createRecord(payload);
    await api?.request();
    setLoading(false);
    onClose();
    if (templateId) {
      navigate(`/settings/emails/${templateId}/${newRecord?.data?.id}`);
    }
  };

  return (
    <div className={classes.email}>
      <Spinner global={false} blend />
    </div>
  );
};

const mapStateToProps = state => ({
  trees: state.contexts.trees,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadTrees,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmailMessage);
