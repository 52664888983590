import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '../../../components/box';
import Page from '../../../components/page';
import Timekeeping from '../../../components/time';
import Engagement from './components/engagement';
import EngagementSpinner from './components/engagement/loading';
import NoticeBoard from './components/notice-board';

import { getTimeBasedGreeting } from '../../../utilities/common';

import useApi from '../../../hooks/use-api';

import { getEngagements } from '../../../apis/supplier/engagements';
import { EngagementStatusType } from '../../../domain/engagement-status-type';

import classes from './dashboard.module.scss';

const Dashboard = ({ user }) => {
  const getEngagementsApi = useApi(getEngagements);

  const activeEngagements = getEngagementsApi?.loading ? [] : getEngagementsApi?.response?.filter?.(e => e?.status === EngagementStatusType.Active);
  const upcomingEngagements = getEngagementsApi?.loading ? [] : getEngagementsApi?.response?.filter?.(e => e?.status === EngagementStatusType.Pending);

  return (
    <Page title={`${getTimeBasedGreeting()}, ${user?.personalDetails?.firstName}.`}>
      <div className={classes.dashboard}>
        {!getEngagementsApi?.loading && !!upcomingEngagements?.length && (
          <Box title="Upcoming engagements" contentClassName={classes.engagements}>
            {upcomingEngagements.map((engagement, i) => (
              <Engagement key={i} engagement={engagement} />
            ))}
          </Box>
        )}
        {!!activeEngagements?.length && (
          <Box contentClassName={classes.time}>
            <Timekeeping.Quick />
          </Box>
        )}
        {getEngagementsApi?.loading ? (
          <Box title="Engagements" contentClassName={classes.engagements}>
            <EngagementSpinner />
          </Box>
        ) : (
          <React.Fragment>
            {!!activeEngagements?.length && (
              <Box title="Active engagements" contentClassName={classes.engagements}>
                {activeEngagements.map((engagement, i) => (
                  <Engagement key={i} engagement={engagement} api={getEngagementsApi} />
                ))}
              </Box>
            )}
            {!activeEngagements?.length && !upcomingEngagements?.length && (
              <Box contentClassName={classes.engagements}>
                <div className={classes.pad15}>You have no active engagements at this time.</div>
              </Box>
            )}
          </React.Fragment>
        )}
        <NoticeBoard />
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
