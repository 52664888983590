import React from 'react';

import useApi from '../../../../../hooks/use-api';
import { getScopes } from '../../../../../apis/configuration';

import BackgroundChecksPolicy from '../../../../../components/organizational/client/policies/background-checks';
import EngagementMetadataPolicy from '../../../../../components/organizational/client/policies/engagement-metadata';
import WorkerClassificationPolicy from '../../../../../components/organizational/client/policies/worker-classification';
import IndependentContractorInsurancePolicy from '../../../../../components/organizational/client/policies/contractor-insurance';

import classes from '../../company-info.module.scss';

const Policies = ({ api }) => {
  const client = api.response;
  const getScopesApi = useApi(() => getScopes({ organizationId: client?.id, includeInherited: true }), { skip: !client?.id });
  const globalScope = getScopesApi.response?.find(scope => !scope.parentId);
  return (
    <div className={classes.tab}>
      <BackgroundChecksPolicy client={client} />
      <EngagementMetadataPolicy client={client} globalScope={globalScope} />
      <WorkerClassificationPolicy client={client} globalScope={globalScope} />
      <IndependentContractorInsurancePolicy client={client} globalScope={globalScope} />
    </div>
  );
};

export default Policies;
