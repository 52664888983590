import React, { useState } from 'react';
import moment from 'moment';

import Avatar from '../../../../components/avatar';
import Table from '../../../../components/table';

import { abbreviateClassification } from '../../../../domain/classification-type';

import { capitalize, uuid } from '../../../../utilities/common';
import { downloadStringAsFile } from '../../../../utilities/files';

import useTable from '../../../../hooks/use-table';
import { exportTimeRecords, getTimeRecords, reviewRecords } from '../../../../apis/platform/time';

import TimeDetails from './details';
import TimeFilters from './filters';
import TimeMenu from './menu';
import { TrackingRecordStatusTypes } from '../../../../domain/tracking-record-status-type';
import { displayPeriod } from '../../../../utilities/time';
import toaster from '../../../toaster';
import { DateFormat } from '../../../../models/enumerations/date-format';

const PlatformTime = ({ clientId, supplierId, engagementId, frequency = undefined, range = undefined, dark = false, handleScroll = true }) => {
  const [details, setDetails] = useState({ open: false, id: undefined, period: undefined, engagement: undefined });
  const defaultFilters = {
    query: '',
    clientId,
    supplierId,
    engagementId,
    classification: undefined,
    status: 0,
    frequency: frequency || 'weekly',
    currencies: undefined,
    countries: undefined,
    range: range || [moment().startOf('isoWeek').format(DateFormat.DEFAULT), moment().endOf('isoWeek').format(DateFormat.DEFAULT)],
  };
  const { items, loading, filters, initialFilters, pageCount, page, goToPage, reload, setFilters, resetFilters } = useTable(
    getTimeRecords,
    { pageSize: 10, controlled: true },
    defaultFilters,
  );

  const updateStatus = async updates => {
    await reviewRecords(updates);
    reload();
  };

  const onExport = () => {
    void toaster.export({
      progress: {
        title: 'Exporting time records',
      },
      promise: () => exportTimeRecords(filters),
      onSuccess: csv => downloadStringAsFile(csv, `TimeRecords-${uuid()}`, 'csv'),
    });
  };

  const getTotalHours = worker => {
    const total = worker?.records?.filter?.(r => r?.type === 0)?.reduce?.((a, b) => a + moment.duration(b?.duration).asHours(), 0) || 0;
    return total.toString().length > 5 ? total.toFixed(2) : total;
  };
  const getTotalAmount = worker => worker?.records?.filter?.(r => r?.type === 1 || r?.type === 2)?.reduce?.((a, b) => a + b?.amount, 0) || 0;

  return (
    <React.Fragment>
      <Table.Container data={items} loading={loading} handleScroll={handleScroll} dark={dark}>
        <Table.Filters
          setFilters={setFilters}
          resetFilters={resetFilters}
          initialFilters={initialFilters}
          filters={filters}
          menu={<TimeMenu updateStatus={updateStatus} onExport={onExport} engagements={items} />}
          content={<TimeFilters />}
        />
        <Table.Columns.Default
          showOnMobile
          name="Name"
          width="30px"
          render={d => <Avatar size={{ box: 30, text: 12 }} name={abbreviateClassification(d?.classification)} />}
        />
        <Table.Columns.TwoLines width="2fr" showOnMobile lines={[d => `${d?.firstName} ${d?.lastName}`, d => d.email]} />
        <Table.Columns.TwoLines
          width="2fr"
          name="Engagement"
          lines={[d => d?.title, d => (d?.parentClient ? `${d?.client} via ${d.parentClient}` : d?.client)]}
        />
        <Table.Columns.TwoLines width="2fr" name="Location" lines={[d => d?.country, d => d?.location || '-']} />
        <Table.Columns.TwoLines
          showOnMobile
          lines={[d => `${getTotalHours(d)} hours`, d => `${d.currencySymbol || ''}${getTotalAmount(d) ? getTotalAmount(d).toFixed(2) : '0.00'}`]}
        />
        <Table.Columns.TwoLines width="130px" name="Period" lines={[d => displayPeriod(d), d => capitalize(d?.frequency) || '-']} />
        <Table.Columns.Actions
          showOnMobile
          actions={[
            {
              name: 'View',
              onClick: d => {
                setDetails({ open: true, id: d?.engagementId, period: d?.period, engagement: d });
              },
            },
            {
              name: 'Set Status',
              nested: true,
              items: [
                {
                  name: 'Pending',
                  onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Pending, records: d.records?.map(r => r.id) }),
                  confirmation: { messages: { confirmButton: 'Update' } },
                  disabled: d => d?.status === TrackingRecordStatusTypes.Pending,
                },
                {
                  name: 'Submitted',
                  onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Submitted, records: d.records?.map(r => r.id) }),
                  confirmation: { messages: { confirmButton: 'Update' } },
                  disabled: d => d?.status === TrackingRecordStatusTypes.Submitted,
                },
                {
                  name: 'Approved',
                  onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Approved, records: d.records?.map(r => r.id) }),
                  confirmation: { messages: { confirmButton: 'Update' } },
                  disabled: d => d?.status === TrackingRecordStatusTypes.Approved,
                },
                {
                  name: 'Disputed',
                  onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Disputed, records: d.records?.map(r => r.id) }),
                  confirmation: { messages: { confirmButton: 'Update' } },
                  disabled: d => d?.status === TrackingRecordStatusTypes.Disputed,
                },
                {
                  name: 'Processed',
                  onClick: d => updateStatus({ status: TrackingRecordStatusTypes.Processed, records: d.records?.map(r => r.id) }),
                  confirmation: { messages: { confirmButton: 'Update' } },
                  disabled: d => d?.status === TrackingRecordStatusTypes.Processed,
                },
              ],
            },
            { divider: true },
            { name: 'View engagement', href: d => `/engagements/${d.engagementId}` },
            { name: 'View supplier', href: d => `/suppliers/${d.supplierId}` },
            { divider: true },
            {
              name: 'Impersonate',
              disabled: d => !d?.email,
              href: d => `/authentication/impersonate/${Buffer.from(d?.email).toString('base64')}`,
              target: '_blank',
            },
          ]}
        />
      </Table.Container>
      <Table.Pagination loading={loading} currentPage={page} totalPages={pageCount} goToPage={goToPage} />
      <TimeDetails
        details={details}
        range={filters.range}
        reloadRecords={reload}
        onClose={() => setDetails({ open: false, id: undefined, period: undefined, engagement: undefined })}
      />
    </React.Fragment>
  );
};

export default PlatformTime;
