import moment from 'moment/moment';
import { extractStartTime, formatDuration } from '../../../../../../../utilities/time/shift';
import { TrackingRecordStatusTypes } from '../../../../../../../domain/tracking-record-status-type';

const endOfDayInMinutes = 24 * 60;

export const isPrevWeekDisabled = date => {
  const now = moment();
  return now.diff(date, 'month') >= 6;
};

export const isNextWeekDisabled = date => {
  const now = moment().endOf('isoWeek');
  return now.isSame(date, 'isoWeek');
};

export const isNextDayDisabled = date => {
  const now = moment().endOf('isoWeek');
  return now.isSame(date, 'day');
};

export const nextWeek = date => {
  if (isNextWeekDisabled(date)) {
    return date;
  }
  return moment(date).add(1, 'week');
};

export const previousWeek = date => {
  if (isPrevWeekDisabled(date)) {
    return date;
  }
  return moment(date).add(-1, 'week');
};

const getEndDuration = shift => {
  const start = extractStartTime(shift.date);
  return moment.duration(start).add(shift.duration);
};

export const getSegments = (dateStr, shifts, opt = { includeBreakSegments: true, includeEmptySegments: false }) => {
  const { includeBreakSegments, includeEmptySegments } = opt;
  // const shifts = timeRecords?.shifts
  //   ?.filter(s => extractDateStr(s.date) === dateStr)
  //   .sort((a, b) => getEndDuration(a).asMinutes() - getEndDuration(b).asMinutes());

  if (!includeBreakSegments) {
    return shifts;
  }

  const records = [];
  shifts?.forEach(shift => {
    if (!!records.length) {
      const lastRecord = records[records.length - 1];
      const lastRecordEnd = getEndDuration(lastRecord);
      const startTime = extractStartTime(shift.date);
      const shiftStart = moment.duration(startTime);
      const diff = shiftStart.asMinutes() - lastRecordEnd.asMinutes();
      if (diff > 0) {
        const breakDuration = moment.duration(diff, 'minutes');
        const breakRecord = {
          id: Math.random(),
          date: `${dateStr}T${formatDuration(lastRecordEnd)}`,
          type: 'break',
          duration: formatDuration(breakDuration),
        };
        records.push(breakRecord);
      }
    }
    records.push(shift);
  });

  if (!includeEmptySegments) {
    return records;
  }

  if (!records?.length) {
    return [];
  }

  const firstRecord = records[0];
  const firstRecordStart = extractStartTime(firstRecord.date);
  if (firstRecordStart !== '00:00') {
    const emptySpace = {
      id: Math.random(),
      date: `${dateStr}T00:00`,
      type: 'empty',
      duration: firstRecordStart,
    };
    records.unshift(emptySpace);
  }

  const lastRecord = records[records.length - 1];
  const lastRecordDuration = getEndDuration(lastRecord);
  const diff = endOfDayInMinutes - lastRecordDuration.asMinutes();
  if (diff > 0) {
    const startTime = formatDuration(lastRecordDuration);
    const emptySpace = {
      id: Math.random(),
      date: `${dateStr}T${startTime}`,
      type: 'empty',
      duration: formatDuration(moment.duration(diff, 'minutes')),
    };
    records.push(emptySpace);
  }

  return records;
};

const calculateShiftStart = (date, records) => {
  if (!records?.length) {
    return '08:00';
  }

  records.sort((a, b) => getEndDuration(b).asMinutes() - getEndDuration(a).asMinutes());

  const lastItem = records[0];
  const end = getEndDuration(lastItem);
  const restOfTheDay = endOfDayInMinutes - end.asMinutes();
  if (restOfTheDay > 60) {
    // if there is more than 1 hour left in the day add break
    end.add(1, 'hour');
  }
  return formatDuration(end);
};

const calculateShiftDuration = start => {
  const endInMinutes = Math.min(endOfDayInMinutes - 1, moment.duration(start).add(4, 'hours').asMinutes());
  const end = moment.duration(endInMinutes, 'minutes');
  const duration = end.subtract(start);
  return formatDuration(duration);
};

export const createNextShiftSession = (dateStr, records) => {
  const start = calculateShiftStart(dateStr, records);
  const duration = calculateShiftDuration(start);
  return {
    date: `${dateStr}T${start}`,
    duration,
    status: TrackingRecordStatusTypes.Pending,
  };
};
