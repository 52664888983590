import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useNavigate } from 'react-router';

import Spinner from '../components/spinner';
import { getUserProfile } from '../state/identity';
import { getThemes } from '../state/application';
import { getCodeFromLocation, getTokens, LOGOUT_ROUTE } from './service';

const Callback = ({ getUserProfile, getThemes }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    async function handleCallback() {
      const code = getCodeFromLocation(location);
      if (code) {
        try {
          await getTokens(code, navigate);
          await getUserProfile();
          await getThemes();
        } catch (e) {
          navigate(LOGOUT_ROUTE);
        }
      }
    }
    void handleCallback();
  }, [getThemes, getUserProfile, location, navigate]);

  return <Spinner />;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserProfile,
      getThemes,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Callback);
