import { ElementTypes } from '../enumerations/field-definitions';
import { capitalize, uuid } from '../../../../utilities/common';
import { GLOBAL_ITERATOR_NAME } from '../enumerations/constants';

const DEFAULT_STYLE_CONFIG = {
  fontSize: '14px',
  textAlign: 'left',
  fontWeight: 'normal',
  textDecoration: 'none',
  color: '#111111',
  paddingTop: '0px',
  paddingRight: '0px',
  paddingBottom: '0px',
  paddingLeft: '0px',
};

export const createContentItem = (field, content = []) => {
  switch (field.name) {
    case ElementTypes.TABLE: {
      return {
        id: uuid(),
        title: `New Table`,
        elementType: field.name,
        iteratorElement: GLOBAL_ITERATOR_NAME,
        content,
        customStyle: '',
        styleConfig: {
          ...DEFAULT_STYLE_CONFIG,
          width: '100%',
          paddingTop: '0px',
          paddingRight: '30px',
          paddingBottom: '0px',
          paddingLeft: '30px',
        },
      };
    }

    case ElementTypes.DIV: {
      return {
        id: uuid(),
        title: `New Block`,
        elementType: field.name,
        class: '',
        style: '',
        content,
        customStyle: '',
        styleConfig: {
          ...DEFAULT_STYLE_CONFIG,
          paddingTop: '30px',
          paddingRight: '30px',
          paddingBottom: '0px',
          paddingLeft: '30px',
        },
      };
    }

    case ElementTypes.BUTTON: {
      return {
        id: uuid(),
        elementType: field.name,
        class: '',
        style: '',
        value: '',
        text: 'New Button',
        customStyle: '',
        styleConfig: {
          ...DEFAULT_STYLE_CONFIG,
          color: '#ffffff',
          backgroundColor: getComputedStyle(document.body).getPropertyValue('--primary'),
          paddingTop: '15px',
          paddingRight: '30px',
          paddingBottom: '15px',
          paddingLeft: '30px',
        },
      };
    }

    case ElementTypes.LINK: {
      return {
        id: uuid(),
        elementType: field.name,
        class: '',
        style: '',
        value: '',
        text: '',
        content,
        styleConfig: { ...DEFAULT_STYLE_CONFIG, textDecoration: 'underline', color: getComputedStyle(document.body).getPropertyValue('--primary') },
      };
    }

    case ElementTypes.COLUMN:
      return {
        id: uuid(),
        elementType: field.name,
        class: '',
        style: '',
        value: '',
        text: capitalize([field.name, 'field'].join(' ')),
        content,
        customStyle: '',
        styleConfig: {
          ...DEFAULT_STYLE_CONFIG,
          width: 'auto',
          paddingTop: '5px',
          paddingRight: '15px',
          paddingBottom: '5px',
          paddingLeft: '15px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      };

    case ElementTypes.TEXT:
    default: {
      return {
        id: uuid(),
        elementType: field.name,
        class: '',
        style: '',
        value: '',
        text: capitalize([field.name, 'field'].join(' ')),
        content,
        customStyle: '',
        styleConfig: DEFAULT_STYLE_CONFIG,
      };
    }
  }
};
