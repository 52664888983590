import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, ButtonGroup, Classes } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import _ from 'lodash';

import Box from '../../../components/box';
import Page from '../../../components/page';

import useApi from '../../../hooks/use-api';
import { downloadDocument, getDocuments } from '../../../apis/documents';

import classes from './pay.module.scss';

const Pay = ({ user }) => {
  const getDocumentsApi = useApi(() => getDocuments({ type: 'payroll', id: user?.id }));

  const payslipIndicator = 'Direct Deposit Vouchers';
  const other = getDocumentsApi?.response?.filter?.(d => !d?.name?.includes?.(payslipIndicator));
  const payslips = _.sortBy(
    getDocumentsApi?.response
      ?.filter?.(d => d?.name?.includes?.(payslipIndicator))
      ?.map?.(d => ({
        ...d,
        date: d?.name?.replace?.(payslipIndicator, '')?.replace?.('.pdf', ''),
      })),
    'date',
  ).reverse();

  const renderBox = (title, loading, items, expanded = false) => (
    <Box title={title} outlined monochrome loading={getDocumentsApi.loading} expandible={{ expanded }}>
      {loading
        ? [1, 2, 3, 4, 5].map((_, i) => (
            <Box.TabularItem key={i}>
              <Box.ItemValue className={Classes.SKELETON}>name</Box.ItemValue>
              <Box.ItemLabel className={Classes.SKELETON}>type</Box.ItemLabel>
              <ButtonGroup>
                <Button small outlined className={Classes.SKELETON} minimal icon="info-sign" />
              </ButtonGroup>
            </Box.TabularItem>
          ))
        : items?.map((document, i) => (
            <Box.TabularItem key={document.name}>
              <Box.ItemValue>{document.name}</Box.ItemValue>
              <Box.ItemLabel>{document.contentType}</Box.ItemLabel>
              <ButtonGroup>
                <Button small outlined icon={<Icons.Download size={16} strokeWidth={1.5} />} onClick={() => downloadDocument(document)} />
              </ButtonGroup>
            </Box.TabularItem>
          ))}
      {!loading && !items?.length && (
        <Box.TabularItem>
          <Box.ItemLabel fill>No records found.</Box.ItemLabel>
        </Box.TabularItem>
      )}
    </Box>
  );

  return (
    <Page title="Pay" path={[{ name: 'Pay', to: '/pay' }]}>
      <div className={classes.pay}>
        {renderBox('Pay Reports', getDocumentsApi.loading, other)}
        {renderBox('Pay History', getDocumentsApi.loading, payslips, true)}
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Pay);
