import React from 'react';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Fields from '../../../../components/fields';
import Form from '../../../../components/form';
import useApi from '../../../../hooks/use-api';

import { addPayrollItem, updatePayrollItem } from '../../../../apis/platform/payroll';
import { getSupplierEngagements } from '../../../../apis/platform/suppliers';
import { getEngagements } from '../../../../apis/platform/engagements';
import { isHourly, PayableItemType, PayableItemTypeOptions } from '../../../../domain/payable-item-type';

const AddPayrollItem = ({ initialValues, payrollGroup, onComplete }) => {
  const getSupplierEngagementsApi = useApi(getSupplierEngagements, { skip: true });
  const getSuppliersEngagementsApi = useApi(getEngagements, { skip: true });

  const { currency, period, periodType, classification } = payrollGroup || {};

  const submit = async values => {
    const engagement = getSupplierEngagementsApi.response?.find(e => e.id === values.engagementId);
    const quantity = isHourly(values.type) ? values.quantity : 1;

    const payload = {
      ...values,
      quantity,
      rate: values.amount / quantity,
      rateType: engagement?.compensation?.type,
      clientId: engagement?.clientId,
      parentClientId: engagement?.parentClientId,
      currencyId: currency?.id,
      period,
      periodType,
    };

    if (!initialValues?.supplierId) {
      const supplier = getSuppliersEngagementsApi.response?.data?.find(e => e.supplierId === values.supplierId);
      payload.userId = supplier?.supplierManagerUserId;
    }

    values?.id ? await updatePayrollItem({ id: values?.id, payload }) : await addPayrollItem(payload);
    onComplete();
  };

  return (
    <Form
      initialValues={{
        id: initialValues?.id,
        payrollId: initialValues?.payrollId,
        userId: initialValues?.userId,
        supplierId: initialValues?.supplierId,
        engagementId: initialValues?.engagementId,
        type: initialValues?.type,
        description: initialValues?.description,
        amount: initialValues?.amount,
        quantity: initialValues?.quantity,
      }}
      validationSchema={Yup.object().shape({
        supplierId: Yup.string().required('Supplier is required.'),
        engagementId: Yup.string().required('Engagement is required.'),
        amount: Yup.number()
          .typeError('Invalid numeric value.')
          .when('type', ([type], schema, { value }) => {
            if (!type || !value) {
              return schema.required('Amount is required.');
            }

            if (type === PayableItemType.Deduction) {
              return schema.max(0, 'Deduction amount must be negative value.');
            }

            return schema;
          }),
        quantity: Yup.number()
          .typeError('Invalid numeric value.')
          .when('type', ([type], schema, { value }) => {
            if (!type || !isHourly(type)) {
              return schema;
            }

            if (!value) {
              return schema.required('Quantity is required.').min(0, 'Quantity must be greater than zero.');
            }

            return schema;
          }),
      })}
      enableReinitialize
      onSubmit={submit}
    >
      {({ values, isSubmitting }) => {
        return (
          <React.Fragment>
            {!initialValues?.supplierId && (
              <Fields.Select
                label="Supplier"
                name="supplierId"
                remoteOptions={{
                  loading: getSuppliersEngagementsApi.loading,
                  request: query => {
                    if (!query) {
                      return Promise.resolve(getSuppliersEngagementsApi.response);
                    }

                    return getSuppliersEngagementsApi.request({
                      filters: {
                        query,
                        classificationType: classification,
                      },
                    });
                  },
                  mapper: result => result?.data?.map?.(e => ({ value: e.supplierId, label: e.supplierManagerName })) || [],
                  filter: 'remote',
                }}
                clearable={false}
              />
            )}
            <Fields.Select
              label="Engagement"
              name="engagementId"
              remoteOptions={{
                loading: getSupplierEngagementsApi.loading,
                request: () => (values.supplierId ? getSupplierEngagementsApi.request({ supplierId: values.supplierId }) : null),
                mapper: result => result?.map?.(e => ({ value: e.id, label: e.title })) || [],
                filter: 'local',
                deps: `${values.supplierId}`,
              }}
              clearable={false}
            />
            <Fields.Select label="Type" name="type" options={PayableItemTypeOptions} />
            <Fields.Text
              label="Amount"
              extra={!!currency?.symbol && { leftElement: <Button text={currency?.symbol} minimal small disabled /> }}
              name="amount"
              type="number"
            />
            {isHourly(values.type) && <Fields.Text label="Hours (Quantity)" name="quantity" type="number" />}
            <Fields.Text label="Description" name="description" />
            <Button type="submit" outlined fill text="Submit" disabled={isSubmitting} loading={isSubmitting} />
          </React.Fragment>
        );
      }}
    </Form>
  );
};

export default AddPayrollItem;
