import React, { useEffect } from 'react';
import { Button } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import Spinner from '../../spinner';
import useApi from '../../../hooks/use-api';
import Wizard from '.';

import { getNextStep, initializeFlow } from '../../../apis/wizards';

import classes from './wizards-configurator.module.scss';

const FlowLoader = ({
  flowType, // flow type which is to be followed
  referenceId, // reference identifier, represents an entity identifier for which data is being collected
  data, // context data required for flow initialization, data/configuration lookups and more
  initialize = false, // a flag indicating if flow is to be initialized
  canContinue = true, // a flag indicating if flow will be loading more than 1 step
  onCreate,
  onCancel,
  onComplete = () => null,
}) => {
  const initializeFlowApi = useApi(initializeFlow, { params: { flowType, context: data }, skip: true });
  const getNextStepApi = useApi(getNextStep, { params: { flowType, referenceId }, skip: initialize });

  const api = initialize ? initializeFlowApi : getNextStepApi;

  useEffect(() => {
    async function createInstance() {
      if (initialize) {
        const response = await initializeFlowApi.request({ flowType, context: data });
        if (!response.error) {
          onCreate?.(response);
        }
      }
    }
    void createInstance();
  }, [initialize]);

  const onStepComplete = async () => {
    if (canContinue) {
      await getNextStepApi.request({ flowType, referenceId });
    } else {
      onComplete();
    }
  };

  // if there is no aux data, then no one is needed to sign
  const everyoneSigned =
    !api.response?.aux || api.response?.aux?.every(signatories => !signatories || signatories?.every(signatory => !!signatory.signatureId));

  if (!api.loading && !everyoneSigned) {
    return (
      <div className={classes.loader}>
        <div className={classes.splash}>
          <Icons.FileText className={classes.file} size={150} strokeWidth={0.25} />
          <Icons.Search className={classes.search} size={50} strokeWidth={1.5} />
        </div>
        <div className={classes.message}>Please wait for all signatories to complete their signatures before proceeding.</div>
        {!!onCancel && <Button outlined text="Back" onClick={onCancel} />}
      </div>
    );
  }
  if (!api.loading && api.response?.name === 'In review') {
    return (
      <div className={classes.loader}>
        <div className={classes.splash}>
          <Icons.FileText className={classes.file} size={150} strokeWidth={0.25} />
          <Icons.Search className={classes.search} size={50} strokeWidth={1.5} />
        </div>
        <div className={classes.message}>All set! You can close this screen.</div>
        {!!onCancel && <Button outlined text="Back" onClick={onCancel} />}
      </div>
    );
  }

  if (!api.loading && api.response?.name?.endsWith('in progress')) {
    return (
      <div className={classes.loader}>
        <div className={classes.splash}>
          <Icons.FileText className={classes.file} size={150} strokeWidth={0.25} />
          <Icons.Edit2 className={classes.writing} size={50} strokeWidth={1.5} />
        </div>
        <div className={classes.message}>{`${api.response?.name}, please wait for them to complete.`}</div>
        {!!onCancel && <Button outlined text="Back" onClick={onCancel} />}
      </div>
    );
  }

  if (!api.loading && !api.response?.templateId) {
    return (
      <div className={classes.loader}>
        <div className={classes.message}>Unable to load the flow configuration. Please contact platform administrators for support.</div>
        {!!onCancel && <Button outlined text="Back" onClick={onCancel} />}
      </div>
    );
  }

  if (!api.loading && !api.response?.templateId) {
    return (
      <div className={classes.loader}>
        <div className={classes.message}>Unable to load the flow configuration. Please contact platform administrators for support.</div>
        {!!onCancel && <Button outlined text="Back" onClick={onCancel} />}
      </div>
    );
  }

  return (
    <React.Fragment>
      {api.loading && <Spinner global={false} blend />}
      {!api?.loading && (!api?.response || !!api?.error) && (
        <div className={classes.loader}>
          <div className={classes.message}>Unable to load the flow. Please contact platform administrators for support.</div>
          {!!onCancel && <Button outlined text="Back" onClick={onCancel} />}
        </div>
      )}
      {!api?.loading && !!api?.response && (
        <Wizard
          flowType={flowType}
          referenceId={referenceId}
          templateId={api?.response?.templateId}
          instanceId={api?.response?.wizardInstanceId}
          data={{ ...data, instanceId: api?.response?.wizardInstanceId }}
          onComplete={onStepComplete}
          onCancel={onCancel}
        />
      )}
    </React.Fragment>
  );
};

export default FlowLoader;
