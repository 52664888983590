import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';

import { TimeRecordCommentsContext } from './state';

import classes from './comments.module.scss';

const CommentMessages = ({ user }) => {
  const { comments } = useContext(TimeRecordCommentsContext);

  if (!comments?.length) {
    return null;
  }

  return (
    <div className={classes.inner}>
      {comments.map((comment, i) => (
        <Message key={i} {...comment} mine={comment?.senderId === user?.id} />
      ))}
    </div>
  );
};

const Message = ({ sender, message, date, mine = false }) => {
  return (
    <div className={classNames(classes.message, !!mine && classes.mine)}>
      {!!sender && (
        <div className={classes.details}>
          <div className={classes.sender}>{sender}</div>
          <div className={classes.date}>{moment(date).fromNow()}</div>
          {/* <div className={classes.actions}></div> */}
        </div>
      )}
      <div className={classes.text}>{message}</div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.identity.user,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommentMessages);
