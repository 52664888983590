import React, { useContext, useState } from 'react';
import { Button } from '@blueprintjs/core';

import Navigator from './components/navigator';

import Form from '../../../form';
import Progress from '../../../progress';
import Spinner from '../../../spinner';

import { WizardContext } from '../state';
import { WizardInstanceContext } from '../state/instance-provider';

import WizardForm from './form';

import classes from './browser.module.scss';

const Browser = () => {
  const wizardContext = useContext(WizardContext);
  const instanceContext = useContext(WizardInstanceContext);
  const { template, onCancel } = wizardContext;
  const { step, progress, instance, instanceId, moveForward } = instanceContext;
  const [formOptions, setFormOptions] = useState({ initialValues: instance || {}, validationSchema: null });
  const [navVisible, setNavVisible] = useState(false);

  if (wizardContext.loading || instanceContext.loading || !template) {
    return (
      <div className={classes.browser}>
        <Spinner blend global={false} />
      </div>
    );
  }

  if (!!instanceContext.error) {
    return (
      <div className={classes.browser}>
        <div className={classes.error}>
          <div className={classes.message}>{instanceContext.error}</div>
        </div>
      </div>
    );
  }

  const page = template?.pages?.[step];
  return (
    <div className={classes.browser}>
      <Form initialValues={formOptions.initialValues} validationSchema={formOptions.validationSchema} enableReinitialize={true} onSubmit={moveForward}>
        <div className={classes.progress}>
          <Button minimal icon="more" onClick={() => setNavVisible(true)} />
          <Progress current={progress} total={template?.pages?.length} />
          {page?.buttons?.close?.show !== false && <Button minimal icon={page?.buttons?.close?.icon} onClick={() => onCancel?.(instanceId)} />}
        </div>
        <WizardForm page={page} {...{ setFormOptions }} />
        <Navigator isOpen={navVisible} onClose={() => setNavVisible(false)} />
      </Form>
    </div>
  );
};

export default Browser;
