import React from 'react';

import Dialog from '../../../../dialog';
import useDocuments from '../../state/use-documents';
import FileDropzone from '../../../../fields/file/file-dropzone';
import { replaceTemplateStream } from '../../../../../apis/templates';

const ReplaceDialog = ({ open, toggle }) => {
  const { template, refreshStream } = useDocuments();
  const handleUpload = async file => {
    const formData = new FormData();
    formData.append('file', file);
    await replaceTemplateStream(template.id, formData);
    await refreshStream();
    toggle();
  };

  return (
    <Dialog title="Replace Template Document" isOpen={open} onClose={toggle}>
      <FileDropzone extensions=".pdf" onDrop={handleUpload} />
    </Dialog>
  );
};

export default ReplaceDialog;
