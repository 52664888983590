import React from 'react';

import Page from '../../../components/page';
import Box from '../../../components/box';

import classes from './privacy.module.scss';

const Privacy = () => {
  return (
    <Page title="Privacy Policy" path={[{ name: 'Privacy Policy', to: '/privacy' }]}>
      <div className={classes.privacy}>
        <Box title="Last updated: June 6th, 2023" small monochrome />
        <Box>
          <Box.ItemContainer>
            <div className={classes.content}>
              <p>
                This Privacy Policy explains how information about you is collected, used and disclosed by GreenLight Workforce Solutions ("GreenLight," "we,"
                "us" or "our") when you access or use our website (the"Site") and our online Employer of Record, Agent of Record, benefits, human resources and
                other related services (the "Service"), which are provided through the Site to you and to our client who engages your services (the "Client").
                By using the Site and/or the Service, you consent to the collection, use and disclosure of your information as outlined in this Privacy Policy.
              </p>
              <h3>Information We Collect and How We Collect It</h3>
              <p>
                In connection with your access to our Site and/or use of our Service, we collect and store certain information about you. Some of this
                information can be used on its own or in combination with other information to identify you individually. We call that information "personal
                information." We collect personal information and other information as described below:
              </p>
              <ul>
                <li>
                  <i>Information You Provide.</i> We collect your personal information when you or our Client registers to use the Service, provides information
                  when using the Site or Service, updates your account information, adds additional services, submits information to verify your identity,
                  contacts us with questions or feedback, or otherwise communicates with us. This personal information may include your name, address, email
                  address, phone number, bank account information and taxpayer identification number.
                </li>
                <li>
                  <i>Your Email or Social Network Contacts.</i>
                  We collect your email, social network and other contacts ("Contacts") if you choose to share them with us.
                </li>
                <li>
                  <i>Public Information.</i>
                  We may collect information about you from public sources, such as public social media pages.
                </li>
                <li>
                  <i>Information from Third Parties.</i> We may collect and receive information about you, including personal information and financial account
                  information, from third parties, such as financial institutions and our service providers, for identity verification, fraud protection, risk
                  assessment and other purposes. We may collect your business information from credit bureaus for the foregoing purposes as well. In addition,
                  we may receive demographic information about you from third parties to help us better understand our users and to improve and market our
                  Service.
                </li>
                <li>
                  <i>Health and Health Insurance Information.</i> If you or our Client uses the Service to manage your health benefits (the "Benefits Service"),
                  we may receive health information about you and your dependents in order to provide the Benefits Service. The health information may include
                  information about your insurance carrier, insurance plan and claims you submit for coverage. We receive the health information (i) directly
                  from you when you submit enrollment or claims information, or otherwise provide health information to us; (ii) from the primary policyholder
                  on your benefits plan, if you are a spouse or dependent of the primary policyholder; (iii) from our Client; (iv) from insurance carriers; or
                  (v) from other third-party administrators.
                </li>
                <li>
                  <i>Automatically Collected Information.</i> We automatically collect certain usage information when you access the Site or use the Service,
                  such as your device identifier (if using a mobile device), Internet Protocol (IP) address (if using a browser), operating system, browser type
                  and the address of a referring site. We also automatically collect certain usage information through cookies and related technologies, as
                  described below.
                </li>
                <li>
                  <i>Single Sign-On Information.</i> Single Sign-On allows you to sign in to the Service from another service you use and with which we partner.
                  We will collect certain information for security purposes in order to verify your authorized access to the Service, including your username
                  and password for the other service.
                </li>
              </ul>
              <h5>Third-Party Software, Cookies and Other Related Technologies</h5>
              <p>
                We may use cookies, pixel tags, web beacons and other similar technologies to better understand how you interact with our Site, monitor
                aggregate usage by our users, and monitor web traffic routing on our Site to help us improve our Site. Most Internet browsers let you change the
                browser's settings to stop accepting cookies or to prompt you before accepting a cookie from websites you visit. If you do not allow cookies,
                you may not be able to use some or all portions or functionality of the Site or Service.
              </p>
              <p>
                We partner with third parties to manage our advertising on other sites and to determine our Site performance. Such partners may use cookies,
                pixel tags, web beacons and other related technologies to collect information about your activities on our Site and other sites so that we can
                (i) provide advertising that may be of interest to you, and (ii) evaluate the efficacy of our marketing programs and our Site. To prevent our
                partners from collecting your information for these purposes, you can visit{' '}
                <a href="http://preferences-mgr.truste.com/" target="_blank" rel="noreferrer">
                  http://preferences-mgr.truste.com{' '}
                </a>
                to opt out of certain advertising networks.
              </p>
              <h3>Use of Your Information</h3>
              <p>We use information that we collect about you for the following purposes:</p>
              <ul>
                <li>to administer the Site, manage accounts and provide the Service;</li>
                <li>to monitor, analyze, improve and develop the Site and Service, and to create new Service features;</li>
                <li>to provide a more customized experience on the Site, Service and/or our partners' or affiliates' websites;</li>
                <li>to understand our users better;</li>
                <li>to validate user information provided to us for fraud and risk detection purposes;</li>
                <li>to determine eligibility for the Service;</li>
                <li>to prevent, identify and address fraudulent or other illegal activity and security issues;</li>
                <li>
                  to (i) solicit feedback, (ii) respond to your or our Client's comments, requests or inquiries, (iii) provide customer service and support, or
                  (iv) otherwise contact you in connection with the Site or Service;
                </li>
                <li>to generate anonymized, aggregate data containing only de-identified, non-personal information that we may use to publish reports;</li>
                <li>
                  for our marketing purposes, such as (i) informing you of our or our partners' or affiliates' products, services, features or offerings that
                  may be of interest to you, (ii) providing you with newsletters, articles, reports, updates and announcements, as well as information about
                  upcoming events, (iii) improving and tailoring our advertising and communications, (iv) analyzing our marketing efforts, and (v) determining
                  your eligibility for certain marketing programs, events and offers;
                </li>
                <li>
                  to operate our business, which includes, without limitation, using your information (i) to process payments, (ii) to manage and enforce
                  contracts with you or with third parties, (iii) to manage our corporate governance, compliance and auditing practices, and (iv) for
                  recruitment purposes, if you submit an application for employment with GreenLight via the Site;
                </li>
                <li>
                  to (i) comply with laws, rules and regulations, including any disclosure or reporting obligations, (ii) resolve disputes with users or third
                  parties, (iii) respond to claims and legal process (including but not limited to subpoenas and court orders) as we deem necessary or
                  appropriate, (iv) protect our property rights or those of third parties, (v) protect the safety of the public or any person, and (vi) prevent
                  or stop any activity we may consider to be (or to pose a risk of being) illegal, unethical or legally actionable; and
                </li>
                <li>for any other purpose for which you expressly authorize us to use your information.</li>
              </ul>
              <h3>Sharing and Disclosure of Your Information</h3>
              <p>
                We will only share your information with the third parties listed below for the purposes described above in the "Use of Your Information"
                Section:
              </p>
              <ul>
                <li>
                  government agencies and taxing authorities, as required to provide the Service, including but not limited to the Internal Revenue Service and
                  state and local tax agencies;
                </li>
                <li>
                  group health plans, insurance carriers and other third parties, such as doctors, hospitals and pharmacies, as needed to carry out the Benefits
                  Service (which may include but not be limited to facilitating benefits plan enrollments, health care operations and insurance payments);
                </li>
                <li>banking and financial institutions;</li>
                <li>certain parties as necessary to respond in good faith to legal process (including but not limited to subpoenas and court orders);</li>
                <li>legal and financial advisors and auditors;</li>
                <li>
                  third-party agents, partners and service providers, who (i) are only permitted to use your information as we allow (which may include
                  contacting you on our behalf), and (ii) are required under law or contract to keep your personal information confidential; and
                </li>
                <li>
                  the following third parties under the circumstances described below:
                  <ul>
                    <li>
                      we may share business information with credit bureaus, and we may share information with certain companies, banks and organizations for
                      the purposes of fraud prevention and determining eligibility for the Service;
                    </li>
                    <li>
                      if there is a sale of GreenLight (including, without limitation, a merger, stock acquisition, sale of assets or reorganization), or in the
                      event that GreenLight liquidates or dissolves, we may sell, transfer or otherwise share some or all of our assets, which could include
                      your information, to the acquirer;
                    </li>
                    <li>
                      from time to time, we may share reports with the public that contain anonymized, aggregate, de-identified information and statistics; and
                    </li>
                    <li>we may share your information with certain other third parties with whom you expressly authorize us to share your information.</li>
                  </ul>
                </li>
              </ul>
              <p>
                We do not share information with third parties for their own direct marketing purposes. If we disclose any Protected Health Information (as that
                term is defined in 45 C.F.R. Part 160) to third parties, we will do so in accordance with the Health Insurance Portability and Accountability
                Act, as amended ("HIPAA"), the Health Information Technology for Economic and Clinical Health Act, as amended ("HITECH"), and any other
                applicable state and federal privacy and security laws, as they may be amended from time to time.
              </p>
              <h3>Your Choices</h3>
              <h5>Changing or Deleting Your Information</h5>
              <p>
                You may review, update, correct or delete your personal information through your account or by contacting us using the contact information
                listed below. If you would like us to delete your account entirely, please contact us at{' '}
                <a href="mailto:greenlight@greenlight.ai">greenlight@greenlight.ai</a> with a request that we delete your personal information from our
                database. Please note that there may be some delay in the deletion of your data from our servers following your request. Additionally, we may
                retain some of your data as necessary to comply with our legal obligations, resolve disputes, enforce our agreements, or as needed for other
                legitimate business purposes.
              </p>
              <h5>Promotional Communications</h5>
              <p>
                You may unsubscribe from marketing and promotional emails that we send to you by following the opt-out instructions contained in such emails or
                by emailing us at <a href="mailto:marketing@greenlight.ai">marketing@greenlight.ai</a>. If you opt out of receiving marketing and promotional
                emails from us, we may still need to send you emails related to your account and the Service.
              </p>
              <h5>Do Not Track</h5>
              <p>Our Site does not currently have the capability of responding to "Do Not Track" signals received from various browsers.</p>
              <h3>Security</h3>
              <p>
                We employ administrative, physical and technical measures designed to protect your information from unauthorized access and to comply with
                HIPAA, HITECH and other applicable state and federal privacy and security laws; however, despite these efforts, no security measures are perfect
                or impenetrable and no method of data transmission can be guaranteed to prevent any interception or other type of misuse. We also depend on you
                to protect your information. Please set up a strong password and keep it confidential. If you become aware of any breach of security, please
                notify us immediately.
              </p>
              <h3>Links to Other Sites</h3>
              <p>
                The Site and/or Service may contain links to other sites. Any information you provide on a third-party site is provided directly to the owner of
                that site and is subject to that party's privacy policy. This Privacy Policy does not apply to such sites, and we are not responsible for the
                content, policies, or privacy and security practices of such sites.
              </p>
              <h3>Our Policy Toward Children</h3>
              <p>
                The Service is not directed to children under 13 and we do not knowingly collect personal information from children under 13. However, if a
                child under the age of 13 is a dependent on a benefits plan covered by the Benefits Service, we may collect information about the child (solely
                as needed to provide the Benefits Service) from the child's parent or legal guardian, or from insurance carriers and third-party administrators.
              </p>
              <h3>Changes to this Privacy Policy</h3>
              <p>
                Any information that we collect is subject to the Privacy Policy in effect at the time such information is collected. We may, however, modify
                and revise our Privacy Policy from time to time. If we make any material changes to this policy, we will notify you of such changes by posting
                them on our Site, informing you through the Service, or sending you an email or other notification, and we will indicate when such changes will
                become effective. By continuing to access or use our Site or Service after those changes become effective, you are agreeing to be bound by the
                revised policy.
              </p>
              <h3>Contact Information</h3>
              <p>
                Please contact us at <a href="mailto:greenlight@greenlight.ai">greenlight@greenlight.ai</a> if you have any questions about our Privacy Policy.
              </p>
            </div>
          </Box.ItemContainer>
        </Box>
      </div>
    </Page>
  );
};

export default Privacy;
