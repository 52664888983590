import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Button, Drawer } from '@blueprintjs/core';
import * as Icons from 'react-feather';

import WithConfirmation from '../../../../../hooks/with-confirmation';

import { TrackingRecordStatusTypes } from '../../../../../domain/tracking-record-status-type';

import { getTotalAmount, getTotalHours } from '../../../../../utilities/time';

import ShiftGraph from './boxes/shift-graph';
import Shifts from './boxes/shifts';
import Expenses from './boxes/expenses';
import Deliverables from './boxes/deliverables';

import classes from './details.module.scss';

const TimeApprovalDetails = ({ isOpen, onClose, supplier, updateStatus }) => {
  const [shifts, setShifts] = useState([]);
  const [deliverables, setDeliverables] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState({ status: false, type: undefined });

  useEffect(() => {
    if (!!supplier) {
      const records = supplier?.records?.reduce(
        (mapper, r) => {
          const key = r?.type === 0 ? 'shifts' : r?.type === 1 ? 'deliverables' : 'expenses';
          if (!mapper[key]) {
            mapper[key] = [];
          }
          mapper[key].push(r);
          return mapper;
        },
        { shifts: [], deliverables: [], expenses: [] },
      );

      setShifts(records?.shifts || []);
      setDeliverables(records?.deliverables || []);
      setExpenses(records?.expenses || []);
    }
  }, [isOpen, supplier]);

  const handleApproveAll = async () => {
    setLoading({ status: true, type: 'approve' });
    await updateStatus({
      status: TrackingRecordStatusTypes.Approved,
      records: [...shifts?.map?.(s => s.id), ...deliverables?.map?.(d => d.id), ...expenses?.map?.(e => e.id)],
    });
    setLoading({ status: false, type: undefined });
  };
  const handleDisputeAll = async () => {
    setLoading({ status: true, type: 'dispute' });
    await updateStatus({
      status: TrackingRecordStatusTypes.Disputed,
      records: [...shifts?.map?.(s => s.id), ...deliverables?.map?.(d => d.id), ...expenses?.map?.(e => e.id)],
    });
    setLoading({ status: false, type: undefined });
  };

  if (!supplier) return null;

  const hours = getTotalHours(shifts);
  const amount = getTotalAmount([...deliverables, ...expenses], supplier?.currencySymbol);

  return (
    <Drawer isOpen={!!isOpen} onClose={onClose} className={classes.details} position="right" canEscapeKeyClose canOutsideClickClose>
      <div className={classes.header}>
        <div className={classes.title}>
          {supplier?.firstName} {supplier?.lastName}
        </div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
      </div>
      <div className={classes.actions}>
        <div className={classes.period}>{supplier?.formattedPeriod}</div>
        <WithConfirmation messages={{ question: `Approve all logged items ?`, confirmButton: 'Approve all' }}>
          <Button
            outlined
            intent="success"
            rightIcon={<Icons.Check size={16} strokeWidth={1.5} />}
            text="Approve all"
            loading={!!loading?.status && loading?.type === 'approve'}
            onClick={handleApproveAll}
          />
        </WithConfirmation>
        <WithConfirmation messages={{ question: `Dispute all logged items ?`, confirmButton: 'Dispute all' }}>
          <Button
            outlined
            rightIcon={<Icons.X size={16} strokeWidth={1.5} />}
            text="Dispute all"
            loading={!!loading?.status && loading?.type === 'dispute'}
            onClick={handleDisputeAll}
          />
        </WithConfirmation>
      </div>
      <SimpleBar className={classes.wrapper}>
        <div className={classes.content}>
          <ShiftGraph supplier={supplier} shifts={shifts} />
          <Shifts supplier={supplier} shifts={shifts} updateStatus={updateStatus} />
          <Deliverables supplier={supplier} deliverables={deliverables} updateStatus={updateStatus} />
          <Expenses supplier={supplier} expenses={expenses} updateStatus={updateStatus} />
        </div>
      </SimpleBar>
      <div className={classes.totals}>
        <div className={classes.totalsRow}>
          <div className={classes.totalsLabel}>Total</div>
          <div className={classes.totalsValue}>{`${hours} (+ ${amount})`}</div>
        </div>
      </div>
    </Drawer>
  );
};

export default TimeApprovalDetails;
