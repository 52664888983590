import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

import Spinner from '../../spinner';

import { getInstance, getTemplateStream, getFlowStepReview } from '../../../apis/wizards';

import Toolbar from './toolbar';
import TimelineQuestionnaire from './timeline-questionnaire';
import TimelineFlow from './timeline-flow';
import TimelineBackgroundCheck from './timeline-background-check';
import TimelineTypeSpecificContent from './type-specific-content';
import { getInsightsMapper, parseTemplate } from './helpers';

import classes from './timeline-step.module.scss';

const TimelineStep = ({ engagement, step, onClose, loadTimeline }) => {
  const [loading, setLoading] = useState(false);
  const [instance, setInstance] = useState();
  const [template, setTemplate] = useState();
  const [originalTemplate, setOriginalTemplate] = useState();
  const [review, setReview] = useState();

  const loadReview = async () => {
    if (!step?.review?.isRequired) return;

    const reviewResponse = await getFlowStepReview(step?.review?.flowReviewId);
    reviewResponse?.insights?.sort?.((x, y) => moment(x.createdAt).toDate() - moment(y.createdAt).toDate());
    const result = { ...reviewResponse, insights: getInsightsMapper(reviewResponse) };
    setReview(result);

    return result;
  };

  useEffect(() => {
    async function load() {
      if (step?.instanceId) {
        setLoading(true);

        // Get instance
        const instanceResponse = await getInstance(step?.instanceId);
        instanceResponse.values = JSON.parse(instanceResponse?.data);
        setInstance(instanceResponse);

        // Get template
        const templateResponse = await getTemplateStream(instanceResponse?.templateId);
        setOriginalTemplate(templateResponse);
        const parsedTemplate = parseTemplate(templateResponse, instanceResponse.values);
        setTemplate(parsedTemplate);

        await loadReview(step?.review?.flowReviewId);

        setLoading(false);
      }
    }
    void load();
  }, [step?.instanceId]);

  if (loading) {
    return (
      <div className={classes.timelineStep}>
        <div className={classes.header}>
          <div className={classes.title}>{step?.name}</div>
          <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
        </div>
        <div className={classes.loading}>
          <Spinner global={false} blend />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.timelineStep}>
      <div className={classes.header}>
        <div className={classes.title}>{step?.name}</div>
        <Button minimal icon="cross" className={classes.iconButton} onClick={onClose} />
      </div>
      <SimpleBar className={classes.wrapper}>
        <Toolbar
          instanceId={instance?.id}
          engagement={engagement}
          template={template}
          review={review}
          step={step}
          onComplete={loadReview}
          loadTimeline={loadTimeline}
          onClose={onClose}
        />
        <div className={classes.content}>
          <TimelineQuestionnaire engagement={engagement} loadReview={loadReview} review={review} template={template} />
          <TimelineTypeSpecificContent {...{ engagement, step, template, loadTimeline, closeStepDetails: onClose, review, loadReview, instance }} />
          <TimelineFlow
            instanceId={instance?.id}
            engagement={engagement}
            loadReview={loadReview}
            review={review}
            template={template}
            originalTemplate={originalTemplate}
          />
          <TimelineBackgroundCheck engagement={engagement} loadReview={loadReview} review={review} template={template} />
        </div>
      </SimpleBar>
    </div>
  );
};

export default TimelineStep;
