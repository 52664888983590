import React, { useState } from 'react';
import { Button, ButtonGroup, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import * as Icons from 'react-feather';
import SimpleBar from 'simplebar-react';
import _ from 'lodash';

import Form from '../../../../../components/form';
import Fields from '../../../../../components/fields';

import useApi from '../../../../../hooks/use-api';
import { getClientsMap } from '../../../../../apis/client/engagements';

import classes from './invite-button.module.scss';

const InviteButton = ({ organization, onClick }) => {
  const [query, setQuery] = useState('');

  const getClientsMapApi = useApi(getClientsMap, { skip: !organization?.policies?.isPartner });
  if (!organization || !onClick) {
    return null;
  }

  const clients = _.sortBy(getClientsMapApi?.response, ['name']);
  const filtered = clients?.filter(c => c.name?.toLowerCase()?.includes(query?.toLocaleLowerCase()));

  return (
    <ButtonGroup>
      {!!organization?.policies?.isPartner && !!getClientsMapApi?.response?.length ? (
        <Popover
          position="bottom"
          content={
            <div className={classes.inviteButtonList}>
              <Menu>
                <MenuItem text={organization?.name} onClick={() => onClick({ clientId: organization?.id })} />
                {!!getClientsMapApi?.response?.length && (
                  <React.Fragment>
                    <MenuDivider />
                    <Form onSubmit={({ queryValue }) => setQuery(queryValue)} enableReinitialize initialValues={{ queryValue: query }}>
                      <div className={classes.search}>
                        <Icons.Search className={classes.icon} size={17} strokeWidth={2.1} />
                        <Fields.Text name="queryValue" submitOnChange={true} outline={false} minimal={true} showError={false} />
                        <Button className={classes.reset} minimal small icon="cross" onClick={() => setQuery('')} disabled={!query} />
                      </div>
                    </Form>
                    <MenuDivider />
                    <SimpleBar className={classes.inviteButtonListWrapper}>
                      {filtered?.map?.(c => (
                        <MenuItem key={c?.id} text={c?.name} onClick={() => onClick({ clientId: c?.id })} />
                      ))}
                      {!!query && !!clients?.length && !filtered?.length && <MenuItem text="No matches found." disabled />}
                    </SimpleBar>
                  </React.Fragment>
                )}
              </Menu>
            </div>
          }
        >
          <Button
            outlined
            small
            title="Send new supplier invite"
            icon={<Icons.Plus size={16} strokeWidth={1.5} />}
            loading={getClientsMapApi?.loading}
            disabled={getClientsMapApi?.loading}
          />
        </Popover>
      ) : (
        <Button
          outlined
          small
          title="Send new supplier invite"
          icon={<Icons.Plus size={16} strokeWidth={1.5} />}
          onClick={() => onClick({ clientId: organization?.id })}
          loading={getClientsMapApi?.loading}
          disabled={getClientsMapApi?.loading}
        />
      )}
    </ButtonGroup>
  );
};

export default InviteButton;
