import React, { useContext } from 'react';
import SimpleBar from 'simplebar-react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { WizardContext } from '../../../state';
import { WizardInstanceContext } from '../../../state/instance-provider';

import GroupRenderer from './group';
import { replaceMacros } from './renderers-helper';

import classes from './renderers.module.scss';

const PageRenderer = ({ formik, page }) => {
  const wizardContext = useContext(WizardContext);
  const instanceContext = useContext(WizardInstanceContext);

  const { isDesigner } = wizardContext;
  const { step, moveBackward, getVisibleGroups } = instanceContext;

  const textToDisplay = React.useMemo(() => {
    return isDesigner ? page?.description : replaceMacros(page?.description || '', formik.values);
  }, [page?.description, formik.values]);

  const visibleGroups = getVisibleGroups(page, formik?.values);

  return (
    <React.Fragment>
      <div id="wizard_container" className={classes.wizardContainer}>
        <SimpleBar className={classes.page} style={{ overflowX: 'hidden' }}>
          <div className={classes.scrollableContent}>
            {page?.showHeader && (
              <div className={classes.intro}>
                <div className={classes.htmlContent} dangerouslySetInnerHTML={{ __html: textToDisplay }} />
              </div>
            )}
            <div className={classes.body}>
              {visibleGroups?.map(group => (
                <GroupRenderer key={group.id} page={page} group={group} />
              ))}
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className={classes.footer}>
        <Button
          type="button"
          outlined
          className={classNames(classes.button, classes.back, page?.buttons?.back?.show !== true && classes.hidden)}
          text={page?.buttons?.back?.text}
          disabled={step === 0}
          onClick={() => moveBackward(formik.values)}
        />
        <div className={classes.space} />
        <Button
          className={classNames(classes.button, classes.submit, page?.buttons?.next?.show !== true && classes.hidden)}
          type="submit"
          intent="success"
          text={page?.buttons?.next?.text}
        />
      </div>
    </React.Fragment>
  );
};

export default PageRenderer;
