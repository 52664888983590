import React from 'react';
import { Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { uuid } from '../../../../../utilities/common';
import { downloadStringAsFile } from '../../../../../utilities/files';
import { exportClients } from '../../../../../apis/platform/organizations';
import toaster from '../../../../../components/toaster';

const ClientsMenu = ({ filters, page, pageSize, setDialog, openEmailDialog }) => {
  const onExport = () => {
    void toaster.export({
      progress: {
        title: 'Exporting clients',
      },
      promise: () => exportClients({ filters, page, pageSize }),
      onSuccess: response => downloadStringAsFile(response, `Clients_${uuid()}`, 'csv'),
    });
  };

  return (
    <Menu>
      <MenuItem text="New" label={<Icon icon="plus" />} onClick={() => setDialog({ open: true })} />
      <MenuDivider />
      <MenuItem text="Export" label=".csv" onClick={onExport} />
      <MenuDivider />
      <MenuItem text="Send email" label={<Icon icon="send-message" />} onClick={() => openEmailDialog()} />
    </Menu>
  );
};

export default ClientsMenu;
